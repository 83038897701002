export const whiteLabel = {
    hasStarted: false,
    isSaving: false,
    isSubmitting: false,
    isSubmitted: false,
    payment: null,
    assets: {},
    validation: {
        prototype: [
            'sc-wl-android-app-icon',
            'sc-wl-notifications-logo-svg',
            'sc-wl-app-name',
            'sc-wl-splashscreen',
            'sc-wl-brand-pdf',
            'sc-wl-brand-svg',
            'sc-wl-title-en',
            'sc-wl-intro-en',
            'sc-wl-background',
            'sc-wl-terms',
            'sc-wl-privacy',
            'sc-wl-main-color',
            'sc-wl-text-color',
            'sc-wl-suggestion-cards',
            // 'sc-wl-features-flow-start',
            'sc-wl-about-pdf',
            'sc-wl-about-svg',
            'sc-wl-social-website',
            'sc-wl-copyright-company-name',
            'sc-wl-copyright-start-date',
        ],
        release: [
            'sc-wl-ios-app-icon',
            'sc-wl-google-maps-key',
            'sc-wl-azure-manage-endpoint',
            'sc-wl-azure-subscription-endpoint',
            'sc-wl-azure-namespace',
            'sc-wl-azure-hubname',
            'sc-wl-ios-bundle-id',
            'sc-wl-android-bundle-id',
            'sc-wl-ios-dist-cert-file',
            'sc-wl-ios-dist-cert-pass',
            'sc-wl-android-google-services-file',
            'sc-wl-ios-push-cert-file',
            'sc-wl-ios-push-cert-pass',
            'sc-wl-android-keystore-file',
            'sc-wl-ios-dist-provisioning-file',
            'sc-wl-android-keystore-pass',
            'sc-wl-android-key-alias',
            'sc-wl-android-key-pass',
        ],
    },
    steps: [
        {
            id: 0,
            label: '1',
            text: 'Intro',
            assets: null,
        },
        {
            id: 1,
            label: '2',
            text: 'App Icon',
            assets: "in/['sc-wl-ios-app-icon', 'sc-wl-android-app-icon', 'sc-wl-app-name', 'sc-wl-notifications-logo-svg']/j",
            required: [
                'sc-wl-ios-app-icon',
                'sc-wl-android-app-icon',
                'sc-wl-app-name',
                'sc-wl-notifications-logo-svg',
            ],
        },
        {
            id: 2,
            label: '3',
            text: 'Splashscreen',
            assets: "in/['sc-wl-splashscreen']/j",
            required: ['sc-wl-splashscreen'],
        },
        {
            id: 3,
            label: '4',
            text: 'Start Screen',
            assets: "in/['sc-wl-brand-pdf', 'sc-wl-brand-svg', 'sc-wl-title-en', 'sc-wl-intro-en', 'sc-wl-background', 'sc-wl-terms', 'sc-wl-privacy']/j",
            required: [
                'sc-wl-brand-pdf',
                'sc-wl-brand-svg',
                'sc-wl-title-en',
                'sc-wl-intro-en',
                'sc-wl-background',
                'sc-wl-terms',
                'sc-wl-privacy',
            ],
        },
        {
            id: 4,
            label: '5',
            text: 'Main Menu',
            assets: "in/['sc-wl-main-color','sc-wl-text-color','sc-wl-suggestion-cards']/j",
            required: ['sc-wl-main-color', 'sc-wl-text-color', 'sc-wl-suggestion-cards'],
        },
        {
            id: 5,
            label: '6',
            text: 'Profile',
            assets: "in/['sc-wl-social-facebook', 'sc-wl-social-twitter', 'sc-wl-social-blog', 'sc-wl-about-pdf', 'sc-wl-about-svg', 'sc-wl-social-website', 'sc-wl-copyright-company-name', 'sc-wl-copyright-start-date', 'sc-wl-faq']/j",
            required: [
                'sc-wl-about-pdf',
                'sc-wl-about-svg',
                'sc-wl-social-website',
                'sc-wl-copyright-company-name',
                'sc-wl-copyright-start-date',
            ],
        },
        {
            id: 6,
            label: '7',
            text: 'Submit',
            assets: "in/['sc-wl-google-maps-key', 'sc-wl-azure-manage-endpoint', 'sc-wl-azure-namespace', 'sc-wl-azure-hubname', 'sc-wl-ios-bundle-id', 'sc-wl-android-bundle-id', 'sc-wl-ios-dist-cert-file', 'sc-wl-ios-dist-cert-pass', 'sc-wl-android-google-services-file', 'sc-wl-ios-push-cert-file', 'sc-wl-ios-push-cert-pass', 'sc-wl-android-keystore-file', 'sc-wl-ios-dist-provisioning-file', 'sc-wl-azure-subscription-endpoint', 'sc-wl-android-keystore-pass', 'sc-wl-android-key-alias', 'sc-wl-android-key-pass']/j",
            required: [
                'sc-wl-google-maps-key',
                'sc-wl-azure-manage-endpoint',
                'sc-wl-azure-namespace',
                'sc-wl-azure-hubname',
                'sc-wl-ios-bundle-id',
                'sc-wl-android-bundle-id',
                'sc-wl-ios-dist-cert-file',
                'sc-wl-ios-dist-cert-pass',
                'sc-wl-android-google-services-file',
                'sc-wl-ios-push-cert-file',
                'sc-wl-ios-push-cert-pass',
                'sc-wl-android-keystore-file',
                'sc-wl-ios-dist-provisioning-file',
                'sc-wl-azure-subscription-endpoint',
                'sc-wl-android-keystore-pass',
                'sc-wl-android-key-alias',
                'sc-wl-android-key-pass',
            ],
        },
    ],
};
