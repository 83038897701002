import { Observable } from 'rxjs';
import { apiGetAuthHeader, apiHandleRequest } from 'services/api';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { adminGetInsurerInfo } from './admin';
import routes from './routes';

export const getAppMetaData = async params => {
    const url = routes.appMeta.replace(':id', params.id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.params,
    };

    return await apiHandleRequest(request);
};

export const getMetaData = async params => {
    const url = routes.metaData;

    // const payload = {
    //     collection: params.collection ? params.collection : 'users',
    //     expression: params.expression ? params.expression : 'target_id = {application_id}',
    //     type: params.type ? params.type : 'count',
    //     params: params.params ? params.params : null,
    // };

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params,
    };

    return await apiHandleRequest(request);
};

export const getCountries = async () => {
    const url = routes.countries;

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const getCurrencies = async () => {
    const url = routes.currencies;

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const getInsurers = async () => {
    const url = routes.insurers;

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const getAddressFromLatLng = async latlng => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;

    const request = {
        method: 'get',
        url: url,
        headers: {
            // Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const getLegalDisclaimers = async params => {
    const url = routes.settingsLegalDisclaimers.replace(':id', params.id);

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            page_size: params && params.page_size ? params.page_size : DEFAULT_PAGE_SIZE,
            order_by: params && params.order_by ? params.order_by : '-created',
        },
    };

    return await apiHandleRequest(request);
};

export const getLegalDisclaimersObs = params => {
    const obs = new Observable(observer => {
        getLegalDisclaimers(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getAppMetaDataObs = params => {
    const obs = new Observable(observer => {
        getAppMetaData(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getMetaDataObs = params => {
    const obs = new Observable(observer => {
        getMetaData(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getCountriesObs = params => {
    const obs = new Observable(observer => {
        getCountries(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getCurrenciesObs = params => {
    const obs = new Observable(observer => {
        getCurrencies(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getInsurersObs = params => {
    const obs = new Observable(observer => {
        getInsurers(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getProductDetailObs = params => {
    const obs = new Observable(observer => {
        adminGetInsurerInfo(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });
    return obs;
};
