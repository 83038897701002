import React from 'react';
import PropTypes from 'prop-types';
import Styles from './Styles.module.scss';

const DoSearch = ({ text }) => {
    return (
        <div data-testid="Container" className={Styles.container}>
            <i className="icon-icon_search"></i>
            <p>{text || 'Search something...'}</p>
        </div>
    );
};

DoSearch.propTypes = {
    text: PropTypes.string,
};

export default DoSearch;
