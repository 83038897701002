import { getText } from 'localization';
import { roles } from 'services/roles';

export const board = {
    cards: [
        {
            id: '0',
            name: getText('home_tile_integrations_title'),
            key: 'integrations',
            add: {
                slug: '/integrations/create',
            },
            disabled: false,
            hidden: false,
            scope: [roles.ADMIN],
        },
        {
            id: '1',
            name: getText('home_tile_products_title'),
            key: 'projects',
            add: {
                slug: '/projects/create',
            },
            disabled: false,
            hidden: false,
            scope: [roles.INSURER],
        },
        {
            id: '2',
            name: getText('home_tile_data_analytics_title'),
            key: 'analytics',
            add: {
                slug: '/analytics/create',
            },
            disabled: false,
            hidden: false,
            scope: [roles.INSURER],
        },
        {
            id: '3',
            name: getText('home_tile_insurance_title'),
            key: 'insurance',
            add: {
                slug: '/insurance/create',
            },
            disabled: false,
            hidden: false,
            scope: [roles.INSURER, roles.DISTRIBUTOR, roles.DISTRIBUTORSELLER],
        },
        {
            id: '4',
            name: getText('home_tile_networks_title'),
            key: 'networks',
            add: {
                slug: '/networks/create',
            },
            disabled: false,
            hidden: false,
            scope: [roles.INSURER],
        },
        {
            id: '5',
            name: getText('home_tile_distributors_title'),
            key: 'distributors',
            add: {
                slug: '/distributors/create',
            },
            disabled: false,
            hidden: false,
            scope: [roles.INSURER, roles.DISTRIBUTOR],
        },
        {
            id: '6',
            name: getText('home_tile_sellers_title'),
            key: 'sellers',
            add: {
                slug: '/sellers/create',
            },
            disabled: false,
            hidden: false,
            scope: [roles.SELLER, roles.DISTRIBUTORSELLER],
        },
        {
            id: '7',
            name: getText('home_tile_campaigns_title'),
            key: 'campaigns',
            add: {
                slug: '/campaigns/create',
            },
            disabled: false,
            hidden: false,
            scope: [roles.DISTRIBUTOR, roles.INSURER],
        },
    ],
};
