import { default as classNames, default as classnames } from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Paginator from 'shared/Paginator';
import Styles from './Header.module.scss';

export class Header extends PureComponent {
    static propTypes = {
        isSearch: PropTypes.bool,
        texts: PropTypes.object,
        filters: PropTypes.object,
        pagination: PropTypes.object,
        handleOnSort: PropTypes.func,
        onClickPrev: PropTypes.func,
        onClickNext: PropTypes.func,
        onClickStart: PropTypes.func,
        onClickEnd: PropTypes.func,
        data: PropTypes.array,
        hasBorderTop: PropTypes.bool,
        guardAgainstInnacurateClassNames: PropTypes.string,
        headerCustomComponent: PropTypes.func,
        featureFlag: PropTypes.string,
    };

    static defaultProps = {
        hasBorderTop: false,
    };

    getExceptionColWidthAdjustmentsClasses = col => {
        if (
            col.field === 'checked' &&
            this.props.guardAgainstInnacurateClassNames ===
                'admin_distributor_modal_authorized_products'
        ) {
            return Styles['first-item-sssm'];
        }
    };

    render() {
        const {
            isSearch,
            filters,
            texts,
            handleOnSort,
            pagination,
            onClickPrev,
            onClickNext,
            onClickStart,
            onClickEnd,
            data,
            hasBorderTop,
        } = this.props;

        return (
            <div
                data-test="Container"
                className={classNames(Styles.header, hasBorderTop && Styles.hasBorderTop)}
            >
                {data &&
                    data.length > 0 &&
                    data.map(item => (
                        <div
                            data-test="Col"
                            key={item.id}
                            className={classnames(
                                Styles.col,
                                !isSearch && filters.field === item.field ? Styles?.active : null,
                                this.getExceptionColWidthAdjustmentsClasses(item),
                            )}
                            onClick={!isSearch && item.isSortable ? handleOnSort : null}
                            data-field={item.field}
                            data-direction={filters.direction}
                            data-inverse
                        >
                            <p
                                data-field={item.field}
                                data-direction={filters.direction}
                                data-inverse
                            >
                                {item.name}
                            </p>
                            <span
                                className={classnames(
                                    Styles.sort,
                                    !item.isSortable ? Styles.noSort : null,
                                )}
                            >
                                <i
                                    data-field={item.field}
                                    data-direction="asc"
                                    className={classnames(
                                        'icon-icon_arrow_up',
                                        Styles.sortAsc,
                                        !isSearch &&
                                            filters.field === item.field &&
                                            filters.direction === 'asc'
                                            ? Styles?.active
                                            : null,
                                    )}
                                ></i>
                                <i
                                    data-field={item.field}
                                    data-direction="desc"
                                    className={classnames(
                                        'icon-icon_arrow_down',
                                        Styles.sortDesc,
                                        !isSearch &&
                                            filters.field === item.field &&
                                            filters.direction === 'desc'
                                            ? Styles?.active
                                            : null,
                                    )}
                                ></i>
                            </span>
                        </div>
                    ))}

                <div className={Styles.col}>
                    <Paginator
                        data-test="Paginator"
                        texts={texts}
                        data={pagination}
                        onClickPrev={onClickPrev}
                        onClickNext={onClickNext}
                        onClickStart={onClickStart}
                        onClickEnd={onClickEnd}
                    />
                </div>
            </div>
        );
    }
}

export default Header;
