const ActionTypes = {
    TOGGLE_DRAWER: '@@app/TOGGLE_DRAWER',
    TOGGLE_MODAL: '@@app/TOGGLE_MODAL',
    IS_LOADING: '@@app/IS_LOADING',
    IS_SAVING: '@@app/IS_SAVING',
    HANDLE_GENERIC_LOADING: '@@app/HANDLE_GENERIC_LOADING',
    APP_LOGIN_SUCCESS: '@@app/APP_LOGIN_SUCCESS',
    APP_LOGIN_ERROR: '@@app/APP_LOGIN_ERROR',
    APP_LOGIN_STORE_EMAIL: '@@app/APP_LOGIN_STORE_EMAIL',
    APP_LOGIN_CLEAR: '@@app/APP_LOGIN_CLEAR',
    APP_REGISTER_SUCCESS: '@@app/APP_REGISTER_SUCCESS',
    APP_REGISTER_ERROR: '@@app/APP_REGISTER_ERROR',
    APP_REGISTER_RESET: '@@app/APP_REGISTER_RESET',
    APP_RECOVER_SUCCESS: '@@app/APP_RECOVER_SUCCESS',
    APP_RECOVER_RESET: '@@app/APP_RECOVER_RESET',
    APP_RECOVER_ERROR: '@@app/APP_RECOVER_ERROR',
    APP_CHECK_AUTH: '@@app/APP_CHECK_AUTH',
    APP_REGISTER_OPEN_TERMS_MODAL: '@@app/APP_REGISTER_OPEN_TERMS_MODAL',
    APP_REGISTER_CLOSE_TERMS_MODAL: '@@app/APP_REGISTER_CLOSE_TERMS_MODAL',
    CHECK_SESSION: '@@app/CHECK_SESSION',
    APP_TOGGLE_INTEGRATIONS: '@@app/APP_TOGGLE_INTEGRATIONS',
    HANDLE_DEVICE_SETUP_DIALOG: '@@flow/HANDLE_DEVICE_SETUP_DIALOG',
    FLOW_TESTER_EDITING: '@@flow/FLOW_TESTER_EDITING',
    FLOW_TESTER_SELECTED: '@@flow/FLOW_TESTER_SELECTED',
    FLOW_TESTER_SEARCH_USER: '@@flow/FLOW_TESTER_SEARCH_USER',
    FLOW_TESTER_CREATE_NEW: '@@flow/FLOW_TESTER_CREATE_NEW',
    FLOW_TESTER_ADD_STEP: '@@flow/FLOW_TESTER_ADD_STEP',
    FLOW_TESTER_EDIT_STEP: '@@flow/FLOW_TESTER_EDIT_STEP',
    FLOW_TESTER_DELETE_STEP: '@@flow/FLOW_TESTER_DELETE_STEP',
    FLOW_TESTER_EDIT_NOTIFICATIONS: '@@flow/FLOW_TESTER_EDIT_NOTIFICATIONS',
    FLOW_TESTER_SELECT_DEVICE: '@@flow/FLOW_TESTER_SELECT_DEVICE',
    FLOW_TESTER_DEVICE_PROPERTIES: '@@flow/FLOW_TESTER_DEVICE_PROPERTIES',
    FLOW_TESTER_TOGGLE_SCHEDULE: '@@flow/FLOW_TESTER_TOGGLE_SCHEDULE',
    FLOW_TESTER_ADD_FLOW: '@@flow/FLOW_TESTER_ADD_FLOW',
    FLOW_TESTER_EDIT_FLOW: '@@flow/FLOW_TESTER_EDIT_FLOW',
    FLOW_TESTER_DELETE_FLOW: '@@flow/FLOW_TESTER_DELETE_FLOW',
    FLOW_TESTER_TOGGLE_EVENT_MODE: '@@flow/FLOW_TESTER_TOGGLE_EVENT_MODE',
    FLOW_TESTER_CHANGE_RATE_INTERVAL: '@@flow/FLOW_TESTER_CHANGE_RATE_INTERVAL',
    PROJECT_IS_LOADING: '@@project/PROJECT_IS_LOADING',
    PROJECT_CLEAR: '@@project/PROJECT_CLEAR',
    PROJECT_DASHBOARD_SYNC_WL: '@@project/PROJECT_DASHBOARD_SYNC_WL',
    PROJECT_SYNC_ANALYTICS_DATA: '@@project/PROJECT_SYNC_ANALYTICS_DATA',
    PROJECT_FETCH: '@@project/PROJECT_FETCH',
    PROJECT_EDIT: '@@project/PROJECT_EDIT',
    PROJECT_UPLOAD_IMAGE: '@@project/PROJECT_UPLOAD_IMAGE',
    PROJECT_SYNC_INSURER_PRODUCT: '@@project/PROJECT_SYNC_INSURER_PRODUCT',
    PROJECT_SYNC_SETTINGS_COUNTRIES: '@@project/PROJECT_SYNC_SETTINGS_COUNTRIES',
    PROJECT_SYNC_SETTINGS_CURRENCIES: '@@project/PROJECT_SYNC_SETTINGS_CURRENCIES',
    PROJECT_SYNC_SETTINGS_LEGAL_DISCLAIMER: '@@project/PROJECT_SYNC_SETTINGS_LEGAL_DISCLAIMER',
    PROJECT_SYNC_DOCUMENTS: '@@project/PROJECT_SYNC_DOCUMENTS',
    PROJECTS_FETCH: '@@projects/PROJECTS_FETCH',
    PROJECTS_ADD_PROJECT: '@@projects/PROJECTS_ADD_PROJECT',
    PROJECTS_EDIT_PROJECT: '@@projects/PROJECTS_EDIT_PROJECT',
    PROJECTS_DELETE_PROJECT: '@@projects/PROJECTS_DELETE_PROJECT',
    PROJECTS_REDIRECT: '@@projects/PROJECTS_REDIRECT',
    PROJECTS_SYNC_INSURERS_PRODUCTS: '@@projects/PROJECTS_SYNC_INSURERS_PRODUCTS',
    PROJECTS_CLEAR: '@@projects/PROJECTS_CLEAR',
    TEAM_MEMBERS_TOGGLE_MODAL: '@@teamMembers/TEAM_MEMBERS_TOGGLE_MODAL',
    TEAM_MEMBERS_ADD_MEMBER: '@@teamMembers/TEAM_MEMBERS_ADD_MEMBER',
    TEAM_MEMBERS_ADD_MEMBER_ERROR: '@@teamMembers/TEAM_MEMBERS_ADD_MEMBER_ERROR',
    TEAM_MEMBERS_ADD_MEMBER_CLEAR_ERROR: '@@teamMembers/TEAM_MEMBERS_ADD_MEMBER_CLEAR_ERROR',
    TEAM_MEMBERS_EDIT_MEMBER: '@@teamMembers/TEAM_MEMBERS_EDIT_MEMBER',
    TEAM_MEMBERS_DELETE_MEMBER: '@@teamMembers/TEAM_MEMBERS_DELETE_MEMBER',
    TEAM_MEMBERS_RESUME_SUSPEND_MEMBER: '@@teamMembers/TEAM_MEMBERS_RESUME_SUSPEND_MEMBER',
    TEAM_MEMBERS_FETCH: '@@teamMembers/TEAM_MEMBERS_FETCH',
    TEAM_MEMBERS_ID: '@@teamMembers/TEAM_MEMBERS_ID',
    TEAM_MEMBERS_DATA: '@@teamMembers/TEAM_MEMBERS_DATA',
    PROMPT_TOGGLE: '@@prompt/PROMPT_TOGGLE',
    PROMPT_OPEN: '@@prompt/PROMPT_OPEN',
    PROMPT_CLOSE: '@@prompt/PROMPT_CLOSE',
    END_USERS_CHANGE_MODE: '@@endUsers/END_USERS_CHANGE_MODE',
    END_USERS_SEARCH_USER: '@@endUsers/END_USERS_SEARCH_USER',
    END_USERS_RESUME_SUSPEND_USER: '@@endUsers/END_USERS_RESUME_SUSPEND_USER',
    END_USERS_DELETE_USER: '@@endUsers/END_USERS_DELETE_USER',
    END_USERS_FETCH: '@@endUsers/END_USERS_FETCH',
    END_USERS_SORT: '@@endUsers/END_USERS_SORT',
    END_USERS_PAGINATE: '@@endUsers/END_USERS_PAGINATE',
    END_USERS_PAGINATE_DISABLE_NEXT: '@@endUsers/END_USERS_PAGINATE_DISABLE_NEXT',
    END_USERS_IS_SENDING_EMAIL: '@@endUsers/END_USERS_IS_SENDING_EMAIL',
    END_USERS_SYNC_TOTAL: '@@endUsers/END_USERS_SYNC_TOTAL',
    END_USERS_SYNC_METADATA: '@@endUsers/END_USERS_SYNC_METADATA',
    END_USERS_SYNC_METADATA_SEARCH: '@@endUsers/END_USERS_SYNC_METADATA_SEARCH',
    END_USERS_CLEAR: '@@endUsers/END_USERS_CLEAR',
    END_USERS_OPEN_VISITED_PLACES_MODAL: '@@endUsers/END_USERS_OPEN_VISITED_PLACES_MODAL',
    END_USERS_CLOSE_VISITED_PLACES_MODAL: '@@endUsers/END_USERS_CLOSE_VISITED_PLACES_MODAL',
    EVENTS_SIMULATOR_CREATE_NEW: '@@eventsSimulator/EVENTS_SIMULATOR_CREATE_NEW',
    EVENTS_SIMULATOR_SELECT_DEVICE: '@@eventsSimulator/EVENTS_SIMULATOR_SELECT_DEVICE',
    EVENTS_SIMULATOR_DEVICE_PROPERTIES: '@@eventsSimulator/EVENTS_SIMULATOR_DEVICE_PROPERTIES',
    EVENTS_SIMULATOR_DELETE_EVENT: '@@eventsSimulator/EVENTS_SIMULATOR_DELETE_EVENT',
    EVENTS_SIMULATOR_SEARCH_USER: '@@eventsSimulator/EVENTS_SIMULATOR_SEARCH_USER',
    BUILDS_FETCH: '@@builds/BUILDS_FETCH',
    BUILDS_SYNC: '@@builds/BUILDS_SYNC',
    BUILDS_CLEAR: '@@builds/BUILDS_CLEAR',
    BUILDS_IS_DOWNLOADING: '@@builds/BUILDS_IS_DOWNLOADING',
    INTEGRATIONS_FETCH: '@@integrations/INTEGRATIONS_FETCH',
    INTEGRATIONS_ADD_INTEGRATION: '@@integrations/INTEGRATIONS_ADD_INTEGRATION',
    INTEGRATIONS_CLEAR: '@@integrations/INTEGRATIONS_CLEAR',
    INTEGRATION_IS_LOADING: '@@integration/INTEGRATION_IS_LOADING',
    INTEGRATION_IS_SAVING: '@@integration/INTEGRATION_IS_SAVING',
    INTEGRATION_CLEAR: '@@integration/INTEGRATION_CLEAR',
    INTEGRATION_FETCH: '@@integration/INTEGRATION_FETCH',
    INTEGRATION_SAVE_DETAILS: '@@integration/INTEGRATION_SAVE_DETAILS',
    INTEGRATION_SEARCH_DEVICE: '@@integration/INTEGRATION_SEARCH_DEVICE',
    INTEGRATION_SYNC_DEVICES: '@@integration/INTEGRATION_SYNC_DEVICES',
    INTEGRATION_CREATE_DEVICE: '@@integration/INTEGRATION_CREATE_DEVICE',
    INTEGRATION_EDIT_DEVICE: '@@integration/INTEGRATION_EDIT_DEVICE',
    INTEGRATION_SYNC_DEVICE: '@@integration/INTEGRATION_SYNC_DEVICE',
    INTEGRATION_DELETE_DEVICE: '@@integration/INTEGRATION_DELETE_DEVICE',
    INTEGRATION_SYNC_DEVICE_COMPONENTS: '@@integration/INTEGRATION_SYNC_DEVICE_COMPONENTS',
    INTEGRATION_CLEAR_DEVICE_STRUCTURE: '@@integration/INTEGRATION_CLEAR_DEVICE_STRUCTURE',
    INTEGRATION_ADD_COMPONENT: '@@integration/INTEGRATION_ADD_COMPONENT',
    INTEGRATION_DELETE_COMPONENT: '@@integration/INTEGRATION_DELETE_COMPONENT',
    INTEGRATION_EDIT_COMPONENT_DETAILS: '@@integration/INTEGRATION_EDIT_COMPONENT_DETAILS',
    INTEGRATION_ADD_COMPONENT_PROPERTY: '@@integration/INTEGRATION_ADD_COMPONENT_PROPERTY',
    INTEGRATION_DELETE_COMPONENT_PROPERTY: '@@integration/INTEGRATION_DELETE_COMPONENT_PROPERTY',
    INTEGRATION_EDIT_COMPONENT_PROPERTY_DETAILS:
        '@@integration/INTEGRATION_EDIT_COMPONENT_PROPERTY_DETAILS',
    INTEGRATION_ADD_COMPONENT_CLASS: '@@integration/INTEGRATION_ADD_COMPONENT_CLASS',
    INTEGRATION_DELETE_COMPONENT_CLASS: '@@integration/INTEGRATION_DELETE_COMPONENT_CLASS',
    INTEGRATION_OPEN_DEVICE_CLASSES_MODAL: '@@integration/INTEGRATION_OPEN_DEVICE_CLASSES_MODAL',
    INTEGRATION_CLOSE_DEVICE_CLASSES_MODAL: '@@integration/INTEGRATION_CLOSE_DEVICE_CLASSES_MODAL',
    INTEGRATION_GRAPHICS_CHANGE_WEBVIEW: '@@integration/INTEGRATION_GRAPHICS_CHANGE_WEBVIEW',
    INTEGRATION_OPEN_DEVICE_UNITS_MODAL: '@@integration/INTEGRATION_OPEN_DEVICE_UNITS_MODAL',
    INTEGRATION_CLOSE_DEVICE_UNITS_MODAL: '@@integration/INTEGRATION_CLOSE_DEVICE_UNITS_MODAL',
    INTEGRATION_CHANGE_DEVICE_PROPERTY_UNIT:
        '@@integration/INTEGRATION_CHANGE_DEVICE_PROPERTY_UNIT',
    INTEGRATION_DELETE_PROPERTY_UNIT: '@@integration/INTEGRATION_DELETE_PROPERTY_UNIT',
    INTEGRATION_OPEN_DEVICE_SCHEMA_MODAL: '@@integration/INTEGRATION_OPEN_DEVICE_SCHEMA_MODAL',
    INTEGRATION_CLOSE_DEVICE_SCHEMA_MODAL: '@@integration/INTEGRATION_CLOSE_DEVICE_SCHEMA_MODAL',
    INTEGRATION_CHANGE_DEVICE_PROPERTY_SCHEMA:
        '@@integration/INTEGRATION_CHANGE_DEVICE_PROPERTY_SCHEMA',
    INTEGRATION_DELETE_PROPERTY_SCHEMA: '@@integration/INTEGRATION_DELETE_PROPERTY_SCHEMA',
    INTEGRATION_OPEN_GRAPHICS_TILE_INFORMATION_MODAL:
        '@@integration/INTEGRATION_OPEN_GRAPHICS_TILE_INFORMATION_MODAL',
    INTEGRATION_CLOSE_GRAPHICS_TILE_INFORMATION_MODAL:
        '@@integration/INTEGRATION_CLOSE_GRAPHICS_TILE_INFORMATION_MODAL',
    INTEGRATION_TOOGLE_TILE_INFORMATION_SECTION:
        '@@integration/INTEGRATION_TOOGLE_TILE_INFORMATION_SECTION',
    INTEGRATION_SYNC_CLASSES: '@@integration/INTEGRATION_SYNC_CLASSES',
    INTEGRATION_SYNC_SCHEMAS: '@@integration/INTEGRATION_SYNC_SCHEMAS',
    INTEGRATION_SYNC_UNITS: '@@integration/INTEGRATION_SYNC_UNITS',
    CHANGE_ACTIVE_PAGE: '@@navigation/CHANGE_ACTIVE_PAGE',
    NAVIGATION_MANAGE_SIDEBAR_ROUTES: '@@navigation/NAVIGATION_MANAGE_SIDEBAR_ROUTES',
    CHANGE_STEP: '@@whiteLabel/CHANGE_STEP',
    WL_UPLOAD_APP_ICON: '@@whiteLabel/WL_UPLOAD_APP_ICON',
    WL_CLEAR_ASSETS_SETUP: '@@whiteLabel/WL_CLEAR_ASSETS_SETUP',
    WL_SYNC_ALL_ASSETS: '@@whiteLabel/WL_SYNC_ALL_ASSETS',
    WL_SYNC_ASSETS_BY_STEP: '@@whiteLabel/WL_SYNC_ASSETS_BY_STEP',
    WL_SYNC_FORM: '@@whiteLabel/WL_SYNC_FORM',
    WL_SUBMIT_SUCCESS: '@@whiteLabel/WL_SUBMIT_SUCCESS',
    WL_SUBMIT_ERROR: '@@whiteLabel/WL_SUBMIT_ERROR',
    WL_SUBMIT_CLEAR: '@@whiteLabel/WL_SUBMIT_CLEAR',
    WL_IS_SAVING: '@@whiteLabel/WL_IS_SAVING',
    WL_IS_SUBMITTING: '@@whiteLabel/WL_IS_SUBMITTING',
    WL_CLEAR: '@@whiteLabel/WL_CLEAR',
    WL_SYNC_BUILDS: '@@whiteLabel/WL_SYNC_BUILDS',
    WL_SYNC_PAYMENT_GATEWAYS: '@@whiteLabel/WL_SYNC_PAYMENT_GATEWAYS',
    COMPATIBLE_DEVICES_MODAL_IS_SAVING: '@@compatibleDevices/COMPATIBLE_DEVICES_MODAL_IS_SAVING',
    COMPATIBLE_DEVICES_MODAL_ERROR: '@@compatibleDevices/COMPATIBLE_DEVICES_MODAL_ERROR',
    COMPATIBLE_DEVICES_OPEN_PUBLIC_MODAL:
        '@@compatibleDevices/COMPATIBLE_DEVICES_OPEN_PUBLIC_MODAL',
    COMPATIBLE_DEVICES_CLOSE_PUBLIC_MODAL:
        '@@compatibleDevices/COMPATIBLE_DEVICES_CLOSE_PUBLIC_MODAL',
    COMPATIBLE_DEVICES_OPEN_PRIVATE_MODAL:
        '@@compatibleDevices/COMPATIBLE_DEVICES_OPEN_PRIVATE_MODAL',
    COMPATIBLE_DEVICES_CLOSE_PRIVATE_MODAL:
        '@@compatibleDevices/COMPATIBLE_DEVICES_CLOSE_PRIVATE_MODAL',
    COMPATIBLE_DEVICES_ADD_DEVICE: '@@compatibleDevices/COMPATIBLE_DEVICES_ADD_DEVICE',
    COMPATIBLE_DEVICES_REMOVE_DEVICE: '@@compatibleDevices/COMPATIBLE_DEVICES_REMOVE_DEVICE',
    COMPATIBLE_DEVICES_PUBLIC_FETCH: '@@compatibleDevices/COMPATIBLE_DEVICES_PUBLIC_FETCH',
    COMPATIBLE_DEVICES_FETCH: '@@compatibleDevices/COMPATIBLE_DEVICES_FETCH',
    COMPATIBLE_DEVICES_ENABLED_FETCH: '@@compatibleDevices/COMPATIBLE_DEVICES_ENABLED_FETCH',
    COMPATIBLE_DEVICES_SORT_BY: '@@compatibleDevices/COMPATIBLE_DEVICES_SORT_BY',
    COMPATIBLE_DEVICES_FILTER: '@@compatibleDevices/COMPATIBLE_DEVICES_FILTER',
    COMPATIBLE_DEVICES_CLEAR_SORT_BY: '@@compatibleDevices/COMPATIBLE_DEVICES_CLEAR_SORT_BY',
    COMPATIBLE_DEVICES_SYNC_ASSETS: '@@compatibleDevices/COMPATIBLE_DEVICES_SYNC_ASSETS',
    ANALYTICS_POPULATION_SEGMENTATION_TOGGLE_FILTER:
        '@@analytics/ANALYTICS_POPULATION_SEGMENTATION_TOGGLE_FILTER',
    ANALYTICS_POPULATION_SEGMENTATION_CLEAR_FILTERS:
        '@@analytics/ANALYTICS_POPULATION_SEGMENTATION_CLEAR_FILTERS',
    ANALYTICS_POPULATION_SEGMENTATION_OPEN_COMPARE_MODAL:
        '@@analytics/ANALYTICS_POPULATION_SEGMENTATION_OPEN_COMPARE_MODAL',
    ANALYTICS_POPULATION_SEGMENTATION_CLOSE_COMPARE_MODAL:
        '@@analytics/ANALYTICS_POPULATION_SEGMENTATION_CLOSE_COMPARE_MODAL',
    ANALYTICS_USERS_VISITED_PLACES_TOGGLE_FILTER:
        '@@analytics/ANALYTICS_USERS_VISITED_PLACES_TOGGLE_FILTER',
    ANALYTICS_USERS_VISITED_PLACES_CLEAR_FILTERS:
        '@@analytics/ANALYTICS_USERS_VISITED_PLACES_CLEAR_FILTERS',
    ANALYTICS_SYNC_PROJECTS: '@@analytics/ANALYTICS_SYNC_PROJECTS',
    ANALYTICS_USERS_VISITED_PLACES_CHANGE_PROJECT:
        '@@analytics/ANALYTICS_USERS_VISITED_PLACES_CHANGE_PROJECT',
    ANALYTICS_USERS_VISITED_PLACES_ERROR: '@@analytics/ANALYTICS_USERS_VISITED_PLACES_ERROR',
    ANALYTICS_USERS_VISITED_PLACES_STORE_USER:
        '@@analytics/ANALYTICS_USERS_VISITED_PLACES_STORE_USER',
    ANALYTICS_USERS_VISITED_PLACES_CLEAR_USER:
        '@@analytics/ANALYTICS_USERS_VISITED_PLACES_CLEAR_USER',
    ANALYTICS_USERS_VISITED_PLACES_STORE_VIEWS:
        '@@analytics/ANALYTICS_USERS_VISITED_PLACES_STORE_VIEWS',
    ANALYTICS_USERS_VISITED_PLACES_ALERT_DISMISS:
        '@@analytics/ANALYTICS_USERS_VISITED_PLACES_ALERT_DISMISS',
    ANALYTICS_USER_DATA_STORE_VIEWS: '@@analytics/ANALYTICS_USER_DATA_STORE_VIEWS',
    ANALYTICS_USER_RAW_LOCATION_DATA_ALERT_DISMISS:
        '@@analytics/ANALYTICS_USER_RAW_LOCATION_DATA_ALERT_DISMISS',
    ANALYTICS_USER_DATA_CLEAR: '@@analytics/ANALYTICS_USER_DATA_CLEAR',
    ANALYTICS_USER_RAW_LOCATION_DATA_ERROR: '@@analytics/ANALYTICS_USER_RAW_LOCATION_DATA_ERROR',
    ANALYTICS_USER_RAW_LOCATION_DATA_STORE_USER:
        '@@analytics/ANALYTICS_USER_RAW_LOCATION_DATA_STORE_USER',
    ANALYTICS_USER_RAW_LOCATION_DATA_CLEAR_USER:
        '@@analytics/ANALYTICS_USER_RAW_LOCATION_DATA_CLEAR_USER',
    ANALYTICS_USER_FACTS_STORE_VIEWS: '@@analytics/ANALYTICS_USER_FACTS_STORE_VIEWS',
    ANALYTICS_USER_FACTS_CLEAR_DATA: '@@analytics/ANALYTICS_USER_FACTS_CLEAR_DATA',
    ANALYTICS_CHANGE_DATES: '@@analytics/ANALYTICS_CHANGE_DATES',
    ANALYTICS_USER_FACTS_IS_LOADING_SCORE: '@@analytics/ANALYTICS_USER_FACTS_IS_LOADING_SCORE',
    ANALYTICS_USER_FACTS_STORE_SCORE: '@@analytics/ANALYTICS_USER_FACTS_STORE_SCORE',
    ANALYTICS_USER_FACTS_CLEAR_MAIN_VIEWS: '@@analytics/ANALYTICS_USER_FACTS_CLEAR_MAIN_VIEWS',
    ANALYTICS_USER_FACTS_CLEAR_SCORE: '@@analytics/ANALYTICS_USER_FACTS_CLEAR_SCORE',
    USECASES_IS_SAVING: '@@usecases/USECASES_IS_SAVING',
    USECASES_OPEN_MODAL: '@@usecases/USECASES_OPEN_MODAL',
    USECASES_CLOSE_MODAL: '@@usecases/USECASES_CLOSE_MODAL',
    USECASES_SYNC: '@@usecases/USECASES_SYNC',
    USECASES_CLEAR: '@@usecases/USECASES_CLEAR',
    INSURANCE_IS_LOADING: '@@insurance/INSURANCE_IS_LOADING',
    INSURANCE_IS_SAVING: '@@insurance/INSURANCE_IS_SAVING',
    INSURANCE_CLEAR: '@@insurance/INSURANCE_CLEAR',
    INSURANCE_SYNC_PROJECTS: '@@insurance/INSURANCE_SYNC_PROJECTS',
    INSURANCE_CHANGE_PROJECT: '@@insurance/INSURANCE_CHANGE_PROJECT',
    INSURANCE_SYNC_INSUREES: '@@insurance/INSURANCE_SYNC_INSUREES',
    INSURANCE_SYNC_INSUREES_SEARCH: '@@insurance/INSURANCE_SYNC_INSUREES_SEARCH',
    INSURANCE_CLEAR_INSUREES_SEARCH: '@@insurance/INSURANCE_CLEAR_INSUREES_SEARCH',
    INSURANCE_SYNC_SEARCH_BY_TYPE: '@@insurance/INSURANCE_SYNC_SEARCH_BY_TYPE',
    INSURANCE_CLEAR_SEARCH_BY_TYPE: '@@insurance/INSURANCE_CLEAR_SEARCH_BY_TYPE',
    INSURANCE_SYNC_DATA_BY_TYPE: '@@insurance/INSURANCE_SYNC_DATA_BY_TYPE',
    INSURANCE_POLICIES_OPEN_PAYMENTS_MODAL: '@@insurance/INSURANCE_POLICIES_OPEN_PAYMENTS_MODAL',
    INSURANCE_POLICIES_CLOSE_PAYMENTS_MODAL: '@@insurance/INSURANCE_POLICIES_CLOSE_PAYMENTS_MODAL',
    INSURANCE_POLICIES_OPEN_DOCUMENTS_MODAL: '@@insurance/INSURANCE_POLICIES_OPEN_DOCUMENTS_MODAL',
    INSURANCE_POLICIES_CLOSE_DOCUMENTS_MODAL:
        '@@insurance/INSURANCE_POLICIES_CLOSE_DOCUMENTS_MODAL',
    INSURANCE_OPEN_BILLING_MODAL: '@@insurance/INSURANCE_OPEN_BILLING_MODAL',
    INSURANCE_CLOSE_BILLING_MODAL: '@@insurance/INSURANCE_CLOSE_BILLING_MODAL',
    INSURANCE_POLICIES_OPEN_MEDIA_MODAL: '@@insurance/INSURANCE_POLICIES_OPEN_MEDIA_MODAL',
    INSURANCE_POLICIES_CLOSE_MEDIA_MODAL: '@@insurance/INSURANCE_POLICIES_CLOSE_MEDIA_MODAL',
    INSURANCE_POLICIES_OPEN_DAMAGES_MODAL: '@@insurance/INSURANCE_POLICIES_OPEN_DAMAGES_MODAL',
    INSURANCE_POLICIES_CLOSE_DAMAGES_MODAL: '@@insurance/INSURANCE_POLICIES_CLOSE_DAMAGES_MODAL',
    INSURANCE_SYNC_META_DATA_TOTALS: '@@insurance/INSURANCE_SYNC_META_DATA_TOTALS',
    INSURANCE_SYNC_CURRENT_VIEW: '@@insurance/INSURANCE_SYNC_CURRENT_VIEW',
    INSURANCE_CLEAR_CURRENT_VIEW: '@@insurance/INSURANCE_CLEAR_CURRENT_VIEW',
    INSURANCE_SYNC_CAR_SPECS: '@@insurance/INSURANCE_SYNC_CAR_SPECS',
    INSURANCE_SYNC_META_DATA_BY_TYPE: '@@insurance/INSURANCE_SYNC_META_DATA_BY_TYPE',
    INSURANCE_SYNC_PAGINATE_DATA: '@@insurance/INSURANCE_SYNC_PAGINATE_DATA',
    INSURANCE_SYNC_SORT_BY_TYPE: '@@insurance/INSURANCE_SYNC_SORT_BY_TYPE',
    INSURANCE_SYNC_META_DATA_TOTAL_PAYMENTS: '@@insurance/INSURANCE_SYNC_META_DATA_TOTAL_PAYMENTS',
    INSURANCE_SYNC_PAYMENTS: '@@insurance/INSURANCE_SYNC_PAYMENTS',
    INSURANCE_SYNC_SERVICE: '@@insurance/INSURANCE_SYNC_SERVICE',
    INSURANCE_SYNC_CLAIM_LOCATION: '@@insurance/INSURANCE_SYNC_CLAIM_LOCATION',
    INSURANCE_SYNC_CLAIM_CHAT_HISTORY: '@@insurance/INSURANCE_SYNC_CLAIM_CHAT_HISTORY',
    INSURANCE_FETCH_CLAIM_CHAT_HISTORY: '@@insurance/INSURANCE_FETCH_CLAIM_CHAT_HISTORY',
    INSURANCE_CLEAR_CLAIM_CHAT_HISTORY: '@@insurance/INSURANCE_CLEAR_CLAIM_CHAT_HISTORY',
    INSURANCE_ASSET_OPEN_ODOMETER_HISTORY_MODAL:
        '@@insurance/INSURANCE_ASSET_OPEN_ODOMETER_HISTORY_MODAL',
    INSURANCE_ASSET_CLOSE_ODOMETER_HISTORY_MODAL:
        '@@insurance/INSURANCE_ASSET_CLOSE_ODOMETER_HISTORY_MODAL',
    INSURANCE_ASSET_SYNC_ODOMETER_HISTORY: '@@insurance/INSURANCE_ASSET_SYNC_ODOMETER_HISTORY',
    INSURANCE_ASSET_SYNC_ODOMETER_HISTORY_TOTAL_DRIVEN:
        '@@insurance/INSURANCE_ASSET_SYNC_ODOMETER_HISTORY_TOTAL_DRIVEN',
    INSURANCE_SYNC_BILLING: '@@insurance/INSURANCE_SYNC_BILLING',
    INSURANCE_SYNC_ASSET_URL: '@@insurance/INSURANCE_SYNC_ASSET_URL',
    INSURANCE_CURRENT_VIEW_HAS_ERROR: '@@insurance/INSURANCE_CURRENT_VIEW_HAS_ERROR',
    NETWORKS_IS_LOADING: '@@networks/NETWORKS_IS_LOADING',
    NETWORKS_IS_SAVING: '@@networks/NETWORKS_IS_SAVING',
    NETWORKS_SYNC_DIRECTORIES: '@@networks/NETWORKS_SYNC_DIRECTORIES',
    NETWORKS_SYNC_DIRECTORY: '@@networks/NETWORKS_SYNC_DIRECTORY',
    NETWORKS_CREATE_ENTITY_DIRECTORY: '@@networks/NETWORKS_CREATE_ENTITY_DIRECTORY',
    NETWORKS_EDIT_DIRECTORY: '@@networks/NETWORKS_EDIT_DIRECTORY',
    NETWORKS_CREATE_ENTITY: '@@networks/NETWORKS_CREATE_ENTITY',
    NETWORKS_SYNC_SEGMENTS: '@@networks/NETWORKS_SYNC_SEGMENTS',
    NETWORKS_SYNC_CATEGORIES: '@@networks/NETWORKS_SYNC_CATEGORIES',
    NETWORKS_SYNC_COUNTRIES: '@@networks/NETWORKS_SYNC_COUNTRIES',
    NETWORKS_OPEN_SHARE_MODAL: '@@networks/NETWORKS_OPEN_SHARE_MODAL',
    NETWORKS_CLOSE_SHARE_MODAL: '@@networks/NETWORKS_CLOSE_SHARE_MODAL',
    NETWORKS_SYNC_DIRECTORY_MEMBERS: '@@networks/NETWORKS_SYNC_DIRECTORY_MEMBERS',
    NETWORKS_OPEN_SERVICE_MODAL: '@@networks/NETWORKS_OPEN_SERVICE_MODAL',
    NETWORKS_CLOSE_SERVICE_MODAL: '@@networks/NETWORKS_CLOSE_SERVICE_MODAL',
    NETWORKS_OPEN_ITEM_MODAL: '@@networks/NETWORKS_OPEN_ITEM_MODAL',
    NETWORKS_CLOSE_ITEM_MODAL: '@@networks/NETWORKS_CLOSE_ITEM_MODAL',
    NETWORKS_ADD_MEMBER_ERROR: '@@networks/NETWORKS_ADD_MEMBER_ERROR',
    NETWORKS_ADD_MEMBER_CLEAR_ERROR: '@@networks/NETWORKS_ADD_MEMBER_CLEAR_ERROR',
    NETWORKS_OPEN_ELEMENT_MODAL: '@@networks/NETWORKS_OPEN_ELEMENT_MODAL',
    NETWORKS_CLOSE_ELEMENT_MODAL: '@@networks/NETWORKS_CLOSE_ELEMENT_MODAL',
    NETWORKS_SYNC_ELEMENT: '@@networks/NETWORKS_SYNC_ELEMENT',
    NETWORKS_SYNC_ELEMENT_IMAGE: '@@networks/NETWORKS_SYNC_ELEMENT_IMAGE',
    NETWORKS_SYNC_DIRECTORY_ELEMENTS: '@@networks/NETWORKS_SYNC_DIRECTORY_ELEMENTS',
    NETWORKS_SYNC_META_DATA_TOTALS: '@@networks/NETWORKS_SYNC_META_DATA_TOTALS',
    NETWORKS_SYNC_NETWORKS: '@@networks/NETWORKS_SYNC_NETWORKS',
    NETWORKS_SYNC_NETWORK: '@@networks/NETWORKS_SYNC_NETWORK',
    NETWORKS_SYNC_NETWORK_AVAILABLE_ENTITIES: '@@networks/NETWORKS_SYNC_NETWORK_AVAILABLE_ENTITIES',
    NETWORKS_SYNC_NETWORK_ENTITIES: '@@networks/NETWORKS_SYNC_NETWORK_ENTITIES',
    NETWORKS_SYNC_NETWORK_IMAGE: '@@networks/NETWORKS_SYNC_NETWORK_IMAGE',
    NETWORKS_CLEAR: '@@networks/NETWORKS_CLEAR',
    NETWORKS_SYNC_NETWORK_SELECTED_DIRECTORY: '@@networks/NETWORKS_SYNC_NETWORK_SELECTED_DIRECTORY',
    NETWORKS_LOAD_MORE_SERVICES_ITEMS: '@@networks/NETWORKS_LOAD_MORE_SERVICES_ITEMS',
    NETWORKS_SYNC_NETWORK_ENTITY_SERVICES_ITEMS:
        '@@networks/NETWORKS_SYNC_NETWORK_ENTITY_SERVICES_ITEMS',
    NETWORKS_SYNC_NETWORK_ENTITY_SERVICES_ITEMS_SELECTED:
        '@@networks/NETWORKS_SYNC_NETWORK_ENTITY_SERVICES_ITEMS_SELECTED',
    NETWORKS_SYNC_PROVIDERS: '@@networks/NETWORKS_SYNC_PROVIDERS',
    TEMPLATES_IS_LOADING: '@@templates/TEMPLATES_IS_LOADING',
    TEMPLATES_IS_SAVING: '@@templates/TEMPLATES_IS_SAVING',
    TEMPLATES_SYNC_SERVICE: '@@templates/TEMPLATES_SYNC_SERVICE',
    TEMPLATES_SYNC_SERVICES: '@@templates/TEMPLATES_SYNC_SERVICES',
    TEMPLATES_CLEAR_SERVICE: '@@templates/TEMPLATES_CLEAR_SERVICE',
    TEMPLATES_OPEN_ITEM_MODAL: '@@templates/TEMPLATES_OPEN_ITEM_MODAL',
    TEMPLATES_CLOSE_ITEM_MODAL: '@@templates/TEMPLATES_CLOSE_ITEM_MODAL',
    TEMPLATES_OPEN_COVERAGE_MODAL: '@@templates/TEMPLATES_OPEN_COVERAGE_MODAL',
    TEMPLATES_CLOSE_COVERAGE_MODAL: '@@templates/TEMPLATES_CLOSE_COVERAGE_MODAL',
    TEMPLATES_SYNC_SERVICES_ASSETS: '@@templates/TEMPLATES_SYNC_SERVICES_ASSETS',
    TEMPLATES_SYNC_SERVICES_ITEMS: '@@templates/TEMPLATES_SYNC_SERVICES_ITEMS',
    TEMPLATES_SYNC_COVERAGE: '@@templates/TEMPLATES_SYNC_COVERAGE',
    TEMPLATES_SYNC_SETTINGS: '@@templates/TEMPLATES_SYNC_SETTINGS',
    TEMPLATES_OPEN_CARD_ELEMENT_MODAL: '@@templates/TEMPLATES_OPEN_CARD_ELEMENT_MODAL',
    TEMPLATES_CLOSE_CARD_ELEMENT_MODAL: '@@templates/TEMPLATES_CLOSE_CARD_ELEMENT_MODAL',
    TEMPLATES_SYNC_CARDS: '@@templates/TEMPLATES_SYNC_CARDS',
    TEMPLATES_CLEAR_CARDS: '@@templates/TEMPLATES_CLEAR_CARDS',
    TEMPLATES_SYNC_CARD_IMAGE: '@@templates/TEMPLATES_SYNC_CARD_IMAGE',
    BRE_IS_LOADING: '@@bre/BRE_IS_LOADING',
    BRE_CLEAR: '@@bre/BRE_CLEAR',
    BRE_SYNC_RETRIEVE: '@@bre/BRE_SYNC_RETRIEVE',
    BRE_REQUESTED: '@@bre/BRE_REQUESTED',
    DISTRIBUTORS_IS_LOADING: '@@distributors/DISTRIBUTORS_IS_LOADING',
    DISTRIBUTORS_IS_SAVING: '@@distributors/DISTRIBUTORS_IS_SAVING',
    DISTRIBUTORS_CLEAR: '@@distributors/DISTRIBUTORS_CLEAR',
    DISTRIBUTORS_ADD_INSURANCE_OPEN_MODAL: '@@distributors/DISTRIBUTORS_ADD_INSURANCE_OPEN_MODAL',
    DISTRIBUTORS_ADD_INSURANCE_CLOSE_MODAL: '@@distributors/DISTRIBUTORS_ADD_INSURANCE_CLOSE_MODAL',
    DISTRIBUTORS_ADD_INSURANCE_SYNC_INSURANCE_LIST:
        '@@distributors/DISTRIBUTORS_ADD_INSURANCE_SYNC_INSURANCE_LIST',
    DISTRIBUTORS_ADD_INSURANCE_SYNC_ADDED_INSURANCE_LIST:
        '@@distributors/DISTRIBUTORS_ADD_INSURANCE_SYNC_ADDED_INSURANCE_LIST',
    DISTRIBUTORS_ADD_INSURANCE_SYNC_ADDED_INSURANCE_META_LIST:
        '@@distributors/DISTRIBUTORS_ADD_INSURANCE_SYNC_ADDED_INSURANCE_META_LIST',
    DISTRIBUTORS_ADD_INSURANCE_SYNC_FILTER: '@@distributors/DISTRIBUTORS_ADD_INSURANCE_SYNC_FILTER',
    DISTRIBUTORS_MY_DISTRIBUTORS_SYNC_FILTER:
        '@@distributors/DISTRIBUTORS_MY_DISTRIBUTORS_SYNC_FILTER',
    DISTRIBUTORS_REQUEST_OPEN_MODAL: '@@distributors/DISTRIBUTORS_REQUEST_OPEN_MODAL',
    DISTRIBUTORS_REQUEST_CLOSE_MODAL: '@@distributors/DISTRIBUTORS_REQUEST_CLOSE_MODAL',
    DISTRIBUTORS_REQUEST_SYNC_DISTRIBUTOR: '@@distributors/DISTRIBUTORS_REQUEST_SYNC_DISTRIBUTOR',
    DISTRIBUTORS_SYNC_DISTRIBUTOR_REQUESTS: '@@distributors/DISTRIBUTORS_SYNC_DISTRIBUTOR_REQUESTS',
    DISTRIBUTORS_SYNC_API_ACCESS_CREDENTIALS:
        '@@distributors/DISTRIBUTORS_SYNC_API_ACCESS_CREDENTIALS',
    PROVIDERS_IS_LOADING: '@@providers/PROVIDERS_IS_LOADING',
    PROVIDERS_IS_SAVING: '@@providers/PROVIDERS_IS_SAVING',
    PROVIDERS_CLEAR: '@@providers/PROVIDERS_CLEAR',
    PROVIDERS_ADD_INSURANCE_OPEN_MODAL: '@@providers/PROVIDERS_ADD_INSURANCE_OPEN_MODAL',
    PROVIDERS_ADD_INSURANCE_CLOSE_MODAL: '@@providers/PROVIDERS_ADD_INSURANCE_CLOSE_MODAL',
    PROVIDERS_ADD_INSURANCE_SYNC_INSURANCE_LIST:
        '@@providers/PROVIDERS_ADD_INSURANCE_SYNC_INSURANCE_LIST',
    PROVIDERS_ADD_INSURANCE_SYNC_ADDED_INSURANCE_LIST:
        '@@providers/PROVIDERS_ADD_INSURANCE_SYNC_ADDED_INSURANCE_LIST',
    PROVIDERS_ADD_INSURANCE_SYNC_ADDED_INSURANCE_META_LIST:
        '@@providers/PROVIDERS_ADD_INSURANCE_SYNC_ADDED_INSURANCE_META_LIST',
    PROVIDERS_ADD_INSURANCE_SYNC_FILTER: '@@providers/PROVIDERS_ADD_INSURANCE_SYNC_FILTER',
    PROVIDERS_MY_PROVIDERS_SYNC_FILTER: '@@providers/PROVIDERS_MY_PROVIDERS_SYNC_FILTER',
    PROVIDERS_REQUEST_OPEN_MODAL: '@@providers/PROVIDERS_REQUEST_OPEN_MODAL',
    PROVIDERS_REQUEST_CLOSE_MODAL: '@@providers/PROVIDERS_REQUEST_CLOSE_MODAL',
    PROVIDERS_REQUEST_SYNC_DISTRIBUTOR: '@@providers/PROVIDERS_REQUEST_SYNC_DISTRIBUTOR',
    PROVIDERS_SYNC_DISTRIBUTOR_REQUESTS: '@@providers/PROVIDERS_SYNC_DISTRIBUTOR_REQUESTS',
    PROVIDERS_SYNC_API_ACCESS_CREDENTIALS: '@@providers/PROVIDERS_SYNC_API_ACCESS_CREDENTIALS',

    ADMIN_IS_LOADING: '@@admin/ADMIN_IS_LOADING',
    ADMIN_IS_SAVING: '@@admin/ADMIN_IS_SAVING',
    ADMIN_CLEAR: '@@admin/ADMIN_CLEAR',
    ADMIN_SYNC_APPROVAL_REQUESTS: '@@admin/ADMIN_SYNC_APPROVAL_REQUESTS',
    ADMIN_APPROVAL_REQUESTS_OPEN_MODAL: '@@admin/ADMIN_APPROVAL_REQUESTS_OPEN_MODAL',
    ADMIN_PAGINATE_INSURANCE_PRODUCTS_FORWARD: '@@admin/ADMIN_PAGINATE_INSURANCE_PRODUCTS_FORWARD',
    ADMIN_PAGINATE_INSURANCE_PRODUCTS_BACKWARD:
        '@@admin/ADMIN_PAGINATE_INSURANCE_PRODUCTS_BACKWARD',
    ADMIN_APPROVAL_REQUESTS_CLOSE_MODAL: '@@admin/ADMIN_APPROVAL_REQUESTS_CLOSE_MODAL',

    ADMIN_SYNC_FINANCIAL_ENTITIES_MANAGEMENT: '@@admin/ADMIN_SYNC_FINANCIAL_ENTITIES_MANAGEMENT',
    ADMIN_FINANCIAL_ENTITY_OPEN_MODAL: '@@admin/ADMIN_FINANCIAL_ENTITY_OPEN_MODAL',
    ADMIN_FINANCIAL_ENTITY_CLOSE_MODAL: '@@admin/ADMIN_FINANCIAL_ENTITY_CLOSE_MODAL',

    ADMIN_FINANCIAL_FLOW_OPEN_MODAL: '@@admin/ADMIN_FINANCIAL_FLOW_OPEN_MODAL',
    ADMIN_FINANCIAL_FLOW_CLOSE_MODAL: '@@admin/ADMIN_FINANCIAL_FLOW_CLOSE_MODAL',

    ADMIN_SYNC_DISTRIBUTORS: '@@admin/ADMIN_SYNC_DISTRIBUTORS',
    ADMIN_SYNC_DISTRIBUTOR: '@@admin/ADMIN_SYNC_DISTRIBUTOR',

    ADMIN_SYNC_PROVIDERS: '@@admin/ADMIN_SYNC_PROVIDERS',
    ADMIN_SYNC_PROVIDER: '@@admin/ADMIN_SYNC_PROVIDER',

    ADMIN_SYNC_COUNTRIES_LIST: '@@admin/ADMIN_SYNC_COUNTRIES_LIST',
    ADMIN_INSURANCE_PRODUCTS_SYNC_FILTER: '@@admin/ADMIN_INSURANCE_PRODUCTS_SYNC_FILTER',
    ADMIN_INSURANCE_PRODUCTS_SYNC: '@@admin/ADMIN_INSURANCE_PRODUCTS_SYNC',
    ADMIN_SETTINGS_DISCLAIMERS_SYNC: '@@admin/ADMIN_SETTINGS_DISCLAIMERS_SYNC',
    SELLERS_SET_SHOULD_SHOW_LANGUAGE_SELECTOR:
        '@@sellers/SELLERS_SET_SHOULD_SHOW_LANGUAGE_SELECTOR',
    ADMIN_PRODUCTS_SET_PRODUCT_DETAILS: '@@admin/ADMIN_PRODUCTS_SET_PRODUCT_DETAILS',
};

export default ActionTypes;
