import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './ValidationStatus.module.scss';

export class ValidationStatus extends React.PureComponent {
    static propTypes = {
        texts: PropTypes.object,
        status: PropTypes.string.isRequired,
        errorValidationTip: PropTypes.string,
    };

    static defaultProps = {
        errorValidationTip: 'Required field',
    };

    render() {
        const { status, errorValidationTip } = this.props;

        return (
            <div
                data-test="ValidationStatus"
                className={classnames(Styles.validationStatus, Styles[status])}
            >
                {status === 'success' && <i className="icon-icon_save"></i>}

                {status === 'error' && (
                    <i data-tip={errorValidationTip} className="icon-icon_alert_1"></i>
                )}
            </div>
        );
    }
}

export default ValidationStatus;
