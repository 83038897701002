import rootInitialState from 'redux/store/initialState';
import { generateRandomId, generateUUID } from 'utils/functions';

export const feedInitialstate = () => {
    const initalstate = rootInitialState.integration;

    for (let i = 0; i <= 100; i++) {
        const unit = {
            id: generateUUID(),
            metric: {
                id: generateUUID(),
                name: generateRandomId(),
            },
            imperial: {
                id: generateUUID(),
                name: generateRandomId(),
            },
        };
        rootInitialState.integration.units.list = [...initalstate.units.list, unit];
    }

    return initalstate;
};

export const unitSystems = [
    {
        metric: 'ampere',
        imperial: 'ampere',
    },
    {
        metric: 'calorie',
        imperial: 'calorie',
    },
    {
        metric: 'bpm',
        imperial: 'bpm',
    },
    {
        metric: 'decibel',
        imperial: 'decibel',
    },
    {
        metric: 'hertz',
        imperial: 'hertz',
    },
    {
        metric: 'hour',
        imperial: 'hour',
    },
    {
        metric: 'kelvin',
        imperial: 'kelvin',
    },
    {
        metric: 'lumen',
        imperial: 'lumen',
    },
    {
        metric: 'lux',
        imperial: 'lux',
    },
    {
        metric: 'mgdl',
        imperial: 'mgdl',
    },
    {
        metric: 'millisecond',
        imperial: 'millisecond',
    },
    {
        metric: 'mmhg',
        imperial: 'mmhg',
    },
    {
        metric: 'minute',
        imperial: 'minute',
    },
    {
        metric: 'pascal',
        imperial: 'pascal',
    },
    {
        metric: 'ppm',
        imperial: 'ppm',
    },
    {
        metric: 'rpm',
        imperial: 'rpm',
    },
    {
        metric: 'second',
        imperial: 'second',
    },
    {
        metric: 'spo2',
        imperial: 'spo2',
    },
    {
        metric: 'volt',
        imperial: 'volt',
    },
    {
        metric: 'bar',
        imperial: 'psi',
    },
    {
        metric: 'celsius',
        imperial: 'fahrenheit',
    },
    {
        metric: 'centimeter',
        imperial: 'foot',
    },
    {
        metric: 'millimeter',
        imperial: 'inch',
    },
    {
        metric: 'kilometer',
        imperial: 'mile',
    },
    {
        metric: 'mps',
        imperial: 'fps',
    },
    {
        metric: 'liter',
        imperial: 'gallon',
    },
    {
        metric: 'kilogram',
        imperial: 'pound',
    },
    {
        metric: 'gram',
        imperial: 'ounce',
    },
    {
        metric: 'milligram',
        imperial: 'grain',
    },
    {
        metric: 'kilowatt',
        imperial: 'horsepower',
    },
    {
        metric: 'watt',
        imperial: 'horsepower',
    },
    {
        metric: 'kmph',
        imperial: 'mph',
    },
    {
        metric: 'kmpl',
        imperial: 'mpg',
    },
    // {
    //     metric: 'hmph',
    //     imperial: 'mph',
    // },
    {
        metric: 'mbar',
        imperial: 'inhg',
    },
];
