import ActionTypes from 'redux/actionTypes';
import rootInitialState from 'redux/store/initialState';

export const initialState = {
    projects: rootInitialState.projects,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.PROJECTS_FETCH:
            return syncProjects(state, action);
        case ActionTypes.PROJECTS_ADD_PROJECT:
            return addProject(state, action);
        case ActionTypes.PROJECTS_EDIT_PROJECT:
            return editProject(state, action);
        case ActionTypes.PROJECTS_DELETE_PROJECT:
            return deleteProject(state, action);
        case ActionTypes.PROJECTS_REDIRECT:
            return redirect(state, action);
        case ActionTypes.PROJECTS_SYNC_INSURERS_PRODUCTS:
            return syncInsurersProducts(state, action);
        case ActionTypes.PROJECTS_CLEAR:
            return clear(state, action);
        default:
            return state;
    }
};

const clear = state => {
    return rootInitialState.projects;
};

/**
 * Sync Projects Insurers Products
 * @param {*} state
 * @param {*} action
 */
const syncInsurersProducts = (state, action) => {
    const payload = action.payload.data;
    const elements = payload.elements;

    const projects = state.map(p => {
        const search = elements.filter(el => el.app_id === p.id);
        if (search && search[0]) {
            return {
                ...p,
                insurerProduct: search[0],
            };
        }

        return {
            ...p,
            insurerProduct: null,
        };
    });

    return [...projects];
};

/**
 * Sync Projects
 * @param {*} state
 * @param {*} action
 */
const syncProjects = (state, action) => {
    const payload = action.payload.data;
    const elements = payload.elements;

    return [...elements];
};

/**
 * Redirect action
 * @param {*} state
 * @param {*} action
 */
const redirect = (state, action) => {
    const slug = state[state.length - 1].slug;
    return window.location.replace(slug);
};

/**
 * Add Project
 * @param {*} state
 * @param {*} action
 */
const addProject = (state, action) => {
    const payload = action.payload.data;

    const fakeId = state[state.length - 1].id + 1;

    const project = {
        id: fakeId,
        name: payload.name,
        image: 'http://www.example.com',
        slug: `/projects/${fakeId}`,
    };

    return [...state, project];
};

/**
 * Edit Project
 * @param {*} state
 * @param {*} action
 */
const editProject = (state, action) => {
    const payload = action.payload.data;

    const output = state.map(project => {
        if (project.id === payload.id) {
            return {
                ...project,
                name: payload.name,
            };
        }
        return project;
    });

    return [...output];
};

/**
 * Delete Project
 * @param {*} state
 * @param {*} action
 */
const deleteProject = (state, action) => {
    const payload = action.payload.data;

    const output = state.filter(project => project.id !== payload.id);

    return [...output];
};

export default reducer;
