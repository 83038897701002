import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
// import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en';
import ValidationStatus from 'shared/ValidationStatus';
import './PhoneNumberInput.css';
import Styles from './PhoneNumberInput.module.scss';

export class PhoneNumberInput extends React.PureComponent {
    // myRef = React.createRef();

    static propTypes = {
        number: PropTypes.string,
        withValidation: PropTypes.bool,
        unsaved: PropTypes.bool,
        disabled: PropTypes.bool,
        isLoading: PropTypes.bool,
        isValid: PropTypes.bool,
        onChange: PropTypes.func,
        name: PropTypes.string,
    };

    handleSetValue = value => {
        const payload = {
            target: {
                name: this.props.name,
                value: value,
            },
        };
        this.props.onChange(payload);
    };

    render() {
        const { number, withValidation, unsaved, disabled, isLoading, isValid } = this.props;
        const isActive = number !== '' ? true : false;

        return (
            <div
                data-test="PhoneNumberInputContainer"
                className={classnames(Styles.container, isActive ? Styles?.active : null)}
            >
                <PhoneInput
                    data-test="PhoneInput"
                    placeholder="Insert phone number"
                    value={number}
                    onChange={this.handleSetValue}
                    className={isActive ? 'active' : null}
                    international={true}
                    autoComplete="off"
                    labels={en}
                    defaultCountry="PT"
                    countryCallingCodeEditable={false}
                    focusInputOnCountrySelection={true}
                    disabled={disabled}
                />

                {withValidation && !unsaved && !disabled && !isLoading && (
                    <ValidationStatus
                        data-test="PhoneNumberValidationStatus"
                        status={isValid ? 'success' : 'error'}
                    />
                )}
            </div>
        );
    }
}

export default PhoneNumberInput;
