import { Observable } from 'rxjs';
import { apiGetAuthHeader, apiHandleRequest } from 'services/api';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import routes from './routes';

export const getSegments = async () => {
    const url = routes.networksSegments;

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            page_size: 999,
        },
    };

    return await apiHandleRequest(request);
};

export const getCategories = async params => {
    const url = routes.networksCategories;

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: params ? params : null,
    };

    return await apiHandleRequest(request);
};

export const createDirectory = async params => {
    const url = routes.networksDirectories;

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            name: params.name ? params.name : null,
            directory_type: params.directory_type ? params.directory_type : null,
        },
    };

    return await apiHandleRequest(request);
};

export const getDirectories = async params => {
    const url = routes.networksDirectories;

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            ...params,
            order_by: params && params.order_by ? params.order_by : '-created',
            page_size: params && params.page_size ? params.page_size : DEFAULT_PAGE_SIZE,
        },
    };

    return await apiHandleRequest(request);
};

export const networksGetProviders = async params => {
    const url = routes.networksGetProviders;

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            page_size: params && params.page_size ? params.page_size : DEFAULT_PAGE_SIZE,
            order_by: params && params.order_by ? params.order_by : '-created',
            page_start_index: params && params.page_start_index ? params.page_start_index : 0,
            state:
                params.state?.length > 0
                    ? `in/[${params.state.map(state => `"${state}"`)}]/j`
                    : undefined,
            name: params.name ? params.name : undefined,
            email: params.email ? params.email : undefined,
        },
    };

    return await apiHandleRequest(request);
};

export const getDirectoriesByNetworkId = async params => {
    const url = routes.networksDirectoriesById.replace(':id', params.id);

    let payload;
    if (params && params.params) {
        payload = {
            ...params.params,
            order_by: params && params.params.order_by ? params.params.order_by : '-created',
            page_size:
                params && params.params.page_size ? params.params.page_size : DEFAULT_PAGE_SIZE,
        };
    } else {
        payload = {
            ...params,
            order_by: params && params.order_by ? params.order_by : '-created',
            page_size: params && params.page_size ? params.page_size : DEFAULT_PAGE_SIZE,
        };
    }

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: payload,
    };

    return await apiHandleRequest(request);
};

export const getDirectory = async params => {
    const url = routes.networksDirectoryCRUD.replace(':id', params.id);

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        // params: params,
    };

    return await apiHandleRequest(request);
};

export const editDirectory = async params => {
    const url = routes.networksDirectoryCRUD.replace(':id', params.id);

    const request = {
        method: 'patch',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            ...params.payload,
        },
    };

    return await apiHandleRequest(request);
};

export const deleteDirectory = async params => {
    const url = routes.networksDirectoryCRUD.replace(':id', params.id);

    const request = {
        method: 'delete',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const getDirectoryElements = async (params, types) => {
    let url = '';

    switch (types.section) {
        case 'entities':
            url = routes.networksDirectoryEntities.replace(':id', params.id);
            break;
        case 'services':
            url = routes.networksDirectoryServices.replace(':id', params.id);
            break;
        case 'items':
            url = routes.networksDirectoryItems.replace(':id', params.id);
            break;

        default:
            break;
    }

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        // params: params.params,
        params: {
            ...params.params,
            order_by: params.params && params.params.order_by ? params.params.order_by : '-created',
            page_size:
                params.params && params.params.page_size
                    ? params.params.page_size
                    : DEFAULT_PAGE_SIZE,
        },
    };

    return await apiHandleRequest(request);
};

export const createEntity = async params => {
    const url = routes.networksDirectoryEntities.replace(':id', params.id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            ...params.data,
        },
    };

    return await apiHandleRequest(request);
};

export const editEntity = async params => {
    const url = routes.networksEntityCRUD
        .replace(':id', params.id)
        .replace(':entity-id', params.entityId);

    const request = {
        method: 'patch',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            ...params.data,
        },
    };

    return await apiHandleRequest(request);
};

export const deleteEntity = async params => {
    const url = routes.networksEntityCRUD
        .replace(':id', params.id)
        .replace(':entity-id', params.entityId);

    const request = {
        method: 'delete',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const createUpdateSegments = async (params, types) => {
    let url = '';

    switch (types.section) {
        case 'entities':
            url = routes.networksSegmentsCreate
                .replace(':id', params.id)
                .replace(':type', 'entities')
                .replace(':element-id', params.elementId);

            if (params.action === 'update') {
                url = routes.networksSegmentsUpdate
                    .replace(':id', params.id)
                    .replace(':type', 'entities')
                    .replace(':element-id', params.elementId);
            }
            break;
        case 'services':
            url = routes.networksSegmentsCreate
                .replace(':id', params.id)
                .replace(':type', 'offered-services')
                .replace(':element-id', params.elementId);

            if (params.action === 'update') {
                url = routes.networksSegmentsUpdate
                    .replace(':id', params.id)
                    .replace(':type', 'offered-services')
                    .replace(':element-id', params.elementId);
            }
            break;
        case 'items':
            url = routes.networksSegmentsCreate
                .replace(':id', params.id)
                .replace(':type', 'offered-items')
                .replace(':element-id', params.elementId);

            if (params.action === 'update') {
                url = routes.networksSegmentsUpdate
                    .replace(':id', params.id)
                    .replace(':type', 'offered-items')
                    .replace(':element-id', params.elementId);
            }
            break;

        default:
            break;
    }

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            ...params.data,
        },
    };

    return await apiHandleRequest(request);
};

export const createUpdateCategories = async (params, types) => {
    let url = '';

    switch (types.section) {
        case 'entities':
            url = routes.networksCategoriesCreate
                .replace(':id', params.id)
                .replace(':type', 'entities')
                .replace(':element-id', params.elementId);

            if (params.action === 'update') {
                url = routes.networksCategoriesUpdate
                    .replace(':id', params.id)
                    .replace(':type', 'entities')
                    .replace(':element-id', params.elementId);
            }
            break;
        case 'services':
            url = routes.networksCategoriesCreate
                .replace(':id', params.id)
                .replace(':type', 'offered-services')
                .replace(':element-id', params.elementId);

            if (params.action === 'update') {
                url = routes.networksCategoriesUpdate
                    .replace(':id', params.id)
                    .replace(':type', 'offered-services')
                    .replace(':element-id', params.elementId);
            }
            break;
        case 'items':
            url = routes.networksCategoriesCreate
                .replace(':id', params.id)
                .replace(':type', 'offered-items')
                .replace(':element-id', params.elementId);

            if (params.action === 'update') {
                url = routes.networksCategoriesUpdate
                    .replace(':id', params.id)
                    .replace(':type', 'offered-items')
                    .replace(':element-id', params.elementId);
            }
            break;

        default:
            break;
    }

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            ...params.data,
        },
    };

    return await apiHandleRequest(request);
};

export const getMembers = async params => {
    const url = routes.networksGetNetworkMembers
        .replace(':type', params.type)
        .replace(':id', params.id);

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            ...params,
            order_by: params && params.order_by ? params.order_by : '-created',
            page_size: params && params.page_size ? params.page_size : DEFAULT_PAGE_SIZE,
        },
    };

    return await apiHandleRequest(request);
};

export const memberInvite = async params => {
    const url = routes.networksGetMembersInvite
        .replace(':type', params.type)
        .replace(':id', params.id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            ...params.data,
        },
    };

    return await apiHandleRequest(request);
};

export const memberPause = async params => {
    const url = routes.networksGetMembersPause
        .replace(':type', params.type)
        .replace(':id', params.id)
        .replace(':member-id', params.memberId);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        // data: {
        //     ...params.data,
        // },
    };

    return await apiHandleRequest(request);
};

export const memberResume = async params => {
    const url = routes.networksGetMembersResume
        .replace(':type', params.type)
        .replace(':id', params.id)
        .replace(':member-id', params.memberId);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        // data: {
        //     ...params.data,
        // },
    };

    return await apiHandleRequest(request);
};

export const memberDelete = async params => {
    const url = routes.networksGetMembersDelete
        .replace(':type', params.type)
        .replace(':id', params.id)
        .replace(':member-id', params.memberId);

    const request = {
        method: 'delete',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const createElement = async (params, types) => {
    let url = '';

    switch (types.section) {
        case 'entities':
            url = routes.networksDirectoryEntities.replace(':id', params.id);
            break;
        case 'services':
            url = routes.networksDirectoryServices.replace(':id', params.id);
            break;
        case 'items':
            url = routes.networksDirectoryItems.replace(':id', params.id);
            break;

        default:
            break;
    }

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            ...params.data,
        },
    };

    return await apiHandleRequest(request);
};

export const editElement = async (params, types) => {
    let url = '';

    switch (types.section) {
        case 'entities':
            url = routes.networksEntityCRUD
                .replace(':id', params.id)
                .replace(':entity-id', params.elementId);
            break;
        case 'services':
            url = routes.networksServiceCRUD
                .replace(':id', params.id)
                .replace(':service-id', params.elementId);
            break;
        case 'items':
            url = routes.networksItemCRUD
                .replace(':id', params.id)
                .replace(':item-id', params.elementId);
            break;

        default:
            break;
    }

    const request = {
        method: 'patch',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            ...params.data,
        },
    };

    return await apiHandleRequest(request);
};

export const deleteElement = async (params, types) => {
    let url = '';

    switch (types.section) {
        case 'entities':
            url = routes.networksEntityCRUD
                .replace(':id', params.id)
                .replace(':entity-id', params.elementId);
            break;
        case 'services':
            url = routes.networksServiceCRUD
                .replace(':id', params.id)
                .replace(':service-id', params.elementId);
            break;
        case 'items':
            url = routes.networksItemCRUD
                .replace(':id', params.id)
                .replace(':item-id', params.elementId);
            break;

        default:
            break;
    }

    const request = {
        method: 'delete',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const getNetworks = async params => {
    const url = routes.networksNetworks;

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            ...params,
            order_by: params && params.order_by ? params.order_by : '-created',
            page_size: params && params.page_size ? params.page_size : DEFAULT_PAGE_SIZE,
        },
    };

    return await apiHandleRequest(request);
};

export const createNetwork = async params => {
    const url = routes.networksNetworks;

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            ...params.data,
        },
    };

    return await apiHandleRequest(request);
};

export const editNetwork = async params => {
    const url = routes.networksNetwork.replace(':id', params.id);

    const request = {
        method: 'patch',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            ...params.data,
        },
    };

    return await apiHandleRequest(request);
};

export const updateNetworkDirectories = async params => {
    const url = routes.networksNetworkUpdateDirectories.replace(':id', params.id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            ...params.data,
        },
    };

    return await apiHandleRequest(request);
};

export const updateNetworkSegments = async params => {
    const url = routes.networksNetworkUpdateSegments.replace(':id', params.id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            ...params.data,
        },
    };

    return await apiHandleRequest(request);
};

export const deleteNetwork = async params => {
    const url = routes.networksNetwork.replace(':id', params.id);

    const request = {
        method: 'delete',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const getNetwork = async (params, queryParams) => {
    let url = routes.networksNetwork.replace(':id', params.id);
    if (queryParams?.name) {
        url += `?name=m%2F(%3Fi)${queryParams.name}.*%2Fi`;
    }

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const getNetworkEntities = async (params, queryParams) => {
    let url = routes.networksNetworkEntities.replace(':id', params.id);
    if (queryParams?.name) {
        url += `?name=m%2F(%3Fi)${queryParams.name}.*%2Fi`;
    }
    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            ...params,
            order_by: params && params.order_by ? params.order_by : '-created',
            page_size: params && params.page_size ? params.page_size : DEFAULT_PAGE_SIZE,
        },
    };

    return await apiHandleRequest(request);
};

export const getNetworkAvailableEntities = async (params, queryParams) => {
    let url = routes.networksNetworkAvailableEntities.replace(':id', params.id);
    if (queryParams?.name) {
        url += `?name=m%2F(%3Fi)${queryParams.name}.*%2Fi`;
    }
    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            ...params.params,
            order_by: params && params.order_by ? params.order_by : '-created',
            page_size: params && params.page_size ? params.page_size : DEFAULT_PAGE_SIZE,
        },
    };

    return await apiHandleRequest(request);
};

export const associateNetworkEntities = async params => {
    const url = routes.networksNetworkAssociateEntities.replace(':id', params.id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            ...params.data,
        },
    };

    return await apiHandleRequest(request);
};

export const associateNetworkEntityServicesItems = async params => {
    let url = routes.networksNetworkAssociateEntityServices
        .replace(':id', params.networkId)
        .replace(':entity-id', params.entityId);

    if (params.type === 'items') {
        url = routes.networksNetworkAssociateEntityItems
            .replace(':id', params.networkId)
            .replace(':entity-id', params.entityId);
    }

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            ...params.data,
        },
    };

    return await apiHandleRequest(request);
};

export const getNetworkEntityAvailableServices = async (params, queryParams) => {
    let url = routes.networksNetworkEntityAvailableServices.replace(':id', params.id);
    if (queryParams.name) {
        url += `?name=${queryParams.name}`;
    }

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            ...params.params,
            order_by: params && params.order_by ? params.order_by : '-created',
        },
    };

    return await apiHandleRequest(request);
};

export const getNetworkEntityServices = async (params, queryParams) => {
    let url = routes.networksNetworkEntityServices
        .replace(':id', params.id)
        .replace(':entity-id', params.entityId);

    if (queryParams.name) {
        url += `?name=${queryParams.name}`;
    }

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            ...params.params,
            order_by: params && params.order_by ? params.order_by : '-created',
        },
    };

    return await apiHandleRequest(request);
};

export const getNetworkEntityAvailableItems = async (params, queryParams) => {
    let url = routes.networksNetworkEntityAvailableItems.replace(':id', params.id);
    if (queryParams.name) {
        url += `?name=${queryParams.name}`;
    }
    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            ...params.params,
            order_by: params && params.order_by ? params.order_by : '-created',
        },
    };

    return await apiHandleRequest(request);
};

export const getNetworkEntityItems = async (params, queryParams) => {
    let url = routes.networksNetworkEntityItems
        .replace(':id', params.id)
        .replace(':entity-id', params.entityId);

    if (queryParams.name) {
        url += `?name=${queryParams.name}`;
    }

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            ...params.params,
            order_by: params && params.order_by ? params.order_by : '-created',
        },
    };

    return await apiHandleRequest(request);
};

/**
 * Observables
 */

export const getNetworkEntityAvailableServicesObs = (params, queryParams) => {
    const obs = new Observable(observer => {
        getNetworkEntityAvailableServices(params, queryParams)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getNetworkEntityServicesObs = (params, queryParams) => {
    const obs = new Observable(observer => {
        getNetworkEntityServices(params, queryParams)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getNetworkEntityAvailableItemsObs = (params, queryParams) => {
    const obs = new Observable(observer => {
        getNetworkEntityAvailableItems(params, queryParams)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getNetworkEntityItemsObs = (params, queryParams) => {
    const obs = new Observable(observer => {
        getNetworkEntityItems(params, queryParams)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getNetworkAvailableEntitiesObs = (params, queryParams) => {
    const obs = new Observable(observer => {
        getNetworkAvailableEntities(params, queryParams)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getNetworkEntitiesObs = (params, queryParams) => {
    const obs = new Observable(observer => {
        getNetworkEntities(params, queryParams)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getNetworkObs = (params, queryParams) => {
    const obs = new Observable(observer => {
        getNetwork(params, queryParams)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getNetworksObs = params => {
    const obs = new Observable(observer => {
        getNetworks(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getDirectoriesObs = params => {
    const obs = new Observable(observer => {
        getDirectories(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getDirectoryObs = params => {
    const obs = new Observable(observer => {
        getDirectory(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getDirectoriesByNetworkIdObs = params => {
    const obs = new Observable(observer => {
        getDirectoriesByNetworkId(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getDirectoryElementsObs = (params, types) => {
    const obs = new Observable(observer => {
        getDirectoryElements(params, types)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getSegmentsObs = params => {
    const obs = new Observable(observer => {
        getSegments(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getCategoriesObs = params => {
    const obs = new Observable(observer => {
        getCategories(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};
