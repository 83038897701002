import classnames from 'classnames';
import { getText } from 'localization';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'shared/Button';
import Popup from 'shared/Popup';
import Search from 'shared/Search';
import ValidationStatus from 'shared/ValidationStatus';
import { INSURANCE_STATUS_LIST } from 'utils/constants';
import {
    getDefaultShowSearchOnList,
    getElementPosition,
    isElementIntoViewOnContainer,
} from 'utils/functions';
import Styles from './Dropdown.module.scss';
export class Dropdown extends React.PureComponent {
    state = {
        search: '',
        popup: {
            id: 'dropdown',
            active: false,
            element: {},
            selectedId: -1,
        },
        staticStatesID: INSURANCE_STATUS_LIST.map(status => status.id),
        dynamicStates: this.props.data,
    };

    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])),
        selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        theme: PropTypes.oneOf([
            'primary',
            'secondary',
            'ternary',
            'quaternary',
            'danger',
            'success',
            'blue',
            'form',
            'select',
        ]),
        variant: PropTypes.oneOf(['none', 'underlined', 'outlined', 'filled', 'locked']),
        state: PropTypes.oneOf([
            'empty',
            'active',
            'error',
            'selected',
            'focus',
            'yellow',
            'green',
            'red',
            'gray',
        ]),
        size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl']),
        position: PropTypes.string,
        id: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        disabled: PropTypes.bool,
        double: PropTypes.bool,
        withSearch: PropTypes.bool,
        block: PropTypes.bool,
        name: PropTypes.string,
        namespace: PropTypes.string,
        hasEmptyState: PropTypes.bool,
        readOnly: PropTypes.bool,
        maxWidth: PropTypes.string,
        width: PropTypes.string,
        withValidation: PropTypes.bool,
        isValid: PropTypes.bool,
        unsaved: PropTypes.bool,
        isLoading: PropTypes.bool,
        onUnlock: PropTypes.func,
        payload: PropTypes.object,
        itemIcon: PropTypes.string,
        isAlphabeticSort: PropTypes.bool,
        isCustomPosition: PropTypes.bool,
        title: PropTypes.string,
        valuePossibleCannotBePartOfDataList: PropTypes.bool,
    };

    static defaultProps = {
        theme: 'form',
        variant: 'outlined',
        state: 'empty',
        size: 'md',
        double: false,
        selected: null,
        width: 'auto',
        itemIcon: '',
        isAlphabeticSort: true,
        isCustomPosition: false,
        title: '',
        valuePossibleCannotBePartOfDataList: false,
    };

    componentDidMount() {
        if (this.props.maxWidth) {
            const dropdown = document.getElementById(`dropdown-${this.props.id || 'temp'}`);
            if (dropdown) {
                dropdown.style.maxWidth = `${this.props.maxWidth}`;
            }
        }
    }

    focusOnSelectedElement = () => {
        setTimeout(() => {
            isElementIntoViewOnContainer(
                '[data-selected]',
                `selectPopup-${this.props.id || 'temp'}`,
                true,
                { behavior: 'auto', block: 'center', inline: 'nearest' },
            );
        }, 0);
    };

    handleOnSelect = e => {
        const id = e.currentTarget.getAttribute('data-id');
        const { onChange, name, namespace, payload } = this.props;

        if (name) {
            onChange &&
                typeof onChange === 'function' &&
                onChange({
                    name: name,
                    id: id,
                    namespace: namespace ? namespace : null,
                    payload: payload ? payload : null,
                });
        } else {
            onChange && typeof onChange === 'function' && onChange(id);
        }

        this.handleClosePopup();
    };

    handleTogglePopup = e => {
        e.preventDefault();
        const target = e.currentTarget;
        let targetPosition = getElementPosition(target);
        let customPosition = {
            ...targetPosition,
            top: 'auto',
            left: 'auto',
        };
        this.setState(
            state => ({
                popup: {
                    ...state.popup,
                    active: !state.popup?.active,
                    element: this.props.isCustomPosition ? customPosition : targetPosition,
                    selectedId: !state.popup?.active ? -1 : state.popup.selectedId,
                },
            }),
            () => {
                if (this.state.popup?.active) {
                    window.addEventListener('keydown', this.handleListCursorKeys, false);
                    this.focusOnSelectedElement();
                }
                if (!this.state.popup?.active) {
                    window.removeEventListener('keydown', this.handleListCursorKeys, false);
                }
            },
        );
    };

    handleListCursorKeys = evt => {
        evt = evt || window.event;
        const { onChange, name, id } = this.props;
        switch (evt.keyCode) {
            case 13:
                //Enter
                const selected = document.querySelectorAll('[data-selected]');
                if (selected && selected[0]) {
                    const id = selected[0].getAttribute('data-id');
                    if (name) {
                        onChange &&
                            typeof onChange === 'function' &&
                            onChange({ name: name, id: id });
                    } else {
                        onChange && typeof onChange === 'function' && onChange(id);
                    }

                    this.handleClosePopup();
                }

                break;
            case 38:
                //Up
                this.setState(
                    state => ({
                        popup: {
                            ...state.popup,
                            selectedId: state.popup.selectedId > 0 ? state.popup.selectedId - 1 : 0,
                        },
                    }),
                    () => {
                        isElementIntoViewOnContainer(
                            '[data-selected]',
                            `selectPopup-${id || 'temp'}`,
                            true,
                        );
                    },
                );
                break;
            case 40:
                //Down
                const items = document.querySelectorAll(`#selectPopup-${id || 'temp'} ul > li`);

                this.setState(
                    state => ({
                        popup: {
                            ...state.popup,
                            selectedId:
                                state.popup.selectedId !== -1 &&
                                state.popup.selectedId < items.length
                                    ? state.popup.selectedId + 1
                                    : 0,
                        },
                    }),
                    () => {
                        isElementIntoViewOnContainer(
                            '[data-selected]',
                            `selectPopup-${id || 'temp'}`,
                            true,
                        );
                    },
                );
                break;

            default:
                break;
        }
    };

    handleClosePopup = () => {
        this.setState(state => ({
            search: '',
            popup: {
                ...state.popup,
                active: false,
                selectedId: -1,
            },
        }));
    };

    handleOnChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            state => ({
                [name]: value,
                popup: { ...state.popup, selectedId: -1 },
            }),
            () => {
                if (value === '') {
                    this.focusOnSelectedElement();
                }
            },
        );
    };

    handleOnClear = e => {
        this.setState(
            state => ({ search: '', popup: { ...state.popup, selectedId: -1 } }),
            () => {
                this.focusOnSelectedElement();
            },
        );
    };

    getContent() {
        const {
            data,
            selected,
            double,
            withSearch,
            hasEmptyState,
            placeholder,
            block,
            maxWidth,
            isAlphabeticSort,
            title,
        } = this.props;
        const { search, popup } = this.state;

        const style = {
            maxWidth: maxWidth || 'auto',
        };

        let searchResults = data;

        function alphabeticOrder(a, b) {
            if (isAlphabeticSort === false) return;
            if (a.text < b.text) return -1;
            if (a.text > b.text) return 1;
            return 0;
        }

        if (withSearch) {
            searchResults =
                data &&
                data.length > 0 &&
                data.filter(
                    item =>
                        (item.text &&
                            item.text.toUpperCase().indexOf(search.toUpperCase()) !== -1) ||
                        (item.context?.description &&
                            item.context?.description
                                .toUpperCase()
                                .indexOf(search.toUpperCase()) !== -1) ||
                        (item.context?.insurer &&
                            item.context?.insurer.toUpperCase().indexOf(search.toUpperCase()) !==
                                -1),
                );
        }

        if (double) {
            return (
                <div className={classnames(Styles.list)}>
                    <ul style={style}>
                        {data[0].length > 0 &&
                            data[0].map(item => (
                                <li
                                    className={
                                        selected && item.id.toString() === selected.toString()
                                            ? Styles?.active
                                            : null
                                    }
                                    key={item.id}
                                    data-id={item.id}
                                    onClick={this.handleOnSelect}
                                    title={
                                        item.text +
                                        (item.context !== null && item.context.description !== null)
                                            ? ' - ' + item.context.description
                                            : ''
                                    }
                                >
                                    {item.text +
                                    (item.context !== null && item.context.description !== null)
                                        ? ' - ' + item.context.description
                                        : ''}
                                </li>
                            ))}
                    </ul>
                    <ul style={style}>
                        {data[1].length > 0 &&
                            data[1].map(item => (
                                <li
                                    className={
                                        selected && item.id.toString() === selected.toString()
                                            ? Styles?.active
                                            : null
                                    }
                                    key={item.id}
                                    data-id={item.id}
                                    onClick={this.handleOnSelect}
                                    title={
                                        item.text +
                                        (item.context !== null &&
                                            item.context.description !== null &&
                                            item.context.insurer !== null)
                                            ? item.text +
                                              ' : ' +
                                              item.context.description +
                                              ' - ' +
                                              item.context.insurer
                                            : ''
                                    }
                                >
                                    {item.text +
                                    (item.context !== null &&
                                        item.context.description !== null &&
                                        item.context.insurer !== null)
                                        ? item.text +
                                          ' : ' +
                                          item.context.description +
                                          ' - ' +
                                          item.context.insurer
                                        : ''}
                                </li>
                            ))}
                    </ul>
                </div>
            );
        } else {
            return (
                <>
                    {title && (
                        <div className={Styles.title}>
                            <span>{title}:</span>
                            <span className={Styles.line}></span>
                        </div>
                    )}
                    {withSearch && data.length >= getDefaultShowSearchOnList() && (
                        <div className={classnames(Styles.search, block ? Styles.block : null)}>
                            <Search
                                data-testid="Search"
                                autoFocus={true}
                                name="search"
                                theme="primary"
                                variant="outlined"
                                state={search === '' ? 'empty' : 'active'}
                                size="sm"
                                placeholder={getText('search_with_dots')}
                                onSearch={this.handleOnSubmit}
                                onChange={this.handleOnChange}
                                onClear={search !== '' ? this.handleOnClear : null}
                                value={this.state.search}
                            />
                        </div>
                    )}

                    <ul style={style}>
                        {hasEmptyState &&
                            placeholder &&
                            searchResults &&
                            searchResults.length > 0 && (
                                <li
                                    data-id={null}
                                    onClick={this.handleOnSelect}
                                    className={Styles.clear}
                                >
                                    {placeholder}
                                </li>
                            )}
                        {searchResults &&
                            searchResults.length > 0 &&
                            Array.isArray(searchResults) &&
                            [...searchResults]
                                .filter(result => Boolean(result))
                                .slice()
                                .sort(alphabeticOrder)
                                .map((item, index) =>
                                    item?.invisible ? null : (
                                        <li
                                            data-selected={
                                                (popup &&
                                                    popup.selectedId !== -1 &&
                                                    index.toString() ===
                                                        popup.selectedId.toString()) ||
                                                (popup.selectedId === -1 &&
                                                    selected &&
                                                    item.id.toString() === selected.toString())
                                                    ? 'selected'
                                                    : null
                                            }
                                            className={classnames(
                                                selected &&
                                                    item?.id?.toString() === selected?.toString()
                                                    ? Styles?.active
                                                    : null,
                                                item?.hasOwnProperty('disabled') && item.disabled
                                                    ? Styles.disabled
                                                    : null,
                                            )}
                                            key={item?.id ? item.id : index}
                                            data-id={item?.id ? item.id : index}
                                            onClick={this.handleOnSelect}
                                            title={
                                                item?.context &&
                                                item?.context?.description &&
                                                item?.context?.insurer
                                                    ? item?.text +
                                                      ' : ' +
                                                      item?.context?.description +
                                                      ' - ' +
                                                      item?.context?.insurer
                                                    : item?.text
                                            }
                                        >
                                            <p>
                                                {item?.preIcon && (
                                                    <span
                                                        className={classnames([
                                                            Styles[`fi-${item.preIcon}`],
                                                            Styles.fi,
                                                        ])}
                                                    />
                                                )}
                                                {item?.context && item.context?.description
                                                    ? item?.text +
                                                      ' : ' +
                                                      item?.context?.description +
                                                      ' - ' +
                                                      item?.context?.insurer
                                                    : item?.text}
                                            </p>
                                        </li>
                                    ),
                                )}

                        {(!searchResults ||
                            searchResults.length === 0 ||
                            !searchResults.find(result => Boolean(result))) && (
                            <li className={Styles.noResults}>{getText('no_results_with_dots')}</li>
                        )}
                    </ul>
                </>
            );
        }
    }

    render() {
        const {
            id,
            selected,
            data,
            position,
            placeholder,
            disabled,
            theme,
            variant,
            state,
            size,
            double,
            block,
            readOnly,
            withValidation,
            isValid,
            unsaved,
            isLoading,
            width,
            onUnlock,
            onChange,
            itemIcon,
            valuePossibleCannotBePartOfDataList,
        } = this.props;

        const { popup } = this.state;
        let value = placeholder ? placeholder : getText('dropdown_select');
        if (data) {
            if (double) {
                value =
                    data[0][selected] && data[0][selected].text
                        ? `Sorted by: ${data[0][selected].text}`
                        : placeholder
                        ? placeholder
                        : getText('dropdown_select');
                if (!value) {
                    value =
                        data[1][selected - data[0].length] &&
                        data[1][selected - data[0].length].text
                            ? data[1][selected - data[0].length].text
                            : placeholder
                            ? placeholder
                            : getText('dropdown_select');
                }
            } else {
                const select = data.filter(el => el?.id === selected);
                if (select && select[0]) {
                    value = select[0].icon
                        ? `<i class="icon-${select[0].icon}"></i> ${select[0].text}`
                        : select[0].text;
                } else {
                    if (valuePossibleCannotBePartOfDataList) {
                        value = selected;
                    } else {
                        value =
                            data[selected] && data[selected].text
                                ? data[selected].text
                                : placeholder
                                ? placeholder
                                : getText('dropdown_select');
                    }
                }
            }
        } else {
            value = 'undefined';
        }

        return (
            <div
                data-testid="Dropdown"
                className={Styles.dropdown}
                id={`dropdown-${id || 'temp'}`}
                style={{ width: width }}
            >
                <Button
                    type="button"
                    theme={theme}
                    variant={variant}
                    size={size}
                    value={value}
                    state={
                        state ? state : popup?.active ? 'active' : selected ? 'selected' : 'empty'
                    }
                    onClick={
                        onChange && typeof onChange === 'function' ? this.handleTogglePopup : null
                    }
                    disableClick={onChange && typeof onChange === 'function' ? false : true}
                    icon={
                        popup?.active
                            ? 'icon_arrow_up'
                            : readOnly && onUnlock
                            ? 'icon_delete_2'
                            : 'icon_arrow_down'
                    }
                    itemIcon={itemIcon}
                    disabled={disabled}
                    readOnly={readOnly}
                    onUnlock={onUnlock}
                    block={block}
                />

                {withValidation && !unsaved && !disabled && !isLoading && isValid && (
                    <ValidationStatus status="success" />
                )}

                {withValidation && !unsaved && !disabled && !isLoading && !isValid && (
                    <ValidationStatus status="error" />
                )}

                <Popup
                    data-testid="Popup"
                    id={`selectPopup-${id || 'temp'}`}
                    position={position}
                    size="md"
                    active={popup?.active}
                    element={popup.element}
                    closePopup={this.handleClosePopup}
                    content={popup?.active ? this.getContent() : null}
                    isCustomPosition={
                        this.props.isCustomPosition ? this.state.popup.element : false
                    }
                />
            </div>
        );
    }
}

export default Dropdown;
