import ActionTypes from 'redux/actionTypes';
import rootInitialState from 'redux/store/initialState';
import { getFilename } from 'utils/functions';

export const initialState = {
    whiteLabel: rootInitialState.whiteLabel,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.WL_SYNC_ALL_ASSETS:
            return syncAllAssets(state, action);
        case ActionTypes.WL_CLEAR_ASSETS_SETUP:
            return clearAssets(state, action);
        case ActionTypes.WL_SYNC_ASSETS_BY_STEP:
            return syncAssetsByStep(state, action);
        case ActionTypes.WL_SYNC_FORM:
            return syncForm(state, action);
        case ActionTypes.WL_SUBMIT_SUCCESS:
            return submitSuccess(state, action);
        case ActionTypes.WL_SUBMIT_ERROR:
            return submitError(state, action);
        case ActionTypes.WL_SUBMIT_CLEAR:
            return submitClear(state, action);
        case ActionTypes.WL_IS_SAVING:
            return isSaving(state, action);
        case ActionTypes.WL_IS_SUBMITTING:
            return isSubmitting(state, action);
        case ActionTypes.WL_CLEAR:
            return clear(state, action);
        case ActionTypes.WL_SYNC_BUILDS:
            return syncBuilds(state, action);
        case ActionTypes.WL_SYNC_PAYMENT_GATEWAYS:
            return syncPaymentGateways(state, action);
        default:
            return state;
    }
};

const syncPaymentGateways = (state, action) => {
    const payload = action.payload.data;

    if (!payload || !payload.elements || !payload.elements[0] || !payload.elements[0].cdata) {
        return { ...state };
    }

    return {
        ...state,
        payment: {
            username: payload.elements[0].cdata.webhooks.receive_notification.basic_auth.username,
            password: payload.elements[0].cdata.webhooks.receive_notification.basic_auth.password,
            url: payload.elements[0].cdata.webhooks.receive_notification.url,
        },
    };
};

/**
 * Sync Builds
 * @param {*} state
 * @param {*} action
 */
const syncBuilds = (state, action) => {
    const payload = action.payload.data;
    const builds = payload.elements;

    const prototypes = builds.filter(build => build.target === 'android_prototype');
    const releases = builds.filter(build => build.target !== 'android_prototype');
    let ios = null;
    let android = null;
    if (releases) {
        ios = releases.filter(build => build.target === 'ios_release');
        android = releases.filter(build => build.target === 'android_release');
    }

    return {
        ...state,
        builds: {
            prototypes: prototypes[0] ? { ...prototypes[0] } : null,
            releases: {
                ios: ios[0] ? { ...ios[0] } : null,
                android: android[0] ? { ...android[0] } : null,
            },
        },
    };
};

/**
 * Clear
 * @param {*} state
 * @param {*} action
 */
const clear = (state, action) => {
    return {
        ...state,
        hasStarted: false,
        isSaving: false,
        isSubmitting: false,
        isSubmitted: false,
        assets: {},
    };
};

/**
 * Is Submitting
 * @param {*} state
 * @param {*} action
 */
const isSubmitting = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        isSubmitting: payload,
    };
};

/**
 * Is Saving
 * @param {*} state
 * @param {*} action
 */
const isSaving = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        isSaving: payload,
    };
};

const submitClear = state => {
    return {
        ...state,
        isSubmitted: false,
    };
};

const submitSuccess = state => {
    return {
        ...state,
        isSubmitted: true,
    };
};

const submitError = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        isSubmitted: false,
        errors: payload,
    };
};

/**
 * Clear All Assets
 * @param {*} state
 * @param {*} action
 */
const syncForm = (state, action) => {
    const payload = action.payload.data;

    if (payload.value !== '') {
        return {
            ...state,
            form: {
                ...state.form,
                [payload.field]: payload.value,
            },
        };
    }

    return {
        ...state,
        form: {
            ...state.form,
            [payload.field]: null,
        },
    };
};

/**
 * Clear All Assets
 * @param {*} state
 * @param {*} action
 */
const clearAssets = (state, action) => {
    let assets = {};

    if (!assets['sc-wl-main-color']) {
        assets['sc-wl-main-color'] = {
            namespace: 'sc-wl-main-color',
            content: '#f9bb00',
            filename: null,
        };
    }

    if (!assets['sc-wl-text-color']) {
        assets['sc-wl-text-color'] = {
            namespace: 'sc-wl-text-color',
            content: '#ffffff',
            filename: null,
        };
    }

    return {
        ...state,
        hasStarted: false,
        assets: {
            ...assets,
        },
    };
};

/**
 * Sync All Assets
 * @param {*} state
 * @param {*} action
 */
const syncAllAssets = (state, action) => {
    const payload = action.payload.data;
    let elements = {};

    for (let i = 0; i < payload.length; i++) {
        const asset = payload[i];

        if (asset.assetschema) {
            const namespace = asset.assetschema.namespace;

            if (namespace.indexOf('sc-wl-') !== -1) {
                let content = asset.content;
                let isHabit = false;

                if (
                    namespace === 'sc-wl-ios-bundle-id' &&
                    content.indexOf('io.habit.ios.') !== -1
                ) {
                    content = content.replace('io.habit.ios.', '');
                    isHabit = true;
                }

                if (
                    namespace === 'sc-wl-android-bundle-id' &&
                    content.indexOf('io.habit.android.') !== -1
                ) {
                    content = content.replace('io.habit.android.', '');
                    isHabit = true;
                }

                if (content.indexOf('secret://') !== -1) {
                    content = content.replace('.enc', '');
                }

                let final = null;
                if (asset.assetschema.type === 'file' || asset.assetschema.type === 'image') {
                    final = getFilename(content);
                    // const split = content.split("/");
                    // const last = split.pop();
                    // const sub = last.substring(0, last.indexOf("_") + 1);
                    // const temp = last.split(sub);
                    // final = temp.pop();
                }

                elements[namespace] = {
                    id: asset.id,
                    namespace: namespace,
                    content: content,
                    filename: final,
                    isHabit: isHabit,
                };
            }
        }
    }

    if (!elements['sc-wl-main-color']) {
        elements['sc-wl-main-color'] = {
            namespace: 'sc-wl-main-color',
            content: '#f9bb00',
            filename: null,
        };
    }

    if (!elements['sc-wl-text-color']) {
        elements['sc-wl-text-color'] = {
            namespace: 'sc-wl-text-color',
            content: '#ffffff',
            filename: null,
        };
    }

    return {
        ...state,
        hasStarted: payload.length > 0 ? true : false,
        assets: {
            ...elements,
        },
    };
};

/**
 * Sync Assets by step
 * @param {*} state
 * @param {*} action
 */
const syncAssetsByStep = (state, action) => {
    const payload = action.payload.data;
    let elements = {};

    for (let i = 0; i < payload.elements.length; i++) {
        if (payload.elements[i].assetschema) {
            const namespace = payload.elements[i].assetschema.namespace;

            if (namespace.indexOf('sc-wl-') !== -1) {
                elements[namespace] = {
                    id: payload.elements[i].id,
                    namespace: namespace,
                    content: payload.elements[i].content,
                };
            }
        }
    }

    // const test = payload.filter(el => el.assetschema).map(el => {
    //     if (el.assetschema) {
    //         const namespace = el.assetschema.namespace;

    //         if (namespace.indexOf('sc-wl-') !== -1) {
    //             return {
    //                 id: el.id,
    //                 namespace: namespace,
    //                 content: el.content
    //             }
    //         }
    //     }

    //     return null;
    // })

    // console.log(test)

    return {
        ...state,
        assets: {
            ...state.assets,
            ...elements,
        },
    };
};

export default reducer;
