import { getEndpoints } from 'services/localStorage';
import routes from './routes';
import { apiGetAuthHeader, apiHandleRequest } from 'services/api';

export const assetFileUpload = async data => {
    const endpoints = getEndpoints();
    const url = routes.assetUpload.replace(':id', data.id);

    const formData = new FormData();
    formData.append('assetschema_namespace', data.namespace);
    formData.append('content', data.content);

    const request = {
        method: 'post',
        baseURL: endpoints.uploader,
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'multipart/form-data',
        },
        data: formData,
    };

    return await apiHandleRequest(request);
};

export const assetFieldUpdate = async data => {
    const endpoints = getEndpoints();
    const url = routes.assetUpload.replace(':id', data.id);

    const request = {
        method: 'post',
        baseURL: endpoints.http,
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            assetschema_namespace: data.namespace,
            content: data.content,
        },
    };

    return await apiHandleRequest(request);
};

export const deleteAsset = async data => {
    const endpoints = getEndpoints();
    const url = routes.assetsDetete.replace(':id', data.id).replace(':asset', data.asset);

    const request = {
        method: 'delete',
        baseURL: endpoints.http,
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const downloadAsset = async data => {
    const endpoints = getEndpoints();
    const url = routes.assetsDownload.replace(':id', data.id);

    const request = {
        method: 'post',
        baseURL: endpoints.http,
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            url: data.url,
            path: data.path,
            confirmation_hash: data.confirmation_hash,
        },
    };

    return await apiHandleRequest(request);
};

export const downloadAssetBulkSWR = async (key, id, confirmation_hash, raw) => {
    const endpoints = getEndpoints();
    const url = routes.assetsDownloadBulk.replace(':id', id);

    const assets = raw.map(a => {
        let path = a.uri.split('?');
        if (path[0].indexOf('/distributors/') !== -1) {
            path = path[0].split('/distributors/');
            path = '/distributors/' + path[1];
        } else if (path[0].indexOf('/applications/') !== -1) {
            path = path[0].split('/applications/');
            path = '/applications/' + path[1];
        }

        return {
            url: a.uri,
            path: path,
            expires_in: 3600, // 1 hour
            // expires_in: 660, // 11 min
            // expires_in: 6, // 6 seconds
        };
    });

    const request = {
        method: 'post',
        baseURL: endpoints.http,
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            confirmation_hash: confirmation_hash,
            assets: assets,
        },
    };

    return await apiHandleRequest(request);
};
