import withAuth from 'hoc/withAuth';
import { lazy } from 'react';
import { roles } from 'services/roles';

const InvitesPage = lazy(() => import('redux/containers/Sellers/Invites'));
const LeadsGeneratorsPage = lazy(() => import('pages/Sellers/LeadsGenerators/index'));
const LeadsGeneratorPointsPage = lazy(() => import('pages/Sellers/LeadsGeneratorsPoints/index'));

export const sellersRoutes = [
    {
        id: 'route-sellers-invites',
        path: ['/sellers/invites'],
        exact: true,
        header: {
            visible: true,
            section: 'sellers',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'sellers',
        },
        component: withAuth(InvitesPage),
        'data-test': 'RouteSellersInvitesPage',
        scope: [roles.SELLER, roles.DISTRIBUTORSELLER],
    },
    {
        id: 'route-sellers-leads-generators',
        path: ['/sellers/leads-generators'],
        exact: true,
        header: {
            visible: true,
            section: 'sellers',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'sellers',
        },
        component: withAuth(LeadsGeneratorsPage),
        'data-test': 'RouteSellersLeadsPage',
        scope: [roles.SELLER /* TODO- UNCOMMENT ON NEXT VERSION , roles.DISTRIBUTORSELLER */],
    },
    {
        id: 'route-sellers-leads-generators-points',
        path: ['/sellers/leads-generators-points'],
        exact: true,
        header: {
            visible: true,
            section: 'sellers',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'sellers',
        },
        component: withAuth(LeadsGeneratorPointsPage),
        'data-test': 'RouteSellersLeadsGeneratorsPage',
        scope: [roles.SELLER /* TODO- UNCOMMENT ON NEXT VERSION , roles.DISTRIBUTORSELLER */],
    },
];
