import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './Toggle.module.scss';

export class Toggle extends React.PureComponent {
    static propTypes = {
        max: PropTypes.number,
        value: PropTypes.number,
        state: PropTypes.oneOf(['on', 'off', 'disabled']),
        onToggleOff: PropTypes.func,
        onToggleOn: PropTypes.func,
        onChange: PropTypes.func,
        texts: PropTypes.object,
    };

    static defaultProps = {
        max: 100,
        value: 0,
        state: 'off',
    };

    handleOnChange = e => {
        const value = e.target.value;

        if (value <= this.props.max) {
            this.props.onChange(e);
        }

        return false;
    };

    render() {
        const { max, value, state, onToggleOff, onToggleOn } = this.props;

        return (
            <div data-test="Toggle" className={Styles.toggle}>
                <div
                    data-test="ToggleGhost"
                    className={classnames(Styles.ghost, Styles[state])}
                ></div>
                <div
                    data-test="ToggleInputOff"
                    className={classnames(Styles.left, Styles[state])}
                    onClick={onToggleOff}
                >
                    <input type="text" name="off" value="Off" readOnly></input>
                </div>
                <div
                    data-test="ToggleInputOn"
                    className={classnames(Styles.right, Styles[state])}
                    onClick={onToggleOn}
                >
                    <input
                        type="number"
                        name="on"
                        min="0"
                        max={max}
                        step="1"
                        value={value}
                        onChange={this.handleOnChange}
                    />
                    {/* <span data-test="ToggleMinutes" className={Styles.minutes}>min</span> */}
                </div>
            </div>
        );
    }
}

export default Toggle;
