import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en';
import pt from './locales/pt';

const FALLBACK_LANGUAGE = 'pt';
const CURRENT_LANGUAGE = 'en';
const AVAILABLE_LANGUAGES = {
    pt: {
        translation: pt,
    },
    en: {
        translation: en,
    },
};

i18next.use(initReactI18next).use(LanguageDetector).init({
    debug: false,
    fallbackLng: FALLBACK_LANGUAGE,
    resources: AVAILABLE_LANGUAGES,
    lng: CURRENT_LANGUAGE,
});
