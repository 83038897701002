export const hasProjectSelected = state => {
    return state.insurance.filters.projects.selected ? true : false;
};

export const getInsureesTableHeader = state => {
    const header = [
        {
            id: 0,
            name: 'Name',
            field: 'name',
        },
        {
            id: 1,
            name: 'Phone',
            field: 'phone',
        },
        {
            id: 2,
            name: 'Email',
            field: 'email',
        },
    ];

    return header;
};

export const selectedProject = state => {
    return state?.insurance?.filters?.projects?.selected
        ? state.insurance.filters.projects.list.filter(
              p => p.id === state.insurance.filters.projects.selected,
          )[0]
        : null;
};
