import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './BrowserAlert.module.scss';
import Logo from 'assets/images/habit-logo-v2.png';
import Chrome from 'assets/images/browsers/chrome.png';
import Firefox from 'assets/images/browsers/firefox.png';
import Opera from 'assets/images/browsers/opera.png';

export class BrowserAlert extends React.PureComponent {
    state = {
        visible: true,
    };

    static propTypes = {
        texts: PropTypes.object,
    };

    handleOnClick = e => {
        this.setState({ visible: false });
    };

    render() {
        const { visible } = this.state;

        if (!visible) return null;

        return (
            <div className={classnames(Styles.alert)}>
                <div className={Styles.container}>
                    <div className={Styles.logo}>
                        <img src={Logo} alt="Habit Analytics" />
                    </div>

                    <div className={Styles.message}>
                        <h3 data-test="BrowserAlertTitle">ATTENTION!</h3>
                        <p
                            data-test="BrowserAlertMessage"
                            dangerouslySetInnerHTML={{
                                __html: 'This website is not optimized for Internet Explorer. <br/>Please, use a more modern browser.',
                            }}
                        />
                        <p
                            data-test="BrowserAlertMessage"
                            className={Styles.bold}
                            dangerouslySetInnerHTML={{
                                __html: 'Our recomendations: ',
                            }}
                        />
                        <p
                            data-test="BrowserAlertMessage"
                            className={Styles.actions}
                            dangerouslySetInnerHTML={{
                                __html: `<a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer"><img src=${Chrome} alt="Google Chrome" /> Chrome</a> <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noopener noreferrer"><img src=${Firefox} alt="Firefox" /> Firefox</a> <a href="https://www.opera.com/" target="_blank" rel="noopener noreferrer"><img src=${Opera} alt="Opera" /> Opera</a>`,
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default BrowserAlert;
