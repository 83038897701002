import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Styles from './HorizontalFilter.module.scss';

export class HorizontalFilter extends React.PureComponent {
    static propTypes = {
        texts: PropTypes.object,
        data: PropTypes.arrayOf(PropTypes.object),
        selected: PropTypes.number,
        onClick: PropTypes.func,
        label: PropTypes.string,
        labelPosition: PropTypes.oneOf(['left', 'right']),
    };

    static defaultProps = {
        labelPosition: 'left',
    };

    render() {
        const { data, selected, onClick, label } = this.props;

        return (
            <div data-test="HorizontalFilterContainer" className={Styles.container}>
                <p className={Styles.text}>{label}</p>
                <div className={Styles.picker}>
                    {data &&
                        data.map(item => (
                            <>
                                {item.id === selected && (
                                    <div
                                        key={item.id}
                                        data-id={item.id}
                                        className={classnames(Styles.slot, Styles?.active)}
                                        onClick={onClick}
                                    >
                                        {item.text}
                                    </div>
                                )}

                                {item.id !== selected && (
                                    <span
                                        key={item.id}
                                        data-id={item.id}
                                        className={classnames(Styles.slot)}
                                        onClick={onClick}
                                    >
                                        {item.text}
                                    </span>
                                )}
                            </>
                        ))}
                </div>
            </div>
        );
    }
}

export default HorizontalFilter;
