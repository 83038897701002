export const usecases = {
    isSaving: false,
    modal: {
        type: 'modal',
        id: 'UsecaseModal',
        active: false,
        steps: [
            {
                id: 0,
                label: 1,
                text: 'Scope',
            },
            {
                id: 1,
                label: 2,
                text: 'Business Rule',
            },
            {
                id: 2,
                label: 3,
                text: 'In-App Graphics',
            },
        ],
    },
    list: [],
};
