import classnames from 'classnames';
import { getText } from 'localization';
import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from 'shared/Checkbox';
import Popup from 'shared/Popup';
import { getElementPosition } from 'utils/functions';
import Styles from './CheckboxOptions.module.scss';

export class CheckboxOptions extends React.PureComponent {
    state = {
        popup: {
            id: 'dropdown',
            active: false,
            element: {},
            selectedId: -1,
        },
    };

    static propTypes = {
        id: PropTypes.string,
        name: PropTypes.string,
        namespace: PropTypes.string,
        options: PropTypes.array,
        selected: PropTypes.string,
        checked: PropTypes.bool,
        label: PropTypes.string,
        size: PropTypes.oneOf(['xs', 'sm', 'md']),
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        onSelectCheckbox: PropTypes.func,
    };

    handleOnSelect = e => {
        const id = e.currentTarget.getAttribute('data-id');
        if (this.props.name) {
            this.props.onChange({
                name: this.props.name,
                id: id,
                namespace: this.props.namespace ? this.props.namespace : null,
            });
        } else {
            this.props.onChange(id);
        }

        this.handleClosePopup();
    };

    handleTogglePopup = e => {
        e.preventDefault();
        const target = e.currentTarget;

        this.setState(
            state => ({
                popup: {
                    ...state.popup,
                    active: !state.popup?.active,
                    element: getElementPosition(target),
                    selectedId: !state.popup?.active ? -1 : state.popup.selectedId,
                },
            }),
            () => {
                // if (this.state.popup?.active) {
                //     window.addEventListener('keydown', this.handleListCursorKeys, false);
                //     this.focusOnSelectedElement();
                // }
                // if (!this.state.popup?.active) {
                //     window.removeEventListener('keydown', this.handleListCursorKeys, false);
                // }
            },
        );
    };

    handleClosePopup = () => {
        this.setState(state => ({
            popup: {
                ...state.popup,
                active: false,
                selectedId: -1,
            },
        }));
    };

    getContent() {
        const { options, selected } = this.props;

        return (
            <ul>
                {options &&
                    options.length > 0 &&
                    options.map((item, index) => (
                        <li
                            className={
                                selected && item.id.toString() === selected.toString()
                                    ? Styles?.active
                                    : null
                            }
                            key={item.id}
                            data-id={item.id}
                            onClick={this.handleOnSelect}
                        >
                            <p>{item.text}</p>
                        </li>
                    ))}
                {(!options || options.length === 0) && (
                    <li className={Styles.noResults}>{getText('no_results_with_dots')}</li>
                )}
            </ul>
        );
    }

    render() {
        const { id, checked, label, onSelectCheckbox, disabled, size } = this.props;
        const { popup } = this.state;

        return (
            <div
                data-test="CheckboxOptionsContainer"
                className={classnames(Styles.container, Styles[size])}
            >
                <Checkbox
                    data-test="CheckboxOptionsCheckbox"
                    theme="round"
                    variant="light"
                    // name={item.id}
                    onChange={onSelectCheckbox}
                    position="center"
                    checked={checked}
                    disabled={disabled}
                />

                <div className={classnames(Styles.label, Styles[size])}>
                    <i
                        data-test="CheckboxOptionsAction"
                        className="icon-icon_arrow_down"
                        onClick={!disabled ? this.handleTogglePopup : null}
                    ></i>
                    <p
                        data-test="CheckboxOptionsLabel"
                        dangerouslySetInnerHTML={{ __html: label }}
                    />
                </div>

                <Popup
                    data-test="CheckboxOptionsPopup"
                    id={`checkboxPopup-${id || 'temp'}`}
                    // position={position}
                    size="md"
                    active={popup?.active}
                    element={popup.element}
                    closePopup={this.handleClosePopup}
                    // content={this.getContent()}
                    content={popup?.active ? this.getContent() : null}
                />
            </div>
        );
    }
}

export default CheckboxOptions;
