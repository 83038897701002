import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { sendErrorToast } from 'services/toast';
import Loading from 'shared/Loading';
import Styles from './Odometer.module.scss';

const Input = ({ index, data, onChange, onKeyDown, onFocus, onBlur, disabled }) => {
    return (
        <input
            data-index={index}
            type="number"
            step="1"
            min="0"
            max="9"
            value={data}
            onChange={onChange}
            placeholder="0"
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={disabled}
            pattern="[0–9]"
            onKeyDown={onKeyDown}
        />
    );
};
Input.propTypes = {
    isLoading: PropTypes.bool,
    index: PropTypes.number,
    data: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    disabled: PropTypes.bool,
};

export class Odometer extends React.PureComponent {
    state = {
        value: ['', '', '', '', '', '', ''],
        focus: false,
        canSave: true,
        disabled: true,
        editable: false,
    };

    static propTypes = {
        isLoading: PropTypes.bool,
        data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        digits: PropTypes.number,
        onSave: PropTypes.func,
        disabled: PropTypes.bool,
        canSave: PropTypes.bool,
        onDemand: PropTypes.bool,
        saveText: PropTypes.string,
        distanceId: PropTypes.string,
    };

    static defaultProps = {
        digits: 7,
        onDemand: false,
    };

    componentDidMount() {
        ReactTooltip.rebuild();

        if (this.props.data || this.props.data === '') {
            let split = this.props.data.toString().split('');
            if (split.length < this.props.digits) {
                const rest = this.props.digits - split.length;
                for (let i = 0; i < rest; i++) {
                    split = ['', ...split];
                }
            }
            this.setState((state, props) => ({
                ...state,
                value: split,
                disabled: !props.disabled ? false : true,
                canSave: !props.onDemand ? true : false,
            }));
        }

        if (this.props.data === null) {
            this.setState((state, props) => ({
                ...state,
                disabled: true,
            }));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        ReactTooltip.rebuild();

        if (prevProps.data && prevProps.data !== this.props.data) {
            let split = this.props.data.toString().split('');
            if (split.length < this.props.digits) {
                const rest = this.props.digits - split.length;
                for (let i = 0; i < rest; i++) {
                    split = ['', ...split];
                }
            }
            this.setState((state, props) => ({
                ...state,
                value: split,
                disabled: !props.disabled ? false : true,
                canSave: !props.onDemand ? true : false,
            }));
        }
    }

    handleOnMouseEnter = () => {
        if (this.props.onDemand) {
            this.setState((state, props) => ({
                editable: true,
            }));
        }
    };

    handleOnMouseLeave = () => {
        if (this.props.onDemand) {
            this.setState((state, props) => ({
                editable: false,
            }));
        }
    };

    handleOnKeyDown = e => {
        if (e.keyCode >= 48 && e.keyCode <= 57) {
            const value = e.key;
            const valueIndex = e.target.getAttribute('data-index');
            const newValues = this.state.value.map((val, index) => {
                if (index.toString() === valueIndex.toString()) {
                    return value !== '' ? value.replace(/[^\d.]/g, '') : val !== '' ? '0' : '';
                }

                return val;
            });

            this.setState(
                (state, props) => ({
                    ...state,
                    value: [...newValues],
                }),
                () => {},
            );
        }
    };

    handleOnChange = e => {
        const value = e.target.value;
        const valueIndex = e.target.getAttribute('data-index');
        const newValues = this.state.value.map((val, index) => {
            if (index.toString() === valueIndex.toString()) {
                return value !== '' && value !== 'e'
                    ? value[0].replace(/[^\d.]/g, '')
                    : val !== ''
                    ? val
                    : '';
            }

            return val;
        });

        this.setState(
            (state, props) => ({
                ...state,
                value: [...newValues],
            }),
            () => {
                if (this.props.onDemand) {
                    this.handleValidation();
                }
            },
        );
    };

    handleOnFocus = () => {
        this.setState((state, props) => ({
            ...state,
            focus: true,
        }));
    };

    handleOnBlur = () => {
        this.setState((state, props) => ({
            ...state,
            focus: false,
        }));
    };

    handleValidation = () => {
        const currentValue = this.state.value.join('');
        this.setState((state, props) => ({
            ...state,
            canSave: props.data !== currentValue ? true : false,
        }));
    };

    handleOnSave = () => {
        const value = this.state.value.join('');

        if (parseInt(value) <= parseInt(this.props.data)) {
            return sendErrorToast('Odometer value must be higher then the previous report.');
        }

        this.props.onSave({ value: value, distanceId: this.props.distanceId });
        ReactTooltip.hide();
    };

    render() {
        const { isLoading, saveText, distanceId } = this.props;
        const { value, focus, canSave, disabled, editable } = this.state;

        return (
            <div data-test="OdometerContainer" className={Styles.container}>
                <div
                    className={classnames(
                        Styles.digits,
                        focus ? Styles?.active : null,
                        disabled ? Styles.disabled : null,
                        editable ? Styles.editable : null,
                    )}
                >
                    {value &&
                        value.map((val, index) => (
                            <span key={index}>
                                <Input
                                    data-test="OdometerInput"
                                    data-distance-id={distanceId}
                                    data={val}
                                    index={index}
                                    onChange={this.handleOnChange}
                                    onFocus={this.handleOnFocus}
                                    onBlur={this.handleOnBlur}
                                    onKeyDown={this.handleOnKeyDown}
                                    disabled={disabled || isLoading}
                                    // onMouseEnter={this.handleOnMouseEnter}
                                    // onMouseLeave={this.handleonMouseLeave}
                                />
                            </span>
                        ))}
                </div>
                <div className={Styles.actions}>
                    {isLoading && <Loading variant="xxs" />}

                    {!isLoading && !canSave && (
                        <i
                            className={classnames(
                                'icon-icon_edit',
                                disabled ? Styles.hidden : null,
                            )}
                        ></i>
                    )}

                    {!isLoading && canSave && !disabled && (
                        <i
                            data-test="OdometerSave"
                            data-tip={saveText ? saveText : 'Approve'}
                            className={classnames('icon-icon_save', Styles.save)}
                            onClick={this.handleOnSave}
                        ></i>
                    )}
                </div>
            </div>
        );
    }
}

export default Odometer;
