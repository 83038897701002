// export const fakeData = [
//     {
//         country: 'AD',
//         'hot dog': 72,
//         'hot dogColor': 'hsl(156, 70%, 50%)',
//         burger: 28,
//         burgerColor: 'hsl(100, 70%, 50%)',
//     },
//     {
//         country: 'AE',
//         'hot dog': 1,
//         'hot dogColor': 'hsl(203, 70%, 50%)',
//         burger: 99,
//         burgerColor: 'hsl(247, 70%, 50%)',
//     },
//     {
//         country: 'AF',
//         'hot dog': 67,
//         'hot dogColor': 'hsl(293, 70%, 50%)',
//         burger: 33,
//         burgerColor: 'hsl(252, 70%, 50%)',
//     },
// ];

export const fakeData = [
    {
        type: 'overview',
        'Crash detected': 15,
        field1Label: 'Crash detected',
        'Highway driver': 45,
        field2Label: 'Highway driver',
        'Legal driver': 5,
        field3Label: 'Legal driver',
        'Smooth driver': 25,
        field4Label: 'Smooth driver',
        'Short commuter': 10,
        field5Label: 'Short commuter',
    },
];
