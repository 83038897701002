import { mocks as PopulationSegmentationMocks } from 'components/Analytics/PopulationSegmentation/Filters/__MOCKS__';
import { mocks as UsersVisitedPlacesMocks } from 'components/Analytics/UsersVisitedPlaces/Filters/__MOCKS__';

export const analytics = {
    filters: {
        projects: null,
        dates: null,
    },
    userFacts: {
        isLoadingScore: false,
        stats: [
            {
                id: 0,
                type: 'users',
                title: 'Total users joined',
                number: '--',
                last_month: '--',
                description: '-- from the last month',
            },
            {
                id: 1,
                type: 'location',
                title: 'Nº of Location Events',
                number: '--',
                description: 'Average period with location events',
            },
            {
                id: 2,
                type: 'score',
                title: 'Score',
                number: 0,
                description: 'Location events registered per user',
            },
            {
                id: 3,
                type: 'countries',
                title: 'Nº of Countries',
                number: '--',
                description: null,
                countries: [],
            },
        ],
        map: [],
        chartInterval: null,
        chart: [
            {
                id: 'Users',
                data: [],
                dataEvents: [],
                dataUsers: [],
                max: 0,
            },
        ],
    },
    populationSegmentation: {
        filters: PopulationSegmentationMocks,
        compare: {
            type: 'modal',
            id: 'CompareModal',
            active: false,
        },
    },
    usersVisitedPlaces: {
        filters: UsersVisitedPlacesMocks,
        // data: TimelineMocks,
        data: null,
        user: null,
        error: null,
        alert: false,
    },
    userRawLocationData: {
        data: null,
        user: null,
        error: null,
        alert: false,
    },
};
