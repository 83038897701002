import ActionTypes from 'redux/actionTypes';

export const toggleModalAction = data => ({
    type: ActionTypes.TEAM_MEMBERS_TOGGLE_MODAL,
    payload: {
        data,
    },
});

export const addMemberAction = data => ({
    type: ActionTypes.TEAM_MEMBERS_ADD_MEMBER,
    payload: {
        data,
    },
});

export const addMemberErrorAction = data => ({
    type: ActionTypes.TEAM_MEMBERS_ADD_MEMBER_ERROR,
    payload: {
        data,
    },
});

export const addMemberClearErrorAction = () => ({
    type: ActionTypes.TEAM_MEMBERS_ADD_MEMBER_CLEAR_ERROR,
});

export const editMemberAction = data => ({
    type: ActionTypes.TEAM_MEMBERS_EDIT_MEMBER,
    payload: {
        data,
    },
});

export const deleteMemberAction = data => ({
    type: ActionTypes.TEAM_MEMBERS_DELETE_MEMBER,
    payload: {
        data,
    },
});

export const resumeSuspendMemberAction = data => ({
    type: ActionTypes.TEAM_MEMBERS_RESUME_SUSPEND_MEMBER,
    payload: {
        data,
    },
});

export const syncTeamMembersIdAction = data => ({
    type: ActionTypes.TEAM_MEMBERS_ID,
    payload: {
        data,
    },
});

export const syncTeamMembersDataAction = data => ({
    type: ActionTypes.TEAM_MEMBERS_DATA,
    payload: {
        data,
    },
});

export const syncTeamMembersAction = data => ({
    type: ActionTypes.TEAM_MEMBERS_FETCH,
    payload: {
        data,
    },
});
