import PropTypes from 'prop-types';
import React from 'react';
import CheckboxOptions from 'shared/CheckboxOptions';

import { getText } from 'localization';
import Search from 'shared/Search';
import { NETWORK_ENTITIES_SELECT_OPTIONS } from 'utils/constants';
import Styles from './SubHeader.module.scss';
export class SubHeader extends React.PureComponent {
    state = {
        search: '',
        checked: false,
        selectOptions: {
            list: NETWORK_ENTITIES_SELECT_OPTIONS,
            selected: null,
        },
    };

    static propTypes = {
        texts: PropTypes.object,
        search: PropTypes.string,
        onSearch: PropTypes.func,
        onClear: PropTypes.func,
        onSelectCheckbox: PropTypes.func,
        entities: PropTypes.array,
        selectState: PropTypes.object,
        isLoading: PropTypes.bool,
        isSaving: PropTypes.bool,
        readOnly: PropTypes.bool,
        fetchData: PropTypes.func,
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectState !== this.props.selectState) {
            const { directories, selected } = this.props.selectState || {};
            const prevDirectories = prevProps.selectState?.directories || [];
            const currentDirectories = directories || [];
            const newDirectories =
                JSON.stringify(prevDirectories) !== JSON.stringify(currentDirectories)
                    ? currentDirectories
                    : [];
            this.setState(state => ({
                ...state,
                selectOptions: {
                    ...state.selectOptions,
                    list: [...state.selectOptions.list, ...newDirectories],
                    selected: selected,
                },
                checked: selected === 'all',
            }));
        }
    }

    handleOnChangeVisibles = value => {
        const checked = value === 'all' ? true : false;
        const type = value === 'all' || value === 'none' ? checked : value;

        this.setState(
            state => ({
                ...state,
                selectOptions: {
                    ...state.selectOptions,
                    selected: value,
                },
                checked: checked,
            }),
            () => {
                this.props.onSelectCheckbox(null, { value: type });
            },
        );
    };

    handleOnChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            {
                [name]: value,
            },
            () => {
                this.handleOnSearch();

                if (value === '') {
                    this.props.onClear();
                }
            },
        );
    };

    handleOnSearch = () => {
        this.props.onSearch(this.state.search);
    };

    handleOnSubmit = e => {
        if (e) {
            e.preventDefault();
            this.handleOnSearch();
        }
    };

    handleOnClear = () => {
        this.setState(
            {
                search: '',
            },
            () => {
                this.props.onClear();
            },
        );
    };

    render() {
        const {
            onSearch,
            onClear,
            search,
            onSelectCheckbox,
            selectState,
            isLoading,
            isSaving,
            readOnly,
            fetchData,
        } = this.props;
        const { selectOptions, checked } = this.state;

        return (
            <div data-test="SubHeaderContainer" className={Styles.container}>
                <div className={Styles.select}>
                    <CheckboxOptions
                        data-test="SubHeaderCheckboxOptions"
                        options={selectOptions.list}
                        selected={selectOptions.selected}
                        checked={checked}
                        onChange={this.handleOnChangeVisibles}
                        onSelectCheckbox={onSelectCheckbox}
                        label={`${getText(
                            'selfcare_network_section_visible_entities_label',
                        )} <span>${selectState.actives}</span>`}
                        disabled={isLoading || isSaving || readOnly}
                    />
                </div>

                <div className={Styles.search}>
                    <Search
                        data-test="SubHeaderSearch"
                        autoFocus
                        name="search"
                        theme="primary"
                        variant="outlined"
                        size="sm"
                        placeholder={getText('search_with_dots')}
                        onSearch={str => fetchData(str)}
                        onConfirm={str => fetchData(str)}
                        onClear={onClear}
                        onChange={onSearch}
                        value={search}
                    />
                </div>
            </div>
        );
    }
}

export default SubHeader;
