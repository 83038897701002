import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import appReducer from 'redux/domains/app/reducer';
import navigationReducer from 'redux/domains/navigation/reducer';
import boardReducer from 'redux/domains/board/reducer';
import flowTesterReducer from 'redux/domains/flowTester/reducer';
import eventsSimulatorReducer from 'redux/domains/eventsSimulator/reducer';
import integrationReducer from 'redux/domains/integration/reducer';
import integrationsReducer from 'redux/domains/integrations/reducer';
import projectReducer from 'redux/domains/project/reducer';
import projectsReducer from 'redux/domains/projects/reducer';
import textsReducer from 'redux/domains/texts/reducer';
import userReducer from 'redux/domains/user/reducer';
import sellersReducer from 'redux/domains/sellers/reducer';
import successRateIntervalsReducer from 'redux/domains/successRateIntervals/reducer';
import teamMembersReducer from 'redux/domains/teamMembers/reducer';
import promptReducer from 'redux/domains/prompt/reducer';
import endUsersReducer from 'redux/domains/endUsers/reducer';
import eventsReducer from 'redux/domains/events/reducer';
import whiteLabelReducer from 'redux/domains/whiteLabel/reducer';
import buildsReducer from 'redux/domains/builds/reducer';
import compatibleDevicesReducer from 'redux/domains/compatibleDevices/reducer';
import analyticsReducer from 'redux/domains/analytics/reducer';
import usecasesReducer from 'redux/domains/usecases/reducer';
import insuranceReducer from 'redux/domains/insurance/reducer';
import networksReducer from 'redux/domains/networks/reducer';
import templatesReducer from 'redux/domains/templates/reducer';
import breReducer from 'redux/domains/businessRuleEngine/reducer';
import distributorsReducer from 'redux/domains/distributors/reducer';
import providerReducer from 'redux/domains/providers/reducer';
import adminReducer from 'redux/domains/admin/reducer';

// eslint-disable-next-line import/no-anonymous-default-export
export default history =>
    combineReducers({
        router: connectRouter(history),
        admin: adminReducer,
        analytics: analyticsReducer,
        app: appReducer,
        board: boardReducer,
        builds: buildsReducer,
        businessRuleEngine: breReducer,
        compatibleDevices: compatibleDevicesReducer,
        distributors: distributorsReducer,
        providers: providerReducer,
        endUsers: endUsersReducer,
        events: eventsReducer,
        eventsSimulator: eventsSimulatorReducer,
        flowTester: flowTesterReducer,
        insurance: insuranceReducer,
        integration: integrationReducer,
        integrations: integrationsReducer,
        navigation: navigationReducer,
        networks: networksReducer,
        project: projectReducer,
        projects: projectsReducer,
        prompt: promptReducer,
        sellers: sellersReducer,
        successRateIntervals: successRateIntervalsReducer,
        teamMembers: teamMembersReducer,
        templates: templatesReducer,
        texts: textsReducer,
        usecases: usecasesReducer,
        user: userReducer,
        whiteLabel: whiteLabelReducer,
    });
