import { getText } from 'localization';
import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';
import Button from 'shared/Button';
import Dropdown from 'shared/Dropdown';
import ErrorBoundary from 'shared/ErrorBoundary';
import Input from 'shared/Input';
import { INSURANCE_PAYMENTS_STATUS_LIST } from 'utils/constants';
import { cleanMaskedBirthForSubmit, cleanMaskedForSubmit } from 'utils/functions';
import Styles from './AdvancedSearch.module.scss';

export class AdvancedSearch extends React.PureComponent {
    state = {
        overlayId: 'advancedSearchOverlay',
        containerId: 'advancedSearchContainer',
        canSearch: false,
        state: {
            selected: null,
            list: INSURANCE_PAYMENTS_STATUS_LIST,
        },
        fields: {
            policy_code: '',
            state: '',
            start_date: '',
        },
    };

    static propTypes = {
        texts: PropTypes.object,
        isLoading: PropTypes.bool,
        data: PropTypes.object,
        onAdvancedSearchSubmit: PropTypes.func,
        onClear: PropTypes.func,
        onToggle: PropTypes.func,
    };

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, false);

        if (
            this.props.data &&
            this.props.data.fields &&
            Object.entries(this.props.data).length > 0 &&
            Object.entries(this.props.data.fields).length > 0
        ) {
            this.syncPropsToState();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, false);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.data &&
            this.props.data &&
            prevProps.data.fields &&
            this.props.data.fields &&
            Object.entries(this.props.data).length > 0 &&
            Object.entries(this.props.data.fields).length > 0 &&
            prevProps.data.fields !== this.props.data.fields
        ) {
            this.syncPropsToState();
        }
    }

    syncPropsToState = () => {
        this.setState(
            state => ({
                fields: { ...this.props.data.fields },
                state: {
                    ...state.state,
                    selected: this.props.data.fields?.state
                        ? this.props.data.fields.state.data
                        : this.props.data.fields?.state
                        ? this.props.data.fields.state.data
                        : null,
                },
            }),
            () => {
                this.handleValidation();
            },
        );
    };

    handleClickOutside = ({ target }) => {
        if (target) {
            const { overlayId, containerId } = this.state;
            const specifiedElement = document.getElementById(containerId);
            const targetId = target.id;
            const isClickInside = specifiedElement.contains(target);

            if (targetId && targetId === overlayId && !isClickInside) {
                this.props.onToggle();
            }
        }
    };

    handleOnChange = e => {
        const target = e.target;
        const value = target.type && target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            state => ({
                fields: {
                    ...state.fields,
                    [name]: value,
                },
            }),
            () => {
                this.handleValidation();
            },
        );
    };

    handleOnChangeDropdown = data => {
        if (!data.id) {
            const newState = Object.keys(this.state.fields).reduce((object, key) => {
                if (key !== data.name) {
                    object[key] = this.state.fields[key];
                }
                return object;
            }, {});

            this.setState(
                state => ({
                    [data.name]: {
                        ...state[data.name],
                        selected: data.id,
                    },
                    fields: {
                        ...newState,
                    },
                }),
                () => {
                    this.handleValidation();
                    this.handleOnSubmit();
                },
            );
        } else {
            this.setState(
                state => ({
                    [data.name]: {
                        ...state[data.name],
                        selected: data.id,
                    },
                    fields: {
                        ...state.fields,
                        [data.name]: data.id,
                    },
                }),
                () => {
                    this.handleValidation();
                },
            );
        }
    };

    handleOnClear = () => {
        this.setState(
            state => ({
                canSearch: false,
                fields: {
                    policy_code: '',
                    state: '',
                    start_date: '',
                },
                state: {
                    ...state.state,
                    selected: null,
                },
            }),
            () => {
                this.props.onClear();
            },
        );
    };

    handleOnClearSingle = e => {
        const target = e.target;
        const name = target.getAttribute('data-name');

        this.setState(
            state => ({
                fields: {
                    ...state.fields,
                    [name]: '',
                },
            }),
            () => {
                this.handleValidation();
                this.handleOnSubmit();
            },
        );
    };

    handleOnSubmit = e => {
        if (e) {
            e.preventDefault();
        }

        const map = {
            identifier: ['io.habit.operations.fields.identifier'],
        };
        let fields = {
            start_date: cleanMaskedBirthForSubmit(this.state.fields.start_date),
            policy_code: cleanMaskedForSubmit(this.state.fields.policy_code),
        };

        switch (this.state.fields.state) {
            case 'received':
            case 'sent_for_settle':
            case 'settled':
                fields = {
                    ...fields,
                    state: {
                        namespace: 'state',
                        type: 'equals',
                        data: 'paid',
                    },
                    // state: {
                    //     namespace: 'state',
                    //     type: 'equals',
                    //     data: this.state.fields.state,
                    // },
                };
                break;
            default:
                fields = {
                    ...fields,
                    state: {
                        namespace: 'state',
                        type: 'equals',
                        data: this.state.fields.state,
                    },
                };
        }

        this.props.onAdvancedSearchSubmit({
            map: map,
            fields: fields,
            closeSearch: e ? true : false,
        });
    };

    handleValidation = () => {
        let hasSearchField = false;
        Object.entries(this.state.fields).forEach(([key, value]) => {
            if (value !== '') {
                hasSearchField = true;
            }
        });

        if (!hasSearchField) {
            this.setState({ canSearch: false });
        } else {
            this.setState({ canSearch: true });
        }
    };

    render() {
        const { isLoading } = this.props;
        const { canSearch, fields, state, overlayId, containerId } = this.state;

        return (
            <ErrorBoundary>
                <div id={overlayId} className={Styles.overlay}>
                    <section
                        id={containerId}
                        data-test="AdvancedSearchContainer"
                        className={Styles.advancedSearch}
                    >
                        <form autoComplete="off" onSubmit={this.handleOnSubmit}>
                            <div className={Styles.row}>
                                <div className={Styles.col}>
                                    <p className={Styles.fieldTitle}>
                                        {getText('common_policy_number')}
                                    </p>
                                    <InputMask
                                        data-test="Input"
                                        mask="aaa-999"
                                        maskPlaceholder="_"
                                        value={
                                            fields.policy_code && fields.policy_code !== ''
                                                ? fields.policy_code.toUpperCase()
                                                : ''
                                        }
                                        onChange={this.handleOnChange}
                                        disabled={isLoading}
                                    >
                                        <Input
                                            type="text"
                                            namespace=""
                                            name="policy_code"
                                            theme="ternary"
                                            variant="outlined"
                                            state={
                                                fields.policy_code && fields.policy_code !== ''
                                                    ? 'active'
                                                    : null
                                            }
                                            placeholder="JAD-346"
                                            value={
                                                fields.policy_code && fields.policy_code !== ''
                                                    ? fields.policy_code.toUpperCase()
                                                    : ''
                                            }
                                            disabled={isLoading}
                                            onChange={this.handleOnChange}
                                            onClear={this.handleOnClearSingle}
                                        />
                                    </InputMask>
                                </div>

                                <div className={Styles.col}>
                                    <p className={Styles.fieldTitle}>{getText('common_status')}</p>
                                    <Dropdown
                                        id="paymentStatus"
                                        data={state.list}
                                        selected={state.selected}
                                        position="bottom"
                                        onChange={this.handleOnChangeDropdown}
                                        name="state"
                                        theme="select"
                                        variant="outlined"
                                        state={state.selected ? 'active' : null}
                                        block
                                        hasEmptyState
                                        placeholder={getText('common_select_label')}
                                    />
                                </div>
                            </div>

                            <div className={Styles.row}>
                                <div className={Styles.col}>
                                    <p className={Styles.fieldTitle}>{getText('common_date')}</p>
                                    <InputMask
                                        mask="9999-99-99"
                                        maskPlaceholder="_"
                                        value={
                                            fields.start_date && fields.start_date !== ''
                                                ? fields.start_date
                                                : ''
                                        }
                                        onChange={this.handleOnChange}
                                        disabled={isLoading}
                                    >
                                        <Input
                                            type="tel"
                                            namespace=""
                                            name="start_date"
                                            theme="ternary"
                                            variant="outlined"
                                            state={
                                                fields.start_date && fields.start_date !== ''
                                                    ? 'active'
                                                    : null
                                            }
                                            placeholder="YYYY-MM-DD"
                                            value={
                                                fields.start_date && fields.start_date !== ''
                                                    ? fields.start_date
                                                    : ''
                                            }
                                            disabled={isLoading}
                                            onChange={this.handleOnChange}
                                            onClear={this.handleOnClearSingle}
                                        />
                                    </InputMask>
                                </div>
                            </div>

                            <div className={Styles.actions}>
                                <div className={Styles.col}>
                                    <Button
                                        data-test="AdvancedSearchClear"
                                        theme="ternary"
                                        variant="outlined"
                                        type="button"
                                        value={getText('common_clear')}
                                        disabled={isLoading || !canSearch}
                                        onClick={this.handleOnClear}
                                        icon="icon_refresh"
                                    />
                                    <Button
                                        data-test="AdvancedSearchSubmit"
                                        theme="ternary"
                                        variant="filled"
                                        type="submit"
                                        value={getText('common_search')}
                                        disabled={isLoading || !canSearch}
                                        icon="icon_search"
                                    />
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
            </ErrorBoundary>
        );
    }
}

export default AdvancedSearch;
