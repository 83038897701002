// import { fakeInsurance } from '__MOCKS__/providers/insurance';
// import { fakeDistributors } from '__MOCKS__/providers/providers';

import { getText } from 'localization';
import { MY_PROVIDERS_STATUS_LIST } from 'utils/constants';

export const providers = {
    isLoading: false,
    isSaving: false,
    insurance: {
        // list: fakeInsurance,
        list: [],
        modal: {
            type: 'modal',
            id: 'AddInsuranceModal',
            active: false,
            selectors: {
                insurer: {
                    list: [],
                    selected: null,
                },
                segment: {
                    list: [],
                    selected: null,
                },
                country: {
                    list: [],
                    selected: null,
                },
            },
            selected: ['a1459a0f-3d54-4282-8a9a-b81aeeddb341'],
            list: [],
            header: [
                {
                    id: 0,
                    name: 'Insurance',
                    field: 'name',
                    isSortable: false,
                    hasIcon: true,
                    hasCheckbox: true,
                },
                {
                    id: 1,
                    name: 'Product',
                    field: 'product',
                    isSortable: false,
                    hasIcon: null,
                },
                {
                    id: 2,
                    name: 'Segment',
                    field: 'segment',
                    isSortable: false,
                    hasIcon: null,
                },
                {
                    id: 3,
                    name: 'Country',
                    field: 'country',
                    isSortable: false,
                    hasIcon: null,
                },
            ],
            pagination: {
                current: '--',
                total: '--',
                next: false,
                page_start_index: 0,
            },
            filters: {
                field: null,
                direction: 'desc',
            },
            chunk: 15,
        },
    },
    api: {
        credentials: null,
        list: [],
    },
    myProviders: {
        selectors: {
            status: {
                //TODO: look at constant
                list: MY_PROVIDERS_STATUS_LIST,
                selected: null,
            },
            segment: {
                list: [],
                selected: null,
            },
            country: {
                list: [],
                selected: null,
            },
        },
        // list: fakeDistributors,
        list: [],
        modal: {
            type: 'modal',
            id: 'RequestModal',
            active: false,
            data: null,
        },
        header: [
            {
                id: 0,
                name: getText('distributors_distributor_label'),
                field: 'name',
                isSortable: false,
                hasIcon: true,
                hasCheckbox: true,
            },
            {
                id: 1,
                name: getText('distributors_segment_label'),
                field: 'segment',
                isSortable: false,
                hasIcon: null,
            },
            {
                id: 2,
                name: getText('distributors_product_label'),
                field: 'product',
                isSortable: false,
                hasIcon: null,
            },
            {
                id: 3,
                name: getText('distributors_country_label'),
                field: 'country',
                isSortable: false,
                hasIcon: null,
            },
        ],
        pagination: {
            current: '--',
            total: '--',
            next: false,
            page_start_index: 0,
        },
        filters: {
            field: null,
            direction: 'desc',
        },
        chunk: 15,
    },
};
