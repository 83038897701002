export const analyticsUserFactsViewsModel = (state, views) => {
    const stats = state.userFacts.stats.map(stat => {
        switch (stat.type) {
            case 'users':
                return {
                    ...stat,
                    number:
                        views?.total_users_created_view?.data &&
                        views.total_users_created_view.data.hasOwnProperty('total')
                            ? views.total_users_created_view.data.total === null
                                ? '--'
                                : views.total_users_created_view.data.total
                            : '--',
                    last_month:
                        views?.total_users_created_view?.data &&
                        views.total_users_created_view.data.hasOwnProperty('total_last_month')
                            ? views.total_users_created_view.data.total_last_month === null
                                ? '--'
                                : `${
                                      views.total_users_created_view.data.total_last_month > 0
                                          ? '+'
                                          : ''
                                  }${views.total_users_created_view.data.total_last_month}`
                            : '--',
                };
            case 'location':
                return {
                    ...stat,
                    number:
                        views?.total_location_events_view?.data &&
                        views.total_location_events_view.data.hasOwnProperty('total')
                            ? views.total_location_events_view.data.total === null
                                ? '--'
                                : views.total_location_events_view.data.total
                            : '--',
                };
            case 'countries':
                return {
                    ...stat,
                    number:
                        views?.total_countries_view?.data &&
                        views.total_countries_view.data.hasOwnProperty('total')
                            ? views.total_countries_view.data.total === null
                                ? '--'
                                : views.total_countries_view.data.total
                            : '--',
                    countries:
                        views?.total_countries_view?.data &&
                        views.total_countries_view.data.hasOwnProperty('countries')
                            ? views.total_countries_view.data.countries.filter(
                                  (country, index) =>
                                      index < 3 && country.country && country.percentage,
                              )
                            : [],
                };

            default:
                return stat;
        }
    });

    let max = 0;
    let chartDataUsers = [];
    let chartDataEvents = [];

    if (views?.calendar_users_created_per_day_view?.data) {
        chartDataUsers = views.calendar_users_created_per_day_view.data.map(item => {
            max = item.total > max ? item.total : max;
            const split = item.date.split('-');
            return {
                x: split[split.length - 1].toString(),
                y: item.total,
                dateLabel: 'Date',
                date: item.date,
                valueLabel: 'Users',
                value: item.total,
            };
        });
    }

    if (views?.calendar_location_events_per_day_view?.data) {
        chartDataEvents = views.calendar_location_events_per_day_view.data.map(item => {
            max = item.total > max ? item.total : max;
            const split = item.date.split('-');
            return {
                x: split[split.length - 1].toString(),
                y: item.total,
                dateLabel: 'Date',
                date: item.date,
                valueLabel: 'Events',
                value: item.total,
            };
        });
    }

    return {
        stats: stats,
        map: {
            users: views?.map_users_created_unique_locations_view?.data
                ? views.map_users_created_unique_locations_view.data
                : null,
            events: views?.map_unique_locations_view?.data
                ? views.map_unique_locations_view.data
                : null,
        },
        chart: [
            {
                ...state.userFacts.chart[0],
                data: [],
                dataUsers: chartDataUsers,
                dataEvents: chartDataEvents,
                max: max,
            },
        ],
    };
};
