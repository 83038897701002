export const mocks = [
    {
        id: 0,
        label: 'Commuting habits',
        list: [
            {
                id: 0,
                label: 'Long commute',
                selected: false,
            },
            {
                id: 1,
                label: 'Short commute',
                selected: false,
            },
            {
                id: 2,
                label: 'Heavy commuter',
                selected: false,
            },
            {
                id: 3,
                label: 'Light commuter',
                selected: false,
            },
            {
                id: 4,
                label: 'City commuter',
                selected: false,
            },
            {
                id: 5,
                label: 'Public transports user',
                selected: false,
            },
        ],
    },
    {
        id: 1,
        label: 'Living Areas',
        list: [
            {
                id: 0,
                label: 'Big City home',
                selected: false,
            },
            {
                id: 1,
                label: 'City surroundings home',
                selected: false,
            },
            {
                id: 2,
                label: 'Countryside home',
                selected: false,
            },
            {
                id: 3,
                label: 'Small towns home',
                selected: false,
            },
            {
                id: 4,
                label: 'Moved home',
                selected: false,
            },
        ],
    },
    {
        id: 2,
        label: 'Work habits',
        list: [
            {
                id: 0,
                label: 'Full-time worker',
                selected: false,
            },
            {
                id: 1,
                label: 'Part-time worker',
                selected: false,
            },
            {
                id: 2,
                label: 'Flexible worker',
                selected: false,
            },
            {
                id: 3,
                label: 'Night-shifter',
                selected: false,
            },
        ],
    },
    {
        id: 3,
        label: 'Sleeping habits',
        list: [
            {
                id: 0,
                label: 'Night owl',
                selected: false,
            },
            {
                id: 1,
                label: 'Early bird',
                selected: false,
            },
            {
                id: 2,
                label: 'Random sleeper',
                selected: false,
            },
        ],
    },
    {
        id: 4,
        label: 'Driving habits',
        list: [
            {
                id: 0,
                label: 'Speed Racer',
                selected: false,
            },
            {
                id: 1,
                label: 'Car lover',
                selected: false,
            },
            {
                id: 2,
                label: 'Distracted driver',
                selected: false,
            },
        ],
    },
    {
        id: 5,
        label: 'Personal habits',
        list: [
            {
                id: 0,
                label: 'Frequent flyer',
                selected: false,
            },
            {
                id: 1,
                label: 'Foodie',
                selected: false,
            },
            {
                id: 2,
                label: 'Gym addict',
                selected: false,
            },
            {
                id: 3,
                label: 'Couch potato',
                selected: false,
            },
            {
                id: 4,
                label: 'Weekend madness',
                selected: false,
            },
        ],
    },
    {
        id: 6,
        label: 'Brand Loyalty',
        list: [
            {
                id: 0,
                label: 'Restaurant',
                selected: false,
            },
            {
                id: 1,
                label: 'Supermarket',
                selected: false,
            },
        ],
    },
];
