import withAuth from 'hoc/withAuth';
import { lazy } from 'react';
import { roles } from 'services/roles';
import { getProjectInfo } from 'utils/helpers';

const MedipetBoardPage = lazy(() => import('redux/containers/MedipetBoardPage'));
const BoardPage = lazy(() => import('redux/containers/BoardPage'));

const project = getProjectInfo().currentProject;

export const appRoutes = [
    {
        id: 'route-board',
        path: ['/board', '/'],
        exact: true,
        header: {
            visible: true,
            section: 'board',
        },
        sidebar: {
            visible: false,
            disabled: false,
        },
        footer: {
            visible: false,
        },
        component:
            project === 'selfcare' || project === 'b2b'
                ? withAuth(BoardPage)
                : project === 'medipet'
                ? withAuth(MedipetBoardPage)
                : null,
        'data-test': 'RouteBoardPage',
        scope: [
            roles.ADMIN,
            roles.DISTRIBUTOR,
            roles.INSURER,
            roles.SELLER,
            roles.DISTRIBUTORSELLER,
            roles.PROVIDER,
        ],
    },
];
