export const flowTester = [
    {
        id: 'fc719683-094e-4cfd-ac5b-cfbca4c7d082-0',
        mode: 'view',
        successRateInterval: 0,
        user: {
            value: 'cenas@cenas.com',
            match: true,
        },
        selectedDevice: '5060d1bb-9f0d-4db2-8309-39d11946e647-0',
        notifications: {
            type: '',
            email: '',
        },
        schedule: {
            status: 'on',
            interval: 10,
        },
        deviceProperties: {
            deviceId: '5060d1bb-9f0d-4db2-8309-39d11946e647-0',
            properties: [
                {
                    id: 'f4a0fd4e-0e54-48d9-bbe0-1734cf2749db-0',
                    name: 'Video/motion',
                    value: 'on',
                },
                {
                    id: 'f4a0fd4e-0e54-48d9-bbe0-1734cf2749db-1',
                    name: 'Audio',
                    value: 'off',
                },
            ],
        },
        devices: [
            {
                id: '5060d1bb-9f0d-4db2-8309-39d11946e647-0',
                name: 'Nest Camera',
                image: 'https://store.nest.com/assets/images/social/open-graph/nest-cam-iq.jpg',
                properties: [
                    {
                        id: 'f4a0fd4e-0e54-48d9-bbe0-1734cf2749db-0',
                        name: 'Video/motion',
                        value: 'on',
                    },
                    {
                        id: 'f4a0fd4e-0e54-48d9-bbe0-1734cf2749db-1',
                        name: 'Audio',
                        value: 'off',
                    },
                ],
            },
            {
                id: '5060d1bb-9f0d-4db2-8309-39d11946e647-1',
                name: 'Nest Smoke Detector',
                image: 'https://store.nest.com/assets/images/social/open-graph/smoke-co-alarm.jpg',
                properties: [
                    {
                        id: 'f5a87a38-1327-41ce-abd2-dd97c2a0bdf0-0',
                        name: 'Smoke detector',
                        value: 'off',
                    },
                    {
                        id: 'f5a87a38-1327-41ce-abd2-dd97c2a0bdf0-1',
                        name: 'Audio alarm',
                        value: 'on',
                    },
                ],
            },
        ],
        steps: [
            {
                id: 'a2254c93-4008-45c6-8c66-20dc324b4a5d-0',
                text: 'Flow Tester 1.1',
                interval: 10,
                url: 'https://habit.io/',
                error: false,
            },
            {
                id: 'a2254c93-4008-45c6-8c66-20dc324b4a5d-1',
                text: 'Flow Tester 1.2',
                interval: 20,
                url: 'https://habit.io/',
                error: false,
            },
            {
                id: 'a2254c93-4008-45c6-8c66-20dc324b4a5d-2',
                text: 'Flow Tester 1.3',
                interval: 30,
                url: 'https://habit.io/',
                error: false,
            },
        ],
        events: [],
    },
    {
        id: 'fc719683-094e-4cfd-ac5b-cfbca4c7d082-1',
        mode: 'view',
        successRateInterval: 1,
        user: {
            value: 'coisas@coisas.com',
            match: true,
        },
        selectedDevice: '5060d1bb-9f0d-4db2-8309-39d11946e648-1',
        notifications: {
            type: '',
            email: '',
        },
        schedule: {
            status: 'on',
            interval: 1,
        },
        deviceProperties: {
            deviceId: '5060d1bb-9f0d-4db2-8309-39d11946e648-1',
            properties: [
                {
                    id: 'f5a87a38-1327-41ce-abd2-dd97c2a0bdf0-0',
                    name: 'Smoke detector',
                    value: 'on',
                },
                {
                    id: 'f5a87a38-1327-41ce-abd2-dd97c2a0bdf0-1',
                    name: 'Audio alarm',
                    value: 'on',
                },
            ],
        },
        devices: [
            {
                id: '5060d1bb-9f0d-4db2-8309-39d11946e648-0',
                name: 'Nest Camera',
                image: 'https://store.nest.com/assets/images/social/open-graph/nest-cam-iq.jpg',
                properties: [
                    {
                        id: 'f4a0fd4e-0e54-48d9-bbe0-1734cf2749db-0',
                        name: 'Video/motion',
                        value: 'on',
                    },
                    {
                        id: 'f4a0fd4e-0e54-48d9-bbe0-1734cf2749db-1',
                        name: 'Audio',
                        value: 'off',
                    },
                ],
            },
            {
                id: '5060d1bb-9f0d-4db2-8309-39d11946e648-1',
                name: 'Nest Smoke Detector',
                image: 'https://store.nest.com/assets/images/social/open-graph/smoke-co-alarm.jpg',
                properties: [
                    {
                        id: 'f5a87a38-1327-41ce-abd2-dd97c2a0bdf0-0',
                        name: 'Smoke detector',
                        value: 'off',
                    },
                    {
                        id: 'f5a87a38-1327-41ce-abd2-dd97c2a0bdf0-1',
                        name: 'Audio alarm',
                        value: 'on',
                    },
                ],
            },
        ],
        steps: [
            {
                id: '634afa53-abbe-4c84-90f4-43c88936aa3f-0',
                text: 'Flow Tester 1.1',
                interval: 10,
                url: 'https://habit.io/',
                error: false,
            },
            {
                id: '634afa53-abbe-4c84-90f4-43c88936aa3f-1',
                text: 'Flow Tester 1.2',
                interval: 20,
                url: 'https://habit.io/',
                error: true,
            },
            {
                id: '634afa53-abbe-4c84-90f4-43c88936aa3f-2',
                text: 'Flow Tester 1.3',
                interval: 30,
                url: 'https://habit.io/',
                error: true,
            },
        ],
        events: [],
    },
];
