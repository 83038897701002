export const integration = {
    isLoading: false,
    isSaving: false,
    details: {},
    devices: [],
    components: [],
    classes: {
        type: 'modal',
        id: 'DeviceClassesModal',
        active: false,
        componentId: null,
        propertyId: null,
        list: [],
    },
    units: {
        type: 'modal',
        id: 'DeviceUnitsModal',
        active: false,
        componentId: null,
        propertyId: null,
        list: [],
    },
    schema: {
        type: 'modal',
        id: 'DeviceSchemaModal',
        active: false,
        componentId: null,
        propertyId: null,
        list: [],
    },
    graphics: {
        steps: [
            {
                id: 0,
                label: 1,
                text: 'Add Device',
            },
            {
                id: 1,
                label: 2,
                text: 'Control Device',
            },
            {
                id: 2,
                label: 3,
                text: 'Device List',
            },
            {
                id: 3,
                label: 4,
                text: 'Webview',
            },
        ],
        tileInformation: {
            type: 'modal',
            id: 'TileInformationModal',
            active: false,
            componentId: null,
            propertyId: null,
            components: {
                list: [
                    {
                        id: 0,
                        text: 'Bulb',
                    },
                    {
                        id: 1,
                        text: 'Smoke Detector',
                    },
                ],
                selected: 1,
            },
            properties: {
                list: [
                    {
                        id: 0,
                        text: 'Brightness',
                    },
                    {
                        id: 1,
                        text: 'Temperature',
                    },
                ],
                selected: 1,
            },
            selected: [1],
        },
    },
    webview: {
        selected: null,
        list: [
            {
                id: 0,
                text: 'Webview 1',
            },
            {
                id: 1,
                text: 'Webview 2',
            },
            {
                id: 2,
                text: 'Webview 3',
            },
            {
                id: 3,
                text: 'Webview 4',
            },
        ],
    },
};
