import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Styles from './StepsBar.module.scss';

export class StepsBar extends React.PureComponent {
    static propTypes = {
        current: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        steps: PropTypes.arrayOf(PropTypes.object),
        route: PropTypes.string,
        assets: PropTypes.object,
        whiteLabel: PropTypes.bool,
    };

    getIsValid(step) {
        const required = step.required;
        const assets = this.props.assets;
        let isValid = true;

        if (required && assets) {
            for (let i = 0; i < required.length; i++) {
                if (!assets.hasOwnProperty(required[i])) {
                    isValid = false;
                }
            }
        }

        return isValid;
    }

    render() {
        const { current, steps, route, whiteLabel } = this.props;

        if (!steps || steps.length === 0) return null;

        return (
            <nav role="navigation" data-test="StepsBar" className={Styles.stepsBar}>
                <ul data-test="StepsTexts" className={Styles.stepsTexts}>
                    {steps &&
                        steps.map(step => (
                            <li
                                data-test="StepsTextsElement"
                                key={step.id}
                                className={current === step.label ? Styles?.active : null}
                            >
                                {step.text}
                            </li>
                        ))}
                </ul>

                <ul data-test="StepsProgress" className={Styles.stepsProgress}>
                    {steps &&
                        steps.map(step => (
                            <li
                                data-test="StepsProgressElement"
                                key={step.id}
                                className={classnames(
                                    step.id === 0 ? Styles.first : null,
                                    current > step.label ? Styles.done : null,
                                    (current > 1 && current === step.label && whiteLabel) ||
                                        (current > 0 && current === step.label && !whiteLabel)
                                        ? Styles?.active
                                        : null,
                                )}
                            >
                                <Link to={`${route}${step.label}`}>
                                    {step.id === 0 && whiteLabel && (
                                        <div className={classnames(Styles.label, Styles.valid)}>
                                            <i
                                                className={classnames(
                                                    'icon-icon_about_2',
                                                    Styles.about,
                                                )}
                                            ></i>
                                        </div>
                                    )}
                                    {((step.id > 0 && current > step.label && whiteLabel) ||
                                        (current > step.label && !whiteLabel)) && (
                                        <>
                                            {this.getIsValid(step) && (
                                                <div
                                                    className={classnames(
                                                        Styles.label,
                                                        Styles.valid,
                                                    )}
                                                >
                                                    <i className="icon-icon_check"></i>
                                                </div>
                                            )}

                                            {!this.getIsValid(step) && (
                                                <div
                                                    className={classnames(
                                                        Styles.label,
                                                        Styles.error,
                                                    )}
                                                >
                                                    <i className="icon-icon_alert_1"></i>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {((step.id > 0 && current <= step.label && whiteLabel) ||
                                        (current <= step.label && !whiteLabel)) && (
                                        <>
                                            {current !== step.label && this.getIsValid(step) && (
                                                <div
                                                    className={classnames(
                                                        Styles.label,
                                                        Styles.processed,
                                                    )}
                                                >
                                                    <i className="icon-icon_check"></i>
                                                </div>
                                            )}

                                            {(current === step.label || !this.getIsValid(step)) && (
                                                <div className={Styles.label}>{step.label}</div>
                                            )}
                                        </>
                                    )}
                                </Link>

                                <div className={Styles.line}></div>
                            </li>
                        ))}
                </ul>
            </nav>
        );
    }
}

export default StepsBar;
