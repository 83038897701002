import rootInitialState from 'redux/store/initialState';
import ActionTypes from 'redux/actionTypes';
import { sendErrorToast } from 'services/toast';
import { analyticsUserFactsViewsModel } from './model';
// import { getUserVisitedPlacesAlert, setUserVisitedPlacesAlert } from 'services/localStorage';

export const initialState = {
    analytics: rootInitialState.analytics,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.ANALYTICS_POPULATION_SEGMENTATION_TOGGLE_FILTER:
            return populationSegmentationToggleFilter(state, action);
        case ActionTypes.ANALYTICS_POPULATION_SEGMENTATION_CLEAR_FILTERS:
            return populationSegmentationClearFilters(state, action);
        case ActionTypes.ANALYTICS_POPULATION_SEGMENTATION_OPEN_COMPARE_MODAL:
            return openPopulationSegmentationCompareModal(state, action);
        case ActionTypes.ANALYTICS_POPULATION_SEGMENTATION_CLOSE_COMPARE_MODAL:
            return closePopulationSegmentationCompareModal(state, action);
        case ActionTypes.ANALYTICS_USERS_VISITED_PLACES_TOGGLE_FILTER:
            return usersVisitedPlacesToggleFilter(state, action);
        case ActionTypes.ANALYTICS_USERS_VISITED_PLACES_CLEAR_FILTERS:
            return usersVisitedPlacesClearFilters(state, action);
        case ActionTypes.ANALYTICS_SYNC_PROJECTS:
            return analyticsSyncProjects(state, action);
        case ActionTypes.ANALYTICS_USERS_VISITED_PLACES_CHANGE_PROJECT:
            return analyticsUsersVisitedPlacesChangeProject(state, action);
        case ActionTypes.ANALYTICS_USERS_VISITED_PLACES_ERROR:
            return analyticsUsersVisitedPlacesError(state, action);
        case ActionTypes.ANALYTICS_USERS_VISITED_PLACES_STORE_USER:
            return analyticsUsersVisitedPlacesStoreUser(state, action);
        case ActionTypes.ANALYTICS_USERS_VISITED_PLACES_CLEAR_USER:
            return analyticsUsersVisitedPlacesClearUser(state);
        case ActionTypes.ANALYTICS_USERS_VISITED_PLACES_STORE_VIEWS:
            return analyticsUsersVisitedPlacesStoreViews(state, action);
        case ActionTypes.ANALYTICS_USERS_VISITED_PLACES_ALERT_DISMISS:
            return analyticsUsersVisitedPlacesAlertDismiss(state, action);
        case ActionTypes.ANALYTICS_USER_DATA_STORE_VIEWS:
            return analyticsUserDataStoreViews(state, action);
        case ActionTypes.ANALYTICS_USER_RAW_LOCATION_DATA_ALERT_DISMISS:
            return analyticsUserRawLocationDataAlertDismiss(state, action);
        case ActionTypes.ANALYTICS_USER_DATA_CLEAR:
            return analyticsUserRawLocationDataClear(state);
        case ActionTypes.ANALYTICS_USER_RAW_LOCATION_DATA_ERROR:
            return analyticsUserRawLocationDataError(state, action);
        case ActionTypes.ANALYTICS_USER_RAW_LOCATION_DATA_STORE_USER:
            return analyticsUserRawLocationDataStoreUser(state, action);
        case ActionTypes.ANALYTICS_USER_RAW_LOCATION_DATA_CLEAR_USER:
            return analyticsUserRawLocationDataClearUser(state, action);
        case ActionTypes.ANALYTICS_USER_FACTS_STORE_VIEWS:
            return analyticsUserFactsStoreViews(state, action);
        case ActionTypes.ANALYTICS_USER_FACTS_CLEAR_DATA:
            return analyticsUserFactsClearData(state, action);
        case ActionTypes.ANALYTICS_CHANGE_DATES:
            return analyticsChangeDates(state, action);
        case ActionTypes.ANALYTICS_USER_FACTS_IS_LOADING_SCORE:
            return analyticsIsLoadingScore(state, action);
        case ActionTypes.ANALYTICS_USER_FACTS_STORE_SCORE:
            return analyticsUserFactsStoreScore(state, action);
        case ActionTypes.ANALYTICS_USER_FACTS_CLEAR_MAIN_VIEWS:
            return analyticsUserFactsClearMainViews(state, action);
        case ActionTypes.ANALYTICS_USER_FACTS_CLEAR_SCORE:
            return analyticsUserFactsClearScore(state, action);
        default:
            // return analyticsUsersVisitedPlacesAlert(state);
            return state;
    }
};

/**
 * User Facts Clear Data
 * @param {*} state
 * @param {*} action
 */
const analyticsUserFactsClearMainViews = state => {
    const score = state.userFacts.stats.filter(stat => stat.type === 'score');

    return {
        ...state,
        userFacts: {
            ...rootInitialState.analytics.userFacts,
            stats: rootInitialState.analytics.userFacts.stats.map(stat => {
                if (stat.type === 'score') {
                    return {
                        ...score,
                    };
                }

                return stat;
            }),
        },
    };
};

/**
 * User Facts Clear Data
 * @param {*} state
 * @param {*} action
 */
const analyticsUserFactsClearScore = state => {
    return {
        ...state,
        userFacts: {
            ...state.userFacts,
            stats: state.userFacts.stats.map(stat => {
                if (stat.type === 'score') {
                    return {
                        ...stat,
                        number: '--',
                    };
                }

                return stat;
            }),
        },
    };
};

/**
 * User Facts Clear Data
 * @param {*} state
 * @param {*} action
 */
const analyticsIsLoadingScore = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        userFacts: {
            ...state.userFacts,
            isLoadingScore: payload,
        },
    };
};

/**
 * Change Project
 * @param {*} state
 * @param {*} action
 */
const analyticsChangeDates = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        filters: {
            ...state.filters,
            dates: {
                start: payload.offset_date,
                end: payload.date,
            },
        },
    };
};

/**
 * User Facts Clear Data
 * @param {*} state
 * @param {*} action
 */
const analyticsUserFactsClearData = state => {
    return {
        ...state,
        userFacts: rootInitialState.analytics.userFacts,
    };
};

/**
 * Store User Facts Views
 * @param {*} state
 * @param {*} action
 */
const analyticsUserFactsStoreViews = (state, action) => {
    const payload = action.payload.data;
    const interval = action.payload.interval;
    const views = payload.views;

    const viewsModel = analyticsUserFactsViewsModel(state, views);

    return {
        ...state,
        userFacts: {
            ...state.userFacts,
            stats: viewsModel.stats,
            map: viewsModel.map,
            chart: viewsModel.chart,
            chartInterval: interval,
        },
    };
};

/**
 * User Facts Store Score View
 * @param {*} state
 * @param {*} action
 */
const analyticsUserFactsStoreScore = (state, action) => {
    const payload = action.payload.data;

    let number = '--';
    if (
        payload &&
        payload.hasOwnProperty('views') &&
        payload.views.hasOwnProperty('total_score_location_events_view') &&
        payload.views.total_score_location_events_view.data.final_score !== null &&
        !isNaN(payload.views.total_score_location_events_view.data.final_score)
    ) {
        number = parseInt(
            (payload.views.total_score_location_events_view.data.final_score * 100).toFixed(0),
        );
    }

    return {
        ...state,
        userFacts: {
            ...state.userFacts,
            stats: state.userFacts.stats.map(stat => {
                if (stat.type === 'score') {
                    return {
                        ...stat,
                        number: number,
                    };
                }

                return stat;
            }),
        },
    };
};

/**
 * Clear User
 * @param {*} state
 * @param {*} action
 */
const analyticsUserRawLocationDataClearUser = state => {
    return {
        ...state,
        userRawLocationData: {
            ...state.userRawLocationData,
            data: null,
            user: null,
            error: null,
            date: null,
            // alert: false,
        },
    };
};

/**
 * Error
 * @param {*} state
 * @param {*} action
 */
const analyticsUserRawLocationDataError = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        userRawLocationData: {
            ...state.userRawLocationData,
            error: payload,
            data: null,
        },
    };
};

/**
 * Store User
 * @param {*} state
 * @param {*} action
 */
const analyticsUserRawLocationDataStoreUser = (state, action) => {
    const payload = action.payload.data;
    // const date = action.payload.date;

    return {
        ...state,
        userRawLocationData: {
            ...state.userRawLocationData,
            user: { ...payload },
            error: null,
            // date: date,
        },
    };
};

const analyticsUserRawLocationDataAlertDismiss = state => {
    // setUserVisitedPlacesAlert();
    // const alert = getUserVisitedPlacesAlert();

    return {
        ...state,
        userRawLocationData: {
            ...state.userRawLocationData,
            alert: true,
            // alert: alert ? true : false,
        },
    };
};

const analyticsUsersVisitedPlacesAlertDismiss = state => {
    // setUserVisitedPlacesAlert();
    // const alert = getUserVisitedPlacesAlert();

    return {
        ...state,
        usersVisitedPlaces: {
            ...state.usersVisitedPlaces,
            alert: true,
            // alert: alert ? true : false,
        },
    };
};

// const analyticsUsersVisitedPlacesAlert = state => {
//     // const alert = getUserVisitedPlacesAlert();

//     return {
//         ...state,
//         usersVisitedPlaces: {
//             ...state.usersVisitedPlaces,
//             alert: false,
//             // alert: alert ? true : false,
//         },
//     };
// };

const analyticsUserRawLocationDataClear = state => {
    return {
        ...state,
        userRawLocationData: {
            data: null,
            user: null,
            error: null,
            alert: false,
        },
    };
};

/**
 * Store Views
 * @param {*} state
 * @param {*} action
 */
const analyticsUsersVisitedPlacesStoreViews = (state, action) => {
    const payload = action.payload.data;
    const date = action.payload.date;
    let output = payload;

    if (!payload) {
        return {
            ...state,
        };
    }

    if (
        payload &&
        payload.views &&
        payload.views.calendar_view &&
        payload.views.calendar_view.data &&
        payload.views.calendar_view.data.length > 0
    ) {
        const dates = payload.views.calendar_view.data.map(date => date.date);
        output = {
            ...output,
            views: {
                ...output.views,
                calendar_view: {
                    ...output.views.calendar_view,
                    data: [...dates],
                },
            },
        };
    }

    return {
        ...state,
        usersVisitedPlaces: {
            ...state.usersVisitedPlaces,
            data: { ...output },
            error: null,
            date: date,
        },
    };
};

/**
 * Clear User
 * @param {*} state
 * @param {*} action
 */
const analyticsUsersVisitedPlacesClearUser = state => {
    return {
        ...state,
        usersVisitedPlaces: {
            ...state.usersVisitedPlaces,
            data: null,
            user: null,
            error: null,
            date: null,
            // alert: false,
        },
    };
};

/**
 * Store User
 * @param {*} state
 * @param {*} action
 */
const analyticsUsersVisitedPlacesStoreUser = (state, action) => {
    const payload = action.payload.data;
    // const date = action.payload.date;

    return {
        ...state,
        usersVisitedPlaces: {
            ...state.usersVisitedPlaces,
            user: { ...payload },
            error: null,
            // date: date,
        },
    };
};

/**
 * Error
 * @param {*} state
 * @param {*} action
 */
const analyticsUsersVisitedPlacesError = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        usersVisitedPlaces: {
            ...state.usersVisitedPlaces,
            error: payload,
            data: null,
        },
    };
};

/**
 * Change Project
 * @param {*} state
 * @param {*} action
 */
const analyticsUsersVisitedPlacesChangeProject = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        filters: {
            ...state.filters,
            projects: {
                ...state.filters.projects,
                selected: payload,
            },
        },
    };
};

/**
 * Sync Projects
 * @param {*} state
 * @param {*} action
 */
const analyticsSyncProjects = (state, action) => {
    const payload = action.payload.data;
    const elements = payload.elements;

    const list = elements.map(project => {
        return {
            id: project.id,
            text: project.name,
            value: project.id,
        };
    });

    return {
        ...state,
        filters: {
            ...state.filters,
            projects: {
                ...state.filters.projects,
                // selected: null,
                list: [...list],
            },
        },
    };
};

const openPopulationSegmentationCompareModal = (state, action) => {
    return {
        ...state,
        populationSegmentation: {
            ...state.populationSegmentation,
            compare: {
                ...state.populationSegmentation.compare,
                active: true,
            },
        },
    };
};

const closePopulationSegmentationCompareModal = (state, action) => {
    return {
        ...state,
        populationSegmentation: {
            ...state.populationSegmentation,
            compare: {
                ...state.populationSegmentation.compare,
                active: false,
            },
        },
    };
};

const populationSegmentationClearFilters = (state, action) => {
    const filters = state.populationSegmentation.filters.map(section => {
        return {
            ...section,
            list: section.list.map(filter => {
                return {
                    ...filter,
                    selected: false,
                };
            }),
        };
    });

    return {
        ...state,
        populationSegmentation: {
            ...state.populationSegmentation,
            filters: filters,
        },
    };
};

const populationSegmentationToggleFilter = (state, action) => {
    const payload = action.payload.data;

    const filters = state.populationSegmentation.filters.map(section => {
        if (section.id === payload.listId) {
            return {
                ...section,
                list: section.list.map(filter => {
                    if (filter.id === payload.id) {
                        return {
                            ...filter,
                            selected: filter.selected ? false : true,
                        };
                    }

                    return filter;
                }),
            };
        }

        return section;
    });

    return {
        ...state,
        populationSegmentation: {
            ...state.populationSegmentation,
            filters: filters,
        },
    };
};

const usersVisitedPlacesClearFilters = (state, action) => {
    const filters = state.usersVisitedPlaces.filters.map(section => {
        return {
            ...section,
            list: section.list.map(filter => {
                return {
                    ...filter,
                    selected: false,
                };
            }),
        };
    });

    return {
        ...state,
        usersVisitedPlaces: {
            ...state.usersVisitedPlaces,
            filters: filters,
        },
    };
};

const usersVisitedPlacesToggleFilter = (state, action) => {
    const payload = action.payload.data;

    const filters = state.usersVisitedPlaces.filters.map(section => {
        if (section.id === payload.listId) {
            return {
                ...section,
                list: section.list.map(filter => {
                    if (filter.id === payload.id) {
                        return {
                            ...filter,
                            selected: filter.selected ? false : true,
                        };
                    }

                    return filter;
                }),
            };
        }

        return section;
    });

    return {
        ...state,
        usersVisitedPlaces: {
            ...state.usersVisitedPlaces,
            filters: filters,
        },
    };
};
/**
 * Store Data Views
 * @param {*} state
 * @param {*} action
 */
const analyticsUserDataStoreViews = (state, action) => {
    const payload = action.payload.data;
    const date = action.payload.date;
    const type = action.payload.type;
    let output = payload;

    if (payload.hasOwnProperty('error')) {
        sendErrorToast();
    }

    if (!payload) {
        return {
            ...state,
        };
    }

    if (
        payload &&
        payload.views &&
        payload.views.calendar_view &&
        payload.views.calendar_view.data &&
        payload.views.calendar_view.data.length > 0
    ) {
        const dates = payload.views.calendar_view.data.map(date => date.date);
        output = {
            ...output,
            views: {
                ...output.views,
                calendar_view: {
                    ...output.views.calendar_view,
                    data: [...dates],
                },
            },
        };
    }

    return {
        ...state,
        [type]: {
            ...state[type],
            data: { ...output },
            user:
                payload && payload.params && payload.params.user_id
                    ? { id: payload.params.user_id }
                    : null,
            error: null,
            date: date,
        },
    };
};

export default reducer;
