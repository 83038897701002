import DirectoryTile from 'components/Networks/DirectoryTile';
import { getText } from 'localization';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import ShareModal from 'redux/containers/Networks/ShareModal';
import ErrorBoundary from 'shared/ErrorBoundary';
import Loading from 'shared/Loading';
import Popup from 'shared/Popup';
import SectionHeader from 'shared/SectionHeader';
import { getElementPosition } from 'utils/functions';

import Styles from './EntityDirectories.module.scss';

export class EntityDirectories extends React.PureComponent {
    state = {
        search: '',
        settings: {
            active: false,
            element: {},
            id: null,
        },
    };

    static propTypes = {
        texts: PropTypes.object,
        isLoading: PropTypes.bool,
        directories: PropTypes.arrayOf(PropTypes.object),
        addEntityDirectory: PropTypes.func,
        onDeleteEntityDirectory: PropTypes.func,
        openPrompt: PropTypes.func,
        getDirectories: PropTypes.func,
        openShareModal: PropTypes.func,
        shareModal: PropTypes.bool,
    };

    componentDidMount() {
        this.props.getDirectories();
    }

    handleAddEntityDirectory = e => {
        this.props.addEntityDirectory();
    };

    handleOnSearch = value => {
        this.setState({
            search: value.toUpperCase(),
        });
    };

    handleOnClear = () => {
        this.setState({
            search: '',
        });
    };

    handleOnSubmit = e => {
        e.preventDefault();
    };

    handleToogleSettings = e => {
        e.preventDefault();
        const id = e.currentTarget.getAttribute('data-directory-id');

        this.setState({
            settings: {
                active: !this.state.settings?.active,
                element: getElementPosition(e.currentTarget),
                id: id,
            },
        });
    };

    handleClickSetting = e => {
        e.preventDefault();

        const action = e.currentTarget.getAttribute('data-action');

        switch (action) {
            case 'delete':
                const data = {
                    id: 'deleteDirectoryPrompt',
                    title: 'Alert',
                    content: getText('prompt_delete_directory'),
                    onConfirm: () =>
                        this.props.onDeleteEntityDirectory({
                            id: this.state.settings.id,
                            type: 'entities',
                        }),
                };

                this.props.openPrompt(data);
                break;

            case 'share':
                this.props.openShareModal({ id: this.state.settings.id, type: 'entities' });
                break;

            default:
                break;
        }
    };

    handleCloseSettings = () => {
        this.setState({
            settings: {
                active: false,
                id: null,
            },
        });
    };

    render() {
        const { texts, directories, isLoading, shareModal } = this.props;
        const { search, settings } = this.state;

        const searchResults =
            directories &&
            directories.length > 0 &&
            directories.filter(directory => directory.name.toUpperCase().indexOf(search) !== -1);

        return (
            <ErrorBoundary>
                <article data-test="EntitiesContainer" className={Styles.entities}>
                    <SectionHeader
                        data-test="EntitiesSectionHeader"
                        title={getText('entities')}
                        texts={texts}
                        action="search"
                        onClick={
                            directories && directories.length > 0
                                ? this.handleAddEntityDirectory
                                : null
                        }
                        onSearch={
                            directories && directories.length > 0 ? this.handleOnSearch : null
                        }
                        onClear={this.handleOnClear}
                        tooltipText={getText('create_directory')}
                    />

                    <section className={Styles.scrollContainer}>
                        <Suspense fallback={<Loading theme="none" variant="sm" />}>
                            {isLoading && <Loading />}

                            {!isLoading && directories && directories.length === 0 && (
                                <div data-test="EntitiesEmpty">
                                    <h3>
                                        <i className="icon-icon_about"></i>Add your entities and
                                        list them in different directories.
                                    </h3>

                                    <DirectoryTile
                                        data-test="DirectoryTileAdd"
                                        type="add"
                                        size="lg"
                                        text={getText('create_directory')}
                                        onClick={this.handleAddEntityDirectory}
                                        isLoading={isLoading}
                                    />
                                </div>
                            )}

                            {!isLoading &&
                                directories &&
                                directories.length > 0 &&
                                searchResults.map(dir => (
                                    <DirectoryTile
                                        data-test="DirectoryTile"
                                        key={dir.id}
                                        type="view"
                                        size="lg"
                                        texts={texts}
                                        directory={dir}
                                        directorytype="entities"
                                        baseUrl={`/networks-management/entities`}
                                        onSettings={this.handleToogleSettings}
                                    />
                                ))}

                            {!isLoading &&
                                directories &&
                                directories.length > 0 &&
                                searchResults.length === 0 && (
                                    <p data-test="EntitiesNotFound" className={Styles.notFound}>
                                        {getText('no_search_results')}
                                    </p>
                                )}
                        </Suspense>
                    </section>

                    <Popup
                        data-test="EntitiesSettingsPopup"
                        list
                        position="right"
                        id="entitySettingsPopup"
                        active={settings?.active}
                        element={settings.element}
                        element-id={settings.id}
                        onClickSetting={this.handleClickSetting}
                        closePopup={this.handleCloseSettings}
                        content={this.getPopupContent()}
                    />

                    {shareModal && <ShareModal data-test="EntitiesShareModal" />}
                </article>
            </ErrorBoundary>
        );
    }

    getPopupContent() {
        return (
            <>
                <li onClick={this.handleClickSetting} data-action="share">
                    {getText('share_directory')}
                </li>
                {/* TODO: Implement delete directory 
                <li onClick={this.handleClickSetting} data-action="delete">
                    {getText('delete_directory')}
                </li>
                */}
            </>
        );
    }
}

export default EntityDirectories;
