import ActionTypes from 'redux/actionTypes';
import rootInitialState from 'redux/store/initialState';
import { getErrorMsgByCode, REGISTER_DISTRIBUTOR_PENDING } from 'services/errorCodes';
import {
    getAccessToken,
    getAppSettings,
    getRefreshToken,
    getSession,
    getUserInfo,
    isUserAuthenticated,
    setAppSettings,
} from 'services/localStorage';

export const initialState = {
    name: rootInitialState.app.name,
    isLoading: rootInitialState.app.isLoading,
    isAuthenticated: rootInitialState.app.isAuthenticated,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.IS_LOADING:
            return { ...state, isLoading: action.payload.isLoading };
        case ActionTypes.IS_SAVING:
            return { ...state, isSaving: action.payload.data };
        case ActionTypes.HANDLE_GENERIC_LOADING:
            return { ...state, isGenericLoading: action.payload.isGenericLoading };
        case ActionTypes.APP_LOGIN_SUCCESS:
            return loginSuccess(state, action);
        case ActionTypes.APP_LOGIN_ERROR:
            return loginError(state, action);
        case ActionTypes.APP_LOGIN_STORE_EMAIL:
            return loginStoreEmail(state, action);
        case ActionTypes.APP_LOGIN_CLEAR:
            return loginClear(state, action);
        case ActionTypes.APP_REGISTER_SUCCESS:
            return registerSuccess(state, action);
        case ActionTypes.APP_REGISTER_ERROR:
            return registerError(state, action);
        case ActionTypes.APP_REGISTER_RESET:
            return registerReset(state, action);
        case ActionTypes.APP_RECOVER_SUCCESS:
            return recoverSuccess(state, action);
        case ActionTypes.APP_RECOVER_ERROR:
            return recoverError(state, action);
        case ActionTypes.APP_RECOVER_RESET:
            return recoverReset(state, action);
        case ActionTypes.APP_CHECK_AUTH:
            return checkAuth(state, action);
        case ActionTypes.CHECK_SESSION:
            return checkSession(state, action);
        case ActionTypes.APP_REGISTER_OPEN_TERMS_MODAL:
            return openTerms(state, action);
        case ActionTypes.APP_REGISTER_CLOSE_TERMS_MODAL:
            return closeTerms(state, action);
        case ActionTypes.APP_TOGGLE_INTEGRATIONS:
            return toggleIntegrations(state, action);
        default:
            return state;
    }
};

const toggleIntegrations = state => {
    const settings = getAppSettings();
    let payload = {};

    if (settings) {
        payload = {
            ...settings,
            board: {
                ...settings.board,
                integrations: !settings.board.integrations,
            },
        };
    } else {
        payload = {
            board: {
                integrations: true,
            },
        };
    }

    setAppSettings(payload);

    return {
        ...state,
        settings: payload,
    };
};

const openTerms = (state, action) => {
    return {
        ...state,
        terms: {
            ...state.terms,
            active: true,
        },
    };
};

const closeTerms = (state, action) => {
    return {
        ...state,
        terms: {
            ...state.terms,
            active: false,
        },
    };
};

const checkAuth = (state, action) => {
    const access_token = getAccessToken();
    const refresh_token = getRefreshToken();

    return {
        ...state,
        isAuthenticated:
            access_token && access_token !== '' && access_token !== 'undefined' ? true : false,
        access_token: access_token,
        refresh_token: refresh_token,
    };
};

const checkSession = (state, action) => {
    const session = getSession();
    const isAuthenticated = isUserAuthenticated();

    return {
        ...state,
        isAuthenticated: isAuthenticated,
        access_token: session.access_token ? session.access_token : null,
        refresh_token: session.refresh_token ? session.refresh_token : null,
        user: session.user ? session.user : null,
    };
};

const loginClear = (state, action) => {
    return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        access_token: null,
        refresh_token: null,
        errors: null,
        callbackUrl: null,
    };
};

const loginSuccess = (state, action) => {
    const user = getUserInfo();
    const callbackUrl = action.payload.callbackUrl;
    console.log('---> loginSuccess');
    return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        callbackUrl: callbackUrl,
        user: {
            ...state.user,
            id: user.id,
            name: user.name,
            email: user.email,
        },
    };
};

const loginError = (state, action) => {
    console.log('---> loginError');
    const payload = action.payload.data;
    const custom = action.payload.custom;
    const pending = action.payload.pending;

    return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        access_token: null,
        refresh_token: null,
        errors: {
            login: {
                msg: !custom
                    ? getErrorMsgByCode(payload?.data?.code ? payload.data.code : 0)
                    : custom,
                pending:
                    pending || payload?.data?.code === REGISTER_DISTRIBUTOR_PENDING ? true : false,
            },
        },
    };
};

const loginStoreEmail = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        email: payload.email,
    };
};

const registerSuccess = (state, action) => {
    return {
        ...state,
        isLoading: false,
        registerSuccess: true,
    };
};

const registerError = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        isLoading: false,
        errors: {
            register: {
                msg: getErrorMsgByCode(payload && payload.data ? payload.data.code : 0),
            },
        },
    };
};

const registerReset = (state, action) => {
    return {
        ...state,
        isLoading: false,
        registerSuccess: false,
        errors: {},
    };
};

const recoverSuccess = (state, action) => {
    return {
        ...state,
        isLoading: false,
        recoverSuccess: true,
    };
};

const recoverError = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        isLoading: false,
        errors: {
            recover: {
                msg: getErrorMsgByCode(payload && payload.data ? payload.data.code : 0),
            },
        },
    };
};

const recoverReset = (state, action) => {
    return {
        ...state,
        isLoading: false,
        recoverSuccess: false,
        email: null,
    };
};

export default reducer;
