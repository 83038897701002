import React from 'react';
import Button from 'shared/Button';
import Styles from './Translation.module.scss';
import { isDevEnv } from 'utils/functions';
import { apiHandleRequest } from 'services/api';
import { useTranslation } from 'react-i18next';
import { sendSuccessToast } from 'services/toast';
import i18next from 'i18next';

let sourceLang = 'en';
let targetLang = 'pt';

let json = require(`../../locales/${sourceLang}.json`);

const handleGetValuesFromJSON = () => {
    return Object.values(json);
};

const handleGetKeysFromJSON = () => {
    return Object.keys(json);
};

let values = handleGetValuesFromJSON();
let keys = handleGetKeysFromJSON();

let translatedWords = [];

const handleTranslate = () => {
    Promise.all(
        values.map(
            async (word, i) =>
                await apiHandleRequest({
                    url:
                        'https://translate.googleapis.com/translate_a/single?client=gtx&sl=' +
                        sourceLang +
                        '&tl=' +
                        targetLang +
                        '&dt=t&q=' +
                        encodeURI(word),
                    method: 'GET',
                }).then(res => {
                    translatedWords.push({ value: res[0][0][0], index: i });
                }),
        ),
    ).then(() => {
        mountTranslatedJSON();
    });
};

const mountTranslatedJSON = () => {
    let reorderedTranslatedWords = translatedWords.sort((a, b) => a.index - b.index);
    let mounted = JSON.stringify(
        Array(keys.length)
            .fill(null)
            .map((_, i) => {
                return { [keys[i]]: reorderedTranslatedWords[i]?.value };
            }),
    )
        .replace(/{/g, ' ')
        .replace(/}/g, ' ')
        .replace('[', '{')
        .replace(']', '}');
    console.log(mounted);
    sendSuccessToast('JSON successfully mounted in console!');
};

const Translation = () => {
    let showExample = true;
    const { t } = useTranslation();
    return isDevEnv() ? (
        <div className={Styles.container}>
            <div className={Styles.buttonContainer}>
                <Button value={t('translate_key')} type="button" onClick={handleTranslate} />
            </div>
            {showExample && (
                <div className={Styles.buttonContainer}>
                    <Button
                        value={t('change_to_pt')}
                        type="button"
                        onClick={() => i18next.changeLanguage('pt')}
                    />
                </div>
            )}
        </div>
    ) : null;
};

export default Translation;
