export const endUsers = {
    mode: 'view',
    total: '--',
    actives: '--',
    pending: '--',
    suspended: '--',
    search: '',
    pagination: {
        current: '--',
        total: '--',
        next: true,
    },
    filters: {
        field: 'created',
        direction: '-',
    },
    chunk: 15,
    users: [],
    visitedPlaces: {
        type: 'modal',
        id: 'EndUsersVisistedPlacesModal',
        user: null,
        active: false,
    },
};
