import PropTypes from 'prop-types';
import React from 'react';
// import classnames from 'classnames';
import Button from 'shared/Button';
import Styles from './AdvancedSearch.module.scss';
// import Dropdown from 'shared/Dropdown';
import Input from 'shared/Input';
// import InputMask from 'react-input-mask';
import { getText } from 'localization';
import ErrorBoundary from 'shared/ErrorBoundary';

import { GENDER_LIST, MARITAL_STATUS_LIST } from 'utils/constants';
import { cleanMaskedBirthForSubmit, cleanMaskedPostalCodeForSubmit } from 'utils/functions';

export class AdvancedSearch extends React.PureComponent {
    state = {
        overlayId: 'advancedSearchOverlay',
        containerId: 'advancedSearchContainer',
        canSearch: false,
        gender: {
            selected: null,
            list: GENDER_LIST,
        },
        marital: {
            selected: null,
            list: MARITAL_STATUS_LIST,
        },
        fields: {
            distributor: '',
            nif: '',
            partner_code: '',
            user_mobile: '',
            email: '',
            // name: '',
            // user_email: '',
            // identifier: '',
            // gender: '',
            // postal_code: '',
            // address: '',
            // city: '',
            // user_mobile: '',
            // marital: '',
            // region: '',
            // district: '',
            // birthdate: '',
        },
    };

    static propTypes = {
        texts: PropTypes.object,
        isLoading: PropTypes.bool,
        data: PropTypes.object,
        onAdvancedSearchSubmit: PropTypes.func,
        onClear: PropTypes.func,
        onToggle: PropTypes.func,
    };

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, false);

        if (
            this.props.data &&
            this.props.data.fields &&
            Object.entries(this.props.data).length > 0 &&
            Object.entries(this.props.data.fields).length > 0
        ) {
            this.syncPropsToState();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, false);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.data &&
            this.props.data &&
            prevProps.data.fields &&
            this.props.data.fields &&
            Object.entries(this.props.data).length > 0 &&
            Object.entries(this.props.data.fields).length > 0 &&
            prevProps.data.fields !== this.props.data.fields
        ) {
            this.syncPropsToState();
        }
    }

    syncPropsToState = () => {
        this.setState(
            state => ({
                fields: { ...this.props.data.fields },
                gender: {
                    ...state.gender,
                    selected: this.props.data.fields.gender ? this.props.data.fields.gender : null,
                },
                marital: {
                    ...state.marital,
                    selected: this.props.data.fields.marital
                        ? this.props.data.fields.marital
                        : null,
                },
            }),
            () => {
                this.handleValidation();
            },
        );
    };

    handleClickOutside = ({ target }) => {
        if (target) {
            const { overlayId, containerId } = this.state;
            const specifiedElement = document.getElementById(containerId);
            const targetId = target.id;
            const isClickInside = specifiedElement.contains(target);

            if (targetId && targetId === overlayId && !isClickInside) {
                this.props.onToggle();
            }
        }
    };

    handleOnChange = e => {
        const target = e.target;
        const value = target.type && target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState(
            state => ({
                fields: {
                    ...state.fields,
                    [name]: name === 'user_email' ? value : value.replace('_', ''),
                },
            }),
            () => {
                this.handleValidation();
            },
        );
    };

    handleOnChangeDropdown = data => {
        if (!data.id) {
            const newState = Object.keys(this.state.fields).reduce((object, key) => {
                if (key !== data.name) {
                    object[key] = this.state.fields[key];
                }
                return object;
            }, {});

            this.setState(
                state => ({
                    [data.name]: {
                        ...state[data.name],
                        selected: data.id,
                    },
                    fields: {
                        ...newState,
                    },
                }),
                () => {
                    this.handleValidation();
                    this.handleOnSubmit();
                },
            );
        } else {
            this.setState(
                state => ({
                    [data.name]: {
                        ...state[data.name],
                        selected: data.id,
                    },
                    fields: {
                        ...state.fields,
                        [data.name]: data.id,
                    },
                }),
                () => {
                    this.handleValidation();
                },
            );
        }
    };

    handleOnClear = () => {
        this.setState(
            state => ({
                canSearch: false,
                fields: {
                    distributor: '',
                    nif: '',
                    partner_code: '',
                    user_mobile: '',
                    email: '',
                    // name: '',
                    // user_email: '',
                    // identifier: '',
                    // gender: '',
                    // postal_code: '',
                    // address: '',
                    // city: '',
                    // user_mobile: '',
                    // marital: '',
                    // region: '',
                    // district: '',
                    // birthdate: '',
                },
                gender: {
                    ...state.gender,
                    selected: null,
                },
                marital: {
                    ...state.marital,
                    selected: null,
                },
            }),
            () => {
                this.props.onClear();
            },
        );
    };

    handleOnClearSingle = e => {
        const target = e.target;
        const name = target.getAttribute('data-name');

        this.setState(
            state => ({
                fields: {
                    ...state.fields,
                    [name]: '',
                },
            }),
            () => {
                this.handleValidation();
                this.handleOnSubmit();
            },
        );
    };

    handleOnSubmit = e => {
        if (e) {
            e.preventDefault();
        }

        const map = {
            identifier: ['io.habit.operations.fields.identifier'],
        };
        const fields = {
            ...this.state.fields,
            birthdate: cleanMaskedBirthForSubmit(this.state.fields.birthdate),
            postal_code: cleanMaskedPostalCodeForSubmit(this.state.fields.postal_code),
        };

        this.props.onAdvancedSearchSubmit({
            map: map,
            fields: fields,
            closeSearch: e ? true : false,
        });
    };

    handleValidation = () => {
        let hasSearchField = false;
        Object.entries(this.state.fields).forEach(([key, value]) => {
            if (value !== '') {
                hasSearchField = true;
            }
        });

        if (!hasSearchField) {
            this.setState({ canSearch: false });
        } else {
            this.setState({ canSearch: true });
        }
    };

    beforeMaskedStateChange({ nextState }) {
        let { value } = nextState;
        // if (value.endsWith('/')) {
        //     value = value.slice(0, -1);
        // }
        value = value.replace('_', '');
        return {
            ...nextState,
            value,
        };
    }

    render() {
        const { isLoading } = this.props;
        const { canSearch, fields, overlayId, containerId } = this.state;

        return (
            <ErrorBoundary>
                <div id={overlayId} className={Styles.overlay}>
                    <section
                        id={containerId}
                        data-test="AdvancedSearchContainer"
                        className={Styles.advancedSearch}
                    >
                        <form autoComplete="off" onSubmit={this.handleOnSubmit}>
                            <div className={Styles.row}>
                                <div className={Styles.col}>
                                    <p className={Styles.fieldTitle}>
                                        {getText(
                                            'insurance_insurees_advanced_search_distributor_label',
                                        )}
                                    </p>
                                    <Input
                                        data-test="Input"
                                        // autoFocus
                                        type="text"
                                        namespace=""
                                        name="distributor"
                                        theme="ternary"
                                        variant="outlined"
                                        state={
                                            fields.distributor && fields.distributor !== ''
                                                ? 'active'
                                                : null
                                        }
                                        placeholder="e.g: Distributor name"
                                        value={
                                            fields.distributor && fields.distributor !== ''
                                                ? fields.distributor
                                                : ''
                                        }
                                        disabled={isLoading}
                                        onChange={this.handleOnChange}
                                        onClear={this.handleOnClearSingle}
                                    />
                                </div>

                                <div className={Styles.col}>
                                    <p className={Styles.fieldTitle}>
                                        {getText(
                                            'insurance_insurees_advanced_search_identifier_label',
                                        )}
                                    </p>
                                    <Input
                                        type="text"
                                        namespace=""
                                        name="identifier"
                                        theme="ternary"
                                        variant="outlined"
                                        state={
                                            fields.identifier && fields.identifier !== ''
                                                ? 'active'
                                                : null
                                        }
                                        placeholder="e.g: NIF, CPF"
                                        value={
                                            fields.identifier && fields.identifier !== ''
                                                ? fields.identifier
                                                : ''
                                        }
                                        disabled={isLoading}
                                        onChange={this.handleOnChange}
                                        onClear={this.handleOnClearSingle}
                                    />
                                </div>

                                <div className={Styles.col}>
                                    <p className={Styles.fieldTitle}>
                                        {getText(
                                            'insurance_insurees_advanced_search_account_partner_code_label',
                                        )}
                                    </p>
                                    <Input
                                        type="text"
                                        namespace=""
                                        name="partner_code"
                                        theme="ternary"
                                        variant="outlined"
                                        state={
                                            fields.partner_code && fields.partner_code !== ''
                                                ? 'active'
                                                : null
                                        }
                                        placeholder="e.g: 912345678"
                                        value={
                                            fields.partner_code && fields.partner_code !== ''
                                                ? fields.partner_code
                                                : ''
                                        }
                                        disabled={isLoading}
                                        onChange={this.handleOnChange}
                                        onClear={this.handleOnClearSingle}
                                    />
                                </div>
                            </div>
                            <div className={Styles.row}>
                                <div className={Styles.col}>
                                    <p className={Styles.fieldTitle}>
                                        {getText(
                                            'insurance_insurees_advanced_search_account_phone_number_label',
                                        )}
                                    </p>
                                    <Input
                                        type="tel"
                                        namespace=""
                                        name="user_mobile"
                                        theme="ternary"
                                        variant="outlined"
                                        state={
                                            fields.user_mobile && fields.user_mobile !== ''
                                                ? 'active'
                                                : null
                                        }
                                        placeholder="e.g: 912345678"
                                        value={
                                            fields.user_mobile && fields.user_mobile !== ''
                                                ? fields.user_mobile
                                                : ''
                                        }
                                        disabled={isLoading}
                                        onChange={this.handleOnChange}
                                        onClear={this.handleOnClearSingle}
                                    />
                                </div>

                                <div className={Styles.col}>
                                    <p className={Styles.fieldTitle}>Email</p>
                                    <Input
                                        type="email"
                                        namespace=""
                                        name="user_email"
                                        theme="ternary"
                                        variant="outlined"
                                        state={
                                            fields.user_email && fields.user_email !== ''
                                                ? 'active'
                                                : null
                                        }
                                        placeholder="e.g: example@example.com"
                                        value={
                                            fields.user_email && fields.user_email !== ''
                                                ? fields.user_email
                                                : ''
                                        }
                                        disabled={isLoading}
                                        onChange={this.handleOnChange}
                                        onClear={this.handleOnClearSingle}
                                    />
                                </div>
                            </div>

                            <div className={Styles.actions}>
                                <div className={Styles.col}>
                                    <Button
                                        data-test="AdvancedSearchClear"
                                        theme="ternary"
                                        variant="outlined"
                                        type="button"
                                        value={getText('common_clear')}
                                        disabled={isLoading || !canSearch}
                                        onClick={this.handleOnClear}
                                        icon="icon_refresh"
                                        // loading={isSaving}
                                    />
                                    <Button
                                        data-test="AdvancedSearchSubmit"
                                        theme="ternary"
                                        variant="filled"
                                        type="submit"
                                        value={getText('common_search')}
                                        disabled={isLoading || !canSearch}
                                        icon="icon_search"
                                        // loading={isSaving}
                                    />
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
            </ErrorBoundary>
        );
    }
}

export default AdvancedSearch;
