import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Styles from './withOverlay.module.scss';

const withOverlay = WrappedComponent => {
    class HOC extends React.PureComponent {
        targetRef = React.createRef();
        targetElement = null;

        static propTypes = {
            id: PropTypes.string,
            active: PropTypes.bool,
            type: PropTypes.string,
            position: PropTypes.string,
            onCloseModal: PropTypes.func,
            onCancelPrompt: PropTypes.func,
        };

        componentDidMount() {
            this.targetElement = this.targetRef.current;
        }

        componentDidUpdate(prevProps, prevState) {
            if (!prevProps?.active && this.props?.active) {
                disableBodyScroll(this.targetElement);
                document.addEventListener('keydown', this.handleEscKey);
            }

            if (prevProps?.active && !this.props?.active) {
                enableBodyScroll(this.targetElement);
                document.removeEventListener('keydown', this.handleEscKey);
            }
        }
        componentWillUnmount() {
            clearAllBodyScrollLocks();
        }

        handleClickOutside = evt => {
            const flyoutElement = document.getElementById(this.props.id);
            let targetElement = evt.target;

            do {
                if (targetElement === flyoutElement) {
                    return;
                }

                targetElement = targetElement.parentNode;
            } while (targetElement);

            const prompt = document.getElementById('deleteTeamMemberPrompt');
            const { type, onCloseModal, onCancelPrompt } = this.props;

            if (type === 'modal') {
                if (!prompt || getComputedStyle(prompt).visibility === 'hidden') {
                    onCloseModal && typeof onCloseModal === 'function' && onCloseModal();
                }
            } else {
                onCancelPrompt && typeof onCancelPrompt === 'function' && onCancelPrompt();
            }
        };

        handleEscKey = evt => {
            const event = evt || window.event;
            if (event.keyCode === 27) {
                const { type, onCloseModal, onCancelPrompt } = this.props;
                if (type === 'modal') {
                    const prompt = document.getElementById('deleteTeamMemberPrompt');
                    if (!prompt || getComputedStyle(prompt).visibility === 'hidden') {
                        onCloseModal && typeof onCloseModal === 'function' && onCloseModal();
                    }
                } else {
                    onCancelPrompt && typeof onCancelPrompt === 'function' && onCancelPrompt();
                }
            }
        };

        render() {
            const { active, position } = this.props;

            return (
                <article
                    data-test="withOverlay"
                    ref={this.targetRef}
                    className={classnames(
                        Styles.overlay,
                        active ? Styles?.active : null,
                        Styles[position],
                    )}
                >
                    <WrappedComponent {...this.props} />
                </article>
            );
        }
    }

    return HOC;
};

export default withOverlay;
