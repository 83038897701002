import ActionTypes from 'redux/actionTypes';
import rootInitialState from 'redux/store/initialState';
import { formatDate } from 'utils/functions';

export const initialState = {
    endUsers: rootInitialState.endUsers,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.END_USERS_CLEAR:
            return clear(state, action);
        case ActionTypes.END_USERS_CHANGE_MODE:
            return changeMode(state, action);
        case ActionTypes.END_USERS_SEARCH_USER:
            return searchUser(state, action);
        case ActionTypes.END_USERS_RESUME_SUSPEND_USER:
            return resumeSuspendUser(state, action);
        case ActionTypes.END_USERS_DELETE_USER:
            return deleteUser(state, action);
        case ActionTypes.END_USERS_FETCH:
            return fetchEndUsers(state, action);
        case ActionTypes.END_USERS_SORT:
            return sortEndUsers(state, action);
        case ActionTypes.END_USERS_PAGINATE:
            return paginateEndUsers(state, action);
        case ActionTypes.END_USERS_PAGINATE_DISABLE_NEXT:
            return paginateDisableNext(state, action);
        case ActionTypes.END_USERS_IS_SENDING_EMAIL:
            return isSendingEmail(state, action);
        case ActionTypes.END_USERS_SYNC_TOTAL:
            return syncTotalUsers(state, action);
        case ActionTypes.END_USERS_SYNC_METADATA:
            return syncMetaDataByField(state, action);
        case ActionTypes.END_USERS_OPEN_VISITED_PLACES_MODAL:
            return openVisitedPlacesModal(state, action);
        case ActionTypes.END_USERS_CLOSE_VISITED_PLACES_MODAL:
            return closeVisitedPlacesModal(state, action);
        case ActionTypes.END_USERS_SYNC_METADATA_SEARCH:
            return syncMetaDataOnSearch(state, action);
        default:
            return state;
    }
};

/**
 * Open End Users Visited Modal
 * @param {*} state
 * @param {*} action
 */
const openVisitedPlacesModal = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        visitedPlaces: {
            ...state.visitedPlaces,
            user: payload.user || null,
            active: true,
        },
    };
};

/**
 * Close End Users Visited Modal
 * @param {*} state
 * @param {*} action
 */
const closeVisitedPlacesModal = (state, action) => {
    return {
        ...state,
        visitedPlaces: {
            ...state.visitedPlaces,
            user: null,
            active: false,
        },
    };
};

/**
 * Clear End Users
 * @param {*} state
 * @param {*} action
 */
const clear = (state, action) => {
    return { ...initialState.endUsers };
};

/**
 * Is Sending
 * @param {*} state
 * @param {*} action
 */
const isSendingEmail = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        isSending: payload,
    };
};
/**
 * Sort end users
 * @param {*} state
 * @param {*} action
 */
const paginateDisableNext = (state, action) => {
    return {
        ...state,
        pagination: {
            ...state.pagination,
            next: false,
        },
    };
};

/**
 * Paginate end users
 * @param {*} state
 * @param {*} action
 */
const paginateEndUsers = (state, action) => {
    const payload = action.payload.data;
    const elements = payload.elements;

    let current = state.pagination.current;

    switch (payload.action) {
        case 'prev':
            current = current > 1 ? current - 1 : 1;
            break;
        case 'next':
            current = current + 1;
            break;
        case 'start':
            current = 1;
            break;
        case 'end':
            current = payload.currentPage;
            break;

        default:
            break;
    }

    return {
        ...state,
        // total: 'NA',
        // actives: 'NA',
        // pending: 'NA',
        // suspended: 'NA',
        pagination: {
            ...state.pagination,
            current: current,
            next: payload.action !== 'end' ? elements.length >= state.chunk : null,
        },
        users: [...elements],
    };
};

/**
 * Sort end users
 * @param {*} state
 * @param {*} action
 */
const sortEndUsers = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        filters: {
            field: payload.field,
            direction: payload.direction,
        },
    };
};

/**
 * Fetch end users
 * @param {*} state
 * @param {*} action
 */
const fetchEndUsers = (state, action) => {
    const payload = action.payload.data;

    if (payload && payload !== '') {
        // const size = payload.size || 0;
        const elements = payload.elements;

        const pagination = {
            current: payload.size ? 1 : 0,
            total: Math.ceil(state.total / state.chunk),
            next: elements.length >= state.chunk,
        };

        // const actives = elements.filter(user => user.state === 'active').length;
        // const pending = elements.filter(user => user.state === 'unverified').length;
        // const suspended = elements.filter(user => user.state === 'inactive').length;

        // return {
        //     ...state,
        //     total: size,
        //     actives: actives,
        //     pending: pending,
        //     suspended: suspended,
        //     pagination: pagination,
        //     users: [ ...elements ]
        // }

        //TODO: change when endpoint is available
        return {
            ...state,
            // total: 'NA',
            // actives: 'NA',
            // pending: 'NA',
            // suspended: 'NA',
            isSearch: false,
            pagination: pagination,
            users: [...elements],
        };
    } else {
        return {
            ...state,
            // total: 0,
            // actives: 0,
            // pending: 0,
            // suspended: 0,
            isSearch: false,
            pagination: {
                current: 0,
                total: 0,
            },
            users: [],
        };
    }
};

/**
 * Change row mode
 * @param {*} state
 * @param {*} action
 */
const changeMode = (state, action) => {
    const mode = state.mode === 'view' ? 'edit' : 'view';

    return { ...state, mode: mode };
};

/**
 * state = 1 -> Active
 * state = 0 -> Pending
 * state = -1 -> Suspended
 * @param {*} state
 * @param {*} action
 */
const resumeSuspendUser = (state, action) => {
    const id = action.payload.data;

    const users = state.users.map(user => {
        if (user.id === id) {
            return {
                ...user,
                state: user.state === 'active' ? 'inactive' : 'active',
                since: formatDate(Date.now(), undefined, undefined, false),
            };
        }

        return { ...user };
    });

    return { ...state, users: [...users] };
};

/**
 * Delete User
 * @param {*} state
 * @param {*} action
 */
const deleteUser = (state, action) => {
    const payload = action.payload.data;

    const users = state.users.filter(user => user.id !== payload.id);

    return { ...state, users: [...users] };
};

/**
 * Search User
 * @param {*} state
 * @param {*} action
 */
const searchUser = (state, action) => {
    const payload = action.payload.data;

    return { ...state, search: payload };
};

/**
 * Sync Total Users
 * @param {*} state
 * @param {*} action
 */
const syncTotalUsers = (state, action) => {
    const payload = action.payload.data;

    const total = payload.elements[0] && payload.elements[0].count ? payload.elements[0].count : 0;

    return { ...state, total: total };
};

/**
 * Sync Total Users
 * @param {*} state
 * @param {*} action
 */
const syncMetaDataByField = (state, action) => {
    const payload = action.payload.data;

    const count = payload.elements[0] && payload.elements[0].count ? payload.elements[0].count : 0;

    if (action.payload.field === 'total') {
        return {
            ...state,
            [action.payload.field]: count,
            pagination: {
                ...state.pagination,
                total: Math.ceil(count / state.chunk),
            },
        };
    } else {
        return {
            ...state,
            [action.payload.field]: count,
        };
    }
};

const syncMetaDataOnSearch = (state, action) => {
    const payload = action.payload.data;

    const total = payload && payload.size ? payload.size : 0;

    return {
        ...state,
        isSearch: true,
        total: total,
        actives: '--',
        pending: '--',
        suspended: '--',
        pagination: {
            current: 1,
            total: 1,
            next: false,
        },
    };
};

export default reducer;
