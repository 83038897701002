// @ts-nocheck
import { getUserRole } from './localStorage';

export const roles = {
    INSURER: 'INSURER',
    DISTRIBUTOR: 'DISTRIBUTOR',
    ADMIN: 'ADMIN',
    SELLER: 'SELLER',
    INSURER_DISTRIBUTOR: 'INSURER_DISTRIBUTOR',
    USER: 'USER',
    DISTRIBUTORSELLER: 'DISTRIBUTORSELLER',
    PROVIDER: 'PROVIDER',
};

export const rolesCombo = {
    INSURER: [roles.INSURER],
    DISTRIBUTOR: [roles.DISTRIBUTOR],
    PROVIDER: [roles.PROVIDER],
    SELLER: [roles.SELLER],
    INSURER_DISTRIBUTOR: [roles.INSURER, roles.DISTRIBUTOR],
    ADMIN: [roles.ADMIN],
    USER: [roles.USER],
    DISTRIBUTORSELLER: [roles.DISTRIBUTORSELLER],
};

export const userHasPermission = list => {
    const userRoles = getUserRole();

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    const search = userRoles.filter(value => list.includes(value));

    return search && search.length > 0 ? true : false;
};

export const userIsThisButNotThat = (isThis, notThat) => {
    const userRoles = getUserRole();

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    const searchThis = userRoles.filter(value => isThis.includes(value));
    const searchThat = userRoles.filter(value => notThat.includes(value));

    if (searchThis && searchThis.length > 0 && (!searchThat || searchThat.length === 0)) {
        return true;
    }
    return false;
};

export const userIsDistributorNotInsurer = () => {
    const userRoles = getUserRole();

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    if (userRoles.includes(roles.DISTRIBUTOR) && !userRoles.includes(roles.INSURER)) {
        return true;
    }
    return false;
};

export const userIsSellerAndDistributorSeller = () => {
    const userRoles = getUserRole();

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    if (
        userRoles.includes(roles.SELLER) &&
        userRoles.includes(roles.DISTRIBUTORSELLER) &&
        !userRoles.includes(roles.DISTRIBUTOR)
    ) {
        return true;
    }
    return false;
};

export const userIsDistributorSellerOnly = () => {
    const userRoles = getUserRole();

    if (!userRoles || userRoles.length !== 1) {
        return false;
    }

    if (userRoles[0] === roles.DISTRIBUTORSELLER) {
        return true;
    }
    return false;
};

export const userIsOnlyInsurer = () => {
    const userRoles = getUserRole();

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    const unique = [...new Set(userRoles)];

    return unique.length <= 1 && JSON.stringify(unique) === JSON.stringify([roles.INSURER])
        ? true
        : false;
};

export const userIsOnlyDistributor = () => {
    const userRoles = getUserRole();

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    const unique = [...new Set(userRoles)];

    return unique.length <= 1 && JSON.stringify(unique) === JSON.stringify([roles.DISTRIBUTOR])
        ? true
        : false;
};

export const userIsOnlyProvider = () => {
    const userRoles = getUserRole();

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    const unique = [...new Set(userRoles)];

    return unique.length <= 1 && JSON.stringify(unique) === JSON.stringify([roles.PROVIDER])
        ? true
        : false;
};

export const userIsOnlyAdmin = () => {
    const userRoles = getUserRole();

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    const unique = [...new Set(userRoles)];
    return unique.length <= 1 && JSON.stringify(unique) === JSON.stringify([roles.ADMIN])
        ? true
        : false;
};

export const userIsOnlyAdminDistributorAndDistributorSeller = () => {
    const userRoles = getUserRole();

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    const requiredRoles = [roles.DISTRIBUTOR, roles.ADMIN, roles.DISTRIBUTORSELLER];
    const uniqueUserRoles = [...new Set(userRoles)];

    return (
        uniqueUserRoles.length === requiredRoles.length &&
        requiredRoles.every(role => uniqueUserRoles.includes(role))
    );
};

export const userIsOnlyAdminDistributor = () => {
    const userRoles = getUserRole();

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    const requiredRoles = [roles.DISTRIBUTOR, roles.ADMIN];
    const uniqueUserRoles = [...new Set(userRoles)];

    return (
        uniqueUserRoles.length === requiredRoles.length &&
        requiredRoles.every(role => uniqueUserRoles.includes(role))
    );
};

export const userIsAdmin = () => {
    const userRoles = getUserRole();

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    return userRoles.includes(roles.ADMIN) ? true : false;
};

export const userIsInsurer = () => {
    const userRoles = getUserRole();

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    return userRoles.includes(roles.INSURER) ? true : false;
};

export const userIsDistributor = () => {
    const userRoles = getUserRole();

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    return userRoles.includes(roles.DISTRIBUTOR) ? true : false;
};

export const userIsSeller = () => {
    const userRoles = getUserRole();

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    return userRoles.includes(roles.SELLER) ? true : false;
};

export const userIsOnlySeller = () => {
    const userRoles = getUserRole();

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    const unique = [...new Set(userRoles)];

    return unique.length <= 1 && JSON.stringify(unique) === JSON.stringify([roles.SELLER])
        ? true
        : false;
};

export const userIsOnlyByType = type => {
    const userRoles = getUserRole();

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    const unique = [...new Set(userRoles)];
    return unique.length > 0 && JSON.stringify(unique) === JSON.stringify(rolesCombo[type])
        ? true
        : false;
};

export const userHasThisPermissionOnly = permission => {
    const userRoles = getUserRole();

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    return userRoles.length === 1 && JSON.stringify(permission) === JSON.stringify(userRoles)
        ? true
        : false;
};

/**
 * Function to create the session validation hash
 * @param {*} string
 */
export const stringToHash = string => {
    const saltKey = process.env.REACT_APP_HASH_SALT_KEY || '';
    const saltString = `${saltKey}${string}`;

    if (Array.prototype.reduce) {
        return saltString.split('').reduce(function (a, b) {
            a = (a << 5) - a + b.charCodeAt(0);
            return a & a;
        }, 0);
    } else {
        let hash = 0,
            i,
            chr,
            len;
        if (saltString.length === 0) return hash;
        for (i = 0, len = saltString.length; i < len; i++) {
            chr = saltString.charCodeAt(i);
            hash = (hash << 5) - hash + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }
};
