export const fakeData = [
    {
        id: 'active',
        label: 'Active',
        value: 332,
        color: 'hsl(261, 70%, 50%)',
    },
    {
        id: 'inactive',
        label: 'Inactive',
        value: 120,
        color: 'hsl(42, 70%, 50%)',
    },
];

export const fakeOverviewData = [
    {
        id: 'home',
        label: 'Home',
        value: 53,
    },
    {
        id: 'work',
        label: 'Work',
        value: 5,
    },
    {
        id: 'stationary',
        label: 'Stationary',
        value: 27,
    },
    {
        id: 'walking',
        label: 'Walking',
        value: 3,
    },
    {
        id: 'car',
        label: 'Car',
        value: 10,
    },
    {
        id: 'cycling',
        label: 'Cycling',
        value: 2,
    },
];
