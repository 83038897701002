import { getText } from 'localization';
import { FIELD_STATUS, INSURANCE_PAYMENTS_STATUS_LIST } from 'utils/constants';

export const insurance = {
    isLoading: false,
    isSaving: false,
    carSpecs: null,
    filters: {
        projects: {
            selected: null,
            list: [],
        },
        select: {
            selected: 0,
            list: [
                {
                    id: 0,
                    text: 'All Projects',
                    value: 'all',
                },
                {
                    id: 1,
                    text: 'Project X',
                    value: 'x',
                },
                {
                    id: 2,
                    text: 'Project Y',
                    value: 'y',
                },
                {
                    id: 3,
                    text: 'Project Z',
                    value: 'z',
                },
            ],
        },
        dates: {},
    },
    dashboard: {
        totals: {
            total_number_of_insurees: 0,
            total_number_of_policies: 0,
            total_number_of_claims: 0,
            total_number_of_quotes: 0,
            total_number_of_protectedassets: 0,
        },
    },
    view: {
        odometer: {
            type: 'modal',
            id: 'OdometerModal',
            active: false,
            data: null,
        },
    },
    insurees: {
        search: {},
        selected: null,
        list: [],
        header: [
            {
                id: 0,
                name: getText('initial_state_insurance_name_label'),
                field: 'name',
                isSortable: true,
                hasIcon: true,
            },
            {
                id: 1,
                name: getText('initial_state_insurance_distributor_label'),
                field: 'distributor',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 2,
                name: getText('initial_state_insurance_phone_number_label'),
                field: 'user_mobile',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 3,
                name: 'NIF',
                field: 'identifier',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 4,
                name: getText('initial_state_insurance_number_of_policies_label'),
                field: 'policies',
                isSortable: false,
                hasIcon: null,
            },
        ],
        pagination: {
            current: '--',
            total: '--',
            next: true,
            page_start_index: 0,
        },
        filters: {
            field: 'created',
            direction: 'desc',
        },
        chunk: 15,
    },
    policies: {
        payments: {
            type: 'modal',
            id: 'PaymentsModal',
            active: false,
            search: {},
            selected: null,
            list: [],
            header: [
                {
                    id: 0,
                    name: 'Type',
                    field: 'type',
                    isSortable: false,
                    hasIcon: null,
                },
                {
                    id: 1,
                    name: 'Amount',
                    field: 'amount',
                    isSortable: false,
                    hasIcon: null,
                },
                {
                    id: 2,
                    name: 'Payday',
                    field: 'payday',
                    isSortable: false,
                    hasIcon: null,
                },
                {
                    id: 3,
                    name: 'Status',
                    field: 'status',
                    isSortable: false,
                    hasIcon: null,
                    hasStyle: true,
                },
            ],
            pagination: {
                current: '--',
                total: '--',
                next: false,
                page_start_index: 0,
            },
            filters: {
                field: null,
                direction: 'desc',
            },
            chunk: 15,
        },
        documents: {
            type: 'modal',
            id: 'DocumentsModal',
            active: false,
            data: null,
        },
        media: {
            type: 'modal',
            id: 'MediaModal',
            active: false,
            data: null,
        },
        damages: {
            type: 'modal',
            id: 'DamagesModal',
            active: false,
            data: null,
        },
        search: {},
        selected: null,
        list: [],
        header: [
            {
                id: 0,
                name: getText('initial_state_insurance_version_label'),
                field: 'service',
                isSortable: true,
                hasIcon: true,
            },
            {
                id: 1,
                name: getText('initial_state_insurance_distributor_label_'),
                field: 'distributor',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 2,
                name: getText('insurance_claim_table_headers_origin'),
                field: 'origin',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 3,
                name: getText('insurance_claim_table_headers_seller_point_info'),
                field: 'seller_point_info',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 4,
                name: getText('initial_state_insurance_policy_number_label'),
                field: 'code',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 5,
                name: getText('initial_state_insurance_submission_date_label'),
                field: 'enrolment_date',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 6,
                name: getText('initial_state_insurance_state_label'),
                field: 'status',
                isSortable: true,
                hasIcon: null,
            },
        ],
        pagination: {
            current: '--',
            total: '--',
            next: true,
            page_start_index: 0,
        },
        filters: {
            field: 'enrolment_date',
            direction: 'desc',
        },
        chunk: 15,
    },
    claims: {
        search: {},
        selected: null,
        list: [],
        header: [
            {
                id: 0,
                name: getText('initial_state_claims_insurance_product_label'),
                field: 'service',
                isSortable: true,
                hasIcon: true,
            },
            {
                id: 1,
                name: getText('initial_state_claims_insurance_distributor_label'),
                field: 'distributor',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 2,
                name: getText('initial_state_claims_insurance_claim_number_label'),
                field: 'claim_number',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 3,
                name: getText('initial_state_claims_insurance_state_label'),
                field: 'status',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 4,
                name: getText('initial_state_claims_insurance_submission_date_label'),
                field: 'submission_date',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 5,
                name: getText('initial_state_claims_insurance_policy_number_label'),
                field: 'policy_number',
                isSortable: true,
                hasIcon: null,
            },
        ],
        pagination: {
            current: '--',
            total: '--',
            next: true,
            page_start_index: 0,
        },
        filters: {
            field: 'submission_date',
            direction: 'desc',
        },
        chunk: 15,
        chat: {
            isLoading: false,
            history: null,
        },
    },
    quotes: {
        search: {},
        selected: null,
        list: [],
        header: [
            {
                id: 0,
                name: getText('insurance_claim_table_headers_product'),
                field: 'service',
                isSortable: true,
                hasIcon: true,
            },
            {
                id: 1,
                name: getText('insurance_claim_table_headers_distributor'),
                field: 'distributor',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 2,
                name: getText('insurance_claim_table_headers_origin'),
                field: 'origin',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 3,
                name: getText('insurance_claim_table_headers_seller_point_info'),
                field: 'seller_point_info',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 4,
                name: getText('insurance_claim_table_headers_quote_number'),
                field: 'code',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 5,
                name: getText('insurance_claim_table_headers_submission_date'),
                field: 'submission_date',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 6,
                name: getText('insurance_claim_table_headers_converted_into_policy'),
                field: 'policy',
                isSortable: true,
                isBoolean: true,
                hasIcon: null,
            },
        ],
        pagination: {
            current: '--',
            total: '--',
            next: true,
            page_start_index: 0,
        },
        filters: {
            field: 'submission_date',
            direction: 'desc',
        },
        chunk: 15,
    },
    assets: {
        search: {},
        selected: null,
        list: [],
        header: [
            {
                id: 0,
                name: getText('insurance_protected_assets_table_protected_asset_title'),
                field: 'service',
                isSortable: true,
                hasIcon: true,
            },
            {
                id: 1,
                name: getText('insurance_protected_assets_table_distributor_title'),
                field: 'distributor',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 4,
                name: getText('insurance_protected_assets_table_policy_title'),
                field: 'policy',
                isSortable: true,
                hasIcon: null,
            },
        ],
        pagination: {
            current: '--',
            total: '--',
            next: true,
            page_start_index: 0,
        },
        filters: {
            field: 'creation_date',
            direction: 'desc',
        },
        chunk: 15,
    },
    payments: {
        search: {},
        selected: null,
        list: [],
        // list: payments,
        header: [
            {
                id: 0,
                name: getText('initial_state_payments_and_documents_insurance_status_label'),
                field: 'state',
                isSortable: true,
                hasIcon: true,
            },
            {
                id: 1,
                name: getText('initial_state_payments_and_documents_insurance_policy_number_label'),
                field: 'policy_code',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 2,
                name: getText('initial_state_payments_and_documents_insurance_date_label'),
                field: 'start_date',
                isSortable: true,
                hasIcon: null,
                customStateFields: FIELD_STATUS,
            },
            {
                id: 4,
                name: getText('initial_state_payments_and_documents_insurance_payment_type_label'),
                field: 'payment_type',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 5,
                name: getText('initial_state_payments_and_documents_insurance_amount_label'),
                field: 'amount',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 7,
                name: getText('initial_state_payments_and_documents_insurance_documents_label'),
                field: 'documents',
                isSortable: false,
                hasIcon: null,
            },
        ],
        pagination: {
            current: '--',
            total: '--',
            next: true,
            page_start_index: 0,
        },
        filters: {
            field: 'start_date',
            direction: 'desc',
        },
        chunk: 15,
    },
    billing: {
        type: 'modal',
        id: 'PaymentsDocumentsModal',
        active: false,
        policyId: null,
        clientserviceId: null,
        search: null,
        selected: null,
        list: [],
        selectors: {
            date: null,
            state: {
                list: INSURANCE_PAYMENTS_STATUS_LIST,
                selected: null,
            },
        },
        header: [
            {
                id: 0,
                name: getText('common_status'),
                field: 'state',
                isSortable: true,
                hasIcon: true,
            },
            {
                id: 1,
                name: getText('insurance_billing_details_update_date_label'),
                field: 'start_date',
                isSortable: true,
                hasIcon: null,
                customStateFields: FIELD_STATUS,
            },
            {
                id: 3,
                name: getText('insurance_billing_details_emission_date_label'),
                field: 'emission_ts',
                isSortable: false,
                hasIcon: null,
            },
            {
                id: 4,
                name: getText('initial_state_insurance_payment_type_label'),
                field: 'payment_type',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 5,
                name: getText('initial_state_insurance_payment_amount_label'),
                field: 'amount',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 5,
                name: getText('initial_state_insurance_documents_label'),
                field: 'documents',
                isSortable: false,
                hasIcon: null,
            },
        ],
        pagination: {
            current: '--',
            total: '--',
            next: false,
            page_start_index: 0,
        },
        filters: {
            field: 'start_date',
            direction: 'desc',
        },
        chunk: 15,
    },
};
