import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './History.module.scss';
import { formatDateToChat, formatHourToChat } from 'utils/functions';

export class History extends React.PureComponent {
    static propTypes = {
        data: PropTypes.object,
        onDownloadAsset: PropTypes.func,
    };

    componentDidMount() {
        if (this.props.data && Object.entries(this.props.data).length > 0) {
            this.handleScrollIntoView();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (!prevProps.data && this.props.data) ||
            JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)
        ) {
            this.handleScrollIntoView();
        }
    }

    handleScrollIntoView = () => {
        const el = document.querySelectorAll('[data-message]');

        if (el && el.length > 0) {
            el[el.length - 1].scrollIntoView({ block: 'nearest', inline: 'nearest' });
        }
    };

    render() {
        const { data, onDownloadAsset } = this.props;

        return (
            <ul data-test="HistoryContainer" className={Styles.history} id="historyContainer">
                {data &&
                    Object.entries(data).map(([key, value]) => (
                        <React.Fragment key={key}>
                            <li data-test="HistoryDate" key={key} className={Styles.date}>
                                {formatDateToChat(key)}
                            </li>
                            {value.map(el => (
                                <li
                                    data-test="HistoryMessage"
                                    data-message
                                    key={`${key}-${el.id}`}
                                    className={
                                        el.action === 'user_message' ? Styles.left : Styles.right
                                    }
                                >
                                    <div className={classnames(Styles.avatar)}>
                                        <i
                                            className={
                                                el.action === 'user_message'
                                                    ? 'icon-icon_user'
                                                    : 'icon-icon_insurance'
                                            }
                                        ></i>
                                    </div>
                                    <div className={classnames(Styles.baloon)}>
                                        {el.entity === 'ClaimHistory' && el.description}
                                        {el.entity === 'ClaimAssets' && (
                                            <span
                                                className={Styles.text}
                                                data-id={el.entity_id}
                                                onClick={onDownloadAsset}
                                            >
                                                <i
                                                    className={
                                                        el.description.indexOf('photo') !== -1
                                                            ? 'icon-icon_Add_image'
                                                            : el.description.indexOf('video') !== -1
                                                            ? 'icon-icon_play'
                                                            : 'icon-icon_flow_test'
                                                    }
                                                ></i>
                                                {el.description}
                                            </span>
                                        )}
                                        <span className={Styles.hour}>
                                            {formatHourToChat(el.created)}
                                        </span>
                                    </div>
                                </li>
                            ))}
                        </React.Fragment>
                    ))}
            </ul>
        );
    }
}

export default History;
