import Networks from 'pages/Networks/Networks';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { networksOpenShareModalAction } from 'redux/domains/networks/actions';
import { networksDeleteNetworkThunk, networksGetNetworksThunk } from 'redux/domains/networks/thunk';
import { openPromptAction } from 'redux/domains/prompt/actions';
import { toggleModalAction } from 'redux/domains/teamMembers/actions';

const mapStateToProps = (state, ownProps) => ({
    texts: state.texts,
    isLoading: state.app.isLoading,
    networks: state.networks.networks.networks,
    shareModal: state.networks.share?.active,
    segments: state.networks.segments,
    countries: state.networks.countries,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    openShareModal: data => dispatch(networksOpenShareModalAction(data)),
    toggleModal: () => dispatch(toggleModalAction()),
    openPrompt: data => dispatch(openPromptAction(data)),
    onDeleteNetwork: data => networksDeleteNetworkThunk(dispatch, data),
    getNetworks: () => networksGetNetworksThunk(dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Networks));
