import React from 'react';
import PropTypes from 'prop-types';
import Styles from './CrossfadeImgAnim.module.scss';

export class CrossfadeImgAnim extends React.PureComponent {
    static propTypes = {
        images: PropTypes.array,
    };

    render() {
        const { images } = this.props;

        return (
            <div data-test="CrossfadeImgAnimContainer" className={Styles.container}>
                {images.map(img => (
                    <img data-test="image" key={img.id} src={img.src} alt={img.name} />
                ))}
            </div>
        );
    }
}

export default CrossfadeImgAnim;
