import ActionTypes from 'redux/actionTypes';

export const isLoadingNetworksAction = isLoading => ({
    type: ActionTypes.NETWORKS_IS_LOADING,
    payload: {
        isLoading,
    },
});

export const isSavingNetworksAction = data => ({
    type: ActionTypes.NETWORKS_IS_SAVING,
    payload: {
        data,
    },
});

export const clearNetworksAction = () => ({
    type: ActionTypes.NETWORKS_CLEAR,
});

export const networksSyncDirectoriesByTypeAction = (data, type) => ({
    type: ActionTypes.NETWORKS_SYNC_DIRECTORIES,
    payload: {
        data,
        type,
    },
});

export const networksSyncDirectoryAction = (data, types) => ({
    type: ActionTypes.NETWORKS_SYNC_DIRECTORY,
    payload: {
        data,
        types,
    },
});

export const networksEditDirectoryAction = (data, types) => ({
    type: ActionTypes.NETWORKS_EDIT_DIRECTORY,
    payload: {
        data,
        types,
    },
});

export const networksCreateEntityAction = data => ({
    type: ActionTypes.NETWORKS_CREATE_ENTITY,
    payload: {
        data,
    },
});

export const networksSyncSegmentsAction = (data, types) => ({
    type: ActionTypes.NETWORKS_SYNC_SEGMENTS,
    payload: {
        data,
        types,
    },
});

export const networksSyncCategoriesAction = (data, types) => ({
    type: ActionTypes.NETWORKS_SYNC_CATEGORIES,
    payload: {
        data,
        types,
    },
});

export const networksSyncCountriesAction = (data, types) => ({
    type: ActionTypes.NETWORKS_SYNC_COUNTRIES,
    payload: {
        data,
        types,
    },
});

export const networksOpenShareModalAction = data => ({
    type: ActionTypes.NETWORKS_OPEN_SHARE_MODAL,
    payload: {
        data,
    },
});

export const networksCloseShareModalAction = data => ({
    type: ActionTypes.NETWORKS_CLOSE_SHARE_MODAL,
    payload: {
        data,
    },
});

export const networksAddMemberErrorAction = data => ({
    type: ActionTypes.NETWORKS_ADD_MEMBER_ERROR,
    payload: {
        data,
    },
});

export const networksAddMemberClearErrorAction = () => ({
    type: ActionTypes.NETWORKS_ADD_MEMBER_CLEAR_ERROR,
});

export const networksSyncDirectoryMembersAction = data => ({
    type: ActionTypes.NETWORKS_SYNC_DIRECTORY_MEMBERS,
    payload: {
        data,
    },
});

export const networksOpenElementModalAction = (data, types) => ({
    type: ActionTypes.NETWORKS_OPEN_ELEMENT_MODAL,
    payload: {
        data,
        types,
    },
});

export const networksCloseElementModalAction = types => ({
    type: ActionTypes.NETWORKS_CLOSE_ELEMENT_MODAL,
    payload: {
        types,
    },
});

export const networksSyncElementAction = (data, types) => ({
    type: ActionTypes.NETWORKS_SYNC_ELEMENT,
    payload: {
        data,
        types,
    },
});

export const networksSyncElementImageAction = (data, types) => ({
    type: ActionTypes.NETWORKS_SYNC_ELEMENT_IMAGE,
    payload: {
        data,
        types,
    },
});

export const networksSyncDirectoryElementsAction = (data, element, types) => ({
    type: ActionTypes.NETWORKS_SYNC_DIRECTORY_ELEMENTS,
    payload: {
        data,
        element,
        types,
    },
});

export const networksOpenItemModalAction = data => ({
    type: ActionTypes.NETWORKS_OPEN_ITEM_MODAL,
    payload: {
        data,
    },
});

export const networksCloseItemModalAction = () => ({
    type: ActionTypes.NETWORKS_CLOSE_ITEM_MODAL,
});

export const networksSyncMetaDataTotalsAction = data => ({
    type: ActionTypes.NETWORKS_SYNC_META_DATA_TOTALS,
    payload: {
        data,
    },
});

export const networksSyncNetworksAction = data => ({
    type: ActionTypes.NETWORKS_SYNC_NETWORKS,
    payload: {
        data,
    },
});

export const networksSyncNetworkAction = data => ({
    type: ActionTypes.NETWORKS_SYNC_NETWORK,
    payload: {
        data,
    },
});

export const networksSyncAvailableNetworkEntitiesAction = data => ({
    type: ActionTypes.NETWORKS_SYNC_NETWORK_AVAILABLE_ENTITIES,
    payload: {
        data,
    },
});

export const networksSyncNetworkEntitiesAction = data => ({
    type: ActionTypes.NETWORKS_SYNC_NETWORK_ENTITIES,
    payload: {
        data,
    },
});

export const networksSyncNetworkImageAction = data => ({
    type: ActionTypes.NETWORKS_SYNC_NETWORK_IMAGE,
    payload: {
        data,
    },
});

export const networksSyncNetworkSelectDirectoryAction = data => ({
    type: ActionTypes.NETWORKS_SYNC_NETWORK_SELECTED_DIRECTORY,
    payload: {
        data,
    },
});

export const loadMoreServicesItemsAction = data => ({
    type: ActionTypes.NETWORKS_LOAD_MORE_SERVICES_ITEMS,
    payload: {
        data,
    },
});

export const networksSyncNetworkEntityServicesItemsAction = data => ({
    type: ActionTypes.NETWORKS_SYNC_NETWORK_ENTITY_SERVICES_ITEMS,
    payload: {
        data,
    },
});

export const networksSyncNetworkEntityServicesItemsSelectedAction = data => ({
    type: ActionTypes.NETWORKS_SYNC_NETWORK_ENTITY_SERVICES_ITEMS_SELECTED,
    payload: {
        data,
    },
});

export const networksSyncProvidersAction = providers => ({
    type: ActionTypes.NETWORKS_SYNC_PROVIDERS,
    payload: {
        providers,
    },
});
