import { updateClaimData, updateInsureeData, updateProtectedAssetData } from 'api/insurance';
import { diff } from 'deep-object-diff';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { sendSuccessToast } from 'services/toast';
import { getIsEditable } from 'utils/helpers';

export enum Feature {
    policyholder = 'policyholder',
    protectedAsset = 'protectedAsset',
    claims = 'claims',
}

export enum PolicyholderSections {
    info = 'info',
    address = 'address',
    user = 'user',
}

export enum ProtectedAssetSections {
    other = ' other',
    mandatory = 'mandatory',
}

export enum ClaimsSections {
    description = 'description',
}

export enum CLASSES {
    editable_null = 'io.habit.operations.editable_null',
    editable_management = 'io.habit.operations.editable_management',
    immutable = 'io.habit.operations.immutable',
    platform_management = 'io.habit.platform.management',
}

export type InputField = {
    key: string;
    data: string;
    classes: string[];
    [key: string]: any;
};

enum ProtectedAssetProperties {
    protectedassetpropertyspec = 'protectedassetpropertyspec',
}

const useDynamic = (data: any, feature: Feature, options?: { [key: string]: string }) => {
    const [shouldLetEdit, setShouldLetEdit] = useState(false);
    const [fields, setFields] = useState<any>(data);

    /**
     * @description This ref is used to store the found editable fields
     */
    let found: React.MutableRefObject<InputField[]> = useRef([]);

    /**
     * @description Used to an early return, check if the attribute should not be editable
     * */
    const shouldStop = (item: (typeof found.current)[number]) => {
        return (
            item?.classes?.includes(CLASSES.immutable) ||
            item.key === ProtectedAssetProperties.protectedassetpropertyspec
        );
    };

    /**
     *  @description This hook is used to check if the data includes any editable field
     * */
    const doesDataIncludeAnyEditableField: boolean = useMemo(() => {
        return found?.current
            ?.map((item: InputField) => {
                if (shouldStop(item)) return false;
                return getIsEditable(item, feature);
            })
            ?.find((item: boolean) => Boolean(item));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [found.current]);

    /**
     * @description This function will populate the ref with the found editable fields
     */
    const mapInputsOnRef = useCallback((obj: any, lastLayer?: any) => {
        for (const key in obj) {
            if (typeof obj[key] === 'object') {
                if (obj[key]?.classes && Array.isArray(obj[key].classes)) {
                    found.current = [
                        ...found.current,
                        {
                            key,
                            data: obj[key].data ? obj[key].data : lastLayer?.data,
                            classes: obj[key].classes,
                        },
                    ];
                }
                if (key in obj) {
                    mapInputsOnRef(obj[key], obj[key]);
                }
            }
        }
    }, []);

    /**
     * @description This function will handle the change of the input field
     * Each section has its own format for the fields, so we need to handle them separately
     */
    const handleChangeInputField = useCallback(
        (
            namespace: string,
            value: string,
            section: PolicyholderSections | ProtectedAssetSections | ClaimsSections,
        ) => {
            switch (section) {
                case PolicyholderSections.info:
                    setFields({
                        ...fields,
                        info: {
                            ...fields.info,
                            [namespace]: {
                                ...fields.info[namespace],
                                data: value,
                                shouldTemporarilyNotDisableInput: true,
                            },
                        },
                    });
                    break;
                case PolicyholderSections.address:
                    setFields({
                        ...fields,
                        address: {
                            ...fields.address,
                            [namespace]: {
                                ...fields.address[namespace],
                                data: value,
                                shouldTemporarilyNotDisableInput: true,
                            },
                        },
                    });
                    break;
                case PolicyholderSections.user:
                    setFields({
                        ...fields,
                        user: {
                            ...fields.user,
                            [namespace]: {
                                ...fields.user[namespace],
                                data: value,
                                shouldTemporarilyNotDisableInput: true,
                            },
                        },
                    });
                    break;
                case ProtectedAssetSections.other:
                    setFields({
                        ...fields,
                        other: {
                            ...fields.other,
                            [namespace]: {
                                ...fields.other[namespace],
                                data: value,
                                shouldTemporarilyNotDisableInput: true,
                            },
                        },
                    });
                    break;
                case ProtectedAssetSections.mandatory:
                    setFields({
                        ...fields,
                        mandatory: {
                            ...fields.mandatory,
                            [namespace]: {
                                ...fields.mandatory[namespace],
                                data: value,
                                shouldTemporarilyNotDisableInput: true,
                            },
                        },
                    });
                    break;
                case ClaimsSections.description:
                    const findIndexWhereNamespaceIsEqual = fields.info.findIndex(
                        (item: any) => item.namespace === namespace,
                    );
                    if (findIndexWhereNamespaceIsEqual !== -1) {
                        const updatedInfo = [...fields.info];
                        updatedInfo[findIndexWhereNamespaceIsEqual] = {
                            ...updatedInfo[findIndexWhereNamespaceIsEqual],
                            data: value,
                            shouldTemporarilyNotDisableInput: true,
                        };
                        setFields({
                            ...fields,
                            info: updatedInfo,
                        });
                    }
                    break;
                default:
            }
        },
        [fields, setFields],
    );

    const flags = useMemo(() => fields?.user?.flags?.data, [fields]);

    const changedFields = useMemo(() => diff(data, fields), [data, fields]);

    /**
     * @description This payload format will be used for Policyholder and ProtectedAsset features
     * Where the data is indexed by the namespace, i.e.:
     * @example [{ namespace: 'info', data: 'value' }]
     * Whereas in the Claims feature, the data is not indexed by the namespace, i.e.:
     * @example [ 0: { namespace: 'info', data: 'value' }]
     */
    const indexedPayload = useMemo(() => {
        const output = changedFields;
        if (!output) return [];
        const sections = Object.values(output);
        const innerValues = sections
            .flatMap(section => {
                if (!section) return [];
                const keys = Object.keys(section);
                return keys.map(key => {
                    if (!section[key]) return {};
                    return {
                        [key]: section[key].data,
                    };
                });
            })
            .filter(item => item !== null && typeof item === 'object')
            .map(item => {
                const key = Object.keys(item)[0];
                const value = Object.values(item)[0];
                if (key === undefined || value === undefined) return {};
                return {
                    namespace: key,
                    data: value,
                };
            })
            .filter(item => item !== null && typeof item === 'object');

        return innerValues;
    }, [changedFields]);

    /**
     * @description This payload format will be used for Claims feature
     * Where the data is not indexed by the namespace, but as a number, i.e.:
     * @example [ 0: { namespace: 'info', data: 'value' }]
     * And different from the Policyholders and ProtectedAssets, the url contains the id of the namespace
     * And only the data is sent to the backend as a payload to update the claim
     */
    const nonIndexedPayload = useMemo(() => {
        // @ts-ignore
        let f: {
            info: {
                [key: number]: {
                    data: string;
                };
            };
        } = { ...changedFields };

        if (f?.info) {
            const nonIndexedPayload: {
                data: string;
                id: string;
            }[] = Object.keys(f.info).map((key: string) => {
                return {
                    data: f.info?.[key]?.data,
                    id: fields.info?.[key]?.id,
                };
            });

            return nonIndexedPayload;
        }

        return [];
    }, [changedFields, fields?.info]);

    const handleUpdateInsureeData = useCallback(async () => {
        try {
            const product_id =
                window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
            return await updateInsureeData({
                product_id,
                insuree_id: data?.insuree_id,
                data: indexedPayload,
            });
        } catch (error) {
            console.log(error);
        } finally {
            setShouldLetEdit(false);
        }
    }, [data?.insuree_id, indexedPayload]);

    const handleUpdateProtectedAssetData = useCallback(async () => {
        try {
            const protected_asset_id = data?.id;
            const quote_id = options?.quoteId;
            const application_id =
                window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
            return await updateProtectedAssetData({
                quote_id,
                protected_asset_id,
                application_id,
                data: indexedPayload,
            });
        } catch (error) {
            console.log(error);
        } finally {
            setShouldLetEdit(false);
        }
    }, [data?.id, options?.quoteId, indexedPayload]);

    const handleUpdateClaimData = useCallback(async () => {
        if (nonIndexedPayload && Array.isArray(nonIndexedPayload) && nonIndexedPayload.length > 0) {
            return Promise.all(
                nonIndexedPayload.map(async item => {
                    try {
                        const application_id =
                            window.location.pathname.split('/')[
                                window.location.pathname.split('/').length - 1
                            ];
                        return await updateClaimData({
                            application_id,
                            claim_id: data?.id,
                            property_id: item.id,
                            data: item.data,
                        });
                    } catch (error) {
                        console.log(error);
                    }
                }),
            );
        } else {
            setShouldLetEdit(false);
        }
    }, [data?.id, nonIndexedPayload]);

    const updateData = useCallback(
        async (feature: Feature) => {
            switch (feature) {
                case Feature.policyholder:
                    return await handleUpdateInsureeData();
                case Feature.protectedAsset:
                    return await handleUpdateProtectedAssetData();
                case Feature.claims:
                    return await handleUpdateClaimData();
                default:
                    break;
            }
        },
        [handleUpdateInsureeData, handleUpdateProtectedAssetData, handleUpdateClaimData],
    );

    const handleClick = useCallback(
        async fetchData => {
            const fallback = () => {
                setFields(data);
            };

            setShouldLetEdit(!shouldLetEdit);

            if (!shouldLetEdit) return;

            const handleIndexedPayload = async () => {
                if (indexedPayload?.length > 0) {
                    const updatedForm = await updateData(feature);
                    if (updatedForm) {
                        if (typeof fetchData === 'function') {
                            fetchData();
                        } else {
                            window.location.reload();
                        }
                        sendSuccessToast();
                    } else {
                        fallback();
                    }
                }
            };

            const handleNonIndexedPayload = async () => {
                if (nonIndexedPayload?.length > 0) {
                    const updatedForm = await updateData(feature);
                    if (updatedForm) {
                        if (typeof fetchData === 'function') {
                            fetchData();
                        } else {
                            window.location.reload();
                        }
                        sendSuccessToast();
                    } else {
                        fallback();
                    }
                }
            };

            try {
                switch (feature) {
                    case Feature.policyholder:
                    case Feature.protectedAsset:
                        handleIndexedPayload();
                        break;
                    case Feature.claims:
                        handleNonIndexedPayload();
                        break;
                    default:
                        break;
                }
            } catch (error) {
                console.error(error);
                fallback();
            }
        },
        [data, feature, indexedPayload.length, nonIndexedPayload.length, shouldLetEdit, updateData],
    );

    useEffect(() => {
        mapInputsOnRef(data);
    }, [mapInputsOnRef, data]);

    return {
        shouldLetEdit,
        fields,
        handleChangeInputField,
        flags,
        setShouldLetEdit,
        PolicyholderSections,
        ProtectedAssetSections,
        setFields,
        handleUpdateInsureeData,
        handleClick,
        doesDataIncludeAnyEditableField,
        found,
    };
};

export default useDynamic;
