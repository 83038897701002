import React from 'react';
import PropTypes from 'prop-types';
import Styles from './NivoBarChart.module.scss';
import { ResponsiveBar } from '@nivo/bar';
import { fakeData } from './__MOCKS__';

export class NivoBarChart extends React.PureComponent {
    state = {
        fakeData: null,
    };

    static propTypes = {
        data: PropTypes.object,
    };

    componentDidMount() {
        if (!this.props.data) {
            this.setState({ fakeData: fakeData });
        }
    }

    render() {
        const { fakeData } = this.state;

        return (
            <div className={Styles.chart}>
                <ResponsiveBar
                    indexBy="type"
                    label={d => `${d.value}%`}
                    labelTextColor="#ffffff"
                    data={fakeData}
                    keys={[
                        'Crash detected',
                        'Highway driver',
                        'Legal driver',
                        'Smooth driver',
                        'Short commuter',
                    ]}
                    margin={{ top: 50, right: 30, bottom: 50, left: 150 }}
                    padding={0.3}
                    innerPadding={3}
                    layout="horizontal"
                    colors={['#eb7987', '#bfcdf0', '#99a9d0', '#64759a', '#38466b']}
                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={null}
                    axisLeft={null}
                    enableGridY={false}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'left',
                            direction: 'column',
                            justify: false,
                            translateX: -150,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                />
            </div>
        );
    }
}

export default NivoBarChart;
