import './nprogress.css';
import NProgress from 'nprogress';
import axios from 'axios';
import { apiHandleErrorResponse } from 'services/api';

const calculatePercentage = (loaded, total) => Math.floor(loaded * 1.0) / total;

const setupUpdateProgress = () => {
    axios.defaults.onDownloadProgress = e => {
        const percentage = calculatePercentage(e.loaded, e.total);
        NProgress.set(percentage);
    };
};

const setupStartProgress = () => {
    axios.interceptors.request.use(response => {
        NProgress.start();
        return response;
    });
};

const setupStopProgress = () => {
    axios.interceptors.response.use(
        response => {
            NProgress.done(true);
            return response;
        },
        error => {
            NProgress.done(true);

            apiHandleErrorResponse(error);

            return Promise.reject(error);
        },
    );
};

export function NProgressLoad(config) {
    NProgress.configure(config);
    setupStartProgress();
    setupUpdateProgress();
    setupStopProgress();
}
