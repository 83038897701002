import { getText } from 'localization';
import React, { useState } from 'react';
import { AppContextType, ConfigCard } from 'types';
import { AuthorizationType, Fields, Radios, RegistrationType } from 'types/distributors/register';
import { PortalTypes } from 'utils/constants';

export const AppContext = React.createContext<AppContextType>(null);

const AppProvider = ({ children }) => {
    const [flows, setFlows] = useState<[]>();
    const [allRemovedEntities, setAllRemovedEntities] = useState([]);
    const [products, setProducts] = useState([]);
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [observer, setObserver] = useState(0);
    const [distributorSellerId, setDistributorSellerId] = useState('');
    const [lastProducts, setLastProducts] = useState([]);
    const [lastDistributionPoints, setLastDistributionPoints] = useState([]);
    const [distributionPoints, setDistributionPoints] = useState([]);
    const [alreadyFilledSteps, setAlreadyFilledSteps] = useState([]);
    const [userRegistrationType, setUserRegistrationType] = useState<RegistrationType>('');
    const [authorizationType, setAuthorizationType] = useState<AuthorizationType>('');
    const [checkedProducts, setCheckedProducts] = useState<
        Array<{
            _id: string;
            app_id: string;
            insurer_id: string;
            allowed_distribution_flows: PortalTypes[];
        }>
    >([]);
    const [headerElements, setHeaderElements] = useState<Radios>();
    const [registerFields, setRegisterFields] = useState<Array<Fields>>([
        {
            name: '',
            country: 'pt',
            documentType: 'nif',
            documentValue: '',
            cellphoneCountry: 'pt',
            cellphoneNumber: '',
            website: null,
            postalCode: '',
            city: '',
            region: '',
            address: '',
            empty: '',
        },
        {
            account_owner: '',
            empty: '',
            bank_name: '',
            bank_number: '',
        },
        {
            authorized_branch: '',
            authorized_person_to_sign: '',
            function: '',
            permanent_certificate_number: '',
            policy_number: '',
            policy_insurer_name: '',
            asf_number: '',
        },
    ]);
    const [providerProductSettings, setProviderProductSettings] = useState<ConfigCard[]>([
        {
            title: getText('distributor_admin_policy_type_question'),
            namespace: 'policy_type',
            buttons: {
                first: {
                    namespace: 'policy_type_predefined',
                    text: getText('distributor_admin_policy_type_predefined'),
                    value: 'predefined',
                    selected: true,
                },
                second: {
                    namespace: 'policy_type_custom',
                    text: getText('distributor_admin_policy_type_custom'),
                    value: 'custom',
                    shouldOpenJSONEditor: true,
                    JSONEditorValue: '',
                    selected: false,
                },
            },
        },
        {
            namespace: 'customer_selfcare',
            title: getText('distributor_admin_has_customer_selfcare_question'),
            buttons: {
                first: {
                    namespace: 'customer_selfcare_yes',
                    text: getText('common_yes_label'),
                    value: 'yes',
                    shouldOpenJSONEditor: true,
                    JSONEditorValue: '',
                    selected: false,
                },
                second: {
                    namespace: 'customer_selfcare_no',
                    text: getText('common_no_label'),
                    value: 'no',
                    selected: true,
                },
            },
        },
        {
            namespace: 'cdata_public',
            title: getText('distributor_admin_has_distributor_specific_configuration_question'),
            buttons: {
                first: {
                    namespace: 'cdata_public_yes',
                    text: getText('common_yes_label'),
                    value: 'yes',
                    shouldOpenJSONEditor: true,
                    selected: false,
                    JSONEditorValue: '',
                },
                second: {
                    namespace: 'cdata_public_no',
                    text: getText('common_no_label'),
                    value: 'no',
                    selected: true,
                },
            },
        },
        {
            namespace: 'specific_premiums',
            title: getText('distributor_admin_has_distributor_specific_premium_question'),
            buttons: {
                first: {
                    namespace: 'specific_premiums_yes',
                    text: getText('common_yes_label'),
                    value: 'yes',
                    shouldOpenJSONEditor: true,
                    JSONEditorValue: '',
                    selected: false,
                },
                second: {
                    namespace: 'specific_premiums_no',
                    text: getText('common_no_label'),
                    value: 'no',
                    selected: true,
                },
            },
        },
        {
            namespace: 'selfcare_behavior',
            title: getText('distributor_admin_selfcare_behavior_question'),
            buttons: [
                {
                    first: {
                        namespace: 'selfcare_behavior_distributor',
                        text: getText('distributor_admin_selfcare_behavior_for_distributor'),
                        value: 'distributor',
                        selected: true,
                    },
                },
                {
                    first: {
                        namespace: 'selfcare_behavior_seller',
                        text: getText('distributor_admin_selfcare_behavior_for_distributor_seller'),
                        value: 'seller',
                        selected: true,
                    },
                },
                {
                    first: {
                        namespace: 'selfcare_behavior_lead',
                        text: getText('distributor_admin_selfcare_behavior_for_distributor_lead'),
                        value: 'lead',
                        selected: true,
                    },
                },
            ],
            radios: [
                {
                    first: {
                        namespace: 'selfcare_behavior_with_predefined_access',
                        text: getText('distributor_admin_selfcare_behavior_with_predefined_access'),
                        value: 'predefined',
                        scope: 'distributor',
                        selected: true,
                    },
                    second: {
                        namespace: 'selfcare_behavior_with_custom_access',
                        text: getText('distributor_admin_selfcare_behavior_with_custom_access'),
                        value: 'custom',
                        shouldOpenJSONEditor: true,
                        JSONEditorValue: '',
                        selected: false,
                        scope: 'distributor',
                    },
                    third: {
                        namespace: 'selfcare_behavior_without_access',
                        text: getText('distributor_admin_selfcare_behavior_without_access'),
                        value: 'none',
                        selected: false,
                        scope: 'distributor',
                    },
                },
                {
                    first: {
                        namespace: 'selfcare_behavior_with_predefined_access',
                        text: getText('distributor_admin_selfcare_behavior_with_predefined_access'),
                        value: 'predefined',
                        selected: true,
                        scope: 'seller',
                    },
                    second: {
                        namespace: 'selfcare_behavior_with_custom_access',
                        text: getText('distributor_admin_selfcare_behavior_with_custom_access'),
                        value: 'custom',
                        shouldOpenJSONEditor: true,
                        JSONEditorValue: '',
                        selected: false,
                        scope: 'seller',
                    },
                    third: {
                        namespace: 'selfcare_behavior_without_access',
                        text: getText('distributor_admin_selfcare_behavior_without_access'),
                        value: 'none',
                        selected: false,
                        scope: 'seller',
                    },
                },
                {
                    first: {
                        namespace: 'selfcare_behavior_with_predefined_access',
                        text: getText('distributor_admin_selfcare_behavior_with_predefined_access'),
                        value: 'predefined',
                        selected: true,
                        scope: 'lead',
                    },
                    second: {
                        namespace: 'selfcare_behavior_with_custom_access',
                        text: getText('distributor_admin_selfcare_behavior_with_custom_access'),
                        value: 'custom',
                        shouldOpenJSONEditor: true,
                        JSONEditorValue: '',
                        selected: false,
                        scope: 'lead',
                    },
                    third: {
                        namespace: 'selfcare_behavior_without_access',
                        text: getText('distributor_admin_selfcare_behavior_without_access'),
                        value: 'none',
                        selected: false,
                        scope: 'lead',
                    },
                },
            ],
        },
    ]);
    const [distributorProductSettings, setDistributorProductSettings] = useState<ConfigCard[]>([
        {
            title: getText('distributor_admin_policy_type_question'),
            namespace: 'policy_type',
            buttons: {
                first: {
                    namespace: 'policy_type_predefined',
                    text: getText('distributor_admin_policy_type_predefined'),
                    value: 'predefined',
                    selected: true,
                },
                second: {
                    namespace: 'policy_type_custom',
                    text: getText('distributor_admin_policy_type_custom'),
                    value: 'custom',
                    shouldOpenJSONEditor: true,
                    JSONEditorValue: '',
                    selected: false,
                },
            },
        },
        {
            namespace: 'customer_selfcare',
            title: getText('distributor_admin_has_customer_selfcare_question'),
            buttons: {
                first: {
                    namespace: 'customer_selfcare_yes',
                    text: getText('common_yes_label'),
                    value: 'yes',
                    shouldOpenJSONEditor: true,
                    JSONEditorValue: '',
                    selected: false,
                },
                second: {
                    namespace: 'customer_selfcare_no',
                    text: getText('common_no_label'),
                    value: 'no',
                    selected: true,
                },
            },
        },
        {
            namespace: 'cdata_public',
            title: getText('distributor_admin_has_distributor_specific_configuration_question'),
            buttons: {
                first: {
                    namespace: 'cdata_public_yes',
                    text: getText('common_yes_label'),
                    value: 'yes',
                    shouldOpenJSONEditor: true,
                    selected: false,
                    JSONEditorValue: '',
                },
                second: {
                    namespace: 'cdata_public_no',
                    text: getText('common_no_label'),
                    value: 'no',
                    selected: true,
                },
            },
        },
        {
            namespace: 'specific_premiums',
            title: getText('distributor_admin_has_distributor_specific_premium_question'),
            buttons: {
                first: {
                    namespace: 'specific_premiums_yes',
                    text: getText('common_yes_label'),
                    value: 'yes',
                    shouldOpenJSONEditor: true,
                    JSONEditorValue: '',
                    selected: false,
                },
                second: {
                    namespace: 'specific_premiums_no',
                    text: getText('common_no_label'),
                    value: 'no',
                    selected: true,
                },
            },
        },
        {
            namespace: 'selfcare_behavior',
            title: getText('distributor_admin_selfcare_behavior_question'),
            buttons: [
                {
                    first: {
                        namespace: 'selfcare_behavior_distributor',
                        text: getText('distributor_admin_selfcare_behavior_for_distributor'),
                        value: 'distributor',
                        selected: true,
                    },
                },
                {
                    first: {
                        namespace: 'selfcare_behavior_seller',
                        text: getText('distributor_admin_selfcare_behavior_for_distributor_seller'),
                        value: 'seller',
                        selected: true,
                    },
                },
                {
                    first: {
                        namespace: 'selfcare_behavior_lead',
                        text: getText('distributor_admin_selfcare_behavior_for_distributor_lead'),
                        value: 'lead',
                        selected: true,
                    },
                },
            ],
            radios: [
                {
                    first: {
                        namespace: 'selfcare_behavior_with_predefined_access',
                        text: getText('distributor_admin_selfcare_behavior_with_predefined_access'),
                        value: 'predefined',
                        scope: 'distributor',
                        selected: true,
                    },
                    second: {
                        namespace: 'selfcare_behavior_with_custom_access',
                        text: getText('distributor_admin_selfcare_behavior_with_custom_access'),
                        value: 'custom',
                        shouldOpenJSONEditor: true,
                        JSONEditorValue: '',
                        selected: false,
                        scope: 'distributor',
                    },
                    third: {
                        namespace: 'selfcare_behavior_without_access',
                        text: getText('distributor_admin_selfcare_behavior_without_access'),
                        value: 'none',
                        selected: false,
                        scope: 'distributor',
                    },
                },
                {
                    first: {
                        namespace: 'selfcare_behavior_with_predefined_access',
                        text: getText('distributor_admin_selfcare_behavior_with_predefined_access'),
                        value: 'predefined',
                        selected: true,
                        scope: 'seller',
                    },
                    second: {
                        namespace: 'selfcare_behavior_with_custom_access',
                        text: getText('distributor_admin_selfcare_behavior_with_custom_access'),
                        value: 'custom',
                        shouldOpenJSONEditor: true,
                        JSONEditorValue: '',
                        selected: false,
                        scope: 'seller',
                    },
                    third: {
                        namespace: 'selfcare_behavior_without_access',
                        text: getText('distributor_admin_selfcare_behavior_without_access'),
                        value: 'none',
                        selected: false,
                        scope: 'seller',
                    },
                },
                {
                    first: {
                        namespace: 'selfcare_behavior_with_predefined_access',
                        text: getText('distributor_admin_selfcare_behavior_with_predefined_access'),
                        value: 'predefined',
                        selected: true,
                        scope: 'lead',
                    },
                    second: {
                        namespace: 'selfcare_behavior_with_custom_access',
                        text: getText('distributor_admin_selfcare_behavior_with_custom_access'),
                        value: 'custom',
                        shouldOpenJSONEditor: true,
                        JSONEditorValue: '',
                        selected: false,
                        scope: 'lead',
                    },
                    third: {
                        namespace: 'selfcare_behavior_without_access',
                        text: getText('distributor_admin_selfcare_behavior_without_access'),
                        value: 'none',
                        selected: false,
                        scope: 'lead',
                    },
                },
            ],
        },
    ]);
    const [documents, setDocuments] = useState<Array<any>>([]);
    const [version, setVersion] = useState<string>(null);
    const [backendWrittenFields, setBackendWrittenFields] = useState<Array<Fields>>([]);
    const [isDebugging, setIsDebugging] = useState(false);

    const initialValue = {
        admin: {
            products: {
                providers: {
                    productsConfiguration: {
                        providerProductSettings,
                        setProviderProductSettings,
                        documents,
                        setDocuments,
                        version,
                        setVersion,
                    },
                    addProductsModal: {
                        checkedProducts,
                        setCheckedProducts,
                    },
                    financialFlow: {
                        financialFlowModal: {
                            setFlows,
                            flows,
                            allRemovedEntities,
                            setAllRemovedEntities,
                            products,
                            setProducts,
                            distributionPoints,
                            setDistributionPoints,
                        },
                        paymentSection: {
                            transactions: {
                                datePicker: {
                                    year,
                                    month,
                                    setYear,
                                    setMonth,
                                },
                                observer,
                                setObserver,
                            },
                        },
                    },
                },
                distributors: {
                    productsConfiguration: {
                        distributorProductSettings,
                        setDistributorProductSettings,
                        documents,
                        setDocuments,
                        version,
                        setVersion,
                    },
                    addProductsModal: {
                        checkedProducts,
                        setCheckedProducts,
                    },
                    financialFlow: {
                        financialFlowModal: {
                            setFlows,
                            flows,
                            allRemovedEntities,
                            setAllRemovedEntities,
                            products,
                            setProducts,
                            distributionPoints,
                            setDistributionPoints,
                        },
                        paymentSection: {
                            transactions: {
                                datePicker: {
                                    year,
                                    month,
                                    setYear,
                                    setMonth,
                                },
                                observer,
                                setObserver,
                            },
                        },
                    },
                },
            },
        },
        distributors: {
            entities: {
                distributors: {
                    distributorSellerId,
                    setDistributorSellerId,
                    lastProducts,
                    setLastProducts,
                    lastDistributionPoints,
                    setLastDistributionPoints,
                },
                vendors: {
                    distributorSellerId,
                    setDistributorSellerId,
                    lastProducts,
                    setLastProducts,
                    lastDistributionPoints,
                    setLastDistributionPoints,
                },
            },
        },
        register: {
            header: {
                headerElements,
                setHeaderElements,
            },
            fields: {
                userRegistrationType,
                setUserRegistrationType,
                authorizationType,
                setAuthorizationType,
                registerFields,
                setRegisterFields,
                backendWrittenFields,
                setBackendWrittenFields,
            },
            alreadyFilledSteps,
            setAlreadyFilledSteps,
        },
        global: {
            isDebugging,
            setIsDebugging,
        },
    };

    return <AppContext.Provider value={initialValue}>{children}</AppContext.Provider>;
};

export default AppProvider;
