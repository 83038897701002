import classnames from 'classnames';
import { getText } from 'localization';
import PropTypes from 'prop-types';
import React from 'react';
import { getViewportAlert, setViewportAlert } from 'services/localStorage';
import Button from 'shared/Button';
import ErrorBoundary from 'shared/ErrorBoundary';
import Styles from './ViewportAlert.module.scss';

export class ViewportAlert extends React.PureComponent {
    state = {
        visible: false,
    };

    static propTypes = {
        texts: PropTypes.object,
    };

    componentDidMount() {
        const alert = getViewportAlert();

        if (!alert) {
            this.setState({ visible: true });
        }
    }

    handleOnClick = e => {
        setViewportAlert();
        this.setState({ visible: false });
    };

    render() {
        const { texts } = this.props;
        const { visible } = this.state;

        if (!visible || !texts) return null;

        return (
            <ErrorBoundary>
                <div className={classnames(Styles.alert)}>
                    <div className={Styles.content}>
                        <div className={Styles.icon}>
                            <i className="icon-icon_about"></i>
                        </div>
                        <div className={Styles.message}>
                            <p
                                data-testid="ViewportAlertMessage1"
                                dangerouslySetInnerHTML={{
                                    __html: getText('viewport_alert_message_1'),
                                }}
                            />
                            <p
                                data-testid="ViewportAlertMessage2"
                                dangerouslySetInnerHTML={{
                                    __html: getText('viewport_alert_message_2'),
                                }}
                            />
                        </div>
                        <div className={Styles.action}>
                            <Button
                                data-testid="ViewportAlertButton"
                                theme="ternary"
                                variant="filled"
                                type="button"
                                value={getText('viewport_alert_got_it')}
                                onClick={this.handleOnClick}
                            />
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
        );
    }
}

export default ViewportAlert;
