export const swrCacheKeys = {
    DISTRIBUTORS_INSURANCE_PRODUCTS: `/api/distributors/insurance/products`,
    DISTRIBUTORS_META_CDATA: `/api/distributors/meta/cdata`,
    DISTRIBUTORS_STORES_LIST: `/api/distributors/stores/list`,
    DISTRIBUTORS_SELLER_INVITES: `/api/distributors/seller/invites`,
    DISTRIBUTORS_SELLER_INVITE_STORES: `/api/distributors/seller/invites/stores`,
    SELLERS_INVITES: `/api/sellers/invites`,
    INSURANCE_INSUREE_FINDER: `/api/insurance/insuree/finder`,
    INSURANCE_VIEW: `/api/insurance/view`,
    INSURANCE_ASSETS: `/api/insurance/assets`,
    INSURANCE_CLAIM_CHAT: `/api/insurance/claim/chat`,
    BOARD_PROJECTS: `/api/board/projects`,
    BOARD_INTEGRATIONS: `/api/board/integrations`,
};
