import React from 'react';
import PropTypes from 'prop-types';
import { checkAuthWithRedirect } from 'utils/functions';

const withAuth = WrappedComponent => {
    class Auth extends React.PureComponent {
        static propTypes = {
            location: PropTypes.object,
            history: PropTypes.object,
        };

        componentDidMount() {
            this.handleLogout();
        }

        componentDidUpdate(prevProps, prevState) {
            this.handleLogout();
        }

        handleLogout = async () => {
            await checkAuthWithRedirect();
        };

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    return Auth;
};

export default withAuth;
