import ServiceDirectory from 'pages/Networks/ServiceDirectory';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    clearNetworksAction,
    networksOpenElementModalAction,
} from 'redux/domains/networks/actions';
import { networksGetDirectoryObs } from 'redux/domains/networks/observer';
import { getDirectorySelector, NETWORKS_TYPE_SERVICE } from 'redux/domains/networks/selector';
import {
    networksDeleteElementThunk,
    networksEditDirectoryThunk,
} from 'redux/domains/networks/thunk';
import { openPromptAction } from 'redux/domains/prompt/actions';

const mapStateToProps = (state, ownProps) => ({
    texts: state.texts,
    isLoading: state.app.isLoading,
    isSaving: state.networks.isSaving,
    services: state.networks.services.list,
    directory: getDirectorySelector(state, ownProps, NETWORKS_TYPE_SERVICE),
    activeModal: state.networks.services.service?.active,
    tableHeader: state.networks.services.header,
    tableFilters: state.networks.services.filters,
    tablePagination: state.networks.services.pagination,
    ownProps: ownProps,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    openPrompt: data => dispatch(openPromptAction(data)),
    getDirectory: () => networksGetDirectoryObs(dispatch, ownProps, null, NETWORKS_TYPE_SERVICE),
    onEditDirectory: data =>
        networksEditDirectoryThunk(dispatch, data, ownProps, NETWORKS_TYPE_SERVICE),
    onOpenServiceModal: data =>
        dispatch(networksOpenElementModalAction(data, NETWORKS_TYPE_SERVICE)),
    onDeleteService: data =>
        networksDeleteElementThunk(dispatch, data, ownProps, NETWORKS_TYPE_SERVICE),
    onClearNetwork: () => dispatch(clearNetworksAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceDirectory));
