import { admin } from 'redux/store/initialState/admin';
import { analytics } from 'redux/store/initialState/analytics';
import { app } from 'redux/store/initialState/app';
import { board } from 'redux/store/initialState/board';
import { builds } from 'redux/store/initialState/builds';
import { businessRuleEngine } from 'redux/store/initialState/businessRuleEngine';
import { compatibleDevices } from 'redux/store/initialState/compatibleDevices';
import { distributors } from 'redux/store/initialState/distributors';
import { providers } from 'redux/store/initialState/providers';
import { endUsers } from 'redux/store/initialState/endUsers';
import { eventsSimulator } from 'redux/store/initialState/eventsSimulator';
import { flowTester } from 'redux/store/initialState/flowTester';
import { insurance } from 'redux/store/initialState/insurance';
import { integration } from 'redux/store/initialState/integration';
import { integrations } from 'redux/store/initialState/integrations';
import { navigation } from 'redux/store/initialState/navigation';
import { networks } from 'redux/store/initialState/networks';
import { project } from 'redux/store/initialState/project';
import { projects } from 'redux/store/initialState/projects';
import { prompt } from 'redux/store/initialState/prompt';
import { sellers } from 'redux/store/initialState/sellers';
import { successRateIntervals } from 'redux/store/initialState/successRateIntervals';
import { teamMembers } from 'redux/store/initialState/teamMembers';
import { templates } from 'redux/store/initialState/templates';
import { texts } from 'redux/store/initialState/texts';
import { usecases } from 'redux/store/initialState/usecases';
import { whiteLabel } from 'redux/store/initialState/whiteLabel';

const initialState = {
    admin: admin,
    analytics: analytics,
    app: app,
    board: board,
    builds: builds,
    businessRuleEngine: businessRuleEngine,
    compatibleDevices: compatibleDevices,
    distributors: distributors,
    providers: providers,
    endUsers: endUsers,
    eventsSimulator: eventsSimulator,
    flowTester: flowTester,
    integration: integration,
    integrations: integrations,
    insurance: insurance,
    navigation: navigation,
    networks: networks,
    project: project,
    projects: projects,
    prompt: prompt,
    sellers: sellers,
    successRateIntervals: successRateIntervals,
    teamMembers: teamMembers,
    templates: templates,
    texts: texts,
    usecases: usecases,
    whiteLabel: whiteLabel,
};

export default initialState;
