export const approvalRequestsTableListModel = payload => {
    return payload.map(item => {
        return {
            id: item.id,
            name: item?.user?.name ? item.user.name : null,
            company: item.name ? item.name : null,
            email: item?.user?.email ? item.user.email : null,
            website: item.website ? item.website : null,
            state: item.state ? approvalRequestStateMap(item.state) : null,
            type: item.type ? item.type : null,
            created: item.created ? item.created : null,
            approved_ts: item.updated ? item.updated : null,
            pending_ts: item.updated ? item.updated : null,
            rejected_ts: item.updated ? item.updated : null,
            // approved_ts: item.approved_ts ? item.approved_ts : null,
            // pending_ts: item.pending_ts ? item.pending_ts : null,
            // rejected_ts: item.rejected_ts ? item.rejected_ts : null,
            rejected_comment: item?.cdata?.reject?.comment ? item.cdata.reject.comment : null,
            user: item?.cdata?.reject?.user
                ? item.cdata.reject.user
                : item?.cdata?.pending?.user
                ? item.cdata.pending.user
                : null,
            cdata: item.cdata && Object.keys(item.cdata).length > 0 ? item.cdata : null,
        };
    });
};

const approvalRequestStateMap = type => {
    switch (type) {
        case 'pending':
        case 'submited':
        case 'submitted':
            return 'pending';
        case 'approved':
        case 'active':
            return 'approved';
        case 'rejected':
            return 'rejected';

        default:
            return 'N/D';
    }
};

export const financialEntitiesTableListModel = payload => {
    return payload.map(item => {
        return {
            id: item?.id ? item.id : null,
            account_id: item?.account_id ? item.account_id : null,
            name: item?.name ? item.name : null,
            default_financialentitytype: item.default_financialentitytype
                ? item.default_financialentitytype
                : null,
            type: item.default_financialentitytype ? item.default_financialentitytype : null,
            payout: item?.payout ? item.payout : null,
            country_id: item?.country_id ? item.country_id : null,
            country_name: item?.country?.name ? item?.country?.name : null,
            created: item?.created ? item.created : null,
            contact_details_phone_number: item?.contact_details?.phone_number
                ? item?.contact_details?.phone_number
                : null,
            contact_details_email: item?.contact_details?.email
                ? item?.contact_details?.email
                : null,
            contact_details_address: item?.contact_details?.address
                ? item?.contact_details?.address
                : null,
            contact_details_city: item?.contact_details?.city ? item?.contact_details?.city : null,
            contact_details_postal_code: item?.contact_details?.postal_code
                ? item?.contact_details?.postal_code
                : null,
            financial_details_identifier: item?.financial_details?.identifier
                ? item?.financial_details?.identifier
                : null,

            financial_details_bank_account_holder_name: item?.financial_details
                ?.bank_account_holder_name
                ? item?.financial_details?.bank_account_holder_name
                : null,
            financial_details_bic_swift_code: item?.financial_details?.bic_swift_code
                ? item?.financial_details?.bic_swift_code
                : null,
            financial_details_iban: item?.financial_details?.iban
                ? item?.financial_details?.iban
                : null,
            financial_details_payment_type: item?.financial_details?.payment_type
                ? item?.financial_details?.payment_type
                : null,
        };
    });
};

export const distributorsTableListModel = payload => {
    return payload.map(item => {
        return {
            id: item?.id ? item.id : null,
            name: item?.name ? item.name : null,
            type: item?.type ? item.type : null,
            state: item?.state ? item.state : null,
            namespace: item?.namespace ? item.namespace : null,
            email: item?.email ? item.email : null,
            apps: item?.apps ? item.apps : null,
            country: item?.country_id
                ? item.country_id === 'pt'
                    ? 'Portugal'
                    : item.country_id === 'br'
                    ? 'Brasil'
                    : item.country_id
                : null,
        };
    });
};

export const providersTableListModel = payload => {
    return payload.map(item => {
        return {
            id: item?.id ? item.id : null,
            name: item?.name ? item.name : null,
            type: item?.type ? item.type : null,
            state: item?.state ? item.state : null,
            namespace: item?.namespace ? item.namespace : null,
            email: item?.email ? item.email : null,
            country: item?.country_id
                ? item.country_id === 'pt'
                    ? 'Portugal'
                    : item.country_id
                : null,
        };
    });
};
