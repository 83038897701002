import { getText } from 'localization';
import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';
import Button from 'shared/Button';
import Dropdown from 'shared/Dropdown';
import ErrorBoundary from 'shared/ErrorBoundary';
import Input from 'shared/Input';
import { INSURANCE_CLAIM_STATUS_LIST } from 'utils/constants';
import { cleanMaskedBirthForSubmit, cleanMaskedForSubmit } from 'utils/functions';
import Styles from './AdvancedSearch.module.scss';

export class AdvancedSearch extends React.PureComponent {
    state = {
        overlayId: 'advancedSearchOverlay',
        containerId: 'advancedSearchContainer',
        canSearch: false,
        status: {
            selected: null,
            list: INSURANCE_CLAIM_STATUS_LIST,
        },
        fields: {
            policy_number: '',
            claim_number: '',
            status: '',
            submission_date: '',
        },
    };

    static propTypes = {
        texts: PropTypes.object,
        isLoading: PropTypes.bool,
        data: PropTypes.object,
        advancedSearchStates: PropTypes.array,
        customSearchStates: PropTypes.array,
        onAdvancedSearchSubmit: PropTypes.func,
        onClear: PropTypes.func,
        onToggle: PropTypes.func,
    };

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, false);
        if (this.props.advancedSearchStates) {
            this.setState(
                state => ({
                    status: {
                        ...state.status,
                        list: this.props.advancedSearchStates,
                    },
                }),
                () => {},
            );
        }

        if (
            this.props.data &&
            this.props.data.fields &&
            Object.entries(this.props.data).length > 0 &&
            Object.entries(this.props.data.fields).length > 0
        ) {
            this.syncPropsToState();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, false);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.data &&
            this.props.data &&
            prevProps.data.fields &&
            this.props.data.fields &&
            Object.entries(this.props.data).length > 0 &&
            Object.entries(this.props.data.fields).length > 0 &&
            prevProps.data.fields !== this.props.data.fields
        ) {
            this.syncPropsToState();
        }
    }

    syncPropsToState = () => {
        this.setState(
            state => ({
                fields: { ...this.props.data.fields },
                status: {
                    ...state.status,
                    selected: this.props.data.fields.custom_status
                        ? this.props.data.fields.custom_status
                        : this.props.data.fields.status
                        ? this.props.data.fields.status
                        : null,
                    list: this.props.advancedSearchStates,
                },
            }),
            () => {
                this.handleValidation();
            },
        );
    };

    handleClickOutside = ({ target }) => {
        if (target) {
            const { overlayId, containerId } = this.state;
            const specifiedElement = document.getElementById(containerId);
            const targetId = target.id;
            const isClickInside = specifiedElement.contains(target);

            if (targetId && targetId === overlayId && !isClickInside) {
                this.props.onToggle();
            }
        }
    };

    handleOnChange = e => {
        const target = e.target;
        const value = target.type && target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            state => ({
                fields: {
                    ...state.fields,
                    [name]: value,
                },
            }),
            () => {
                this.handleValidation();
            },
        );
    };

    handleOnChangeDropdown = data => {
        if (!data.id) {
            const newState = Object.keys(this.state.fields).reduce((object, key) => {
                if (key !== data.name) {
                    object[key] = this.state.fields[key];
                }
                return object;
            }, {});

            this.setState(
                state => ({
                    [data.name]: {
                        ...state[data.name],
                        selected: data.id,
                    },
                    fields: {
                        ...newState,
                    },
                }),
                () => {
                    this.handleValidation();
                    this.handleOnSubmit();
                },
            );
        } else {
            this.setState(
                state => ({
                    [data.name]: {
                        ...state[data.name],
                        selected: data.id,
                    },
                    fields: {
                        ...state.fields,
                        [data.name]: data.id,
                    },
                }),
                () => {
                    this.handleValidation();
                },
            );
        }
    };

    handleOnClear = () => {
        this.setState(
            state => ({
                canSearch: false,
                fields: {
                    policy_number: '',
                    claim_number: '',
                    status: '',
                    submission_date: '',
                },
                status: {
                    ...state.status,
                    selected: null,
                },
            }),
            () => {
                this.props.onClear();
            },
        );
    };

    handleOnClearSingle = e => {
        const target = e.target;
        const name = target.getAttribute('data-name');

        this.setState(
            state => ({
                fields: {
                    ...state.fields,
                    [name]: '',
                },
            }),
            () => {
                this.handleValidation();
                this.handleOnSubmit();
            },
        );
    };

    handleOnSubmit = e => {
        if (e) {
            e.preventDefault();
        }
        const map = {
            identifier: ['io.habit.operations.fields.identifier'],
        };

        var fields = {
            ...this.state.fields,
            submission_date: cleanMaskedBirthForSubmit(this.state.fields.submission_date),
            policy_number: cleanMaskedForSubmit(this.state.fields.policy_number),
            claim_number: cleanMaskedForSubmit(this.state.fields.claim_number),
        };

        var tempStatus = this.state.fields.status.toLowerCase().replace(/ /g, '-');

        if (
            this.props.customSearchStates &&
            this.props.customSearchStates.some(e => e.data === tempStatus)
        ) {
            fields.status = 'custom';
            fields.custom_status = tempStatus;
        } else {
            fields.status = tempStatus;
            fields.custom_status = '';
        }

        this.props.onAdvancedSearchSubmit({
            map: map,
            fields: fields,
            closeSearch: e ? true : false,
        });
    };

    createStatusField = status => {};

    handleValidation = () => {
        let hasSearchField = false;
        Object.entries(this.state.fields).forEach(([key, value]) => {
            if (value !== '') {
                hasSearchField = true;
            }
        });

        if (!hasSearchField) {
            this.setState({ canSearch: false });
        } else {
            this.setState({ canSearch: true });
        }
    };

    render() {
        const { isLoading } = this.props;
        const { canSearch, fields, status, overlayId, containerId } = this.state;

        return (
            <ErrorBoundary>
                <div id={overlayId} className={Styles.overlay}>
                    <section
                        id={containerId}
                        data-test="AdvancedSearchContainer"
                        className={Styles.advancedSearch}
                    >
                        <form autoComplete="off" onSubmit={this.handleOnSubmit}>
                            <div className={Styles.row}>
                                <div className={Styles.col}>
                                    <p className={Styles.fieldTitle}>{getText('common_status')}</p>
                                    <Dropdown
                                        id="claimStatus"
                                        data={status.list}
                                        selected={status.selected}
                                        position="bottom"
                                        onChange={this.handleOnChangeDropdown}
                                        name="status"
                                        theme="select"
                                        variant="outlined"
                                        state={status.selected ? 'active' : null}
                                        block
                                        hasEmptyState
                                        placeholder={getText('common_select_label')}
                                    />
                                </div>

                                <div className={Styles.col}>
                                    <p className={Styles.fieldTitle}>
                                        {getText('common_submission_date_label')}
                                    </p>
                                    <InputMask
                                        data-test="Input"
                                        mask="9999-99-99"
                                        maskPlaceholder="_"
                                        value={
                                            fields.submission_date && fields.submission_date !== ''
                                                ? fields.submission_date
                                                : ''
                                        }
                                        onChange={this.handleOnChange}
                                        disabled={isLoading}
                                    >
                                        <Input
                                            type="tel"
                                            namespace=""
                                            name="submission_date"
                                            theme="ternary"
                                            variant="outlined"
                                            state={
                                                fields.submission_date &&
                                                fields.submission_date !== ''
                                                    ? 'active'
                                                    : null
                                            }
                                            placeholder="YYYY-MM-DD"
                                            value={
                                                fields.submission_date &&
                                                fields.submission_date !== ''
                                                    ? fields.submission_date
                                                    : ''
                                            }
                                            disabled={isLoading}
                                            onChange={this.handleOnChange}
                                            onClear={this.handleOnClearSingle}
                                        />
                                    </InputMask>
                                </div>
                            </div>

                            <div className={Styles.row}>
                                <div className={Styles.col}>
                                    <p className={Styles.fieldTitle}>
                                        {getText('insurance_policy_details_claim_number_label')}
                                    </p>
                                    <InputMask
                                        mask="aaa-999"
                                        maskPlaceholder="_"
                                        value={
                                            fields.claim_number && fields.claim_number !== ''
                                                ? fields.claim_number.toUpperCase()
                                                : ''
                                        }
                                        onChange={this.handleOnChange}
                                        disabled={isLoading}
                                    >
                                        <Input
                                            type="text"
                                            namespace=""
                                            name="claim_number"
                                            theme="ternary"
                                            variant="outlined"
                                            state={
                                                fields.claim_number && fields.claim_number !== ''
                                                    ? 'active'
                                                    : null
                                            }
                                            placeholder="JAD-346"
                                            value={
                                                fields.claim_number && fields.claim_number !== ''
                                                    ? fields.claim_number.toUpperCase()
                                                    : ''
                                            }
                                            disabled={isLoading}
                                            onChange={this.handleOnChange}
                                            onClear={this.handleOnClearSingle}
                                        />
                                    </InputMask>
                                </div>

                                <div className={Styles.col}>
                                    <p className={Styles.fieldTitle}>
                                        {getText('common_policy_number')}
                                    </p>
                                    <InputMask
                                        mask="aaa-999"
                                        maskPlaceholder="_"
                                        value={
                                            fields.policy_number && fields.policy_number !== ''
                                                ? fields.policy_number.toUpperCase()
                                                : ''
                                        }
                                        onChange={this.handleOnChange}
                                        disabled={isLoading}
                                    >
                                        <Input
                                            type="text"
                                            namespace=""
                                            name="policy_number"
                                            theme="ternary"
                                            variant="outlined"
                                            state={
                                                fields.policy_number && fields.policy_number !== ''
                                                    ? 'active'
                                                    : null
                                            }
                                            placeholder="JAD-346"
                                            value={
                                                fields.policy_number && fields.policy_number !== ''
                                                    ? fields.policy_number.toUpperCase()
                                                    : ''
                                            }
                                            disabled={isLoading}
                                            onChange={this.handleOnChange}
                                            onClear={this.handleOnClearSingle}
                                        />
                                    </InputMask>
                                </div>
                            </div>

                            <div className={Styles.actions}>
                                <div className={Styles.col}>
                                    <Button
                                        data-test="AdvancedSearchClear"
                                        theme="ternary"
                                        variant="outlined"
                                        type="button"
                                        value={getText('common_clear')}
                                        disabled={isLoading || !canSearch}
                                        onClick={this.handleOnClear}
                                        icon="icon_refresh"
                                    />
                                    <Button
                                        data-test="AdvancedSearchSubmit"
                                        theme="ternary"
                                        variant="filled"
                                        type="submit"
                                        value={getText('common_search')}
                                        disabled={isLoading || !canSearch}
                                        icon="icon_search"
                                    />
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
            </ErrorBoundary>
        );
    }
}

export default AdvancedSearch;
