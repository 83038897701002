import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './FilterCheck.module.scss';

export class FilterCheck extends React.PureComponent {
    static propTypes = {
        data: PropTypes.object,
        listId: PropTypes.number,
        onToggleFilter: PropTypes.func,
    };

    render() {
        const { data, onToggleFilter, listId } = this.props;

        return (
            <li
                data-test="FilterCheck"
                onClick={onToggleFilter}
                data-id={data.id}
                data-list-id={listId}
                className={classnames(Styles.container, data.selected ? Styles.selected : null)}
            >
                {data.label}
            </li>
        );
    }
}

export default FilterCheck;
