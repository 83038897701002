import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { lazy, Suspense } from 'react';
import ErrorBoundary from 'shared/ErrorBoundary';
import Loading from 'shared/Loading';
import SectionHeader from 'shared/SectionHeader';
import AdvancedSearch from './AdvancedSearch';
import Styles from './PaymentsDocuments.module.scss';
// import { isLocalEnv } from 'utils/functions';
import { getText } from 'localization';
import NoResults from 'shared/NoResults';

const Table = lazy(() => import('components/Insurance/PaymentsDocuments/Table'));

export class PaymentsDocuments extends React.PureComponent {
    state = {
        search: '',
        advancedSearch: {},
        isAdvancedSearch: false,
    };

    static propTypes = {
        texts: PropTypes.object,
        isLoading: PropTypes.bool,
        isSaving: PropTypes.bool,
        data: PropTypes.object,
        hasProjectSelected: PropTypes.bool,
        ownProps: PropTypes.object,
        onSearch: PropTypes.func,
        onClearSearch: PropTypes.func,
        fetchData: PropTypes.func,
        projectId: PropTypes.string,
        onClickPrev: PropTypes.func,
        onClickNext: PropTypes.func,
        onClickStart: PropTypes.func,
        onClickEnd: PropTypes.func,
        onSort: PropTypes.func,
        onSave: PropTypes.func,
        onUpload: PropTypes.func,
        onDeleteDocument: PropTypes.func,
        project: PropTypes.object,
    };

    componentDidMount() {
        const { data, ownProps, projectId, fetchData, onClearSearch } = this.props;
        const values = queryString.parse(ownProps.location.search);

        if (!ownProps?.match?.params?.app_id && projectId) {
            ownProps.history.replace(`${ownProps.location.pathname}/app/${projectId}`);
        }

        if (projectId && data && Object.entries(data.search).length > 0 && !values?.from) {
            onClearSearch && typeof onClearSearch === 'function' && onClearSearch();
        }

        if (projectId && data && Object.entries(data.search).length <= 1) {
            fetchData && typeof fetchData === 'function' && fetchData();
        }

        if (values && values.from && values.from === 'details') {
            this.setState({ isAdvancedSearch: false });
        }

        if (JSON.stringify(this.state.advancedSearch) !== JSON.stringify(data.search)) {
            this.setState((state, props) => ({
                advancedSearch: {
                    ...props.data.search,
                },
            }));
        }

        //TODO: remove condition when ready
        // if (!isLocalEnv() && !hasProjectSelected) {
        //     ownProps.history.replace('/insurance/dashboard');
        // }
    }

    componentDidUpdate(prevProps, prevState) {
        const { data, projectId, fetchData } = this.props;

        if (JSON.stringify(prevProps.data.search) !== JSON.stringify(data.search)) {
            this.setState({
                advancedSearch: {
                    ...this.props.data.search,
                },
            });
        }

        if (prevProps.projectId !== projectId) {
            fetchData && typeof fetchData === 'function' && fetchData();
        }
    }

    handleOnSearch = value => {
        this.setState(
            {
                search: value.toUpperCase(),
            },
            () => {
                if (value !== '') {
                    this.props.onSearch({
                        id: this.props.projectId,
                        fields: { policy_code: this.state.search },
                        map: {},
                    });

                    this.handleCloseAdvancedSearch();
                }
            },
        );
    };

    handleOnClear = () => {
        this.setState(
            {
                search: '',
            },
            () => {
                this.props.onClearSearch();
            },
        );
    };

    handleOnToggleAdvancedSearch = () => {
        this.setState(state => ({
            isAdvancedSearch: !state.isAdvancedSearch,
        }));
    };

    handleCloseAdvancedSearch = () => {
        this.setState(state => ({
            isAdvancedSearch: false,
        }));
    };

    handleOnAdvancedSearchSubmit = data => {
        this.setState(
            {
                isAdvancedSearch: !data.closeSearch,
            },
            () => {
                this.props.onSearch({
                    id: this.props.projectId,
                    fields: data.fields,
                    map: data.map,
                });
            },
        );
    };

    handleOnClickPrev = e => {
        this.props.onClickPrev();
    };

    handleOnClickNext = e => {
        this.props.onClickNext();
    };

    handleOnClickStart = e => {
        this.props.onClickStart();
    };

    handleOnClickEnd = e => {
        this.props.onClickEnd();
    };

    render() {
        const {
            texts,
            data,
            isLoading,
            isSaving,
            onClearSearch,
            projectId,
            onSort,
            onSave,
            onUpload,
            onDeleteDocument,
            project,
        } = this.props;
        const { isAdvancedSearch } = this.state;

        return (
            <ErrorBoundary>
                <section data-test="Container" className={Styles.container}>
                    <SectionHeader
                        data-test="Header"
                        title={getText('payments_and_documents')}
                        texts={texts}
                        action="insurance"
                        onSearch={this.handleOnSearch}
                        onClear={this.handleOnClear}
                        isAdvancedSearch={isAdvancedSearch}
                        onToggleAdvancedSearch={this.handleOnToggleAdvancedSearch}
                        searchPlaceholder={getText('search_by_policy_number')}
                    />

                    <div className={Styles.scrollContainer}>
                        {isAdvancedSearch && (
                            <AdvancedSearch
                                data-test="AdvancedSearch"
                                texts={texts}
                                isLoading={isLoading}
                                onAdvancedSearchSubmit={this.handleOnAdvancedSearchSubmit}
                                onClear={onClearSearch}
                                data={data.search}
                                onToggle={this.handleOnToggleAdvancedSearch}
                            />
                        )}

                        <Suspense fallback={<Loading theme="none" variant="sm" />}>
                            <ErrorBoundary>
                                {(isLoading || !projectId) && (
                                    <Loading
                                        data-test="Loading"
                                        theme="white"
                                        background="opacity"
                                        variant="sm"
                                        position="absolute"
                                    />
                                )}

                                {!isLoading && projectId && (
                                    <>
                                        {(!data?.list || data.list.length === 0) && <NoResults />}

                                        {data?.list && data.list.length > 0 && (
                                            <Table
                                                data-test="Table"
                                                section="payments-documents"
                                                header={data.header}
                                                data={data.list}
                                                filters={data.filters}
                                                pagination={data.pagination}
                                                texts={texts}
                                                projectId={projectId}
                                                onClickPrev={this.handleOnClickPrev}
                                                onClickNext={this.handleOnClickNext}
                                                onClickStart={this.handleOnClickStart}
                                                onClickEnd={this.handleOnClickEnd}
                                                onSort={onSort}
                                                onSave={onSave}
                                                onUpload={onUpload}
                                                onDeleteDocument={onDeleteDocument}
                                                isSaving={isSaving}
                                                isLoading={isLoading}
                                                project={project}
                                            />
                                        )}
                                    </>
                                )}
                            </ErrorBoundary>
                        </Suspense>
                    </div>
                </section>
            </ErrorBoundary>
        );
    }
}

export default PaymentsDocuments;
