export const eventsSimulator = [
    {
        id: 'fc719683-094e-4cfd-ac5b-cfbca4c7d083-1',
        mode: 'view',
        user: {
            value: 'coisas@coisas.com',
            match: true,
        },
        selectedDevice: '5060d1bb-9f0d-4db2-8309-39d11946e648-1',
        deviceProperties: {
            deviceId: '5060d1bb-9f0d-4db2-8309-39d11946e648-1',
            properties: [
                {
                    id: 'f5a87a38-1327-41ce-abd2-dd97c2a0bdf0-0',
                    name: 'Smoke detector',
                    value: 'on',
                },
                {
                    id: 'f5a87a38-1327-41ce-abd2-dd97c2a0bdf0-1',
                    name: 'Audio alarm',
                    value: 'on',
                },
            ],
        },
        devices: [
            {
                id: '5060d1bb-9f0d-4db2-8309-39d11946e648-0',
                name: 'Nest Camera',
                image: 'https://store.nest.com/assets/images/social/open-graph/nest-cam-iq.jpg',
                properties: [
                    {
                        id: 'f4a0fd4e-0e54-48d9-bbe0-1734cf2749db-0',
                        name: 'Video/motion',
                        value: 'on',
                    },
                    {
                        id: 'f4a0fd4e-0e54-48d9-bbe0-1734cf2749db-1',
                        name: 'Audio',
                        value: 'off',
                    },
                ],
            },
            {
                id: '5060d1bb-9f0d-4db2-8309-39d11946e648-1',
                name: 'Nest Smoke Detector',
                image: 'https://store.nest.com/assets/images/social/open-graph/smoke-co-alarm.jpg',
                properties: [
                    {
                        id: 'f5a87a38-1327-41ce-abd2-dd97c2a0bdf0-0',
                        name: 'Smoke detector',
                        value: 'off',
                    },
                    {
                        id: 'f5a87a38-1327-41ce-abd2-dd97c2a0bdf0-1',
                        name: 'Audio alarm',
                        value: 'on',
                    },
                ],
            },
        ],
    },
];
