export const successRateIntervals = [
    {
        id: 0,
        text: '24h',
        value: '24h',
    },
    {
        id: 1,
        text: '48h',
        value: '48h',
    },
    {
        id: 2,
        text: '1w',
        value: '1w',
    },
    {
        id: 3,
        text: '2w',
        value: '2w',
    },
];
