import ActionTypes from 'redux/actionTypes';
import rootInitialState from 'redux/store/initialState';
import { formatDate } from 'utils/functions';

export const initialState = {
    teamMembers: rootInitialState.teamMembers,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.TEAM_MEMBERS_TOGGLE_MODAL:
            return toggleModal(state, action);
        case ActionTypes.TEAM_MEMBERS_ADD_MEMBER:
            return addMember(state, action);
        case ActionTypes.TEAM_MEMBERS_ADD_MEMBER_ERROR:
            return addMemberError(state, action);
        case ActionTypes.TEAM_MEMBERS_ADD_MEMBER_CLEAR_ERROR:
            return addMemberClearError(state, action);
        case ActionTypes.TEAM_MEMBERS_EDIT_MEMBER:
            return editMember(state, action);
        case ActionTypes.TEAM_MEMBERS_DELETE_MEMBER:
            return deleteMember(state, action);
        case ActionTypes.TEAM_MEMBERS_RESUME_SUSPEND_MEMBER:
            return resumeSuspendMember(state, action);
        case ActionTypes.TEAM_MEMBERS_FETCH:
            return syncTeamMembers(state, action);
        case ActionTypes.TEAM_MEMBERS_ID:
            return syncTeamMembersId(state, action);
        case ActionTypes.TEAM_MEMBERS_DATA:
            return syncTeamMembersData(state, action);
        default:
            return state;
    }
};

/**
 * Sync TeamMembers Data
 * @param {*} state
 * @param {*} action
 */
const syncTeamMembersData = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        appId: payload.id,
        name: payload.name,
        type: payload.type,
        error: null,
    };
};

/**
 * Sync TeamMembers ID
 * @param {*} state
 * @param {*} action
 */
const syncTeamMembersId = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        appId: payload.id,
    };
};

/**
 * Sync TeamMembers
 * @param {*} state
 * @param {*} action
 */
const syncTeamMembers = (state, action) => {
    const payload = action.payload.data;
    const elements = payload.elements;

    const owner = elements.filter(el => el.role === 'owner');
    const users = elements.filter(el => el.role !== 'owner');

    return {
        ...state,
        owner: owner[0],
        members: [...users],
    };
};

/**
 * status = 1 -> Active
 * status = 0 -> Pending
 * status = -1 -> Paused
 * @param {*} state
 * @param {*} action
 */
const resumeSuspendMember = (state, action) => {
    const id = action.payload.data;

    const members = state.members.map(member => {
        if (member.id === id) {
            return {
                ...member,
                state: member.state === 'active' ? 'inactive' : 'active',
                since: formatDate(Date.now(), undefined, undefined, false),
            };
        }

        return { ...member };
    });

    return { ...state, members: [...members] };
};

/**
 * Add Member
 * @param {*} state
 * @param {*} action
 */
const addMember = (state, action) => {
    const payload = action.payload.data;

    const member = {
        id: state.members[state.members.length - 1].id + 1,
        name: '',
        role: 1,
        email: payload.email,
        state: 0,
        since: formatDate(Date.now(), undefined, undefined, false),
    };

    return {
        ...state,
        error: null,
        members: [...state.members.slice(0, 1), member, ...state.members.slice(1)],
    };
};

/**
 * Add Member Error
 * @param {*} state
 * @param {*} action
 */
const addMemberError = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        error: payload,
    };
};

/**
 * Add Member Clear Error
 * @param {*} state
 * @param {*} action
 */
const addMemberClearError = (state, action) => {
    return {
        ...state,
        error: null,
    };
};

/**
 * Edit Member
 * @param {*} state
 * @param {*} action
 */
const editMember = (state, action) => {
    const payload = action.payload.data;

    const members = state.members.filter(member => member.id !== payload.id);

    return { ...state, members: [...members] };
};

/**
 * Delete Member
 * @param {*} state
 * @param {*} action
 */
const deleteMember = (state, action) => {
    const payload = action.payload.data;

    const members = state.members.filter(member => member.id !== payload.id);

    return { ...state, members: [...members] };
};

/**
 * Toggle Modal
 * @param {*} state
 * @param {*} action
 */
const toggleModal = (state, action) => {
    return { ...state, active: !state?.active };
};

export default reducer;
