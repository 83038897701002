import classnames from 'classnames';
import { getText } from 'localization';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'shared/Button';
import Styles from './Steps.module.scss';

export class Steps extends React.PureComponent {
    static propTypes = {
        texts: PropTypes.object,
        flowId: PropTypes.string,
        steps: PropTypes.arrayOf(PropTypes.object),
        addStep: PropTypes.func,
        editStep: PropTypes.func,
        addFlow: PropTypes.func,
        disabled: PropTypes.bool,
        maxSteps: PropTypes.number,
    };

    render() {
        const { steps, addFlow, disabled } = this.props;

        return (
            <div data-test="Steps" className={Styles.steps}>
                <div className={Styles.progress}>
                    {steps && this.renderTitles()}
                    {steps && this.renderSteps()}
                </div>
                <div className={Styles.action}>
                    <Button
                        disabled={disabled || steps.length === 0}
                        variant="filled"
                        theme="ternary"
                        value={getText('save')}
                        onClick={addFlow}
                    />
                </div>
            </div>
        );
    }

    renderTitles() {
        const steps = this.props.steps;

        return (
            <ul data-test="StepsLabels" className={Styles.stepsLabels}>
                {steps.length === 0 && (
                    <li>
                        <p className={Styles.defaultLabel}>Add step</p>
                    </li>
                )}

                {steps.length > 0 &&
                    steps.map(step => (
                        <li key={step.id}>
                            <p className={Styles.label}>{step.text}</p>
                        </li>
                    ))}
            </ul>
        );
    }

    renderSteps() {
        const { flowId, steps, maxSteps } = this.props;

        return (
            <ul data-test="StepsObjects" className={Styles.stepsObjects}>
                {steps.length > 0 &&
                    steps.map(step => (
                        <li key={step.id} className={Styles?.active}>
                            <div
                                className={classnames(Styles.button, Styles?.active)}
                                data-flow-id={flowId}
                                data-id={step.id}
                                onClick={this.props.editStep}
                            >
                                <span className={Styles.default}></span>
                                <span className={Styles.edit}>
                                    <i className="icon-icon_edit"></i>
                                </span>
                            </div>
                        </li>
                    ))}

                {steps.length < maxSteps && (
                    <li
                        className={classnames(Styles.button, Styles.add)}
                        data-flow-id={flowId}
                        onClick={this.props.addStep}
                    >
                        <i className="icon-icon_add_2"></i>
                    </li>
                )}
            </ul>
        );
    }
}

export default Steps;
