import { getText } from 'localization';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { lazy, Suspense } from 'react';
import Button from 'shared/Button';
import ErrorBoundary from 'shared/ErrorBoundary';
import Loading from 'shared/Loading';
import Popup from 'shared/Popup';
import { getElementPosition } from 'utils/functions';
import Header from './Header';
import Styles from './ServiceDirectory.module.scss';

const Table = lazy(() => import('components/Networks/Table'));
const ServiceModal = lazy(() => import('redux/containers/Networks/ServiceModal'));

export class ServiceDirectory extends React.PureComponent {
    state = {
        search: '',
        focusEdit: false,
        settings: {
            active: false,
            element: {},
            id: null,
        },
    };

    static propTypes = {
        isLoading: PropTypes.bool,
        isSaving: PropTypes.bool,
        texts: PropTypes.object,
        services: PropTypes.array,
        directory: PropTypes.object,
        onEditDirectory: PropTypes.func,
        history: PropTypes.object,
        onOpenServiceModal: PropTypes.func,
        getDirectories: PropTypes.func,
        getDirectory: PropTypes.func,
        activeModal: PropTypes.bool,
        tableHeader: PropTypes.array,
        tableFilters: PropTypes.object,
        tablePagination: PropTypes.object,
        openPrompt: PropTypes.func,
        onDeleteService: PropTypes.func,
        onClearNetwork: PropTypes.func,
        ownProps: PropTypes.object,
    };

    componentDidMount() {
        this.props.getDirectory();

        const values = queryString.parse(this.props.ownProps.location.search);
        if (values && values.from && values.from === 'create') {
            this.setState({ focusEdit: true });
        }
    }

    componentWillUnmount() {
        this.props.onClearNetwork();
    }

    handleOnSearch = e => {
        const value = e.target.value;

        this.setState({
            search: value,
        });
    };

    handleOnSearchClear = () => {
        this.setState({
            search: '',
        });
    };

    handleOnSubmit = e => {
        e.preventDefault();
    };

    handleOnOpenServiceModal = e => {
        if (e && e.id) {
            this.props.onOpenServiceModal({ type: 'edit', id: e.id });
        } else {
            this.props.onOpenServiceModal({ type: 'add' });
        }
    };

    handleToogleSettings = e => {
        e.preventDefault();
        const id = e.currentTarget.getAttribute('data-id');

        this.setState({
            settings: {
                active: !this.state.settings?.active,
                element: getElementPosition(e.currentTarget),
                id: id,
            },
        });
    };

    handleClickSetting = e => {
        e.preventDefault();

        const data = {
            id: 'deleteServicePrompt',
            title: 'Alert',
            content: getText('prompt_delete_service'),
            onConfirm: () => this.props.onDeleteService({ id: this.state.settings.id }),
        };

        this.props.openPrompt(data);
    };

    handleCloseSettings = () => {
        this.setState({
            settings: {
                active: false,
                id: null,
            },
        });
    };

    getPopupContent() {
        return (
            <>
                <li onClick={this.handleClickSetting}>{getText('delete_service')}</li>
            </>
        );
    }

    render() {
        const {
            isLoading,
            isSaving,
            texts,
            services,
            directory,
            onEditDirectory,
            activeModal,
            tableHeader,
            tableFilters,
            tablePagination,
        } = this.props;
        const { search, focusEdit, settings } = this.state;

        const searchResults = services.filter(
            e => e.name.toUpperCase().indexOf(search.toUpperCase()) !== -1,
        );

        return (
            <ErrorBoundary>
                <article data-test="ServiceDirectoryContainer" className={Styles.service}>
                    <Header
                        data-test="ServiceDirectoryHeader"
                        autoFocus={focusEdit}
                        texts={texts}
                        isLoading={isLoading}
                        isSaving={isSaving}
                        directory={directory}
                        onEdit={onEditDirectory}
                        hasServices={services && services.length ? true : false}
                        tooltipText={getText('add_service')}
                        onAdd={this.handleOnOpenServiceModal}
                        onChange={this.handleOnSearch}
                        onClear={this.handleOnSearchClear}
                        searchValue={search}
                    />

                    <section className={Styles.scrollContainer}>
                        <Suspense fallback={<Loading theme="none" variant="sm" />}>
                            {isLoading && (
                                <Loading
                                    theme="white"
                                    background="opacity"
                                    variant="sm"
                                    position="absolute"
                                />
                            )}

                            {!isLoading && (!services || services.length === 0) && (
                                <div data-test="ServiceDirectoryEmpty" className={Styles.empty}>
                                    <div className={Styles.icons}>
                                        <i className="icon-icon_services"></i>
                                        <i className="icon-icon_services"></i>
                                        <i className="icon-icon_services"></i>
                                    </div>
                                    <div className={Styles.text}>
                                        <h2>No services yet!</h2>
                                        <p>Let’s start to add your first service</p>
                                    </div>
                                    <div className={Styles.add}>
                                        <Button
                                            data-test="ServiceDirectoryAdd"
                                            variant="filled"
                                            theme="ternary"
                                            value="+ Add services"
                                            state="active"
                                            onClick={this.handleOnOpenServiceModal}
                                        />
                                    </div>
                                </div>
                            )}

                            {!isLoading && services && services.length > 0 && (
                                <Table
                                    data-test="ServiceDirectoryTable"
                                    section="claims"
                                    header={tableHeader}
                                    data={searchResults}
                                    filters={tableFilters}
                                    pagination={tablePagination}
                                    texts={texts}
                                    onClickPrev={this.handleOnClickPrev}
                                    onClickNext={this.handleOnClickNext}
                                    onClickStart={this.handleOnClickStart}
                                    onClickEnd={this.handleOnClickEnd}
                                    onEdit={this.handleOnOpenServiceModal}
                                    onSort={this.handleOnSort}
                                    emptyText={getText('no_results')}
                                    hasMoreInfo
                                    onMoreInfo={this.handleToogleSettings}
                                />
                            )}

                            {activeModal && (
                                <ServiceModal data-test="ServiceDirectoryServiceModal" />
                            )}

                            <Popup
                                data-test="ServiceDirectorySettingsPopup"
                                list
                                position="bottom"
                                id="serviceSettingsPopup"
                                active={settings?.active}
                                element={settings.element}
                                element-id={settings.id}
                                onClickSetting={this.handleClickSetting}
                                closePopup={this.handleCloseSettings}
                                content={this.getPopupContent()}
                            />
                        </Suspense>
                    </section>
                </article>
            </ErrorBoundary>
        );
    }
}

export default ServiceDirectory;
