import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './UploadImage.module.scss';
import { sendErrorToast } from 'services/toast';
import { roundRatio } from 'utils/functions';
import Loading from 'shared/Loading';

class UploadImage extends React.PureComponent {
    myRef = React.createRef();

    state = {
        init: false,
    };

    static propTypes = {
        id: PropTypes.string,
        namespace: PropTypes.string,
        field: PropTypes.string,
        texts: PropTypes.object,
        data: PropTypes.object,
        onUpload: PropTypes.func,
        isLoading: PropTypes.bool,
        isSaving: PropTypes.bool,
        disabled: PropTypes.bool,
        section: PropTypes.string,
        size: PropTypes.oneOf(['sm', 'md', 'lg']),
        tooltip: PropTypes.string,
        accept: PropTypes.string,
        extensions: PropTypes.array,
        width: PropTypes.number,
        height: PropTypes.number,
        minWidth: PropTypes.number,
        minHeight: PropTypes.number,
        shape: PropTypes.oneOf(['round', 'square', 'rectangle']),
        theme: PropTypes.oneOf(['light', 'dark', 'white']),
        ratio: PropTypes.number,
        strict: PropTypes.bool,
        format: PropTypes.oneOf(['square', 'portrait', 'landscape']),
    };

    static defaultProps = {
        size: 'sm',
        width: 512,
        height: 512,
        extensions: ['image/jpg', 'image/jpeg', 'image/png'],
        accept: 'image/png, image/jpeg',
        shape: 'round',
        field: 'image',
        theme: 'light',
    };

    handleUploadFile = e => {
        if (e.target.files) {
            const { extensions, strict } = this.props;
            const file = e.target.files[0];

            if (!file || !file.type) {
                return false;
            }

            const size = file ? file.size / 1024 / 1024 : null;
            if (size > 2) {
                sendErrorToast('Image size exceeds 2 MB.');
                return false;
            }

            if (extensions && !extensions.includes(file.type)) {
                sendErrorToast('Wrong image extension.');
                return false;
            }

            if (strict) {
                this.handleImageSizeValidation(file);
            } else {
                this.handleOnUpload(file);
                e.target.value = '';
            }
        }
    };

    handleOnClickIcon = e => {
        this.myRef.current.click();
    };

    handleImageSizeValidation = file => {
        const { width, height, minWidth, minHeight, ratio, format } = this.props;

        let img = new Image();
        img.src = window.URL.createObjectURL(file);

        img.onload = () => {
            const naturalWidth = img.naturalWidth;
            const naturalHeight = img.naturalHeight;
            window.URL.revokeObjectURL(img.src);

            if (ratio && format) {
                switch (format) {
                    case 'square':
                        if (naturalWidth !== naturalHeight) {
                            return sendErrorToast(
                                `Image format required is square. Width and height must be equal. Minimum image size required: ${minWidth}x${minHeight}px`,
                            );
                        }
                        break;
                    case 'portrait':
                        if (naturalWidth > naturalHeight) {
                            return sendErrorToast(
                                `Image format required is portrait. Width can't be higher then height. Minimum image size required: ${minWidth}x${minHeight}px`,
                            );
                        }
                        break;
                    case 'landscape':
                        if (naturalWidth < naturalHeight) {
                            return sendErrorToast(
                                `Image format required is landscape. Height can't be higher then width. Minimum image size required: ${minWidth}x${minHeight}px`,
                            );
                        }
                        break;

                    default:
                        break;
                }

                if (roundRatio(naturalWidth / naturalHeight, 2) !== ratio) {
                    sendErrorToast(`Wrong image ratio. Ex: ${minWidth}x${minHeight}px`);
                } else if (naturalWidth < minWidth || naturalHeight < minHeight) {
                    sendErrorToast(`Minimum image size required: ${minWidth}x${minHeight}px`);
                } else {
                    this.handleOnUpload(file);
                }
            } else {
                if (naturalWidth !== width || naturalHeight !== height) {
                    sendErrorToast(`Image size required: ${width}x${height}px`);
                } else {
                    this.handleOnUpload(file);
                }
            }
        };
    };

    handleOnUpload = file => {
        const payload = {
            name: this.props.data && this.props.data.name ? this.props.data.name : null,
            namespace: this.props.namespace ? this.props.namespace : null,
            content: file,
        };

        this.props.onUpload(payload);
        // e.target.value = '';
    };

    render() {
        const {
            id,
            shape,
            data,
            disabled,
            isLoading,
            isSaving,
            tooltip,
            size,
            accept,
            field,
            theme,
        } = this.props;

        return (
            <div
                data-test="UploadImageContainer"
                className={classnames(Styles.image, Styles[shape], Styles[size], Styles[theme])}
            >
                <div
                    data-tip={tooltip ? tooltip : null}
                    className={classnames(
                        Styles.upload,
                        Styles[size],
                        data && data[field] ? Styles.hover : Styles.static,
                    )}
                >
                    <input
                        data-test="UploadImageUploadInput"
                        ref={this.myRef}
                        type="file"
                        name="file"
                        id={id || `file`}
                        className="inputfile"
                        onChange={this.handleUploadFile}
                        accept={accept}
                        disabled={isLoading || disabled}
                    />

                    {isSaving && (
                        <div className={Styles.loading}>
                            <Loading />
                        </div>
                    )}

                    {!isSaving && data && data[field] && <img src={data[field]} alt={data.name} />}

                    {!isSaving && (!data || !data[field]) && (
                        <i className={`${Styles.placeholder} icon-icon_Add_image`}></i>
                    )}

                    {!isLoading && !isSaving && (
                        <i
                            data-test="UploadImageUploadIcon"
                            className={`${Styles.uploadIcon} icon-icon_upload`}
                            onClick={this.handleOnClickIcon}
                        ></i>
                    )}
                </div>
            </div>
        );
    }
}

export default UploadImage;
