export const validation = (props, state) => {
    if (
        props.canSave ||
        (props.data && props.data.name && state.fields.name !== props.data.name) ||
        ((!props.data || !props.data.name) && state.fields.name) ||
        (props.data &&
            props.data.country_id &&
            state.fields.country_id !== props.data.country_id) ||
        ((!props.data || !props.data.country_id) && state.fields.country_id) ||
        (props.data &&
            props.data.region_covered &&
            state.fields.region_covered !== props.data.region_covered) ||
        ((!props.data || !props.data.region_covered) && state.fields.region_covered) ||
        (props.data &&
            props.data.segments &&
            props.data.segments.length > 0 &&
            JSON.stringify(state.fields.segments) !==
                JSON.stringify(props.data.segments.map(s => s.segment_id))) ||
        ((!props.data || !props.data.segments || props.data.segments.length === 0) &&
            state.fields.segments.length) ||
        (props.data &&
            props.data.directories &&
            props.data.directories.entities.length > 0 &&
            JSON.stringify(state.fields.directory_entities) !==
                JSON.stringify(props.data.directories.entities.map(dir => dir.id))) ||
        ((!props.data ||
            !props.data.directories ||
            !props.data.directories.entities ||
            props.data.directories.entities.length === 0) &&
            state.fields.directory_entities.length) ||
        (props.data &&
            props.data.directories &&
            props.data.directories.services.length > 0 &&
            JSON.stringify(state.fields.directory_services) !==
                JSON.stringify(props.data.directories.services.map(dir => dir.id))) ||
        ((!props.data ||
            !props.data.directories ||
            !props.data.directories.services ||
            props.data.directories.services.length === 0) &&
            state.fields.directory_services.length) ||
        (props.data &&
            props.data.directories &&
            props.data.directories.items.length > 0 &&
            JSON.stringify(state.fields.directory_items) !==
                JSON.stringify(props.data.directories.items.map(dir => dir.id))) ||
        ((!props.data ||
            !props.data.directories ||
            !props.data.directories.items ||
            props.data.directories.items.length === 0) &&
            state.fields.directory_items.length)
    ) {
        return true;
    }

    return false;
};
