export const mocks = [
    {
        id: 0,
        label: 'Category',
        list: [
            {
                id: 0,
                label: 'House',
                value: 'house',
                selected: false,
            },
            {
                id: 1,
                label: 'Work',
                value: 'work',
                selected: false,
            },
            {
                id: 2,
                label: 'Public Interest',
                value: 'public',
                selected: false,
            },
            {
                id: 3,
                label: 'Restaurant',
                value: 'restaurant',
                selected: false,
            },
            {
                id: 4,
                label: 'Airport',
                value: 'airport',
                selected: false,
            },
        ],
    },
    {
        id: 1,
        label: 'Something',
        list: [
            {
                id: 0,
                label: 'House',
                value: 'house',
                selected: false,
            },
            {
                id: 1,
                label: 'Work',
                value: 'work',
                selected: false,
            },
            {
                id: 2,
                label: 'Public Interest',
                value: 'public',
                selected: false,
            },
            {
                id: 3,
                label: 'Restaurant',
                value: 'restaurant',
                selected: false,
            },
            {
                id: 4,
                label: 'Airport',
                value: 'airport',
                selected: false,
            },
        ],
    },
    {
        id: 2,
        label: 'Something else',
        list: [
            {
                id: 0,
                label: 'House',
                value: 'house',
                selected: false,
            },
            {
                id: 1,
                label: 'Work',
                value: 'work',
                selected: false,
            },
            {
                id: 2,
                label: 'Public Interest',
                value: 'public',
                selected: false,
            },
            {
                id: 3,
                label: 'Restaurant',
                value: 'restaurant',
                selected: false,
            },
            {
                id: 4,
                label: 'Airport',
                value: 'airport',
                selected: false,
            },
        ],
    },
];
