import { getAppSettings } from 'services/localStorage';

export const app = {
    name: 'Selfcare',
    isLoading: false,
    isSaving: false,
    isAuthenticated: false,
    access_token: null,
    refresh_token: null,
    user: {
        id: null,
        name: null,
        email: null,
    },
    errors: {
        login: {
            pending: false,
        },
    },
    terms: {
        type: 'modal',
        id: 'TermsModal',
        active: false,
    },
    settings: getAppSettings(),
    registerSuccess: false,
};
