import React from 'react';
import PropTypes from 'prop-types';
import Styles from './HorizontalBarChart.module.scss';
import { mocks } from './__MOCKS__';

/** TODO: change to <progress> tag */
const Bar = ({ data }) => {
    const size = data.value < 12 ? 13 : data.value;
    const style = {
        backgroundColor: data.color,
        width: `${size}%`,
    };

    return (
        <div data-test="HorizontalBarChartBar" className={Styles.group}>
            <p className={Styles.label}>{data.label}</p>
            <div className={Styles.bar}>
                <span className={Styles.fill} style={style}>
                    {data.value}%
                </span>
            </div>
            {/* <progress value={size} max="100" style={{backgroundColor: data.color,}}>{data.value}%</progress> */}
        </div>
    );
};

Bar.propTypes = {
    data: PropTypes.object,
};

export class HorizontalBarChart extends React.PureComponent {
    state = {
        data: mocks,
    };
    static propTypes = {
        data: PropTypes.object,
    };

    componentDidMount() {
        if (this.props.data) {
            this.setState({ data: this.props.data });
        }
    }

    render() {
        const { data } = this.state;

        return (
            <div data-test="HorizontalBarChartContainer" className={Styles.container}>
                {data && data.length > 0 && data.map(bar => <Bar key={bar.id} data={bar} />)}
            </div>
        );
    }
}

export default HorizontalBarChart;
