export const prompt = {
    type: 'prompt',
    id: null,
    active: false,
    title: null,
    content: null,
    onConfirm: null,
    callback: null,
    retry: false,
};
