import classnames from 'classnames';
import React from 'react';
import Styles from './Radio.module.scss';

interface RadioProps {
    label?: string;
    extraLabel?: string;
    name?: string;
    value?: string;
    checked?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    position?: 'right' | 'left';
    size?: 'sm' | 'md';
    disabled?: boolean;
    customChildLabel?: React.ReactNode;
}

const Radio: React.FC<RadioProps> = ({
    label,
    extraLabel,
    name,
    value,
    checked = false,
    onChange,
    position,
    disabled,
    size = 'md',
    customChildLabel = null,
}) => {
    const labelStyle = label
        ? checked
            ? classnames(Styles.label, Styles.checked)
            : Styles.label
        : null;
    const checkmarkStyle = checked
        ? classnames(Styles.checkmark, Styles.checked)
        : classnames(Styles.checkmark);

    return (
        <div data-test="Radio" className={classnames(Styles.radio, Styles[size])}>
            <label
                data-test="RadioLabelTag"
                className={classnames(
                    Styles.container,
                    Styles[position],
                    disabled ? Styles.disabled : null,
                )}
            >
                <span data-test="RadioLabelText" className={labelStyle}>
                    {label}
                    {extraLabel && (
                        <span className={Styles.extraLabel}>
                            <br />
                            <em>{extraLabel}</em>
                        </span>
                    )}
                    {customChildLabel && customChildLabel}
                </span>
                <input
                    data-test="RadioInput"
                    type="radio"
                    name={name}
                    value={value}
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled}
                    aria-disabled={disabled}
                />
                <span
                    data-test="RadioCheckmark"
                    className={classnames(checkmarkStyle, Styles[position])}
                ></span>
            </label>
        </div>
    );
};

export default Radio;
