import ActionTypes from 'redux/actionTypes';
import rootInitialState from 'redux/store/initialState';

export const initialState = {
    prompt: rootInitialState.prompt,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.PROMPT_OPEN:
            return openPrompt(state, action);
        case ActionTypes.PROMPT_CLOSE:
            return closePrompt(state, action);
        case ActionTypes.PROMPT_TOGGLE:
            return togglePrompt(state, action);
        default:
            return state;
    }
};

const openPrompt = (state, action) => {
    const payload = action.payload.data;
    return { ...state, ...payload, active: true };
};

const closePrompt = (state, action) => {
    return { ...state, active: false, retry: false };
};

const togglePrompt = (state, action) => {
    return { ...state, active: !state?.active };
};

export default reducer;
