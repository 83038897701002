import ActionTypes from 'redux/actionTypes';
import rootInitialState from 'redux/store/initialState';
import { generateUUID } from 'utils/functions';
import { unitSystems } from './__MOCKS__/units';

export const initialState = {
    integration: rootInitialState.integration,
};

const reducer = (state = rootInitialState, action) => {
    switch (action.type) {
        case ActionTypes.INTEGRATION_IS_LOADING:
            return isLoading(state, action);
        case ActionTypes.INTEGRATION_IS_SAVING:
            return isSaving(state, action);
        case ActionTypes.INTEGRATION_CLEAR:
            return clearIntegration(state, action);
        case ActionTypes.INTEGRATION_FETCH:
            return syncIntegration(state, action);
        case ActionTypes.INTEGRATION_EDIT:
            return editIntegration(state, action);
        case ActionTypes.INTEGRATION_SAVE_DETAILS:
            return saveDetails(state, action);
        case ActionTypes.INTEGRATION_CREATE_DEVICE:
            return createDevice(state, action);
        case ActionTypes.INTEGRATION_SYNC_DEVICE:
            return syncDevice(state, action);
        case ActionTypes.INTEGRATION_ADD_COMPONENT:
            return addComponent(state, action);
        case ActionTypes.INTEGRATION_DELETE_COMPONENT:
            return deleteComponent(state, action);
        case ActionTypes.INTEGRATION_EDIT_COMPONENT_DETAILS:
            return editComponentDetails(state, action);
        case ActionTypes.INTEGRATION_ADD_COMPONENT_PROPERTY:
            return addComponentProperty(state, action);
        case ActionTypes.INTEGRATION_DELETE_COMPONENT_PROPERTY:
            return deleteComponentProperty(state, action);
        case ActionTypes.INTEGRATION_EDIT_COMPONENT_PROPERTY_DETAILS:
            return editComponentPropertyDetails(state, action);
        case ActionTypes.INTEGRATION_ADD_COMPONENT_CLASS:
            return addComponentClass(state, action);
        case ActionTypes.INTEGRATION_DELETE_COMPONENT_CLASS:
            return deleteComponentClass(state, action);
        case ActionTypes.INTEGRATION_OPEN_DEVICE_CLASSES_MODAL:
            return openClassesModal(state, action);
        case ActionTypes.INTEGRATION_CLOSE_DEVICE_CLASSES_MODAL:
            return closeClassesModal(state, action);
        case ActionTypes.INTEGRATION_GRAPHICS_CHANGE_WEBVIEW:
            return changeWebview(state, action);
        case ActionTypes.INTEGRATION_OPEN_DEVICE_UNITS_MODAL:
            return openUnitsModal(state, action);
        case ActionTypes.INTEGRATION_CLOSE_DEVICE_UNITS_MODAL:
            return closeUnitsModal(state, action);
        case ActionTypes.INTEGRATION_CHANGE_DEVICE_PROPERTY_UNIT:
            return changePropertyUnit(state, action);
        case ActionTypes.INTEGRATION_DELETE_PROPERTY_UNIT:
            return deletePropertyUnit(state, action);
        case ActionTypes.INTEGRATION_OPEN_DEVICE_SCHEMA_MODAL:
            return openSchemaModal(state, action);
        case ActionTypes.INTEGRATION_CLOSE_DEVICE_SCHEMA_MODAL:
            return closeSchemaModal(state, action);
        case ActionTypes.INTEGRATION_CHANGE_DEVICE_PROPERTY_SCHEMA:
            return changePropertySchema(state, action);
        case ActionTypes.INTEGRATION_DELETE_PROPERTY_SCHEMA:
            return deletePropertySchema(state, action);
        case ActionTypes.INTEGRATION_OPEN_GRAPHICS_TILE_INFORMATION_MODAL:
            return openTileInformationModal(state, action);
        case ActionTypes.INTEGRATION_CLOSE_GRAPHICS_TILE_INFORMATION_MODAL:
            return closeTileInformationModal(state, action);
        case ActionTypes.INTEGRATION_TOOGLE_TILE_INFORMATION_SECTION:
            return toogleTileInformationSection(state, action);
        case ActionTypes.INTEGRATION_SYNC_CLASSES:
            return syncClasses(state, action);
        case ActionTypes.INTEGRATION_SYNC_SCHEMAS:
            return syncSchemas(state, action);
        case ActionTypes.INTEGRATION_SYNC_UNITS:
            return syncUnits(state, action);
        case ActionTypes.INTEGRATION_SYNC_DEVICES:
            return syncDevices(state, action);
        case ActionTypes.INTEGRATION_SYNC_DEVICE_COMPONENTS:
            return syncDeviceComponents(state, action);
        case ActionTypes.INTEGRATION_CLEAR_DEVICE_STRUCTURE:
            return clearStructure(state);
        default:
            return state;
    }
};

export const mapUnitSystems = payload => {
    const elements = payload.unitSystem ? payload.unitSystem : unitSystems;

    const units = elements.map((system, index) => {
        if (system.metric === system.imperial) {
            return {
                id: `unit-${index}`,
                metric: {
                    id: system.metric,
                    ...payload.unitSpec[system.metric],
                    formula: { ...payload.unitCalc[system.metric] },
                },
                imperial: {
                    id: system.metric,
                    ...payload.unitSpec[system.metric],
                    formula: { ...payload.unitCalc[system.metric] },
                },
            };
        } else {
            return {
                id: `unit-${index}`,
                metric: {
                    id: system.metric,
                    ...payload.unitSpec[system.metric],
                    formula: { ...payload.unitCalc[system.metric] },
                },
                imperial: {
                    id: system.imperial,
                    ...payload.unitSpec[system.imperial],
                    formula: { ...payload.unitCalc[system.imperial] },
                },
            };
        }
    });

    return units;
};

const syncUnits = (state, action) => {
    const payload = action.payload.data;

    const units = mapUnitSystems(payload);

    return {
        ...state,
        units: {
            ...state.units,
            list: [...units],
        },
    };
};

const syncDevice = (state, action) => {
    const payload = action.payload.data;

    if (payload) {
        const devices = state.devices.map(device => {
            if (device.template.id === payload.id) {
                return {
                    ...device,
                    template: {
                        ...payload,
                    },
                };
            }

            return device;
        });

        return {
            ...state,
            devices: [...devices],
        };
    }
};

const clearStructure = state => {
    return {
        ...state,
        components: [],
    };
};

const syncDeviceComponents = (state, action) => {
    const payload = action.payload.data;

    if (payload.components && payload.components.length > 0) {
        return {
            ...state,
            prevState:
                state.components && state.components.length
                    ? [...state.components]
                    : [...payload.components],
            components: [...payload.components],
        };
    }

    const component = generateFakeComponent(state);
    return {
        ...state,
        components: [...state.components, component],
    };
};

/**
 * Edit Component Details
 * @param {*} state
 * @param {*} action
 */
const editComponentDetails = (state, action) => {
    const payload = action.payload.data;

    const components = state.components.map(component => {
        if (component.id === payload.id) {
            return {
                ...component,
                name: payload.name,
                // namespace: payload.namespace,
            };
        }

        return component;
    });

    return {
        ...state,
        components: [...components],
    };
};

/**
 * Sync Devices
 * @param {*} state
 * @param {*} action
 */
const syncDevices = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        devices: [...payload.elements],
    };
};

/**
 * Sync Classes
 * @param {*} state
 * @param {*} action
 */
const syncClasses = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        classes: {
            ...state.classes,
            list: [...payload.elements],
        },
    };
};

/**
 * Sync Schemas
 * @param {*} state
 * @param {*} action
 */
const syncSchemas = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        schema: {
            ...state.schema,
            list: [...payload.elements],
        },
    };
};

/**
 * Manage Loading state
 * @param {*} state
 * @param {*} action
 */
const isLoading = (state, action) => {
    const payload = action.payload.isLoading;

    return {
        ...state,
        isLoading: payload,
    };
};

/**
 * Manage Saving state
 * @param {*} state
 * @param {*} action
 */
const isSaving = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        isSaving: payload,
    };
};

/**
 * Clear Integration
 * @param {*} state
 * @param {*} action
 */
const clearIntegration = (state, action) => {
    return {
        ...state,
        isLoading: false,
        isSaving: false,
        details: {},
        devices: [],
        components: [],
    };
};

/**
 * Sync Integration
 * @param {*} state
 * @param {*} action
 */
const syncIntegration = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        details: { ...payload },
    };
};

/**
 * Edit Integration
 * @param {*} state
 * @param {*} action
 */
const editIntegration = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        name: payload.name,
    };
};

/**
 * Open Tile Information Modal
 * @param {*} state
 */
const toogleTileInformationSection = (state, action) => {
    const payload = action.payload.data;

    let selected = state.graphics.tileInformation.selected;
    if (selected.indexOf(payload.section) !== -1) {
        selected = selected.filter(el => el !== payload.section);
    } else {
        selected = [...selected, payload.section];
    }

    return {
        ...state,
        graphics: {
            ...state.graphics,
            tileInformation: {
                ...state.graphics.tileInformation,
                selected: selected,
            },
        },
    };
};

/**
 * Open Tile Information Modal
 * @param {*} state
 */
const openTileInformationModal = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        graphics: {
            ...state.graphics,
            tileInformation: {
                ...state.graphics.tileInformation,
                componentId: payload.componentId,
                propertyId: payload.propertyId,
                active: true,
            },
        },
    };
};

/**
 * Close Tile Information Modal
 * @param {*} state
 */
const closeTileInformationModal = state => {
    return {
        ...state,
        graphics: {
            ...state.graphics,
            tileInformation: {
                ...state.graphics.tileInformation,
                componentId: null,
                propertyId: null,
                active: false,
            },
        },
    };
};

/**
 * Change Property Unit
 * @param {*} state
 */
const changePropertyUnit = (state, action) => {
    const payload = action.payload.data;

    const output = state.components.map(component => {
        if (component.id === payload.componentId) {
            const properties = component.properties.map(property => {
                if (property.id === payload.propertyId) {
                    const unit = state.units.list.filter(u => u.id === payload.unitId);
                    if (
                        property.metric_system_units &&
                        property.metric_system_units.unit_id === unit[0].metric.id
                    ) {
                        return {
                            ...property,
                            metric_system_units: {
                                unit_id: 'none',
                                unitsystem_id: 'metric',
                            },
                            unit_id: 'none',
                        };
                    } else {
                        return {
                            ...property,
                            metric_system_units: {
                                unit_id: unit[0].metric.id,
                                unitsystem_id: 'metric',
                            },
                            unit_id: unit[0].metric.id,
                        };
                    }
                }

                return property;
            });

            return {
                ...component,
                properties: [...properties],
            };
        }

        return component;
    });

    return {
        ...state,
        components: [...output],
    };
};

/**
 * Delete Property Unit
 * @param {*} state
 */
const deletePropertyUnit = (state, action) => {
    const payload = action.payload.data;

    const output = state.components.map(component => {
        if (component.id === payload.componentId) {
            const properties = component.properties.map(property => {
                if (property.id === payload.propertyId) {
                    return {
                        ...property,
                        metric_system_units: {
                            unit_id: 'none',
                            unitsystem_id: 'metric',
                        },
                        unit_id: 'none',
                    };
                }

                return property;
            });

            return {
                ...component,
                properties: [...properties],
            };
        }

        return component;
    });

    return {
        ...state,
        components: [...output],
    };
};

/**
 * Open Units Modal
 * @param {*} state
 */
const openUnitsModal = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        units: {
            ...state.units,
            componentId: payload.componentId,
            propertyId: payload.propertyId,
            active: true,
        },
    };
};

/**
 * Close Units Modal
 * @param {*} state
 */
const closeUnitsModal = state => {
    return {
        ...state,
        units: {
            ...state.units,
            componentId: null,
            propertyId: null,
            active: false,
        },
    };
};

/**
 * Open Schema Modal
 * @param {*} state
 */
const openSchemaModal = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        schema: {
            ...state.schema,
            componentId: payload.componentId,
            propertyId: payload.propertyId,
            active: true,
        },
    };
};

/**
 * Close Schema Modal
 * @param {*} state
 */
const closeSchemaModal = state => {
    return {
        ...state,
        schema: {
            ...state.schema,
            componentId: null,
            propertyId: null,
            active: false,
        },
    };
};

/**
 * Open Schema Modal
 * @param {*} state
 */
const changePropertySchema = (state, action) => {
    const payload = action.payload.data;

    const output = state.components.map(component => {
        if (component.id === payload.componentId) {
            const properties = component.properties.map(property => {
                if (property.id === payload.propertyId) {
                    const schema = state.schema.list.filter(u => u.id === payload.schemaId);
                    if (property.schema_id && property.schema_id === schema[0].id) {
                        return {
                            ...property,
                            schema_id: null,
                        };
                    } else {
                        return {
                            ...property,
                            schema_id: schema[0].id,
                        };
                    }
                }

                return property;
            });

            return {
                ...component,
                properties: [...properties],
            };
        }

        return component;
    });

    return {
        ...state,
        components: [...output],
    };
};

/**
 * Delete Property Schema
 * @param {*} state
 */
const deletePropertySchema = (state, action) => {
    const payload = action.payload.data;

    const output = state.components.map(component => {
        if (component.id === payload.componentId) {
            const properties = component.properties.map(property => {
                if (property.id === payload.propertyId) {
                    return {
                        ...property,
                        schema_id: null,
                    };
                }

                return property;
            });

            return {
                ...component,
                properties: [...properties],
            };
        }

        return component;
    });

    return {
        ...state,
        components: [...output],
    };
};

/**
 * Change webview dropdown
 * @param {*} state
 * @param {*} action
 */
const changeWebview = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        webview: {
            ...state.webview,
            selected: payload.id,
        },
    };
};

/**
 * Open Classes Modal
 * @param {*} state
 */
const openClassesModal = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        classes: {
            ...state.classes,
            componentId: payload.componentId,
            propertyId: payload.propertyId,
            active: true,
        },
    };
};

/**
 * Close Classes Modal
 * @param {*} state
 */
const closeClassesModal = state => {
    return {
        ...state,
        classes: {
            ...state.classes,
            componentId: null,
            propertyId: null,
            active: false,
        },
    };
};

/**
 * Save Integration details
 * @param {*} state
 * @param {*} action
 */
const saveDetails = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        details: {
            manufacture: payload.manufacture,
            type: payload.type,
        },
    };
};

/**
 * Add Device
 * @param {*} state
 * @param {*} action
 */
const createDevice = (state, action) => {
    const payload = action.payload.data;
    const device = {
        template: { ...payload.channel_templates },
    };

    return {
        ...state,
        devices: [...state.devices, device],
    };
};

const generateFakeComponent = (state, reset) => {
    const randomId = `temp-${generateUUID()}`;

    let index = 1;

    if (!reset) {
        index = state.components.length + 1;
    }

    const component = {
        id: randomId,
        name: `Component Name #${index}`,
        // label: '',
        namespace: '',
        image: '',
        properties: [],
        classes: [],
    };

    return component;
};

/**
 * Add Component
 * @param {*} state
 * @param {*} action
 */
const addComponent = (state, action) => {
    const component = generateFakeComponent(state);

    return {
        ...state,
        components: [...state.components, component],
    };
};

/**
 * Delete Component
 * @param {*} state
 * @param {*} action
 */
const deleteComponent = (state, action) => {
    const payload = action.payload.data;

    const output = state.components.filter(component => component.id !== payload.id);

    if (output.length === 0) {
        const component = generateFakeComponent(state, true);

        return {
            ...state,
            components: [component],
        };
    }

    return {
        ...state,
        components: [...output],
    };
};

/**
 * Add Component Property
 * @param {*} state
 * @param {*} action
 */
const addComponentProperty = (state, action) => {
    const payload = action.payload.data;

    const output = state.components.map(component => {
        if (component.id === payload.id) {
            const randomId = `temp-${generateUUID()}`;

            const property = {
                id: randomId,
                name: `Property Name #${component.properties.length + 1}`,
                schema_id: '',
                namespace: '',
                classes: [],
                icon: '',
                metric_system_units: {
                    unit_id: 'none',
                    unitsystem_id: 'metric',
                },
                unit_id: 'none',
            };

            return {
                ...component,
                properties: [...component.properties, property],
            };
        }
        return component;
    });

    return {
        ...state,
        components: [...output],
    };
};

/**
 * Delete Component Property
 * @param {*} state
 * @param {*} action
 */
const deleteComponentProperty = (state, action) => {
    const payload = action.payload.data;

    const output = state.components.map(component => {
        if (component.id === payload.componentId) {
            return {
                ...component,
                properties: component.properties.filter(property => property.id !== payload.id),
            };
        }
        return component;
    });

    return {
        ...state,
        components: [...output],
    };
};

/**
 * Edit Component Property Details
 * @param {*} state
 * @param {*} action
 */
const editComponentPropertyDetails = (state, action) => {
    const payload = action.payload.data;

    const components = state.components.map(component => {
        if (component.id === payload.componentId) {
            const properties = component.properties.map(property => {
                if (property.id === payload.propertyId) {
                    return {
                        ...property,
                        name: payload.name,
                        // label: payload.name,
                        // namespace: payload.namespace,
                    };
                }
                return property;
            });

            return {
                ...component,
                properties: [...properties],
            };
        }

        return component;
    });

    return {
        ...state,
        components: [...components],
    };
};

/**
 * Add Component Class
 * @param {*} state
 * @param {*} action
 */
const addComponentClass = (state, action) => {
    const payload = action.payload.data;

    if (!payload.propertyId) {
        const output = state.components.map(component => {
            if (component.id === payload.componentId) {
                const newClass = state.classes.list.filter(c => c.id === payload.id);
                return {
                    ...component,
                    classes: [...component.classes, newClass[0].id],
                };
            }
            return component;
        });

        return {
            ...state,
            components: [...output],
        };
    }

    const output = state.components.map(component => {
        if (component.id === payload.componentId) {
            const properties = component.properties.map(property => {
                if (property.id === payload.propertyId) {
                    const newClass = state.classes.list.filter(c => c.id === payload.id);
                    return {
                        ...property,
                        classes: [...property.classes, newClass[0].id],
                    };
                }

                return property;
            });

            return {
                ...component,
                properties: [...properties],
            };
        }

        return component;
    });

    return {
        ...state,
        components: [...output],
    };
};

/**
 * Delete Component Class
 * @param {*} state
 * @param {*} action
 */
const deleteComponentClass = (state, action) => {
    const payload = action.payload.data;

    if (!payload.propertyId) {
        const output = state.components.map(component => {
            if (component.id === payload.componentId) {
                return {
                    ...component,
                    classes: component.classes.filter(c => c !== payload.id),
                };
            }
            return component;
        });

        return {
            ...state,
            components: [...output],
        };
    }

    const output = state.components.map(component => {
        if (component.id === payload.componentId) {
            const properties = component.properties.map(property => {
                if (property.id === payload.propertyId) {
                    return {
                        ...property,
                        classes: property.classes.filter(c => c !== payload.id),
                    };
                }

                return property;
            });

            return {
                ...component,
                properties: [...properties],
            };
        }

        return component;
    });

    return {
        ...state,
        components: [...output],
    };
};

export default reducer;
