export const compatibleDevices = {
    isSaving: false,
    hasError: {},
    filters: {
        selected: 0,
        list: [
            {
                id: 0,
                text: 'All',
                value: null,
            },
            {
                id: 1,
                text: 'Public',
                value: 'public',
            },
            {
                id: 2,
                text: 'Private',
                value: 'private',
            },
        ],
    },
    devices: {
        sort: {
            selected: null,
            double: true,
            list: [
                [
                    {
                        id: 0,
                        text: 'Name',
                        value: 'name',
                    },
                    {
                        id: 1,
                        text: 'Date',
                        value: 'created',
                    },
                ],
                [
                    {
                        id: 2,
                        text: 'Public',
                        value: 'public',
                    },
                    {
                        id: 3,
                        text: 'Private',
                        value: 'private',
                    },
                    {
                        id: 4,
                        text: 'Clear filter',
                        value: 'clear',
                    },
                ],
            ],
        },
        public: [],
        private: [],
        mine: [],
        added: [],
    },
    public: {
        type: 'modal',
        id: 'PublicDeviceModal',
        active: false,
    },
    private: {
        type: 'modal',
        id: 'PrivateDeviceModal',
        active: false,
        error: false,
    },
};
