import { getText } from 'localization';
import PropTypes from 'prop-types';
import React from 'react';
import Styles from './Styles.module.scss';

const NoResults = ({ text }) => {
    return (
        <div className={Styles.container}>
            <i className="icon-icon_search"></i>
            <p>{text || getText('common_there_is_no_results')}</p>
        </div>
    );
};

NoResults.propTypes = {
    text: PropTypes.string,
};

export default NoResults;
