import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import Logo from 'assets/images/habit-logo-v2.png';
import React from 'react';
import { getUserInfo } from 'services/localStorage';
import { BUGSNAG_API_KEY, DEFAULT_ALERT_ERROR_MESSAGE } from 'utils/constants';

const appVersion = process.env.REACT_APP_GIT_TAG
    ? process.env.REACT_APP_GIT_TAG.replace('v', '')
    : '';

const user = getUserInfo();

Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_KEY || BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    appVersion: appVersion,
    enabledReleaseStages: ['production', 'staging'],
    onError: async event => {
        if (
            event.errors[0].errorMessage.indexOf('ChunkLoadError') !== -1 ||
            event.errors[0].errorMessage.indexOf('Loading chunk') !== -1 ||
            event.errors[0].errorMessage.indexOf('Loading CSS chunk') !== -1
        ) {
            await window.location.reload();
            return false;
        }
        return true;
    },
    user: {
        id: user && user.id ? user.id : 'N/D',
        name: user && user.name ? user.email : 'N/D',
        email: user && user.email ? user.email : 'N/D',
    },
    metadata: {
        userInfo: {
            id: user && user.id ? user.id : 'N/D',
            name: user && user.name ? user.email : 'N/D',
            email: user && user.email ? user.email : 'N/D',
            roles: user && user.roles ? user.roles : 'N/D',
            raw: user ? JSON.stringify(user) : 'N/D',
        },
    },
});

export const BugsnagNotifyError = error => {
    Bugsnag.notify(error);
};

export const BugsnagErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

export const FallbackComponent = err => {
    const { error, info } = err;

    return (
        <div className="bugsnagErrorBoundary">
            <img src={Logo} alt="Habit Analytics" />
            <h1>{DEFAULT_ALERT_ERROR_MESSAGE}</h1>
            <details>
                {error && error.toString()}
                <br />
                {info.componentStack}
            </details>
        </div>
    );
};
