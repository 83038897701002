import { appFileUpload } from 'api/app';
import { downloadAsset } from 'api/assets';
import {
    assetEditOdometer,
    assetEditOdometerReport,
    assetOdometerHistory,
    assetRejectOdometerImage,
    claimChatHistoryCreateAsset,
    claimChatHistoryCreateMessage,
    downloadClaimChatHistoryAsset,
    editQuoteAsset,
    getAllClaimPossibleStatuses,
    getAllPolicyPossibleStatuses,
    getClaimChatHistory,
    getClaimDataById,
    getInsuranceCarSpecs,
    getInsuranceMetaData,
    getInsureeData,
    insuranceDeletePaymentDocument,
    insuranceUpdatePayment,
    insuranceUploadPaymentDocument,
    insureeFinder,
    searchInsuranceByType,
    // getCountryByNamespace,
    updateClaimStatus,
} from 'api/insurance';
import { getAddressFromLatLng } from 'api/utils';
import { isLoadingAction } from 'redux/domains/app/actions';
import { manageSidebarRoutesAction } from 'redux/domains/navigation/actions';
import { apiHandleThunkErrorResponse } from 'services/api';
import { UNAUTHORIZED_APPLICATION } from 'services/errorCodes';
import { sendErrorToast, sendSuccessToast } from 'services/toast';
import { DEFAULT_PAGE_SIZE, INSURANCE_CLAIM_CHAT_SEND_OPTIONS } from 'utils/constants';
import { downloadFileFromUrl, getFileType } from 'utils/functions';
import { closePromptAction } from '../prompt/actions';
import {
    insuranceChangeProjectAction,
    insuranceClearSearchByTypeAction,
    insuranceFetchClaimChatHistoryAction,
    insuranceSyncAssetOdometerHistoryAction,
    insuranceSyncBillingAction,
    insuranceSyncClaimChatHistoryAction,
    insuranceSyncClaimLocationAction,
    insuranceSyncMetaDataByTypeAction,
    insuranceSyncPaginateByTypeAction,
    insuranceSyncSearchByTypeAction,
    insuranceSyncSortByTypeAction,
    insuranceViewHasErrorAction,
    isLoadingInsuranceAction,
    isSavingInsuranceAction,
    syncInsuranceCurrentViewAction,
} from './actions';
import { getInsuranceSearchPayload } from './model';
import { insuranceAssetOdometerHistoryObs, insuranceMetaDataObs } from './observer';

export const insuranceChangeProjectThunk = (dispatch, data, fetch, ownProps) => {
    return dispatch(async (dispatch, getState) => {
        await dispatch(insuranceChangeProjectAction(data));
        const projectID = getState().insurance.filters.projects.selected;

        if (!ownProps) return;

        const location = ownProps.location.pathname;

        if (ownProps?.match?.params?.app_id !== data) {
            const url = location.replace(ownProps.match.params.app_id, data);
            ownProps.history.push(url);
        }

        if (projectID) {
            if (!ownProps?.match?.params?.app_id) {
                ownProps.history.replace(`${location}/app/${projectID}`);
            }

            const baseRoutes = getState().navigation.routes.insurance;
            const routes = baseRoutes.map(r => {
                return {
                    ...r,
                    list: r.list.map(l => {
                        return {
                            ...l,
                            disabled: false,
                            slug: `${l.original}/app/${projectID}`,
                        };
                    }),
                };
            });

            const payload = {
                section: 'insurance',
                routes: routes,
            };
            await dispatch(manageSidebarRoutesAction(payload));

            if (location.indexOf('/insurance/dashboard') !== -1) {
                await insuranceMetaDataObs(dispatch);
            }

            if (fetch) {
                if (ownProps.match?.params?.app_id && ownProps.match?.params?.id) {
                    return ownProps.history.replace('/insurance/dashboard');
                }

                switch (location) {
                    case location.indexOf('/insurance/insurees') !== -1:
                        await insuranceOnSearchByTypeThunk(
                            dispatch,
                            { id: projectID },
                            'insurees',
                            true,
                        );
                        break;
                    case location.indexOf('/insurance/policies') !== -1:
                        await insuranceOnSearchByTypeThunk(
                            dispatch,
                            { id: projectID },
                            'policies',
                            true,
                        );
                        break;
                    case location.indexOf('/insurance/claims') !== -1:
                        await insuranceOnSearchByTypeThunk(
                            dispatch,
                            { id: projectID },
                            'claims',
                            true,
                        );
                        break;
                    case location.indexOf('/insurance/quotes') !== -1:
                        await insuranceOnSearchByTypeThunk(
                            dispatch,
                            { id: projectID },
                            'quotes',
                            true,
                        );
                        break;
                    case location.indexOf('/insurance/assets') !== -1:
                        await insuranceOnSearchByTypeThunk(
                            dispatch,
                            { id: projectID },
                            'assets',
                            true,
                        );
                        break;
                    case location.indexOf('/insurance/payments-documents') !== -1:
                        await insuranceOnSearchByTypeThunk(
                            dispatch,
                            { id: projectID },
                            'payments',
                            true,
                        );
                        break;

                    default:
                        break;
                }
            }
        }

        // TODO: to be changed
        if (!window.carSpecs) {
            await getInsuranceCarSpecs()
                .then(response => {
                    window.carSpecs = response;
                })
                .catch(error => {
                    console.log('TCL: insuranceFetchDataThunk -> error', error);
                    sendErrorToast();
                });
        }
    });
};

export const insuranceMetaDataByType = (dispatch, type, id, entry) => {
    return dispatch((dispatch, getState) => {
        const payload = {
            namespace: type,
            type: 'count',
            params: {
                application_id: id,
            },
        };

        return getInsuranceMetaData(payload)
            .then(response => {
                dispatch(insuranceSyncMetaDataByTypeAction(entry, response[type].count));
            })
            .then(() => dispatch(isLoadingAction(false)))
            .catch(error => {
                // const response = error && error.response;
                // console.log(response);
                dispatch(isLoadingAction(false));

                return apiHandleThunkErrorResponse(error, true, null);
            });
    });
};

// ->
export const insuranceOnSearchByTypeThunk = (dispatch, data, type, loading = true) => {
    return dispatch(async (dispatch, getState) => {
        loading && dispatch(isLoadingAction(true));
        dispatch(isSavingInsuranceAction(true));

        const state = getState();
        const id = data && data.id ? data.id : state.insurance.filters.projects.selected;
        const chunk = state.insurance[type].chunk;
        const filters = state.insurance[type].filters;

        const payload = {
            id: id,
            type: type,
            data: {
                properties: {
                    filters: getInsuranceSearchPayload(data, type),
                },
                order_by: [
                    {
                        field: [filters.field, filters.direction],
                    },
                ],
                page_start_index: 0,
                page_size: chunk,
            },
        };

        var dynamicStates = null;
        if (type === 'claims') {
            dynamicStates = await getClaimStatesThunk([]);
        }
        if (type === 'policies') {
            dynamicStates = await getPolicyStatesThunk([]);
        }
        return await searchInsuranceByType(payload)
            .then(response => {
                const payload = {
                    ...response,
                    action: 'start',
                    start: 0,
                    currentPage: 1,
                    elements: response && response.elements ? response.elements : [],
                    dynamicStates: dynamicStates ? dynamicStates : [],
                };
                dispatch(insuranceSyncPaginateByTypeAction(type, payload));
            })
            .then(() => dispatch(insuranceSyncSearchByTypeAction(type, data)))
            .then(() => {
                loading && dispatch(isLoadingAction(false));
                dispatch(isSavingInsuranceAction(false));
            })
            .catch(error => {
                // const response = error && error.response;
                loading && dispatch(isLoadingAction(false));
                dispatch(isSavingInsuranceAction(false));

                return apiHandleThunkErrorResponse(error, true, null);
            });
    });
};

export const insuranceSortDataThunk = (dispatch, data, type) => {
    insuranceSearchPaginateThunk(dispatch, null, type, null, true, data);
};

export const insuranceSearchPaginateThunk = (dispatch, data, type, action, init, sort) => {
    return dispatch((dispatch, getState) => {
        dispatch(isLoadingAction(true));

        const state = getState();
        const projectId = data && data.id ? data.id : state.insurance.filters.projects.selected;
        const chunk = state.insurance[type].chunk;
        const currentPage = state.insurance[type].pagination.current;
        // const filters = state.insurance[type].filters;
        const search = state.insurance[type].search;
        const { id, ...filters } = search;
        const pages = state.insurance[type].pagination.total;

        let start = state.insurance[type].pagination.page_start_index;
        switch (action) {
            case 'prev':
                start = chunk * (currentPage - 2);
                break;
            case 'next':
                start = chunk * currentPage;
                break;
            case 'start':
                start = 0;
                break;
            case 'end':
                start = chunk * (pages - 1);
                break;

            default:
                break;
        }

        let params = {
            id: projectId,
            type: type,
            data: {
                properties: {
                    filters:
                        Object.entries(filters).length > 0
                            ? getInsuranceSearchPayload(filters, type, false)
                            : getInsuranceSearchPayload(search, type, init),
                },
                order_by: [
                    {
                        field: [
                            state.insurance[type].filters.field,
                            state.insurance[type].filters.direction,
                        ],
                    },
                ],
                page_start_index: start,
                page_size: chunk,
            },
        };

        if (sort) {
            params = {
                ...params,
                data: {
                    ...params.data,
                    order_by: [
                        {
                            field: [sort.field, sort.direction],
                        },
                    ],
                },
            };
        }

        return searchInsuranceByType(params)
            .then(response => {
                if (response && response !== '' && response.elements) {
                    const payload = {
                        ...response,
                        action: action,
                        start: start,
                        currentPage: currentPage,
                        elements: response && response.elements ? response.elements : [],
                    };
                    dispatch(insuranceSyncPaginateByTypeAction(type, payload));
                }
            })
            .then(() => {
                if (sort) {
                    dispatch(insuranceSyncSortByTypeAction(type, sort));
                }
            })
            .then(() => dispatch(isLoadingAction(false)))
            .catch(error => {
                const response = error && error.response;
                console.log('TCL: insuranceSearchPaginateThunk -> error -> response', response);

                dispatch(isLoadingAction(false));

                return apiHandleThunkErrorResponse(error, true, null);
            });
    });
};

export const insuranceFetchDataThunk = (dispatch, ownProps, type, loading = true) => {
    if (loading) {
        dispatch(isLoadingAction(true));
    }

    return dispatch(async (dispatch, getState) => {
        const state = getState();
        const id = state.insurance.filters.projects.selected || ownProps.match.params.app_id;

        if (!state.insurance.filters.projects.selected) {
            await insuranceChangeProjectThunk(dispatch, id, false, ownProps);
        }

        if (!id) {
            ownProps.history.replace('/insurance/dashboard');
        }

        let payload = {
            id: id,
            insuree_id: ownProps.match.params.id,
        };

        if (!type || type === 'insurees') {
            return insuranceFetchDataWithContextId(dispatch, ownProps, payload, 'insurees');
        } else {
            return insureeFinder({
                app_id: id,
                data: {
                    collection: type,
                    id: ownProps.match.params.id,
                },
            })
                .then(async response => {
                    if (response) {
                        if (response.insurees) {
                            const insuree = response;
                            payload = {
                                id: id,
                                insuree_id: insuree.insurees[0],
                            };
                        } else if (response.quotes) {
                            const quotesObj = response.quotes;
                            payload = {
                                id: id,
                                quote_id: quotesObj[0],
                            };
                        }
                        await insuranceFetchDataWithContextId(dispatch, ownProps, payload, type);
                    } else {
                        console.error('No data found');
                        dispatch(isLoadingAction(false));
                        if (ownProps?.match?.params?.id) {
                            console.error(
                                'No data found for insuree with id: ' + ownProps.match.params.id,
                            );
                            dispatch(insuranceViewHasErrorAction(true));
                        }
                        // sendErrorToast('Oops. No data was found...');
                    }
                })
                .catch(error => {
                    console.log(error);
                    const response = error && error.response;

                    dispatch(isLoadingAction(false));

                    if (ownProps?.match?.params?.id) {
                        console.error(
                            'No data found for insuree with id: ' + ownProps.match.params.id,
                        );
                        dispatch(insuranceViewHasErrorAction(true));
                    }

                    //TODO: refactor
                    if (
                        response &&
                        response.status &&
                        response.status === 401 &&
                        response.data.code === UNAUTHORIZED_APPLICATION
                    ) {
                        // const prompt = {
                        //     id: 'genericError',
                        //     title: 'Access forbidden',
                        //     content:
                        //         "You don't have permissions to access. You will be redirected.",
                        //     custom: true,
                        //     customText: 'OK',
                        //     onConfirm: () => {
                        //         ownProps.history.replace('/insurance/dashboard');
                        //         dispatch(closePromptAction());
                        //     },
                        // };
                        // return apiHandleThunkErrorResponse(
                        //     error,
                        //     false,
                        //     dispatch(openPromptAction(prompt)),
                        // );
                    } else {
                        return apiHandleThunkErrorResponse(error, false, null);
                    }
                });
        }
    });
};

export const insuranceFetchDataWithContextId = async (dispatch, ownProps, payload, type) => {
    return getInsureeData(payload)
        .then(async response => {
            if (response) {
                /// Commented out due to endpoint not returning properly in QA and PRD.
                const insuree = response.insuree; // = await getCountryByNamespaceThunk(response.insuree);

                await getAllClaimPossibleStatusesThunk(response.policies);

                await getAllPolicyPossibleStatusesThunk(response.policies);

                return {
                    ...response,
                    insuree: insuree,
                };
            } else {
                if (ownProps?.match?.params?.id) {
                    console.error('No data found for insuree with id: ' + ownProps.match.params.id);
                    dispatch(insuranceViewHasErrorAction(true));
                }
                // sendErrorToast('Oops. No data was found...');
            }
        })
        .then(async response => {
            if (response) {
                if (response && response.insuree && response.distributor) {
                    response.insuree['distributor'] = response.distributor;
                }

                const data = {
                    id: ownProps.match.params.id,
                    type: type,
                };

                await dispatch(syncInsuranceCurrentViewAction(response, data));
            }
        })
        .then(() => dispatch(isLoadingAction(false)))
        .catch(error => {
            const response = error && error.response;

            dispatch(isLoadingAction(false));
            if (ownProps?.match?.params?.id) {
                console.error('No data found for insuree with id: ' + ownProps.match.params.id);
                console.error(error);
                dispatch(insuranceViewHasErrorAction(true));
            }

            //TODO: refactor
            if (
                response &&
                response.status &&
                response.status === 401 &&
                response.data.code === UNAUTHORIZED_APPLICATION
            ) {
                // const prompt = {
                //     id: 'genericError',
                //     title: 'Access forbidden',
                //     content: "You don't have permissions to access. You will be redirected.",
                //     custom: true,
                //     customText: 'OK',
                //     onConfirm: () => {
                //         ownProps.history.replace('/insurance/dashboard');
                //         dispatch(closePromptAction());
                //     },
                // };
                // return apiHandleThunkErrorResponse(
                //     error,
                //     false,
                //     dispatch(openPromptAction(prompt)),
                // );
            } else {
                return apiHandleThunkErrorResponse(error, false, null);
            }
        });
};

export const insuranceClearSearchByTypeThunk = async (dispatch, type) => {
    try {
        await dispatch(insuranceClearSearchByTypeAction(type));
        await insuranceOnSearchByTypeThunk(dispatch, null, type, true);
    } catch (error) {
        console.log('TCL: insuranceClearSearchByTypeThunk -> error', error);
        sendErrorToast();
    }
};

export const insuranceOnEditAssetThunk = (dispatch, data, ownProps) => {
    dispatch(isSavingInsuranceAction(true));
    return dispatch((dispatch, getState) => {
        const payload = {
            app_id: ownProps.match.params.app_id,
            quote_id: data.quoteId,
            asset_id: data.assetId,
            data: {
                properties: data.properties,
            },
        };

        return editQuoteAsset(payload)
            .then(async response => {
                // sendSuccessToast('Saved successfully.');
                const url = ownProps.match.url.split('/');
                const type =
                    url && url[2]
                        ? url[2] === 'assets'
                            ? 'quote-protected-assets'
                            : url[2]
                        : null;
                await insuranceFetchDataThunk(dispatch, ownProps, type, false);
            })
            .then(async () => await dispatch(isSavingInsuranceAction(false)))
            .catch(error => {
                // const response = error && error.response;

                dispatch(isSavingInsuranceAction(false));

                return apiHandleThunkErrorResponse(error, true, null);
            });
    });
};

// const getCountryByNamespaceThunk = insuree => {
//     let country = null;

//     Object.entries(insuree.properties).forEach(([key, prop]) => {
//         Object.entries(prop).forEach(([key, value]) => {
//             if (key === 'namespace' && value === 'country') {
//                 country = prop;
//             }
//         });
//     });

//     if (!country) {
//         return insuree;
//     }

//     return getCountryByNamespace(country.data)
//         .then(response => {
//             const properties = insuree.properties.filter(prop => prop.id !== country.id);
//             country = {
//                 ...country,
//                 data: response.name,
//             };

//             return {
//                 ...insuree,
//                 properties: [...properties, country],
//             };
//         })
//         .catch(error => {
//             const response = error && error.response;
//             console.log('TCL: response', response);

//             return apiHandleThunkErrorResponse(error, true, null);
//         });
// };

const getAllPolicyPossibleStatusesThunk = policies => {
    const projectID = window.location.pathname.split('/app/').pop();
    const payload = {
        app_id: projectID,
        data: {
            namespace: 'policy-states',
        },
    };

    return getAllPolicyPossibleStatuses(payload)
        .then(response => {
            const policyStates = createPolicyStatesObject(response.main, response.custom);
            policies.forEach(policy => {
                policy.policyDynamicStates = policyStates;
            });

            return policies;
        })

        .catch(error => {
            const response = error && error.response;
            console.log('TCL: response', response);

            return apiHandleThunkErrorResponse(error, true, null);
        });
};

const getPolicyStatesThunk = policy_states => {
    const projectID = window.location.pathname.split('/app/').pop();
    const payload = {
        app_id: projectID,
        data: {
            namespace: 'policy-states',
        },
    };

    return getAllPolicyPossibleStatuses(payload)
        .then(response => {
            const policyStates = createPolicyStatesObject(
                response.main,
                response.custom,
                response.all,
            );

            policy_states = policyStates;
            return policy_states;
        })

        .catch(error => {
            const response = error && error.response;
            console.log('TCL: response', response);

            return apiHandleThunkErrorResponse(error, true, null);
        });
};

function createPolicyStatesObject(main, custom) {
    var possibleSelectableStates = [
        'active:cancelation-scheduled',
        'active:cancelation-rejected',
        'canceled',
    ];
    var customActiveStates = [];

    if (custom !== null && custom?.active !== null) {
        customActiveStates = PolicyCustomStatesToListStructure(custom?.active, 'active');
    }

    let mainStates = PolicyStatesToListStructure(main);
    var readOnlyStates = customActiveStates?.concat(mainStates);

    var all = customActiveStates?.concat(mainStates);

    var selectableStates = [];
    all.forEach(state => {
        if (possibleSelectableStates.includes(state.id)) {
            selectableStates.push(state);
        }
    });

    readOnlyStates = readOnlyStates.filter(el => !selectableStates.includes(el));

    var policyStates = {
        all: all,
        custom: customActiveStates,
        main: main,
        readOnly: readOnlyStates,
        selectable: selectableStates,
    };
    return policyStates;
}

function PolicyCustomStatesToListStructure(list, primaryState) {
    var structured = [];
    list?.forEach(value => {
        if (value && value !== '') {
            structured.push({
                data: value,
                id: primaryState !== value ? primaryState + ':' + value : value,
                text:
                    primaryState !== value
                        ? primaryState.charAt(0).toUpperCase() +
                          primaryState.slice(1) +
                          ' : ' +
                          value.replace(/-/g, ' ').replace(/^./, value[0].toUpperCase())
                        : value.replace(/-/g, ' ').replace(/^./, value[0].toUpperCase()),
            });
        }
    });

    return structured;
}
function PolicyStatesToListStructure(list) {
    var structured = [];
    list.forEach(value => {
        if (value && value !== '') {
            structured.push({
                data: value,
                id: value,
                text: value.replace(/-/g, ' ').replace(/^./, value[0].toUpperCase()),
            });
        }
    });

    return structured;
}

////////////////////

const getAllClaimPossibleStatusesThunk = policies => {
    const projectID = window.location.pathname.split('/app/').pop();
    const payload = {
        app_id: projectID,
        data: {
            namespace: 'claim-states',
        },
    };

    return getAllClaimPossibleStatuses(payload)
        .then(response => {
            const claimStates = createClaimStatesObject(
                response.main,
                response.custom,
                response.all,
            );

            policies.forEach(policy => {
                policy.claimPossibleStates = claimStates;
                policy.claims.forEach(claim => {
                    claim.claimPossibleStates = claimStates;
                });
            });

            return policies;
        })

        .catch(error => {
            const response = error && error.response;
            console.log('TCL: response', response);

            return apiHandleThunkErrorResponse(error, true, null);
        });
};

const getClaimStatesThunk = claim_states => {
    const projectID = window.location.pathname.split('/app/').pop();
    const payload = {
        app_id: projectID,
        data: {
            namespace: 'claim-states',
        },
    };

    return getAllClaimPossibleStatuses(payload)
        .then(response => {
            const claimStates = createClaimStatesObject(
                response.main,
                response.custom,
                response.all,
            );

            claim_states = claimStates;
            return claim_states;
        })

        .catch(error => {
            const response = error && error.response;
            console.log('TCL: response', response);

            return apiHandleThunkErrorResponse(error, true, null);
        });
};

function createClaimStatesObject(main, custom, all) {
    let readOnlyStatesToRemove = [
        'draft',
        'open',
        'submitted',
        'custom',
        'deductible-received',
        'upfront-premium-and-deductible-received',
        'upfront-premium-received',
    ];
    var selectableStates = main?.concat(custom);
    selectableStates =
        selectableStates?.filter(value => !readOnlyStatesToRemove.includes(value)) || [];
    var readOnlyStates = main?.concat(custom);

    var claimStates = {
        all: ClaimStatesToListStructure(all),
        custom: ClaimStatesToListStructure(custom),
        main: ClaimStatesToListStructure(main),
        readOnly: ClaimStatesToListStructure(readOnlyStates),
        selectable: ClaimStatesToListStructure(selectableStates),
    };

    return claimStates;
}

function ClaimStatesToListStructure(list) {
    var structured = [];
    list.forEach(value => {
        if (value && value !== '') {
            structured.push({
                data: value,
                id: value,
                text: value.replace(/-/g, ' ').replace(/^./, value[0].toUpperCase()),
            });
        }
    });

    return structured;
}

export const insuranceOnChangeClaimStatusThunk = (dispatch, data, ownProps) => {
    dispatch(isSavingInsuranceAction(true));

    return dispatch((dispatch, getState) => {
        const payload = {
            app_id: ownProps.match.params.app_id,
            claim_id: data.claim_id,
            status: data.status,
        };

        return updateClaimStatus(payload)
            .then(async response => {
                // await insuranceFetchClaimDataThunk(dispatch, data);
                const url = ownProps.match.url.split('/');
                const type =
                    url && url[2]
                        ? url[2] === 'assets'
                            ? 'quote-protected-assets'
                            : url[2]
                        : null;
                await insuranceFetchDataThunk(dispatch, ownProps, type, false);
            })
            .then(async () => await dispatch(isSavingInsuranceAction(false)))
            .catch(error => {
                dispatch(isSavingInsuranceAction(false));

                return apiHandleThunkErrorResponse(error, true, null);
            });
    });
};

export const insuranceFetchClaimDataThunk = (dispatch, data) => {
    dispatch(isSavingInsuranceAction(true));

    return dispatch((dispatch, getState) => {
        const payload = {
            app_id: data.app_id,
            claim_id: data.claim_id,
        };

        return getClaimDataById(payload)
            .then(async response => {
                console.log('insuranceFetchClaimDataThunk -> response', response);
                // await dispatch(insuranceSyncClaimAction(response));
            })
            .then(async () => await dispatch(isSavingInsuranceAction(false)))
            .catch(error => {
                // const response = error && error.response;
                // console.log(response);

                dispatch(isSavingInsuranceAction(false));

                return apiHandleThunkErrorResponse(error, true, null);
            });
    });
};

export const insuranceGetAddressFromLatLngThunk = (dispatch, data) => {
    return dispatch((dispatch, getState) => {
        return getAddressFromLatLng(data.latlng)
            .then(async response => {
                if (response && response.status === 'OK') {
                    const payload = {
                        claim_id: data.id,
                        ...response,
                    };
                    await dispatch(insuranceSyncClaimLocationAction(payload));
                }
            })
            .catch(error => {
                // const response = error && error.response;
                // console.log(response);

                return apiHandleThunkErrorResponse(error, false, null);
            });
    });
};

export const insuranceOnFetchClaimChatHistoryThunk = (dispatch, data, ownProps) => {
    dispatch(insuranceFetchClaimChatHistoryAction(true));

    return dispatch((dispatch, getState) => {
        const payload = {
            app_id: ownProps.match.params.app_id,
            claim_id: data.id,
            params: {
                page_size: DEFAULT_PAGE_SIZE,
                order_by: '+created',
                classes:
                    'elemMatch/{"$in": ["io.habit.claim.chat.message", "io.habit.claim.asset"]}/class_id',
            },
        };

        return getClaimChatHistory(payload)
            .then(async response => {
                await dispatch(
                    insuranceSyncClaimChatHistoryAction(
                        response && response.elements ? response.elements : [],
                    ),
                );
            })
            .then(async () => {
                await dispatch(insuranceFetchClaimChatHistoryAction(false));
            })
            .catch(error => {
                const response = error && error.response;
                console.log(response);

                dispatch(insuranceFetchClaimChatHistoryAction(false));

                return apiHandleThunkErrorResponse(error, false, null);
            });
    });
};

export const insuranceClaimChatHistoryCreateMessageThunk = (dispatch, data, ownProps) => {
    dispatch(insuranceFetchClaimChatHistoryAction(true));

    return dispatch((dispatch, getState) => {
        const payload = {
            app_id: ownProps.match.params.app_id,
            claim_id: data.id,
            data: { ...data.params },
        };

        return claimChatHistoryCreateMessage(payload)
            .then(async response => {
                if (response && response.id) {
                    insuranceOnFetchClaimChatHistoryThunk(dispatch, data, ownProps);
                }
            })
            .then(async () => {
                await dispatch(insuranceFetchClaimChatHistoryAction(false));
            })
            .catch(error => {
                const response = error && error.response;
                console.log(response);

                dispatch(insuranceFetchClaimChatHistoryAction(false));

                return apiHandleThunkErrorResponse(error, false, null);
            });
    });
};

export const insuranceClaimChatHistoryUploadAssetThunk = (dispatch, data, ownProps) => {
    dispatch(insuranceFetchClaimChatHistoryAction(true));

    return dispatch((dispatch, getState) => {
        return appFileUpload(data.upload)
            .then(async response => {
                if (response && response.url) {
                    const type = getFileType(response.url);
                    const payload = {
                        app_id: ownProps.match.params.app_id,
                        claim_id: data.id,
                        name: `Uploaded ${type}`,
                        data: {
                            assets: [
                                {
                                    name: `Uploaded ${type}`,
                                    icon: type === 'photo' ? response.url : null,
                                    image: type === 'photo' ? response.url : null,
                                    uri: response.url,
                                    classes: [`io.habit.trackableassets.type.${type}`],
                                },
                            ],
                        },
                    };
                    await insuranceClaimChatHistoryCreateAssetThunk(dispatch, payload, ownProps);
                } else {
                    sendErrorToast();
                }
            })
            .catch(error => {
                dispatch(insuranceFetchClaimChatHistoryAction(false));

                return apiHandleThunkErrorResponse(error, false, null);
            });
    });
};

export const insuranceClaimChatHistoryCreateAssetThunk = (dispatch, data, ownProps) => {
    return dispatch((dispatch, getState) => {
        return claimChatHistoryCreateAsset(data)
            .then(async response => {
                if (response && response.assets) {
                    const payload = {
                        app_id: ownProps.match.params.app_id,
                        id: data.claim_id,
                        params: {
                            ...INSURANCE_CLAIM_CHAT_SEND_OPTIONS.asset,
                            entity_id: response.assets[0].id,
                            description: response.assets[0].name,
                        },
                    };
                    await insuranceClaimChatHistoryCreateMessageThunk(dispatch, payload, ownProps);
                } else {
                    sendErrorToast();
                }
            })
            .then(async () => {
                const url = ownProps.match.url.split('/');
                const type =
                    url && url[2]
                        ? url[2] === 'assets'
                            ? 'quote-protected-assets'
                            : url[2]
                        : null;
                await insuranceFetchDataThunk(dispatch, ownProps, type, false);
            })
            .catch(error => {
                dispatch(insuranceFetchClaimChatHistoryAction(false));

                return apiHandleThunkErrorResponse(error, false, null);
            });
    });
};

export const insuranceClaimChatHistoryDownloadAssetThunk = (dispatch, data, ownProps) => {
    return dispatch((dispatch, getState) => {
        const payload = {
            app_id: ownProps.match.params.app_id,
            claim_id: data.claim_id,
            asset_id: data.asset_id,
        };

        return downloadClaimChatHistoryAsset(payload)
            .then(async response => {
                if (response && response.uri) {
                    await downloadFileFromUrl(response.uri);
                } else {
                    sendErrorToast();
                }
            })
            .catch(error => {
                dispatch(insuranceFetchClaimChatHistoryAction(false));

                return apiHandleThunkErrorResponse(error, false, null);
            });
    });
};

export const insuranceOnEditOdometerDistanceThunk = (dispatch, data, ownProps) => {
    dispatch(isSavingInsuranceAction(true));

    return dispatch((dispatch, getState) => {
        const payload = {
            app_id: ownProps.match.params.app_id,
            quote_id: data.quote_id,
            asset_id: data.asset_id,
            data: {
                distance: data.distance,
            },
        };

        return assetEditOdometer(payload)
            .then(async response => {
                if (response) {
                    const payload = {
                        channel_id: data.channel_id,
                        action: 'distance_report',
                        quote_id: data.quote_id,
                        protectedasset_id: data.asset_id,
                    };
                    await insuranceSendOdometerReport(payload);
                }
                return response;
            })
            .then(async response => {
                if (response) {
                    const url = ownProps.match.url.split('/');
                    const type =
                        url && url[2]
                            ? url[2] === 'assets'
                                ? 'quote-protected-assets'
                                : url[2]
                            : null;
                    await insuranceFetchDataThunk(dispatch, ownProps, type, false);
                } else {
                    sendErrorToast();
                    // dispatch(isSavingInsuranceAction(false));
                }
            })
            .then(async () => {
                await dispatch(isSavingInsuranceAction(false));
            })
            .catch(error => {
                dispatch(isSavingInsuranceAction(false));

                return apiHandleThunkErrorResponse(error, true, null);
            });
    });
};

const insuranceSendOdometerReport = (payload, alert) => {
    return assetEditOdometerReport(payload)
        .then(async response => {
            if (alert) {
                sendSuccessToast('Report sent successfully');
            }
        })
        .catch(error => {
            // console.log('insuranceSendOdometerReport -> error', error);

            return apiHandleThunkErrorResponse(error, true, null);
        });
};

export const insuranceSendOdometerReportThunk = (dispatch, payload, ownProps, alert) => {
    dispatch(isSavingInsuranceAction(true));

    return assetEditOdometerReport(payload)
        .then(async response => {
            if (alert) {
                await sendSuccessToast('Report sent successfully');
            }
            return true;
        })
        .then(async response => {
            setTimeout(async () => {
                await insuranceAssetOdometerHistoryObs(dispatch, payload, ownProps, false, true);
            }, 1000);
            return response;
        })
        .catch(error => {
            dispatch(isSavingInsuranceAction(false));

            return apiHandleThunkErrorResponse(error, true, null);
        });
};

export const insuranceOnRejectOdometerImageThunk = (dispatch, data, ownProps) => {
    dispatch(isSavingInsuranceAction(true));
    dispatch(isLoadingAction(true));

    return dispatch((dispatch, getState) => {
        const payload = {
            app_id: ownProps.match.params.app_id,
            quote_id: data.quote_id,
            asset_id: data.asset_id,
        };

        return assetRejectOdometerImage(payload)
            .then(async response => {
                if (response) {
                    const payload = {
                        channel_id: data.channel_id,
                        action: 'distance_report',
                        quote_id: data.quote_id,
                        protectedasset_id: data.asset_id,
                    };
                    await insuranceSendOdometerReport(payload);
                }
                return response;
            })
            .then(async response => {
                if (response) {
                    const url = ownProps.match.url.split('/');
                    const type =
                        url && url[2]
                            ? url[2] === 'assets'
                                ? 'quote-protected-assets'
                                : url[2]
                            : null;
                    await insuranceFetchDataThunk(dispatch, ownProps, type, false);
                } else {
                    sendErrorToast();
                }
            })
            .then(async () => {
                await dispatch(isSavingInsuranceAction(false));
            })
            .then(async () => {
                await dispatch(closePromptAction());
            })
            .then(async () => {
                await dispatch(isLoadingAction(false));
            })
            .catch(error => {
                dispatch(isSavingInsuranceAction(false));
                dispatch(isLoadingAction(false));

                return apiHandleThunkErrorResponse(error, true, null);
            });
    });
};

export const insuranceAssetOdometerHistoryThunk = (dispatch, data, ownProps, loading = true) => {
    if (loading) {
        dispatch(isLoadingAction(true));
    }

    return dispatch((dispatch, getState) => {
        const payload = {
            app_id: ownProps.match.params.app_id,
            quote_id: data.quote_id,
            asset_id: data.asset_id,
            params: {
                page_size: DEFAULT_PAGE_SIZE,
                order_by: '-created',
            },
        };

        return assetOdometerHistory(payload)
            .then(async response => {
                await dispatch(
                    insuranceSyncAssetOdometerHistoryAction(
                        response && response.elements ? response.elements : [],
                    ),
                );
            })
            .then(async () => {
                await dispatch(isLoadingAction(false));
            })
            .catch(error => {
                dispatch(isLoadingAction(false));

                return apiHandleThunkErrorResponse(error, false, null);
            });
    });
};

export const insuranceFetchBillingDataThunk = (dispatch, data, ownProps, loading = true) => {
    loading && dispatch(isLoadingInsuranceAction(true));
    dispatch(isSavingInsuranceAction(true));

    return dispatch((dispatch, getState) => {
        const payload = {
            id: ownProps.match.params.app_id,
            type: 'payments',
            data: {
                properties: {
                    filters: getInsuranceSearchPayload(data, 'billing', false),
                },
                order_by: [
                    {
                        field: ['start_date', 'desc'],
                    },
                ],
                page_start_index: 0,
                page_size: DEFAULT_PAGE_SIZE,
            },
        };

        return searchInsuranceByType(payload)
            .then(async response => {
                await dispatch(
                    insuranceSyncBillingAction(
                        response.elements,
                        data.policy_id,
                        data.clientservice_id,
                    ),
                );
            })
            .then(async () => {
                loading && (await dispatch(isLoadingInsuranceAction(false)));
                await dispatch(isSavingInsuranceAction(false));
            })
            .catch(error => {
                dispatch(isLoadingInsuranceAction(false));
                dispatch(isSavingInsuranceAction(false));

                return apiHandleThunkErrorResponse(error, true, null);
            });
    });
};

export const insuranceUpdatePaymentThunk = (dispatch, data, ownProps, loading = true, type) => {
    loading && dispatch(isSavingInsuranceAction(true));

    return dispatch((dispatch, getState) => {
        const state = getState();
        const id = state.insurance.filters.projects.selected;
        const payload = {
            app_id: id,
            payment_id: data.payment_id,
            data: data.params,
        };

        return insuranceUpdatePayment(payload)
            .then(async response => {
                switch (type) {
                    case 'view':
                        await insuranceFetchBillingDataThunk(dispatch, data, ownProps, false);
                        break;
                    case 'modal':
                        await insuranceOnSearchByTypeThunk(dispatch, data, 'billing', false);
                        break;

                    default:
                        await insuranceOnSearchByTypeThunk(dispatch, null, 'payments', false);
                        break;
                }
            })
            .then(async () => {
                loading && (await dispatch(isSavingInsuranceAction(false)));
            })
            .catch(error => {
                dispatch(isSavingInsuranceAction(false));
                return apiHandleThunkErrorResponse(error, true, null);
            });
    });
};

export const insuranceUploadPaymentDocumentThunk = (
    dispatch,
    data,
    ownProps,
    loading = true,
    type,
) => {
    loading && dispatch(isSavingInsuranceAction(true));

    return dispatch((dispatch, getState) => {
        const state = getState();
        const id = state.insurance.filters.projects.selected;
        const payload = {
            app_id: id,
            payment_id: data.payment_id,
            data: {
                assets: [data.params],
            },
        };

        return (
            insuranceUploadPaymentDocument(payload)
                .then(async response => {
                    switch (type) {
                        case 'view':
                            await insuranceFetchBillingDataThunk(dispatch, data, ownProps, false);
                            break;
                        case 'modal':
                            await insuranceOnSearchByTypeThunk(dispatch, data, 'billing', false);
                            break;

                        default:
                            await insuranceOnSearchByTypeThunk(dispatch, null, 'payments', false);
                            break;
                    }
                })
                // .then(async () => {
                //     loading && (await dispatch(isSavingInsuranceAction(false)));
                // })
                .catch(error => {
                    dispatch(isSavingInsuranceAction(false));

                    return apiHandleThunkErrorResponse(error, false, null);
                })
        );
    });
};

export const insuranceDeletePaymentDocumentThunk = (
    dispatch,
    data,
    ownProps,
    loading = true,
    type,
) => {
    loading && dispatch(isSavingInsuranceAction(true));

    return dispatch((dispatch, getState) => {
        const state = getState();
        const id = state.insurance.filters.projects.selected;
        const payload = {
            app_id: id,
            ...data,
        };

        return insuranceDeletePaymentDocument(payload)
            .then(async response => {
                switch (type) {
                    case 'view':
                        await insuranceFetchBillingDataThunk(dispatch, data, ownProps, false);
                        break;
                    case 'modal':
                        await insuranceOnSearchByTypeThunk(dispatch, data, 'billing', false);
                        break;

                    default:
                        await insuranceOnSearchByTypeThunk(dispatch, null, 'payments', false);
                        break;
                }
            })
            .then(async () => {
                loading && (await dispatch(isSavingInsuranceAction(false)));
            })
            .catch(error => {
                dispatch(isSavingInsuranceAction(false));

                return apiHandleThunkErrorResponse(error, false, null);
            });
    });
};

export const insuranceFetchProtectedAssetThunk = (dispatch, data, ownProps) => {
    dispatch(isSavingInsuranceAction(true));

    return dispatch((dispatch, getState) => {
        let path = data.url.split('?');
        path = path[0].split('/assets/');
        path = '/assets/' + path[1];

        const id = getState().insurance.filters.projects.selected;
        const project = getState().insurance.filters.projects.list.filter(p => p.id === id)[0];

        const payload = {
            id: project.id,
            confirmation_hash: project.confirmation_hash,
            url: data.url,
            path: path,
        };

        return downloadAsset(payload)
            .then(response => {
                if (response !== '' && response.url) {
                    dispatch(isSavingInsuranceAction(false));
                } else {
                    dispatch(isSavingInsuranceAction(false));
                    sendErrorToast();
                }

                return response;
            })
            .then(async () => {
                await dispatch(isSavingInsuranceAction(false));
            })
            .catch(error => {
                dispatch(isSavingInsuranceAction(false));

                return apiHandleThunkErrorResponse(error, false, null);
            });
    });
};
