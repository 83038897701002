import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { SingleDatePicker, isInclusivelyBeforeDay } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Styles from './Styles.module.scss';
import './Styles.scss';

const CustomInputIcon = () => {
    return <i className={classnames('icon-icon_calendar', Styles.calendarIcon)}></i>;
};

export class InputDatePicker extends React.PureComponent {
    // TODO: refactor

    state = {
        visibleMonths: 1,
        date: null,
        // focused: this.props.autoFocus,
        focused: null,
        offset: 0,
    };

    static propTypes = {
        date: PropTypes.string,
        autoFocus: PropTypes.bool,
        disabled: PropTypes.bool,
        blockedDays: PropTypes.array,
        highlightedDays: PropTypes.array,
        onChange: PropTypes.func,
        offset: PropTypes.number,
        inputDateIconPosition: PropTypes.string,
    };

    static defaultProps = {
        autoFocus: false,
        disabled: false,
        blockedDays: [],
        highlightedDays: [],
        offset: 0,
        inputDateIconPosition: 'after',
    };

    componentDidMount() {
        if (!this.props.date) {
            this.setState(
                (state, props) => ({
                    date: moment(),
                }),
                () => {
                    this.props.onChange(moment.utc(this.state.date).format('YYYY-MM-DD'));
                },
            );
        } else {
            this.setState({
                date: moment(this.props.date),
            });
        }

        if (this.props.offset) {
            this.setState({
                offset: this.props.offset,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.date &&
            prevState.date !== this.props.date &&
            prevProps.date !== this.props.date
        ) {
            this.setState({
                date: moment(this.props.date),
            });
        }
    }

    onDatesChange = date => {
        this.setState(
            {
                date,
            },
            () => {
                this.props.onChange(moment.utc(date).format('YYYY-MM-DD'));
            },
        );
    };

    onFocusChange = ({ focused }) => {
        this.setState({
            focused,
        });
    };

    onClearDates = () => {
        this.setState({
            date: null,
        });
    };

    onApplyDates = () => {
        this.setState({
            focused: null,
        });
    };

    isOutsideRange = day => {
        return !isInclusivelyBeforeDay(day, moment().subtract(this.state.offset, 'day'));
    };

    isDayBlocked = day => {
        return this.props.blockedDays.includes(day.format('DD/MM/YYYY'));
    };

    isDayHighlighted = day => {
        if (this.props.highlightedDays && this.props.highlightedDays.length > 0) {
            return this.props.highlightedDays.includes(day.format('YYYY-MM-DD'));
        }
        return false;
    };

    render() {
        const { date, focused, visibleMonths } = this.state;
        const { disabled } = this.props;

        return (
            <div
                data-test="InputDatePickerContainer"
                className={focused ? Styles?.active : date ? Styles.filled : null}
            >
                <SingleDatePicker
                    placeholder="Select Date"
                    date={date}
                    id="inputDatePicker"
                    onDateChange={this.onDatesChange}
                    focused={focused}
                    onFocusChange={this.onFocusChange}
                    disabled={disabled}
                    showDefaultInputIcon={true}
                    customInputIcon={<CustomInputIcon />}
                    keepOpenOnDateSelect={false}
                    hideKeyboardShortcutsPanel={true}
                    onClose={this.onClose}
                    isOutsideRange={this.isOutsideRange}
                    numberOfMonths={visibleMonths}
                    // displayFormat={focused ? 'DD/MM/YYYY' : 'MMMM Do YYYY'}
                    displayFormat={'DD/MM/YYYY'}
                    isDayBlocked={this.isDayBlocked}
                    isDayHighlighted={this.isDayHighlighted}
                    inputIconPosition={this.props.inputDateIconPosition}
                    block
                />
            </div>
        );
    }
}

export default InputDatePicker;
