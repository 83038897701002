import ActionTypes from 'redux/actionTypes';
import rootInitialState from 'redux/store/initialState';
import { generateUUID } from 'utils/functions';

export const initialState = {
    integrations: rootInitialState.integrations,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.INTEGRATIONS_FETCH:
            return syncIntegrations(state, action);
        case ActionTypes.INTEGRATIONS_ADD_INTEGRATION:
            return addIntegration(state, action);
        case ActionTypes.INTEGRATIONS_CLEAR:
            return clear(state, action);
        default:
            return state;
    }
};

const clear = state => {
    return rootInitialState.integrations;
};

/**
 * Sync Integrations
 * @param {*} state
 * @param {*} action
 */
const syncIntegrations = (state, action) => {
    const payload = action.payload.data;
    const elements = payload.elements;

    return [...elements];
};

/**
 * Add Integration
 * @param {*} state
 * @param {*} action
 */
const addIntegration = (state, action) => {
    const randomId = generateUUID();

    const integration = {
        id: randomId,
        account_id: 'a7d212ab-9cfb-4c26-8e27-05370c39b659',
        active_ts: '2019-02-08T16:03:08.873+0000',
        confirmation_hash: '91ktr0zbg4gyennrrkax1m5psvr5zr21j9rreiv1f7z93e72l3qri7csm0wzhecq',
        created: '2019-02-08T16:03:08.282+0000',
        href: '/v3/applications/b033f42f-9cec-4534-aa8f-61ec5e816ecc',
        inactive_ts: '2019-02-08T16:03:08.175+0000',
        mobile_scheme: 'integration-name-15',
        name: 'Integration Name',
        namespace: 'integration-name-15',
        owner_id: 'af620e5c-dc3a-47e8-9976-7e673e0fb5f0',
        secret: 'o0ix8lqe3vej95wc8jf6na9f',
        state: 'active',
        type: 'application',
        updated: '2019-02-08T16:30:13.628+0000',
        _id: '/v3/applications/b033f42f-9cec-4534-aa8f-61ec5e816ecc',
        _rev: '3-da5e741bf87f574e84d1d68596755375',
    };

    return [...state, integration];
};

export default reducer;
