import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Loading from 'shared/Loading';
import { DATE_FORMAT_NO_TIME_INTERNATIONAL } from 'utils/constants';
import { formatDate, getFirstAndLastInitials } from 'utils/functions';
import Styles from './DirectoryTile.module.scss';

export class DirectoryTile extends React.PureComponent {
    static defaultProps = {
        size: 'lg',
    };

    static propTypes = {
        isLoading: PropTypes.bool,
        text: PropTypes.string,
        directory: PropTypes.object,
        directoryType: PropTypes.string,
        type: PropTypes.string,
        baseUrl: PropTypes.string,
        onClick: PropTypes.func,
        size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
        onSettings: PropTypes.func,
    };

    handleSettings = e => {
        e.preventDefault();
    };

    render() {
        const {
            directory,
            text,
            type,
            baseUrl,
            onClick,
            size,
            isLoading,
            onSettings,
            directoryType,
        } = this.props;

        if (type === 'add') {
            return (
                <div
                    data-test="DirectoryTileAdd"
                    className={classnames(Styles.directory, Styles[type], Styles[size])}
                    onClick={!isLoading ? onClick : null}
                >
                    {isLoading && <Loading />}

                    {!isLoading && (
                        <>
                            <i className="icon-icon_add_2"></i>
                            <p>{text}</p>
                        </>
                    )}
                </div>
            );
        }

        return (
            <Link data-test="DirectoryTileUrl" to={`${baseUrl}/${directory.id}`}>
                <div
                    data-test="DirectoryTileView"
                    className={classnames(Styles.directory, Styles[type], Styles[size])}
                >
                    <div className={Styles.wrapper}>
                        <div data-test="DirectoryTileSettings" className={Styles.header}>
                            <p>Directory</p>
                            <i
                                className="icon-icon_more_info"
                                data-directory-id={directory ? directory.id : null}
                                data-directory-type={directoryType ? directoryType : null}
                                onClick={onSettings}
                            ></i>
                        </div>

                        <div className={Styles.content}>
                            <div className={Styles.stamp}>
                                <span>{getFirstAndLastInitials(directory.name)}</span>
                            </div>
                            <div className={Styles.info}>
                                <p data-test="DirectoryTileName">{directory.name}</p>
                                {/* <p data-test="DirectoryTileName">{`Entities (3)`}</p> */}
                            </div>
                            <p data-test="DirectoryTileDate" className={Styles.date}>
                                Created on{' '}
                                {formatDate(
                                    directory.created,
                                    false,
                                    DATE_FORMAT_NO_TIME_INTERNATIONAL,
                                    false,
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

export default DirectoryTile;
