import classnames from 'classnames';
import { getText } from 'localization';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import ErrorBoundary from 'shared/ErrorBoundary';
import Input from 'shared/Input';
import Search from 'shared/Search';
import Styles from './Header.module.scss';

export class Header extends React.PureComponent {
    state = {
        init: false,
        focus: false,
        name: '',
    };

    static propTypes = {
        texts: PropTypes.object,
        isLoading: PropTypes.bool,
        isSaving: PropTypes.bool,
        directory: PropTypes.object,
        action: PropTypes.string,
        onEdit: PropTypes.func,
        hasServices: PropTypes.bool,
        onAdd: PropTypes.func,
        tooltipText: PropTypes.string,
        onChange: PropTypes.func,
        onClear: PropTypes.func,
        searchValue: PropTypes.string,
        autoFocus: PropTypes.bool,
    };

    componentDidMount() {
        if (
            this.props.directory &&
            this.props.directory?.name &&
            this.props.directory?.name !== this.state.name
        ) {
            this.setState({ name: this.props.directory?.name });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.init && this.props.directory && this.props.directory.name) {
            this.setState({
                init: true,
                name: this.props.directory?.name,
            });
        }

        if (this.state.init && prevProps.directory?.name !== this.props.directory?.name) {
            this.setState({ name: this.props.directory?.name });
        }

        if (!this.props.isLoading && this.props.autoFocus && !this.state.focus) {
            setTimeout(() => {
                this.setState({ focus: true });
            }, 100);
        }
    }

    handleOnChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            {
                [name]: value,
            },
            () => {
                // this.props.onEdit(value);
            },
        );
    };

    handleOnSave = () => {
        // e.preventDefault();
        this.props.onEdit({ name: this.state.name });
    };

    handleOnSubmit = e => {
        if (e) {
            e.preventDefault();
        }
        this.props.onEdit({ name: this.state.name });
    };

    render() {
        const {
            action,
            isLoading,
            isSaving,
            directory,
            hasServices,
            tooltipText,
            onAdd,
            onChange,
            onClear,
            searchValue,
        } = this.props;

        const { focus } = this.state;

        const placeholder = isLoading
            ? getText('loading_with_dots')
            : directory && directory?.name
            ? directory?.name
            : 'Placeholder name';

        return (
            <ErrorBoundary>
                <section
                    data-test="HeaderContainer"
                    className={classnames(Styles.header, Styles[action])}
                >
                    <div className={Styles.edit}>
                        <form autoComplete="off" onSubmit={this.handleOnSubmit}>
                            <Input
                                data-test="HeaderInput"
                                focusOnDemand={focus}
                                disabled={isLoading}
                                isLoading={isSaving}
                                onSave={this.handleOnSave}
                                onChange={this.handleOnChange}
                                canSave={
                                    this.props.directory &&
                                    this.state.name !== this.props.directory?.name &&
                                    this.state.name.length > 0
                                }
                                onBlur={this.handleOnClear}
                                value={this.state.name ? this.state.name : ''}
                                placeholder={placeholder}
                                name="name"
                                type="text"
                                theme="edit"
                                variant="none"
                                state="left"
                            />
                        </form>
                    </div>

                    <div className={Styles.actions}>
                        {hasServices && (
                            <>
                                <Search
                                    data-test="HeaderSearch"
                                    autoFocus={!focus}
                                    name="search"
                                    theme="primary"
                                    variant="outlined"
                                    size="md"
                                    placeholder={getText('search_with_dots')}
                                    onSearch={this.handleOnSubmit}
                                    onClear={onClear}
                                    onChange={onChange}
                                    value={searchValue}
                                />

                                {onAdd && (
                                    <i
                                        className={classnames('icon-icon_add_2', Styles.add)}
                                        onClick={onAdd}
                                        data-tip={tooltipText ? tooltipText : null}
                                    ></i>
                                )}
                            </>
                        )}

                        <div className={Styles.goBack}>
                            <Link data-test="GoBack" to={`/networks-management/services-items`}>
                                <i className="icon-icon_delete_2"></i>
                            </Link>
                        </div>
                    </div>
                </section>
            </ErrorBoundary>
        );
    }
}

export default Header;
