import configureAPI from 'api/config';
import MedipetIco from 'assets/ico/medipet.ico';
import 'assets/scss/main.scss';
import 'assets/uns-browser.css';
import React, { StrictMode } from 'react';
import 'react-app-polyfill/ie9';
import ReactDOM from 'react-dom';
import configureStore from 'redux/store/config';
import initialState from 'redux/store/initialState';
import { BugsnagErrorBoundary, FallbackComponent } from 'services/bugsnag';
import { isQaEnv } from 'utils/functions';
import { getProjectInfo } from 'utils/helpers';
import App from './App';
import './i18n';
// import reportWebVitals from './reportWebVitals';
// import * as serviceWorker from './serviceWorker';

/**
 * Redirect if muzzley.com to habit.io
 */
if (
    (typeof String.prototype.endsWith === 'function' &&
        window.location.hostname.endsWith('.muzzley.com') === true) ||
    window.location.hostname.match(/.muzzley.com$/)
) {
    window.location.replace(window.location.href.replace('.muzzley.com', '.habit.io'));
}

/* https://github.com/facebook/react/issues/11538#issuecomment-417504600 */
if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
        if (child.parentNode !== this) {
            if (console) {
                console.error('Cannot remove a child from a different parent', child, this);
            }
            return child;
        }
        return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
        if (referenceNode && referenceNode.parentNode !== this) {
            if (console) {
                console.error(
                    'Cannot insert before a reference node from a different parent',
                    referenceNode,
                    this,
                );
            }
            return newNode;
        }
        return originalInsertBefore.apply(this, arguments);
    };
}

/**
 * Redux store and API Config
 */
const rootStore = configureStore(initialState);
configureAPI();
/** END Config */

const { currentProject } = getProjectInfo();
if (currentProject === 'medipet') {
    let link = document.querySelector(`link[rel~='icon']`);
    link.href = MedipetIco;
}

const titles = {
    selfcare: `${isQaEnv() && 'Sandbox QA - '}Selfcare by Habit`,
    medipet: `${isQaEnv() && 'Sandbox QA - '}Medipet`,
    b2b: `${isQaEnv() && 'Sandbox QA - '}B2B by Habit`,
};

const title = process.env.REACT_APP_TITLE ? process.env.REACT_APP_TITLE : titles[currentProject];

document.title = title;

/**
 * Remove devtools in production mode
 */
if (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'production') {
    ReactDOM.render(
        <BugsnagErrorBoundary FallbackComponent={FallbackComponent}>
            <App store={rootStore.store} history={rootStore.history} />
        </BugsnagErrorBoundary>,
        document.getElementById('__SELFCARE__'),
    );
} else {
    ReactDOM.render(
        <StrictMode>
            <BugsnagErrorBoundary FallbackComponent={FallbackComponent}>
                <App store={rootStore.store} history={rootStore.history} />
            </BugsnagErrorBoundary>
        </StrictMode>,
        document.getElementById('__SELFCARE__'),
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();

// if (process.env.NODE_ENV !== 'production') {
//     reportWebVitals(console.log);
// }
