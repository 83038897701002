import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './TagInput.module.scss';
import ValidationStatus from 'shared/ValidationStatus';

export class TagInput extends React.PureComponent {
    state = {
        items: [],
        focused: false,
        input: '',
    };

    static propTypes = {
        isLoading: PropTypes.bool,
        items: PropTypes.array,
        placeholder: PropTypes.string,
        handle: PropTypes.oneOf(['internal', 'external']),
        disabled: PropTypes.bool,
        state: PropTypes.string,
        withValidation: PropTypes.bool,
        isValid: PropTypes.bool,
        unsaved: PropTypes.bool,
        onKeyDown: PropTypes.func,
        onChange: PropTypes.func,
        onRemove: PropTypes.func,
        value: PropTypes.string,
        type: PropTypes.string,
        tagWidth: PropTypes.string,
        isEncrypted: PropTypes.bool,
    };

    static defaultProps = {
        handle: 'internal',
        type: 'text',
        tagWidth: 'auto',
        isEncrypted: false,
    };

    handleInputChange = evt => {
        this.setState({
            input: evt.target.value,
        });
    };

    handleInputKeyDown = evt => {
        if (evt.keyCode === 13) {
            evt.preventDefault();
            evt.stopPropagation();

            const { value } = evt.target;

            this.setState(state => ({
                items: [...state.items, value],
                input: '',
            }));
        }

        if (this.state.items.length && evt.keyCode === 8 && !this.state.input.length) {
            this.setState(state => ({
                items: state.items.slice(0, state.items.length - 1),
            }));
        }
    };

    handleRemoveItem = index => {
        return () => {
            this.setState(state => ({
                items: state.items.filter((item, i) => i !== index),
            }));
        };
    };

    render() {
        const {
            items,
            placeholder,
            disabled,
            handle,
            onKeyDown,
            onChange,
            onRemove,
            value,
            withValidation,
            isValid,
            unsaved,
            isLoading,
            type,
            tagWidth,
            isEncrypted,
        } = this.props;

        const localItems = handle === 'internal' ? this.state.items : items;
        const localValue = handle === 'internal' ? this.state.input : value;
        const localOnChange = handle === 'internal' ? this.handleInputChange : onChange;
        const localOnKeyDown = handle === 'internal' ? this.handleInputKeyDown : onKeyDown;

        return (
            <label data-test="TagInputContainer">
                <ul
                    className={classnames(
                        Styles.container,
                        disabled ? Styles.disabled : null,
                        localItems && localItems.length > 0 && localItems[0] !== ''
                            ? Styles.sticky
                            : null,
                    )}
                >
                    <li
                        className={classnames(
                            Styles.input,
                            localItems && localItems.length > 0 && localItems[0] !== ''
                                ? Styles.sticky
                                : null,
                        )}
                    >
                        <input
                            type={type}
                            disabled={disabled}
                            placeholder={placeholder}
                            value={localValue}
                            onChange={localOnChange}
                            onKeyDown={localOnKeyDown}
                        />
                    </li>

                    {localItems &&
                        localItems.length > 0 &&
                        localItems[0] !== '' &&
                        localItems.map((item, i) => (
                            <React.Fragment key={i}>
                                {item && item !== '' && (
                                    <li key={i} className={Styles.items}>
                                        <p
                                            title={isEncrypted ? '**********' : item}
                                            style={{ maxWidth: tagWidth }}
                                        >
                                            {isEncrypted ? '**********' : item}
                                        </p>

                                        <i
                                            onClick={
                                                handle === 'internal'
                                                    ? this.handleRemoveItem(i)
                                                    : onRemove(i)
                                            }
                                            className="icon-icon_delete_2"
                                        ></i>
                                    </li>
                                )}
                            </React.Fragment>
                        ))}
                </ul>

                {withValidation && !unsaved && !disabled && !isLoading && isValid && (
                    <ValidationStatus status="success" />
                )}

                {withValidation && !unsaved && !disabled && !isLoading && !isValid && (
                    <ValidationStatus status="error" />
                )}
            </label>
        );
    }
}

export default TagInput;
