export const getDirectorySelector = (state, ownProps, types) => {
    const directories = state.networks[types.section].directories;

    if (directories.length === 0) {
        return null;
    } else {
        const directory = directories.filter(dir => dir.id === ownProps.match.params.id);

        if (!directory || !directory[0]) {
            return null;
        }

        return directory[0];
    }
};

export const getNetworkSelector = (state, ownProps, types) => {
    const networks = state.networks[types.section].networks;

    if (networks.length === 0) {
        return null;
    } else {
        const network = networks.filter(dir => dir.id === ownProps.match.params.id);

        if (!network || !network[0]) {
            return null;
        }

        return network[0];
    }
};

export const NETWORKS_TYPE_ENTITY = {
    section: 'entities',
    element: 'entity',
};

export const NETWORKS_TYPE_SERVICE = {
    section: 'services',
    element: 'service',
};

export const NETWORKS_TYPE_ITEM = {
    section: 'items',
    element: 'item',
};

export const NETWORKS_TYPE_NETWORK = {
    section: 'networks',
    element: 'network',
};
