import { getDataViewsDefaultDate, getInsuranceProductTypeTheme } from 'utils/functions';

export const myProvidersTableListModel = payload => {
    return payload.map(item => {
        return {
            id: item.id,
            icon: item?.provider?.icon ? item.provider.icon : null,
            name: item?.provider?.name ? item.provider.name : null,
            segment: item?.service?.segment?.name ? item.service.segment.name : null,
            product: item?.app_name ? item.app_name : '--',
            country: item?.country?.name ? item.country.name : null,
            country_id: item?.country?.id ? item.country.id : null,
            state: item.state ? item.state : null,
            created: item.created ? item.created : null,
            approved_ts: item.approved_ts ? item.approved_ts : null,
            pending_ts: item.pending_ts ? item.pending_ts : null,
            rejected_ts: item.rejected_ts ? item.rejected_ts : null,
            rejected_comment: item?.cdata?.rejected_comment ? item.cdata.rejected_comment : null,
            details: {
                website: item?.provider?.website ? item.provider.website : null,
                type: item?.provider?.type ? item.provider.type : null,
                email: item?.provider?.email ? item.provider.email : null,
            },
            segment_id: item?.service?.segment_id ? item.service.segment_id : null,
            ui_theme: getInsuranceProductTypeTheme(
                item?.service?.segment?.name ? item.service.segment.name : null,
            ),
        };
    });
};

export const insuranceProductsTableListModel = (payload, list) => {
    return payload?.map(item => {
        const search = list?.filter(i => i?.app_id === item?.app_id);
        return {
            enabled: search && search[0] ? true : false,
            enabled_state: search && search[0] ? getRequestStatus(search[0]) : null,
            id: item.id,
            app_id: item.app_id,
            insurer_id: item.insurer_id,
            service_id: item?.enabledservice?.service_id ? item.enabledservice.service_id : null,
            enabledservice_id: item?.enabledservice?.id ? item.enabledservice.id : null,
            icon: item?.insurer?.icon ? item.insurer.icon : null,
            name: item?.insurer?.name ? item.insurer.name : null,
            segment: item?.enabledservice?.service?.segment?.name
                ? item.enabledservice.service.segment.name
                : null,
            segment_id: item?.enabledservice?.service?.segment?.id
                ? item.enabledservice.service.segment.id
                : null,
            product: item?.app_name ? item.app_name : '--',
            country: item?.country?.name ? item.country.name : null,
            country_id: item?.country?.id ? item.country.id : null,
            state: item.state ? item.state : null,
            created: item.created ? item.created : null,
            approved_ts: item.approved_ts ? item.approved_ts : null,
            pending_ts: item.pending_ts ? item.pending_ts : null,
            rejected_ts: item.rejected_ts ? item.rejected_ts : null,
            rejected_comment: item?.cdata?.rejected_comment ? item.cdata.rejected_comment : null,
            details: {
                website: item?.insurer?.website ? item.insurer.website : null,
                type: item?.insurer?.type ? item.insurer.type : null,
                email: item?.insurer?.email ? item.insurer.email : null,
            },
            addedInsurance: search && search[0] ? search[0] : null,
            ui_theme: getInsuranceProductTypeTheme(
                item?.enabledservice?.service?.segment?.name
                    ? item.enabledservice.service.segment.name
                    : null,
            ),
            insurer_name: item?.insurer?.name ? item.insurer.name : null,
        };
    });
};

export const insuranceProductsAddedListModel = payload => {
    return payload
        .filter(item => item.state !== 'submitted')
        .map(item => {
            return {
                id: item.id,
                app_id: item.app_id,
                provider_id: item?.provider_id,
                service_id: item?.enabledservice?.service_id
                    ? item.enabledservice.service_id
                    : null,
                enabledservice_id: item?.enabledservice?.id ? item.enabledservice.id : null,
                icon: item?.insurer?.icon ? item.insurer.icon : null,
                name: item?.insurer?.name ? item.insurer.name : null,
                segment: item?.enabledservice?.service?.segment?.name
                    ? item.enabledservice.service.segment.name
                    : null,
                product: item?.app_name ? item.app_name : '--',
                client_id: item?.enabledservice?.client_id ? item.enabledservice.client_id : null,
                country: item?.country?.name ? item.country.name : null,
                country_id: item?.country?.id ? item.country.id : null,
                state: item.state ? item.state : null,
                active: item?.cdata?.status ? item.cdata.status : null,
                created: item.created ? item.created : null,
                approved_ts: item.approved_ts ? item.approved_ts : null,
                pending_ts: item.pending_ts ? item.pending_ts : null,
                rejected_ts: item.rejected_ts ? item.rejected_ts : null,
                rejected_comment: item?.cdata?.rejected_comment
                    ? item.cdata.rejected_comment
                    : null,
                details: {
                    website: item?.insurer?.website ? item.insurer.website : null,
                    type: item?.insurer?.type ? item.insurer.type : null,
                    email: item?.insurer?.email ? item.insurer.email : null,
                },
                cdata: item.cdata,
                coverages: {
                    mandatory:
                        item?.enabledservice?.specs?.coverages &&
                        item.enabledservice.specs.coverages.length > 0
                            ? item.enabledservice.specs.coverages.filter(
                                  i => i.type === 'mandatory',
                              )
                            : [],
                    optional:
                        item?.enabledservice?.specs?.coverages &&
                        item.enabledservice.specs.coverages.length > 0
                            ? item.enabledservice.specs.coverages.filter(i => i.type === 'optional')
                            : [],
                },
                documents: item?.documents && item.documents.length > 0 ? item.documents : [],
                ui_theme: getInsuranceProductTypeTheme(
                    item?.enabledservice?.service?.segment?.name
                        ? item.enabledservice.service.segment.name
                        : null,
                ),
            };
        });
};

export const insuranceProductsMetaModel = (payload, list) => {
    const d = new Date();
    const currentYear = d.getFullYear();
    const currentMonth = d.getMonth() + 1;
    const filter = {
        year: currentYear,
        month: currentMonth,
        current_year: currentYear,
        current_month: currentMonth,
        text: getDataViewsDefaultDate(null),
    };

    const output = list.map(product => {
        const search =
            payload &&
            payload.filter(
                i => i.app_id === product.app_id && i?.provider_id === product?.provider_id,
            );

        if (search && search[0]?.start_date) {
            const temp = search[0];
            const date = temp.start_date.split('-');
            const hasData =
                temp.n_sold !== 0 ||
                temp.n_renewed !== 0 ||
                temp.n_canceled !== 0 ||
                temp.n_deductible !== 0
                    ? true
                    : false;

            const newFilter = {
                year: parseInt(date[0]),
                month: parseInt(date[1]),
                current_year: currentYear,
                current_month: currentMonth,
                text: getDataViewsDefaultDate(
                    parseInt(date[0]) === currentYear && parseInt(date[1]) === currentMonth
                        ? null
                        : temp.start_date,
                ),
            };

            let new_n_renewed_and_paid =
                (temp.n_paid ? temp.n_paid : 0) + (temp.n_renewed ? temp.n_renewed : 0);
            let new_total_renewed_and_paid =
                (temp.total_paid ? temp.total_paid : 0) +
                (temp.total_renewed ? temp.total_renewed : 0);
            let new_comission_renewed_and_paid =
                (temp.comission_paid ? temp.comission_paid : 0) +
                (temp.comission_renewed ? temp.comission_renewed : 0);

            const newCommissions = {
                ...temp,
                // n_renewed_and_paid: temp.n_recurrency
                //     ? temp.n_recurrency
                n_renewed_and_paid: new_n_renewed_and_paid,
                total_renewed_and_paid: new_total_renewed_and_paid,
                comission_renewed_and_paid: new_comission_renewed_and_paid,
            };

            return {
                ...product,
                commissions: { ...newCommissions, hasData, filter: newFilter },
            };
        } // Else was not match

        const defaultStats = {
            provider_id: product?.provider_id,
            service_id: product.service_id,
            app_id: product.app_id,
            n_sold: 0,
            total_sold: 0,
            comission_sold: 0,
            n_renewed: 0,
            total_renewed: 0,
            comission_renewed: 0,
            n_paid: 0,
            n_recurrency: 0,
            total_paid: 0,
            comission_paid: 0,
            n_renewed_and_paid: 0,
            total_renewed_and_paid: 0,
            comission_renewed_and_paid: 0,
            n_canceled: 0,
            n_canceled_inside: 0,
            n_canceled_outside: 0,
            total_canceled: 0,
            total_canceled_inside: 0,
            total_canceled_outside: 0,
            refunded: 0,
            n_deductible: 0,
            total_deductible: 0,
            start_date: null,
            end_date: null,
            hasData: false,
            filter,
        };

        return {
            ...product,
            commissions: defaultStats,
        };
    });
    return output;
};

export const getRequestStatus = data => {
    if (data?.state === 'rejected') {
        return 'rejected';
    }

    if (data?.state === 'pending' && data?.cdata?.status === 'pending') {
        return 'pending';
    }

    if (data?.state === 'pending' && data?.cdata?.status === 'canceled') {
        return 'canceled';
    }

    if (data?.state === 'approved' && data?.cdata?.status === 'enabled') {
        return 'active';
    }

    if (data?.state === 'approved' && data?.cdata?.status === 'disabled') {
        return 'inactive';
    }

    return null;
};
