import ActionTypes from 'redux/actionTypes';

export const togglePromptAction = data => ({
    type: ActionTypes.PROMPT_TOGGLE,
    payload: {
        data,
    },
});

export const openPromptAction = data => ({
    type: ActionTypes.PROMPT_OPEN,
    payload: {
        data,
    },
});

export const closePromptAction = () => ({
    type: ActionTypes.PROMPT_CLOSE,
});
