import { getText } from 'localization';
import { roles, userHasPermission } from 'services/roles';
import { isDevEnv, isStgEnv } from 'utils/functions';

const environments = {
    development: 'development',
    production: 'production',
};

const env = isDevEnv() || isStgEnv() ? environments.development : environments.production;

const isAdmin = userHasPermission([roles.ADMIN]);

export let navigation = {
    current: {
        name: 'Home',
        slug: '/',
    },
    routes: {
        projects: [
            {
                section: null,
                icon: null,
                badge: null,
                list: [
                    {
                        id: 0,
                        text: 'DASHBOARD',
                        slug: 'dashboard',
                        disabled: false,
                        routeId: true,
                    },
                ],
            },
            {
                section: 'Devices/Services',
                icon: 'icon_devices',
                badge: null,
                list: [
                    {
                        id: 0,
                        text: 'Marketplace',
                        slug: 'marketplace',
                        disabled: false,
                        routeId: true,
                    },
                ],
            },
            {
                section: 'Users',
                icon: 'icon_user',
                badge: null,
                list: [
                    {
                        id: 0,
                        text: 'End Users',
                        slug: 'end-users',
                        disabled: false,
                        routeId: true,
                    },
                ],
            },
            {
                section: 'Business Logic',
                icon: 'icon_usecases',
                badge: null,
                list: [
                    {
                        id: 0,
                        text: 'Business Rule Engine',
                        slug: 'business-rule-engine',
                        disabled: false,
                        routeId: true,
                    },
                ],
            },
            {
                section: 'TEMPLATES',
                icon: 'icon_templates',
                badge: null,
                list: [
                    {
                        id: 0,
                        text: 'Marketing Cards',
                        slug: 'templates/cards',
                        disabled: false,
                        routeId: true,
                    },
                ],
            },
            {
                section: 'DOCUMENTS',
                icon: 'icon_flow_test',
                badge: null,
                list: [
                    {
                        id: 0,
                        text: 'Documents',
                        slug: 'documents',
                        disabled: false,
                        routeId: true,
                    },
                ],
            },
            {
                section: 'SETTINGS',
                icon: 'icon_settings',
                badge: null,
                list: [
                    {
                        id: 0,
                        text: 'Project settings',
                        slug: 'settings/project',
                        disabled: false,
                        routeId: true,
                    },
                    {
                        id: 1,
                        text: 'Insurance definitions',
                        slug: 'templates/services',
                        disabled: false,
                        routeId: true,
                    },
                ],
            },
            {
                section: 'COMMISSIONS',
                icon: 'icon_quote',
                badge: env === 'production' ? 'Soon' : null,
                list: [
                    {
                        id: 0,
                        text: 'Distributor Commissions',
                        slug: 'commissions/distributor',
                        disabled: env === 'production' ? true : false,
                        routeId: true,
                    },
                ],
            },
            {
                section: 'Monitoring',
                icon: 'icon_flow_test',
                badge: env === 'production' ? 'Soon' : null,
                list: [
                    {
                        id: 0,
                        text: 'Flow Tester',
                        slug: 'flow-tester',
                        disabled: env === 'production',
                        routeId: true,
                    },
                    {
                        id: 1,
                        text: 'Events Simulator',
                        slug: 'events-simulator',
                        disabled: env === 'production',
                        routeId: true,
                    },
                ],
                scope: [roles.INSURER],
            },
        ],
        integrations: [
            {
                section: 'INTEGRATIONS',
                icon: 'icon_apps',
                list: [
                    {
                        id: 0,
                        text: 'My Devices',
                        slug: 'devices',
                        disabled: false,
                        routeId: true,
                    },
                    {
                        id: 1,
                        text: 'Credentials',
                        slug: 'credentials',
                        disabled: false,
                        routeId: true,
                    },
                    {
                        id: 2,
                        text: 'My Services',
                        slug: 'services',
                        disabled: true,
                        routeId: true,
                    },
                    {
                        id: 3,
                        text: 'Statistics',
                        slug: 'statistics',
                        disabled: true,
                        routeId: true,
                    },
                ],
            },
        ],
        analytics: [
            {
                section: 'HUMAN BEHAVIOUR',
                icon: 'icon_behavior_1',
                badge: null,
                list: [
                    {
                        id: 0,
                        text: 'User Visited Places',
                        slug: 'user-visited-places',
                        disabled: false,
                    },
                    {
                        id: 1,
                        text: 'Population Segmentation',
                        slug: 'population-segmentation',
                        disabled: env === 'production',
                    },
                    {
                        id: 2,
                        text: 'Population Overview',
                        slug: 'population-overview',
                        disabled: env === 'production',
                    },
                ],
            },
            {
                section: 'PROJECT METRICS',
                icon: 'icon_metrics',
                badge: null,
                list: [
                    {
                        id: 0,
                        text: 'User Facts',
                        slug: 'user-facts',
                        icon: 'icon_devices',
                        disabled: false,
                    },
                ],
            },
            {
                section: 'USER RAW DATA',
                icon: 'icon_user-raw-data',
                badge: null,
                list: [
                    {
                        id: 0,
                        text: 'Location Data',
                        slug: 'user-location-data',
                        icon: 'icon_devices',
                        disabled: false,
                    },
                ],
            },
        ],
        insurance: [
            {
                section: null,
                icon: null,
                badge: null,
                list: [
                    {
                        id: 0,
                        text: getText('initial_state_navigation_dashboard_label'),
                        slug: 'dashboard',
                        original: 'dashboard',
                        disabled: false,
                        static: true,
                        routeId: false,
                    },
                ],
            },
            {
                section: getText('insurance_section_label'),
                icon: 'icon_insurance',
                list: [
                    {
                        id: 0,
                        text: getText('insurance_dashboard_policyholders_label'),
                        slug: 'insurees',
                        original: 'insurees',
                        disabled: true,
                        routeId: false,
                    },
                    {
                        id: 1,
                        text: getText('insurance_dashboard_quotes_label'),
                        slug: 'quotes',
                        original: 'quotes',
                        disabled: true,
                        routeId: false,
                    },
                    {
                        id: 2,
                        text: getText('insurance_dashboard_policies_label'),
                        slug: 'policies',
                        original: 'policies',
                        disabled: true,
                        routeId: false,
                    },
                    {
                        id: 3,
                        text: getText('insurance_dashboard_protected_assets_label'),
                        slug: 'assets',
                        original: 'assets',
                        disabled: true,
                        routeId: false,
                    },
                    {
                        id: 4,
                        text: getText('constants_payments_and_documents'),
                        slug: 'payments-documents',
                        original: 'payments-documents',
                        disabled: true,
                        routeId: false,
                    },
                    {
                        id: 5,
                        text: getText('insurance_dashboard_claims_label'),
                        slug: 'claims',
                        original: 'claims',
                        disabled: true,
                        routeId: false,
                    },
                ],
            },
        ],
        'networks-management': [
            {
                section: null,
                icon: null,
                badge: null,
                list: [
                    {
                        id: 0,
                        text: 'DASHBOARD',
                        slug: 'dashboard',
                        disabled: false,
                        static: true,
                    },
                ],
            },
            {
                section: 'DIRECTORIES',
                icon: 'icon_devices',
                badge: null,
                list: [
                    {
                        id: 0,
                        text: 'Entities',
                        slug: 'entities',
                        disabled: false,
                        routeId: false,
                    },
                    {
                        id: 1,
                        text: 'Services/Items',
                        slug: 'services-items',
                        childs: ['services', 'items'],
                        disabled: false,
                        routeId: false,
                    },
                ],
            },
            {
                section: 'NETWORKS',
                icon: 'icon_networks',
                badge: null,
                list: [
                    {
                        id: 0,
                        text: 'Networks',
                        slug: 'networks',
                        disabled: false,
                        routeId: false,
                    },
                    {
                        id: 1,
                        text: 'Network usage',
                        slug: 'network-usage',
                        disabled: true,
                        routeId: false,
                    },
                ],
            },
        ],
        distributors: [
            {
                section: null,
                icon: null,
                badge: null,
                list: [
                    {
                        id: 0,
                        text: 'DASHBOARD',
                        slug: 'dashboard',
                        disabled: false,
                        routeId: true,
                    },
                ],
                scope: [roles.DISTRIBUTOR],
            },
            {
                section: 'INSURANCE',
                icon: 'icon_insurance',
                badge: null,
                list: [
                    {
                        id: 0,
                        text: getText('navigation_my_distributors_label'),
                        slug: 'my-distributors',
                        disabled: false,
                    },
                ],
                scope: [roles.INSURER],
            },
            {
                section: getText('navigation_distribution_label'),
                icon: 'icon_devices_2',
                badge: null,
                list: [
                    {
                        text: getText('insurance_products_label'),
                        slug: 'insurance-products',
                        disabled: false,
                    },
                    {
                        text: getText('distributors_entities_distributor_title'),
                        slug: 'distributors',
                        disabled: false,
                    },
                ],
                scope: [roles.DISTRIBUTOR],
            },
        ],
        sellers: [
            {
                section: getText('sellers_navbar_header_seller'),
                icon: 'icon_devices_2',
                badge: null,
                list: [
                    {
                        id: 0,
                        text: getText('sellers_navbar_seller_products'), //'Products',
                        slug: 'invites',
                        disabled: false,
                    },
                ],
                scope: [roles.SELLER, roles.DISTRIBUTORSELLER],
            },
        ],
        admin: [
            {
                section: getText('initial_state_navigation_permissions_label'),
                icon: 'icon_lock',
                badge: null,
                list: [
                    {
                        id: 0,
                        text: getText('initial_state_navigation_approval_requests_label'),
                        slug: 'permissions/approval-requests',
                        disabled: false,
                    },
                ],
                scope: [roles.ADMIN],
            },
            {
                section: getText('initial_state_navigation_entities_label'),
                icon: 'icon_financial_entities',
                badge: null,
                list: [
                    {
                        id: 0,
                        text: getText('initial_state_navigation_entities_management_label'),
                        slug: 'entities/entities-management',
                        disabled: false,
                    },
                ],
                scope: [roles.ADMIN],
            },
            {
                section: 'PRODUCTS',
                icon: 'icon_devices_2',
                badge: null,
                list: [
                    {
                        id: 0,
                        text: getText('initial_state_navigation_insurer_products_label'),
                        slug: 'products/insurer-products',
                        disabled: false,
                    },
                    {
                        id: 1,
                        text: getText('initial_state_navigation_distributors_label'),
                        slug: 'products/distributors',
                        disabled: false,
                    },
                    {
                        id: 2,
                        text: "Providers",
                        slug: 'products/providers',
                        disabled: false,
                    },
                ],
                scope: [roles.ADMIN],
            },
            {
                section: getText('initial_state_navigation_settings_label'),
                icon: 'icon_settings',
                badge: null,
                list: [
                    {
                        id: 0,
                        text: getText('initial_state_navigation_defaults_per_geography_label'),
                        slug: 'settings/defaults-per-geography',
                        disabled: false,
                    },
                ],
                scope: [roles.ADMIN],
            },
        ],
        devices: [
            {
                id: 0,
                name: 'Structure',
                slug: 'structure',
                baseUrl: null,
            },
            {
                id: 1,
                name: 'In-App Graphics',
                slug: 'graphics',
                baseUrl: null,
                disabled: env === 'production',
            },
            {
                id: 2,
                name: 'Automations',
                slug: 'automations',
                baseUrl: null,
                disabled: env === 'production',
            },
        ],
        compatibleDevices: [
            {
                id: 0,
                name: 'Marketplace',
                slug: 'public',
                baseUrl: null,
            },
            {
                id: 1,
                name: 'Dedicated to Project',
                slug: 'private',
                baseUrl: null,
            },
            {
                id: 2,
                name: 'My Integrations',
                slug: 'mine',
                baseUrl: null,
            },
        ],
        templatesService: [
            {
                id: 0,
                name: 'Protected Assets',
                slug: 'protected-assets',
                baseUrl: null,
                disabled: false,
            },
            {
                id: 1,
                name: 'Coverages',
                slug: 'coverages',
                baseUrl: null,
                disabled: false,
            },
            {
                id: 2,
                name: 'Settings',
                slug: 'settings',
                baseUrl: null,
                disabled: false,
            },
        ],
        campaigns: [
            {
                section: null,
                icon: null,
                badge: null,
                list: [
                    {
                        id: 0,
                        text: getText('vouchers_list_campaigns_title'),
                        slug: 'campaigns',
                        disabled: false,
                        routeId: true,
                    },
                    /* {
                        id: 1,
                        text: getText('vouchers_list_vouchers_title'),
                        slug: 'vouchers',
                        disabled: true,
                        routeId: true,
                    }, */
                ],
                scope: [roles.INSURER, roles.DISTRIBUTOR],
            },
        ],
    },
};

(function populateNewDistributorFeatures() {
    const lists = {
        vendors: [
            {
                id: 0,
                text: getText('distributor_features_sellers_label'),
                slug: 'vendors',
                disabled: false,
            },
            {
                id: 1,
                text: getText('distributor_features_points_of_sale_label'),
                slug: 'outlets',
                disabled: false,
            },
        ],
        leads: [
            {
                id: 0,
                text: getText('distributor_features_leads_generator_label'),
                slug: 'leads-generators',
                disabled: false,
            },
            {
                id: 1,
                text: getText('distributor_features_leads_generator_points_of_sale_label'),
                slug: 'leads-generators-points',
                disabled: false,
            },
        ],
    };

    const entities = {
        vendors: {
            section: getText('distributor_sale_section_label'),
            icon: 'icon_store',
            badge: null,
            scope: [roles.DISTRIBUTOR],
            list: lists.vendors,
        },
        leads: {
            section: getText('distributor_leads_generation_section_label'),
            icon: 'icon_office',
            badge: null,
            scope: [roles.DISTRIBUTOR],
            list: lists.leads,
        },
    };

    navigation.routes.distributors.push(entities.vendors);
    navigation.routes.distributors.push(entities.leads);
})();

/* if (isDevEnv() || isQaEnv()) {
    const lists = {
        partners: [
            {
                id: 0,
                text: 'Geradores de Leads',
                slug: 'leads-generators',
                disabled: false,
            },
            {
                id: 1,
                text: 'Pontos de Geração de Leads',
                slug: 'leads-generators-points',
                disabled: false,
            },
        ],
    };

    const entities = {
        partners: {
            section: 'Parcerias',
            icon: 'icon_devices_2',
            badge: null,
            list: lists.partners,
            scope: [roles.SELLER], // TODO: change to [roles.DISTRIBUTORSELLER]
        },
    };

    navigation.routes.sellers.push(entities.partners);
}
 */
