import Spinner from 'assets/svg/spinner.svg';
import classnames from 'classnames';
import React, { ChangeEvent, FocusEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { sendSuccessToast } from 'services/toast';
import ValidationStatus from 'shared/ValidationStatus';
import Styles from './Input.module.scss';

interface InputProps {
    title?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    theme?:
        | 'primary'
        | 'secondary'
        | 'ternary'
        | 'edit'
        | 'search'
        | 'form'
        | 'select'
        | 'quaternary'
        | 'json';
    variant?:
        | 'none'
        | 'underlined'
        | 'outlined'
        | 'outlined-bluish'
        | 'filled'
        | 'white'
        | 'grey'
        | 'small'
        | 'locked';
    state?: 'empty' | 'active' | 'error' | 'left';
    size?: 'sm' | 'md' | 'lg';
    contained?: boolean;
    icon?: string;
    label?: string;
    type?: string;
    name?: string;
    placeholder?: string;
    value?: string;
    defaultValue?: string;
    onSave?: (value: string) => void;
    onClick?: (event: MouseEvent<HTMLInputElement>) => void;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onClickIcon?: () => void;
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    onClear?: () => void;
    error?: string | boolean;
    copyToClipboard?: boolean;
    minLength?: string | number;
    maxLength?: string | number;
    withCounter?: boolean;
    counter?: number;
    withPicker?: boolean;
    pickColor?: string;
    prefix?: string;
    canSave?: boolean;
    isLoading?: boolean;
    focusOnDemand?: boolean;
    focusOnDemandDelay?: number;
    namespace?: string;
    extra?: string;
    withValidation?: boolean;
    isValid?: boolean;
    errorValidationTip?: string;
    unsaved?: boolean;
    minValue?: string;
    maxValue?: string;
    pickerColor?: string;
    readonly?: boolean;
    pattern?: string;
    inputRef?: React.RefObject<HTMLInputElement>;
    isRequired?: boolean;
    onUnlock?: () => void;
    withBluishBackground?: boolean;
    squared?: boolean;
}

const Input: React.FC<InputProps> = props => {
    const {
        title,
        autoFocus,
        disabled,
        theme = 'primary',
        variant = 'outlined',
        state,
        size = 'md',
        placeholder,
        value,
        defaultValue,
        icon,
        label,
        type = 'text',
        name,
        onChange,
        onClick,
        contained,
        error,
        minLength,
        maxLength,
        withCounter,
        counter,
        withPicker,
        pickerColor,
        prefix,
        canSave,
        isLoading,
        namespace,
        withValidation,
        isValid,
        unsaved,
        minValue,
        maxValue,
        readonly = false,
        pattern,
        onClear,
        inputRef,
        isRequired = false,
        extra,
        onUnlock,
        errorValidationTip,
        withBluishBackground,
        focusOnDemand = false,
        focusOnDemandDelay = 10,
        copyToClipboard = false,
        onClickIcon,
        onSave,
        onBlur,
        squared = false,
    } = props;

    const myRef = useRef<HTMLInputElement>(null);
    if (inputRef) {
        myRef.current = inputRef.current;
    }

    const [save, setSave] = useState<boolean>(false);

    useEffect(() => {
        if (myRef.current && (autoFocus || focusOnDemand)) {
            myRef.current.focus();
        }
    }, [autoFocus, focusOnDemand]);

    useEffect(() => {
        if (focusOnDemand) {
            setTimeout(() => {
                if (myRef.current) {
                    myRef.current.focus();
                }
            }, focusOnDemandDelay);
        }
    }, [focusOnDemand, focusOnDemandDelay]);

    const handleOnClickIcon = (e: MouseEvent<HTMLElement>) => {
        if (copyToClipboard) {
            const value = myRef.current?.value;
            if (value) {
                const el = document.createElement('textarea');
                el.value = value;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                sendSuccessToast('Text copied to clipboard.');
            }
        } else {
            onClickIcon && onClickIcon();
        }
    };

    const handleOnSave = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setSave(true);
        onSave && onSave(myRef.current?.value || '');
    };

    const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
        if (theme === 'edit' && onBlur && !save) {
            setTimeout(() => {
                setSave(false);
                onBlur(e);
            }, 150);
        } else if (onBlur) {
            onBlur(e);
        }
    };

    const titleStyle = title ? Styles.title : null;
    const labelStyle = label ? Styles.label : null;
    let inputStyle = theme
        ? classnames(
              Styles.input,
              Styles[theme],
              Styles[variant],
              squared && Styles.squared,
              Styles[size],
              readonly ? Styles.readOnly : null,
          )
        : classnames(Styles.input, Styles[variant]);
    inputStyle = state ? classnames(inputStyle, Styles[state]) : inputStyle;
    inputStyle = icon || onClear ? classnames(inputStyle, Styles.withIcon) : inputStyle;
    inputStyle = withCounter ? classnames(inputStyle, Styles.withCounter) : inputStyle;
    inputStyle = prefix ? classnames(inputStyle, Styles.withPrefix) : inputStyle;
    inputStyle = withBluishBackground ? classnames(inputStyle, Styles.bluish) : inputStyle;

    return (
        <div
            data-test="InputContainer"
            className={classnames(Styles.container, contained ? Styles.contained : null)}
        >
            <label data-test="InputLabelTag">
                {title && (
                    <span data-test="InputLabelText" className={titleStyle}>
                        {title}
                    </span>
                )}
                {label && (
                    <span data-test="InputLabelText" className={labelStyle}>
                        {label}
                    </span>
                )}
                {prefix && (
                    <span className={classnames(Styles.prefix, Styles[state])}>{prefix}</span>
                )}
                <input
                    data-test="InputField"
                    data-namespace={namespace}
                    data-extra={extra}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    aria-disabled={disabled}
                    ref={myRef}
                    onChange={onChange}
                    onClick={onClick}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    value={value}
                    type={type}
                    name={name}
                    className={icon ? classnames(inputStyle, Styles.icon) : inputStyle}
                    minLength={minLength ? Number(minLength) : undefined}
                    maxLength={maxLength ? Number(maxLength) : undefined}
                    onBlur={handleOnBlur}
                    min={minValue}
                    max={maxValue}
                    readOnly={readonly}
                    pattern={pattern}
                    required={isRequired}
                />
                {icon && (
                    <i
                        data-test="InputIcon"
                        className={classnames(Styles.icon, `icon-${icon}`)}
                        onClick={handleOnClickIcon}
                    ></i>
                )}
                {onClear && value !== '' && (
                    <i
                        data-test="InputIcon"
                        data-name={name}
                        className={classnames(Styles.clear, 'icon-icon_delete_2')}
                        onClick={onClear}
                    ></i>
                )}
                {theme === 'edit' && !isLoading && !disabled && (
                    <i
                        data-test="InputIcon"
                        className={classnames(
                            Styles.action,
                            Styles.icon,
                            canSave ? Styles?.active : null,
                            canSave ? 'icon-icon_save' : 'icon-icon_edit',
                        )}
                        onClick={canSave ? handleOnSave : null}
                    ></i>
                )}
                {theme === 'edit' && isLoading && (
                    <div data-test="InputLoading" className={Styles.loading}>
                        <img src={Spinner} alt="Loading..." />
                    </div>
                )}
                {theme === 'search' && (
                    <i
                        data-test="InputIcon"
                        className={classnames(
                            Styles.action,
                            Styles.icon,
                            canSave ? Styles?.active : null,
                            variant === 'filled' || variant === 'white' || variant === 'grey'
                                ? `icon-icon_search`
                                : `icon-icon_arrow_down`,
                        )}
                        onClick={canSave ? handleOnSave : null}
                    ></i>
                )}
                {withCounter && maxLength && (
                    <span className={Styles.counter}>
                        {counter ? counter : value?.length}/{maxLength}
                    </span>
                )}
                {withPicker && pickerColor && (
                    <span className={Styles.picker} style={{ backgroundColor: pickerColor }}></span>
                )}
                {variant === 'locked' && readonly && (
                    <span data-test="InputClear" className={Styles.clear}>
                        <i className="icon-icon_delete_2" onClick={onUnlock}></i>
                    </span>
                )}
                {withValidation && !unsaved && !disabled && !isLoading && isValid && (
                    <ValidationStatus status="success" />
                )}
                {withValidation && !unsaved && !disabled && !isLoading && !isValid && (
                    <ValidationStatus
                        errorValidationTip={
                            errorValidationTip ? errorValidationTip : 'Required field'
                        }
                        status="error"
                    />
                )}
            </label>
            {error && (
                <p data-test="InputError" className={Styles.errorMessage}>
                    {error}
                </p>
            )}
        </div>
    );
};

export default Input;
