import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './ButtonLink.module.scss';

export class ButtonLink extends React.PureComponent {
    static propTypes = {
        theme: PropTypes.oneOf(['yellow', 'blue', 'red', 'sport', 'pet', 'car', 'default']),
        variant: PropTypes.oneOf(['filled']),
        size: PropTypes.oneOf(['sm', 'md', 'lg']),
        text: PropTypes.string,
        url: PropTypes.string,
        disabled: PropTypes.bool,
        icon: PropTypes.string,
        iconPosition: PropTypes.oneOf(['before', 'after']),
        onClick: PropTypes.func,
    };

    static defaultProps = {
        theme: 'yellow',
        variant: 'filled',
        size: 'md',
        url: '#',
    };

    render() {
        const { url, text, icon, iconPosition, theme, variant, size, onClick } = this.props;

        return (
            <div
                data-test="ButtonLinkContainer"
                className={classnames(Styles.container, Styles[theme])}
            >
                <Link data-test="ButtonLinkAnchor" to={url} onClick={onClick}>
                    <button
                        data-test="ButtonLinkBtn"
                        type="button"
                        className={classnames(
                            Styles.button,
                            Styles[theme],
                            Styles[size],
                            Styles[variant],
                        )}
                    >
                        {icon && iconPosition === 'before' && (
                            <i
                                data-test="ButtonIcon"
                                className={classnames(
                                    Styles.icon,
                                    Styles[iconPosition],
                                    `icon-${icon}`,
                                )}
                            ></i>
                        )}

                        {text}

                        {icon && iconPosition === 'after' && (
                            <i
                                data-test="ButtonIcon"
                                className={classnames(
                                    Styles.icon,
                                    Styles[iconPosition],
                                    `icon-${icon}`,
                                )}
                            ></i>
                        )}
                    </button>
                </Link>
            </div>
        );
    }
}

export default ButtonLink;
