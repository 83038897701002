import React from 'react';
import PropTypes from 'prop-types';
import Styles from './List.module.scss';
import FilterCheck from 'shared/FilterCheck';

export class List extends React.PureComponent {
    static propTypes = {
        data: PropTypes.object,
        listId: PropTypes.number,
        onToggleFilter: PropTypes.func,
    };

    render() {
        const { data, onToggleFilter, listId } = this.props;

        if (!data || data.list.length === 0) {
            return null;
        }

        return (
            <ul data-test="ListContainer" className={Styles.list}>
                <li data-test="ListTitle">{data.label}</li>

                {data.list.map(item => (
                    <FilterCheck
                        data-test="ListFilter"
                        key={item.id}
                        data={item}
                        listId={listId}
                        onToggleFilter={onToggleFilter}
                    />
                ))}
            </ul>
        );
    }
}

export default List;
