import { getText } from 'localization';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { lazy, Suspense } from 'react';
import NetworkDetails from 'redux/containers/Networks/NetworkDetails';
import Button from 'shared/Button';
import ErrorBoundary from 'shared/ErrorBoundary';
import Loading from 'shared/Loading';
import Popup from 'shared/Popup';
import SectionHeader from 'shared/SectionHeader';
import { getElementPosition } from 'utils/functions';
import Header from './Header';
import Styles from './Network.module.scss';
import SubHeader from './SubHeader';

const Table = lazy(() => import('components/Networks/Table'));
const NetworkEntityModal = lazy(() => import('redux/containers/Networks/NetworkEntityModal'));

export class Network extends React.PureComponent {
    state = {
        search: '',
        focusEdit: false,
        settings: {
            active: false,
            element: {},
            id: null,
        },
        isNetworkDetails: false,
    };

    static propTypes = {
        isLoading: PropTypes.bool,
        isSaving: PropTypes.bool,
        texts: PropTypes.object,
        entities: PropTypes.array,
        selectState: PropTypes.object,
        network: PropTypes.object,
        onEditEntityDirectory: PropTypes.func,
        history: PropTypes.object,
        onOpenNetworkEntityModal: PropTypes.func,
        getDirectories: PropTypes.func,
        fetchData: PropTypes.func,
        activeModal: PropTypes.bool,
        tableHeader: PropTypes.array,
        tableFilters: PropTypes.object,
        tablePagination: PropTypes.object,
        openPrompt: PropTypes.func,
        onDeleteNetwork: PropTypes.func,
        onClearNetwork: PropTypes.func,
        onToggleEntity: PropTypes.func,
        ownProps: PropTypes.object,
    };

    componentDidMount() {
        this.props.fetchData();

        const values = queryString.parse(this.props.ownProps.location.search);
        if (values && values.from && values.from === 'create') {
            this.setState({ focusEdit: true });
        }
    }

    componentWillUnmount() {
        this.props.onClearNetwork();
    }

    handleGoBack = () => {
        // this.props.history.push('/networks-management/networks');
        this.props.ownProps.history.push('/networks-management/networks');
    };

    handleGoToEntities = () => {
        // this.props.history.push('/networks-management/networks');
        this.props.ownProps.history.push('/networks-management/entities');
    };

    handleOnOpenNetworkDetails = () => {
        this.setState(state => ({
            isNetworkDetails: true,
        }));
    };

    handleOnCloseNetworkDetails = () => {
        this.setState(state => ({
            isNetworkDetails: false,
        }));
    };

    handleOnSearch = e => {
        const value = e.target.value;

        this.setState({
            search: value,
        });
    };

    handleOnSearchClear = () => {
        this.setState({
            search: '',
        });
    };

    handleOnSubmit = e => {
        e.preventDefault();
    };

    handleOnOpenNetworkEntityModal = e => {
        if (e && e.id) {
            this.props.onOpenNetworkEntityModal({ id: e.id });
        }
    };

    handleToogleSettings = e => {
        e.preventDefault();
        const id = e.currentTarget.getAttribute('data-id');

        this.setState({
            settings: {
                active: !this.state.settings?.active,
                element: getElementPosition(e.currentTarget),
                id: id,
            },
        });
    };

    handleClickSetting = e => {
        e.preventDefault();

        const data = {
            id: 'deleteEntityPrompt',
            title: 'Alert',
            content: getText('prompt_delete_entity'),
            onConfirm: () => this.props.onDeleteNetwork({ id: this.state.settings.id }),
        };

        this.props.openPrompt(data);
    };

    handleCloseSettings = () => {
        this.setState({
            settings: {
                active: false,
                id: null,
            },
        });
    };

    handleOnSelect = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (value === false) {
            const data = {
                id: 'inactiveEntityPrompt',
                title: 'Alert',
                content: getText('prompt_disable_entity'),
                onConfirm: () =>
                    this.props.onToggleEntity({ id: name, state: 'inactive', action: 'single' }),
            };
            this.props.openPrompt(data);
        } else {
            this.props.onToggleEntity({ id: name, state: 'active', action: 'single' });
        }
    };

    handleOnSelectBulk = (e, direct) => {
        let value = null;
        let type = 'bulk';

        if (!direct) {
            const target = e.target;
            value = target.type === 'checkbox' ? target.checked : target.value;
        } else {
            value = direct.value;

            if (typeof value === 'string') {
                type = 'directory';
            }
        }

        if (value === false) {
            const data = {
                id: 'inactiveEntityPrompt',
                title: 'Alert',
                content: getText('selfcare_network_section_prompt_disable_entity_bulk'),
                onConfirm: () => this.props.onToggleEntity({ state: 'inactive', action: type }),
            };
            this.props.openPrompt(data);
        } else {
            this.props.onToggleEntity({ id: value, state: 'active', action: type });
        }
    };

    getPopupContent() {
        return (
            <>
                <li onClick={this.handleClickSetting}>{getText('delete_entity')}</li>
            </>
        );
    }

    render() {
        const {
            isLoading,
            isSaving,
            texts,
            entities,
            selectState,
            network,
            activeModal,
            tableHeader,
            tableFilters,
            tablePagination,
            fetchData,
        } = this.props;
        const { search, settings, isNetworkDetails } = this.state;

        const readOnly =
            network && network.hasOwnProperty('read_only') && network.read_only === true
                ? true
                : false;

        return (
            <ErrorBoundary>
                <article data-test="NetworkContainer" className={Styles.entity}>
                    <SectionHeader
                        data-test="NetworkSectionHeader"
                        title={
                            !isNetworkDetails
                                ? getText('network')
                                : getText('selfcare_network_section_edit_network_label')
                        }
                        texts={texts}
                        action="network"
                        onClick={
                            !isNetworkDetails ? this.handleGoBack : this.handleOnCloseNetworkDetails
                        }
                        onSearch={null}
                        onClear={null}
                    />
                    <Header
                        data-test="NetworkHeader"
                        texts={texts}
                        isLoading={isLoading}
                        isSaving={isSaving}
                        network={network}
                        onEdit={this.handleOnOpenNetworkDetails}
                        isOpen={isNetworkDetails}
                        readOnly={readOnly}
                    />

                    {!isNetworkDetails && (
                        <SubHeader
                            data-test="NetworkSubHeader"
                            texts={texts}
                            search={search}
                            onSearch={this.handleOnSearch}
                            onClear={this.handleOnSearchClear}
                            onSelectCheckbox={this.handleOnSelectBulk}
                            section="Entities"
                            selectState={selectState}
                            isLoading={isLoading}
                            isSaving={isSaving}
                            readOnly={readOnly}
                            fetchData={fetchData}
                        />
                    )}

                    <section className={Styles.scrollContainer}>
                        {isNetworkDetails && (
                            <NetworkDetails
                                data-test="NetworkDetails"
                                type="edit"
                                data={network}
                                onClose={this.handleOnCloseNetworkDetails}
                            />
                        )}

                        <Suspense fallback={<Loading theme="none" variant="sm" />}>
                            {isLoading && (
                                <Loading
                                    theme="white"
                                    background="opacity"
                                    variant="sm"
                                    position="absolute"
                                />
                            )}

                            {!isLoading && (!entities || entities.length === 0) && (
                                <div data-test="NetworkEmpty" className={Styles.empty}>
                                    <div className={Styles.icons}>
                                        <i className="icon-icon_entities"></i>
                                        <i className="icon-icon_entities"></i>
                                        <i className="icon-icon_entities"></i>
                                    </div>
                                    <div className={Styles.text}>
                                        <h2>
                                            {getText(
                                                'selfcare_network_section_no_entities_message',
                                            )}
                                        </h2>
                                        <p>
                                            {getText('selfcare_network_section_add_entity_message')}
                                        </p>
                                    </div>
                                    <div className={Styles.add}>
                                        <Button
                                            variant="filled"
                                            theme="ternary"
                                            value="Entities"
                                            state="active"
                                            onClick={this.handleGoToEntities}
                                        />
                                    </div>
                                </div>
                            )}

                            {entities && entities.length > 0 && (
                                <Table
                                    data-test="NetworkTable"
                                    section="network"
                                    header={tableHeader}
                                    data={entities}
                                    filters={tableFilters}
                                    pagination={tablePagination}
                                    texts={texts}
                                    onClickPrev={this.handleOnClickPrev}
                                    onClickNext={this.handleOnClickNext}
                                    onClickStart={this.handleOnClickStart}
                                    onClickEnd={this.handleOnClickEnd}
                                    onEdit={this.handleOnOpenNetworkEntityModal}
                                    onSort={this.handleOnSort}
                                    emptyText={getText('no_results')}
                                    onSelect={this.handleOnSelect}
                                    readOnly={readOnly}
                                />
                            )}
                            {activeModal && (
                                <NetworkEntityModal
                                    data-test="NetworkNetworkEntityModal"
                                    readOnly={readOnly}
                                />
                            )}

                            <Popup
                                data-test="NetworkSettingsPopup"
                                list
                                position="bottom"
                                id="entitySettingsPopup"
                                active={settings?.active}
                                element={settings.element}
                                element-id={settings.id}
                                onClickSetting={this.handleClickSetting}
                                closePopup={this.handleCloseSettings}
                                content={this.getPopupContent()}
                            />
                        </Suspense>
                    </section>
                </article>
            </ErrorBoundary>
        );
    }
}

export default Network;
