import withAuth from 'hoc/withAuth';
import { lazy } from 'react';
import { roles } from 'services/roles';

const Campaigns = lazy(() => import('pages/Campaigns/index'));
const Campaign = lazy(() => import('pages/Campaign/index'));

export const campaignsRoutes = [
    {
        id: 'route-campaigns-campaigns',
        path: ['/campaigns/campaigns'],
        exact: true,
        header: {
            visible: true,
            section: 'campaigns',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'campaigns',
        },
        component: withAuth(Campaigns),
        'data-test': 'RouteCampaignsCampaigns',
        scope: [roles.DISTRIBUTOR, roles.INSURER],
    },
    {
        id: 'route-campaigns-campaigns',
        path: ['/campaigns/campaigns/:id'],
        exact: true,
        header: {
            visible: true,
            section: 'campaigns',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'campaigns',
        },
        component: withAuth(Campaign),
        'data-test': 'RouteCampaignsCampaigns',
        scope: [roles.DISTRIBUTOR, roles.INSURER],
    },
    {
        id: 'route-campaigns-vouchers',
        path: ['/campaigns/vouchers'],
        exact: true,
        header: {
            visible: true,
            section: 'campaigns',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'campaigns',
        },
        component: withAuth(Campaigns),
        'data-test': 'RouteCampaignsVouchers',
        scope: [roles.DISTRIBUTOR, roles.INSURER],
    },
];
