import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { sendSuccessToast } from 'services/toast';
import ValidationStatus from 'shared/ValidationStatus';
import Styles from './Textarea.module.scss';

export class Textarea extends React.PureComponent {
    myRef = React.createRef();

    state = {
        focus: true,
        blur: null,
        save: false,
    };

    static propTypes = {
        autoFocus: PropTypes.bool,
        disabled: PropTypes.bool,
        theme: PropTypes.oneOf([
            'primary',
            'secondary',
            'ternary',
            'quaternary',
            'edit',
            'search',
            'form',
            'json',
        ]),
        variant: PropTypes.oneOf(['none', 'underlined', 'outlined', 'filled', 'white', 'grey']),
        state: PropTypes.oneOf(['empty', 'active', 'error', 'left', 'action']),
        contained: PropTypes.bool,
        icon: PropTypes.string,
        name: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.string,
        defaultValue: PropTypes.string,
        onSave: PropTypes.func,
        onClick: PropTypes.func,
        onChange: PropTypes.func,
        onClickIcon: PropTypes.func,
        onBlur: PropTypes.func,
        error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        copyToClipboard: PropTypes.bool,
        minLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        withCounter: PropTypes.bool,
        counter: PropTypes.number,
        isLoading: PropTypes.bool,
        focusOnDemand: PropTypes.bool,
        namespace: PropTypes.string,
        withValidation: PropTypes.bool,
        isValid: PropTypes.bool,
        unsaved: PropTypes.bool,
        minValue: PropTypes.string,
        maxValue: PropTypes.string,
        pickerColor: PropTypes.string,
        rows: PropTypes.string,
        cols: PropTypes.string,
        spellcheck: PropTypes.bool,
        fullHeight: PropTypes.bool,
        noResize: PropTypes.bool,
        isFullWidth: PropTypes.bool,
        isOverflowHidden: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        variant: 'outlined',
        theme: 'primary',
        copyToClipboard: false,
        rows: '2',
        cols: '50',
        spellcheck: false,
        fullHeight: false,
        noResize: false,
        isFullWidth: false,
        customClassname: undefined,
    };

    componentDidMount() {
        if (
            this.myRef &&
            this.myRef.current &&
            (this.props.autoFocus || this.props.focusOnDemand)
        ) {
            this.myRef.current.focus();
        }

        this.setState({
            value: this.props.value,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.myRef &&
            this.myRef.current &&
            !prevProps.focusOnDemand &&
            this.props.focusOnDemand
        ) {
            this.myRef.current.focus();
        }
    }

    handleOnClickIcon = e => {
        // e.preventDefault();
        if (this.props.copyToClipboard) {
            const value = this.myRef.current.value;
            if (value) {
                const el = document.createElement('textarea');
                el.value = value;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                sendSuccessToast('Text copied to clipboard.');
            }
        } else {
            this.props.onClickIcon();
        }
    };

    handleOnSave = e => {
        e.preventDefault();
        this.setState(
            {
                focus: true,
                blur: false,
                save: true,
            },
            () => {
                this.props.onSave(this.myRef.current.value);
            },
        );
    };

    handleOnFocus = e => {
        if (this.props.theme === 'edit') {
            this.setState({
                focus: true,
                blur: false,
            });
        }
    };

    handleOnBlur = e => {
        if (this.props.theme === 'edit' && this.props.onBlur && !this.state.save) {
            setTimeout(() => {
                this.setState(
                    {
                        focus: false,
                        blur: true,
                        save: false,
                    },
                    () => {
                        this.props.onBlur();
                    },
                );
            }, 150);
        } else if (this.props.onBlur) {
            this.props.onBlur(e);
        }
    };

    render() {
        const {
            autoFocus,
            disabled,
            theme,
            variant,
            state,
            placeholder,
            value,
            defaultValue,
            icon,
            name,
            onChange,
            onClick,
            contained,
            error,
            minLength,
            maxLength,
            withCounter,
            counter,
            isLoading,
            namespace,
            withValidation,
            isValid,
            unsaved,
            minValue,
            maxValue,
            rows,
            cols,
            spellcheck,
            fullHeight,
            noResize,
            isFullWidth,
            customClassname,
            isOverflowHidden,
        } = this.props;

        let textareaStyle = theme
            ? classnames(
                  Styles.textarea,
                  Styles[theme],
                  isFullWidth && Styles.fullWidth,
                  Styles[variant],
                  customClassname && Styles[customClassname],
              )
            : classnames(
                  Styles.textarea,
                  Styles[variant],
                  isFullWidth && Styles.fullWidth,
                  customClassname && Styles[customClassname],
              );
        textareaStyle = state
            ? classnames(
                  textareaStyle,
                  Styles[state],
                  isFullWidth && Styles.fullWidth,
                  customClassname && Styles[customClassname],
              )
            : textareaStyle;
        textareaStyle = icon
            ? classnames(
                  textareaStyle,
                  Styles.withIcon,
                  isFullWidth && Styles.fullWidth,
                  customClassname && Styles[customClassname],
              )
            : textareaStyle;
        textareaStyle = withCounter
            ? classnames(
                  textareaStyle,
                  Styles.withCounter,
                  isFullWidth && Styles.fullWidth,
                  customClassname && Styles[customClassname],
              )
            : textareaStyle;

        return (
            <div
                data-test="TextareaContainer"
                className={classnames(
                    Styles.container,
                    contained ? Styles.contained : null,
                    fullHeight ? Styles.fullHeight : null,
                    noResize ? Styles.noResize : null,
                )}
            >
                <textarea
                    data-test="TextareaField"
                    autoFocus={autoFocus}
                    disabled={disabled}
                    aria-disabled={disabled}
                    data-namespace={namespace}
                    ref={this.myRef}
                    onChange={onChange}
                    onClick={onClick}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    value={value}
                    name={name}
                    rows={rows}
                    cols={cols}
                    className={
                        icon
                            ? classnames(
                                  textareaStyle,
                                  Styles.icon,
                                  isOverflowHidden && Styles.isOverflowHidden,
                              )
                            : classnames(textareaStyle, isOverflowHidden && Styles.isOverflowHidden)
                    }
                    minLength={minLength}
                    maxLength={maxLength}
                    onFocus={this.handleOnFocus}
                    onBlur={this.handleOnBlur}
                    min={minValue}
                    max={maxValue}
                    spellCheck={spellcheck}
                >
                    {value}
                </textarea>

                {withCounter && counter && maxLength && (
                    <span className={Styles.counter}>
                        {counter}/{maxLength}
                    </span>
                )}

                {withCounter && !counter && maxLength && (
                    <span className={Styles.counter}>
                        {value?.length}/{maxLength}
                    </span>
                )}

                {withValidation && !unsaved && !disabled && !isLoading && isValid && (
                    <ValidationStatus status="success" />
                )}

                {withValidation && !unsaved && !disabled && !isLoading && !isValid && (
                    <ValidationStatus status="error" />
                )}

                {error && (
                    <p data-test="TextareaError" className={Styles.errorMessage}>
                        {error}
                    </p>
                )}
            </div>
        );
    }
}

export default Textarea;
