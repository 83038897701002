import ActionTypes from 'redux/actionTypes';

export const isLoadingAction = isLoading => ({
    type: ActionTypes.IS_LOADING,
    payload: {
        isLoading,
    },
});

export const isGenericLoadingAction = isGenericLoading => ({
    type: ActionTypes.HANDLE_GENERIC_LOADING,
    payload: {
        isGenericLoading,
    },
});

export const appIsSavingAction = data => ({
    type: ActionTypes.IS_SAVING,
    payload: {
        data,
    },
});

export const toggleModalAction = modalOpen => ({
    type: ActionTypes.TOGGLE_MODAL,
    payload: {
        modalOpen,
    },
});

export const loginSuccessAction = (data, callbackUrl) => ({
    type: ActionTypes.APP_LOGIN_SUCCESS,
    payload: {
        data,
        callbackUrl,
    },
});

export const loginErrorAction = (data, custom, pending) => ({
    type: ActionTypes.APP_LOGIN_ERROR,
    payload: {
        data,
        custom,
        pending,
    },
});

export const loginStoreEmailAction = data => ({
    type: ActionTypes.APP_LOGIN_STORE_EMAIL,
    payload: {
        data,
    },
});

export const loginClearAction = () => ({
    type: ActionTypes.APP_LOGIN_CLEAR,
});

export const checkAuthAction = () => ({
    type: ActionTypes.APP_CHECK_AUTH,
});

export const checkSessionAction = () => ({
    type: ActionTypes.CHECK_SESSION,
});

export const registerSuccessAction = data => ({
    type: ActionTypes.APP_REGISTER_SUCCESS,
    payload: {
        data,
    },
});

export const registerErrorAction = data => ({
    type: ActionTypes.APP_REGISTER_ERROR,
    payload: {
        data,
    },
});

export const registerResetAction = () => ({
    type: ActionTypes.APP_REGISTER_RESET,
});

export const recoverSuccessAction = data => ({
    type: ActionTypes.APP_RECOVER_SUCCESS,
    payload: {
        data,
    },
});

export const recoverErrorAction = data => ({
    type: ActionTypes.APP_RECOVER_ERROR,
    payload: {
        data,
    },
});

export const recoverResetAction = () => ({
    type: ActionTypes.APP_RECOVER_RESET,
});

export const openRegisterTermsModalAction = () => ({
    type: ActionTypes.APP_REGISTER_OPEN_TERMS_MODAL,
});

export const closeRegisterTermsModalAction = () => ({
    type: ActionTypes.APP_REGISTER_CLOSE_TERMS_MODAL,
});

export const appToggleIntegrations = () => ({
    type: ActionTypes.APP_TOGGLE_INTEGRATIONS,
});
