import Quote from 'pages/Insurance/Quote';
import { connect } from 'react-redux';
import {
    insuranceClearCurrentViewAction,
    insuranceClearSearchByTypeAction,
    openInsurancePoliciesDamagesModalAction,
    openInsurancePoliciesDocumentsModalAction,
    openInsurancePoliciesMediaModalAction,
    openInsurancePoliciesPaymentsModalAction,
} from 'redux/domains/insurance/actions';
import { hasProjectSelected } from 'redux/domains/insurance/selector';
import {
    insuranceFetchDataThunk,
    insuranceOnEditAssetThunk,
    insuranceOnSearchByTypeThunk,
} from 'redux/domains/insurance/thunk';

const mapStateToProps = (state, ownProps) => ({
    texts: state.texts,
    isLoading: state.app.isLoading,
    data: state.insurance.view,
    hasProjectSelected: hasProjectSelected(state),
    ownProps: ownProps,
    isSaving: state.insurance.isSaving,
    paymentsModalActive: state.insurance.policies.payments?.active,
    mediaModalActive: state.insurance.policies.media?.active,
    damagesModalActive: state.insurance.policies.damages?.active,
    documentsModalActive: state.insurance.policies.documents?.active,
    odometerModalActive: state.insurance.view.odometer?.active,
    billingModalActive: state.insurance.billing?.active,
    projectId: state.insurance.filters.projects.selected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchData: () => insuranceFetchDataThunk(dispatch, ownProps, 'quotes'),
    clearData: () => dispatch(insuranceClearCurrentViewAction()),
    onSearch: data => insuranceOnSearchByTypeThunk(dispatch, data, 'quotes'),
    onClearSearch: () => dispatch(insuranceClearSearchByTypeAction('quotes')),
    onOpenPaymentsModal: data => dispatch(openInsurancePoliciesPaymentsModalAction(data)),
    onOpenDocumentsModal: data => dispatch(openInsurancePoliciesDocumentsModalAction(data)),
    onOpenMediaModal: data => dispatch(openInsurancePoliciesMediaModalAction(data)),
    onOpenDamagesModal: data => dispatch(openInsurancePoliciesDamagesModalAction(data)),
    onEditAsset: data => insuranceOnEditAssetThunk(dispatch, data, ownProps),
});

export default connect(mapStateToProps, mapDispatchToProps)(Quote);
