import EntityDirectories from 'pages/Networks/EntityDirectories';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { networksOpenShareModalAction } from 'redux/domains/networks/actions';
import {
    networksCreateDirectoryByTypeThunk,
    networksDeleteDirectoryThunk,
    networksGetDirectoriesThunk,
} from 'redux/domains/networks/thunk';
import { openPromptAction } from 'redux/domains/prompt/actions';

const mapStateToProps = (state, ownProps) => ({
    texts: state.texts,
    isLoading: state.app.isLoading,
    entityId: ownProps.match.params.id,
    teamMembers: state.teamMembers,
    directories: state.networks.entities.directories,
    shareModal: state.networks.share?.active,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    openShareModal: data => dispatch(networksOpenShareModalAction(data)),
    openPrompt: data => dispatch(openPromptAction(data)),
    getDirectories: () =>
        networksGetDirectoriesThunk(
            dispatch,
            ownProps,
            false,
            false,
            'directory-entity',
            'entities',
        ),
    addEntityDirectory: () =>
        networksCreateDirectoryByTypeThunk(dispatch, 'directory-entity', 'entities', ownProps),
    onDeleteEntityDirectory: data => networksDeleteDirectoryThunk(dispatch, data, ownProps),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EntityDirectories));
