import { appFileUpload } from 'api/app';
import {
    associateNetworkEntities,
    associateNetworkEntityServicesItems,
    createDirectory,
    createElement,
    createNetwork,
    createUpdateCategories,
    createUpdateSegments,
    deleteDirectory,
    deleteElement,
    deleteNetwork,
    editDirectory,
    editElement,
    editNetwork,
    getCategories,
    getDirectories,
    getMembers,
    getNetworks,
    memberDelete,
    memberInvite,
    memberPause,
    memberResume,
    networksGetProviders,
    updateNetworkDirectories,
    updateNetworkSegments,
} from 'api/networks';
import { isLoadingAction } from 'redux/domains/app/actions';
import { closePromptAction, openPromptAction } from 'redux/domains/prompt/actions';
import { apiHandleThunkErrorResponse } from 'services/api';
import { sendErrorToast, sendErrorToastByStatusCode, sendSuccessToast } from 'services/toast';
import {
    DEFAULT_ALERT_ERROR_MESSAGE,
    NETWORK_MAX_NUM_OF_ITEMS,
    NETWORK_SECTIONS,
} from 'utils/constants';
import {
    isLoadingNetworksAction,
    isSavingNetworksAction,
    loadMoreServicesItemsAction,
    networksAddMemberErrorAction,
    networksCloseElementModalAction,
    networksEditDirectoryAction,
    networksSyncCategoriesAction,
    networksSyncDirectoriesByTypeAction,
    networksSyncDirectoryMembersAction,
    networksSyncElementImageAction,
    networksSyncNetworkEntityServicesItemsSelectedAction,
    networksSyncNetworkImageAction,
    networksSyncNetworkSelectDirectoryAction,
    networksSyncNetworksAction,
    networksSyncProvidersAction,
} from './actions';
import {
    networksGetDirectoryObs,
    networksGetNetworkEntityDataObs,
    networksGetNetworkObs,
} from './observer';

export const networksGetDirectoriesThunk = (dispatch, ownProps, redirect, match, type, domain) => {
    return dispatch((dispatch, getState) => {
        dispatch(isLoadingAction(true));

        const payload = {
            directory_type: type ? type : 'directory-entity',
            order_by: '-created',
        };

        return getDirectories(payload)
            .then(async response => {
                await dispatch(
                    networksSyncDirectoriesByTypeAction(
                        response && response !== '' ? response : { elements: [] },
                        domain,
                    ),
                );
            })
            .then(async () => await dispatch(closePromptAction()))
            .then(() => {
                if (redirect) {
                    ownProps.history.push(`/networks-management/${domain}/${redirect}?from=create`);
                }
            })
            .then(() => {
                if (match) {
                    const directory = getState().networks[domain].directories.filter(
                        dir => dir.directory.id === ownProps.match.params.id,
                    );
                    if (!directory || !directory[0]) {
                        ownProps.history.push(`/networks-management/${domain}`);
                    }
                }
            })
            .then(async () => {
                await dispatch(isLoadingAction(false));
            })
            .catch(error => {
                // const response = error && error.response;
                // console.log(response);

                dispatch(isLoadingAction(false));

                const prompt = {
                    id: 'genericError',
                    title: 'Oops',
                    content: 'Something went wrong...',
                    retry: true,
                    onConfirm: () => {
                        networksGetNetworksThunk(dispatch);
                    },
                };

                return apiHandleThunkErrorResponse(
                    error,
                    false,
                    dispatch(openPromptAction(prompt)),
                );
            });
    });
};

export const networksCreateDirectoryByTypeThunk = (dispatch, type, domain, ownProps) => {
    return dispatch((dispatch, getState) => {
        dispatch(isLoadingAction(true));

        const payload = {
            name: 'Directory name',
            directory_type: type ? type : 'directory-entity',
        };

        return createDirectory(payload)
            .then(response => {
                if (response && response.id) {
                    networksGetDirectoriesThunk(
                        dispatch,
                        ownProps,
                        response.id,
                        false,
                        type,
                        domain,
                    );
                }
            })
            .catch(error => {
                // const response = error && error.response;
                // console.log(response);

                dispatch(isLoadingAction(false));

                return apiHandleThunkErrorResponse(error, true, null);
            });
    });
};

export const networksEditDirectoryThunk = (dispatch, data, ownProps, types) => {
    return dispatch((dispatch, getState) => {
        dispatch(isSavingNetworksAction(true));

        const payload = {
            id: ownProps.match.params.id,
            payload: {
                name: data.name,
            },
        };

        return editDirectory(payload)
            .then(async response => {
                await dispatch(networksEditDirectoryAction(payload, types));
            })
            .then(async () => {
                await dispatch(isSavingNetworksAction(false));
            })
            .catch(error => {
                // const response = error && error.response;
                // console.log(response);

                dispatch(isSavingNetworksAction(false));

                return apiHandleThunkErrorResponse(error, true, null);
            });
    });
};

export const networksDeleteDirectoryThunk = (dispatch, payload, ownProps) => {
    return dispatch((dispatch, getState) => {
        dispatch(isLoadingAction(true));

        return deleteDirectory(payload)
            .then(async response => {
                if (response) {
                    switch (payload.type) {
                        case 'entities':
                            await networksGetDirectoriesThunk(
                                dispatch,
                                ownProps,
                                null,
                                null,
                                'directory-entity',
                                payload.type,
                            );
                            break;
                        case 'services':
                            await networksGetDirectoriesThunk(
                                dispatch,
                                ownProps,
                                null,
                                null,
                                'directory-offered-service',
                                payload.type,
                            );
                            break;
                        case 'items':
                            await networksGetDirectoriesThunk(
                                dispatch,
                                ownProps,
                                null,
                                null,
                                'directory-offered-item',
                                payload.type,
                            );
                            break;

                        default:
                            break;
                    }
                }
            })
            .then(async () => await dispatch(closePromptAction()))
            .catch(error => {
                // const response = error && error.response;
                // console.log(response);

                dispatch(isLoadingAction(false));
                dispatch(closePromptAction());

                return apiHandleThunkErrorResponse(error, true, null);
            });
    });
};

export const networksDeleteElementThunk = (dispatch, data, ownProps, types) => {
    return dispatch((dispatch, getState) => {
        dispatch(isLoadingAction(true));

        const payload = {
            id: ownProps.match.params.id,
            elementId: data.id,
        };

        return deleteElement(payload, types)
            .then(async response => {
                if (response) {
                    await networksGetDirectoryObs(dispatch, ownProps, null, types);
                }
            })
            .then(async () => await dispatch(closePromptAction()))
            .then(async () => {
                await dispatch(isLoadingAction(false));
            })
            .catch(error => {
                // const response = error && error.response;
                // console.log(response);

                dispatch(isLoadingAction(false));
                dispatch(closePromptAction());

                return apiHandleThunkErrorResponse(error, true, null);
            });
    });
};

export const networksOnSaveElementThunk = (dispatch, data, ownProps, types) => {
    return dispatch((dispatch, getState) => {
        dispatch(isSavingNetworksAction(true));

        const { segments, categories, ...otherFields } = data.fields;

        // EDIT
        if (data.id) {
            const payload = {
                id: ownProps.match.params.id,
                elementId: data.id,
                data: {
                    ...otherFields,
                },
            };

            return editElement(payload, types)
                .then(async response => {
                    if (response) {
                        const payload = {
                            id: ownProps.match.params.id,
                            elementId: data.id,
                            action: 'update',
                            data: {
                                segments: segments,
                            },
                        };
                        await networksCreateUpdateSegments(dispatch, payload, types);
                        return response;
                    }
                })
                .then(async response => {
                    if (response) {
                        const payload = {
                            id: ownProps.match.params.id,
                            elementId: data.id,
                            action: 'update',
                            data: {
                                categories: categories,
                            },
                        };
                        await networksCreateUpdateCategories(dispatch, payload, types);
                        return response;
                    }
                })
                .then(async response => {
                    if (response) {
                        await networksGetDirectoryObs(
                            dispatch,
                            ownProps,
                            data.id ? data.id : null,
                            types,
                        );
                        return response;
                    }
                })
                .then(async response => {
                    if (response) {
                        await dispatch(networksCloseElementModalAction(types));
                    }
                })
                .then(async () => await dispatch(closePromptAction()))
                .then(async () => {
                    await dispatch(isSavingNetworksAction(false));
                })
                .catch(error => {
                    const response = error && error.response;
                    // console.log(response);

                    sendErrorToastByStatusCode(response);
                    dispatch(isSavingNetworksAction(false));

                    return apiHandleThunkErrorResponse(error, false, null);
                });
        } else {
            const payload = {
                id: ownProps.match.params.id,
                data: {
                    ...otherFields,
                },
            };

            return createElement(payload, types)
                .then(async response => {
                    if (response && response.id) {
                        const payload = {
                            id: ownProps.match.params.id,
                            elementId: response.id,
                            action: 'create',
                            data: {
                                segments: segments,
                            },
                        };
                        await networksCreateUpdateSegments(dispatch, payload, types);
                        return response;
                    }
                })
                .then(async response => {
                    if (response && response.id) {
                        const payload = {
                            id: ownProps.match.params.id,
                            elementId: response.id,
                            action: 'create',
                            data: {
                                categories: categories,
                            },
                        };
                        await networksCreateUpdateCategories(dispatch, payload, types);
                        return response;
                    }
                })
                .then(async response => {
                    if (response) {
                        await networksGetDirectoryObs(
                            dispatch,
                            ownProps,
                            response.id ? response.id : null,
                            types,
                        );
                        return response;
                    }
                })
                .then(async response => {
                    if (response) {
                        await dispatch(networksCloseElementModalAction(types));
                    }
                })
                .then(async () => await dispatch(closePromptAction()))
                .then(async () => {
                    await dispatch(isSavingNetworksAction(false));
                })
                .catch(error => {
                    const response = error && error.response;
                    // console.log(response);

                    sendErrorToastByStatusCode(response);
                    dispatch(isSavingNetworksAction(false));

                    return apiHandleThunkErrorResponse(error, false, null);
                });
        }
    });
};

const networksCreateUpdateSegments = async (dispatch, payload, types) => {
    return await createUpdateSegments(payload, types)
        .then(response => {
            // console.log('TCL: networksCreateUpdateSegments -> response', response);
        })
        .catch(error => {
            const response = error && error.response;
            // console.log(response);

            sendErrorToastByStatusCode(response);
            dispatch(isSavingNetworksAction(false));

            return apiHandleThunkErrorResponse(error, false, null);
        });
};

const networksCreateUpdateCategories = async (dispatch, payload, types) => {
    return await createUpdateCategories(payload, types)
        .then(response => {
            // console.log('TCL: networksCreateUpdateCategories -> response', response);
        })
        .catch(error => {
            const response = error && error.response;
            // console.log(response);

            sendErrorToastByStatusCode(response);
            dispatch(isSavingNetworksAction(false));

            return apiHandleThunkErrorResponse(error, false, null);
        });
};

export const networksOnUploadElementImageThunk = (dispatch, data, types) => {
    return dispatch((dispatch, getState) => {
        dispatch(isSavingNetworksAction(true));
        return appFileUpload(data)
            .then(async response => {
                if (response && response.url) {
                    const payload = {
                        url: response.url,
                        type: data.namespace,
                    };
                    await dispatch(networksSyncElementImageAction(payload, types));
                } else {
                    sendErrorToast();
                }
            })
            .then(async () => await dispatch(isSavingNetworksAction(false)))
            .catch(error => {
                dispatch(isSavingNetworksAction(false));

                return apiHandleThunkErrorResponse(error, false, null);
            });
    });
};

export const networksGetCategoriesBySegmentsThunk = (dispatch, data, ownProps) => {
    if (!data.segments || data.segments.length === 0) {
        return dispatch(networksSyncCategoriesAction({ elements: [] }));
    }

    return dispatch((dispatch, getState) => {
        dispatch(isLoadingNetworksAction(true));
        const payload = {
            segment_id: `in/${JSON.stringify(data.segments)}/j`,
        };
        return getCategories(payload)
            .then(async response => {
                await dispatch(
                    networksSyncCategoriesAction(
                        response && response !== '' ? response : { elements: [] },
                    ),
                );
            })
            .then(async () => await dispatch(isLoadingNetworksAction(false)))
            .catch(error => {
                dispatch(isLoadingNetworksAction(false));

                return apiHandleThunkErrorResponse(error, false, null);
            });
    });
};

export const networksGetMembersThunk = (dispatch, data, loading = true) => {
    return dispatch((dispatch, getState) => {
        if (loading) {
            dispatch(isLoadingNetworksAction(true));
        }

        const payload = {
            id: data.id,
            type: data.type,
        };

        return getMembers(payload)
            .then(async response => {
                await dispatch(
                    networksSyncDirectoryMembersAction(
                        response && response !== '' ? response : { elements: [] },
                    ),
                );
            })
            .then(async () => await dispatch(isLoadingNetworksAction(false)))
            .catch(error => {
                dispatch(isLoadingNetworksAction(false));

                return apiHandleThunkErrorResponse(error, false, null);
            });
    });
};

export const networksAddMemberThunk = (dispatch, payload, ownProps) => {
    return dispatch((dispatch, getState) => {
        dispatch(isSavingNetworksAction(true));

        return memberInvite(payload)
            .then(async response => {
                if (response) {
                    await networksGetMembersThunk(dispatch, payload, false);
                    sendSuccessToast(`Member successfully added.`);
                    return response;
                } else {
                    await dispatch(
                        networksAddMemberErrorAction('Please enter a valid member email.'),
                    );
                }
            })
            .then(async () => await dispatch(isSavingNetworksAction(false)))
            .catch(error => {
                dispatch(isSavingNetworksAction(false));
                const response = error && error.response;
                const errorCode = response.data.code;

                let errorMsg = DEFAULT_ALERT_ERROR_MESSAGE;
                switch (errorCode) {
                    case 1016:
                    case 4404:
                    case 1202:
                        errorMsg = 'Please enter a valid member email.';
                        break;
                    case 1211:
                        errorMsg = `Member already in the team.`;
                        break;
                    default:
                        break;
                }

                dispatch(networksAddMemberErrorAction(errorMsg));

                return apiHandleThunkErrorResponse(error, false, null);
            });
    });
};

export const networksPauseMemberThunk = (dispatch, payload) => {
    return dispatch((dispatch, getState) => {
        dispatch(isLoadingAction(true));

        return memberPause(payload)
            .then(async response => {
                if (response) {
                    await networksGetMembersThunk(dispatch, payload, false);
                    return response;
                } else {
                    sendErrorToast();
                }
            })
            .then(async () => await dispatch(isLoadingAction(false)))
            .catch(error => {
                dispatch(isLoadingAction(false));

                return apiHandleThunkErrorResponse(error, true, null);
            });
    });
};

export const networksResumeMemberThunk = (dispatch, payload) => {
    return dispatch((dispatch, getState) => {
        dispatch(isLoadingAction(true));

        return memberResume(payload)
            .then(async response => {
                if (response) {
                    await networksGetMembersThunk(dispatch, payload, false);
                    return response;
                } else {
                    sendErrorToast();
                }
            })
            .then(async () => await dispatch(isLoadingAction(false)))
            .catch(error => {
                dispatch(isLoadingAction(false));

                return apiHandleThunkErrorResponse(error, true, null);
            });
    });
};

export const networksDeleteMemberThunk = (dispatch, payload, ownProps) => {
    return dispatch((dispatch, getState) => {
        dispatch(isLoadingAction(true));

        return memberDelete(payload)
            .then(async response => {
                if (response) {
                    await networksGetMembersThunk(dispatch, payload, false);
                    return response;
                } else {
                    sendErrorToast();
                }
            })
            .then(async () => await dispatch(closePromptAction()))
            .then(async () => await dispatch(isLoadingAction(false)))
            .catch(error => {
                dispatch(isLoadingAction(false));

                return apiHandleThunkErrorResponse(error, true, null);
            });
    });
};

export const networksGetNetworksThunk = dispatch => {
    return dispatch((dispatch, getState) => {
        dispatch(isLoadingAction(true));

        return getNetworks()
            .then(async response => {
                await dispatch(
                    networksSyncNetworksAction(
                        response && response !== '' ? response : { elements: [] },
                    ),
                );
            })
            .then(async () => await dispatch(closePromptAction()))
            .then(async () => await dispatch(isLoadingAction(false)))
            .catch(error => {
                dispatch(isLoadingAction(false));

                const prompt = {
                    id: 'genericError',
                    title: 'Oops',
                    content: 'Something went wrong...',
                    retry: true,
                    onConfirm: () => {
                        networksGetNetworksThunk(dispatch);
                    },
                };

                return apiHandleThunkErrorResponse(
                    error,
                    false,
                    dispatch(openPromptAction(prompt)),
                );
            });
    });
};

export const networksSaveNetworkDetailsThunk = (dispatch, data, ownProps) => {
    return dispatch((dispatch, getState) => {
        dispatch(isLoadingAction(true));

        if (data.type === 'create') {
            const payload = {
                data: data.fields,
            };

            return createNetwork(payload)
                .then(async response => {
                    if (response && response.id) {
                        await setTimeout(() => {
                            ownProps.history.push(`/networks-management/networks/${response.id}`);
                        }, 1000);
                    } else {
                        sendErrorToast();
                    }
                })
                .then(async () => await dispatch(closePromptAction()))
                .then(async () => await dispatch(isLoadingAction(false)))
                .catch(error => {
                    dispatch(isLoadingAction(false));

                    return apiHandleThunkErrorResponse(error, true, null);
                });
        } else {
            const payload = {
                id: ownProps.match.params.id,
                data: {
                    name: data.fields && data.fields.name ? data.fields.name : null,
                    country_id:
                        data.fields && data.fields.country_id ? data.fields.country_id : null,
                    region_covered:
                        data.fields && data.fields.region_covered
                            ? data.fields.region_covered
                            : null,
                    image: data.fields && data.fields.image ? data.fields.image : null,
                },
            };

            return editNetwork(payload)
                .then(async response => {
                    if (response) {
                        const payload = {
                            id: ownProps.match.params.id,
                            data: {
                                segments: [...data.fields.segments],
                            },
                        };
                        await networksUpdateNetworkSegments(dispatch, payload);
                        return response;
                    }
                })
                .then(async response => {
                    if (response) {
                        const payload = {
                            id: ownProps.match.params.id,
                            data: {
                                directories: [
                                    ...data.fields.directory_entities,
                                    ...data.fields.directory_services,
                                    ...data.fields.directory_items,
                                ],
                            },
                        };
                        await networksUpdateNetworkDirectories(dispatch, payload);
                        return response;
                    }
                })
                .then(async response => {
                    if (response) {
                        await networksGetNetworkObs(dispatch, ownProps);
                        return response;
                    }
                })
                .then(async () => await dispatch(closePromptAction()))
                .then(async () => {
                    await dispatch(isSavingNetworksAction(false));
                })
                .catch(error => {
                    const response = error && error.response;
                    // console.log(response);

                    dispatch(isSavingNetworksAction(false));
                    sendErrorToastByStatusCode(response);

                    return apiHandleThunkErrorResponse(error, false, null);
                });
        }
    });
};

const networksUpdateNetworkSegments = async (dispatch, payload) => {
    return await updateNetworkSegments(payload)
        .then(response => {
            // console.log('TCL: networksUpdateNetworkSegments -> response', response);
        })
        .catch(error => {
            const response = error && error.response;
            // console.log(response);

            dispatch(isSavingNetworksAction(false));
            sendErrorToastByStatusCode(response);

            return apiHandleThunkErrorResponse(error, false, null);
        });
};

const networksUpdateNetworkDirectories = async (dispatch, payload) => {
    return await updateNetworkDirectories(payload)
        .then(response => {
            // console.log('TCL: networksUpdateNetworkDirectories -> response', response);
        })
        .catch(error => {
            const response = error && error.response;
            // console.log(response);

            dispatch(isSavingNetworksAction(false));
            sendErrorToastByStatusCode(response);

            return apiHandleThunkErrorResponse(error, false, null);
        });
};

export const networksDeleteNetworkThunk = (dispatch, data) => {
    return dispatch((dispatch, getState) => {
        dispatch(isLoadingAction(true));

        const payload = {
            id: data.id,
        };

        return deleteNetwork(payload)
            .then(async response => {
                if (response) {
                    await networksGetNetworksThunk(dispatch);
                }
            })
            .then(async () => await dispatch(closePromptAction()))
            .then(async () => {
                await dispatch(isLoadingAction(false));
            })
            .catch(error => {
                // const response = error && error.response;
                // console.log(response);

                dispatch(isLoadingAction(false));
                dispatch(closePromptAction());

                return apiHandleThunkErrorResponse(error, true, null);
            });
    });
};

export const networksOnUploadNetworkImageThunk = (dispatch, data) => {
    return dispatch((dispatch, getState) => {
        dispatch(isSavingNetworksAction(true));
        return appFileUpload(data)
            .then(async response => {
                if (response && response.url) {
                    const payload = {
                        url: response.url,
                        type: 'image',
                    };
                    await dispatch(networksSyncNetworkImageAction(payload));
                } else {
                    sendErrorToast();
                }
            })
            .then(async () => await dispatch(isSavingNetworksAction(false)))
            .catch(error => {
                dispatch(isSavingNetworksAction(false));

                return apiHandleThunkErrorResponse(error, false, null);
            });
    });
};

export const networksAssociateNetworkEntitiesThunk = (dispatch, data, ownProps) => {
    return dispatch((dispatch, getState) => {
        let payload = {
            id: ownProps.match.params.id,
        };
        let selectedType = null;

        switch (data.action) {
            case 'single':
                payload = {
                    ...payload,
                    data: {
                        directory_entities: [
                            {
                                directoryentity_id: data.id,
                                state: data.state,
                            },
                        ],
                    },
                };
                break;
            case 'bulk':
                selectedType = data.state === 'active' ? 'all' : 'none';
                const bulk_entities = getState().networks.networks.entities.list;
                const bulk_directory_entities = bulk_entities.map(e => {
                    return {
                        directoryentity_id: e.id,
                        state: data.state,
                    };
                });
                payload = {
                    ...payload,
                    data: {
                        directory_entities: [...bulk_directory_entities],
                    },
                };
                break;
            case 'directory':
                selectedType = data.id;
                const entities = getState().networks.networks.entities.list;
                const directory_entities = entities.map(e => {
                    if (e.directory_id === data.id) {
                        return {
                            directoryentity_id: e.id,
                            state: 'active',
                        };
                    }
                    return {
                        directoryentity_id: e.id,
                        state: 'inactive',
                    };
                });
                payload = {
                    ...payload,
                    data: {
                        directory_entities: [...directory_entities],
                    },
                };
                break;

            default:
                return;
        }

        if (payload.data.directory_entities.length === 0) {
            return sendErrorToast('Directory has no entities');
        }

        return associateNetworkEntities(payload)
            .then(async response => {
                if (response) {
                    await networksGetNetworkObs(dispatch, ownProps);
                    return response;
                }
            })
            .then(async response => {
                await dispatch(networksSyncNetworkSelectDirectoryAction(selectedType));
            })
            .catch(error => {
                const response = error && error.response;
                // console.log(response);

                dispatch(isSavingNetworksAction(false));
                sendErrorToastByStatusCode(response);

                return apiHandleThunkErrorResponse(error, false, null);
            });
    });
};

export const networksAssociateNetworkEntityServicesItemsThunk = (dispatch, data, ownProps) => {
    return dispatch((dispatch, getState) => {
        /**
         * @typedef {Object} Actions
         * @property {boolean} edit - When the rows are edit and the onSave button is clicked
         * @property {boolean} bulk - When the select or unselect all button is clicked
         * @property {boolean} single - When just one row is selected or unselected
         */
        const Actions = {
            edit: data.action === 'edit',
            bulk: data.action === 'bulk',
            single: data.action === 'single',
        };

        const networkId = ownProps.match.params.id;
        const entityId = getState().networks.networks.network.entity.id;

        let payload = {
            networkId: networkId,
            entityId: entityId,
            type: data.type,
            data: {},
        };

        let selectedType = {
            type: data.type,
            option: Actions.single ? null : data.state === 'active' ? 'all' : 'none',
        };

        const normalize = (data, section) => {
            /**
             * @typedef {Object} expectedAttributes
             * @description https://bitbucket.org/muzzley/habit-platform-wiki/wiki/NetworksSelfcareEndpoints.md#markdown-header-associate-network-entity-offered-services
             * @description https://bitbucket.org/muzzley/habit-platform-wiki/wiki/NetworksSelfcareEndpoints.md#markdown-header-associate-network-entity-offered-items
             */
            let expectedAttributes = {
                list_price_amount: data.list_price_amount,
                agreed_amount: data.agreed_amount,
                agreed_amount_type: data.agreed_amount_type,
                copayment_percentage_amount: data.copayment_percentage_amount,
                copayment_minimum_amount: data.copayment_minimum_amount,
                copayment_maximum_amount: data.copayment_maximum_amount,
                coinsurance_percentage_amount: data.coinsurance_percentage_amount,
                coinsurance_minimum_amount: data.coinsurance_minimum_amount,
                coinsurance_maximum_amount: data.coinsurance_maximum_amount,
                state: data.state || 'active',
            };

            if (section === NETWORK_SECTIONS.SERVICES) {
                expectedAttributes = {
                    ...expectedAttributes,
                    directoryofferedservice_id: data.directoryofferedservice_id || data.id,
                    agreed_amount_type:
                        data.agreed_amount === 0 || data.agreed_amount > 0
                            ? data.agreed_amount_type
                            : undefined,
                };
            }

            if (section === NETWORK_SECTIONS.ITEMS) {
                expectedAttributes = {
                    ...expectedAttributes,
                    directoryoffereditem_id: data.directoryoffereditem_id || data.id,
                    agreed_amount_type:
                        data.agreed_amount === 0 || data.agreed_amount > 0
                            ? data.agreed_amount_type
                            : undefined,
                };
            }

            const preventNullOrUndefinedValues = Object.keys(expectedAttributes).reduce(
                (acc, key) => {
                    if (expectedAttributes[key] !== null && expectedAttributes[key] !== undefined) {
                        acc[key] = expectedAttributes[key];
                    }
                    return acc;
                },
                {},
            );

            return preventNullOrUndefinedValues;
        };

        /**
         * @param {Object} el - The element stored on the back-end (back-end only - no interaction with the front-end))
         * @param {Object} data - The front-end data from the action payload (front-end only - that is not stored in the back-end, but intended to be)
         * @param {'services' | 'items'} section - The section of the network (services or items)
         * @description This function is responsible for handling the actions of the user on the front-end and the back-end
         * @description It will return the element with the new state if the user is editing, or the same element if the user is not editing
         * @returns {Object} - The element with the new state if the user is editing, or the same element if the user is not editing
         */
        function handleActions(el, data, section) {
            /**
             * @description This variable is responsible for checking if the element is the one that the user wants to change
             * @description It uses a fallback if the directoryoffereditem_id or directoryofferedservice_id is not present
             * @description Since when the user is editing for the first time, the directoryoffereditem_id or directoryofferedservice_id is not present
             */
            const isChosenToBeChanged =
                section === NETWORK_SECTIONS.SERVICES
                    ? el.directoryofferedservice_id === data.id || el.id === data.id
                    : el.directoryoffereditem_id === data.id || el.id === data.id;
            /**
             * @description This variable is responsible for inverting the state of the element
             */

            if (Actions.edit) {
                if (isChosenToBeChanged) {
                    return {
                        ...normalize(data, section),
                    };
                }
            }
            if (Actions.single) {
                if (isChosenToBeChanged) {
                    const reversedDataFromFE = data.state;
                    return {
                        ...normalize(el, section),
                        state: reversedDataFromFE,
                    };
                }
            }
            if (Actions.bulk) {
                let obj = {
                    ...normalize(el, section),
                    state: data.state,
                };
                return obj;
            }
        }

        switch (data.type) {
            case NETWORK_SECTIONS.SERVICES:
                const services = getState().networks.networks.network.services.list;
                const offered_services = services
                    .map(el => {
                        return handleActions(el, data, 'services');
                    })
                    ?.filter(el => {
                        return Boolean(el);
                    });
                payload = {
                    ...payload,
                    data: {
                        offered_services: offered_services,
                    },
                };
                break;
            case NETWORK_SECTIONS.ITEMS:
                const items = getState().networks.networks.network.items.list;
                const offered_items = items
                    .map(el => {
                        return handleActions(el, data, 'items');
                    })
                    ?.filter(el => {
                        return Boolean(el);
                    });
                payload = {
                    ...payload,
                    data: {
                        offered_items: offered_items,
                    },
                };
                break;
            default:
                return;
        }

        return associateNetworkEntityServicesItems(payload)
            .then(async response => {
                if (response) {
                    await networksGetNetworkEntityDataObs(dispatch, { id: entityId }, ownProps);
                    return response;
                }
            })
            .then(async response => {
                await dispatch(networksSyncNetworkEntityServicesItemsSelectedAction(selectedType));
            })
            .catch(error => {
                const response = error && error.response;
                dispatch(isSavingNetworksAction(false));
                sendErrorToastByStatusCode(response);
                return apiHandleThunkErrorResponse(error, false, null);
            });
    });
};

export const networksLoadMoreServicesItemsThunk = (dispatch, data, ownProps) => {
    return dispatch((dispatch, getState) => {
        dispatch(isLoadingNetworksAction(true));
        const target = getState().networks.networks.size + NETWORK_MAX_NUM_OF_ITEMS;
        dispatch(loadMoreServicesItemsAction(target));
        dispatch(isLoadingNetworksAction(false));
    });
};

export const fetchProvidersThunk = dispatch => {
    dispatch(isLoadingNetworksAction(true));

    return dispatch(async (dispatch, getState) => {
        try {
            await networksGetProviders({})
                .then(async response => {
                    return await dispatch(networksSyncProvidersAction(response?.elements));
                })
                .catch(error => {
                    return apiHandleThunkErrorResponse(error, false, null);
                });
        } catch (error) {
            return apiHandleThunkErrorResponse(error, false, null);
        } finally {
            dispatch(isLoadingNetworksAction(false));
        }
    });
};
