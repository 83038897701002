import ItemDirectory from 'pages/Networks/ItemDirectory';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    clearNetworksAction,
    networksOpenElementModalAction,
} from 'redux/domains/networks/actions';
import { networksGetDirectoryObs } from 'redux/domains/networks/observer';
import { getDirectorySelector, NETWORKS_TYPE_ITEM } from 'redux/domains/networks/selector';
import {
    networksDeleteElementThunk,
    networksEditDirectoryThunk,
} from 'redux/domains/networks/thunk';
import { openPromptAction } from 'redux/domains/prompt/actions';

const mapStateToProps = (state, ownProps) => ({
    texts: state.texts,
    isLoading: state.app.isLoading,
    isSaving: state.networks.isSaving,
    items: state.networks.items.list,
    directory: getDirectorySelector(state, ownProps, NETWORKS_TYPE_ITEM),
    activeModal: state.networks.items.item?.active,
    tableHeader: state.networks.items.header,
    tableFilters: state.networks.items.filters,
    tablePagination: state.networks.items.pagination,
    ownProps: ownProps,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    openPrompt: data => dispatch(openPromptAction(data)),
    getDirectory: () => networksGetDirectoryObs(dispatch, ownProps, null, NETWORKS_TYPE_ITEM),
    onEditDirectory: data =>
        networksEditDirectoryThunk(dispatch, data, ownProps, NETWORKS_TYPE_ITEM),
    onOpenItemModal: data => dispatch(networksOpenElementModalAction(data, NETWORKS_TYPE_ITEM)),
    onDeleteItem: data => networksDeleteElementThunk(dispatch, data, ownProps, NETWORKS_TYPE_ITEM),
    onClearNetwork: () => dispatch(clearNetworksAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ItemDirectory));
