import Network from 'pages/Networks/Network';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    clearNetworksAction,
    networksOpenElementModalAction,
} from 'redux/domains/networks/actions';
import { networksGetNetworkObs } from 'redux/domains/networks/observer';
import { getNetworkSelector, NETWORKS_TYPE_NETWORK } from 'redux/domains/networks/selector';
import {
    networksAssociateNetworkEntitiesThunk,
    networksDeleteNetworkThunk,
    networksEditDirectoryThunk,
} from 'redux/domains/networks/thunk';
import { openPromptAction } from 'redux/domains/prompt/actions';

const mapStateToProps = (state, ownProps) => ({
    texts: state.texts,
    isLoading: state.app.isLoading,
    isSaving: state.networks.isSaving,
    entities: state.networks.networks.entities.list,
    selectState: state.networks.networks.entities.selectState,
    network: getNetworkSelector(state, ownProps, NETWORKS_TYPE_NETWORK),
    activeModal: state.networks.networks.network?.active,
    tableHeader: state.networks.networks.entities.header,
    tableFilters: state.networks.networks.entities.filters,
    tablePagination: state.networks.networks.entities.pagination,
    ownProps: ownProps,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    openPrompt: data => dispatch(openPromptAction(data)),
    fetchData: search => networksGetNetworkObs(dispatch, ownProps, search),
    onEditEntityDirectory: data =>
        networksEditDirectoryThunk(dispatch, data, ownProps, NETWORKS_TYPE_NETWORK),
    onOpenNetworkEntityModal: data =>
        dispatch(networksOpenElementModalAction(data, NETWORKS_TYPE_NETWORK)),
    onDeleteNetwork: data => networksDeleteNetworkThunk(dispatch, data),
    onClearNetwork: () => dispatch(clearNetworksAction()),
    onToggleEntity: data => networksAssociateNetworkEntitiesThunk(dispatch, data, ownProps),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Network));
