import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import Filters from 'redux/containers/Analytics/UsersVisitedPlaces/Filters';
import Avatar from 'shared/Avatar';
import DatePicker from 'shared/DatePicker';
import Loading from 'shared/Loading';
import { DEFAULT_ANALYTICS_DATE_PICKER_OFFSET } from 'utils/constants';
import { isProdEnv, isQaEnv } from 'utils/functions';
import Styles from './Timeline.module.scss';
import TimelineList from './TimelineList';

export class Timeline extends React.PureComponent {
    state = {
        filtersOpen: false,
        filtersPopup: {
            active: false,
            element: {},
        },
        date: null,
    };

    static propTypes = {
        isLoading: PropTypes.bool,
        data: PropTypes.object,
        user: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        onSelect: PropTypes.func,
        selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        active: PropTypes.bool,
        hasUser: PropTypes.bool,
        onChangeDate: PropTypes.func,
        searchDate: PropTypes.string,
        withFilters: PropTypes.bool,
    };

    static defaultProps = {
        hasUser: true,
        withFilters: true,
    };

    componentDidMount() {
        ReactTooltip.hide();
    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    componentWillUnmount() {
        this.setState({
            filtersOpen: false,
            filtersPopup: {
                active: false,
                element: {},
            },
        });
    }

    handleFiltersPopup = e => {
        ReactTooltip.hide();
        this.setState(state => ({
            filtersOpen: !state.filtersOpen,
        }));
    };

    handleClosePopups = () => {
        this.setState({
            filtersOpen: false,
        });
    };

    handleSelectFilter = e => {
        const id = e.currentTarget.getAttribute('data-id');
        console.log('TCL: Timeline -> handleSelectFilter -> id', id);
    };

    handleOnChangeDate = date => {
        this.setState(
            {
                date: date,
            },
            () => {
                const payload = {
                    id: this.props.user.id,
                    date: date,
                };

                this.props.onChangeDate(payload);
            },
        );
    };

    render() {
        const {
            isLoading,
            data,
            user,
            onSelect,
            selected,
            active,
            hasUser,
            searchDate,
            withFilters,
        } = this.props;
        const { filtersOpen } = this.state;

        const avgTime =
            !isLoading &&
            data &&
            data.user_view &&
            data.user_view.data &&
            Object.keys(data.user_view.data).length > 0 &&
            data.user_view.data.avg_total_duration
                ? data.user_view.data.avg_total_duration
                : '--';
        const visitedPlaces =
            !isLoading &&
            data &&
            data.user_view &&
            data.user_view.data &&
            Object.keys(data.user_view.data).length > 0 &&
            data.user_view.data.num_visitedplaces
                ? data.user_view.data.num_visitedplaces
                : '--';
        const timelineData =
            !isLoading &&
            data &&
            data.timeline_view &&
            data.timeline_view.data &&
            data.timeline_view.data.length > 0
                ? data.timeline_view.data
                : null;
        const highlightedDays =
            data &&
            data.calendar_view &&
            data.calendar_view.data &&
            data.calendar_view.data.length > 0
                ? data.calendar_view.data
                : null;

        return (
            <div
                data-test="TimelineContainer"
                className={classnames(
                    Styles.container,
                    active ? Styles?.active : null,
                    hasUser ? Styles.shadow : null,
                )}
            >
                {hasUser && (
                    <div data-test="TimelineUser" className={Styles.user}>
                        <Avatar random={false} color="#50E3C2" text={user.name} />
                        <p>{user.name}</p>
                    </div>
                )}

                <div className={Styles.date}>
                    <DatePicker
                        data-test="DatePicker"
                        offset={DEFAULT_ANALYTICS_DATE_PICKER_OFFSET}
                        date={searchDate}
                        highlightedDays={highlightedDays}
                        onChange={this.handleOnChangeDate}
                    />
                </div>

                <div className={Styles.stats}>
                    <div className={Styles.average}>
                        <p data-test="TimelineAvgTime" className={Styles.number}>
                            {avgTime}
                        </p>
                        <p className={Styles.text}>Avg time visiting</p>
                    </div>
                    <div className={Styles.visited}>
                        <p data-test="TimelineVisitedPlaces" className={Styles.number}>
                            {visitedPlaces}
                        </p>
                        <p className={Styles.text}>Visited places</p>
                    </div>
                </div>

                <div className={Styles.timeline}>
                    {isLoading && <Loading size="xs" />}

                    {!isLoading && timelineData && (
                        <TimelineList
                            data-test="TimelineList"
                            data={timelineData}
                            onSelect={onSelect}
                            selected={selected}
                        />
                    )}

                    {!isLoading && !timelineData && (
                        <p className={Styles.noData}>No data available</p>
                    )}
                </div>

                {withFilters && (
                    <div
                        className={classnames(
                            Styles.filters,
                            hasUser ? Styles.small : null,
                            filtersOpen ? Styles.open : null,
                            isQaEnv() || isProdEnv() ? Styles.hidden : null,
                        )}
                    >
                        <Filters isOpen={filtersOpen} openClose={this.handleFiltersPopup} />
                    </div>
                )}
            </div>
        );
    }
}

export default Timeline;
