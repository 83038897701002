import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './Switch.module.scss';
import ValidationStatus from 'shared/ValidationStatus';

export class Switch extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string,
        checked: PropTypes.bool,
        onChange: PropTypes.func,
        index: PropTypes.number,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        disabled: PropTypes.bool,
        loading: PropTypes.bool,
        withValidation: PropTypes.bool,
        isValid: PropTypes.bool,
        unsaved: PropTypes.bool,
        size: PropTypes.oneOf(['sm', 'md']),
        theme: PropTypes.oneOf([
            'primary',
            'secondary',
            'ternary',
            'blue',
            'grey',
            'sport',
            'pet',
            'car',
            'default',
        ]),
    };

    static defaultProps = {
        size: 'md',
        theme: 'primary',
    };

    render() {
        const {
            name,
            checked,
            onChange,
            index,
            id,
            disabled,
            withValidation,
            isValid,
            unsaved,
            size,
            theme,
            loading,
            ...otherProps
        } = this.props;

        return (
            <div
                data-test="Switch"
                className={classnames(
                    Styles.switch,
                    Styles[theme],
                    Styles[size],
                    disabled ? Styles.disabled : null,
                )}
            >
                <label
                    data-test="SwitchLabelTag"
                    className={classnames(Styles.switch, Styles[size])}
                >
                    <input
                        data-test="SwitchInput"
                        data-index={index}
                        data-id={id}
                        name={name}
                        type="checkbox"
                        checked={checked}
                        value={checked || ''}
                        onChange={onChange}
                        disabled={disabled}
                        aria-disabled={disabled}
                        {...otherProps}
                    />
                    <span
                        data-test="SwitchSlider"
                        className={classnames(
                            Styles.slider,
                            Styles.round,
                            Styles[size],
                            disabled ? Styles.disabled : null,
                            loading ? Styles.loading : null,
                        )}
                    ></span>

                    {withValidation && !unsaved && !disabled && isValid && (
                        <ValidationStatus status="success" />
                    )}

                    {withValidation && !unsaved && !disabled && !isValid && (
                        <ValidationStatus status="error" />
                    )}
                </label>
            </div>
        );
    }
}

export default Switch;
