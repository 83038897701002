import { Observable } from 'rxjs';
import { apiGetAuthHeader, apiHandleRequest, apiHandleRequestRecursively } from 'services/api';
import { getUserInfo } from 'services/localStorage';
import { sendErrorToast } from 'services/toast';
import { ITEMS_PER_PAGE } from 'utils/constants';
import { checkAuthWithRedirect } from 'utils/functions';
import routes from './routes';

export const distributorsGetMyDistributorsRequests = async params => {
    const user = getUserInfo();
    await checkAuthWithRedirect();

    const url = routes.distributorsGetMyDistributorsRequests.replace(':id', user.insurer_id);

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const distributorsGetMyDistributorsRequestsHistory = async params => {
    const user = getUserInfo();
    await checkAuthWithRedirect();

    const url = routes.distributorsGetMyDistributorsRequestsHistory
        .replace(':id', user.insurer_id)
        .replace(':request-id', params.request_id);

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const distributorsSetMyDistributorRequest = async params => {
    const user = getUserInfo();
    await checkAuthWithRedirect();

    const url = routes.distributorsSetMyDistributorRequest
        .replace(':id', user.insurer_id)
        .replace(':request-id', params.id)
        .replace(':state', params.state);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data,
    };

    return await apiHandleRequest(request);
};

export const distributorsGetInsurerApplications = async getState => {
    const user = getUserInfo();
    await checkAuthWithRedirect();

    const state = await getState();

    const url = routes.distributorsGetInsurerApplications.replace(':id', user?.distributor_id);

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            page_size: state?.admin?.insuranceProducts?.chunk
                ? state.admin.insuranceProducts.chunk
                : ITEMS_PER_PAGE,
            page_start_index: state?.admin?.insuranceProducts?.pagination?.page_start_index
                ? state.admin.insuranceProducts.pagination.page_start_index
                : 0,
            order_by: '-created',
        },
    };

    return await apiHandleRequest(request);
};

export const distributorsGetInsuranceProductsRequests = async params => {
    const user = getUserInfo();
    await checkAuthWithRedirect();

    const url = routes.distributorsGetInsuranceProductsRequests.replace(
        ':id',
        user?.distributor_id,
    );

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const distributorsSetInsuranceProductsRequest = async params => {
    const user = getUserInfo();
    await checkAuthWithRedirect();

    const url = routes.distributorsSetInsuranceProductsRequest.replace(':id', user?.distributor_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data,
    };

    return await apiHandleRequest(request);
};

export const distributorsChangeInsuranceProductRequest = async params => {
    const user = getUserInfo();
    await checkAuthWithRedirect();

    const url = routes.distributorsChangeInsuranceProductRequest
        .replace(':id', user?.distributor_id)
        .replace(':request-id', params.id)
        .replace(':state', params.state);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data,
    };

    return await apiHandleRequest(request);
};

export const distributorsGetMetaData = async params => {
    const user = getUserInfo();
    await checkAuthWithRedirect();

    const url = routes.distributorsGetMeta.replace(':id', user?.distributor_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params,
    };

    return await apiHandleRequest(request);
};

export const distributorsGetMetaDataSWR = async (key, namespace) => {
    const user = getUserInfo();

    await checkAuthWithRedirect();

    const url = routes.distributorsGetMeta.replace(':id', user?.distributor_id);

    const params = {
        namespace: namespace,
    };

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params,
    };

    return await apiHandleRequest(request);
};

export const distributorsGetStoreSellersListSWR = async (key, url) => {
    await checkAuthWithRedirect();

    let headers;
    var regexIsAbsoluteUrl = new RegExp('^(?:[a-z+]+:)?//', 'i');
    if (regexIsAbsoluteUrl.test(url)) {
        // likely CDN link. Doesn't support authentication
        headers = {
            'Content-Type': 'application/json',
        };
    } else {
        headers = {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        };
    }
    const request = {
        method: 'get',
        url: url,
        headers: headers,
    };

    return await apiHandleRequest(request);
};

export const distributorsSellersInvites = async params => {
    const user = getUserInfo();

    await checkAuthWithRedirect();

    const url = routes.distributorsSellersInvites.replace(':id', user?.distributor_id);

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            order_by: params?.order_by ? params.order_by : '-created',
        },
    };

    return await apiHandleRequest(request);
};

export const distributorsSellerInvite = async params => {
    const user = getUserInfo();

    await checkAuthWithRedirect();

    const url = routes.distributorsSellerInvite.replace(':id', user?.distributor_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params,
    };

    return await apiHandleRequest(request);
};

export const sellerInviteDist = async params => {
    const user = getUserInfo();

    await checkAuthWithRedirect();
    const url = routes.distributorSellerInvite.replace(':id', user?.distributor_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params,
    };

    return await apiHandleRequest(request);
};

export const distributorInviteDist = async params => {
    await checkAuthWithRedirect();
    const url = routes.distributorInviteDistributorInvite;

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params,
    };

    return await apiHandleRequest(request);
};

/**
 * Creates application distributor assets.
 * @param {Object} params - The parameters for creating application distributor assets.
 * @param {string} params?.distributor_id - The distributor ID.
 * @param {Object} params.data - The data for creating application distributor assets.
 * @returns {Promise} A promise that resolves with the result of the API request.
 */
export const createApplicationDistributorAssets = async params => {
    const url = routes.createApplicationDistributorAssets.replace(
        ':distributor-id',
        params?.distributor_id,
    );

    const request = {
        method: 'post',
        url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data,
    };

    return await apiHandleRequest(request);
};

export const updateSellerDistributionPoint = async params => {
    const user = getUserInfo();

    await checkAuthWithRedirect();
    const url = routes.distributorUpdateDistributionSellerPoint
        .replace(':id', user?.distributor_id)
        .replace(':distributorseller_id', params.distributorseller_id);

    const request = {
        method: 'POST',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            distribution_points: params.distribution_points,
        },
    };

    return await apiHandleRequest(request);
};

export const updateSellerDistributorInfo = async params => {
    const user = getUserInfo();

    await checkAuthWithRedirect();
    const url = routes.distributorUpdateDistributorSeller
        .replace(':id', user?.distributor_id)
        .replace(':distributorseller_id', params.distributorseller_id);

    const request = {
        method: 'POST',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            remote_code: params.externalId,
            phone: params.phone,
        },
    };

    return await apiHandleRequest(request);
};

export const removeSellerAuthorizedProduct = async params => {
    const user = getUserInfo();

    await checkAuthWithRedirect();

    const url = routes.distributorRemoveAuthorizedProduct
        .replace(':id', user?.distributor_id)
        .replace(':distributorseller_id', params.distributorseller_id);

    const request = {
        method: 'POST',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            products: params.products,
        },
    };

    return await apiHandleRequest(request);
};

export const removeSellerDistributionPoint = async params => {
    const user = getUserInfo();

    await checkAuthWithRedirect();

    const url = routes.distributorRemoveDistributionSellerProduct
        .replace(':id', user?.distributor_id)
        .replace(':distributorseller_id', params.distributorseller_id);

    const request = {
        method: 'POST',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            distribution_points: params.products,
        },
    };

    return await apiHandleRequest(request);
};

export const updateSellerDistributionAuthorizedProducts = async params => {
    const user = getUserInfo();

    await checkAuthWithRedirect();
    const url = routes.distributorUpdateDistributionSellerProduct
        .replace(':id', user?.distributor_id)
        .replace(':distributorseller_id', params.distributorseller_id);

    const request = {
        method: 'POST',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            products: params.products,
        },
    };

    return await apiHandleRequest(request);
};

export const distributorsSellerInviteStores = async () => {
    await checkAuthWithRedirect();

    const url = routes.countries;

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const distributorGetAuthorizedProducts = async params => {
    const user = getUserInfo();
    await checkAuthWithRedirect();

    let url = routes.distributorGetAuthorizedProducts.replace(':id', user?.distributor_id);

    if (params?.portal_type) {
        url += `?specs.portal_type=${params.portal_type}`;
    }

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const distributorCreateDistributorPoint = async params => {
    const user = getUserInfo();
    await checkAuthWithRedirect();

    const url = routes.distributorCreateDistributorPoint.replace(':id', user?.distributor_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params,
    };

    return await apiHandleRequest(request);
};

export const distributorEditDistributorPoint = async params => {
    const user = getUserInfo();
    await checkAuthWithRedirect();

    const url = routes.distributorUpdateDistributionPoint
        .replace(':id', user?.distributor_id)
        .replace(':distributionpoint_id', params.pointId);

    const data = {
        name: params.name,
        cdata: {
            public: {
                location: params.location,
                postal_code: params.postalCode,
            },
        },
    };

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data,
    };

    return await apiHandleRequest(request);
};

export const distributorsGetInsurerApplicationsObs = (params, state) => {
    const obs = new Observable(observer => {
        distributorsGetInsurerApplications(params, state)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
                sendErrorToast();
            });
    });

    return obs;
};

export const distributorsGetMyDistributorsRequestsObs = params => {
    const obs = new Observable(observer => {
        distributorsGetMyDistributorsRequests(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
                sendErrorToast();
            });
    });

    return obs;
};

export const distributorsGetInsuranceProductsRequestsObs = params => {
    const obs = new Observable(observer => {
        distributorsGetInsuranceProductsRequests(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
                sendErrorToast();
            });
    });

    return obs;
};

export const distributorsGetMetaDataObs = params => {
    const obs = new Observable(observer => {
        distributorsGetMetaData(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
                sendErrorToast();
            });
    });

    return obs;
};

export const getDistributorsOutlets = async (params, type) => {
    const { stateQueryParams, paginationQueryParams, searchQueryParams } = params;
    const user = getUserInfo();
    const url = routes.distributorListPoints
        .replace(':id', user?.distributor_id)
        .replace('flag', type);

    await checkAuthWithRedirect();

    let request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            state:
                stateQueryParams?.length > 0
                    ? `in/[${stateQueryParams.map(state => `"${state}"`)}]/j`
                    : undefined,

            page_size:
                paginationQueryParams && paginationQueryParams?.page_size
                    ? paginationQueryParams?.page_size
                    : ITEMS_PER_PAGE,
            name:
                searchQueryParams && searchQueryParams?.name?.length > 0
                    ? `m/(?i)${searchQueryParams.name}.*/i`
                    : undefined,
            page_start_index: paginationQueryParams?.page_start_index
                ? paginationQueryParams?.page_start_index
                : 0,
            order_by: params && params.order_by ? params.order_by : '-created',
        },
    };

    // return await apiHandleRequest(request);
    return await apiHandleRequestRecursively(request, 'elements');
};

export const getDistributorsLeads = async params => {
    const { stateQueryParams, paginationQueryParams, searchQueryParams } = params;
    const user = getUserInfo();

    const url = routes.distributorListLeadsPoints.replace(':id', user?.distributor_id);

    await checkAuthWithRedirect();

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            state:
                stateQueryParams?.length > 0
                    ? `in/[${stateQueryParams.map(state => `"${state}"`)}]/j`
                    : undefined,

            page_size:
                paginationQueryParams && paginationQueryParams?.page_size
                    ? paginationQueryParams?.page_size
                    : ITEMS_PER_PAGE,
            name:
                searchQueryParams && searchQueryParams?.name?.length > 0
                    ? `m/(?i)${searchQueryParams.name}.*/i`
                    : undefined,
            page_start_index: paginationQueryParams?.page_start_index
                ? paginationQueryParams?.page_start_index
                : 0,
            order_by: params && params.order_by ? params.order_by : '-created',
        },
    };

    return await apiHandleRequest(request);
};

export const getDistributorsLeadsGenerators = async params => {
    const { stateQueryParams, paginationQueryParams, searchQueryParams } = params;
    const user = getUserInfo();

    const url = routes.distributorListLeadsGeneratorsPoints.replace(':id', user?.distributor_id);

    await checkAuthWithRedirect();

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            state:
                stateQueryParams?.length > 0
                    ? `in/[${stateQueryParams.map(state => `"${state}"`)}]/j`
                    : undefined,

            page_size:
                paginationQueryParams && paginationQueryParams?.page_size
                    ? paginationQueryParams?.page_size
                    : ITEMS_PER_PAGE,
            name:
                searchQueryParams && searchQueryParams?.name?.length > 0
                    ? `m/(?i)${searchQueryParams.name}.*/i`
                    : undefined,
            page_start_index: paginationQueryParams?.page_start_index
                ? paginationQueryParams?.page_start_index
                : 0,
            order_by: params && params.order_by ? params.order_by : '-created',
        },
    };

    return await apiHandleRequest(request);
};

export const getDistributorSellersInsuranceAgent = async params => {
    const { stateQueryParams, paginationQueryParams, searchQueryParams } = params;
    const user = getUserInfo();

    const url = routes.distributorListSellerInsuranceAgent.replace(':id', user?.distributor_id);

    await checkAuthWithRedirect();

    let endpointParams = {
        state:
            stateQueryParams?.length > 0
                ? `in/[${stateQueryParams.map(state => `"${state}"`)}]/j`
                : undefined,

        page_size:
            paginationQueryParams && paginationQueryParams?.page_size
                ? paginationQueryParams?.page_size
                : ITEMS_PER_PAGE,
        page_start_index: paginationQueryParams?.page_start_index
            ? paginationQueryParams?.page_start_index
            : 0,
        order_by: params && params.order_by ? params.order_by : '-created',
    };

    let isEmail = Boolean(searchQueryParams?.email);
    if (isEmail) {
        endpointParams.email =
            searchQueryParams && searchQueryParams?.email?.length > 0
                ? `m/(?i)${searchQueryParams.email}.*/i`
                : undefined;
    } else {
        endpointParams.name =
            searchQueryParams && searchQueryParams?.name?.length > 0
                ? `m/(?i)${searchQueryParams.name}.*/i`
                : undefined;
    }

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: endpointParams,
    };

    return params.isRecursive
        ? await apiHandleRequestRecursively(request, 'elements')
        : await apiHandleRequest(request);
};

export const getDistributorInvites = async params => {
    const user = getUserInfo();

    let url;
    if (params?.unique) {
        url = routes.distributorInviteDistributorsGetUniqueInvite
            .replace(':dist_id', user?.distributor_id)
            .replace(':invite_id', params?.unique);
    } else {
        url = routes.distributorInviteDistributorsGetInvites.replace(
            ':dist_id',
            user?.distributor_id,
        );
    }

    await checkAuthWithRedirect();

    let endpointParams = {
        state:
            params?.stateQueryParams?.length > 0
                ? `in/[${params?.stateQueryParams.map(state => `"${state}"`)}]/j`
                : undefined,

        page_size:
            params?.paginationQueryParams && params?.paginationQueryParams?.page_size
                ? params?.paginationQueryParams?.page_size
                : ITEMS_PER_PAGE,
        page_start_index: params?.paginationQueryParams?.page_start_index
            ? params?.paginationQueryParams?.page_start_index
            : 0,
        order_by: params && params?.order_by ? params?.order_by : '-created',
    };

    let isEmail = Boolean(params?.searchQueryParams?.email);
    if (isEmail) {
        endpointParams.email =
            params?.searchQueryParams && params?.searchQueryParams?.email?.length > 0
                ? `m/(?i)${params?.searchQueryParams.email}.*/i`
                : undefined;
    } else {
        endpointParams.name =
            params?.searchQueryParams && params?.searchQueryParams?.name?.length > 0
                ? `m/(?i)${params?.searchQueryParams.name}.*/i`
                : undefined;
    }

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: endpointParams,
    };

    return await apiHandleRequest(request);
};

/**
 *
 * @param { id: string | boolean, state: any, products: any[] } data
 */
export const updateDistributorInvite = async params => {
    const user = getUserInfo();
    const distributor_id = user?.distributor_id;
    const url = routes.distributorUpdateDistributorInvite.replace(':id', params.data.id);

    await checkAuthWithRedirect();

    const request = {
        method: 'PATCH',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            distributor_id: distributor_id,
            state: params.data.state,
            products: params.data.products,
        },
    };

    return await apiHandleRequest(request);
};

export const retrieveDistributorInfo = async params => {
    const user = getUserInfo();

    const url = routes.distributorRetrieveDistributionSellerInfo
        .replace(':id', user?.distributor_id)
        .replace(':distributorseller_id', params.distributorseller_id);

    await checkAuthWithRedirect();

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const retrieveRegisterDistributorInfo = async () => {
    const user = getUserInfo();

    const url = routes.retrieveRegisterDistributorInfo.replace(
        ':distributor_id',
        user?.distributor_id,
    );

    await checkAuthWithRedirect();

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const getDistributorInfo = async params => {
    const url = routes.retrieveRegisterDistributorInfo.replace(
        ':distributor_id',
        params?.distributor_id,
    );

    await checkAuthWithRedirect();

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const updateRegisterDistributorInfo = async (params, isExternal = false) => {
    const user = getUserInfo();

    const url = routes.updateRegisterDistributorInfo.replace(
        ':distributor_id',
        isExternal ? params?.distributor_id : user?.distributor_id,
    );

    await checkAuthWithRedirect();

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data,
    };

    return await apiHandleRequest(request);
};

export const updateRegisterProviderInfo = async (params, isExternal = false) => {
    const user = getUserInfo();

    const url = routes.updateRegisterProviderInfo.replace(
        ':provider_id',
        isExternal ? params?.provider_id : user?.provider_id,
    );

    await checkAuthWithRedirect();

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data,
    };

    return await apiHandleRequest(request);
};

export const submitRegisterDistributorInfo = async params => {
    const user = getUserInfo();

    const url = routes.submitRegisterDistributorInfo.replace(
        ':distributor_id',
        user?.distributor_id,
    );

    await checkAuthWithRedirect();

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data,
    };

    return await apiHandleRequest(request);
};

export const submitRegisterProviderInfo = async params => {
    const user = getUserInfo();

    const url = routes.submitRegisterProviderInfo.replace(
        ':provider_id',
        user?.provider_id,
    );

    await checkAuthWithRedirect();

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data,
    };

    return await apiHandleRequest(request);
};

/**
 *  @param { pointId: string, state: 'active' | 'inactive' } params
 */
export const distributorChangeLeadStatus = async params => {
    const user = getUserInfo();
    await checkAuthWithRedirect();

    const url = routes.distributorMarkPointAsState
        .replace(':id', user?.distributor_id)
        .replace(':distributionpoint_id', params.pointId)
        .replace('{state}', params.state);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const distributorMarkPointAsState = async params => {
    const user = getUserInfo();
    await checkAuthWithRedirect();

    const url = routes.distributorMarkPointAsState
        .replace(':id', user?.distributor_id)
        .replace(':distributionpoint_id', params.pointId)
        .replace('{state}', params.state);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const distributorSellerMarkPointAsState = async params => {
    const user = getUserInfo();
    await checkAuthWithRedirect();

    const url = routes.distributorSellerMarkPointAsState
        .replace(':id', user?.distributor_id)
        .replace(':distributorseller_id', params.pointId)
        .replace('{state}', params.state);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const postDistributorsMetaData = async params => {
    const user = getUserInfo();
    const url = routes.distributorsMetaData.replace(':id', user?.distributor_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            namespace: params.namespace,
        },
    };

    return await apiHandleRequest(request);
};

export const postProvidersMetaData = async params => {
    const user = getUserInfo();
    const url = routes.providersMetaData.replace(':id', user?.provider_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            namespace: params.namespace,
        },
    };

    return await apiHandleRequest(request);
};

export const sendSmsEntry = async params => {
    const user = getUserInfo();
    const url = routes.smsEntry.replace(':distributor-id', user?.distributor_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            'Content-Type': 'application/json',
            Authorization: apiGetAuthHeader(),
        },
        data: {
            mobile: params.mobile,
            code: params.code,
        },
    };

    return await apiHandleRequest(request);
};

export const sendSmsVerificationCode = async params => {
    const user = getUserInfo();
    const url = routes.smsVerify.replace(':distributor-id', user?.distributor_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            'Content-Type': 'application/json',
            Authorization: apiGetAuthHeader(),
        },
        data: {
            mobile: params.mobile,
            code: params.code,
        },
    };

    return await apiHandleRequest(request);
};

export const getDistributorAssets = (self = true, distributor_id) => {
    const user = getUserInfo();
    const url = routes.distributorsGetAssets.replace(
        ':distributor-id',
        self ? user?.distributor_id : distributor_id,
    );

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return apiHandleRequest(request);
};

export const getFilteredDistributorAssets = (self = true, distributor_id) => {
    const user = getUserInfo();
    const url = routes.distributorsGetAssetsFilteredByDistributorsEntityDocumentsOnly.replace(
        ':distributor-id',
        self ? user?.distributor_id : distributor_id,
    );

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return apiHandleRequest(request);
};

export const getFilteredDistributorRequestsAssets = params => {
    const url = routes.distributorsGetAssetsFilteredByDistributorsRequestsEntityDocumentsOnly
        .replace(':distributor-id', params?.distributor_id)
        .replace(':distributorrequest_id', params.entity_id);

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return apiHandleRequest(request);
};

export const distributorMarkAsState = async params => {
    const app_id = process.env.APP_ID || process.env.REACT_APP_APP_ID;
    const url = routes.distributorMarkAsState
        .replace(':id', app_id)
        .replace(':distributor-id', params.id)
        .replace('{state}', params.state);
    console.log(url, params);
    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const distributorGetProducts = async params => {
    const app_id = process.env.APP_ID || process.env.REACT_APP_APP_ID;
    const url = routes.distributorGetProducts
        .replace(':id', app_id)
        .replace(':distributor-id', params?.distributor_id);
    const request = {
        method: 'GET',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            page_size: params && params.page_size ? params.page_size : 15,
            order_by: params && params.order_by ? params.order_by : '-created',
            page_start_index: params && params.page_start_index ? params.page_start_index : 0,
            name: params.name ? params.name : undefined,
        },
    };

    return await apiHandleRequest(request);
};
export const distributorGetProductsConfiguration = async params => {
    const app_id = process.env.APP_ID || process.env.REACT_APP_APP_ID;
    const url = routes.distributorGetProductConfiguration
        .replace(':id', app_id)
        .replace(':entity-id', params.entityId);

    const request = {
        method: 'GET',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};
export const distributorSetProductsConfiguration = async params => {
    const url = routes.distributorSetProductConfiguration.replace(':entity-id', params.entityId);

    const request = {
        method: 'POST',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data,
    };

    return await apiHandleRequest(request);
};
export const distributorSetProductsDocuments = async params => {
    const url = routes.distributorSetDocumentConfiguration.replace(':entity-id', params.entityId);

    const request = {
        method: 'POST',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: { documents: params.data },
    };

    return await apiHandleRequest(request);
};

export const distributorAddProducts = async params => {
    const url = routes.distributorAddProducts.replace(':distributor_id', params?.distributor_id);
    const request = {
        method: 'POST',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            products: params.data,
        },
    };

    return await apiHandleRequest(request);
};

export const getDistributorSellerMeta = async () => {
    const url = routes.distributorsSellersMeta;
    const request = {
        method: 'POST',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            namespace: 'selfcare_behavior',
        },
    };

    return await apiHandleRequest(request);
};
