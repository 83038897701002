import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './Chat.module.scss';
import Loading from 'shared/Loading';
import History from './History';
import { sendErrorToast } from 'services/toast';
import { INSURANCE_CLAIM_CHAT_SEND_OPTIONS } from 'utils/constants';
// import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export class Chat extends React.PureComponent {
    myRef = React.createRef();
    // targetElement = null;

    state = {
        containerId: 'chatContainer',
        message: '',
        canSend: false,
        uploadFile: null,
        uploadFilePreview: null,
        uploadFileName: null,
        uploadFileType: null,
        popup: {
            active: false,
        },
        direction: '',
        lastScrollPos: 0,
    };

    static propTypes = {
        texts: PropTypes.object,
        history: PropTypes.object,
        claimId: PropTypes.string,
        onClose: PropTypes.func,
        fetchHistory: PropTypes.func,
        clearHistory: PropTypes.func,
        onSendMessage: PropTypes.func,
        onUploadAsset: PropTypes.func,
        onDownloadAsset: PropTypes.func,
        isLoading: PropTypes.bool,
        isSaving: PropTypes.bool,
        isFetching: PropTypes.bool,
        disabled: PropTypes.bool,
        accept: PropTypes.string,
        extensions: PropTypes.array,
        width: PropTypes.number,
        height: PropTypes.number,
        strict: PropTypes.bool,
    };

    static defaultProps = {
        width: 512,
        height: 512,
        extensions: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
        accept: 'image/png, image/jpeg, .pdf, video/*',
    };

    componentDidMount() {
        this.props.fetchHistory({ id: this.props.claimId });
        document.addEventListener('click', this.handleClickOutside, true);
        // document.addEventListener('scroll', this.handleDebounceOnScroll, true);

        // this.targetElement = this.myRef.current;
        // if (this.targetElement) {
        //     console.log('DISABLED');
        //     disableBodyScroll(this.targetElement);
        // }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.history &&
            this.props.history &&
            JSON.stringify(prevProps.history) !== JSON.stringify(this.props.history)
        ) {
            this.setState({
                message: '',
                canSend: false,
                uploadFile: null,
                uploadFilePreview: null,
                uploadFileName: null,
                popup: {
                    active: false,
                },
            });
        }
    }

    componentWillUnmount() {
        this.props.clearHistory();
        document.removeEventListener('click', this.handleClickOutside, true);
        // document.removeEventListener('scroll', this.handleDebounceOnScroll, true);
        // clearAllBodyScrollLocks();
    }

    handleClickOutside = ({ target }) => {
        if (target) {
            const { containerId } = this.state;
            const specifiedElement = document.getElementById(containerId);
            const isClickInside = specifiedElement.contains(target);

            if (!target.hasAttribute('download') && !isClickInside) {
                this.props.onClose();
            }
        }
    };

    handleOnScroll = event => {
        if (event && event.target) {
            const { lastScrollPos } = this.state;
            const currentScrollPos = event.target.scrollTop;

            if (currentScrollPos <= 10) {
                this.setState({
                    direction: 'up',
                    lastScrollPos: currentScrollPos,
                });
            } else if (lastScrollPos < currentScrollPos) {
                this.setState({
                    direction: 'down',
                    lastScrollPos: currentScrollPos,
                });
            }
        }
    };

    handleOnChange = e => {
        const target = e.target;

        this.setState({
            message: target.value,
            canSend: target.value !== '' || this.state.uploadFile ? true : false,
        });
    };

    handleUploadFile = e => {
        if (e.target.files) {
            const { extensions, strict } = this.props;
            const file = e.target.files[0];
            // console.log('file', file);

            if (!file || !file.type) {
                return false;
            }

            const size = file ? file.size / 1024 / 1024 : null;
            if (size > 20) {
                sendErrorToast('File size exceeds 20 MB.');
                return false;
            }

            if (
                extensions &&
                !extensions.includes(file.type) &&
                file.type.indexOf('video') === -1
            ) {
                sendErrorToast('Wrong file extension.');
                return false;
            }

            if (strict) {
                this.handleImageSizeValidation(file);
            } else {
                this.handleOnUpload(file);
                e.target.value = '';
            }
        }
    };

    handleImageSizeValidation = file => {
        const { width, height } = this.props;

        let img = new Image();
        img.src = window.URL.createObjectURL(file);

        img.onload = () => {
            const naturalWidth = img.naturalWidth;
            const naturalHeight = img.naturalHeight;
            window.URL.revokeObjectURL(img.src);

            if (naturalWidth !== width || naturalHeight !== height) {
                sendErrorToast(`Image size required: ${width}x${height}px`);
            } else {
                this.handleOnUpload(file);
            }
        };
    };

    handleOnUpload = file => {
        this.setState({
            canSend: true,
            uploadFile: file,
            uploadFilePreview: URL.createObjectURL(file),
            uploadFileName: file.name,
            uploadFileType: file.type,
        });
    };

    handleClearUpload = e => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            canSend: false,
            uploadFile: null,
            uploadFilePreview: null,
            uploadFileName: null,
        });
    };

    handleOnSubmit = e => {
        if (e) {
            e.preventDefault();
        }

        if (this.state.uploadFile) {
            const payload = {
                id: this.props.claimId,
                upload: {
                    content: this.state.uploadFile,
                },
                params: {
                    ...INSURANCE_CLAIM_CHAT_SEND_OPTIONS.asset,
                    description: this.state.uploadFileName,
                },
            };

            this.props.onUploadAsset(payload);
        } else {
            const payload = {
                id: this.props.claimId,
                params: {
                    ...INSURANCE_CLAIM_CHAT_SEND_OPTIONS.message,
                    description: this.state.message,
                },
            };

            this.props.onSendMessage(payload);
        }
    };

    handleDownloadAsset = e => {
        const id = e.currentTarget.getAttribute('data-id');
        const payload = {
            claim_id: this.props.claimId,
            asset_id: id,
        };
        this.props.onDownloadAsset(payload);
    };

    render() {
        const { onClose, isLoading, isSaving, isFetching, history, disabled } = this.props;
        const {
            message,
            canSend,
            uploadFileName,
            uploadFilePreview,
            uploadFileType,
            containerId,
            direction,
        } = this.state;

        return (
            <div
                ref={this.myRef}
                data-test="ChatContainer"
                id={containerId}
                className={Styles.container}
            >
                <div className={Styles.window}>
                    <header className={Styles[direction]}>
                        Chat
                        <i
                            data-test="ChatClose"
                            className={classnames('icon-icon_delete_2', Styles.close)}
                            onClick={onClose}
                        ></i>
                    </header>
                    <div className={Styles.content} onScroll={this.handleOnScroll}>
                        {isFetching && <Loading />}
                        {!isFetching && history && Object.entries(history).length > 0 && (
                            <History
                                data-test="ChatHistory"
                                data={history}
                                onDownloadAsset={this.handleDownloadAsset}
                            />
                        )}
                        {!isFetching && (!history || Object.entries(history).length === 0) && (
                            <div data-test="ChatEmpty" className={Styles.noHistory}>
                                <i className="icon-icon_search"></i>
                                There is no chat history...
                            </div>
                        )}
                    </div>
                    <footer>
                        <form onSubmit={this.handleOnSubmit}>
                            <div className={Styles.input}>
                                {!uploadFilePreview && (
                                    <input
                                        autoFocus
                                        data-test="Input"
                                        disabled={disabled}
                                        aria-disabled={disabled}
                                        ref={this._input}
                                        onChange={this.handleOnChange}
                                        placeholder="Type your message..."
                                        value={message}
                                        type="text"
                                        name="message"
                                        onFocus={this.handleOnFocus}
                                        onBlur={this.handleOnBlur}
                                        autoComplete="off"
                                    />
                                )}

                                {uploadFilePreview && (
                                    <div className={Styles.uploadPreview}>
                                        {uploadFileType.indexOf('image') !== -1 && (
                                            <img src={uploadFilePreview} alt="Upload" />
                                        )}
                                        {uploadFileType.indexOf('video') !== -1 && (
                                            <i
                                                className={classnames(
                                                    Styles.attach,
                                                    Styles.square,
                                                    'icon-icon_play',
                                                )}
                                            ></i>
                                        )}
                                        {uploadFileType.indexOf('application') !== -1 && (
                                            <i
                                                className={classnames(
                                                    Styles.attach,
                                                    'icon-icon_flow_test',
                                                )}
                                            ></i>
                                        )}
                                        <div className={Styles.fileName} title={uploadFileName}>
                                            {uploadFileName}
                                        </div>
                                    </div>
                                )}

                                <button type="button" className={Styles.upload}>
                                    {!uploadFilePreview && (
                                        <>
                                            <input
                                                ref={this._upload}
                                                data-test="Upload"
                                                type="file"
                                                name="file"
                                                id={`chatFileUpload`}
                                                className="inputfile"
                                                onChange={this.handleUploadFile}
                                            />

                                            <i
                                                className={classnames(
                                                    Styles.attach,
                                                    'icon-icon_attachment',
                                                )}
                                            ></i>
                                        </>
                                    )}

                                    {uploadFilePreview && (
                                        <i
                                            className={classnames(
                                                Styles.attach,
                                                'icon-icon_delete_2',
                                            )}
                                            onClick={this.handleClearUpload}
                                        ></i>
                                    )}
                                </button>
                            </div>

                            <button
                                type="submit"
                                className={Styles.send}
                                disabled={!canSend || isFetching || isLoading || isSaving}
                                onClick={this.handleOnSubmit}
                            >
                                <i className="icon-icon_send"></i>
                            </button>
                        </form>
                    </footer>
                </div>
            </div>
        );
    }
}

export default Chat;
