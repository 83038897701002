// @ts-check
import { apiGetAuthHeader, apiHandleRequest } from 'services/api';
import { getEndpoints } from 'services/localStorage';
import routes from './routes';

/**
 *
 * @param {{ content: any }} data
 * @returns
 */
export const appFileUpload = async data => {
    const endpoints = getEndpoints();
    const app_id = process.env.APP_ID || process.env.REACT_APP_APP_ID;
    const url = routes.appFileUpload.replace(':id', app_id);

    const formData = new FormData();
    formData.append('content', data.content);

    const request = {
        method: 'post',
        baseURL: endpoints.uploader,
        url: url,
        // url: "https://assets.ci.integrations.habit.io/v3/applications/af620e5c-dc3a-47e8-9976-7e673e0fb5f0/asset-upload",
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'multipart/form-data',
        },
        data: formData,
    };

    return await apiHandleRequest(request);
};
