import ActionTypes from 'redux/actionTypes';
import rootInitialState from 'redux/store/initialState';
import { DATE_FORMAT_NO_TIME_INTERNATIONAL } from 'utils/constants';
import { formatDate } from 'utils/functions';
import { formatInsurancePaymentsPayload, insureeViewModel } from './model';

export const initialState = {
    insurance: rootInitialState.insurance,
};

const reducer = (state = rootInitialState, action) => {
    switch (action.type) {
        case ActionTypes.INSURANCE_IS_LOADING:
            return isLoading(state, action);
        case ActionTypes.INSURANCE_IS_SAVING:
            return isSaving(state, action);
        case ActionTypes.INSURANCE_CLEAR:
            return clear(state, action);
        case ActionTypes.INSURANCE_SYNC_PROJECTS:
            return syncProjects(state, action);
        case ActionTypes.INSURANCE_CHANGE_PROJECT:
            return changeProject(state, action);
        case ActionTypes.INSURANCE_SYNC_INSUREES_SEARCH:
            return syncInsureesSearch(state, action);
        case ActionTypes.INSURANCE_CLEAR_INSUREES_SEARCH:
            return clearInsureesSearch(state, action);
        case ActionTypes.INSURANCE_SYNC_INSUREES:
            return syncInsurees(state, action);
        case ActionTypes.INSURANCE_SYNC_DATA_BY_TYPE:
            return syncDataBytype(state, action);
        case ActionTypes.INSURANCE_SYNC_SEARCH_BY_TYPE:
            return syncSearchByType(state, action);
        case ActionTypes.INSURANCE_CLEAR_SEARCH_BY_TYPE:
            return clearSearchByType(state, action);
        case ActionTypes.INSURANCE_POLICIES_OPEN_PAYMENTS_MODAL:
            return openPaymentsModal(state, action);
        case ActionTypes.INSURANCE_POLICIES_CLOSE_PAYMENTS_MODAL:
            return closePaymentsModal(state, action);
        case ActionTypes.INSURANCE_POLICIES_OPEN_DOCUMENTS_MODAL:
            return openDocumentsModal(state, action);
        case ActionTypes.INSURANCE_POLICIES_CLOSE_DOCUMENTS_MODAL:
            return closeDocumentsModal(state, action);
        case ActionTypes.INSURANCE_POLICIES_OPEN_MEDIA_MODAL:
            return openMediaModal(state, action);
        case ActionTypes.INSURANCE_POLICIES_CLOSE_MEDIA_MODAL:
            return closeMediaModal(state, action);
        case ActionTypes.INSURANCE_POLICIES_OPEN_DAMAGES_MODAL:
            return openDamagesModal(state, action);
        case ActionTypes.INSURANCE_POLICIES_CLOSE_DAMAGES_MODAL:
            return closeDamagesModal(state, action);
        case ActionTypes.INSURANCE_SYNC_META_DATA_TOTALS:
            return syncMetaDataTotals(state, action);
        case ActionTypes.INSURANCE_SYNC_CURRENT_VIEW:
            return syncCurrentView(state, action);
        case ActionTypes.INSURANCE_CLEAR_CURRENT_VIEW:
            return clearCurrentView(state, action);
        case ActionTypes.INSURANCE_CURRENT_VIEW_HAS_ERROR:
            return hasErrorCurrentView(state, action);
        case ActionTypes.INSURANCE_SYNC_CAR_SPECS:
            return clearCarSpecs(state, action);
        case ActionTypes.INSURANCE_SYNC_META_DATA_BY_TYPE:
            return syncMetaDataByType(state, action);
        case ActionTypes.INSURANCE_SYNC_PAGINATE_DATA:
            return insuranceSyncPaginate(state, action);
        case ActionTypes.INSURANCE_SYNC_SORT_BY_TYPE:
            return insuranceSyncSort(state, action);
        case ActionTypes.INSURANCE_SYNC_META_DATA_TOTAL_PAYMENTS:
            return insuranceSyncTotalPayments(state, action);
        case ActionTypes.INSURANCE_SYNC_PAYMENTS:
            return insuranceSyncPayments(state, action);
        case ActionTypes.INSURANCE_SYNC_SERVICE:
            return insuranceSyncService(state, action);
        case ActionTypes.INSURANCE_SYNC_CLAIM_LOCATION:
            return insuranceSyncClaimLocation(state, action);
        case ActionTypes.INSURANCE_SYNC_CLAIM_CHAT_HISTORY:
            return insuranceSyncClaimChatHistory(state, action);
        case ActionTypes.INSURANCE_FETCH_CLAIM_CHAT_HISTORY:
            return insuranceFetchClaimChatHistory(state, action);
        case ActionTypes.INSURANCE_CLEAR_CLAIM_CHAT_HISTORY:
            return insuranceClearClaimChatHistory(state, action);
        case ActionTypes.INSURANCE_ASSET_OPEN_ODOMETER_HISTORY_MODAL:
            return openAssetOdometerHistoryModal(state, action);
        case ActionTypes.INSURANCE_ASSET_CLOSE_ODOMETER_HISTORY_MODAL:
            return closeAssetOdometerHistoryModal(state, action);
        case ActionTypes.INSURANCE_ASSET_SYNC_ODOMETER_HISTORY:
            return insuranceSyncOdometerHistory(state, action);
        case ActionTypes.INSURANCE_ASSET_SYNC_ODOMETER_HISTORY_TOTAL_DRIVEN:
            return insuranceSyncOdometerHistoryTotalDriven(state, action);
        case ActionTypes.INSURANCE_OPEN_BILLING_MODAL:
            return openBillingModal(state, action);
        case ActionTypes.INSURANCE_CLOSE_BILLING_MODAL:
            return closeBillingModal(state, action);
        case ActionTypes.INSURANCE_SYNC_BILLING:
            return insuranceSyncBilling(state, action);
        default:
            return state;
    }
};

const insuranceSyncBilling = (state, action) => {
    const payload = action.payload.data;
    const policyId = action.payload.policyId;
    const clientserviceId = action.payload.clientserviceId;
    // const elements = formatInsuranceBillingsPayload(payload);

    // const otherPolicies = state.view.policies.filter(p => p.id !== policyId);
    let policy = state.view.policies?.filter(p => p.id === policyId)[0];

    policy = {
        ...policy,
        billing: [...payload],
    };

    return {
        ...state,
        view: {
            ...state.view,
            policies: state.view.policies?.map(p => {
                if (p.id === policyId) {
                    return policy;
                }
                return p;
            }),
            // policies: [...otherPolicies, policy],
        },
        billing: {
            ...state.billing,
            list: [...payload],
            policyId: policyId,
            clientserviceId: clientserviceId,
        },
    };
};

const openBillingModal = (state, action) => {
    // const payload = action.payload.data;

    return {
        ...state,
        billing: {
            ...state.billing,
            active: true,
            // data: payload,
        },
    };
};

const closeBillingModal = (state, action) => {
    return {
        ...state,
        billing: {
            ...state.billing,
            active: false,
            list: [],
            pagination: {
                current: '--',
                total: '--',
                next: false,
                page_start_index: 0,
            },
        },
    };
};

const insuranceSyncOdometerHistoryTotalDriven = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        view: {
            ...state.view,
            odometer: {
                ...state.view.odometer,
                totalDriven: payload,
            },
        },
    };
};

const insuranceSyncOdometerHistory = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        view: {
            ...state.view,
            odometer: {
                ...state.view.odometer,
                history: [...payload],
            },
        },
    };
};

const openAssetOdometerHistoryModal = (state, action) => {
    const payload = action.payload.data;
    return {
        ...state,
        view: {
            ...state.view,
            odometer: {
                ...state.view.odometer,
                asset: payload,
                active: true,
                history: null,
            },
        },
    };
};

const closeAssetOdometerHistoryModal = (state, action) => {
    return {
        ...state,
        view: {
            ...state.view,
            odometer: {
                ...state.view.odometer,
                asset: null,
                active: false,
            },
        },
    };
};

const insuranceClearClaimChatHistory = (state, action) => {
    return {
        ...state,
        claims: {
            ...state.claims,
            chat: {
                ...state.claims.chat,
                isLoading: false,
                history: null,
            },
        },
    };
};

const insuranceFetchClaimChatHistory = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        claims: {
            ...state.claims,
            chat: {
                ...state.claims.chat,
                isLoading: payload,
            },
        },
    };
};

const insuranceSyncClaimChatHistory = (state, action) => {
    const payload = action.payload.data;
    const groupBy = (items, key) =>
        items.reduce(
            (result, item) => ({
                ...result,
                [formatDate(item[key], null, DATE_FORMAT_NO_TIME_INTERNATIONAL, false)]: [
                    ...(result[
                        formatDate(item[key], null, DATE_FORMAT_NO_TIME_INTERNATIONAL, false)
                    ] || []),
                    item,
                ],
            }),
            {},
        );

    const formattedHistory = groupBy(payload, 'created');

    return {
        ...state,
        claims: {
            ...state.claims,
            chat: {
                ...state.claims.chat,
                history: formattedHistory,
            },
        },
    };
};

const insuranceSyncClaimLocation = (state, action) => {
    const payload = action.payload.data;

    const policies = state.view.policies?.map(p => {
        const claims = p.claims.map(c => {
            if (c.id === payload.claim_id) {
                const staticMap = `https://maps.googleapis.com/maps/api/staticmap?center=${c.location.data.latlng[0]},+${c.location.data.latlng[1]}&zoom=13&scale=1&size=360x170&maptype=roadmap&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xf9bb00%7C${c.location.data.latlng[0]},+${c.location.data.latlng[1]}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;

                return {
                    ...c,
                    location: {
                        ...c.location,
                        data: {
                            ...c.location.data,
                            address: payload.results[0].formatted_address,
                            map: staticMap,
                        },
                    },
                };
            }

            return c;
        });

        return {
            ...p,
            claims: claims,
        };
    });

    return {
        ...state,
        view: {
            ...state.view,
            policies: policies,
        },
    };
};

const insuranceSyncSort = (state, action) => {
    const type = action.payload.type;
    const payload = action.payload.data;

    return {
        ...state,
        [type]: {
            ...state[type],
            filters: {
                field: payload.field,
                direction: payload.direction,
            },
        },
    };
};

const syncMetaDataByType = (state, action) => {
    const type = action.payload.type;
    const payload = action.payload.data;

    return {
        ...state,
        [type]: {
            ...state[type],
            pagination: {
                ...state[type].pagination,
                current:
                    state[type].pagination.current === '--' ? 1 : state[type].pagination.current,
                total: Math.ceil(payload / state[type].chunk),
            },
        },
    };
};

const clearCarSpecs = (state, action) => {
    const payload = action.payload.data;
    return {
        ...state,
        carSpecs: payload,
    };
};

const hasErrorCurrentView = state => {
    return {
        ...state,
        view: { ...rootInitialState.insurance.view, hasError: true },
    };
};

const clearCurrentView = state => {
    return {
        ...state,
        view: rootInitialState.insurance.view,
    };
};

const syncCurrentView = (state, action) => {
    const payload = action.payload.data;
    const data = action.payload.type;

    if (!payload) {
        return { ...state };
    }

    const model = insureeViewModel(payload, data);
    // console.log('TCL: syncCurrentView -> model', model);

    return {
        ...state,
        view: {
            ...state.view,
            ...payload,
            insuree: { ...model.insuree },
            policies: [...model.policies],
            quotes: [...model.quotes],
        },
    };
};

const syncMetaDataTotals = (state, action) => {
    const payload = action.payload.data;
    const totals = { ...state.dashboard.totals };

    payload.map(total => {
        Object.entries(total).forEach(([key, value]) => {
            totals[key] = value && value.count ? value.count : 0;
        });
        return total;
    });

    return {
        ...state,
        dashboard: {
            ...state.dashboard,
            totals: totals,
        },
    };
};

const openMediaModal = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        policies: {
            ...state.policies,
            media: {
                ...state.policies.media,
                active: true,
                data: payload,
            },
        },
    };
};

const closeMediaModal = (state, action) => {
    return {
        ...state,
        policies: {
            ...state.policies,
            media: {
                ...state.policies.media,
                active: false,
                data: null,
            },
        },
    };
};

const openDamagesModal = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        policies: {
            ...state.policies,
            damages: {
                ...state.policies.damages,
                active: true,
                data: payload,
            },
        },
    };
};

const closeDamagesModal = (state, action) => {
    return {
        ...state,
        policies: {
            ...state.policies,
            damages: {
                ...state.policies.damages,
                active: false,
                data: null,
            },
        },
    };
};

const openDocumentsModal = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        policies: {
            ...state.policies,
            documents: {
                ...state.policies.documents,
                active: true,
                data: payload,
            },
        },
    };
};

const closeDocumentsModal = (state, action) => {
    return {
        ...state,
        policies: {
            ...state.policies,
            documents: {
                ...state.policies.documents,
                active: false,
            },
        },
    };
};

const openPaymentsModal = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        policies: {
            ...state.policies,
            payments: {
                ...state.policies.payments,
                active: true,
                id: payload.clientservice_id,
            },
        },
    };
};

const closePaymentsModal = (state, action) => {
    return {
        ...state,
        policies: {
            ...state.policies,
            payments: {
                ...state.policies.payments,
                active: false,
                list: [],
                pagination: {
                    current: '--',
                    total: '--',
                    next: true,
                    page_start_index: 0,
                },
                filters: {
                    field: 'payday',
                    direction: 'desc',
                },
            },
        },
    };
};

const insuranceSyncTotalPayments = (state, action) => {
    const payload = action.payload.data;
    const total = payload.total_number_of_payments.count;

    return {
        ...state,
        policies: {
            ...state.policies,
            payments: {
                ...state.policies.payments,
                total: total,
                pagination: {
                    ...state.policies.payments.pagination,
                    current:
                        total > 0 && state.policies.payments.pagination.current === '--'
                            ? 1
                            : state.policies.payments.pagination.current,
                    total: Math.ceil(total / state.policies.payments.chunk),
                },
            },
        },
    };
};

const insuranceSyncPayments = (state, action) => {
    const payload = action.payload.data;
    const elements = formatInsurancePaymentsPayload(payload.elements);

    return {
        ...state,
        policies: {
            ...state.policies,
            payments: {
                ...state.policies.payments,
                list: [...elements],
            },
        },
    };
};

const insuranceSyncService = (state, action) => {
    const payload = action.payload.data;

    const otherPolicies = state.view.policies?.filter(p => p.id !== payload.policy_id);
    let policy = state.view.policies?.filter(p => p.id === payload.policy_id)[0];
    const otherAssets = policy.quote.quote.protected_assets?.filter(a => a.id !== payload.asset_id);
    let asset = policy.quote.quote.protected_assets?.filter(a => a.id === payload.asset_id)[0];

    asset = {
        ...asset,
        service: {
            ...asset.service,
            data: { ...asset.service.data, ...payload.data },
        },
    };

    policy = {
        ...policy,
        quote: {
            ...policy.quote,
            quote: {
                ...policy.quote.quote,
                protected_assets: [...otherAssets, asset],
            },
        },
    };

    return {
        ...state,
        view: {
            ...state.view,
            policies: [...otherPolicies, policy],
        },
    };
};

/**
 * Manage Loading state
 * @param {*} state
 * @param {*} action
 */
const isLoading = (state, action) => {
    const payload = action.payload.isLoading;

    return {
        ...state,
        isLoading: payload,
    };
};

/**
 * Manage Saving state
 * @param {*} state
 * @param {*} action
 */
const isSaving = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        isSaving: payload,
    };
};

/**
 * Clear Integration
 * @param {*} state
 * @param {*} action
 */
const clear = (state, action) => {
    return {
        ...state,
        isLoading: false,
        isSaving: false,
        // details: {},
        // devices: [],
        // components: [],
    };
};

/**
 * Sync Projects
 * @param {*} state
 * @param {*} action
 */
const syncProjects = (state, action) => {
    const payload = action.payload.data;
    const elements = payload.elements;

    const list = elements.map(project => {
        return {
            id: project.id,
            text: project.name,
            value: project.id,
            confirmation_hash: project.confirmation_hash,
            country: project?.cdata?.public?.country || null,
            currency: project?.cdata?.public?.currency || null,
            context: project?.cdata?.public?.context || null,
        };
    });

    return {
        ...state,
        filters: {
            ...state.filters,
            projects: {
                ...state.filters.projects,
                list: [...list],
            },
        },
    };
};

/**
 * Change Project
 * @param {*} state
 * @param {*} action
 */
const changeProject = (state, action) => {
    const payload = action.payload.data;
    return {
        ...state,
        filters: {
            ...state.populationSegmentation,
            projects: {
                ...state.filters.projects,
                selected: payload,
            },
        },
        view: rootInitialState.insurance.view,
        insurees: {
            ...state.insurees,
            search: {},
            selected: null,
            list: state.insurees.list?.length > 0 ? state.insurees.list : [],
            pagination: rootInitialState.insurance.insurees.pagination,
            filters: rootInitialState.insurance.insurees.filters,
        },
        policies: {
            ...state.policies,
            search: {},
            selected: null,
            list: state.policies.list?.length > 0 ? state.policies.list : [],
            pagination: rootInitialState.insurance.policies.pagination,
            filters: rootInitialState.insurance.policies.filters,
        },
        claims: {
            ...state.claims,
            search: {},
            selected: null,
            list: state.claims.list?.length > 0 ? state.claims.list : [],
            pagination: rootInitialState.insurance.claims.pagination,
            filters: rootInitialState.insurance.claims.filters,
        },
        quotes: {
            ...state.quotes,
            search: {},
            selected: null,
            list: state.quotes.list?.length > 0 ? state.quotes.list : [],
            pagination: rootInitialState.insurance.quotes.pagination,
            filters: rootInitialState.insurance.quotes.filters,
        },
        assets: {
            ...state.assets,
            search: {},
            selected: null,
            list: state.assets.list?.length > 0 ? state.assets.list : [],
            pagination: rootInitialState.insurance.assets.pagination,
            filters: rootInitialState.insurance.assets.filters,
        },
        payments: {
            ...state.payments,
            search: {},
            selected: null,
            list: state.payments.list?.length > 0 ? state.payments.list : [],
            pagination: rootInitialState.insurance.payments.pagination,
            filters: rootInitialState.insurance.payments.filters,
        },
    };
};

/**
 * Sync Insurees
 * @param {*} state
 * @param {*} action
 */
const syncInsurees = (state, action) => {
    const payload = action.payload.data;
    const elements = payload.elements;

    return {
        ...state,
        insurees: {
            ...state.insurees,
            list: [...elements],
        },
    };
};

/**
 * Sync Search
 * @param {*} state
 * @param {*} action
 */
const syncInsureesSearch = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        insurees: {
            ...state.insurees,
            search: {
                ...payload,
            },
        },
    };
};

/**
 * Clear Search
 * @param {*} state
 * @param {*} action
 */
const clearInsureesSearch = (state, action) => {
    return {
        ...state,
        insurees: {
            ...state.insurees,
            search: {},
            list: [],
        },
    };
};

/**
 * Sync Data By type
 * @param {*} state
 * @param {*} action
 */
const syncDataBytype = (state, action) => {
    const payload = action.payload.data;
    const type = action.payload.type;

    if (payload.elements.length === 0) {
        return { ...state };
    }

    const elements = payload.elements.elements;
    const total = payload.elements.total;
    const current = state[type].pagination.current;

    return {
        ...state,
        [type]: {
            ...state[type],
            list: elements.length ? [...elements] : [],
            pagination: {
                ...state[type].pagination,
                current: current === '--' ? 1 : current,
                total: Math.ceil(total / state[type].chunk),
            },
        },
    };
};

const insuranceSyncPaginate = (state, action) => {
    const type = action.payload.type;
    const payload = action.payload.data;
    const elements = payload.elements;

    let current = state[type].pagination.current;

    switch (payload.action) {
        case 'prev':
            current = current > 1 ? current - 1 : 1;
            break;
        case 'next':
            current = current + 1;
            break;
        case 'start':
            current = 1;
            break;
        case 'end':
            current = state[type].pagination.total;
            break;
        case 'same':
            current = current === '--' ? 1 : current;
            break;

        default:
            break;
    }

    return {
        ...state,
        [type]: {
            ...state[type],
            pagination: {
                ...state[type].pagination,
                current: current,
                next: payload.action !== 'end' ? elements.length >= state[type].chunk : null,
                page_start_index: payload.start,
            },
            list: [...elements],
            dynamicStates: payload.dynamicStates ? payload.dynamicStates : [],
        },
    };
};

/**
 * Sync Search By type
 * @param {*} state
 * @param {*} action
 */
const syncSearchByType = (state, action) => {
    const payload = action.payload.data;
    const type = action.payload.type;

    return {
        ...state,
        [type]: {
            ...state[type],
            search: {
                ...payload,
            },
        },
    };
};

/**
 * Clear Search By type
 * @param {*} state
 * @param {*} action
 */
const clearSearchByType = (state, action) => {
    const type = action.payload.type;

    return {
        ...state,
        [type]: {
            ...state[type],
            search: {},
            // list: [],
        },
    };
};

export default reducer;
