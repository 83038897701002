import { connect } from 'react-redux';
import Quotes from 'pages/Insurance/Quotes';
import { hasProjectSelected } from 'redux/domains/insurance/selector';
import {
    insuranceOnSearchByTypeThunk,
    insuranceSearchPaginateThunk,
    insuranceSortDataThunk,
    insuranceClearSearchByTypeThunk,
} from 'redux/domains/insurance/thunk';
import { withRouter } from 'react-router';
// import { insuranceClearSearchByTypeAction } from 'redux/domains/insurance/actions';

const mapStateToProps = (state, ownProps) => ({
    texts: state.texts,
    isLoading: state.app.isLoading,
    data: state.insurance.quotes,
    hasProjectSelected: hasProjectSelected(state),
    ownProps: ownProps,
    projectId: state.insurance.filters.projects.selected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchData: () => insuranceOnSearchByTypeThunk(dispatch, null, 'quotes', true),
    onSearch: data => insuranceOnSearchByTypeThunk(dispatch, data, 'quotes'),
    // onClearSearch: () => dispatch(insuranceClearSearchByTypeAction('quotes')),
    onClearSearch: () => insuranceClearSearchByTypeThunk(dispatch, 'quotes'),
    onClickPrev: data => insuranceSearchPaginateThunk(dispatch, data, 'quotes', 'prev', true),
    onClickNext: data => insuranceSearchPaginateThunk(dispatch, data, 'quotes', 'next', true),
    onClickStart: data => insuranceSearchPaginateThunk(dispatch, data, 'quotes', 'start', true),
    onClickEnd: data => insuranceSearchPaginateThunk(dispatch, data, 'quotes', 'end', true),
    onSort: data => insuranceSortDataThunk(dispatch, data, 'quotes'),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Quotes));
