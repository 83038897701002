/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';
// import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Blockly from 'blockly';
import './Base.css';
import ReactBlocklyComponent from 'react-blockly';
import { blockly as MuzzBlockly } from './MuzzleyLogic';
import { getAccessToken } from 'services/localStorage';
import Loading from 'shared/Loading';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { getAPIBaseURL } from 'utils/functions';

export class SelfcareBlockly extends React.PureComponent {
    state = {
        init: false,
        isLoading: true,
        isLoaded: false,
        toolboxCategories: [{}],
        xml: null,
        code: null,
        originalXml: null,
    };

    static propTypes = {
        canSave: PropTypes.func,
        data: PropTypes.string,
    };

    componentDidMount = () => {
        this.handleWrapperSize();

        const access_token = getAccessToken();

        Blockly.Variables.allUsedVarModels = workspace => [];

        /* Define API endpoints once globally */

        const baseURL = getAPIBaseURL();
        // MuzzBlockly.isLoading = true;
        MuzzBlockly.apiEndpoints = {
            'get-classes': `${baseURL}/v3/classes?type={type}&fields=id&page_size=${DEFAULT_PAGE_SIZE}`,
            'get-operators': `${baseURL}/v3/utils/usecase-operators`,
            'get-components-classes': `${baseURL}/v3/classes?type=component&fields=id&page_size=${DEFAULT_PAGE_SIZE}`,
            'get-properties-classes': `${baseURL}/v3/classes?type=property&fields=id&page_size=${DEFAULT_PAGE_SIZE}`,
        };

        MuzzBlockly.auth.access_token = access_token;

        MuzzBlockly.ui.ready(toolboxCategories => {
            this.setState(
                {
                    isLoaded: true,
                    originalXml: this.props.data,
                    toolboxCategories,
                },
                () => {
                    // if (this.props.data) {
                    //     MuzzBlockly.methods.loadBlocks(this.props.data);
                    // }
                },
            );
        });

        window.setTimeout(() => {
            MuzzBlockly.trigger(MuzzBlockly.api.getComponentsClasses);
        }, 2000);
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState(
                state => ({
                    canSave: false,
                    originalXml: this.props.data,
                }),
                () => {
                    const payload = {
                        cdata: {
                            blockly: this.state.xml,
                        },
                        usecase: {
                            procedure: this.state.code,
                        },
                    };
                    this.props.canSave(false, payload);
                },
            );
        }

        // if (
        //     (!prevState.canSave && this.props.canSave) ||
        //     (prevProps.canSave && !this.props.canSave)
        // ) {
        //     const payload = {
        //         cdata: {
        //             blockly: this.state.xml,
        //         },
        //         usecase: {
        //             procedure: this.state.code,
        //         },
        //     };
        //     this.props.canSave(this.state.canSave, payload);
        // }
    }

    componentWillUnmount() {
        // MuzzBlockly.isLoading = false;
    }

    handleWrapperSize = () => {
        const modal = document.getElementById('usecaseBlocklyModal');
        const modalSize = modal.getBoundingClientRect();
        const wrapper = document.getElementById('blockly');
        // wrapper.style.width = `${modalSize.width - 30}px`;
        wrapper.style.height = `${modalSize.height - 150}px`;
    };

    // handleOnLoad = () => {
    //     this._editor = React.createElement(SelfcareBlockly);
    //     ReactDOM.render(this._editor, document.getElementById('blockly'));
    // };

    workspaceDidChange = workspace => {
        MuzzBlockly.ui.workspace = workspace;

        const newXml = Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(workspace));
        const newCode = Blockly.JavaScript.workspaceToCode(workspace);

        if (!this.state.init) {
            // if (this.props.data) {
            //     MuzzBlockly.methods.loadBlocks(this.props.data);
            // }

            this.setState(
                {
                    init: true,
                    canSave: false,
                    // originalXml: this.props.data ? this.props.data : newXml,
                    // xml: this.props.data,
                },
                () => {
                    // const payload = {
                    //     cdata: {
                    //         blockly: this.state.xml,
                    //     },
                    //     usecase: {
                    //         procedure: this.state.code,
                    //     },
                    // };
                    // this.props.canSave(this.state.canSave, payload);
                },
            );
        } else {
            this.setState(
                state => ({
                    xml: newXml ? newXml : this.props.data,
                    code: newCode,
                    // originalXml: this.props.data ? this.props.data : newXml,
                    canSave: newXml !== state.originalXml ? true : false,
                }),
                () => {
                    const payload = {
                        cdata: {
                            blockly: this.state.xml,
                        },
                        usecase: {
                            procedure: this.state.code,
                        },
                    };
                    this.props.canSave(this.state.canSave, payload);
                },
            );
        }
    };

    render() {
        const editor = React.createElement(ReactBlocklyComponent.BlocklyEditor, {
            toolboxCategories: this.state.toolboxCategories,
            workspaceConfiguration: {
                grid: {
                    spacing: 20,
                    length: 3,
                    colour: '#ccc',
                    snap: true,
                },
            },
            initialXml: this.state.originalXml,
            wrapperDivClassName: 'fill-height',
            workspaceDidChange: this.workspaceDidChange,
        });

        return (
            <div className="container">
                <div id="blockly">{this.state.isLoaded ? editor : <Loading />}</div>
            </div>
        );
    }
}

export default SelfcareBlockly;
