import React from 'react';
import Styles from './ErrorPageWithoutRouter.module.scss';

export class ErrorPageWithoutRouter extends React.PureComponent {
    render() {
        return (
            <div className={Styles.container}>
                <div className={Styles.col}>
                    <h1 data-test="ErrorPageCode">404</h1>
                </div>
                <div className={Styles.col}>
                    <div className={Styles.content}>
                        <h3 data-test="ErrorPageTitle">Page not found</h3>
                        <p data-test="ErrorPageSubtitle">
                            The page that you request could not be found.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default ErrorPageWithoutRouter;
