import {
    DATE_FORMAT_NO_TIME_INTERNATIONAL,
    INSURANCE_EXCLUDED_PROPERTY_CLASS,
    INSURANCE_MANDATORY_COVERAGE_PROPERTY_CLASS,
    INSURANCE_MANDATORY_PROPERTY_CLASS,
    INSURANCE_MEDIA_DOCUMENT_PROPERTY_CLASS,
    INSURANCE_MEDIA_PHOTO_PROPERTY_CLASS,
    INSURANCE_MEDIA_VIDEO_PROPERTY_CLASS,
} from 'utils/constants';
import {
    convertUTCtoLocalDateAndHoursString,
    formatDate,
    getProtectedAssetServiceEndpoints,
    groupArrayBy,
    isJson,
    isJsonAndParseIt,
    sortArrayByObjDateProperty,
} from 'utils/functions';

export const insureeViewModel = (payload, data) => {
    const insuree = insureeFieldsModel(payload);
    let policies = [];
    if (payload.policies) {
        policies = payload.policies.map(p => {
            const quote = quoteFieldsModel(p.quote);
            const claims = claimsFieldsModel(p.claims, p.quote);

            return {
                id: p.id,
                code: p.code,
                cdata: p.cdata,
                created: formatDate(p.created, false, DATE_FORMAT_NO_TIME_INTERNATIONAL, false),
                state: p.state,
                custom_state: p.custom_state,
                clientservice_id: p.clientservice_id,
                canceled_ts: p.canceled_ts,
                terminated_ts: p.terminated_ts,
                reason_id: p.reason_id,
                assets: getAssets(p.assets),
                claims: claims,
                quote: quote,
                policyDynamicStates: p.policyDynamicStates,
                insuree_id: insuree.insuree_id,
                updated: p.updated,
                user_id: insuree.user_id,
                ...quote.quote.policy_details,
            };
        });
    }

    let quotes = [];
    if (payload.quotes) {
        quotes = payload.quotes.map(q => {
            let quote = quoteFieldsModel(q);
            quote.insuree_id = insuree.insuree_id;
            quote.user_id = insuree.user_id;
            return quote;
        });
    }

    if (data && data.type !== 'insurees') {
        let policy = null;
        let rest = null;
        switch (data.type) {
            case 'policies':
                if (policies.length) {
                    policy = policies.filter(p => p.id === data.id);
                    rest = policies.filter(p => p.id !== data.id);
                    policies = policy ? [...policy, ...rest] : [...rest];
                }
                break;
            case 'claims':
                if (policies.length) {
                    policies.map(p => {
                        const claim = p.claims.filter(c => c.id === data.id);
                        const rest = p.claims.filter(c => c.id !== data.id);
                        const temp = [...claim, ...rest];

                        if (claim.length) {
                            policy = [
                                {
                                    ...p,
                                    claims: temp,
                                },
                            ];
                        }

                        return p;
                    });
                    rest = policy && policy[0] ? policies.filter(p => p.id !== policy[0].id) : [];
                    policies = policy ? [...policy, ...rest] : [...rest];
                }
                break;
            case 'quotes':
                if (policies.length) {
                    policy = policies.filter(p => p.quote.id === data.id);
                    rest = policies.filter(p => p.quote.id !== data.id);
                    policies = policy ? [...policy, ...rest] : [...rest];
                }
                if (!policy && quotes.length) {
                    policy = quotes.filter(q => q.quote.id === data.id);
                    rest = quotes.filter(q => q.quote.id !== data.id);
                    quotes = policy ? [...policy, ...rest] : [...rest];
                }
                break;
            case 'quote-protected-assets':
                if (policies.length) {
                    policies.map(p => {
                        const asset = p.quote.quote.protected_assets.filter(c => c.id === data.id);

                        if (asset.length) {
                            policy = [
                                {
                                    ...p,
                                },
                            ];
                        }

                        return p;
                    });
                    rest = policy && policy[0] ? policies.filter(p => p.id !== policy[0].id) : [];
                    policies = policy ? [...policy, ...rest] : [...rest];
                }
                if ((!policy || !policy.length) && quotes.length) {
                    quotes.map(q => {
                        const asset = q.quote.protected_assets.filter(c => c.id === data.id);

                        if (asset.length) {
                            policy = [
                                {
                                    ...q,
                                },
                            ];
                        }

                        return q;
                    });
                    rest =
                        policy && policy[0] ? quotes.filter(q => q.quote.id !== policy[0].id) : [];
                    quotes = policy ? [...policy, ...rest] : [...rest];
                }
                break;

            default:
                break;
        }
    }

    return {
        insuree: insuree,
        policies: policies,
        quotes: quotes,
    };
};

const insureeFieldsModel = payload => {
    if (!payload || !payload.insuree || !payload.insuree.properties) {
        return {};
    }
    let insuree = {
        insuree_id: payload?.insuree?.id ? payload?.insuree?.id : null,
        user_id: payload?.insuree?.user_id ? payload?.insuree?.user_id : null,
        info: null,
        distributor: payload?.distributor ? { data: payload.distributor } : null,
        address: {
            address: { label: 'Street', data: null },
            complement: { label: 'Complement', data: null },
            postal_code: { label: 'Postal Code', data: null },
            city: { label: 'City', data: null },
            district: { label: 'District', data: null },
            region: { label: 'Region', data: null },
            country_state: { label: 'State', data: null },
            country: { label: 'Country', data: null },
        },
        user: {
            mobile: payload?.user?.mobile ? { data: payload.user.mobile } : null,
            email: payload?.user?.email ? { data: payload.user.email } : null,
            partner_code: payload?.user?.partner_code ? { data: payload.user.partner_code } : null,
            flags: payload?.user?.flags ? { data: payload.user.flags } : null,
        },
    };

    const addressFields = [
        'address',
        'complement',
        'postal_code',
        'city',
        'district',
        'region',
        'country_state',
        'country',
    ];

    Object.entries(payload.insuree.properties).forEach(([key, prop]) => {
        Object.entries(prop).forEach(([key, value]) => {
            if (key === 'namespace') {
                if (addressFields.includes(value)) {
                    insuree.address[value] = prop;
                } else {
                    if (
                        prop?.label &&
                        prop?.classes &&
                        !prop.classes.includes(INSURANCE_EXCLUDED_PROPERTY_CLASS)
                    ) {
                        insuree.info = {
                            ...insuree.info,
                            [value]: prop,
                        };
                    }
                }
            }
        });
    });
    return insuree;
};

const getPropLabelByData = prop => {
    if (prop?.claimpropertyspec?.options) {
        const option = prop.claimpropertyspec.options.filter(option => option.data === prop.data);

        if (option && option[0] && option[0]?.label) {
            return option[0].label;
        } else {
            return prop.data;
        }
    }

    return null;
};

const claimsFieldsModel = (data, quote) => {
    if (!data || data.length === 0) {
        return [];
    }

    const claims = data.map(c => {
        let claim = {
            id: c.id,
            code: c.code,
            claimPossibleStates: c.claimPossibleStates,
            custom_state: c.custom_state,
            state: c.state !== 'custom' ? c.state : c.custom_state,
            submitted_ts: formatDate(
                c.submitted_ts,
                false,
                DATE_FORMAT_NO_TIME_INTERNATIONAL,
                false,
            ),
            created: formatDate(c.created, false, DATE_FORMAT_NO_TIME_INTERNATIONAL, false),
            // details: {},
            editable:
                c.properties?.filter(p => p.namespace === 'claim_requested_amount').length === 0
                    ? false
                    : true,
            fields: {
                claim_requested_amount: null,
                claim_negotiated_amount: null,
                claim_payable_to: null,
                claim_approved_amount: null,
                protected_asset_covered_value: null,
            },
            media: {
                images: null,
                video: null,
                documents: null,
                damages: null,
            },
            assets: getClaimAssets(c.assets, c.properties),
            damages: {
                grid: null,
                selected: null,
            },
            location: null,
            info: null,
            service: quote?.service?.namespace,
        };

        if (c.properties && Object.entries(c.properties).length > 0) {
            for (let i = 0; i < c.properties.length; i++) {
                const prop = c.properties[i];
                Object.entries(prop).forEach(([key, value]) => {
                    if (prop.classes.includes('io.habit.claim.approved_amount')) {
                        claim.fields.claim_approved_amount = prop.data;
                    }

                    if (key === 'namespace') {
                        switch (value) {
                            case 'claim_incident_car_part':
                                claim.damages = getClaimDamages(prop);
                                break;
                            case 'claim_incident_video':
                                claim.media.video = prop;
                                break;
                            case 'claim_requested_amount':
                                claim.fields.claim_requested_amount = prop.data;
                                break;
                            case 'claim_negotiated_amount':
                                claim.fields.claim_negotiated_amount = prop.data;
                                break;
                            case 'claim_payable_to':
                                claim.fields.claim_payable_to = prop.data;
                                break;
                            // case 'claim_approved_amount':
                            //     claim.fields.claim_approved_amount = prop.data;
                            //     break;

                            default:
                                if (value === 'protected_asset_covered_value') {
                                    // add it here but also to properties
                                    claim.fields.protected_asset_covered_value = prop.data;
                                }
                                if (
                                    prop?.label &&
                                    prop?.classes &&
                                    !prop.classes.includes(INSURANCE_EXCLUDED_PROPERTY_CLASS)
                                ) {
                                    const formatedPropData = formatPropData(prop);

                                    if (formatedPropData !== -1) {
                                        claim.info = {
                                            ...claim.info,
                                            [value]: {
                                                ...prop,
                                                data: formatedPropData,
                                            },
                                        };
                                    }
                                }
                                break;
                        }
                    }
                });
            }
        }

        if (claim.info != null && Object.entries(claim.info).length > 0) {
            let info = claim.info;

            info = Object.keys(info).map(function (key) {
                return info[key];
            });

            return {
                ...claim,
                info: info.sort((a, b) => (a.order_index > b.order_index ? 1 : -1)),
            };
        }

        return claim;
    });

    return claims;
};

const quoteFieldsModel = data => {
    if (!data || Object.entries(data).length === 0) {
        return {};
    }

    let quote = {
        id: data.id,

        quote: {
            id: data.id,
            policy_details: {
                name: data.service.name,
                namespace: data.service.namespace,
                icon: getQuoteIcon(data.service.namespace),
                policy_id: data.policy_id,
                policy_start_date: null,
                policy_end_date: null,
            },
            code: data.code,
            state: data.state,
            name: data.service.name,
            icon: getQuoteIcon(data.service.namespace),
            simulated_ts: data.simulated_ts
                ? formatDate(data.simulated_ts, false, DATE_FORMAT_NO_TIME_INTERNATIONAL, false)
                : null,
            protected_assets: [],
            info: null,
            coverages: {
                mandatory: [],
                optional: [],
            },
            assets: getAssets(data.assets),
            service_id: data.service_id,
        },
    };

    const policyFields = ['policy_start_date', 'policy_end_date', 'policyDynamicStates'];

    /**
     * Quote properties
     */
    if (data.properties && Object.entries(data.properties).length > 0) {
        for (let i = 0; i < data.properties.length; i++) {
            const prop = data.properties[i];
            Object.entries(prop).forEach(([key, value]) => {
                if (key === 'namespace') {
                    if (policyFields.includes(value)) {
                        let dateOnly = prop.data;
                        if (prop.data != null && prop.data.includes('T')) {
                            dateOnly = prop.data.split('T')[0];
                        }
                        quote.quote.policy_details[value] = dateOnly
                            ? formatDate(dateOnly, false, DATE_FORMAT_NO_TIME_INTERNATIONAL, false)
                            : null;
                    } else {
                        if (
                            prop?.label &&
                            prop?.classes &&
                            !prop.classes.includes(INSURANCE_EXCLUDED_PROPERTY_CLASS)
                        ) {
                            quote.quote.info = {
                                ...quote.quote.info,
                                [value]: prop,
                            };
                        }
                    }
                }
            });
        }
    }

    /**
     * Quote coverages
     */
    if (data.coverages && Object.entries(data.coverages).length > 0) {
        for (let i = 0; i < data.coverages.length; i++) {
            const coverage = data.coverages[i];
            for (let i = 0; i < coverage.properties.length; i++) {
                const prop = coverage.properties[i];
                const coverageGroup = {
                    id: coverage?.enabledservicecoverage?.enabledcoverage?.coverage?.id || null,
                    name:
                        coverage?.enabledservicecoverage?.enabledcoverage?.coverage?.name || 'N/D',
                };

                Object.entries(prop).forEach(([key, value]) => {
                    if (key === 'namespace') {
                        if (
                            prop?.label &&
                            prop?.classes &&
                            !prop.classes.includes(INSURANCE_EXCLUDED_PROPERTY_CLASS) &&
                            coverage?.classes &&
                            !coverage.classes.includes(INSURANCE_EXCLUDED_PROPERTY_CLASS)
                        ) {
                            if (
                                coverage.classes.includes(
                                    INSURANCE_MANDATORY_COVERAGE_PROPERTY_CLASS,
                                )
                            ) {
                                quote.quote.coverages.mandatory = [
                                    ...quote.quote.coverages.mandatory,
                                    {
                                        ...coverageGroup,
                                        prop: prop,
                                    },
                                ];
                            } else {
                                quote.quote.coverages.optional = [
                                    ...quote.quote.coverages.optional,
                                    {
                                        ...coverageGroup,
                                        prop: prop,
                                    },
                                ];
                            }
                        }
                    }
                });
            }
        }

        /**
         * TODO: investigate if this is the best solution
         */
        quote.quote.coverages.mandatory = groupArrayBy(quote.quote.coverages.mandatory, 'id');
        quote.quote.coverages.optional = groupArrayBy(quote.quote.coverages.optional, 'id');
    }

    /**
     * Quote protected assets
     */
    if (data.protected_assets && Object.entries(data.protected_assets).length > 0) {
        for (let i = 0; i < data.protected_assets.length; i++) {
            const asset = data.protected_assets[i];
            let protected_asset = null;

            if (data.service.namespace.indexOf('_car_') !== -1) {
                protected_asset = formatProtectedAssetForCars(data, asset);
            } else {
                protected_asset = formatProtectedAssetHybrid(data, asset);
            }

            if (protected_asset && Object.entries(protected_asset).length > 0) {
                quote.quote.protected_assets = [
                    ...quote.quote.protected_assets,
                    { id: data.id, ...protected_asset },
                ];
            }
        }
    }

    return quote;
};

const formatProtectedAssetHybrid = (data, asset) => {
    let protected_asset = {
        id: asset.id,
        code: asset.code ? asset.code : null,
        mandatory: null,
        other: null,
        eligible: {
            label: 'Eligible',
            data: null,
            description: null,
        },
        assets: getProtectedAssetAssets(asset.assets, asset.properties),
    };

    for (let i = 0; i < asset.properties.length; i++) {
        const prop = asset.properties[i];

        Object.entries(prop).forEach(([key, value]) => {
            if (key === 'namespace') {
                switch (value) {
                    case 'eligible':
                        const selected =
                            prop?.data && prop?.options
                                ? prop.options.filter(i => i.data === prop.data)
                                : null;

                        protected_asset.eligible = {
                            ...protected_asset.eligible,
                            data:
                                selected && selected[0]
                                    ? {
                                          id: selected[0].data,
                                          text: selected[0].label,
                                          value: selected[0].data,
                                      }
                                    : null,
                            options: prop.options.length > 0 ? formatPropOptions(prop) : [],
                        };
                        break;

                    case 'eligible_reason':
                        protected_asset.eligible = {
                            ...protected_asset.eligible,
                            description: prop.data || null,
                        };
                        break;

                    default:
                        if (prop?.label) {
                            if (
                                prop?.classes &&
                                !prop.classes.includes(INSURANCE_EXCLUDED_PROPERTY_CLASS) &&
                                prop.classes.includes(INSURANCE_MANDATORY_PROPERTY_CLASS)
                            ) {
                                protected_asset.mandatory = {
                                    ...protected_asset.mandatory,
                                    [value]: prop,
                                };
                            } else {
                                protected_asset.other = {
                                    ...protected_asset.other,
                                    [value]: prop,
                                };
                            }
                        }

                        break;
                }
            }
        });
    }

    return protected_asset;
};

const formatProtectedAssetForCars = (data, asset) => {
    let protected_asset = {
        id: asset.id,
        code: asset.code ? asset.code : null,
        info: {
            car_make: null,
            car_model: null,
            car_trim: null,
            car_year: null,
            car_plate: null,
            car_value: null,
        },
        obd_remote_id: null,
        obd_postalcode: null,
        obd_country: null,
        obd_city: null,
        obd_address: null,
        type: null,
        vin: null,
        connected: false,
        tracking_method: null,
        odometer_details: null,
        service: {
            policy_id: data.policy_id,
            asset_id: asset.id,
            channel_id: data?.client_service?.channel ? data.client_service.channel.id : null,
            endpoints: getProtectedAssetServiceEndpoints(),
            data: {
                requested_on: null,
                sent_on: null,
                cost_month: '--',
                distance_month: '--',
            },
        },
        mandatory: null,
        other: null,
        assets: getProtectedAssetAssets(asset.assets, asset.properties),
    };

    for (let i = 0; i < asset.properties.length; i++) {
        const prop = asset.properties[i];

        Object.entries(prop).forEach(([key, value]) => {
            if (key === 'namespace') {
                switch (value) {
                    case 'tracking_method':
                        protected_asset.tracking_method = prop && prop.data ? prop.data : null;
                        break;
                    case 'obd_remote_id':
                        protected_asset.obd_remote_id = prop;
                        if (prop.data) {
                            protected_asset.service.data.sent_on = formatDate(
                                prop.updated,
                                false,
                                DATE_FORMAT_NO_TIME_INTERNATIONAL,
                                false,
                            );
                        }
                        break;
                    case 'obd_postalcode':
                        protected_asset.obd_postalcode = prop;
                        break;
                    case 'obd_country':
                        protected_asset.obd_country = prop;
                        break;
                    case 'obd_city':
                        protected_asset.obd_city = prop;
                        break;
                    case 'obd_address':
                        protected_asset.obd_address = prop;
                        if (prop.data) {
                            protected_asset.service.data.requested_on = formatDate(
                                prop.updated,
                                false,
                                DATE_FORMAT_NO_TIME_INTERNATIONAL,
                                false,
                            );
                        }
                        break;
                    case 'odometer_details':
                        protected_asset.odometer_details =
                            prop?.data && isJson(prop.data) ? JSON.parse(prop.data) : null;
                        break;

                    default:
                        if (prop?.label) {
                            if (
                                prop?.classes &&
                                !prop.classes.includes(INSURANCE_EXCLUDED_PROPERTY_CLASS) &&
                                prop.classes.includes(INSURANCE_MANDATORY_PROPERTY_CLASS)
                            ) {
                                protected_asset.mandatory = {
                                    ...protected_asset.mandatory,
                                    [value]: prop,
                                };
                            } else {
                                protected_asset.other = {
                                    ...protected_asset.other,
                                    [value]: prop,
                                };
                            }
                        }

                        break;
                }
            }
        });
    }

    const final_protected_asset = mapProtectedAssetFields(protected_asset);

    return final_protected_asset;
};

const mapProtectedAssetFields = asset => {
    if (!asset || !asset.car_year || !window.carSpecs) {
        return asset;
    }

    const car = window.carSpecs.years[asset.car_year.data];
    if (!car) {
        return asset;
    }

    const temp = {};
    temp.car_make = car.filter(s => s.namespace === asset.car_make.data)[0];
    if (!temp.car_make || temp.car_make.models) {
        return asset;
    }
    temp.car_model = temp.car_make.models.filter(s => s.namespace === asset.car_model.data)[0];
    if (!temp.car_model || temp.car_model.trims) {
        return asset;
    }
    temp.car_trim = temp.car_model.trims.filter(s => s.namespace === asset.car_trim.data)[0];

    Object.entries(temp).forEach(([key, value]) => {
        asset = {
            ...asset,
            [key]: {
                ...asset[key],
                data: value && value.label ? value.label : '--',
            },
        };
    });

    return asset;
};

const getQuoteIcon = namespace => {
    if (!namespace) return null;

    if (namespace.indexOf('_car_') !== -1 || namespace.indexOf('v1_car') !== -1) {
        return 'icon-icon_car';
    }
    if (namespace.indexOf('_home_') !== -1 || namespace.indexOf('v1_home') !== -1) {
        return 'icon-icon_home1';
    }
    if (namespace.indexOf('_pet_') !== -1 || namespace.indexOf('v1_pet') !== -1) {
        return 'icon-icon_pets';
    }
    if (namespace.indexOf('_phone_') !== -1 || namespace.indexOf('v1_phone') !== -1) {
        return 'icon-icon_apps';
    }
    return 'icon-icon_insurance';
};

export const getInsuranceSearchPayload = (data, type) => {
    let payload = [];

    switch (type) {
        case 'insurees':
            payload = [
                ...payload,
                {
                    classes: ['io.habit.operations.fields.identifier'],
                    type: 'not-null',
                },
            ];
            break;
        case 'assets':
            payload = [
                ...payload,
                {
                    classes: ['io.habit.operations.fields.identifier'],
                    type: 'not-null',
                },
            ];
            break;
        case 'policies':
            payload = [
                ...payload,
                {
                    namespace: 'code',
                    type: 'not-null',
                },
            ];
            break;
        case 'quotes':
            payload = [
                ...payload,
                {
                    namespace: 'code',
                    type: 'not-null',
                },
                {
                    namespace: 'state',
                    type: 'exclude',
                    data: 'draft',
                },
                {
                    namespace: 'state',
                    type: 'exclude',
                    data: 'open',
                },
            ];
            break;
        case 'claims':
            payload = [
                ...payload,
                {
                    namespace: 'claim_number',
                    type: 'not-null',
                },
                {
                    namespace: 'status',
                    type: 'exclude',
                    data: 'draft',
                },
                {
                    namespace: 'status',
                    type: 'exclude',
                    data: 'open',
                },
            ];
            break;
        case 'payments':
            payload = [
                ...payload,
                {
                    namespace: 'policy_code',
                    type: 'not-null',
                },
            ];
            break;
        case 'billing':
            payload = [
                ...payload,
                {
                    namespace: 'clientservice_id',
                    type: 'equals',
                    data: data.clientservice_id,
                },
            ];
            break;
        default:
            break;
    }

    const getSearchType = (type, { identifier }) => {
        switch (type) {
            case 'policies':
                return 'equals';
            case 'insurees':
                if (identifier) {
                    return 'equals';
                }
                return 'icontains';
            default:
                return 'icontains';
        }
    };

    if (data && data.fields) {
        Object.entries(data.fields).forEach(([key, value]) => {
            if (value && value !== '') {
                let field = {};

                if (data.map[key]) {
                    field = {
                        classes: data.map[key],
                        type: getSearchType(type, { [key]: value }),
                        data: value,
                    };
                } else {
                    if (
                        typeof value === 'object' &&
                        value !== null &&
                        value.hasOwnProperty('namespace')
                    ) {
                        field = {
                            namespace: value.namespace,
                            type: value.type,
                            data: value.data,
                        };
                    } else {
                        field = {
                            namespace: key,
                            type: getSearchType(type, { [key]: value }),
                            data: value,
                        };
                    }
                }

                payload = [...payload, field];
            }
        });
    }

    return payload;
};

export const formatInsurancePaymentsPayload = data => {
    const formated = data.map(payment => {
        return {
            id: payment.id,
            type: getPaymentRateType(payment?.cdata?.type ? payment.cdata.type : null),
            amount: `${payment.amount} ${payment.currency.symbol}`,
            payday: formatDate(payment.created, false, DATE_FORMAT_NO_TIME_INTERNATIONAL, false),
            status: payment.state,
            style: payment.state.toLowerCase(),
        };
    });

    return formated;
};

const getPaymentRateType = type => {
    switch (type) {
        case 'rate_base':
            return 'BASE RATE';
        case 'rate_chunk':
            return 'Extra miles';
        case 'rate_coverage':
            return 'Coverage';

        default:
            return 'N/D';
    }
};

const getClaimDamages = damages => {
    const selected = damages?.data ? damages.data.split(',') : null;

    return {
        grid: damages.options,
        selected: selected,
    };
};

export const formatInsuranceBillingsPayload = data => {
    const formated = data.map(payment => {
        return {
            id: payment.id,
            state: payment.state,
            start_date: payment.start_date,
            payment_type: payment.payment_type,
            amount: payment.amount,
            amount_paid: payment?.cdata?.amount_paid ? payment.cdata.amount_paid : null,
            assets: payment.assets,
            hasNotice: handleHasDocument(payment.assets, 'notice'),
            hasInvoice: handleHasDocument(payment.assets, 'invoice'),
        };
    });

    return formated;
};

const handleHasDocument = (data, type) => {
    let hasIt = false;
    data.map(asset => {
        const search = asset.tags.filter(t => t.search(`.${type}`) !== -1);
        if (search && search[0]) {
            hasIt = search[0];
        }
        return asset;
    });

    return hasIt;
};

const getClaimAssets = (assets, props) => {
    // TODO: temporary fix android app videos
    let temp = [];
    const find = props.filter(p => p.namespace === 'claim_incident_video');
    if (find && find.length > 0) {
        for (let i = 0; i < find.length; i++) {
            const item = find[i];
            if (item.hasOwnProperty('data') && item.data !== '') {
                const uri = item.data ? item.data.replace('_icon.png', '') : null;
                const description = uri ? uri.split('/') : null;
                temp = [
                    ...temp,
                    {
                        ...item,
                        classes: uri ? [INSURANCE_MEDIA_VIDEO_PROPERTY_CLASS] : [...item.classes],
                        description: description ? description[description.length - 1] : null,
                        data: uri,
                        uri: uri,
                        type: 'video',
                    },
                ];
            }
        }
    }

    const images = assets.filter(
        a =>
            a.classes &&
            !a.classes.includes(INSURANCE_EXCLUDED_PROPERTY_CLASS) &&
            a.classes.includes(INSURANCE_MEDIA_PHOTO_PROPERTY_CLASS),
    );
    const videos = assets.filter(
        a =>
            a.classes &&
            !a.classes.includes(INSURANCE_EXCLUDED_PROPERTY_CLASS) &&
            a.classes.includes(INSURANCE_MEDIA_VIDEO_PROPERTY_CLASS),
    );
    const documents = assets.filter(
        a =>
            a.classes &&
            !a.classes.includes(INSURANCE_EXCLUDED_PROPERTY_CLASS) &&
            a.classes.includes(INSURANCE_MEDIA_DOCUMENT_PROPERTY_CLASS),
    );

    return {
        images: sortArrayByObjDateProperty([...images], 'created'),
        videos: sortArrayByObjDateProperty([...videos, ...temp], 'created'),
        imagesVideos: sortArrayByObjDateProperty([...images, ...videos, ...temp], 'created'),
        documents: sortArrayByObjDateProperty([...documents], 'created'),
        // images: images,
        // documents: documents,
    };
};

const getAssets = assets => {
    // const images = assets.filter(
    //     a => a.classes && a.classes[0] && a.classes[0].indexOf('.photo') !== -1,
    // );
    // const videos = assets.filter(
    //     a => a.classes && a.classes[0] && a.classes[0].indexOf('.video') !== -1,
    // );
    // const documents = assets.filter(
    //     a => a.classes && a.classes[0] && a.classes[0].indexOf('.document') !== -1,
    // );

    const images = assets.filter(
        a =>
            a.classes &&
            !a.classes.includes(INSURANCE_EXCLUDED_PROPERTY_CLASS) &&
            a.classes.includes(INSURANCE_MEDIA_PHOTO_PROPERTY_CLASS),
    );
    const videos = assets.filter(
        a =>
            a.classes &&
            !a.classes.includes(INSURANCE_EXCLUDED_PROPERTY_CLASS) &&
            a.classes.includes(INSURANCE_MEDIA_VIDEO_PROPERTY_CLASS),
    );
    const documents = assets.filter(
        a =>
            a.classes &&
            !a.classes.includes(INSURANCE_EXCLUDED_PROPERTY_CLASS) &&
            a.classes.includes(INSURANCE_MEDIA_DOCUMENT_PROPERTY_CLASS),
    );

    return {
        images: sortArrayByObjDateProperty([...images], 'created'),
        videos: sortArrayByObjDateProperty([...videos], 'created'),
        imagesVideos: sortArrayByObjDateProperty([...images, ...videos], 'created'),
        documents: sortArrayByObjDateProperty([...documents], 'created'),
    };
};

const getProtectedAssetAssets = assets => {
    // const images = assets.filter(
    //     a => a.classes && a.classes[0] && a.classes[0].indexOf('.photo') !== -1,
    // );
    // const videos = assets.filter(
    //     a => a.classes && a.classes[0] && a.classes[0].indexOf('.video') !== -1,
    // );
    // const documents = assets.filter(
    //     a => a.classes && a.classes[0] && a.classes[0].indexOf('.document') !== -1,
    // );

    const images = assets.filter(
        a =>
            a.classes &&
            !a.classes.includes(INSURANCE_EXCLUDED_PROPERTY_CLASS) &&
            a.classes.includes(INSURANCE_MEDIA_PHOTO_PROPERTY_CLASS),
    );
    const videos = assets.filter(
        a =>
            a.classes &&
            !a.classes.includes(INSURANCE_EXCLUDED_PROPERTY_CLASS) &&
            a.classes.includes(INSURANCE_MEDIA_VIDEO_PROPERTY_CLASS),
    );
    const documents = assets.filter(
        a =>
            a.classes &&
            !a.classes.includes(INSURANCE_EXCLUDED_PROPERTY_CLASS) &&
            a.classes.includes(INSURANCE_MEDIA_DOCUMENT_PROPERTY_CLASS),
    );

    return {
        images: sortArrayByObjDateProperty([...images], 'created'),
        videos: sortArrayByObjDateProperty([...videos], 'created'),
        imagesVideos: sortArrayByObjDateProperty([...images, ...videos], 'created'),
        documents: sortArrayByObjDateProperty([...documents], 'created'),
    };
};

const formatPropData = prop => {
    if (!prop?.data) {
        return null;
    }

    switch (prop.schema) {
        case 'v2_free_text-1':
        case 'v1_string':
            return prop.data;
        case 'v2_single_option_select-1':
            return prop.data ? getPropLabelByData(prop) : null;
        case 'v2_date-1':
            return prop.data ? convertUTCtoLocalDateAndHoursString(prop.data, 'date_only') : null;
        case 'v2_location':
            if (prop.data && typeof prop.data === 'string') {
                const temp = prop.data.split(',');
                return {
                    address: null,
                    latitude: parseFloat(temp[0]).toFixed(6),
                    longitude: parseFloat(temp[1]).toFixed(6),
                };
                // return [parseFloat(temp[0]).toFixed(6), parseFloat(temp[1]).toFixed(6)];
            }
            return prop.data;
        case 'v2_address_coordinates':
            return isJson(prop.data) ? isJsonAndParseIt(prop.data) : null;
        case 'v2_network_entity-1':
            return isJson(prop.data) ? isJsonAndParseIt(prop.data)?.name : null;
        case 'v2_currency-2':
            return prop.data ? prop.data : null;
        case 'v2_single_file_upload':
        case 'v2_single_asset_upload':
            return -1;

        default:
            return prop.data;
    }
};

const formatPropOptions = prop => {
    if (!prop?.options) {
        return [];
    }

    switch (prop.schema) {
        case 'v2_single_option_select-1':
            return prop.options.length > 0
                ? prop.options.map(o => {
                      return {
                          id: o.data,
                          text: o.label,
                          value: o.data,
                      };
                  })
                : null;

        default:
            return prop.options.length > 0
                ? prop.options.map(o => {
                      return {
                          id: o.data,
                          text: o.label,
                          value: o.data,
                      };
                  })
                : null;
    }
};
