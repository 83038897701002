import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { lazy, Suspense } from 'react';
import ErrorBoundary from 'shared/ErrorBoundary';
import Loading from 'shared/Loading';
import SectionHeader from 'shared/SectionHeader';
import { INSURANCE_CLAIM_STATUS_LIST } from 'utils/constants';
import AdvancedSearch from './AdvancedSearch';
import Styles from './Claims.module.scss';

// import { isLocalEnv } from 'utils/functions';
import { doesCurrentKeyExistsInCurrentLanguage, getText } from 'localization';
import NoResults from 'shared/NoResults';

const Table = lazy(() => import('shared/Table'));

export class Claims extends React.PureComponent {
    state = {
        search: '',
        advancedSearch: {},
        isAdvancedSearch: false,
        advancedSearchStates: [],
        customSearchStates: [],
    };

    static propTypes = {
        texts: PropTypes.object,
        isLoading: PropTypes.bool,
        data: PropTypes.object,
        hasProjectSelected: PropTypes.bool,
        ownProps: PropTypes.object,
        onSearch: PropTypes.func,
        onClearSearch: PropTypes.func,
        fetchData: PropTypes.func,
        projectId: PropTypes.string,
        onClickPrev: PropTypes.func,
        onClickNext: PropTypes.func,
        onClickStart: PropTypes.func,
        onClickEnd: PropTypes.func,
        onSort: PropTypes.func,
        advancedSearchStates: PropTypes.array,
        customSearchStates: PropTypes.array,
    };

    componentDidMount() {
        const { data, ownProps, projectId, fetchData, onClearSearch } = this.props;
        const values = queryString.parse(ownProps.location.search);

        if (!ownProps?.match?.params?.app_id && projectId) {
            ownProps.history.replace(`${ownProps.location.pathname}/app/${projectId}`);
        }

        if (projectId && data && Object.entries(data.search).length > 0 && !values?.from) {
            onClearSearch && typeof onClearSearch === 'function' && onClearSearch();
        }

        if (projectId && data && Object.entries(data.search).length <= 1) {
            fetchData && typeof fetchData === 'function' && fetchData();
        }

        if (values && values.from && values.from === 'details') {
            this.setState({ isAdvancedSearch: false });
        }

        if (data.dynamicStates) {
            this.setState({
                advancedSearchStates: data.dynamicStates
                    ? data.dynamicStates
                    : INSURANCE_CLAIM_STATUS_LIST,
            });
        }

        if (JSON.stringify(this.state.advancedSearch) !== JSON.stringify(data.search)) {
            this.setState((state, props) => ({
                advancedSearch: {
                    ...props.data.search,
                    ...props.advancedSearchStates,
                },
            }));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { data, projectId, fetchData } = this.props;

        if (prevProps.projectId !== projectId) {
            fetchData && typeof fetchData === 'function' && fetchData();
            return;
        }

        if (
            prevProps.projectId === this.props.projectId &&
            data.dynamicStates !== undefined &&
            Object.keys(data.dynamicStates).length > 0 &&
            prevProps.data.dynamicStates !== data.dynamicStates
        ) {
            this.setState({
                advancedSearchStates: data.dynamicStates
                    ? data.dynamicStates.all
                    : INSURANCE_CLAIM_STATUS_LIST,
                customSearchStates: data.dynamicStates.custom ? data.dynamicStates.custom : [],
            });
        }

        data.list.forEach(claim => {
            if (claim.status === 'custom' && claim.custom_status) {
                claim.status = claim.custom_status.replace(/-/g, ' ');
            }
        });

        if (JSON.stringify(prevProps.data.search) !== JSON.stringify(data.search)) {
            this.setState({
                advancedSearch: {
                    ...this.props.data.search,
                },
            });
        }
    }

    handleOnSearch = value => {
        this.setState(
            {
                search: value.toUpperCase(),
            },
            () => {
                if (value !== '') {
                    this.props.onSearch({
                        id: this.props.projectId,
                        fields: { claim_number: this.state.search },
                        map: {},
                    });

                    this.handleCloseAdvancedSearch();
                }
            },
        );
    };

    handleOnClear = () => {
        this.setState(
            {
                search: '',
            },
            () => {
                this.props.onClearSearch();
            },
        );
    };

    handleOnToggleAdvancedSearch = () => {
        this.setState(state => ({
            isAdvancedSearch: !state.isAdvancedSearch,
        }));
    };

    handleCloseAdvancedSearch = () => {
        this.setState(state => ({
            isAdvancedSearch: false,
        }));
    };

    handleOnAdvancedSearchSubmit = data => {
        this.setState(
            {
                isAdvancedSearch: !data.closeSearch,
            },
            () => {
                this.props.onSearch({
                    id: this.props.projectId,
                    fields: data.fields,
                    map: data.map,
                });
            },
        );
    };

    handleOnClickPrev = e => {
        this.props.onClickPrev();
    };

    handleOnClickNext = e => {
        this.props.onClickNext();
    };

    handleOnClickStart = e => {
        this.props.onClickStart();
    };

    handleOnClickEnd = e => {
        this.props.onClickEnd();
    };

    render() {
        const { texts, data, isLoading, onClearSearch, projectId, onSort } = this.props;
        const { isAdvancedSearch } = this.state;

        return (
            <ErrorBoundary>
                <section data-test="Container" className={Styles.container}>
                    <SectionHeader
                        data-test="Header"
                        title={getText('claims_title')}
                        texts={texts}
                        action="insurance"
                        onSearch={this.handleOnSearch}
                        onClear={this.handleOnClear}
                        isAdvancedSearch={isAdvancedSearch}
                        onToggleAdvancedSearch={this.handleOnToggleAdvancedSearch}
                        searchPlaceholder={getText('search_by_claim_number')}
                    />

                    <div className={Styles.scrollContainer}>
                        {isAdvancedSearch && (
                            <AdvancedSearch
                                data-test="AdvancedSearch"
                                texts={texts}
                                isLoading={isLoading}
                                onAdvancedSearchSubmit={this.handleOnAdvancedSearchSubmit}
                                onClear={onClearSearch}
                                data={data.search}
                                advancedSearchStates={this.state.advancedSearchStates?.map(item => {
                                    return {
                                        ...item,
                                        text: doesCurrentKeyExistsInCurrentLanguage(
                                            `insurance_claims_${item.data}`.replace(/ /g, '-'),
                                        )
                                            ? getText(
                                                  `insurance_claims_${item.data}`.replace(
                                                      / /g,
                                                      '-',
                                                  ),
                                              )
                                            : item.data,
                                    };
                                })}
                                customSearchStates={this.state.customSearchStates}
                                onToggle={this.handleOnToggleAdvancedSearch}
                            />
                        )}

                        <Suspense fallback={<Loading theme="none" variant="sm" />}>
                            <ErrorBoundary>
                                {(isLoading || !projectId) && (
                                    <Loading
                                        data-test="Loading"
                                        theme="white"
                                        background="opacity"
                                        variant="sm"
                                        position="absolute"
                                    />
                                )}

                                {!isLoading && projectId && (
                                    <>
                                        {(!data?.list || data.list.length === 0) && <NoResults />}

                                        {data?.list && data.list.length > 0 && (
                                            <Table
                                                data-test="Table"
                                                section="claims"
                                                header={data.header}
                                                data={data.list}
                                                filters={data.filters}
                                                pagination={data.pagination}
                                                texts={texts}
                                                projectId={projectId}
                                                onClickPrev={this.handleOnClickPrev}
                                                onClickNext={this.handleOnClickNext}
                                                onClickStart={this.handleOnClickStart}
                                                onClickEnd={this.handleOnClickEnd}
                                                onSort={onSort}
                                                featureFlag={'insurance_claims'}
                                            />
                                        )}
                                    </>
                                )}
                            </ErrorBoundary>
                        </Suspense>
                    </div>
                </section>
            </ErrorBoundary>
        );
    }
}

export default Claims;
