import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { getElementPosition } from 'utils/functions';
import Styles from './Popup.module.scss';

export class Popup extends React.PureComponent {
    myRef = React.createRef();

    state = {
        position: null,
    };

    static propTypes = {
        className: PropTypes.string,
        id: PropTypes.string,
        position: PropTypes.oneOf([
            // Uses absolute/fixed position attributes
            'center',
            'top',
            'right',
            'bottom',
            'left',
            'bottom-left',
            'bottom-center',
            // Uses offsetCenter attributes
            'offsetCenter',
        ]),
        list: PropTypes.bool,
        active: PropTypes.bool,
        element: PropTypes.object,
        projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        onClickSetting: PropTypes.func,
        closePopup: PropTypes.func,
        content: PropTypes.element,
        size: PropTypes.oneOf(['sm', 'md', 'lg']),
        noPadding: PropTypes.bool,
        minWidth: PropTypes.number,
        maxWidth: PropTypes.number,
        isCustomPosition: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
        clickOutsideBehavior: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    };

    static defaultProps = {
        position: 'bottom',
        size: 'md',
        isCustomPosition: false,
        clickOutsideBehavior: 'default',
    };

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps?.active && this.props?.active) {
            this.handlePosition();
        }

        if (prevProps?.active && !this.props?.active) {
            this.setState({ position: null });
            document.removeEventListener('mouseup', this.handleClickOutside);
            document.removeEventListener('keydown', this.handleEscKey);
        }
    }

    handleClickOutside = evt => {
        if (this.props.clickOutsideBehavior === 'default') {
            const { id, closePopup, active } = this.props;
            const flyoutElement = document.getElementById(id);
            let targetElement = evt.target;

            do {
                if (targetElement === flyoutElement) {
                    return;
                }

                targetElement = targetElement.parentNode;
            } while (targetElement);

            closePopup && typeof closePopup === 'function' && active && closePopup();
        } else {
            this.props.clickOutsideBehavior(evt);
        }
    };

    handleEscKey = evt => {
        evt = evt || window.event;
        const { closePopup, active } = this.props;
        evt.keyCode === 27 &&
            closePopup &&
            typeof closePopup === 'function' &&
            active &&
            closePopup();
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.handleClickOutside);
        document.removeEventListener('keydown', this.handleEscKey);
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        if (this.props?.active) {
            this.handlePosition();
        }
    };

    render() {
        const { id, list, active, content, size, className, noPadding } = this.props;
        const { position } = this.state;

        if (!active) return null;

        return (
            <div
                data-testid="Popup"
                id={id}
                ref={this.myRef}
                className={classnames(
                    Styles.popup,
                    Styles[size],
                    active ? Styles?.active : null,
                    noPadding ? Styles.noPadding : null,
                    className ? Styles[className] : null,
                )}
                style={position}
            >
                {list && <ul>{content}</ul>}
                {!list && <>{content}</>}
            </div>
        );
    }

    handlePosition() {
        const { id, element, position, minWidth, content } = this.props;

        const contentAsElement = document.querySelector(`.${content.props.className}`);

        const popup = getElementPosition(document.getElementById(id));
        const popupWidth = minWidth
            ? minWidth
            : Math.round(popup.width) < element.width
            ? element.width
            : Math.round(popup.width);
        const popupHeight = Math.round(popup.height);

        let finalPosition = null;
        switch (position) {
            case 'offsetCenter':
                finalPosition = {
                    top: element.offsetTop - element.offsetHeight,
                    right: 'auto',
                    bottom: 'auto',
                    left: element.offsetLeft - contentAsElement?.offsetWidth,
                };
                break;

            case 'center':
                finalPosition = {
                    top: element.top - element.height - 140,
                    right: 'auto',
                    bottom: 'auto',
                    left: element.left - 140,
                };
                break;

            case 'top':
                finalPosition = {
                    top: element.top - element.height,
                    right: 'auto',
                    bottom: 'auto',
                    left: 'auto',
                };
                break;

            case 'right':
                finalPosition = {
                    top:
                        element.top + element.height + popupHeight <=
                        (window.innerHeight || document.documentElement.clientHeight)
                            ? element.top
                            : element.top - popupHeight,
                    right: 'auto',
                    bottom: 'auto',
                    left:
                        element.left + element.width + popupWidth <=
                        (window.innerWidth || document.documentElement.clientWidth)
                            ? element.left + element.width
                            : element.left - popupWidth,
                };
                break;

            case 'bottom':
                finalPosition = {
                    top:
                        element.top + element.height + popupHeight <=
                        (window.innerHeight || document.documentElement.clientHeight)
                            ? element.top + element.height
                            : element.top - popupHeight,
                    right: 'auto',
                    bottom: 'auto',
                    left:
                        element.left - element.width / 2 - popupWidth / 2 >= 0
                            ? element.left - element.width / 2 + popupWidth <
                              (window.innerWidth || document.documentElement.clientWidth)
                                ? element.left + element.width / 2 - popupWidth / 2
                                : element.left + element.width / 2 - popupWidth
                            : element.left + element.width / 2 - popupWidth / 2 >= 0
                            ? element.left + element.width / 2 - popupWidth / 2
                            : element.left,
                };
                break;

            case 'left':
                finalPosition = {
                    top: element.top + element.height,
                    right: 'auto',
                    bottom: 'auto',
                    left: element.left - popupWidth,
                };
                break;

            case 'bottom-left':
                finalPosition = {
                    top: element.top + element.height,
                    right: 'auto',
                    bottom: 'auto',
                    left: element.left - (popupWidth - element.width),
                };
                break;

            case 'bottom-center':
                finalPosition = {
                    top: element.top,
                    right: 'auto',
                    bottom: 'auto',
                    left: element.left + element.width / 2 - popupWidth / 2,
                };
                break;

            case 'over':
                finalPosition = {
                    top: element.top - popupHeight / 2 + element.height / 2,
                    right: 'auto',
                    bottom: 'auto',
                    left: element.left + element.width / 2 - popupWidth / 2,
                };
                break;

            default:
                finalPosition = {
                    top: 'auto',
                    right: 'auto',
                    bottom: 'auto',
                    left: 'auto',
                };
        }

        this.setState(
            {
                position: {
                    top: this.props.isCustomPosition.top
                        ? this.props.isCustomPosition.top
                        : !isNaN(finalPosition.top)
                        ? finalPosition.top
                        : 'auto',
                    right: !isNaN(finalPosition.right) ? finalPosition.right : 'auto',
                    bottom: !isNaN(finalPosition.bottom) ? finalPosition.bottom : 'auto',
                    left: this.props.isCustomPosition.left
                        ? this.props.isCustomPosition.left
                        : !isNaN(finalPosition.left)
                        ? finalPosition.left
                        : 'auto',
                    minWidth: minWidth ? `${minWidth}px` : popupWidth,
                },
            },
            () => {
                document.addEventListener('mouseup', this.handleClickOutside);
                document.addEventListener('keydown', this.handleEscKey);
            },
        );
    }
}

export default Popup;
