import ActionTypes from 'redux/actionTypes';
import rootInitialState from 'redux/store/initialState';

export const initialState = {
    sellers: rootInitialState.sellers,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SELLERS_IS_LOADING:
            return isLoading(state, action);
        case ActionTypes.SELLERS_IS_SAVING:
            return isSaving(state, action);
        case ActionTypes.SELLERS_CLEAR:
            return clear(state, action);
        case ActionTypes.SELLERS_SET_SHOULD_SHOW_LANGUAGE_SELECTOR:
            return setShouldShowLanguageSelector(state, action);
        default:
            return state;
    }
};

/**
 * Is Loading
 * @param {*} state
 * @param {*} action
 */
const isLoading = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        isLoading: payload,
    };
};

/**
 * Is Saving
 * @param {*} state
 * @param {*} action
 */
const isSaving = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        isSaving: payload,
    };
};

const setShouldShowLanguageSelector = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        shouldShowLanguageSelector: payload,
    };
};

/**
 * Is Saving
 * @param {*} state
 * @param {*} action
 */
const clear = (state, action) => {
    return {
        ...rootInitialState.distributors,
    };
};

export default reducer;
