export const templatesCardsViewModel = payload => {
    const content = payload.elements
        .filter(el => el.type === 'content')
        .map(el => cardViewModel(el));
    const alert = payload.elements.filter(el => el.type === 'alert').map(el => cardViewModel(el));
    const info = payload.elements.filter(el => el.type === 'info').map(el => cardViewModel(el));

    const output = {
        content: content,
        alert: alert,
        info: info,
    };

    return output;
};

const cardViewModel = payload => {
    if (!payload || !payload.cardspec || !payload.cardspec.conf_fields) {
        return {};
    }

    let card = {
        id: payload.id,
        colors: CARD_COLORS[payload.type],
        type: null,
        class: null,
        image: null,
        label: null,
        text: null,
        actions: [
            {
                type: null,
                label: '',
                args: null,
            },
            {
                type: null,
                label: '',
                args: null,
            },
        ],
    };

    Object.entries(payload.cardspec.conf_fields).forEach(([key, prop]) => {
        Object.entries(prop).forEach(([key, value]) => {
            if (key === 'namespace') {
                switch (value) {
                    // eslint-disable-next-line no-template-curly-in-string
                    case '${template:conf:text:Card type}':
                        card.type = prop.value.text;
                        // card.colors = CARD_COLORS[prop.value.text];
                        break;
                    // eslint-disable-next-line no-template-curly-in-string
                    case '${template:conf:text:Card class}':
                        card.class = prop.value.text;
                        break;
                    // eslint-disable-next-line no-template-curly-in-string
                    case '${template:conf:file:Card First Stage Image}':
                        card.image = prop.value.file;
                        break;
                    // eslint-disable-next-line no-template-curly-in-string
                    case '${template:conf:text:Card title}':
                        card.label = prop.value.text;
                        break;
                    // eslint-disable-next-line no-template-curly-in-string
                    case '${template:conf:textarea:Card First Stage Text}':
                        card.text = prop.value.textarea;
                        break;
                    // eslint-disable-next-line no-template-curly-in-string
                    case '${template:conf:text:Card First Stage Primary Action Label}':
                        card.actions[0].label = prop.value.text ? prop.value.text : '';
                        break;
                    // eslint-disable-next-line no-template-curly-in-string
                    case '${template:conf:text:Card First Stage Primary Action Type}':
                        card.actions[0].type = prop.value.text;
                        break;
                    // eslint-disable-next-line no-template-curly-in-string
                    case '${template:conf:json:Card First Stage Primary Action Args}':
                        card.actions[0].args = prop.value.json ? prop.value.json : null;
                        break;
                    // eslint-disable-next-line no-template-curly-in-string
                    case '${template:conf:text:Card First Stage Secondary Action Label}':
                        card.actions[1].label = prop.value.text ? prop.value.text : '';
                        break;
                    // eslint-disable-next-line no-template-curly-in-string
                    case '${template:conf:text:Card First Stage Secondary Action Type}':
                        card.actions[1].type = prop.value.text;
                        break;
                    // eslint-disable-next-line no-template-curly-in-string
                    case '${template:conf:json:Card First Stage Secondary Action Args}':
                        card.actions[1].args = prop.value.json ? prop.value.json : null;
                        break;

                    default:
                        break;
                }
            }
        });
    });

    const actions = card.actions.map(action => {
        switch (action.type) {
            case 'browse':
                return {
                    ...action,
                    args: action?.args?.url ? action.args.url : '',
                };
            case 'email':
                return {
                    ...action,
                    args: action?.args?.value ? action.args.value : '',
                };
            case 'dismiss':
                return {
                    ...action,
                    args: action?.args?.value ? action.args.value : null,
                };

            default:
                return action;
        }
    });

    return {
        ...payload,
        detailsModel: {
            ...card,
            actions: actions,
        },
    };
};

const CARD_COLORS = {
    'content-card': {
        'action-bar_background_color': '#E7F7FB',
        'action-bar_text_color': '#10B5D8',
        color: '#FFFFFF',
        text_color: '#323232',
        title_color: '#323232',
    },
    automation: {
        'action-bar_background_color': '#FF3D28',
        'action-bar_text_color': '#FFFFFF',
        color: '#FF3D28',
        text_color: '#FFFFFF',
        title_color: '#FFFFFF',
    },
    warning: {
        'action-bar_background_color': '#28BBDB',
        'action-bar_text_color': '#FFFFFF',
        color: '#10B5D8',
        text_color: '#FFFFFF',
        title_color: '#FFFFFF',
    },
    alert: {
        'action-bar_background_color': '#FF3D28',
        'action-bar_text_color': '#FFFFFF',
        color: '#FF3D28',
        text_color: '#FFFFFF',
        title_color: '#FFFFFF',
    },
    info: {
        'action-bar_background_color': '#28BBDB',
        'action-bar_text_color': '#FFFFFF',
        color: '#10B5D8',
        text_color: '#FFFFFF',
        title_color: '#FFFFFF',
    },
};
