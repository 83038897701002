import ActionTypes from 'redux/actionTypes';

export const changeCurrentPageAction = page => ({
    type: ActionTypes.CHANGE_ACTIVE_PAGE,
    payload: {
        page,
    },
});

export const manageSidebarRoutesAction = data => ({
    type: ActionTypes.NAVIGATION_MANAGE_SIDEBAR_ROUTES,
    payload: {
        data,
    },
});
