import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './Styles.module.scss';
import Loading from 'shared/Loading';
import History from './History';
import { sendErrorToast } from 'services/toast';
import { INSURANCE_CLAIM_CHAT_SEND_OPTIONS } from 'utils/constants';
import useSwrInsuranceClaimChat from 'hooks/SWR/Insurance/ClaimChat';
import { swrCacheKeys } from 'hooks/SWR/cacheKeys';
import Error from 'shared/Error';
import {
    claimChatHistoryCreateAsset,
    claimChatHistoryCreateMessage,
    downloadClaimChatHistoryAsset,
} from 'api/insurance';
import { appFileUpload } from 'api/app';
import { getFileType } from 'utils/functions';
import { downloadAsset } from 'api/assets';

const ClaimChat = ({
    claimId,
    disabled,
    onClose,
    extensions,
    strict,
    width,
    height,
    match,
    project,
    fetchInsuranceData,
}) => {
    const containerId = 'claimChatContainer';
    const myRef = useRef(null);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [canSend, setCanSend] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [uploadFile, setUploadFile] = useState(null);
    const [uploadFilePreview, setUploadFilePreview] = useState(null);
    const [uploadFileName, setUploadFileName] = useState(null);
    const [uploadFileType, setUploadFileType] = useState(null);
    const [direction, setDirection] = useState('');
    const [lastScrollPos, setLastScrollPos] = useState(0);

    const { history, isHistoryLoading, isHistoryError, isValidatingHistory, revalidateHistory } =
        useSwrInsuranceClaimChat(match.params.app_id, claimId);
    // isHistoryError = true;

    const handleClickOutside = useCallback(
        ({ target }) => {
            if (target) {
                const specifiedElement = document.getElementById(containerId);
                const isClickInside = specifiedElement.contains(target);

                if (!target.hasAttribute('download') && !isClickInside) {
                    onClose();
                }
            }
        },
        [onClose],
    );

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleClickOutside]);

    const handleOnScroll = event => {
        if (event && event.target) {
            const currentScrollPos = event.target.scrollTop;

            if (currentScrollPos <= 10) {
                setDirection('up');
                setLastScrollPos(currentScrollPos);
            } else if (lastScrollPos < currentScrollPos) {
                setDirection('down');
                setLastScrollPos(currentScrollPos);
            }
        }
    };

    const handleOnChange = e => {
        const target = e.target;
        setMessage(target.value);
        setCanSend(target.value !== '' || uploadFile ? true : false);
        setUploadFile(null);
        setUploadFilePreview(null);
        setUploadFileName(null);
        setUploadFileType(null);
    };

    const handleUploadFile = e => {
        if (e.target.files) {
            const file = e.target.files[0];

            if (!file || !file.type) {
                return false;
            }

            const size = file ? file.size / 1024 / 1024 : null;
            if (size > 20) {
                sendErrorToast('File size exceeds 20 MB.');
                return false;
            }

            if (
                extensions &&
                !extensions.includes(file.type) &&
                file.type.indexOf('video') === -1
            ) {
                sendErrorToast('Wrong file extension.');
                return false;
            }

            if (strict) {
                handleImageSizeValidation(file);
            } else {
                handleOnUpload(file);
                e.target.value = '';
            }
        }
    };

    const handleImageSizeValidation = file => {
        let img = new Image();
        img.src = window.URL.createObjectURL(file);

        img.onload = () => {
            const naturalWidth = img.naturalWidth;
            const naturalHeight = img.naturalHeight;
            window.URL.revokeObjectURL(img.src);

            if (naturalWidth !== width || naturalHeight !== height) {
                sendErrorToast(`Image size required: ${width}x${height}px`);
            } else {
                handleOnUpload(file);
            }
        };
    };

    const handleOnUpload = file => {
        setCanSend(true);
        setMessage('');
        setUploadFile(file);
        setUploadFilePreview(URL.createObjectURL(file));
        setUploadFileName(file.name);
        setUploadFileType(file.type);
    };

    const handleClearUpload = e => {
        e.preventDefault();
        e.stopPropagation();

        setCanSend(false);
        setUploadFile(null);
        setUploadFilePreview(null);
        setUploadFileName(null);
        setUploadFileType(null);
    };

    const handleOnSubmit = async e => {
        if (e) {
            e.preventDefault();
        }

        if (uploadFile) {
            await handleOnUploadAsset();
        } else {
            await handleOnSendMessage();
        }
    };

    const handleOnSendMessage = async (type = 'message', data) => {
        let payload = {
            app_id: match.params.app_id,
            claim_id: claimId,
        };

        if (type === 'message') {
            payload = {
                ...payload,
                data: {
                    ...INSURANCE_CLAIM_CHAT_SEND_OPTIONS.message,
                    description: message,
                },
            };
        }

        if (type === 'upload') {
            payload = {
                ...payload,
                data: {
                    ...INSURANCE_CLAIM_CHAT_SEND_OPTIONS.asset,
                    entity_id: data.assets[0].id,
                    description: data.assets[0].name,
                },
            };
        }

        try {
            setIsSending(true);
            await claimChatHistoryCreateMessage(payload)
                .then(async response => {
                    await revalidateHistory(swrCacheKeys.INSURANCE_CLAIM_CHAT);

                    setIsSending(false);
                    setMessage('');
                    setUploadFile(null);
                    setUploadFilePreview(null);
                    setUploadFileName(null);
                    setUploadFileType(null);
                    setCanSend(false);
                    setError(false);
                })
                .catch(error => {
                    console.log(error);
                    setIsSending(false);
                    setError(true);
                });
        } catch (error) {
            console.log(error);
            setIsSending(false);
            setError(true);
        }
    };

    const handleOnUploadAsset = async e => {
        e?.preventDefault && e.preventDefault();

        try {
            setIsSending(true);
            await appFileUpload({
                content: uploadFile,
            })
                .then(async response => {
                    if (response && response.url) {
                        const type = getFileType(response.url);
                        const payload = {
                            app_id: match.params.app_id,
                            claim_id: claimId,
                            name: `Uploaded ${type}`,
                            data: {
                                assets: [
                                    {
                                        name: `Uploaded ${type}`,
                                        icon: type === 'photo' ? response.url : null,
                                        image: type === 'photo' ? response.url : null,
                                        uri: response.url,
                                        classes: [`io.habit.trackableassets.type.${type}`],
                                    },
                                ],
                            },
                        };

                        await claimChatHistoryCreateAsset(payload)
                            .then(async response => {
                                if (response && response.assets) {
                                    await handleOnSendMessage('upload', response);
                                }
                            })
                            .then(() => {
                                const url = match.url.split('/');
                                const type =
                                    url && url[2]
                                        ? url[2] === 'assets'
                                            ? 'quote-protected-assets'
                                            : url[2]
                                        : null;

                                type &&
                                    fetchInsuranceData &&
                                    typeof fetchInsuranceData === 'function' &&
                                    fetchInsuranceData(type);
                            })
                            .catch(error => {
                                console.log(error);
                                setIsSending(false);
                                setError(true);
                            });
                    }
                })
                .catch(error => {
                    console.log(error);
                    setIsSending(false);
                    setError(true);
                });
        } catch (error) {
            console.log(error);
            setIsSending(false);
            setError(true);
        }
    };

    const handleDownloadAsset = async e => {
        const id = e.currentTarget.getAttribute('data-id');
        const payload = {
            app_id: match.params.app_id,
            claim_id: claimId,
            asset_id: id,
        };

        try {
            setIsSending(true);
            await downloadClaimChatHistoryAsset(payload)
                .then(async response => {
                    if (response.uri) {
                        // window.open(response.uri, '_blank');
                        await handleDownloadProtectedAsset(response.uri);
                    }

                    // setIsSending(false);
                    // setError(false);
                })
                .catch(error => {
                    console.log(error);
                    setIsSending(false);
                    setError(true);
                });
        } catch (error) {
            console.log(error);
            setIsSending(false);
            setError(true);
        }
    };

    const handleDownloadProtectedAsset = async url => {
        let path = url.split('?');
        if (path[0].indexOf('/distributors/') !== -1) {
            path = path[0].split('/distributors/');
            path = '/distributors/' + path[1];
        } else if (path[0].indexOf('/applications/') !== -1) {
            path = path[0].split('/applications/');
            path = '/applications/' + path[1];
        } else {
            sendErrorToast();
            return;
        }

        const payload = {
            id: project.id,
            confirmation_hash: project.confirmation_hash,
            url: url,
            path: path,
        };

        try {
            await downloadAsset(payload)
                .then(response => {
                    if (response?.url) {
                        window.open(response.url, '_blank');
                        setIsSending(false);
                        setError(false);
                    } else {
                        sendErrorToast();
                    }
                })
                .catch(error => {
                    console.log(error);
                    setIsSending(false);
                    setError(true);
                });
        } catch (error) {
            console.log(error);
            setIsSending(false);
            setError(true);
        }
    };

    return (
        <div ref={myRef} data-test="ChatContainer" id={containerId} className={Styles.container}>
            <div className={Styles.window}>
                <header className={Styles[direction]}>
                    {!isSending && !isHistoryLoading && isValidatingHistory && (
                        <span>
                            <Loading variant="xxs" />
                        </span>
                    )}
                    Chat
                    <i
                        data-test="ChatClose"
                        className={classnames('icon-icon_delete_2', Styles.close)}
                        onClick={onClose}
                    ></i>
                </header>
                <div className={Styles.content} onScroll={handleOnScroll}>
                    {isHistoryLoading && !isHistoryError && !error && <Loading variant="sm" />}

                    {!isHistoryLoading && (isHistoryError || error) && <Error size="sm" />}

                    {!isHistoryLoading &&
                        !isHistoryError &&
                        !error &&
                        history &&
                        Object.entries(history).length > 0 && (
                            <History
                                data-test="ChatHistory"
                                data={history}
                                onDownloadAsset={handleDownloadAsset}
                            />
                        )}

                    {!isHistoryLoading &&
                        !isHistoryError &&
                        !error &&
                        (!history || Object.entries(history).length === 0) && (
                            <div data-test="ChatEmpty" className={Styles.noHistory}>
                                <i className="icon-icon_search"></i>
                                There is no chat history...
                            </div>
                        )}
                </div>
                <footer>
                    <form onSubmit={handleOnSubmit}>
                        <div className={Styles.input}>
                            {!uploadFilePreview && (
                                <input
                                    autoFocus
                                    data-test="Input"
                                    disabled={disabled}
                                    aria-disabled={disabled}
                                    onChange={
                                        !error && !isHistoryLoading && !isSending
                                            ? handleOnChange
                                            : () => null
                                    }
                                    placeholder="Type your message..."
                                    value={message}
                                    type="text"
                                    name="message"
                                    onFocus={() => null}
                                    onBlur={() => null}
                                    autoComplete="off"
                                />
                            )}

                            {uploadFilePreview && (
                                <div className={Styles.uploadPreview}>
                                    {uploadFileType.indexOf('image') !== -1 && (
                                        <img src={uploadFilePreview} alt="Upload" />
                                    )}
                                    {uploadFileType.indexOf('video') !== -1 && (
                                        <i
                                            className={classnames(
                                                Styles.attach,
                                                Styles.square,
                                                'icon-icon_play',
                                            )}
                                        ></i>
                                    )}
                                    {uploadFileType.indexOf('application') !== -1 && (
                                        <i
                                            className={classnames(
                                                Styles.attach,
                                                'icon-icon_flow_test',
                                            )}
                                        ></i>
                                    )}
                                    <div className={Styles.fileName} title={uploadFileName}>
                                        {uploadFileName}
                                    </div>
                                </div>
                            )}

                            <button type="button" className={Styles.upload}>
                                {!uploadFilePreview && (
                                    <>
                                        <input
                                            data-test="Upload"
                                            type="file"
                                            name="file"
                                            id={`chatFileUpload`}
                                            className="inputfile"
                                            onChange={
                                                !error && !isHistoryLoading && !isSending
                                                    ? handleUploadFile
                                                    : () => null
                                            }
                                        />

                                        <i
                                            className={classnames(
                                                Styles.attach,
                                                'icon-icon_attachment',
                                            )}
                                        ></i>
                                    </>
                                )}

                                {uploadFilePreview && (
                                    <i
                                        className={classnames(Styles.attach, 'icon-icon_delete_2')}
                                        onClick={handleClearUpload}
                                    ></i>
                                )}
                            </button>
                        </div>

                        <button
                            type="submit"
                            className={classnames(Styles.send, isSending ? Styles.loading : null)}
                            disabled={!canSend || isSending || isHistoryLoading}
                            onClick={
                                !error && !isHistoryLoading && !isSending ? handleOnSubmit : null
                            }
                        >
                            {isSending && <Loading variant="xs" />}
                            {!isSending && <i className="icon-icon_send"></i>}
                        </button>
                    </form>
                </footer>
            </div>
        </div>
    );
};

ClaimChat.propTypes = {
    claimId: PropTypes.string,
    onClose: PropTypes.func,
    disabled: PropTypes.bool,
    accept: PropTypes.string,
    extensions: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
    strict: PropTypes.bool,
    match: PropTypes.object,
    project: PropTypes.object,
    fetchInsuranceData: PropTypes.func,
};

ClaimChat.defaultProps = {
    width: 512,
    height: 512,
    extensions: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    accept: 'image/png, image/jpeg, .pdf, video/*',
};

export default ClaimChat;
