import ShareModal from 'components/Networks/ShareModal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    networksAddMemberClearErrorAction,
    networksCloseShareModalAction,
} from 'redux/domains/networks/actions';
import {
    networksAddMemberThunk,
    networksDeleteMemberThunk,
    networksGetMembersThunk,
    networksPauseMemberThunk,
    networksResumeMemberThunk,
} from 'redux/domains/networks/thunk';
import { openPromptAction } from 'redux/domains/prompt/actions';

const mapStateToProps = (state, ownProps) => ({
    texts: state.texts,
    isLoading: state.networks.isLoading,
    isSaving: state.networks.isSaving,
    type: state.networks.share.type,
    id: state.networks.share.id,
    active: state.networks.share?.active,
    members: state.networks.share.members,
    roles: state.networks.share.roles,
    shareId: state.networks.share.shareId,
    shareType: state.networks.share.shareType,
    title: state.networks.share.title,
    owner: state.networks.share.owner,
    error: state.networks.share.error,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onCloseModal: () => dispatch(networksCloseShareModalAction()),
    openPrompt: data => dispatch(openPromptAction(data)),
    getMembers: data => networksGetMembersThunk(dispatch, data, ownProps),
    addMember: data => networksAddMemberThunk(dispatch, data, ownProps),
    clearError: () => dispatch(networksAddMemberClearErrorAction()),
    deleteMember: data => networksDeleteMemberThunk(dispatch, data, ownProps),
    resumeSuspendMember: data => {
        if (data.action === 'pause') {
            networksPauseMemberThunk(dispatch, data);
        } else {
            networksResumeMemberThunk(dispatch, data);
        }
    },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShareModal));
