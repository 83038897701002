import ActionTypes from 'redux/actionTypes';

export const isLoadingInsuranceAction = isLoading => ({
    type: ActionTypes.INSURANCE_IS_LOADING,
    payload: {
        isLoading,
    },
});

export const isSavingInsuranceAction = data => ({
    type: ActionTypes.INSURANCE_IS_SAVING,
    payload: {
        data,
    },
});

export const clearInsuranceAction = () => ({
    type: ActionTypes.INSURANCE_CLEAR,
});

export const insuranceViewHasErrorAction = data => ({
    type: ActionTypes.INSURANCE_CURRENT_VIEW_HAS_ERROR,
    payload: {
        data,
    },
});

export const insuranceSyncProjectsAction = data => ({
    type: ActionTypes.INSURANCE_SYNC_PROJECTS,
    payload: {
        data,
    },
});

export const insuranceChangeProjectAction = data => ({
    type: ActionTypes.INSURANCE_CHANGE_PROJECT,
    payload: {
        data,
    },
});

export const insuranceSyncInsureesSearchAction = data => ({
    type: ActionTypes.INSURANCE_SYNC_INSUREES_SEARCH,
    payload: {
        data,
    },
});

export const insuranceSyncInsureesAction = data => ({
    type: ActionTypes.INSURANCE_SYNC_INSUREES,
    payload: {
        data,
    },
});

export const insuranceOnClearSearchInsureesAction = () => ({
    type: ActionTypes.INSURANCE_CLEAR_INSUREES_SEARCH,
});

export const insuranceSyncDataByTypeAction = (type, data) => ({
    type: ActionTypes.INSURANCE_SYNC_DATA_BY_TYPE,
    payload: {
        type,
        data,
    },
});

export const insuranceSyncSearchByTypeAction = (type, data) => ({
    type: ActionTypes.INSURANCE_SYNC_SEARCH_BY_TYPE,
    payload: {
        type,
        data,
    },
});

export const insuranceClearSearchByTypeAction = type => ({
    type: ActionTypes.INSURANCE_CLEAR_SEARCH_BY_TYPE,
    payload: {
        type,
    },
});

export const openInsurancePoliciesPaymentsModalAction = data => ({
    type: ActionTypes.INSURANCE_POLICIES_OPEN_PAYMENTS_MODAL,
    payload: {
        data,
    },
});

export const closeInsurancePoliciesPaymentsModalAction = () => ({
    type: ActionTypes.INSURANCE_POLICIES_CLOSE_PAYMENTS_MODAL,
});

export const openInsurancePoliciesDocumentsModalAction = data => ({
    type: ActionTypes.INSURANCE_POLICIES_OPEN_DOCUMENTS_MODAL,
    payload: {
        data,
    },
});

export const closeInsurancePoliciesDocumentsModalAction = () => ({
    type: ActionTypes.INSURANCE_POLICIES_CLOSE_DOCUMENTS_MODAL,
});

export const openInsurancePoliciesMediaModalAction = data => ({
    type: ActionTypes.INSURANCE_POLICIES_OPEN_MEDIA_MODAL,
    payload: {
        data,
    },
});

export const closeInsurancePoliciesMediaModalAction = () => ({
    type: ActionTypes.INSURANCE_POLICIES_CLOSE_MEDIA_MODAL,
});

export const openInsurancePoliciesDamagesModalAction = data => ({
    type: ActionTypes.INSURANCE_POLICIES_OPEN_DAMAGES_MODAL,
    payload: {
        data,
    },
});

export const closeInsurancePoliciesDamagesModalAction = () => ({
    type: ActionTypes.INSURANCE_POLICIES_CLOSE_DAMAGES_MODAL,
});

export const syncInsuranceMetaDataTotalsAction = data => ({
    type: ActionTypes.INSURANCE_SYNC_META_DATA_TOTALS,
    payload: {
        data,
    },
});

export const syncInsuranceCurrentViewAction = (data, type) => ({
    type: ActionTypes.INSURANCE_SYNC_CURRENT_VIEW,
    payload: {
        data,
        type,
    },
});

export const insuranceClearCurrentViewAction = () => ({
    type: ActionTypes.INSURANCE_CLEAR_CURRENT_VIEW,
});

export const syncCarSpecsAction = data => ({
    type: ActionTypes.INSURANCE_SYNC_CAR_SPECS,
    payload: {
        data,
    },
});

export const insuranceSyncMetaDataByTypeAction = (type, data) => ({
    type: ActionTypes.INSURANCE_SYNC_META_DATA_BY_TYPE,
    payload: {
        type,
        data,
    },
});

export const insuranceSyncPaginateByTypeAction = (type, data) => ({
    type: ActionTypes.INSURANCE_SYNC_PAGINATE_DATA,
    payload: {
        type,
        data,
    },
});

export const insuranceSyncSortByTypeAction = (type, data) => ({
    type: ActionTypes.INSURANCE_SYNC_SORT_BY_TYPE,
    payload: {
        type,
        data,
    },
});

export const syncInsurancePaymentsTotalsAction = data => ({
    type: ActionTypes.INSURANCE_SYNC_META_DATA_TOTAL_PAYMENTS,
    payload: {
        data,
    },
});

export const syncInsurancePaymentsAction = data => ({
    type: ActionTypes.INSURANCE_SYNC_PAYMENTS,
    payload: {
        data,
    },
});

export const syncInsuranceServiceDataAction = data => ({
    type: ActionTypes.INSURANCE_SYNC_SERVICE,
    payload: {
        data,
    },
});

export const insuranceSyncClaimLocationAction = data => ({
    type: ActionTypes.INSURANCE_SYNC_CLAIM_LOCATION,
    payload: {
        data,
    },
});

export const insuranceSyncClaimChatHistoryAction = data => ({
    type: ActionTypes.INSURANCE_SYNC_CLAIM_CHAT_HISTORY,
    payload: {
        data,
    },
});

export const insuranceFetchClaimChatHistoryAction = data => ({
    type: ActionTypes.INSURANCE_FETCH_CLAIM_CHAT_HISTORY,
    payload: {
        data,
    },
});

export const insuranceClearClaimChatHistoryAction = () => ({
    type: ActionTypes.INSURANCE_CLEAR_CLAIM_CHAT_HISTORY,
});

export const openInsuranceAssetOdometerHistoryModalAction = data => ({
    type: ActionTypes.INSURANCE_ASSET_OPEN_ODOMETER_HISTORY_MODAL,
    payload: {
        data,
    },
});

export const closeInsuranceAssetOdometerHistoryModalAction = () => ({
    type: ActionTypes.INSURANCE_ASSET_CLOSE_ODOMETER_HISTORY_MODAL,
});

export const insuranceSyncAssetOdometerHistoryAction = data => ({
    type: ActionTypes.INSURANCE_ASSET_SYNC_ODOMETER_HISTORY,
    payload: {
        data,
    },
});

export const insuranceSyncAssetOdometerHistoryTotalDrivenAction = data => ({
    type: ActionTypes.INSURANCE_ASSET_SYNC_ODOMETER_HISTORY_TOTAL_DRIVEN,
    payload: {
        data,
    },
});

export const openInsuranceBillingModalAction = data => ({
    type: ActionTypes.INSURANCE_OPEN_BILLING_MODAL,
    payload: {
        data,
    },
});

export const closeInsuranceBillingModalAction = () => ({
    type: ActionTypes.INSURANCE_CLOSE_BILLING_MODAL,
});

export const insuranceSyncBillingAction = (data, policyId, clientserviceId) => ({
    type: ActionTypes.INSURANCE_SYNC_BILLING,
    payload: {
        data,
        policyId,
        clientserviceId,
    },
});

export const insuranceSyncAssetUrlAction = data => ({
    type: ActionTypes.INSURANCE_SYNC_ASSET_URL,
    payload: {
        data,
    },
});
