import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { formatDate } from 'utils/functions';
import Styles from './TimelineList.module.scss';

const TimelineItem = ({ data, onSelect, selected }) => {
    // let size = '';
    // if (data['total_distance']) {
    //     size = data['total_distance'] * 100;
    // } else {
    //     size = Math.round(Math.random() * 100);
    // }

    // const style = {
    //     width: `${size}%`,
    // };

    return (
        <li
            className={classnames(Styles.item, selected ? Styles.selected : null)}
            onClick={onSelect}
            data-id={data.id}
        >
            <div className={Styles.icon}>
                {data.icon && <i className={data.icon}></i>}

                {!data.icon && <span className={Styles.noIcon}></span>}

                <span className={Styles.vLine}></span>
            </div>

            <div className={Styles.details}>
                <p className={Styles.title}>
                    {data.street_name && data.street_name.trim() !== '' ? data.street_name : `N/D`}
                </p>
                <p className={Styles.time}>
                    {data.hasOwnProperty('total_distance') && data.total_distance !== ''
                        ? `${data.total_distance.toFixed(2)} km`
                        : `N/D`}
                </p>
                {/* formatDate(prop.data, false, DATE_FORMAT_NO_TIME_INTERNATIONAL) */}
                <p className={Styles.date}>
                    {data.hasOwnProperty('timestamp_origin') && data.timestamp_origin !== ''
                        ? formatDate(data.timestamp_origin, undefined, undefined, false)
                        : `N/D`}
                </p>
                {/* <p className={Styles.bar} style={style}></p> */}
            </div>
        </li>
    );
};

TimelineItem.propTypes = {
    data: PropTypes.object,
    onSelect: PropTypes.func,
    selected: PropTypes.bool,
};

export class TimelineList extends React.PureComponent {
    static propTypes = {
        data: PropTypes.array,
        onSelect: PropTypes.func,
        selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    };

    handleOnSelect = e => {
        const id = e.currentTarget.getAttribute('data-id');
        const place = this.props.data.filter(place => place.id.toString() === id);
        this.props.onSelect(place);
    };

    render() {
        const { data, selected } = this.props;

        return (
            <ul className={Styles.container}>
                {data &&
                    data.length > 0 &&
                    data.map((item, index) => (
                        <TimelineItem
                            key={item.id ? item.id : index}
                            data={item}
                            onSelect={item.id ? this.handleOnSelect : null}
                            selected={
                                selected && selected.toString() === item.id.toString()
                                    ? true
                                    : false
                            }
                        />
                    ))}
            </ul>
        );
    }
}

export default TimelineList;
