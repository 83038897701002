import classnames from 'classnames';
import { getText } from 'localization';
import PropTypes from 'prop-types';
import React from 'react';
import ErrorBoundary from 'shared/ErrorBoundary';
import Styles from './Header.module.scss';
// import NetworkDetails from 'redux/containers/Networks/NetworkDetails';

export class Header extends React.PureComponent {
    state = {
        init: false,
        focus: false,
        name: '',
        isNetworkDetails: false,
    };

    static propTypes = {
        texts: PropTypes.object,
        isLoading: PropTypes.bool,
        isSaving: PropTypes.bool,
        network: PropTypes.object,
        action: PropTypes.string,
        onEdit: PropTypes.func,
        isOpen: PropTypes.bool,
        readOnly: PropTypes.bool,
    };

    componentDidMount() {
        if (
            this.props.network &&
            this.props.network.name &&
            this.props.network.name !== this.state.name
        ) {
            this.setState({ name: this.props.network.name });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.init && this.props.network && this.props.network.name) {
            this.setState({
                init: true,
                name: this.props.network.name,
            });
        }

        if (this.state.init && prevProps.network.name !== this.props.network.name) {
            this.setState({ name: this.props.network.name });
        }
    }

    handleOnOpenNetworkDetails = () => {
        this.setState(state => ({
            isNetworkDetails: true,
        }));
    };

    handleOnCloseNetworkDetails = () => {
        this.setState(state => ({
            isNetworkDetails: false,
        }));
    };

    handleOnChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            {
                [name]: value,
            },
            () => {
                // this.props.onEdit(value);
            },
        );
    };

    handleOnSave = () => {
        // e.preventDefault();
        this.props.onEdit({ name: this.state.name });
    };

    handleOnSubmit = e => {
        e.preventDefault();
        this.props.onEdit({ name: this.state.name });
    };

    getSegmentsName = () => {
        const { network } = this.props;
        if (network && network.segments) {
            const names = network.segments.map(segment => {
                if (segment.segment && segment.segment.name) {
                    return segment.segment.name;
                }

                return '--';
            });

            return names.join('; ');
        }

        return '--';
    };

    render() {
        const { action, network, isOpen, onEdit, readOnly } = this.props;

        return (
            <ErrorBoundary>
                <section
                    data-test="HeaderContainer"
                    className={classnames(
                        Styles.header,
                        Styles[action],
                        isOpen ? Styles.collapsed : null,
                    )}
                >
                    <div className={Styles.details}>
                        <div
                            data-test="HeaderDetailsImage"
                            className={classnames(Styles.col, Styles.image)}
                        >
                            {(!network || !network.image) && (
                                <i className="icon-icon_Add_image"></i>
                            )}

                            {network && network.image && (
                                <img src={network.image} alt={network.name} />
                            )}
                        </div>
                        <div
                            data-test="HeaderDetailsName"
                            className={classnames(Styles.col, Styles.name)}
                        >
                            <h6>{getText('selfcare_network_section_networks_label')}</h6>
                            <p>{network && network.name ? network.name : '--'}</p>
                        </div>
                        <div data-test="HeaderDetailsCountry" className={Styles.col}>
                            <h6>{getText('selfcare_network_section_country_label')}</h6>
                            <p>
                                {network && network.country && network.country.name
                                    ? network.country.name
                                    : '--'}
                            </p>
                        </div>
                        <div data-test="HeaderDetailsRegion" className={Styles.col}>
                            <h6>{getText('selfcare_network_section_region_label')}</h6>
                            <p>
                                {network && network.region_covered ? network.region_covered : '--'}
                            </p>
                        </div>
                        <div data-test="HeaderDetailsSegment" className={Styles.col}>
                            <h6>{getText('selfcare_network_section_segment_label')}</h6>
                            <p>{this.getSegmentsName()}</p>
                        </div>
                        <div data-test="HeaderDetailsEntities" className={Styles.col}>
                            <h6>{getText('selfcare_network_section_entities_label')}</h6>
                            <p>
                                {network && network.directories && network.directories.entities
                                    ? `${network.directories.entities.length} Directories`
                                    : '--'}
                            </p>
                        </div>
                        <div data-test="HeaderDetailsServices" className={Styles.col}>
                            <h6>{getText('selfcare_network_section_services_label')}</h6>
                            <p>
                                {network && network.directories && network.directories.services
                                    ? `${network.directories.services.length} Directories`
                                    : '--'}
                            </p>
                        </div>
                        <div data-test="HeaderDetailsItems" className={Styles.col}>
                            <h6>{getText('selfcare_network_section_items_label')}</h6>
                            <p>
                                {network && network.directories && network.directories.items
                                    ? `${network.directories.items.length} Directories`
                                    : '--'}
                            </p>
                        </div>
                        <div
                            data-test="HeaderDetailsActions"
                            className={classnames(Styles.col, Styles.actions)}
                        >
                            <i
                                data-tip={
                                    !readOnly
                                        ? getText('selfcare_network_section_edit_network_label')
                                        : getText('selfcare_network_section_read_permission_label')
                                }
                                className={classnames(
                                    'icon-icon_edit',
                                    Styles.edit,
                                    readOnly ? Styles.disabled : null,
                                )}
                                onClick={!readOnly ? onEdit : null}
                            ></i>
                        </div>
                    </div>
                </section>
            </ErrorBoundary>
        );
    }
}

export default Header;
