import classnames from 'classnames';
import { getText } from 'localization';
import React, { useCallback, useMemo, useState } from 'react';
import Button from 'shared/Button';
import Checkbox from 'shared/Checkbox';
import Styles from 'shared/DropdownMultiselect/DropdownMultiselect.module.scss';
import Search from 'shared/Search';
import {
    getDefaultShowSearchOnList,
    getElementPosition,
    isElementIntoViewOnContainer,
} from 'utils/functions';

export const useDropdownMultiselect = (
    placeholder,
    id,
    name,
    onChange,
    namespace,
    onSelectAll,
    onClearAll,
    withSearch,
    hasEmptyState,
    maxWidth,
    itemKey,
    data,
    selected,
    onDone,
    isCustomPosition,
    hasDoneButton,
) => {
    const [popup, setPopup] = useState({
        id: 'dropdown',
        active: false,
        element: {},
        selectedId: -1,
    });
    const [search, setSearch] = useState('');

    const focusOnSelectedElement = useCallback(() => {
        setTimeout(() => {
            isElementIntoViewOnContainer('[data-selected]', `selectPopup-${id || 'temp'}`, true, {
                behavior: 'auto',
                block: 'center',
                inline: 'nearest',
            });
        }, 0);
    }, [id]);

    const handleOnSelect = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            const id = e.currentTarget.getAttribute('data-id');
            if (name) {
                onChange({
                    name: name,
                    id: id,
                    namespace: namespace ? namespace : null,
                });
            } else {
                onChange(id);
            }
        },
        [name, namespace, onChange],
    );

    const handleTogglePopup = useCallback(
        e => {
            e.preventDefault();
            const target = e.currentTarget;
            const targetPosition = getElementPosition(target);
            let customPosition = {
                ...targetPosition,
                top: 'auto',
                left: 'auto',
            };
            setPopup(popup => ({
                ...popup,
                active: !popup?.active,
                element: isCustomPosition ? customPosition : targetPosition,
                selectedId: !popup?.active ? -1 : popup.selectedId,
            }));

            const dropdown = document.getElementById(`dropdown-${id || 'temp'}`);
            dropdown.style.maxWidth = `${popup.element.width}px`;
        },
        [id, isCustomPosition, popup.element.width],
    );

    const handleClosePopup = useCallback(() => {
        setSearch('');
        setPopup(popup => ({
            ...popup,
            active: false,
            selectedId: -1,
        }));
    }, []);

    const handleOnChange = useCallback(
        e => {
            const target = e.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            setSearch(value);
            setPopup(popup => ({
                ...popup,
                selectedId: -1,
            }));
            if (value === '') {
                focusOnSelectedElement();
            }
        },
        [focusOnSelectedElement, setSearch, setPopup],
    );

    const handleOnClear = useCallback(() => {
        setSearch('');
        setPopup(popup => ({ ...popup, selectedId: -1 }));
        focusOnSelectedElement();
    }, [focusOnSelectedElement]);

    const handleOnSelectAll = useCallback(() => {
        onSelectAll({
            name: name,
            namespace: namespace,
            action: 'all',
        });
    }, [name, namespace, onSelectAll]);

    const handleOnClearAll = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            onClearAll({
                name: name,
                namespace: namespace,
                action: 'all',
            });
        },
        [name, namespace, onClearAll],
    );

    const handleOnDone = useCallback(
        e => {
            handleClosePopup();
            onDone({ name: name });
        },
        [handleClosePopup, name, onDone],
    );

    const getContent = useMemo(() => {
        const style = {
            maxWidth: maxWidth ? '100%' : 'auto',
        };
        let searchResults = data;
        if (withSearch && itemKey) {
            searchResults = data?.filter(
                el => el[itemKey] && el[itemKey].toUpperCase().indexOf(search.toUpperCase()) !== -1,
            );
        }

        return (
            <>
                {withSearch && data?.length >= getDefaultShowSearchOnList() && (
                    <div className={Styles.search}>
                        <Search
                            data-test="Search"
                            autoFocus={true}
                            name="search"
                            theme="primary"
                            variant="outlined"
                            state={search === '' ? 'empty' : 'active'}
                            size="sm"
                            placeholder={getText('search_with_dots')}
                            onSearch={() => {}}
                            onChange={handleOnChange}
                            onClear={search !== '' ? handleOnClear : null}
                            value={search}
                        />
                    </div>
                )}

                <div className={Styles.actions}>
                    <div>
                        <Checkbox
                            theme="round"
                            variant="light"
                            onChange={handleOnSelectAll}
                            position="left"
                            checked={
                                selected &&
                                (selected?.length === 0 || selected?.length < data?.length)
                                    ? false
                                    : true
                            }
                            label={getText('common_alllabel')}
                        />
                    </div>

                    <div onClick={handleOnClearAll}>
                        <i
                            className={classnames(
                                'icon-icon_delete_3',
                                selected && selected?.length > 0 ? Styles?.active : Styles.hidden,
                            )}
                        ></i>
                    </div>

                    {hasDoneButton && (
                        <div>
                            <Button
                                type="button"
                                theme="form"
                                variant="outlined"
                                size="sm"
                                value={getText('common_done')}
                                state={selected && selected?.length > 0 ? 'active' : 'empty'}
                                onClick={handleOnDone}
                            />
                        </div>
                    )}
                </div>

                <ul style={style}>
                    {hasEmptyState && placeholder && searchResults && searchResults?.length > 0 && (
                        <li data-id={null} onClick={handleOnSelect} className={Styles.clear}>
                            {placeholder ? placeholder : ''}
                        </li>
                    )}
                    {searchResults &&
                        searchResults?.length > 0 &&
                        searchResults.map(item => {
                            if ((itemKey && item && item[itemKey]) || (item && item.text)) {
                                return (
                                    <li
                                        key={item.id}
                                        data-id={item.id}
                                        onClick={handleOnSelect}
                                        title={item[itemKey] ? item[itemKey] : item.text}
                                    >
                                        <Checkbox
                                            data-id={item.id}
                                            theme="round"
                                            variant="light"
                                            onChange={handleOnSelect}
                                            checked={
                                                item.id &&
                                                selected &&
                                                selected?.length > 0 &&
                                                selected.includes(item.id.toString())
                                                    ? true
                                                    : false
                                            }
                                            position="center"
                                        />
                                        <p>{item[itemKey] ? item[itemKey] : item.text}</p>
                                    </li>
                                );
                            } else {
                                return null;
                            }
                        })}
                    {(!searchResults ||
                        searchResults?.length === 0 ||
                        !searchResults.find(result => Boolean(result))) && (
                        <li className={Styles.noResults}>{getText('no_results_with_dots')}</li>
                    )}
                </ul>
            </>
        );
    }, [
        data,
        handleOnChange,
        handleOnClear,
        handleOnClearAll,
        handleOnDone,
        handleOnSelect,
        handleOnSelectAll,
        hasDoneButton,
        hasEmptyState,
        itemKey,
        maxWidth,
        placeholder,
        search,
        selected,
        withSearch,
    ]);
    return {
        getContent,
        handleOnSelect,
        handleOnSelectAll,
        handleOnClearAll,
        handleOnDone,
        handleOnChange,
        handleOnClear,
        handleClosePopup,
        focusOnSelectedElement,
        handleTogglePopup,
        popup,
    };
};
