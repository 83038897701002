import rootInitialState from 'redux/store/initialState';

export const initialState = {
    successRateIntervals: rootInitialState.successRateIntervals,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default reducer;
