import ActionTypes from 'redux/actionTypes';
import rootInitialState from 'redux/store/initialState';

export const initialState = {
    usecases: rootInitialState.usecases,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.USECASES_IS_SAVING:
            return isSaving(state, action);
        case ActionTypes.USECASES_OPEN_MODAL:
            return openModal(state, action);
        case ActionTypes.USECASES_CLOSE_MODAL:
            return closeModal(state, action);
        case ActionTypes.USECASES_SYNC:
            return syncUsecases(state, action);
        case ActionTypes.USECASES_CLEAR:
            return clearUsecases(state, action);
        default:
            return state;
    }
};

const clearUsecases = (state, action) => {
    return {
        ...state,
        list: [],
    };
};

const syncUsecases = (state, action) => {
    const payload = action.payload.data;

    if (state.modal?.active) {
        return {
            ...state,
            modal: {
                ...state.modal,
                usecase: payload.elements.filter(
                    usecase => usecase.id === state.modal.usecase.id,
                )[0],
            },
            list: [...payload.elements],
        };
    }

    return {
        ...state,
        list: [...payload.elements],
    };
};

const isSaving = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        isSaving: payload,
    };
};

const openModal = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        modal: {
            ...state.modal,
            active: true,
            action: payload.action,
            usecase: payload.usecase ? { ...payload.usecase } : null,
        },
    };
};

const closeModal = (state, action) => {
    // const payload = action.payload.isSaving;

    return {
        ...state,
        modal: {
            ...state.modal,
            active: false,
            action: null,
            usecase: null,
        },
    };
};

export default reducer;
