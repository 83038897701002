import { zip } from 'rxjs';
import { isLoadingAction } from 'redux/domains/app/actions';
import { openPromptAction } from 'redux/domains/prompt/actions';
import {
    getInsuranceMetaDataObs,
    getInsurancePaymentsObs,
    getInsuranceServiceCostMonthObs,
    getInsuranceDistanceMonthObs,
    updateClaimPropertiesObs,
    assetOdometerHistoryObs,
    assetOdometerHistoryTotalDrivenObs,
} from 'api/insurance';
import {
    syncInsuranceMetaDataTotalsAction,
    syncInsurancePaymentsTotalsAction,
    syncInsurancePaymentsAction,
    isSavingInsuranceAction,
    syncInsuranceServiceDataAction,
    insuranceSyncAssetOdometerHistoryAction,
    insuranceSyncAssetOdometerHistoryTotalDrivenAction,
} from './actions';
import { insuranceFetchDataThunk } from './thunk';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';

export const insuranceMetaDataObs = dispatch => {
    dispatch(isLoadingAction(true));

    return dispatch((dispatch, getState) => {
        const id = getState().insurance.filters.projects.selected;
        if (!id) return;

        const payloadInsurees = {
            namespace: 'total_number_of_insurees',
            type: 'count',
            params: {
                application_id: id,
            },
        };

        const payloadPolicies = {
            namespace: 'total_number_of_policies',
            type: 'count',
            params: {
                application_id: id,
            },
        };

        const payloadClaims = {
            namespace: 'total_number_of_claims',
            type: 'count',
            params: {
                application_id: id,
                status_exclude: ['draft', 'open'],
            },
        };

        const payloadQuotes = {
            namespace: 'total_number_of_quotes',
            type: 'count',
            params: {
                application_id: id,
                state_exclude: ['draft', 'open'],
            },
        };

        const payloadAssets = {
            namespace: 'total_number_of_protectedassets',
            type: 'count',
            params: {
                application_id: id,
            },
        };

        const dataZip = () =>
            zip(
                getInsuranceMetaDataObs(payloadInsurees),
                getInsuranceMetaDataObs(payloadPolicies),
                getInsuranceMetaDataObs(payloadClaims),
                getInsuranceMetaDataObs(payloadQuotes),
                getInsuranceMetaDataObs(payloadAssets),
            );

        const subscription = dataZip().subscribe({
            next: response => {
                dispatch(syncInsuranceMetaDataTotalsAction(response));
            },
            error: err => {
                dispatch(isLoadingAction(false));
                console.error('Something wrong occurred: ', err);

                const prompt = {
                    id: 'genericError',
                    title: 'Oops',
                    content: 'Something went wrong...',
                    retry: true,
                    onConfirm: () => {
                        insuranceMetaDataObs(dispatch);
                    },
                };
                dispatch(openPromptAction(prompt));
            },
            complete: () => dispatch(isLoadingAction(false)),
        });

        return subscription;
    });
};

export const insurancePaymentsObs = dispatch => {
    dispatch(isLoadingAction(true));

    return dispatch((dispatch, getState) => {
        const id = getState().insurance.filters.projects.selected;
        const clientservice_id = getState().insurance.policies.payments.id;
        if (!id) return;

        const payloadTotalPayments = {
            namespace: 'total_number_of_payments',
            type: 'count',
            params: {
                application_id: id,
                clientservice_id: clientservice_id,
            },
        };

        const payloadPayments = {
            id: id,
            subscriptionId: clientservice_id,
        };

        const dataZip = () =>
            zip(
                getInsuranceMetaDataObs(payloadTotalPayments),
                getInsurancePaymentsObs(payloadPayments),
            );

        const subscription = dataZip().subscribe({
            next: response => {
                // dispatch(syncInsuranceMetaDataTotalsAction(response));

                if (response[0] && response[0] !== '') {
                    dispatch(syncInsurancePaymentsTotalsAction(response[0]));
                }

                if (response[1] && response[1] !== '') {
                    dispatch(syncInsurancePaymentsAction(response[1]));
                }
            },
            error: err => {
                dispatch(isLoadingAction(false));
                console.error('Something wrong occurred: ', err);

                const prompt = {
                    id: 'genericError',
                    title: 'Oops',
                    content: 'Something went wrong...',
                    retry: true,
                    onConfirm: () => {
                        insurancePaymentsObs(dispatch);
                    },
                };
                dispatch(openPromptAction(prompt));
            },
            complete: () => dispatch(isLoadingAction(false)),
        });

        return subscription;
    });
};

export const insuranceOnFetchServiceDataObs = (dispatch, data, ownProps) => {
    if (!data.channel_id) {
        return null;
    }

    const appId = ownProps.match.params.app_id;
    const endpoints = {
        cost_month: data.endpoints.cost_month
            .replace(':id', appId)
            .replace(':channel_id', data.channel_id),
        distance_month: data.endpoints.distance_month
            .replace(':id', appId)
            .replace(':channel_id', data.channel_id),
    };

    dispatch(isSavingInsuranceAction(true));
    return dispatch((dispatch, getState) => {
        const dataZip = () =>
            zip(
                getInsuranceServiceCostMonthObs(endpoints.cost_month),
                getInsuranceDistanceMonthObs(endpoints.distance_month),
            );

        const subscription = dataZip().subscribe({
            next: response => {
                const payload = {
                    ...data,
                    data: {
                        cost_month:
                            response && response[0] && response[0].data
                                ? `${response[0].data} €`
                                : '--',
                        distance_month:
                            response && response[1] && response[1].data
                                ? `${response[1].data} km`
                                : '--',
                    },
                };
                // console.log('TCL: insuranceOnFetchServiceDataObs -> payload', payload);
                dispatch(syncInsuranceServiceDataAction(payload));
            },
            error: err => {
                dispatch(isSavingInsuranceAction(false));
                console.error('Something wrong occurred: ', err);

                const prompt = {
                    id: 'genericError',
                    title: 'Oops',
                    content: 'Something went wrong...',
                    retry: true,
                    onConfirm: () => {
                        insuranceOnFetchServiceDataObs(dispatch, data, ownProps);
                    },
                };
                dispatch(openPromptAction(prompt));
            },
            complete: () => dispatch(isSavingInsuranceAction(false)),
        });

        return subscription;
    });
};

export const insuranceOnSaveClaimPropertiesObs = (dispatch, data, ownProps) => {
    const url = ownProps.match.url.split('/');
    const type = url && url[2] ? (url[2] === 'assets' ? 'quote-protected-assets' : url[2]) : null;

    const basePayload = {
        app_id: ownProps.match.params.app_id,
        claim_id: data.claim_id,
    };

    dispatch(isSavingInsuranceAction(true));
    return dispatch((dispatch, getState) => {
        const dataZip = () =>
            zip(
                // updateClaimPropertiesObs({
                //     ...basePayload,
                //     property_id: 'claim_requested_amount',
                //     data: data.claim_requested_amount.toString(),
                // }),
                // updateClaimPropertiesObs({
                //     ...basePayload,
                //     property_id: 'claim_negotiated_amount',
                //     data: data.claim_negotiated_amount.toString(),
                // }),
                // updateClaimPropertiesObs({
                //     ...basePayload,
                //     property_id: 'claim_payable_to',
                //     data: data.claim_payable_to,
                // }),
                // updateClaimPropertiesObs({
                //     ...basePayload,
                //     property_id: 'claim_approved_amount',
                //     data: data.claim_approved_amount.toString(),
                // }),
                updateClaimPropertiesObs({
                    ...basePayload,
                    property_id: 'protected_asset_covered_value',
                    data: data.protected_asset_covered_value.toString(),
                }),
            );

        const subscription = dataZip().subscribe({
            next: async response => {
                // await insuranceFetchClaimDataThunk(dispatch, basePayload);
                await insuranceFetchDataThunk(dispatch, ownProps, type, false);
                await dispatch(isSavingInsuranceAction(false));
            },
            error: err => {
                dispatch(isSavingInsuranceAction(false));
                console.error('Something wrong occurred: ', err);

                const prompt = {
                    id: 'genericError',
                    title: 'Oops',
                    content: 'Something went wrong...',
                    retry: true,
                    onConfirm: () => {
                        insuranceOnSaveClaimPropertiesObs(dispatch, data, ownProps);
                    },
                };
                dispatch(openPromptAction(prompt));
            },
            // complete: async () => await dispatch(isSavingInsuranceAction(false)),
        });

        return subscription;
    });
};

export const insuranceAssetOdometerHistoryObs = (
    dispatch,
    data,
    ownProps,
    loading = true,
    saving = false,
) => {
    if (loading) {
        dispatch(isLoadingAction(true));
    }

    const payload = {
        app_id: ownProps.match.params.app_id,
        quote_id: data.quote_id,
        asset_id: data.asset_id,
        channel_id: data.channel_id,
        params: {
            page_size: DEFAULT_PAGE_SIZE,
            order_by: '-created',
        },
    };

    return dispatch((dispatch, getState) => {
        const dataZip = () =>
            zip(assetOdometerHistoryObs(payload), assetOdometerHistoryTotalDrivenObs(payload));

        const subscription = dataZip().subscribe({
            next: async response => {
                await dispatch(
                    insuranceSyncAssetOdometerHistoryAction(
                        response && response[0] && response[0].elements ? response[0].elements : [],
                    ),
                );

                await dispatch(
                    insuranceSyncAssetOdometerHistoryTotalDrivenAction(
                        response && response[1] && response[1] ? response[1].data : null,
                    ),
                );

                if (saving) {
                    await dispatch(isSavingInsuranceAction(false));
                }

                if (loading) {
                    dispatch(isLoadingAction(false));
                }
            },
            error: err => {
                console.error('Something wrong occurred: ', err);
                dispatch(isSavingInsuranceAction(false));

                if (loading) {
                    dispatch(isLoadingAction(false));
                }

                const prompt = {
                    id: 'genericError',
                    title: 'Oops',
                    content: 'Something went wrong...',
                    retry: true,
                    onConfirm: () => {
                        insuranceAssetOdometerHistoryObs(dispatch, data, ownProps, loading, saving);
                    },
                };
                dispatch(openPromptAction(prompt));
            },
            // complete: () => {
            //     if (loading) {
            //         dispatch(isLoadingAction(false));
            //     }
            // },
        });

        return subscription;
    });
};
