import classnames from 'classnames';
import { getText } from 'localization';
import PropTypes from 'prop-types';
import React from 'react';
import { sendErrorToast } from 'services/toast';
import Button from 'shared/Button';
import Dropdown from 'shared/Dropdown';
import Input from 'shared/Input';
import Popup from 'shared/Popup';
import { MEMBER_PERMISSIONS_LIST } from 'utils/constants';
import { getElementPosition } from 'utils/functions';
import Styles from './AddMember.module.scss';

export class AddMember extends React.PureComponent {
    state = {
        email: '',
        selectRolePopup: {
            active: false,
            element: {},
            selectedId: null,
        },
        permissions: {
            selected: 'false',
            list: MEMBER_PERMISSIONS_LIST,
        },
    };

    static propTypes = {
        texts: PropTypes.object,
        index: PropTypes.number,
        data: PropTypes.object,
        roles: PropTypes.arrayOf(PropTypes.object),
        edit: PropTypes.bool,
        onSave: PropTypes.func,
        clearError: PropTypes.func,
        isLoading: PropTypes.bool,
        isSaving: PropTypes.bool,
        focus: PropTypes.bool,
        error: PropTypes.string,
        membersCount: PropTypes.number,
        withPermissions: PropTypes.bool,
        permissions: PropTypes.arrayOf(PropTypes.object),
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.membersCount < this.props.membersCount && this.state.email !== '') {
            this.setState(state => ({
                ...state,
                email: '',
                permissions: { ...state.permissions, selected: null },
            }));
        }
    }

    handleInputOnChange = e => {
        this.setState(
            {
                email: e.target.value.trim(),
            },
            () => {
                if (this.props.error) {
                    this.props.clearError();
                }
            },
        );
    };

    handleSelectRolePopup = e => {
        e.preventDefault();
        this.setState(
            {
                selectRolePopup: {
                    active: !this.state.selectRolePopup?.active,
                    element: getElementPosition(e.currentTarget),
                },
            },
            () => {
                // this.props.addStep(e);
            },
        );
    };

    handleOnChangeDropdown = data => {
        this.setState(
            state => ({
                [data.name]: {
                    ...state[data.name],
                    selected: data.id,
                },
            }),
            () => {
                // this.handleValidation();
            },
        );
    };

    handleOnSave = e => {
        e.preventDefault();

        if (this.props.withPermissions) {
            if (!this.state.permissions.selected) {
                sendErrorToast('You need to set permissions for the member.');
                return;
            } else {
                const data = {
                    email: this.state.email,
                    permissions: this.state.permissions.selected,
                };

                this.props.onSave(data);
            }
        } else {
            const data = {
                email: this.state.email,
            };

            this.props.onSave(data);
        }
    };

    render() {
        const { isLoading, isSaving, focus, error, withPermissions } = this.props;
        const { email, selectRolePopup, permissions } = this.state;

        let canInvite = false;
        if (withPermissions) {
            canInvite = permissions.selected && email !== '' ? true : false;
        } else {
            canInvite = email !== '' ? true : false;
        }

        return (
            <li data-test="AddMemberItem" className={Styles.member}>
                <form autoComplete="off" onSubmit={this.handleOnSubmit}>
                    <div className={classnames(Styles.col)}>
                        <div className={Styles.avatar}></div>
                    </div>

                    <div className={classnames(Styles.col, Styles.name)}></div>

                    <div
                        data-test="AddMemberItemRole"
                        className={classnames(Styles.col, Styles.role)}
                    >
                        <Button
                            block
                            disabled
                            variant="outlined"
                            theme="ternary"
                            // value={this.getValueById()}
                            value={getText('member')}
                            onClick={this.handleSelectRolePopup}
                            icon={selectRolePopup?.active ? 'icon_arrow_up' : 'icon_arrow_down'}
                        />

                        <Popup
                            list
                            position="bottom"
                            id="addMemberRolePopup"
                            active={selectRolePopup?.active}
                            element={selectRolePopup.element}
                            onClickSetting={this.handleClickUserSetting}
                            closePopup={this.handleClosePopups}
                            content={this.getSelectContent()}
                        />
                    </div>

                    <div
                        data-test="AddMemberItemEmail"
                        className={classnames(Styles.col, Styles.email)}
                    >
                        <Input
                            autoFocus
                            focusOnDemand={focus}
                            onChange={this.handleInputOnChange}
                            value={email}
                            type="text"
                            name="email"
                            theme="ternary"
                            variant="filled"
                            placeholder={getText('insert_email_address')}
                            // error={error}
                        />
                    </div>

                    <div data-test="AddMemberItemSubmit" className={classnames(Styles.col)}>
                        <Button
                            disabled={!canInvite || isLoading || isSaving}
                            variant="filled"
                            theme="ternary"
                            value={getText('share')}
                            onClick={this.handleOnSave}
                            type="submit"
                            loading={isSaving}
                        />
                    </div>

                    <div className={classnames(Styles.col, Styles.actions)}>
                        {withPermissions && (
                            <Dropdown
                                id="permissions"
                                data={permissions.list}
                                selected={permissions.selected}
                                position="bottom"
                                onChange={this.handleOnChangeDropdown}
                                name="permissions"
                                theme="select"
                                variant="outlined"
                                state={permissions.selected ? 'active' : null}
                                block
                                hasEmptyState
                                placeholder={getText('common_select_label')}
                                size="sm"
                            />
                        )}
                    </div>
                </form>

                {error && <p className={Styles.errorMsg}>{error}</p>}
            </li>
        );
    }

    getSelectContent() {
        const { roles } = this.props;

        return (
            <>
                {roles.map(role => (
                    <li key={role.id}>{role.text}</li>
                ))}
            </>
        );
    }

    getValueById() {
        const { data, roles } = this.props;
        const role = roles.filter(role => role.id === data.role);

        return role[0].text;
    }
}

export default AddMember;
