import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import ErrorBoundary from 'shared/ErrorBoundary';
import Styles from './NetworkDetails.module.scss';
import { validation } from './helpers/validation';
import NetworkDetailsForm from './partials/NetworkDetailsForm';

export class NetworkDetails extends React.PureComponent {
    state = {
        init: false,
        overlayId: 'networkDetailsOverlay',
        containerId: 'networkDetailsContainer',
        canSave: false,
        segments: {
            selected: [],
            list: [],
        },
        countries: {
            selected: null,
            list: [],
        },
        directory_entities: {
            selected: [],
            list: [],
        },
        directory_services: {
            selected: [],
            list: [],
        },
        directory_items: {
            selected: [],
            list: [],
        },
        fields: {
            name: '',
            country_id: '',
            region_covered: '',
            segments: [],
            directory_entities: [],
            directory_services: [],
            directory_items: [],
        },
        searchOptions: {
            types: ['(regions)'],
            componentRestrictions: { country: '' },
        },
    };

    static propTypes = {
        texts: PropTypes.object,
        isLoading: PropTypes.bool,
        isSaving: PropTypes.bool,
        data: PropTypes.object,
        temp: PropTypes.object,
        segments: PropTypes.array,
        countries: PropTypes.array,
        directory_entities: PropTypes.array,
        directory_services: PropTypes.array,
        directory_items: PropTypes.array,
        onSubmit: PropTypes.func,
        onClear: PropTypes.func,
        onClose: PropTypes.func,
        fetchData: PropTypes.func,
        onUpload: PropTypes.func,
        type: PropTypes.string,
    };

    componentDidMount() {
        ReactTooltip.hide();

        this.props.fetchData();

        document.addEventListener('click', this.handleClickOutside, false);

        const { data } = this.props;

        let segments = [];
        if (data && data.segments) {
            segments = data.segments.map(s => s.segment_id);
        }

        let directory_entities = [];
        if (data && data.directories && data.directories.entities) {
            directory_entities = data.directories.entities.map(d => d.id);
        }

        let directory_services = [];
        if (data && data.directories && data.directories.services) {
            directory_services = data.directories.services.map(d => d.id);
        }

        let directory_items = [];
        if (data && data.directories && data.directories.items) {
            directory_items = data.directories.items.map(d => d.id);
        }

        this.setState(state => ({
            ...state,
            init: true,
            segments: {
                ...state.segments,
                list: [...this.props.segments],
            },
            countries: {
                ...state.countries,
                list: [...this.props.countries],
            },
            directory_entities: {
                ...state.directory_entities,
                list: [...this.props.directory_entities],
            },
            directory_services: {
                ...state.directory_services,
                list: [...this.props.directory_services],
            },
            directory_items: {
                ...state.directory_items,
                list: [...this.props.directory_items],
            },
            searchOptions: {
                ...state.searchOptions,
                componentRestrictions: {
                    country: data && data.country_id ? data.country_id : '',
                },
            },
            fields: {
                ...state.fields,
                name: data && data.name ? data.name : '',
                country_id: data && data.country && data.country.id ? data.country.id : '',
                region_covered: data && data.region_covered ? data.region_covered : '',
                image: data && data.image ? data.image : null,
                segments: [...segments],
                directory_entities: [...directory_entities],
                directory_services: [...directory_services],
                directory_items: [...directory_items],
            },
        }));
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, false);
    }

    componentDidUpdate(prevProps) {
        if (
            (prevProps.segments.length === 0 && this.props.segments.length > 0) ||
            (prevProps.countries.length === 0 && this.props.countries.length > 0) ||
            (prevProps.directory_entities.length === 0 &&
                this.props.directory_entities.length > 0) ||
            (prevProps.directory_services.length === 0 &&
                this.props.directory_services.length > 0) ||
            (prevProps.directory_items.length === 0 && this.props.directory_items.length > 0)
        ) {
            this.setState(
                state => ({
                    ...state,
                    segments: {
                        ...state.segments,
                        list: [...this.props.segments],
                    },
                    countries: {
                        ...state.countries,
                        list: [...this.props.countries],
                    },
                    directory_entities: {
                        ...state.directory_entities,
                        list: [...this.props.directory_entities],
                    },
                    directory_services: {
                        ...state.directory_services,
                        list: [...this.props.directory_services],
                    },
                    directory_items: {
                        ...state.directory_items,
                        list: [...this.props.directory_items],
                    },
                }),
                () => {
                    this.handleValidation();
                },
            );
        }

        if (this.props.temp && JSON.stringify(prevProps.temp) !== JSON.stringify(this.props.temp)) {
            this.setState(
                state => ({
                    ...state,
                    fields: {
                        ...state.fields,
                        image: this.props.temp.image
                            ? this.props.temp.image
                            : this.props.data && this.props.data.image
                            ? this.props.data.image
                            : '',
                        icon: this.props.temp.icon
                            ? this.props.temp.icon
                            : this.props.data && this.props.data.icon
                            ? this.props.data.icon
                            : '',
                    },
                }),
                () => {
                    this.handleValidation();
                },
            );
        }

        if (
            prevProps.data &&
            prevProps.data.directories &&
            this.props.data &&
            this.props.data.directories &&
            JSON.stringify(prevProps.data.directories) !==
                JSON.stringify(this.props.data.directories)
        ) {
            const { data } = this.props;
            const directory_entities = data.directories.entities.map(d => d.id);
            const directory_services = data.directories.services.map(d => d.id);
            const directory_items = data.directories.items.map(d => d.id);
            let segments = [];
            if (data && data.segments) {
                segments = data.segments.map(s => s.segment_id);
            }
            this.setState(
                state => ({
                    ...state,
                    fields: {
                        ...state.fields,
                        segments: segments,
                        directory_entities: directory_entities,
                        directory_services: directory_services,
                        directory_items: directory_items,
                    },
                }),
                () => {
                    this.handleValidation();
                },
            );
        }

        this.handleValidation();
    }

    handleClickOutside = ({ target }) => {
        if (target) {
            const { overlayId, containerId } = this.state;
            const specifiedElement = document.getElementById(containerId);
            const targetId = target.id;
            const isClickInside = specifiedElement.contains(target);

            if (targetId && targetId === overlayId && !isClickInside) {
                setTimeout(() => {
                    this.props.onClose();
                }, 0);
            }
        }
    };

    handleOnChange = e => {
        const value = e.target.value;
        const name = e.target.name;
        // const namespace = e.target.getAttribute('data-namespace');

        this.setState(
            state => ({
                ...state,
                fields: {
                    ...state.fields,
                    [name]: value,
                },
                // searchOptions: {
                //     ...state.searchOptions,
                //     componentRestrictions: {
                //         country:
                //             name === 'country_id' ? value : state.searchOptions.componentRestrictions.country,
                //     },
                // },
            }),
            () => {
                this.handleValidation();
            },
        );
    };

    handleOnChangeRegion = e => {
        this.setState(
            state => ({
                ...state,
                fields: {
                    ...state.fields,
                    region_covered: e.region_covered,
                },
            }),
            () => {
                this.handleValidation();
            },
        );
    };

    handleOnChangeDropdown = data => {
        if (!data.id) {
            const newState = Object.keys(this.state.fields).reduce((object, key) => {
                if (key !== data.name) {
                    object[key] = this.state.fields[key];
                }
                return object;
            }, {});

            this.setState(
                state => ({
                    [data.name]: {
                        ...state[data.name],
                        selected: data.id,
                    },
                    fields: {
                        ...newState,
                    },
                }),
                () => {
                    this.handleValidation();
                    // this.handleOnSubmit();
                },
            );
        } else {
            this.setState(
                state => ({
                    [data.name]: {
                        ...state[data.name],
                        selected: data.id,
                    },
                    fields: {
                        ...state.fields,
                        [data.name]: data.id,
                        region_covered:
                            data.name === 'country_id' ? '' : state.fields.region_covered,
                    },
                    searchOptions: {
                        ...state.searchOptions,
                        componentRestrictions: {
                            country:
                                data.name === 'country_id'
                                    ? data.id
                                    : state.searchOptions.componentRestrictions.country,
                        },
                    },
                }),
                () => {
                    this.handleValidation();
                },
            );
        }
    };

    handleOnDropdownMultiselectOnChange = data => {
        const name = data.name;
        const value = data.id;
        let selected = [];

        function getFiltered(state) {
            return state.fields[name].filter(i => i !== value);
        }

        function getAdded(state) {
            return [...state.fields[name], value];
        }

        const isSelected = this.state.fields[name].includes(value);

        if (isSelected) {
            selected = getFiltered(this.state);
        } else {
            selected = getAdded(this.state);
        }

        this.setState(state => ({
            fields: {
                ...state.fields,
                [name]: selected,
            },
        }));
    };

    handleOnDropdownMultiselectAll = e => {
        const list = this.state[e.name].list;
        const values = [...list.map(i => i.id)];

        this.setState(
            state => ({
                [e.name]: {
                    ...state[e.name],
                    selected:
                        !state[e.name].selected || state[e.name].selected.length < list.length
                            ? values
                            : [],
                },
                fields: {
                    ...state.fields,
                    [e.namespace]: state.fields[e.namespace].length < list.length ? values : [],
                },
            }),
            () => {
                // this.handleValidation();
            },
        );
    };

    handleOnDropdownMultiselectClear = e => {
        this.setState(
            state => ({
                [e.name]: {
                    ...state[e.name],
                    selected: [],
                },
                fields: {
                    ...state.fields,
                    [e.namespace]: [],
                },
            }),
            () => {
                // this.handleValidation();
            },
        );
    };

    handleOnDropdownMultiselectDone = e => {
        // if (e && e.name && e.name === 'segments') {
        //     this.setState(
        //         state => ({
        //             ...state,
        //             fields: {
        //                 ...state.fields,
        //                 categories: [],
        //             },
        //         }),
        //         () => {
        //             // this.handleValidation();
        //         },
        //     );
        // }
        this.handleValidation();
    };

    handleOnClear = e => {
        this.setState(
            state => ({
                canSave: false,
                fields: {
                    name: '',
                    country_id: '',
                    region_covered: '',
                    segments: [],
                    directory_entities: [],
                    directory_services: [],
                    directory_items: [],
                },
            }),
            () => {
                // this.props.onClear();
            },
        );
    };

    handleOnClearSingle = e => {
        const target = e.target;
        const name = target.getAttribute('data-name');

        this.setState(
            state => ({
                fields: {
                    ...state.fields,
                    [name]: '',
                },
            }),
            () => {
                this.handleValidation();
                // this.handleOnSubmit();
            },
        );
    };

    handleOnSubmit = e => {
        if (e) {
            e.preventDefault();
        }

        this.props.onSubmit({
            type: this.props.type,
            fields: this.state.fields,
        });
    };

    handleValidation = () => {
        const canSave = validation(this.props, this.state);

        this.setState({
            canSave: canSave,
        });
    };

    render() {
        const {
            data,
            temp,
            isLoading,
            isSaving,
            texts,
            segments,
            directory_entities,
            directory_services,
            directory_items,
            type,
            onUpload,
        } = this.props;
        const { init, canSave, fields, overlayId, containerId, countries, searchOptions } =
            this.state;

        return (
            <ErrorBoundary>
                <div id={overlayId} className={Styles.overlay}>
                    <section
                        id={containerId}
                        data-test="NetworkDetailsContainer"
                        className={Styles.details}
                    >
                        <NetworkDetailsForm
                            data-test="NetworkDetailsNetworkDetailsForm"
                            init={init}
                            fields={fields}
                            data={data}
                            temp={temp}
                            texts={texts}
                            countries={countries}
                            segments={segments}
                            dirEntities={directory_entities}
                            dirServices={directory_services}
                            dirItems={directory_items}
                            searchOptions={searchOptions}
                            type={type}
                            canSave={canSave}
                            isLoading={isLoading}
                            isSaving={isSaving}
                            onChange={this.handleOnChange}
                            onChangeRegion={this.handleOnChangeRegion}
                            onDropdownChange={this.handleOnChangeDropdown}
                            onDropdownMultiselectChange={this.handleOnDropdownMultiselectOnChange}
                            onDropdownMultiselectAll={this.handleOnDropdownMultiselectAll}
                            onDropdownMultiselectClear={this.handleOnDropdownMultiselectClear}
                            onDropdownMultiselectDone={this.handleOnDropdownMultiselectDone}
                            onUpload={onUpload}
                            onSubmit={this.handleOnSubmit}
                            onClear={this.handleOnClear}
                        />
                    </section>
                </div>
            </ErrorBoundary>
        );
    }
}

export default NetworkDetails;
