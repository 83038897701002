import classnames from 'classnames';
import withOverlay from 'hoc/withOverlay';
import { getText } from 'localization';
import PropTypes from 'prop-types';
import React from 'react';
import ErrorBoundary from 'shared/ErrorBoundary';
import Loading from 'shared/Loading';
import AddMember from './AddMember';
import Member from './Member';
import Styles from './ShareModal.module.scss';

class ShareModal extends React.PureComponent {
    state = {
        add: false,
        edit: false,
        focus: false,
    };

    static propTypes = {
        id: PropTypes.string,
        texts: PropTypes.object,
        active: PropTypes.bool,
        members: PropTypes.arrayOf(PropTypes.object),
        roles: PropTypes.arrayOf(PropTypes.object),
        shareId: PropTypes.string,
        shareType: PropTypes.string,
        title: PropTypes.string,
        owner: PropTypes.object,
        isLoading: PropTypes.bool,
        isSaving: PropTypes.bool,
        error: PropTypes.string,
        onCloseModal: PropTypes.func,
        openPrompt: PropTypes.func,
        addMember: PropTypes.func,
        clearError: PropTypes.func,
        deleteMember: PropTypes.func,
        resumeSuspendMember: PropTypes.func,
        getMembers: PropTypes.func,
    };

    componentDidMount() {
        this.props.getMembers({ id: this.props.shareId, type: this.props.shareType });

        setTimeout(() => {
            this.setState({
                add: true,
                edit: false,
                focus: true,
            });
        }, 100);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps?.active && this.props?.active) {
            setTimeout(() => {
                this.setState({
                    add: true,
                    edit: false,
                    focus: true,
                });
            }, 100);
        }

        if (prevProps?.active && !this.props?.active) {
            this.setState({
                add: false,
                edit: false,
                focus: false,
            });
        }
    }

    handleAddMode = () => {
        this.setState(
            {
                add: !this.state.add,
            },
            () => {
                if (!this.state.add && this.props.error) {
                    this.props.clearError();
                }
            },
        );
    };

    handleEditMode = () => {
        this.setState({
            edit: !this.state.edit,
        });
    };

    handleOnDelete = e => {
        const id = e.currentTarget.getAttribute('data-id');

        const data = {
            id: 'deleteDirectoryMemberPrompt',
            title: 'Alert',
            content: getText('prompt_delete_member'),
            onConfirm: () =>
                this.props.deleteMember({
                    id: this.props.shareId,
                    memberId: id,
                    type: this.props.shareType,
                }),
        };

        this.props.openPrompt(data);
    };

    handleAddMember = e => {
        let payload = {
            id: this.props.shareId,
            data: {
                ...e,
            },
            type: this.props.shareType,
        };

        if (this.props.shareType === 'networks') {
            payload = {
                ...payload,
                data: {
                    email: e.email,
                    read_only: e.permissions === 'true' ? true : false,
                },
            };
        }
        this.props.addMember(payload);
    };

    handleOnPausePlay = e => {
        const payload = {
            id: this.props.shareId,
            type: this.props.shareType,
            ...e,
        };
        this.props.resumeSuspendMember(payload);
    };

    render() {
        const {
            id,
            title,
            texts,
            onCloseModal,
            owner,
            members,
            roles,
            isLoading,
            error,
            clearError,
            shareType,
            isSaving,
        } = this.props;
        const { add, edit, focus } = this.state;

        const modalTitle = shareType === 'networks' ? `Share network:` : `Share directory:`;

        return (
            <ErrorBoundary>
                <div id={id} className={Styles.modal}>
                    <div className={Styles.header}>
                        <h3>
                            <span>{modalTitle}</span> {title}
                        </h3>
                        <i className="icon-icon_delete_2" onClick={onCloseModal}></i>
                    </div>
                    <div className={Styles.content}>
                        <div className={Styles.actionsContainer}>
                            <div className={Styles.actions}>
                                <i
                                    data-tip={getText('edit_member')}
                                    className={classnames(
                                        'icon-icon_edit',
                                        edit ? Styles?.active : null,
                                        members.length === 0 || isLoading ? Styles.disabled : null,
                                    )}
                                    onClick={members.length > 0 ? this.handleEditMode : null}
                                ></i>
                            </div>
                        </div>

                        <ul className={Styles.owner}>
                            {!isLoading && owner && (
                                <>
                                    <Member
                                        texts={texts}
                                        index={-1}
                                        data={owner}
                                        roles={roles}
                                        add={add}
                                        edit={edit}
                                        onPausePlay={this.handleOnPausePlay}
                                        onDelete={this.handleOnDelete}
                                    />
                                </>
                            )}

                            {!isLoading && add && (
                                <AddMember
                                    texts={texts}
                                    roles={roles}
                                    onSave={this.handleAddMember}
                                    isLoading={isLoading}
                                    isSaving={isSaving}
                                    focus={focus}
                                    error={error}
                                    clearError={clearError}
                                    membersCount={members.length}
                                    withPermissions={shareType === 'networks' ? true : false}
                                />
                            )}
                        </ul>

                        <ul className={classnames(Styles.list, add ? Styles.addMode : null)}>
                            {!isLoading &&
                                members.length > 0 &&
                                members.map((member, index) => (
                                    <Member
                                        key={member.id}
                                        texts={texts}
                                        index={index}
                                        data={member}
                                        roles={roles}
                                        add={add}
                                        edit={edit}
                                        onPausePlay={this.handleOnPausePlay}
                                        onDelete={this.handleOnDelete}
                                        isLoading={isLoading}
                                        onChangePermissions={this.handleAddMember}
                                    />
                                ))}

                            {isLoading && <Loading />}
                        </ul>
                    </div>
                </div>
            </ErrorBoundary>
        );
    }
}

export default withOverlay(ShareModal);
