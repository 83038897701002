import { connect } from 'react-redux';
import Filters from 'components/Analytics/UsersVisitedPlaces/Filters';
import {
    analyticsUsersVisitedPlacesToggleFilterAction,
    analyticsUsersVisitedPlacesClearFiltersAction,
} from 'redux/domains/analytics/actions';

const mapStateToProps = state => ({
    name: state.app.name,
    isLoading: state.app.loading,
    data: state.analytics.usersVisitedPlaces.filters,
});

const mapDispatchToProps = dispatch => ({
    onToggleFilter: data => dispatch(analyticsUsersVisitedPlacesToggleFilterAction(data)),
    onClearFilters: () => dispatch(analyticsUsersVisitedPlacesClearFiltersAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
