import { getClaimChatHistorySWR } from 'api/insurance';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import {
    API_DEFAULT_TIMEOUT,
    API_SWR_DEFAULT_REVALIDATE,
    DATE_FORMAT_NO_TIME_INTERNATIONAL,
} from 'utils/constants';
import { formatDate } from 'utils/functions';
import { swrCacheKeys } from '../../cacheKeys';

const options = {
    revalidateOnReconnect: true,
    revalidateOnFocus: true,
    revalidateOnMount: true,
    refreshInterval: 15000, // 15s
    loadingTimeout: API_DEFAULT_TIMEOUT,
    // eslint-disable-next-line no-unused-vars
    onSuccess: (data, key, config) => {},
    // eslint-disable-next-line no-unused-vars
    onError: (error, key) => {
        if (error.status !== 403 && error.status !== 404) {
            // We can send the error to Sentry,
            // or show a notification UI.
            console.log('Oops! Something went wrong while fetching chat data...');
        }
    },
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Never retry on 404.
        if (error.status === 404) return;
        // Never retry for a specific key.
        if (key === '/api/user') return;
        // Only retry up to 5 times.
        if (retryCount >= 5) return;
        // Retry after 5 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), API_SWR_DEFAULT_REVALIDATE);
    },
};

const useSwrInsuranceClaimChat = (app_id, claim_id) => {
    const [items, setItems] = useState(null);

    const { data, error, isValidating, mutate } = useSWR(
        () => [swrCacheKeys.INSURANCE_CLAIM_CHAT, app_id, claim_id],
        getClaimChatHistorySWR,
        options,
    );

    useEffect(() => {
        if (data?.elements) {
            const groupBy = (payload, key) =>
                payload.reduce(
                    (result, item) => ({
                        ...result,
                        [formatDate(item[key], null, DATE_FORMAT_NO_TIME_INTERNATIONAL, false)]: [
                            ...(result[
                                formatDate(
                                    item[key],
                                    null,
                                    DATE_FORMAT_NO_TIME_INTERNATIONAL,
                                    false,
                                )
                            ] || []),
                            item,
                        ],
                    }),
                    {},
                );

            setItems(groupBy(data.elements, 'created'));
        }
    }, [data]);

    return {
        history: items || null,
        isHistoryLoading: (!error && !data && data !== '') || typeof data === 'undefined',
        isHistoryError: error,
        isValidatingHistory: isValidating,
        revalidateHistory: mutate,
    };
};

export default useSwrInsuranceClaimChat;
