import { getText } from 'localization';
import {
    ADMIN_FINANCIAL_ENTITIES_TYPE_LIST,
    ADMIN_REQUESTS_STATUS_LIST,
    ADMIN_REQUESTS_TYPE_LIST,
} from 'utils/constants';

export const admin = {
    isLoading: false,
    isSaving: false,
    countries: {
        list: [],
    },
    settings: {
        disclaimers: null,
        commissions: null,
    },
    approvalRequests: {
        list: [],
        selectors: {
            status: {
                list: ADMIN_REQUESTS_STATUS_LIST,
                selected: null,
            },
            type: {
                list: ADMIN_REQUESTS_TYPE_LIST,
                selected: null,
            },
        },
        modal: {
            type: 'modal',
            id: 'ApprovalRequestModal',
            active: false,
            data: null,
        },
        header: [
            {
                id: 0,
                name: getText('initial_state_admin_name_label'),
                field: 'name',
                isSortable: false,
                hasIcon: false,
                hasCheckbox: false,
            },
            {
                id: 1,
                name: getText('initial_state_admin_email_label'),
                field: 'email',
                isSortable: false,
                hasIcon: null,
            },
            {
                id: 2,
                name: getText('initial_state_admin_type_label'),
                field: 'type',
                isSortable: false,
                hasIcon: null,
            },
            {
                id: 3,
                name: getText('initial_state_admin_company_label'),
                field: 'company',
                isSortable: false,
                hasIcon: null,
            },
        ],
        pagination: {
            current: '--',
            total: '--',
            next: false,
            page_start_index: 0,
        },
        filters: {
            field: null,
            direction: 'desc',
        },
        chunk: 15,
    },

    financialEntities: {
        list: [],
        selectors: {
            type: {
                list: ADMIN_FINANCIAL_ENTITIES_TYPE_LIST,
                selected: null,
            },
        },
        modal: {
            type: 'modal',
            id: 'EntityModal',
            active: false,
            data: null,
        },
        header: [
            {
                id: 0,
                name: 'Name',
                field: 'name',
                isSortable: true,
                hasIcon: null,
                hasCheckbox: false,
            },
            {
                id: 1,
                name: 'Type',
                field: 'type',
                isSortable: true,
                hasIcon: null,
                hasCheckbox: false,
            },
            {
                id: 2,
                name: 'location',
                field: 'location',
                isSortable: true,
                hasIcon: null,
                hasCheckbox: false,
            },
        ],
        pagination: {
            current: '--',
            total: '--',
            next: false,
            page_start_index: 0,
        },
        filters: {
            field: null,
            direction: 'desc',
        },
        chunk: 15,
    },
    insuranceProducts: {
        selectors: {
            insurer: {
                list: [],
                selected: null,
            },
            segment: {
                list: [],
                selected: null,
            },
            country: {
                list: [],
                selected: null,
            },
        },
        selected: [],
        list: [],
        header: [
            {
                id: 0,
                name: 'Insurer',
                field: 'name',
                isSortable: false,
                hasIcon: true,
                // hasCheckbox: true,
            },
            {
                id: 1,
                name: 'Product',
                field: 'product',
                isSortable: false,
                hasIcon: null,
            },
            {
                id: 2,
                name: 'Segment',
                field: 'segment',
                isSortable: false,
                hasIcon: null,
            },
            {
                id: 3,
                name: 'Country',
                field: 'country',
                isSortable: false,
                hasIcon: null,
            },
        ],
        pagination: {
            current: '--',
            total: '--',
            next: false,
            page_start_index: 0,
        },
        filters: {
            field: null,
            direction: 'desc',
        },
        chunk: 15,
        insuranceProduct: null,
    },
    distributors: {
        list: [],
        header: [
            {
                id: 0,
                name: getText('admin_products_distributor_label'),
                field: 'name',
                isSortable: true,
                hasIcon: null,
                hasCheckbox: false,
            },
            {
                id: 1,
                name: getText('admin_products_registration_type_label'),
                field: 'registration_type',
                isSortable: true,
                hasIcon: null,
                hasCheckbox: false,
            },
            {
                id: 2,
                name: getText('admin_products_registered_email_label'),
                field: 'email',
                isSortable: false,
                hasIcon: null,
                hasCheckbox: false,
            },
            {
                id: 3,
                name: getText('admin_products_country_label'),
                field: 'country', // country_id
                isSortable: false,
                hasIcon: null,
                hasCheckbox: false,
            },
            {
                id: 4,
                name: getText('admin_products_authorized_products_label'),
                field: 'apps',
                isSortable: false,
                hasIcon: null,
                hasCheckbox: false,
            },
            {
                id: 5,
                name: getText('admin_products_state_label'),
                field: 'state',
                isSortable: false,
                hasIcon: null,
                hasCheckbox: false,
            },
        ],
        pagination: {
            current: '--',
            total: '--',
            next: false,
            page_start_index: 0,
        },
        filters: {
            field: null,
            direction: 'desc',
        },
        chunk: 15,
    },
    providers: {
        list: [],
        header: [
            {
                id: 0,
                name: getText('admin_products_provider_label'),
                field: 'name',
                isSortable: true,
                hasIcon: null,
                hasCheckbox: false,
            },
            {
                id: 1,
                name: getText('admin_products_registration_type_label'),
                field: 'registration_type',
                isSortable: true,
                hasIcon: null,
                hasCheckbox: false,
            },
            {
                id: 2,
                name: getText('admin_products_registered_email_label'),
                field: 'email',
                isSortable: false,
                hasIcon: null,
                hasCheckbox: false,
            },
            {
                id: 3,
                name: getText('admin_products_country_label'),
                field: 'country', // country_id
                isSortable: false,
                hasIcon: null,
                hasCheckbox: false,
            },
            {
                id: 4,
                name: getText('admin_products_authorized_products_label'),
                field: 'apps',
                isSortable: false,
                hasIcon: null,
                hasCheckbox: false,
            },
            {
                id: 5,
                name: getText('admin_products_state_label'),
                field: 'state',
                isSortable: false,
                hasIcon: null,
                hasCheckbox: false,
            },
        ],
        pagination: {
            current: '--',
            total: '--',
            next: false,
            page_start_index: 0,
        },
        filters: {
            field: null,
            direction: 'desc',
        },
        chunk: 15,
    },
};
