import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Styles from './Filters.module.scss';
import List from './List';

export class Filters extends React.PureComponent {
    static propTypes = {
        data: PropTypes.array,
        onToggleFilter: PropTypes.func,
        onClearFilters: PropTypes.func,
        isOpen: PropTypes.bool,
        openClose: PropTypes.func,
    };

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    handleOnToggleFilter = e => {
        const id = e.currentTarget.getAttribute('data-id');
        const listId = e.currentTarget.getAttribute('data-list-id');

        this.props.onToggleFilter({ listId: parseInt(listId), id: parseInt(id) });
    };

    render() {
        const { data, onClearFilters, openClose, isOpen } = this.props;

        return (
            <>
                {openClose && (
                    <div className={Styles.openClose}>
                        {isOpen && (
                            <i
                                data-tip="Hide Filters"
                                className="icon-icon_arrow_newer"
                                onClick={openClose}
                            ></i>
                        )}

                        {!isOpen && (
                            <i
                                data-tip="Show Filters"
                                className="icon-icon_filter"
                                onClick={openClose}
                            ></i>
                        )}
                    </div>
                )}

                <div data-test="FiltersContainer" className={Styles.container}>
                    <div className={Styles.header}>
                        <p>Filters:</p>
                        <i
                            data-test="FiltersReset"
                            data-tip="Reset Filters"
                            className="icon-icon_refresh"
                            onClick={onClearFilters}
                        ></i>
                    </div>
                    <div className={Styles.content}>
                        {data &&
                            data.length > 0 &&
                            data.map(list => (
                                <List
                                    data-test="FiltersList"
                                    key={list.id}
                                    data={list}
                                    listId={list.id}
                                    onToggleFilter={this.handleOnToggleFilter}
                                />
                            ))}
                    </div>
                </div>
            </>
        );
    }
}

export default Filters;
