import ActionTypes from 'redux/actionTypes';
import rootInitialState from 'redux/store/initialState';
import { networks } from 'redux/store/initialState/networks';
import { NETWORK_MAX_NUM_OF_ITEMS } from 'utils/constants';

export const initialState = {
    networks: rootInitialState.networks,
};

const reducer = (state = rootInitialState, action) => {
    switch (action.type) {
        case ActionTypes.NETWORKS_IS_LOADING:
            return isLoading(state, action);
        case ActionTypes.NETWORKS_IS_SAVING:
            return isSaving(state, action);
        case ActionTypes.NETWORKS_CLEAR:
            return clear(state, action);
        case ActionTypes.NETWORKS_SYNC_DIRECTORIES:
            return syncDirectories(state, action);
        case ActionTypes.NETWORKS_SYNC_DIRECTORY:
            return syncDirectory(state, action);
        case ActionTypes.NETWORKS_EDIT_DIRECTORY:
            return editDirectory(state, action);
        case ActionTypes.NETWORKS_SYNC_SEGMENTS:
            return syncSegments(state, action);
        case ActionTypes.NETWORKS_SYNC_CATEGORIES:
            return syncCategories(state, action);
        case ActionTypes.NETWORKS_SYNC_COUNTRIES:
            return syncCountries(state, action);
        case ActionTypes.NETWORKS_OPEN_SHARE_MODAL:
            return openShareModal(state, action);
        case ActionTypes.NETWORKS_CLOSE_SHARE_MODAL:
            return closeShareModal(state, action);
        case ActionTypes.NETWORKS_SYNC_DIRECTORY_MEMBERS:
            return syncDirectoryMembers(state, action);
        case ActionTypes.NETWORKS_ADD_MEMBER_ERROR:
            return addMemberError(state, action);
        case ActionTypes.NETWORKS_ADD_MEMBER_CLEAR_ERROR:
            return addMemberClearError(state, action);
        case ActionTypes.NETWORKS_OPEN_ELEMENT_MODAL:
            return openElementModal(state, action);
        case ActionTypes.NETWORKS_CLOSE_ELEMENT_MODAL:
            return closeElementModal(state, action);
        case ActionTypes.NETWORKS_SYNC_ELEMENT:
            return syncElement(state, action);
        case ActionTypes.NETWORKS_SYNC_ELEMENT_IMAGE:
            return syncElementImage(state, action);
        case ActionTypes.NETWORKS_SYNC_DIRECTORY_ELEMENTS:
            return syncDirectoryElements(state, action);
        case ActionTypes.NETWORKS_SYNC_META_DATA_TOTALS:
            return syncMetaDataTotals(state, action);
        case ActionTypes.NETWORKS_SYNC_NETWORKS:
            return syncNetworks(state, action);
        case ActionTypes.NETWORKS_SYNC_NETWORK:
            return syncNetwork(state, action);
        case ActionTypes.NETWORKS_SYNC_NETWORK_AVAILABLE_ENTITIES:
            return syncNetworkAvailableEntities(state, action);
        case ActionTypes.NETWORKS_SYNC_NETWORK_ENTITIES:
            return syncNetworkEntities(state, action);
        case ActionTypes.NETWORKS_SYNC_NETWORK_IMAGE:
            return syncNetworkImage(state, action);
        case ActionTypes.NETWORKS_SYNC_NETWORK_SELECTED_DIRECTORY:
            return syncNetworkSelectedDirectory(state, action);
        case ActionTypes.NETWORKS_LOAD_MORE_SERVICES_ITEMS:
            return loadMoreServicesItems(state, action);
        case ActionTypes.NETWORKS_SYNC_NETWORK_ENTITY_SERVICES_ITEMS:
            return syncNetworkEntityServicesItems(state, action);
        case ActionTypes.NETWORKS_SYNC_NETWORK_ENTITY_SERVICES_ITEMS_SELECTED:
            return syncNetworkEntityServicesItemsSelected(state, action);
        case ActionTypes.NETWORKS_SYNC_PROVIDERS:
            return syncProviders(state, action);
        default:
            return state;
    }
};

const syncNetworkEntityServicesItemsSelected = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        networks: {
            ...state.networks,
            network: {
                ...state.networks.network,
                [payload.type]: {
                    ...state.networks.network[payload.type],
                    selectState: {
                        ...state.networks.network[payload.type].selectState,
                        selected: payload.option,
                    },
                },
            },
        },
    };
};

const syncNetworkEntityServicesItems = (state, action) => {
    const payload = action.payload.data;
    const available = action.payload.data.available.elements;
    const elements = action.payload.data.list.elements;
    const directory_id =
        action.payload.data.type === 'services'
            ? 'directoryofferedservice_id'
            : 'directoryoffereditem_id';

    const list = available.map(item => {
        const search = elements.filter(el => {
            return el[directory_id] === item.id;
        });
        if (search && search[0]) {
            const { copayment_percentage_amount, copayment_maximum_amount } = search[0];
            const parsedCopaymentAmount = copayment_maximum_amount || copayment_percentage_amount;
            return {
                ...item,
                ...search[0],
                copayment_amount: parsedCopaymentAmount,
            };
        }
        return {
            ...item,
            state: 'inactive',
        };
    });

    const actives = list.filter(el => el.state === 'active');
    const counter = actives.length;

    return {
        ...state,
        networks: {
            ...state.networks,
            network: {
                ...state.networks.network,
                [payload.type]: {
                    ...state.networks.network[payload.type],
                    list: [...list],
                    selectState: {
                        ...state.networks.network[payload.type].selectState,
                        actives: counter,
                        selected:
                            !state.networks.network[payload.type].selectState.selected &&
                            counter === 0
                                ? 'none'
                                : !state.networks.network[payload.type].selectState.selected &&
                                  counter === list.length
                                ? 'all'
                                : state.networks.network[payload.type].selectState.selected,
                    },
                },
            },
        },
    };
};

const syncNetworkSelectedDirectory = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        networks: {
            ...state.networks,
            entities: {
                ...state.networks.entities,
                selectState: {
                    ...state.networks.entities.selectState,
                    selected: payload,
                },
            },
        },
    };
};

const loadMoreServicesItems = (state, action) => {
    return {
        ...state,
        networks: {
            ...state.networks,
            size: state.networks.size + NETWORK_MAX_NUM_OF_ITEMS,
        },
    };
};

/**
 * Sync Network Image
 * @param {*} state
 * @param {*} action
 */
const syncNetworkImage = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        networks: {
            ...state.networks,
            network: {
                ...state.networks.network,
                canSave: true,
                temp: {
                    image:
                        payload.type === 'image'
                            ? payload.url
                            : state.networks.network.temp && state.networks.network.temp.image
                            ? state.networks.network.temp.image
                            : null,
                    icon:
                        payload.type === 'icon'
                            ? payload.url
                            : state.networks.network.temp && state.networks.network.temp.icon
                            ? state.networks.network.temp.icon
                            : null,
                },
            },
        },
    };
};

/**
 * Sync Network Available Entities
 * @param {*} state
 * @param {*} action
 */
const syncNetworkAvailableEntities = (state, action) => {
    const payload = action.payload.data;
    const elements = payload.elements;

    return {
        ...state,
        networks: {
            ...state.networks,
            entities: {
                ...state.networks.entities,
                list: [...elements],
            },
        },
    };
};

/**
 * Sync Network Entities
 * @param {*} state
 * @param {*} action
 */
const syncNetworkEntities = (state, action) => {
    const payload = action.payload.data;
    const available = state.networks.entities.list;
    let elements = payload.elements;
    elements = elements.map(entity => {
        return {
            ...entity.directoryentity,
            state: entity.state ? entity.state : 'inactive',
        };
    });

    let list = [...available]; // took out elements
    list = [...new Map(list.map(item => [item['id'], item])).values()];

    list = list.map(entity => {
        const search = elements.filter(el => el.id === entity.id);
        return {
            ...entity,
            state:
                search && search[0] ? (search[0].state ? search[0].state : 'active') : 'inactive',
        };
    });

    const actives = list.filter(el => el.state === 'active');
    const counter = actives.length;
    const directories = state.networks.network.network.directories.entities.map(dir => {
        return {
            id: dir.id,
            text: `Directory - ${dir.name}`,
            value: dir.id,
        };
    });

    return {
        ...state,
        networks: {
            ...state.networks,
            entities: {
                ...state.networks.entities,
                list: [...list],
                selectState: {
                    ...state.networks.entities.selectState,
                    actives: counter,
                    selected:
                        !state.networks.entities.selectState.selected && counter === 0
                            ? 'none'
                            : !state.networks.entities.selectState.selected &&
                              counter === list.length
                            ? 'all'
                            : state.networks.entities.selectState.selected,
                    directories: [...directories],
                },
            },
        },
    };
};

/**
 * Sync Network
 * @param {*} state
 * @param {*} action
 */
const syncNetwork = (state, action) => {
    const payload = action.payload.data;

    let directories = {
        entities: [],
        services: [],
        items: [],
    };

    if (payload && payload.directories) {
        payload.directories.map(dir => {
            if (dir.directory) {
                switch (dir.directory.directory_type) {
                    case 'directory-entity':
                        directories = {
                            ...directories,
                            entities: [...directories.entities, dir.directory],
                        };
                        break;
                    case 'directory-offered-service':
                        directories = {
                            ...directories,
                            services: [...directories.services, dir.directory],
                        };
                        break;
                    case 'directory-offered-item':
                        directories = {
                            ...directories,
                            items: [...directories.items, dir.directory],
                        };
                        break;

                    default:
                        break;
                }
            }
            return dir;
        });
    }

    const network = {
        ...payload,
        directories: directories,
    };

    return {
        ...state,
        networks: {
            ...state.networks,
            networks: [network],
            network: {
                ...state.networks.network,
                canSave: false,
                network: network,
            },
        },
    };
};

/**
 * Sync Networks
 * @param {*} state
 * @param {*} action
 */
const syncNetworks = (state, action) => {
    const payload = action.payload.data;
    const elements = payload.elements;

    return {
        ...state,
        networks: {
            ...state.networks,
            networks: [...elements],
        },
    };
};

/**
 * Sync Directory Members
 * @param {*} state
 * @param {*} action
 */
const syncDirectoryMembers = (state, action) => {
    const payload = action.payload.data;
    const elements = payload.elements;

    const owner = elements.filter(el => el.role === 'owner');
    const users = elements.filter(el => el.role !== 'owner');

    return {
        ...state,
        share: {
            ...state.share,
            owner: owner[0],
            members: [...users],
        },
    };
};

/**
 * Open Share Modal
 * @param {*} state
 * @param {*} action
 */
const openShareModal = (state, action) => {
    const payload = action.payload.data;
    const stateType = payload.type === 'networks' ? 'networks' : 'directories';

    const element = payload.id
        ? state[payload.type][stateType].filter(ent => ent.id === payload.id)[0]
        : null;

    return {
        ...state,
        share: {
            ...state.share,
            active: true,
            shareId: payload.id ? payload.id : null,
            shareType: stateType,
            title: element ? element.name : null,
        },
    };
};

/**
 * Close Share Modal
 * @param {*} state
 * @param {*} action
 */
const closeShareModal = (state, action) => {
    return {
        ...state,
        share: {
            ...state.share,
            active: false,
            directoryId: null,
            title: null,
            members: [],
            owner: null,
            error: null,
        },
    };
};

/**
 * Add Member Error
 * @param {*} state
 * @param {*} action
 */
const addMemberError = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        share: {
            ...state.share,
            error: payload,
        },
    };
};

/**
 * Clear Add Member Error
 * @param {*} state
 * @param {*} action
 */
const addMemberClearError = (state, action) => {
    return {
        ...state,
        share: {
            ...state.share,
            error: null,
        },
    };
};

/**
 * Manage Loading state
 * @param {*} state
 * @param {*} action
 */
const isLoading = (state, action) => {
    const payload = action.payload.isLoading;

    return {
        ...state,
        isLoading: payload,
    };
};

/**
 * Manage Saving state
 * @param {*} state
 * @param {*} action
 */
const isSaving = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        isSaving: payload,
    };
};

/**
 * Clear Integration
 * @param {*} state
 * @param {*} action
 */
const clear = (state, action) => {
    return {
        ...networks,
    };
};

const syncDirectories = (state, action) => {
    const payload = action.payload.data.elements;
    const type = action.payload.type || 'entities';

    const directories = payload.map(directory => {
        if (directory.hasOwnProperty('directory')) {
            return {
                ...directory.directory,
            };
        }
        return { ...directory };
    });

    return {
        ...state,
        [type]: {
            ...state[type],
            directories: [...directories],
            list: [],
        },
    };
};

const syncDirectory = (state, action) => {
    const payload = action.payload.data;
    const types = action.payload.types;

    return {
        ...state,
        [types.section]: {
            ...state[types.section],
            directories: [payload],
        },
    };
};

const editDirectory = (state, action) => {
    const payload = action.payload.data;
    const types = action.payload.types;

    const directories = state[types.section].directories.map(dir => {
        if (dir.id === payload.id) {
            return {
                ...dir,
                name: payload.payload.name,
            };
        }

        return dir;
    });

    return {
        ...state,
        [types.section]: {
            ...state[types.section],
            directories: [...directories],
        },
    };
};

const syncSegments = (state, action) => {
    const payload = action.payload.data.elements;

    const segments = payload.map(i => {
        return {
            id: i.id,
            text: i.name,
        };
    });

    return {
        ...state,
        segments: [...segments],
    };
};

const syncCategories = (state, action) => {
    const payload = action.payload.data.elements;

    const categories = payload.map(i => {
        return {
            id: i.id,
            text: i.name,
        };
    });

    return {
        ...state,
        categories: [...categories],
    };
};

const syncCountries = (state, action) => {
    const payload = action.payload.data.elements;

    const countries = payload.map(i => {
        return {
            id: i.id,
            text: i.name,
        };
    });

    return {
        ...state,
        countries: [...countries],
    };
};

const openElementModal = (state, action) => {
    const payload = action.payload.data;
    const types = action.payload.types;
    if (types.section === 'networks') {
        return {
            ...state,
            [types.section]: {
                ...state[types.section],
                [types.element]: {
                    ...state[types.section][types.element],
                    active: true,
                    canSave: false,
                    temp: null,
                    entity: state.networks.entities.list.filter(e => e.id === payload.id)[0],
                },
            },
        };
    }

    return {
        ...state,
        [types.section]: {
            ...state[types.section],
            [types.element]: {
                ...state[types.section][types.element],
                active: true,
                type: payload.type,
                canSave: false,
                temp: null,
                [types.element]:
                    payload.type === 'edit'
                        ? state[types.section].list.filter(e => e.id === payload.id)[0]
                        : null,
            },
        },
    };
};

const closeElementModal = (state, action) => {
    const types = action.payload.types;

    if (types.section === 'networks') {
        return {
            ...state,
            [types.section]: {
                ...state[types.section],
                [types.element]: {
                    ...state[types.section][types.element],
                    active: false,
                    canSave: false,
                    temp: null,
                    entity: null,
                    services: {
                        selectState: {
                            actives: 0,
                            selected: null,
                        },
                        list: [],
                    },
                    items: {
                        selectState: {
                            actives: 0,
                            selected: null,
                        },
                        list: [],
                    },
                },
            },
        };
    }

    return {
        ...state,
        [types.section]: {
            ...state[types.section],
            [types.element]: {
                ...state[types.section][types.element],
                active: false,
                canSave: false,
                temp: null,
            },
        },
    };
};

const syncElement = (state, action) => {
    const payload = action.payload.data;
    const types = action.payload.types;

    return {
        ...state,
        [types.section]: {
            ...state[types.section],
            [types.element]: {
                ...state[types.section][types.element],
                type: 'edit',
                canSave: false,
                temp: null,
                [types.element]: state[types.section].list.filter(e => e.id === payload.id)[0],
            },
        },
    };
};

const syncElementImage = (state, action) => {
    const payload = action.payload.data;
    const types = action.payload.types;

    return {
        ...state,
        [types.section]: {
            ...state[types.section],
            [types.element]: {
                ...state[types.section][types.element],
                // type: 'edit',
                canSave: true,
                temp: {
                    image:
                        payload.type === 'image'
                            ? payload.url
                            : state[types.section][types.element].temp &&
                              state[types.section][types.element].temp.image
                            ? state[types.section][types.element].temp.image
                            : null,
                    icon:
                        payload.type === 'icon'
                            ? payload.url
                            : state[types.section][types.element].temp &&
                              state[types.section][types.element].temp.icon
                            ? state[types.section][types.element].temp.icon
                            : null,
                },
            },
        },
    };
};

const syncDirectoryElements = (state, action) => {
    const payload = action.payload.data;
    const element = action.payload.element;
    const types = action.payload.types;
    const currentElement = state[types.section][types.element];

    if (
        element ||
        (currentElement?.active &&
            currentElement[types.element] &&
            currentElement[types.element].id)
    ) {
        const id = element ? element : currentElement[types.element].id;

        return {
            ...state,
            [types.section]: {
                ...state[types.section],
                list: [...payload.elements],
                [types.element]: {
                    ...state[types.section][types.element],
                    type: 'edit',
                    canSave: false,
                    temp: null,
                    [types.element]: payload.elements.filter(e => e.id === id)[0],
                },
            },
        };
    }

    return {
        ...state,
        [types.section]: {
            ...state[types.section],
            list: [...payload.elements],
        },
    };
};

const syncMetaDataTotals = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        dashboard: {
            ...state.dashboard,
            totals: payload,
        },
    };
};

const syncProviders = (state, action) => {
    const providers = action.payload.providers;
    return {
        ...state,
        providers: {
            ...state.providers,
            list: providers,
        },
    };
};

export default reducer;
