import { useDropdownMultiselect } from 'hooks/useDropdownMultiselect';
import { getText } from 'localization';
import React, { useEffect, useState } from 'react';
import Button from 'shared/Button';
import Popup from 'shared/Popup';
import ValidationStatus from 'shared/ValidationStatus';
import Styles from './DropdownMultiselect.module.scss';

const DropdownMultiselect = ({
    maxWidth,
    id,
    name,
    onChange,
    namespace,
    onSelectAll,
    onClearAll,
    onDone,
    selected,
    data,
    position,
    placeholder,
    disabled,
    theme,
    variant,
    size,
    block,
    withValidation,
    isValid,
    unsaved,
    isLoading,
    itemKey,
    withSearch,
    hasEmptyState,
    isCustomPosition,
    hasDoneButton = true,
    state,
}) => {
    const [value, setValue] = useState(placeholder ? placeholder : getText('dropdown_select'));
    const { popup, getContent, handleClosePopup, handleOnDone, handleTogglePopup } =
        useDropdownMultiselect(
            placeholder,
            id,
            name,
            onChange,
            namespace,
            onSelectAll,
            onClearAll,
            withSearch,
            hasEmptyState,
            maxWidth,
            itemKey,
            data,
            selected,
            onDone,
            isCustomPosition,
            hasDoneButton,
        );

    useEffect(() => {
        if (selected && Array.isArray(selected) && selected?.length > 0) {
            let names = [];
            const max = selected?.length >= 3 ? 3 : selected?.length;
            for (let i = 0; i < max; i++) {
                const temp = data.filter(item => item.id === selected[i])[0];
                if (temp) {
                    if (itemKey) {
                        if (temp[itemKey]) {
                            names.push(temp[itemKey]);
                        }
                    } else {
                        if (temp.text) {
                            names.push(temp.text);
                        }
                    }
                }
            }
            setValue(
                names.join('; ') + (selected?.length > 3 ? `... +${selected?.length - 3}` : ''),
            );
        } else {
            setValue(
                data && data[selected] && data[selected][itemKey]
                    ? data[selected][itemKey]
                    : placeholder
                    ? placeholder
                    : getText('dropdown_select'),
            );
        }
        if (maxWidth) {
            const dropdown = document.getElementById(`dropdown-${id || 'temp'}`);
            if (dropdown) {
                dropdown.style.maxWidth = `${maxWidth}`;
            }
        }
    }, [data, id, itemKey, maxWidth, placeholder, selected]);

    return (
        <div
            data-test="DropdownMultiselect"
            className={Styles.dropdown}
            id={`dropdown-${id || 'temp'}`}
        >
            <Button
                data-test="DropdownMultiselectButton"
                type="button"
                theme={theme}
                variant={variant}
                size={size}
                value={value}
                state={state ? state : 'empty'}
                onClick={handleTogglePopup}
                icon={popup?.active ? 'icon_arrow_up' : 'icon_arrow_down'}
                disabled={disabled}
                block={block}
            />

            {withValidation && !unsaved && !disabled && !isLoading && isValid && (
                <ValidationStatus status="success" />
            )}

            {withValidation && !unsaved && !disabled && !isLoading && !isValid && (
                <ValidationStatus status="error" />
            )}

            <Popup
                data-test="DropdownMultiselectPopup"
                id={`selectPopup-${id || 'temp'}`}
                position={position}
                size="md"
                active={popup?.active}
                element={popup.element}
                closePopup={onDone ? handleOnDone : handleClosePopup}
                content={popup?.active ? getContent : null}
            />
        </div>
    );
};

export default DropdownMultiselect;
