import EntityDirectory from 'pages/Networks/EntityDirectory';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    clearNetworksAction,
    networksOpenElementModalAction,
} from 'redux/domains/networks/actions';
import { networksGetDirectoryObs } from 'redux/domains/networks/observer';
import { getDirectorySelector, NETWORKS_TYPE_ENTITY } from 'redux/domains/networks/selector';
import {
    networksDeleteElementThunk,
    networksEditDirectoryThunk,
} from 'redux/domains/networks/thunk';
import { openPromptAction } from 'redux/domains/prompt/actions';

const mapStateToProps = (state, ownProps) => ({
    texts: state.texts,
    isLoading: state.app.isLoading,
    isSaving: state.networks.isSaving,
    entities: state.networks.entities.list,
    directory: getDirectorySelector(state, ownProps, NETWORKS_TYPE_ENTITY),
    activeModal: state.networks.entities.entity?.active,
    tableHeader: state.networks.entities.header,
    tableFilters: state.networks.entities.filters,
    tablePagination: state.networks.entities.pagination,
    ownProps: ownProps,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    openPrompt: data => dispatch(openPromptAction(data)),
    getDirectory: () => networksGetDirectoryObs(dispatch, ownProps, null, NETWORKS_TYPE_ENTITY),
    onEditEntityDirectory: data =>
        networksEditDirectoryThunk(dispatch, data, ownProps, NETWORKS_TYPE_ENTITY),
    onOpenEntityModal: data => dispatch(networksOpenElementModalAction(data, NETWORKS_TYPE_ENTITY)),
    onDeleteEntity: data =>
        networksDeleteElementThunk(dispatch, data, ownProps, NETWORKS_TYPE_ENTITY),
    onClearNetwork: () => dispatch(clearNetworksAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EntityDirectory));
