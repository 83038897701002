import { getText } from 'localization';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import ErrorBoundary from 'shared/ErrorBoundary';
import Loading from 'shared/Loading';
import Styles from './Dashboard.module.scss';

export class Dashboard extends React.PureComponent {
    static propTypes = {
        texts: PropTypes.object,
        projectId: PropTypes.string,
        isLoading: PropTypes.bool,
        fetchData: PropTypes.func,
        insurance: PropTypes.object,
        ownProps: PropTypes.object,
    };

    componentDidMount() {
        const { projectId, fetchData, ownProps } = this.props;

        if (!ownProps?.match?.params?.app_id && projectId) {
            ownProps.history.replace(`${ownProps.location.pathname}/app/${projectId}`);
        }

        fetchData && typeof fetchData === 'function' && fetchData();
    }

    render() {
        const { isLoading, insurance } = this.props;

        const hasProjectSelected = insurance.filters.projects.selected ? true : false;

        return (
            <ErrorBoundary>
                <section data-test="DashboardContainer" className={Styles.dashboard}>
                    <div className={Styles.scrollContainer}>
                        <div className={Styles.wrapper}>
                            <div className={Styles.row}>
                                <Link
                                    data-test="DashboardInsurees"
                                    className={Styles.container}
                                    to={hasProjectSelected ? `/insurance/insurees` : '#'}
                                >
                                    <>
                                        <h3 className={Styles.title}>
                                            {getText('insurance_dashboard_policyholders_label')}
                                        </h3>
                                        {isLoading && <Loading />}

                                        {!isLoading && (
                                            <div className={Styles.content}>
                                                <i className="icon-icon_users"></i>
                                                <p className={Styles.number}>
                                                    {hasProjectSelected
                                                        ? insurance.dashboard.totals
                                                              .total_number_of_insurees
                                                        : '--'}
                                                </p>
                                            </div>
                                        )}
                                    </>
                                </Link>
                            </div>

                            <div className={Styles.row}>
                                <Link
                                    data-test="DashboardPolicies"
                                    className={Styles.container}
                                    to={hasProjectSelected ? `/insurance/policies` : '#'}
                                >
                                    <>
                                        <h3 className={Styles.title}>
                                            {getText('insurance_dashboard_active_policies_label')}
                                        </h3>
                                        {isLoading && <Loading />}

                                        {!isLoading && (
                                            <div className={Styles.content}>
                                                <i className="icon-icon_insurance"></i>
                                                <p className={Styles.number}>
                                                    {hasProjectSelected
                                                        ? insurance.dashboard.totals
                                                              .total_number_of_policies
                                                        : '--'}
                                                </p>
                                            </div>
                                        )}
                                    </>
                                </Link>

                                <Link
                                    data-test="DashboardClaims"
                                    className={Styles.container}
                                    to={hasProjectSelected ? `/insurance/claims` : '#'}
                                >
                                    <>
                                        <h3 className={Styles.title}>
                                            {getText('insurance_dashboard_claims_label')}
                                        </h3>
                                        {isLoading && <Loading />}

                                        {!isLoading && (
                                            <div className={Styles.content}>
                                                <i className="icon-icon_flow_test"></i>
                                                <p className={Styles.number}>
                                                    {hasProjectSelected
                                                        ? insurance.dashboard.totals
                                                              .total_number_of_claims
                                                        : '--'}
                                                </p>
                                            </div>
                                        )}
                                    </>
                                </Link>
                            </div>

                            <div className={Styles.row}>
                                <Link
                                    data-test="DashboardQuotes"
                                    className={Styles.container}
                                    to={hasProjectSelected ? `/insurance/quotes` : '#'}
                                >
                                    <>
                                        <h3 className={Styles.title}>
                                            {getText('insurance_dashboard_quotes_label')}
                                        </h3>
                                        {isLoading && <Loading />}

                                        {!isLoading && (
                                            <div className={Styles.content}>
                                                <i className="icon-icon_quote"></i>
                                                <p className={Styles.number}>
                                                    {hasProjectSelected
                                                        ? insurance.dashboard.totals
                                                              .total_number_of_quotes
                                                        : '--'}
                                                </p>
                                            </div>
                                        )}
                                    </>
                                </Link>
                                <Link
                                    data-test="DashboardAssets"
                                    className={Styles.container}
                                    to={hasProjectSelected ? `/insurance/assets` : '#'}
                                >
                                    <>
                                        <h3 className={Styles.title}>
                                            {getText('insurance_dashboard_protected_assets_label')}
                                        </h3>
                                        {isLoading && <Loading />}

                                        {!isLoading && (
                                            <div className={Styles.content}>
                                                <i className="icon-icon_devices"></i>
                                                <p className={Styles.number}>
                                                    {hasProjectSelected
                                                        ? insurance.dashboard.totals
                                                              .total_number_of_protectedassets
                                                        : '--'}
                                                </p>
                                            </div>
                                        )}
                                    </>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </ErrorBoundary>
        );
    }
}

export default Dashboard;
