import rootInitialState from 'redux/store/initialState';

export const initialState = {
    user: rootInitialState.app.user,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default reducer;
