import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PrivateRoute from '../../components/medipet/PrivateRoute';
import { privateRoutes } from '../../routes';

const LogoutPage = lazy(() => import('pages/Logout'));
const AuthenticatePage = lazy(() => import('redux/containers/Authenticate'));
const MePage = lazy(() => import('redux/containers/Me'));
const ErrorPage = lazy(() => import('pages/ErrorPage'));
const LoginPage = lazy(() => import('redux/containers/LoginPage'));
const RegisterPage = lazy(() => import('redux/containers/RegisterPage'));
const RegisterFormPagePage = lazy(
    () => import('redux/containers/RegisterFormPage'),
);
const RecoverPage = lazy(() => import('redux/containers/RecoverPage'));
const ComponentsPage = lazy(() => import('pages/Components'));

const MediPetRoutes = ({
    roles,
    user,
    registerTypeSearchParam,
    registrationType,
    isAuthenticated,
}) => {
    return (
        <>
            {!isAuthenticated && (
                <Switch>
                    <Route data-test="RouteLogin" exact path="/" component={LoginPage} />
                    <Route data-test="RouteLogin" exact path="/login" component={LoginPage} />
                    <Route
                        data-test="RouteRegisterFormPage"
                        exact
                        path="/register"
                        component={RegisterFormPagePage}
                    />
                    <Route
                        data-test="RouteRegister"
                        exact
                        path="/register/invite"
                        component={RegisterPage}
                    />
                    <Route data-test="RouteRecover" exact path="/recover" component={RecoverPage} />
                    <Route
                        data-test="RouteMe"
                        exact
                        path="/me/:access_token/:refresh_token"
                        component={MePage}
                    />
                    <Route data-test="RouteError" component={LogoutPage} />
                </Switch>
            )}
            {isAuthenticated && (
                <Switch>
                    {privateRoutes.map(route => (
                        <PrivateRoute key={route.id} {...route} roles={roles} />
                    ))}

                    {user.isAdmin && (
                        <Route
                            data-test="RouteLogin"
                            exact
                            path="/components"
                            component={ComponentsPage}
                        />
                    )}

                    <Route
                        data-test="RouteRegisterFormPage"
                        exact
                        path="/register"
                        component={RegisterFormPagePage}
                    />
                    <Route
                        data-test="RouteRegister"
                        exact
                        path="/register/invite"
                        component={RegisterPage}
                    />
                    <Route data-test="RouteRecover" exact path="/recover" component={RecoverPage} />
                    <Route data-test="RouteLogout" exact path="/logout" component={LogoutPage} />

                    <Route
                        data-test="RouteAuthenticate"
                        exact
                        path="/authenticate/:id"
                        component={AuthenticatePage}
                    />
                    <Route
                        data-test="RouteMe"
                        exact
                        path="/me/:access_token/:refresh_token"
                        component={MePage}
                    />
                    <Route data-test="RouteError" component={ErrorPage} />
                </Switch>
            )}

            <Switch>
                <Redirect
                    from="/register"
                    to={{
                        pathname: '/register',
                        search: `?type=${registrationType.PROVIDER}`
                    }}
                    push
                />
            </Switch>
        </>
    );
};

export default MediPetRoutes;
