import React from 'react';
import PropTypes from 'prop-types';
import Styles from './NivoPieChart.module.scss';
import { ResponsivePie } from '@nivo/pie';
import { fakeData } from './__MOCKS__';

export class NivoPieChart extends React.PureComponent {
    state = {
        fakeData: null,
    };

    static defaultProps = {
        isInteractive: true,
        sliceLabel: null,
        settings: {
            margin: { top: 10, right: 10, bottom: 10, left: 10 },
            innerRadius: 0.5,
            padAngle: 0,
            cornerRadius: 0,
            borderWidth: 0,
            colors: ['#f9bb00', '#f2f5f8'],
        },
    };

    static propTypes = {
        data: PropTypes.array,
        settings: PropTypes.object,
        sliceLabel: PropTypes.func,
        height: PropTypes.string,
        isInteractive: PropTypes.bool,
    };

    componentDidMount() {
        if (!this.props.data) {
            this.setState({ fakeData: fakeData });
        }
    }

    render() {
        const { data, settings, sliceLabel, height, isInteractive } = this.props;
        const { fakeData } = this.state;

        return (
            <div className={Styles.chart} style={{ height: height }}>
                <ResponsivePie
                    data={data ? data : fakeData}
                    margin={settings.margin}
                    innerRadius={settings.innerRadius}
                    padAngle={settings.padAngle}
                    cornerRadius={settings.cornerRadius}
                    colors={settings.colors}
                    borderWidth={settings.borderWidth}
                    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                    enableRadialLabels={false}
                    radialLabelsSkipAngle={10}
                    radialLabelsTextXOffset={6}
                    radialLabelsTextColor="#333333"
                    radialLabelsLinkOffset={0}
                    radialLabelsLinkDiagonalLength={16}
                    radialLabelsLinkHorizontalLength={24}
                    radialLabelsLinkStrokeWidth={1}
                    radialLabelsLinkColor={{ from: 'color' }}
                    enableSlicesLabels={sliceLabel ? true : false}
                    slicesLabelsSkipAngle={10}
                    slicesLabelsTextColor="#ffffff"
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    sliceLabel={sliceLabel}
                    isInteractive={isInteractive}
                    enableArcLabels={false}
                    enableArcLinkLabels={false}
                    // sliceLabel={function (e) {
                    //     return `${e.value}%`;
                    // }}
                    // tooltip={function (e) {
                    //     return `${e.label}: ${e.value}%`;
                    // }}
                />
            </div>
        );
    }
}

export default NivoPieChart;
