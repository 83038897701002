import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './Styles.scss';

// const Zoom = cssTransition({
//     enter: 'zoomIn',
//     exit: 'zoomOut',
//     appendPosition: false,
//     collapse: true,
//     collapseDuration: 500,
// });

const CloseButton = ({ closeToast }) => <i className="icon-icon_delete_2" onClick={closeToast}></i>;

CloseButton.propTypes = {
    closeToast: PropTypes.func,
};

export class Toast extends React.PureComponent {
    static propTypes = {
        texts: PropTypes.object,
    };

    render() {
        return (
            <ToastContainer
                data-testid="ToastContainer"
                // transition={Zoom}
                transition={Slide}
                closeButton={<CloseButton />}
                hideProgressBar
                closeOnClick
                pauseOnHover
                draggable={false}
                newestOnTop
                rtl={false}
                theme="colored"
                icon={false}
            />
        );
    }
}

export default Toast;
