import ActionTypes from 'redux/actionTypes';
import rootInitialState from 'redux/store/initialState';

export const initialState = {
    compatibleDevices: rootInitialState.compatibleDevices,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.COMPATIBLE_DEVICES_MODAL_IS_SAVING:
            return compatibleDevicesModalIsSaving(state, action);
        case ActionTypes.COMPATIBLE_DEVICES_OPEN_PUBLIC_MODAL:
            return openPublicCompatibleDevicesModal(state, action);
        case ActionTypes.COMPATIBLE_DEVICES_CLOSE_PUBLIC_MODAL:
            return closePublicCompatibleDevicesModal(state, action);
        case ActionTypes.COMPATIBLE_DEVICES_OPEN_PRIVATE_MODAL:
            return openPrivateCompatibleDevicesModal(state, action);
        case ActionTypes.COMPATIBLE_DEVICES_CLOSE_PRIVATE_MODAL:
            return closePrivateCompatibleDevicesModal(state, action);
        case ActionTypes.COMPATIBLE_DEVICES_ADD_DEVICE:
            return addCompatibleDevice(state, action);
        case ActionTypes.COMPATIBLE_DEVICES_REMOVE_DEVICE:
            return removeCompatibleDevice(state, action);
        case ActionTypes.COMPATIBLE_DEVICES_PUBLIC_FETCH:
            return fetchPublicCompatibleDevices(state, action);
        case ActionTypes.COMPATIBLE_DEVICES_FETCH:
            return fetchCompatibleDevices(state, action);
        case ActionTypes.COMPATIBLE_DEVICES_SORT_BY:
            return sortByCompatibleDevices(state, action);
        case ActionTypes.COMPATIBLE_DEVICES_FILTER:
            return filterByCompatibleDevices(state, action);
        case ActionTypes.COMPATIBLE_DEVICES_CLEAR_SORT_BY:
            return clearSortByCompatibleDevices(state, action);
        case ActionTypes.COMPATIBLE_DEVICES_MODAL_ERROR:
            return compatibleDevicesError(state, action);
        case ActionTypes.COMPATIBLE_DEVICES_ENABLED_FETCH:
            return fetchEnabledDevices(state, action);
        case ActionTypes.COMPATIBLE_DEVICES_SYNC_ASSETS:
            return syncDeviceAssets(state, action);
        default:
            return state;
    }
};

const compatibleDevicesError = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        hasError: payload,
    };
};

const compatibleDevicesModalIsSaving = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        isSaving: payload,
    };
};

/**
 * Open Public Modal
 * @param {*} state
 */
const openPublicCompatibleDevicesModal = (state, action) => {
    const payload = action.payload.data;
    // const device = state.devices[payload.type].filter(device => device.template.id === payload.id);

    const devicePublic = state.devices.public.filter(device => device.template.id === payload.id);
    const devicePrivate = state.devices.private.filter(device => device.template.id === payload.id);
    const deviceMine = state.devices.mine.filter(device => device.template.id === payload.id);

    let device = null;
    if (devicePublic && devicePublic[0]) {
        device = devicePublic[0];
    } else if (devicePrivate && devicePrivate[0]) {
        device = devicePrivate[0];
    } else if (deviceMine && deviceMine[0]) {
        device = deviceMine[0];
    } else {
        device = null;
    }

    return {
        ...state,
        public: {
            ...state.public,
            active:
                (state.isSaving && !state.public?.active) ||
                (!state.isSaving && state.public?.active)
                    ? true
                    : false,
            device: device,
        },
        isSaving: false,
    };
};

/**
 * Close Public Modal
 * @param {*} state
 */
const closePublicCompatibleDevicesModal = (state, action) => {
    return {
        ...state,
        hasError: {},
        public: {
            ...state.public,
            active: false,
            device: null,
        },
    };
};

/**
 * Add Device
 * @param {*} state
 */
const addCompatibleDevice = (state, action) => {
    const payload = action.payload.data;

    const deviceTemp = state.devices[payload.type].filter(
        device => device.template.id === payload.deviceId,
    );

    const device = {
        ...deviceTemp[0],
        secret: payload.secret,
        token: payload.token,
        availableTo: payload.availableTo,
        email: payload.email,
    };

    return {
        ...state,
        devices: {
            ...state.devices,
            added: [
                ...state.devices.added.filter(device => device.template.id !== payload.deviceId),
                device,
            ],
        },
        public: {
            ...state.public,
            active: false,
        },
        private: {
            ...state.private,
            active: false,
        },
        mine: {
            ...state.mine,
            active: false,
        },
    };
};

/**
 * Remove Device
 * @param {*} state
 */
const removeCompatibleDevice = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        devices: {
            ...state.devices,
            added: [...state.devices.added.filter(device => device.template.id !== payload.id)],
        },
        [payload.type]: {
            ...state[payload.type],
            active: false,
        },
    };
};

/**
 * Fetch Public Devices
 * @param {*} state
 */
const fetchPublicCompatibleDevices = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        devices: {
            ...state.devices,
            public: [...payload.elements],
        },
    };
};

/**
 * Fetch Devices By Type
 * @param {*} state
 */
const fetchCompatibleDevices = (state, action) => {
    const payload = action.payload.data;
    const enabled = payload.enabled;

    let elements = [...payload.elements];

    if (payload && enabled && payload.elements.length && enabled.elements.length) {
        elements = payload.elements.map(device => {
            let found = false;
            for (let i = 0; i < enabled.elements.length; i++) {
                if (device.template.id === enabled.elements[i].template.id) {
                    found = enabled.elements[i];
                }
            }

            return {
                ...device,
                enabled: found,
            };
        });
    }

    return {
        ...state,
        devices: {
            ...state.devices,
            [payload.type]: [...elements],
        },
    };
};

/**
 * Fetch Devices By Type
 * @param {*} state
 */
const fetchEnabledDevices = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        devices: {
            ...state.devices,
            added: [...payload.elements],
        },
    };
};

/**
 * Sort By
 * @param {*} state
 */
const sortByCompatibleDevices = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        devices: {
            ...state.devices,
            sort: {
                ...state.devices.sort,
                selected: payload.sort.id.toString(),
            },
        },
    };
};

/**
 * Filter By
 * @param {*} state
 */
const filterByCompatibleDevices = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        filters: {
            ...state.filters,
            selected: payload.id,
        },
    };
};

/**
 * Sort By
 * @param {*} state
 */
const clearSortByCompatibleDevices = (state, action) => {
    return {
        ...state,
        filters: {
            ...state.filters,
            selected: 0,
        },
        devices: {
            ...state.devices,
            sort: {
                ...state.devices.sort,
                selected: null,
            },
        },
    };
};

/**
 * Open Private Modal
 * @param {*} state
 */
const openPrivateCompatibleDevicesModal = (state, action) => {
    return {
        ...state,
        private: {
            ...state.private,
            active: true,
        },
    };
};

/**
 * Close Private Modal
 * @param {*} state
 */
const closePrivateCompatibleDevicesModal = (state, action) => {
    return {
        ...state,
        hasError: {},
        private: {
            ...state.private,
            active: false,
        },
    };
};

const syncDeviceAssets = (state, action) => {
    const payload = action.payload.data;
    let elements = payload.elements;

    if (state.public.device && state.public.device.enabled) {
        const enabled = state.public.device.enabled.cdata;

        if (enabled) {
            const keys = Object.keys(enabled);

            elements = payload.elements.map(asset => {
                const field = asset.targets.enabled_channeltemplate.builder_paths[0];

                // eslint-disable-next-line no-unused-vars
                for (const key of keys) {
                    if (`payload.cdata.${key}` === field) {
                        asset.content = enabled[key];
                    }
                }

                return asset;
            });
        }
    }

    if (action.payload.close) {
        return closePublicCompatibleDevicesModal(state, action);
    } else {
        return {
            ...state,
            public: {
                ...state.public,
                active: true,
                device: {
                    ...state.public.device,
                    assets: [...elements],
                },
            },
        };
    }
};

export default reducer;
