import React from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './Map.scss';

class Map extends React.PureComponent {
    // TODO: Refactor

    _map = null;
    _markers = {};
    _markerGroup = null;
    _initialZoom = 3;
    _fitBoundsMaxZoom = 10;
    _fitBounds = [];
    _fixViewPosition = [350, 0];

    static propTypes = {
        type: PropTypes.oneOf(['single', 'multiple']),
        center: PropTypes.array,
        data: PropTypes.array,
        selected: PropTypes.object,
        onSelect: PropTypes.func,
    };

    static defaultProps = {
        type: 'single',
        center: [38.7479075, -9.1622213],
    };

    componentDidMount() {
        this.initMap();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            ((!prevProps.data || prevProps.data.length === 0) &&
                this.props.data &&
                this.props.data.length > 0) ||
            (prevProps.data && prevProps.data !== this.props.data)
        ) {
            this.initMap();
        }

        if (!this.props.data || (this.props.data && this.props.data.length === 0)) {
            this.clearMap();
        }

        if (
            (!prevProps.selected && this.props.selected) ||
            (prevProps.selected &&
                this.props.selected &&
                prevProps.selected.place_id !== this.props.selected.place_id)
        ) {
            const marker = this.props.data.filter(
                marker => marker.place_id === this.props.selected.place_id,
            );
            if (marker[0]) {
                this.changeMap(marker[0]);
            }
        }
    }

    componentWillUnmount() {
        if (this._map) {
            this._map.off();
            this._map.remove();
            this._map.invalidateSize();
            this._map = null;
        }
    }

    initMap() {
        const { data, selected, type, center } = this.props;
        const light_1 = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png';

        if (!this._map) {
            this._map = L.map('map', {
                center: center,
                zoom: this._initialZoom,
                zoomControl: true,
                preferCanvas: true,
                layers: [L.tileLayer(light_1)],
            });

            this._map.zoomControl.setPosition('topright');
        }

        switch (type) {
            case 'multiple':
                if (data && data.length > 0) {
                    this._markerGroup = L.layerGroup().addTo(this._map);
                    for (let i = 0; i < data.length; i++) {
                        if (data[i] && Object.keys(data[i]).length > 0) {
                            this.addMarker(data[i]);
                            this._fitBounds.push([data[i].latitude, data[i].longitude]);
                        }
                    }

                    setTimeout(() => {
                        const bounds = new L.LatLngBounds(this._fitBounds);
                        this._map.fitBounds(bounds, {
                            maxZoom: this._fitBoundsMaxZoom,
                            paddingTopLeft: this._fixViewPosition,
                        });
                    }, 0);
                }
                break;

            default:
                if (selected && Object.keys(selected).length > 0) {
                    this.addMarker(selected);
                }
                break;
        }
    }

    clearMap() {
        if (this._map) {
            this._map.off();
            this._map.remove();
            this._map.invalidateSize();
            this._map = null;

            this.initMap();
        }
    }

    changeMap(data) {
        if (data && data.latitude && data.longitude) {
            Object.keys(this._markers).forEach(item => {
                this._markers[item]._icon.classList.remove('active');
                this._markers[item].closeTooltip();
            });

            this._markers[this.props.selected.place_id].openTooltip();
            this._markers[this.props.selected.place_id]._icon.classList.add('active');

            this._map.setView(new L.latLng(data.latitude, data.longitude));
            this.changeMarker(data);
        }
    }

    addMarker(data) {
        const selected =
            this.props.selected && data.place_id === this.props.selected.place_id ? true : false;

        let icon = null;
        let offset = [];
        if (data.icon) {
            icon = L.divIcon({
                className: selected ? `icon ${data.icon} active` : `icon ${data.icon}`,
                iconSize: [10, 10],
                popupAnchor: [10, 5],
            });
            offset = [10, 0];
        } else {
            icon = L.divIcon({
                className: selected ? 'icon empty active' : 'icon empty',
                iconSize: [5, 5],
                popupAnchor: [5, 2],
            });
            offset = [5, 0];
        }

        if (data.street_name) {
            this._markers[data.place_id] = L.marker(
                [data[0] ? data[0] : data.latitude, data[1] ? data[1] : data.longitude],
                {
                    icon: icon,
                    title:
                        data.street_name && data.street_name.trim() !== ''
                            ? data.street_name
                            : null,
                    alt:
                        data.street_name && data.street_name.trim() !== ''
                            ? data.street_name
                            : null,
                    riseOnHover: true,
                },
            )
                // .bindPopup(data.street_name)
                .bindTooltip(
                    data.street_name && data.street_name.trim() !== '' ? data.street_name : null,
                    { direction: 'top', offset: offset },
                )
                .on('click', e => this.onClickMarker(e, data))
                .addTo(this._markerGroup);
        } else {
            this._markers[data.place_id] = L.marker(
                [data[0] ? data[0] : data.latitude, data[1] ? data[1] : data.longitude],
                {
                    icon: icon,
                    title: 'My marker',
                    alt: 'My marker',
                    riseOnHover: true,
                },
            )
                // .bindPopup(data.street_name)
                .bindTooltip('Marker', { direction: 'top', offset: offset })
                .on('click', e => this.onClickMarker(e, data))
                .addTo(this._markerGroup);
        }
    }

    changeMarker(data) {
        if (!this._markers) {
            this.addMarker(data);
        }
    }

    removeMarkers = () => {
        this._map.eachLayer(function (layer) {
            if (
                layer instanceof L.Marker &&
                layer.isRandom /* ensure that we are not removing any other markers available in the map, see how the marker is added to the map */
            ) {
                layer.remove(); // layer.removeFrom(map); map.removeLayer(layer);
            }
        });
    };

    onClickMarker(e, data) {
        e.target.openTooltip();
        this.props.onSelect([data]);
    }

    render() {
        return <div data-test="MapContainer" id="map"></div>;
    }
}

export default Map;
