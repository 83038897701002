import ActionTypes from 'redux/actionTypes';
import rootInitialState from 'redux/store/initialState';

export const initialState = {
    businessRuleEngine: rootInitialState.businessRuleEngine,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.BRE_IS_LOADING:
            return isLoading(state, action);
        case ActionTypes.BRE_REQUESTED:
            return requested(state, action);
        case ActionTypes.BRE_CLEAR:
            return clear(state, action);
        case ActionTypes.BRE_SYNC_RETRIEVE:
            return syncRetrieve(state, action);
        default:
            return state;
    }
};

/**
 * Sync retrieve
 * @param {*} state
 * @param {*} action
 */
const syncRetrieve = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        empty: Object.keys(payload).length === 0 ? true : false,
        data: payload,
    };
};

/**
 * Clear
 * @param {*} state
 * @param {*} action
 */
const clear = (state, action) => {
    return {
        ...rootInitialState.businessRuleEngine,
    };
};

/**
 * Requested
 * @param {*} state
 * @param {*} action
 */
const requested = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        requested: payload,
    };
};

/**
 * Is Loading
 * @param {*} state
 * @param {*} action
 */
const isLoading = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        isLoading: payload,
    };
};

export default reducer;
