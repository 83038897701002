import ActionTypes from 'redux/actionTypes';
import rootInitialState from 'redux/store/initialState';
import {
    myProvidersTableListModel,
    insuranceProductsTableListModel,
    insuranceProductsAddedListModel,
    insuranceProductsMetaModel,
} from './model';

export const initialState = {
    providers: rootInitialState.providers,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.PROVIDERS_IS_LOADING:
            return isLoading(state, action);
        case ActionTypes.PROVIDERS_IS_SAVING:
            return isSaving(state, action);
        case ActionTypes.PROVIDERS_CLEAR:
            return clear(state, action);
        case ActionTypes.PROVIDERS_ADD_INSURANCE_OPEN_MODAL:
            return openAddInsuranceModal(state, action);
        case ActionTypes.PROVIDERS_ADD_INSURANCE_CLOSE_MODAL:
            return closeAddInsuranceModal(state, action);
        case ActionTypes.PROVIDERS_ADD_INSURANCE_SYNC_INSURANCE_LIST:
            return syncInsuranceList(state, action);
        case ActionTypes.PROVIDERS_ADD_INSURANCE_SYNC_ADDED_INSURANCE_LIST:
            return syncAddedInsuranceList(state, action);
        case ActionTypes.PROVIDERS_ADD_INSURANCE_SYNC_ADDED_INSURANCE_META_LIST:
            return syncAddedInsuranceMetaList(state, action);
        case ActionTypes.PROVIDERS_ADD_INSURANCE_SYNC_FILTER:
            return syncAddedInsuranceFiltersType(state, action);
        case ActionTypes.PROVIDERS_MY_PROVIDERS_SYNC_FILTER:
            return syncMyProvidersFiltersType(state, action);
        case ActionTypes.PROVIDERS_REQUEST_OPEN_MODAL:
            return openRequestModal(state, action);
        case ActionTypes.PROVIDERS_REQUEST_CLOSE_MODAL:
            return closeRequestModal(state, action);
        case ActionTypes.PROVIDERS_REQUEST_SYNC_PROVIDER:
            return syncProviderRequest(state, action);
        case ActionTypes.PROVIDERS_SYNC_PROVIDER_REQUESTS:
            return syncProviderRequests(state, action);
        case ActionTypes.PROVIDERS_SYNC_API_ACCESS_CREDENTIALS:
            return syncApiAccessCredentials(state, action);
        default:
            return state;
    }
};

const syncApiAccessCredentials = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        api: {
            ...state.api,
            credentials: payload,
        },
    };
};

const syncProviderRequests = (state, action) => {
    // console.log('P1');
    // console.log(action);
    const payload = action.payload.data;

    return {
        ...state,
        myProviders: {
            ...state.myProviders,
            list: myProvidersTableListModel(payload.elements),
        },
    };
};

const syncProviderRequest = (state, action) => {
    const payload = action.payload.data;

    const history =
        payload.history.length > 0
            ? payload.history
                  .filter(
                      item =>
                          item.action === 'pending' ||
                          item.action === 'approved' ||
                          item.action === 'rejected',
                  )
                  .map(item => {
                      return {
                          id: item.id,
                          state: item.action,
                          approved_ts: item.created,
                          pending_ts: item.created,
                          rejected_ts: item.created,
                          client: item.client,
                          rejected_comment:
                              item.action === 'rejected' && item.description !== ''
                                  ? item.description
                                  : null,
                      };
                  })
            : null;

    return {
        ...state,
        myProviders: {
            ...state.myProviders,
            modal: {
                ...state.myProviders.modal,
                data: { ...payload, history },
            },
        },
    };
};

const openRequestModal = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        myProviders: {
            ...state.myProviders,
            modal: {
                ...state.myProviders.modal,
                active: true,
                providerId: payload.id,
            },
        },
    };
};

const closeRequestModal = (state, action) => {
    // const payload = action.payload.data;

    return {
        ...state,
        myProviders: {
            ...state.myProviders,
            modal: rootInitialState.providers.myProviders.modal,
        },
    };
};

const syncAddedInsuranceFiltersType = (state, action) => {
    const payload = action.payload.data;
    const type = action.payload.type;

    const elements = payload.elements.map(i => {
        return {
            id: i.id,
            text: i.name,
        };
    });

    return {
        ...state,
        insurance: {
            ...state.insurance,
            modal: {
                ...state.insurance.modal,
                selectors: {
                    ...state.insurance.modal.selectors,
                    [type]: {
                        list: elements,
                        selected: [],
                    },
                },
            },
        },
    };
};

const syncMyProvidersFiltersType = (state, action) => {
    const payload = action.payload.data;
    const type = action.payload.type;

    const elements = payload.elements.map(i => {
        return {
            id: i.id,
            text: i.name,
        };
    });

    return {
        ...state,
        myProviders: {
            ...state.myProviders,
            selectors: {
                ...state.myProviders.selectors,
                [type]: {
                    list: elements,
                    selected: [],
                },
            },
        },
    };
};

const syncAddedInsuranceList = (state, action) => {
    const payload = action.payload.data;

    // const list = payload.elements.filter(item => item.enabled === true);
    return {
        ...state,
        insurance: {
            ...state.insurance,
            // list: payload.elements.filter(item => item.state !== 'submitted'),
            list: insuranceProductsAddedListModel(payload.elements),
        },
    };
};

const syncAddedInsuranceMetaList = (state, action) => {
    // console.log('P2');
    // console.log(action);
    const payload = action.payload.data;

    return {
        ...state,
        insurance: {
            ...state.insurance,
            list: insuranceProductsMetaModel(payload, state.insurance.list),
        },
    };
};

const syncInsuranceList = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        insurance: {
            ...state.insurance,
            modal: {
                ...state.insurance.modal,
                list: insuranceProductsTableListModel(payload.elements, state.insurance.list),
            },
        },
    };
};

const openAddInsuranceModal = (state, action) => {
    // const payload = action.payload.data;

    return {
        ...state,
        insurance: {
            ...state.insurance,
            modal: {
                ...state.insurance.modal,
                active: true,
                // id: payload.clientservice_id,
            },
        },
    };
};

const closeAddInsuranceModal = (state, action) => {
    return {
        ...state,
        insurance: {
            ...state.insurance,
            modal: {
                ...state.insurance.modal,
                active: false,
                list: [],
                pagination: {
                    current: '--',
                    total: '--',
                    next: true,
                    page_start_index: 0,
                },
                filters: {
                    field: 'payday',
                    direction: 'desc',
                },
            },
        },
    };
};

/**
 * Is Loading
 * @param {*} state
 * @param {*} action
 */
const isLoading = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        isLoading: payload,
    };
};

/**
 * Is Saving
 * @param {*} state
 * @param {*} action
 */
const isSaving = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        isSaving: payload,
    };
};

/**
 * Is Saving
 * @param {*} state
 * @param {*} action
 */
const clear = (state, action) => {
    return {
        ...rootInitialState.providers,
    };
};

export default reducer;
