import React from 'react';
import Styles from '../Table.module.scss';

/**
 * @param {() => void} onClick
 * @param {string | undefined} label
 * @param {string} dataTip
 * @param {any} value
 * @param {string | undefined} iconCopyClassName
 * @param {boolean | undefined} hasCopyButton
 * @param {boolean | undefined} isParagraph
 * @returns {JSX.Element}
 */
const CardItem = ({
    label,
    value,
    onClick,
    hasCopyButton,
    dataTip,
    iconCopyClassName = 'icon-icon_copy',
    isParagraph = false,
}) => {
    const content = () => {
        return (
            <p>
                {label ? <span>{label + ':'}</span> : null} {value ? value : null}
                {hasCopyButton ? (
                    <i
                        className={iconCopyClassName}
                        data-tip={dataTip}
                        data-value={value}
                        onClick={onClick}
                    ></i>
                ) : null}
            </p>
        );
    };

    const listWrapper = child => <li className={Styles.cardListItem}>{child}</li>;
    return isParagraph ? content() : listWrapper(content());
};

export default CardItem;
