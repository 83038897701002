import { useUploadApi } from 'hooks/useUploadApi';
import { getText } from 'localization';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Popup from 'shared/Popup';
import UploadPaymentDocuments from 'shared/UploadPaymentDocuments';
import { getElementPosition } from 'utils/functions';
import Styles from './AttachDocumentsPopup.module.scss';

const AttachDocumentsPopup = ({
    id,
    data,
    onUpload,
    onDelete,
    notice,
    credit_note,
    invoice,
    disabled,
    isSaving,
    isLoading,
    project,
}) => {
    const { filePreview, isUploading, doUpload, clearUpload, fileType, setFileType } = useUploadApi(
        undefined,
        onUpload,
    );
    const [isActive, setIsActive] = useState(false);
    const [element, setElement] = useState(null);

    const handleFileUpload = e => {
        if (e.target.files) {
            const type = e.target.name;
            const file = e.target.files[0];

            if (!file || !file.type) {
                return false;
            }

            e.target.value = '';

            const payload = {
                id: data.id,
                content: file,
            };
            doUpload(payload);
            setFileType(type);
        }
    };

    const getUserSettingsPopupContent = () => {
        return (
            <ul>
                <li>
                    <UploadPaymentDocuments
                        placeholder={getText('attach_payments_and_documents_upload_premium_notice')}
                        text={getText('payments_and_documents_upload_premium_notice')}
                        name="notice"
                        types="image/*, .pdf, .doc"
                        onChange={handleFileUpload}
                        disabled={isUploading || disabled}
                        file={notice?.uri ? notice.uri : filePreview}
                        subscriptionId={notice?.clientservice_id}
                        assetId={notice?.id}
                        onDelete={handleOnDeleteFile}
                        isLoading={isLoading || isSaving}
                        isSaving={isSaving}
                        isUploading={isUploading && fileType === 'notice'}
                        project={project}
                        withDeletion={false}
                    />
                </li>
                <li>
                    <UploadPaymentDocuments
                        placeholder={getText('attach_payments_and_documents_upload_attach_invoice')}
                        text={getText('payments_and_documents_upload_attach_invoice')}
                        name="invoice"
                        types="image/*, .pdf, .doc"
                        onChange={handleFileUpload}
                        disabled={isUploading || disabled}
                        file={invoice?.uri ? invoice.uri : filePreview}
                        subscriptionId={invoice?.clientservice_id}
                        assetId={invoice?.id}
                        onDelete={handleOnDeleteFile}
                        isLoading={isLoading || isSaving}
                        isSaving={isSaving}
                        isUploading={isUploading && fileType === 'invoice'}
                        project={project}
                        withDeletion={false}
                    />
                </li>
                <li>
                    <UploadPaymentDocuments
                        placeholder={getText('attach_payments_and_documents_upload_credit_note')}
                        text={getText('payments_and_documents_upload_credit_note')}
                        name="credit_note"
                        types="image/*, .pdf, .doc"
                        onChange={handleFileUpload}
                        disabled={isUploading || disabled}
                        file={credit_note?.uri ? credit_note.uri : filePreview}
                        subscriptionId={credit_note?.clientservice_id}
                        assetId={credit_note?.id}
                        onDelete={handleOnDeleteFile}
                        isLoading={isLoading || isSaving}
                        isSaving={isSaving}
                        isUploading={isUploading && fileType === 'credit_note'}
                        project={project}
                        withDeletion={false}
                    />
                </li>
            </ul>
        );
    };

    const handleOnDeleteFile = e => {
        onDelete && typeof onDelete === 'function' && onDelete(e);
        clearUpload();
    };

    const handleTogglePopup = e => {
        setIsActive(true);
        setElement(getElementPosition(e.currentTarget));
    };

    const handleClosePopup = () => {
        setIsActive(false);
        setElement(null);
    };

    return (
        <div data-testid="Container" className={Styles.container}>
            {(!data || data.length === 0) && (
                <span
                    data-testid="AttachBtn"
                    className={`${Styles.default} ${isActive ? Styles?.active : ''}`}
                    onClick={handleTogglePopup}
                >
                    <i className="icon-icon_attachment"></i> {getText('common_attach_file')}
                </span>
            )}

            {data && data.length > 0 && (
                <span
                    className={`${Styles.default} ${Styles.withFiles} ${
                        isActive ? Styles?.active : ''
                    }`}
                    onClick={handleTogglePopup}
                >
                    <i className="icon-icon_flow_test"></i> {data.length} {getText('common_file')}
                    (s)
                </span>
            )}

            <Popup
                data-test="AttachDocumentsPopup"
                position="bottom"
                id={`attachDocumentsPopup-${id}`}
                active={isActive}
                element={element}
                closePopup={handleClosePopup}
                content={getUserSettingsPopupContent()}
            />
        </div>
    );
};

AttachDocumentsPopup.propTypes = {
    id: PropTypes.string,
    data: PropTypes.array,
    onUpload: PropTypes.func,
    onDelete: PropTypes.func,
    notice: PropTypes.object,
    invoice: PropTypes.object,
    disabled: PropTypes.bool,
    isSaving: PropTypes.bool,
    isLoading: PropTypes.bool,
    project: PropTypes.object,
};

export default AttachDocumentsPopup;
