import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './ButtonAnchor.module.scss';

export class ButtonAnchor extends React.PureComponent {
    static propTypes = {
        theme: PropTypes.oneOf([
            'yellow',
            'blue',
            'red',
            'sport',
            'pet',
            'car',
            'default',
            'green',
        ]),
        variant: PropTypes.oneOf(['filled']),
        size: PropTypes.oneOf(['sm', 'md', 'lg']),
        text: PropTypes.string,
        url: PropTypes.string,
        disabled: PropTypes.bool,
        icon: PropTypes.string,
        iconPosition: PropTypes.oneOf(['before', 'after']),
        onClick: PropTypes.func,
        target: PropTypes.string,
        onMouseOver: PropTypes.func,
        onMouseOut: PropTypes.func,
    };

    static defaultProps = {
        theme: 'yellow',
        variant: 'filled',
        size: 'md',
        url: '#',
        target: '_blank',
        disabled: false,
    };

    render() {
        const { url, text, icon, iconPosition, theme, variant, size, onClick, target, disabled } =
            this.props;

        return (
            <div
                data-test="ButtonLinkContainer"
                className={classnames(Styles.container, Styles[theme])}
                onMouseOver={this.props.onMouseOver ? this.props.onMouseOver : () => {}}
                onMouseOut={this.props.onMouseOut ? this.props.onMouseOut : () => {}}
            >
                <a data-test="ButtonLinkAnchor" href={url} target={target} onClick={onClick}>
                    <button
                        data-test="ButtonLinkBtn"
                        type="button"
                        disabled={disabled}
                        className={classnames(
                            Styles.button,
                            Styles[theme],
                            Styles[size],
                            Styles[variant],
                        )}
                    >
                        {icon && iconPosition === 'before' && (
                            <i
                                data-test="ButtonIcon"
                                className={classnames(
                                    Styles.icon,
                                    Styles[iconPosition],
                                    `icon-${icon}`,
                                )}
                            ></i>
                        )}

                        {text}

                        {icon && iconPosition === 'after' && (
                            <i
                                data-test="ButtonIcon"
                                className={classnames(
                                    Styles.icon,
                                    Styles[iconPosition],
                                    `icon-${icon}`,
                                )}
                            ></i>
                        )}
                    </button>
                </a>
            </div>
        );
    }
}

export default ButtonAnchor;
