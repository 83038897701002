import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { roles, userHasPermission, userIsOnlyAdmin } from 'services/roles';
import BrowserAlert from 'shared/BrowserAlert';
import Loading from 'shared/Loading';
import { RegistrationType } from 'types/global';
import { checkIsIE } from 'utils/functions';
import { getProjectInfo } from 'utils/helpers';
import MediPetRoutes from './projects/MediPetRoutes';
import SelfcareRoutes from './projects/SelfcareRoutes';
export class Router extends React.PureComponent {
    state = {
        isLoading: false,
        isAuthenticated: false,
        isIE: false,
    };

    static propTypes = {
        history: PropTypes.object,
        isAuthenticated: PropTypes.bool,
        checkSession: PropTypes.func,
    };

    static getDerivedStateFromProps(props, state) {
        props.checkSession();
        return null;
    }

    componentDidMount() {
        if (checkIsIE()) {
            this.setState({ isIE: true });
        }
    }

    render() {
        const { currentProject } = getProjectInfo();
        const user = {
            isOnlyAdmin: userIsOnlyAdmin(),
            isAdmin: userHasPermission([roles.ADMIN]),
        };

        const { history, isAuthenticated } = this.props as typeof Router.propTypes;
        const currentUrl = new URL(window.location.href);

        const registerTypeSearchParam = currentUrl.searchParams.get('type');
        const registrationType: {
            [key: string]: RegistrationType;
        } = {
            INSURER: 'insurer',
            DISTRIBUTOR: 'distributor',
            PROVIDER: 'provider',
        };

        if (this.state.isIE) {
            return <BrowserAlert />;
        }

        return (
            <ConnectedRouter history={history}>
                <Suspense fallback={<Loading theme="full" />}>
                    {(currentProject === 'selfcare' || currentProject === 'b2b') && (
                        <SelfcareRoutes
                            isAuthenticated={isAuthenticated}
                            roles={roles}
                            user={user}
                            registerTypeSearchParam={registerTypeSearchParam}
                            registrationType={registrationType}
                        />
                    )}
                    {currentProject === 'medipet' && (
                        <MediPetRoutes
                            isAuthenticated={isAuthenticated}
                            roles={roles}
                            user={user}
                            registerTypeSearchParam={registerTypeSearchParam}
                            registrationType={registrationType}
                        />
                    )}
                </Suspense>
            </ConnectedRouter>
        );
    }
}

export default Router;
