export const fakeData = [
    {
        id: 'Users',
        color: 'hsl(333, 70%, 50%)',
        data: [
            {
                x: '01',
                y: 33,
            },
            {
                x: '02',
                y: 259,
            },
            {
                x: '03',
                y: 147,
            },
            {
                x: '04',
                y: 262,
            },
            {
                x: '05',
                y: 21,
            },
            {
                x: '06',
                y: 21,
            },
            {
                x: '07',
                y: 167,
            },
            {
                x: '08',
                y: 141,
            },
            {
                x: '09',
                y: 64,
            },
            {
                x: '10',
                y: 243,
            },
            {
                x: '11',
                y: 201,
            },
            {
                x: '12',
                y: 221,
            },
            {
                x: '13',
                y: 33,
            },
            {
                x: '14',
                y: 259,
            },
            {
                x: '15',
                y: 147,
            },
            {
                x: '16',
                y: 262,
            },
            {
                x: '17',
                y: 21,
            },
            {
                x: '18',
                y: 21,
            },
            {
                x: '19',
                y: 167,
            },
            {
                x: '20',
                y: 141,
            },
            {
                x: '21',
                y: 64,
            },
            {
                x: '22',
                y: 243,
            },
            {
                x: '23',
                y: 201,
            },
            {
                x: '24',
                y: 221,
            },
            {
                x: '25',
                y: 221,
            },
            {
                x: '26',
                y: 221,
            },
            {
                x: '27',
                y: 221,
            },
            {
                x: '28',
                y: 221,
            },
            {
                x: '29',
                y: 221,
            },
            {
                x: '30',
                y: 221,
            },
        ],
    },
];

export const fakeEmptyData = [
    {
        id: 'Users',
        data: [
            {
                x: '01',
                y: 0,
                dateLabel: 'Date',
                date: '01',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '02',
                y: 0,
                dateLabel: 'Date',
                date: '02',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '03',
                y: 0,
                dateLabel: 'Date',
                date: '03',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '04',
                y: 0,
                dateLabel: 'Date',
                date: '04',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '05',
                y: 0,
                dateLabel: 'Date',
                date: '05',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '06',
                y: 0,
                dateLabel: 'Date',
                date: '06',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '07',
                y: 0,
                dateLabel: 'Date',
                date: '07',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '08',
                y: 0,
                dateLabel: 'Date',
                date: '08',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '09',
                y: 0,
                dateLabel: 'Date',
                date: '09',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '10',
                y: 0,
                dateLabel: 'Date',
                date: '10',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '11',
                y: 0,
                dateLabel: 'Date',
                date: '11',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '12',
                y: 0,
                dateLabel: 'Date',
                date: '12',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '13',
                y: 0,
                dateLabel: 'Date',
                date: '13',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '14',
                y: 0,
                dateLabel: 'Date',
                date: '14',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '15',
                y: 0,
                dateLabel: 'Date',
                date: '15',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '16',
                y: 0,
                dateLabel: 'Date',
                date: '16',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '17',
                y: 0,
                dateLabel: 'Date',
                date: '17',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '18',
                y: 0,
                dateLabel: 'Date',
                date: '18',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '19',
                y: 0,
                dateLabel: 'Date',
                date: '19',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '20',
                y: 0,
                dateLabel: 'Date',
                date: '20',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '21',
                y: 0,
                dateLabel: 'Date',
                date: '21',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '22',
                y: 0,
                dateLabel: 'Date',
                date: '22',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '23',
                y: 0,
                dateLabel: 'Date',
                date: '23',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '24',
                y: 0,
                dateLabel: 'Date',
                date: '24',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '25',
                y: 0,
                dateLabel: 'Date',
                date: '25',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '26',
                y: 0,
                dateLabel: 'Date',
                date: '26',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '27',
                y: 0,
                dateLabel: 'Date',
                date: '27',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '28',
                y: 0,
                dateLabel: 'Date',
                date: '28',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '29',
                y: 0,
                dateLabel: 'Date',
                date: '29',
                valueLabel: 'Events',
                value: 0,
            },
            {
                x: '30',
                y: 0,
                dateLabel: 'Date',
                date: '30',
                valueLabel: 'Events',
                value: 0,
            },
        ],
    },
];
