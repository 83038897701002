import { getText } from 'localization';
import PropTypes from 'prop-types';
import React, { lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import Error from 'shared/Error';
import ErrorBoundary from 'shared/ErrorBoundary';
import Loading from 'shared/Loading';
import Popup from 'shared/Popup';
import { getElementPosition } from 'utils/functions';

import Styles from './Asset.module.scss';

const InsureeDetails = lazy(() => import('components/Insurance/InsureeDetails'));
const PolicyDetails = lazy(() => import('redux/containers/Insurance/PolicyDetails'));
const PaymentsModal = lazy(() => import('redux/containers/Insurance/PaymentsModal'));
const DocumentsModal = lazy(() => import('redux/containers/Insurance/DocumentsModal'));
const MediaModal = lazy(() => import('redux/containers/Insurance/MediaModal'));
const DamagesModal = lazy(() => import('redux/containers/Insurance/DamagesModal'));
const OdometerHistoryModal = lazy(() =>
    import('redux/containers/Insurance/AssetDetails/OdometerHistoryModal'),
);
const BillingModal = lazy(() => import('redux/containers/Insurance/BillingModal'));

export class Asset extends React.PureComponent {
    state = {
        moreInfo: {
            active: false,
            element: {},
            content: null,
        },
    };

    static propTypes = {
        isLoading: PropTypes.bool,
        texts: PropTypes.object,
        data: PropTypes.object,
        onOpenPaymentsModal: PropTypes.func,
        onOpenDocumentsModal: PropTypes.func,
        onOpenMediaModal: PropTypes.func,
        onOpenDamagesModal: PropTypes.func,
        fetchData: PropTypes.func,
        clearData: PropTypes.func,
        location: PropTypes.object,
        match: PropTypes.object,
        onEditAsset: PropTypes.func,
        isSaving: PropTypes.bool,
        paymentsModalActive: PropTypes.bool,
        mediaModalActive: PropTypes.bool,
        damagesModalActive: PropTypes.bool,
        documentsModalActive: PropTypes.bool,
        odometerModalActive: PropTypes.bool,
        billingModalActive: PropTypes.bool,
        projectId: PropTypes.string,
    };

    componentDidMount() {
        this.props.fetchData();
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (this.props.location !== prevProps.location) {
    //         this.props.fetchData();
    //     }
    // }

    componentWillUnmount() {
        this.props.clearData();
    }

    handleOnSearch = value => {
        this.setState({
            search: value.toUpperCase(),
        });
    };

    handleOnSubmit = e => {
        e.preventDefault();
    };

    shouldFocusAsset = quote => {
        return quote.quote.protected_assets &&
            quote.quote.protected_assets.filter(a => a.id === this.props.match.params.id).length
            ? true
            : false;
    };

    handleAssetConnMoreInfo = data => {
        this.setState({
            moreInfo: {
                active: !this.state.moreInfo?.active,
                element: getElementPosition(data.target.currentTarget),
                content: data.content,
            },
        });
    };

    handleClosePopups = () => {
        this.setState({
            moreInfo: {
                active: false,
            },
        });
    };

    render() {
        const {
            isLoading,
            texts,
            data,
            onOpenPaymentsModal,
            onOpenDocumentsModal,
            onOpenMediaModal,
            onOpenDamagesModal,
            onEditAsset,
            isSaving,
            paymentsModalActive,
            mediaModalActive,
            damagesModalActive,
            documentsModalActive,
            odometerModalActive,
            billingModalActive,
            projectId,
        } = this.props;
        const { moreInfo } = this.state;

        return (
            <ErrorBoundary>
                <article data-test="AssetContainer" className={Styles.asset}>
                    <header>
                        <h3 className={Styles.title}>{getText('asset_detail_title')}</h3>
                        <div className={Styles.actions}>
                            <Link
                                data-test="GoBack"
                                className={Styles.goBackSection}
                                to={`/insurance/assets/app/${projectId}?from=details`}
                            >
                                <i className="icon-icon_delete_2"></i>
                            </Link>
                        </div>
                    </header>
                    <section className={Styles.scrollContainer}>
                        <Suspense fallback={<Loading theme="none" variant="sm" />}>
                            {isLoading && <Loading />}

                            {!isLoading && (
                                <>
                                    {data?.hasError && (
                                        <Error text={getText('insurance_view_error')} />
                                    )}

                                    {!data?.hasError && (
                                        <div className={Styles.wrapper}>
                                            <InsureeDetails
                                                data-test="InsureeDetails"
                                                texts={texts}
                                                canCollapse={true}
                                                isCollapsed={true}
                                                theme="light"
                                                data={data && data.insuree ? data.insuree : null}
                                            />
                                            {data &&
                                                data.policies &&
                                                data.policies.map(p => (
                                                    <PolicyDetails
                                                        data-test="PolicyDetailsConverted"
                                                        key={p.id}
                                                        isConverted={true}
                                                        data={p}
                                                        texts={texts}
                                                        canCollapse={
                                                            this.shouldFocusAsset(p.quote)
                                                                ? false
                                                                : true
                                                        }
                                                        isCollapsed={
                                                            this.shouldFocusAsset(p.quote)
                                                                ? false
                                                                : true
                                                        }
                                                        assetFocus={this.shouldFocusAsset(p.quote)}
                                                        assetMatchId={this.props.match.params.id}
                                                        theme="light"
                                                        onOpenPaymentsModal={onOpenPaymentsModal}
                                                        onOpenDocumentsModal={onOpenDocumentsModal}
                                                        onOpenMediaModal={onOpenMediaModal}
                                                        onOpenDamagesModal={onOpenDamagesModal}
                                                        onEditAsset={onEditAsset}
                                                        isSaving={isSaving}
                                                        onAssetConnMoreInfo={
                                                            this.handleAssetConnMoreInfo
                                                        }
                                                        fetchData={this.props.fetchData}
                                                    />
                                                ))}
                                            {data &&
                                                data.quotes &&
                                                data.quotes.map(q => (
                                                    <PolicyDetails
                                                        data-test="PolicyDetailsNotConverted"
                                                        key={q.id}
                                                        isConverted={false}
                                                        data={q}
                                                        texts={texts}
                                                        canCollapse={
                                                            this.shouldFocusAsset(q) ? false : true
                                                        }
                                                        isCollapsed={
                                                            this.shouldFocusAsset(q) ? false : true
                                                        }
                                                        assetFocus={this.shouldFocusAsset(q)}
                                                        assetMatchId={this.props.match.params.id}
                                                        theme="light"
                                                        onOpenPaymentsModal={onOpenPaymentsModal}
                                                        onOpenDocumentsModal={onOpenDocumentsModal}
                                                        onOpenMediaModal={onOpenMediaModal}
                                                        onOpenDamagesModal={onOpenDamagesModal}
                                                        onEditAsset={onEditAsset}
                                                        isSaving={isSaving}
                                                        onAssetConnMoreInfo={
                                                            this.handleAssetConnMoreInfo
                                                        }
                                                        fetchData={this.props.fetchData}
                                                    />
                                                ))}
                                        </div>
                                    )}
                                </>
                            )}
                        </Suspense>
                    </section>
                </article>

                {billingModalActive && <BillingModal data-test="BillingModal" />}
                {paymentsModalActive && <PaymentsModal data-test="PaymentsModal" />}
                {documentsModalActive && <DocumentsModal data-test="DocumentsModal" />}
                {mediaModalActive && <MediaModal data-test="MediaModal" />}
                {damagesModalActive && <DamagesModal data-test="DamagesModal" />}
                {odometerModalActive && <OdometerHistoryModal data-test="OdometerHistoryModal" />}

                <Popup
                    data-test="MoreInfoPopup"
                    position="bottom"
                    id="moreInfoPopup"
                    active={moreInfo?.active}
                    element={moreInfo.element}
                    content={moreInfo.content}
                    onClickSetting={this.handleClickUserSetting}
                    closePopup={this.handleClosePopups}
                />
            </ErrorBoundary>
        );
    }
}

export default Asset;
