import { lazy } from 'react';
import withAuth from 'hoc/withAuth';
import { roles } from 'services/roles';

const AnalyticsPage = lazy(() => import('redux/containers/Analytics'));

export const analyticsRoutes = [
    {
        id: 'route-analytics',
        path: '/analytics/:section(user-visited-places|population-habits|population-segmentation|population-overview|driving-habits|user-facts|user-location-data)',
        exact: true,
        header: {
            visible: true,
            section: 'analytics',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'analytics',
        },
        component: withAuth(AnalyticsPage),
        'data-test': 'RouteProjectsAnalytics',
        scope: [roles.ADMIN],
    },
];
