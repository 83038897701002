import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Styles from './StepsBarStatic.module.scss';

export class StepsBarStatic extends React.PureComponent {
    static propTypes = {
        current: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        steps: PropTypes.arrayOf(PropTypes.object),
        route: PropTypes.string,
        assets: PropTypes.object,
        whiteLabel: PropTypes.bool,
        onClick: PropTypes.func,
    };

    getIsValid(step) {
        const required = step.required;
        const assets = this.props.assets;
        let isValid = true;

        if (required && assets) {
            for (let i = 0; i < required.length; i++) {
                if (!assets.hasOwnProperty(required[i])) {
                    isValid = false;
                }
            }
        }

        return isValid;
    }

    render() {
        const { current, steps, onClick } = this.props;

        if (!steps || steps.length === 0) return null;

        return (
            <nav role="navigation" data-test="StepsBarStatic" className={Styles.stepsBar}>
                <ul data-test="StepsTexts" className={Styles.stepsTexts}>
                    {steps &&
                        steps.map(step => (
                            <li
                                data-test="StepsTextsElement"
                                key={step.id}
                                className={
                                    current.toString() === step.id.toString()
                                        ? Styles?.active
                                        : null
                                }
                            >
                                {step.text}
                            </li>
                        ))}
                </ul>

                <ul data-test="StepsProgress" className={Styles.stepsProgress}>
                    {steps &&
                        steps.map(step => (
                            <li
                                data-test="StepsProgressElement"
                                key={step.id}
                                data-section={step.id}
                                className={classnames(
                                    // step.id === 0 ? Styles.first : null,
                                    // current > step.label ? Styles.done : null,
                                    current.toString() === step.id.toString()
                                        ? Styles?.active
                                        : null,
                                )}
                                onClick={onClick}
                            >
                                <div className={Styles.label}>{step.label}</div>

                                <div className={Styles.line}></div>
                            </li>
                        ))}
                </ul>
            </nav>
        );
    }
}

export default StepsBarStatic;
