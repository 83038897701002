import ActionTypes from 'redux/actionTypes';
import rootInitialState from 'redux/store/initialState';
import {
    TEMPLATES_SERVICES_SEGMENTS_NAMESPACES,
    TEMPLATES_SETTINGS_NAMESPACES,
} from 'utils/constants';
import { templatesCardsViewModel } from './model';

export const initialState = {
    templates: rootInitialState.templates,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.TEMPLATES_IS_LOADING:
            return isLoading(state, action);
        case ActionTypes.TEMPLATES_IS_SAVING:
            return isSaving(state, action);
        case ActionTypes.TEMPLATES_SYNC_SERVICE:
            return syncService(state, action);
        case ActionTypes.TEMPLATES_CLEAR_SERVICE:
            return clearService(state, action);
        case ActionTypes.TEMPLATES_SYNC_SERVICES:
            return syncServices(state, action);
        case ActionTypes.TEMPLATES_OPEN_ITEM_MODAL:
            return openItemModal(state, action);
        case ActionTypes.TEMPLATES_CLOSE_ITEM_MODAL:
            return closeItemModal(state, action);
        case ActionTypes.TEMPLATES_OPEN_COVERAGE_MODAL:
            return openCoverageModal(state, action);
        case ActionTypes.TEMPLATES_CLOSE_COVERAGE_MODAL:
            return closeCoverageModal(state, action);
        case ActionTypes.TEMPLATES_SYNC_SERVICES_ASSETS:
            return syncServiceAssets(state, action);
        case ActionTypes.TEMPLATES_SYNC_SERVICES_ITEMS:
            return syncServiceItems(state, action);
        case ActionTypes.TEMPLATES_SYNC_COVERAGE:
            return syncServiceCoverage(state, action);
        case ActionTypes.TEMPLATES_SYNC_SETTINGS:
            return syncServiceSettings(state, action);
        case ActionTypes.TEMPLATES_OPEN_CARD_ELEMENT_MODAL:
            return openCardElementModal(state, action);
        case ActionTypes.TEMPLATES_CLOSE_CARD_ELEMENT_MODAL:
            return closeCardElementModal(state, action);
        case ActionTypes.TEMPLATES_SYNC_CARDS:
            return syncCards(state, action);
        case ActionTypes.TEMPLATES_CLEAR_CARDS:
            return clearCards(state, action);
        case ActionTypes.TEMPLATES_SYNC_CARD_IMAGE:
            return syncCardImage(state, action);
        default:
            return state;
    }
};

/**
 * Sync Upload Card Image
 * @param {*} state
 * @param {*} action
 */
const syncCardImage = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        cards: {
            ...state.cards,
            card: {
                ...state.cards.card,
                active: true,
                imageUpload: payload && payload.image ? payload.image : null,
            },
        },
    };
};

/**
 * Clear Cards
 * @param {*} state
 * @param {*} action
 */
const clearCards = (state, action) => {
    return {
        ...state,
        cards: rootInitialState.templates.cards,
    };
};

/**
 * Sync Cards
 * @param {*} state
 * @param {*} action
 */
const syncCards = (state, action) => {
    const payload = action.payload.data;

    const lists = templatesCardsViewModel(payload);

    return {
        ...state,
        cards: {
            ...state.cards,
            lists: lists,
            card: {
                ...state.card,
                imageUpload: null,
            },
        },
    };
};

/**
 * Open Card Element Modal
 * @param {*} state
 * @param {*} action
 */
const openCardElementModal = (state, action) => {
    const payload = action.payload.data;

    let element = null;
    if (payload && payload.type && payload.data) {
        element = state.cards.lists[payload.type].filter(c => c.id === payload.data.id);
    }

    return {
        ...state,
        cards: {
            ...state.cards,
            card: {
                ...state.cards.card,
                active: true,
                cardId: payload && payload.data ? payload.data.id : null,
                element: element && element[0] ? element[0] : null,
                action: payload && payload.data ? 'edit' : 'add',
                cardType: payload && payload.type ? payload.type : null,
                title: payload && payload.title ? payload.title : null,
            },
        },
    };
};

/**
 * Close Card Element Modal
 * @param {*} state
 * @param {*} action
 */
const closeCardElementModal = (state, action) => {
    // const payload = action.payload.data;

    return {
        ...state,
        cards: {
            ...state.cards,
            card: {
                ...state.cards.card,
                active: false,
                data: null,
                element: null,
                action: null,
                cardType: null,
                canSave: false,
                title: null,
                imageUpload: null,
            },
        },
    };
};

/**
 * Sync Settings
 * @param {*} state
 * @param {*} action
 */
const syncServiceSettings = (state, action) => {
    const payload = action.payload.data;

    let options = [];
    payload.elements.map(el => {
        if (el.namespace && TEMPLATES_SETTINGS_NAMESPACES.includes(el.namespace)) {
            options = [...options, el];
        }
        return el;
    });
    console.log('syncServiceSettings -> options', options);

    return {
        ...state,
        services: {
            ...state.services,
            settings: options,
        },
    };
};

/**
 * Sync Service Items
 * @param {*} state
 * @param {*} action
 */
const syncServiceItems = (state, action) => {
    const payload = action.payload.data;
    const type = action.payload.type;

    return {
        ...state,
        services: {
            ...state.services,
            [type]: {
                ...state.services[type],
                items: { ...state.services[type].items, ...payload },
            },
        },
    };
};

/**
 * Sync Service Protected Assets
 * @param {*} state
 * @param {*} action
 */
const syncServiceAssets = (state, action) => {
    const payload = action.payload.data;
    const type = action.payload.type;

    return {
        ...state,
        services: {
            ...state.services,
            'protected-assets': {
                ...state.services['protected-assets'],
                items: {
                    ...state.services['protected-assets'].items,
                    [type]: [...payload.elements],
                },
            },
        },
    };
};

/**
 * Open Item Modal
 * @param {*} state
 * @param {*} action
 */
const openItemModal = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        services: {
            ...state.services,
            'protected-assets': {
                ...state.services['protected-assets'],
                item: {
                    ...state.services['protected-assets'].item,
                    active: true,
                    data: payload && payload.data ? payload.data : null,
                    action: payload && payload.data ? 'edit' : 'add',
                    itemType: payload && payload.type ? payload.type : null,
                },
            },
        },
    };
};

/**
 * Close Item Modal
 * @param {*} state
 * @param {*} action
 */
const closeItemModal = (state, action) => {
    // const payload = action.payload.data;

    return {
        ...state,
        services: {
            ...state.services,
            'protected-assets': {
                ...state.services['protected-assets'],
                item: {
                    ...state.services['protected-assets'].item,
                    active: false,
                    data: null,
                    action: null,
                    itemType: null,
                },
            },
        },
    };
};

/**
 * Sync Coverage
 * @param {*} state
 * @param {*} action
 */
const syncServiceCoverage = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        services: {
            ...state.services,
            coverages: {
                ...state.services.coverages,
                coverage: {
                    ...state.services.coverages.coverage,
                    data: payload,
                },
            },
        },
    };
};

/**
 * Open Coverage Modal
 * @param {*} state
 * @param {*} action
 */
const openCoverageModal = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        services: {
            ...state.services,
            coverages: {
                ...state.services.coverages,
                coverage: {
                    ...state.services.coverages.coverage,
                    active: true,
                    coverageId: payload && payload.data ? payload.data.id : null,
                    // data: payload && payload.data ? payload.data : null,
                    action: payload && payload.data ? 'edit' : 'add',
                    coverageType: payload && payload.type ? payload.type : null,
                },
            },
        },
    };
};

/**
 * Close Coverage Modal
 * @param {*} state
 * @param {*} action
 */
const closeCoverageModal = (state, action) => {
    // const payload = action.payload.data;

    return {
        ...state,
        services: {
            ...state.services,
            coverages: {
                ...state.services.coverages,
                coverage: {
                    ...state.services.coverages.coverage,
                    active: false,
                    data: null,
                    action: null,
                    coverageType: null,
                },
            },
        },
    };
};

/**
 * Sync Services
 * @param {*} state
 * @param {*} action
 */
const syncServices = (state, action) => {
    const payload = action.payload.data;
    const elements = payload.elements;

    return {
        ...state,
        services: {
            ...state.services,
            segments: {
                ...state.services.segments,
                lists: {
                    car: elements.filter(
                        el =>
                            el.service.namespace === TEMPLATES_SERVICES_SEGMENTS_NAMESPACES.car[0],
                    ),
                    pets: elements.filter(
                        el =>
                            el.service.namespace === TEMPLATES_SERVICES_SEGMENTS_NAMESPACES.pets[0],
                    ),
                    home: elements.filter(
                        el =>
                            el.service.namespace === TEMPLATES_SERVICES_SEGMENTS_NAMESPACES.home[0],
                    ),
                },
            },
        },
    };
};

/**
 * Sync Service
 * @param {*} state
 * @param {*} action
 */
const syncService = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        services: {
            ...state.services,
            service: payload,
        },
    };
};

/**
 * Clear Service
 * @param {*} state
 * @param {*} action
 */
const clearService = (state, action) => {
    return {
        ...state,
        services: {
            ...state.services,
            service: null,
        },
    };
};

/**
 * Is Loading
 * @param {*} state
 * @param {*} action
 */
const isLoading = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        isLoading: payload,
    };
};

/**
 * Is Saving
 * @param {*} state
 * @param {*} action
 */
const isSaving = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        isSaving: payload,
    };
};

export default reducer;
