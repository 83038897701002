import { getText } from 'localization';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React from 'react';
import { sendErrorToast } from 'services/toast';
import Search from 'shared/Search';
import ValidationStatus from 'shared/ValidationStatus';
import './SearchRegionInput.css';
import Styles from './SearchRegionInput.module.scss';

// transform snake_case to camelCase
const formattedSuggestion = structured_formatting => ({
    mainText: structured_formatting.main_text,
    secondaryText: structured_formatting.secondary_text,
});

const loadScript = (url, callback) => {
    if (document.querySelectorAll(`script[src="${url}"]`).length > 0) {
        callback();
        return true;
    }

    let script = document.createElement('script');
    script.type = 'text/javascript';

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
};

export class SearchRegionInput extends React.PureComponent {
    // TODO: Refactor

    _autoCompleteRef = React.createRef();
    debouncedFetchPredictions = null;

    state = {
        googleUrl: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
        loading: false,
        suggestions: [],
        userInputValue: this.props.value,
        ready: !this.props.googleCallbackName,
    };

    static defaultProps = {
        searchOptions: {
            types: ['(regions)'],
            componentRestrictions: { country: '' },
        },
    };

    static propTypes = {
        region: PropTypes.string,
        withValidation: PropTypes.bool,
        isValid: PropTypes.bool,
        unsaved: PropTypes.bool,
        isLoading: PropTypes.bool,
        disabled: PropTypes.bool,
        init: PropTypes.bool,
        onChange: PropTypes.func,
        onSelect: PropTypes.func,
        onError: PropTypes.func,
        debounce: PropTypes.number,
        googleCallbackName: PropTypes.string,
        highlightFirstSuggestion: PropTypes.string,
        searchOptions: PropTypes.object,
        value: PropTypes.string,
        size: PropTypes.string,
    };

    componentDidMount() {
        this.debouncedFetchPredictions = debounce(this.fetchPredictions, this.props.debounce);

        if (this.props.init) {
            const { googleCallbackName } = this.props;
            if (googleCallbackName) {
                if (!window.google) {
                    window[googleCallbackName] = this.init;
                } else {
                    loadScript(this.state.googleUrl, () => this.init());
                }
            } else {
                loadScript(this.state.googleUrl, () => this.init());
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.init && this.props.init) {
            loadScript(this.state.googleUrl, () => this.init());
        }

        if (prevProps.region !== this.props.region) {
            this.setState({ userInputValue: this.props.region });
        }
    }

    componentWillUnmount() {
        const { googleCallbackName } = this.props;
        if (googleCallbackName && window[googleCallbackName]) {
            delete window[googleCallbackName];
        }
    }

    init = () => {
        if (!window.google) {
            throw new Error(
                '[react-places-autocomplete]: Google Maps JavaScript API library must be loaded. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library',
            );
        }

        if (!window.google.maps.places) {
            throw new Error(
                '[react-places-autocomplete]: Google Maps Places library must be loaded. Please add `libraries=places` to the src URL. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library',
            );
        }

        const { region } = this.props;
        if (region) {
            this.setState({ userInputValue: region });
        }

        this.autocompleteService = new window.google.maps.places.AutocompleteService();
        this.autocompleteOK = window.google.maps.places.PlacesServiceStatus.OK;
        this.setState(state => {
            if (state.ready) {
                return null;
            } else {
                return { ready: true };
            }
        });
    };

    autocompleteCallback = async (predictions, status) => {
        this.setState({ loading: false });

        if (status !== this.autocompleteOK) {
            // this.props.onError(status, this.clearSuggestions);
            sendErrorToast();
            this.clearSuggestions();
            return;
        }

        const { highlightFirstSuggestion } = this.props;
        this.setState({
            suggestions: predictions.map((p, idx) => ({
                id: p.id,
                description: p.description,
                placeId: p.place_id,
                active: highlightFirstSuggestion && idx === 0 ? true : false,
                index: idx,
                formattedSuggestion: formattedSuggestion(p.structured_formatting),
                matchedSubstrings: p.matched_substrings,
                terms: p.terms,
                types: p.types,
            })),
        });
    };

    getDetailsForPlaceId = async placeId => {
        return new Promise((resolve, reject) => {
            const placesService = new window.google.maps.places.PlacesService(
                document.createElement('div'),
            );

            const request = {
                placeId,
                fields: ['formatted_address', 'geometry'],
            };

            placesService.getDetails(request, (result, status) => {
                if (status !== this.autocompleteOK) {
                    // this.props.onError(status, this.clearSuggestions);
                    sendErrorToast();
                    this.clearSuggestions();
                    return;
                }

                try {
                    const latLng = {
                        lat: result.geometry.location.lat(),
                        lng: result.geometry.location.lng(),
                    };
                    resolve(latLng);
                } catch (e) {
                    reject(e);
                }
            });
        });
    };

    fetchPredictions = () => {
        const { userInputValue } = this.state;
        if (userInputValue.length) {
            this.setState({ loading: true });
            this.autocompleteService.getPlacePredictions(
                {
                    ...this.props.searchOptions,
                    input: userInputValue,
                },
                this.autocompleteCallback,
            );
        }
    };

    clearSuggestions = () => {
        this.setState({ suggestions: [] });
    };

    handleOnSelect = async e => {
        const description = e.currentTarget.getAttribute('data-place-description');

        this.clearSuggestions();

        const payload = {
            region_covered: description,
        };

        this.setState(
            {
                userInputValue: description,
            },
            () => {
                this.props.onChange(payload);
            },
        );
    };

    handleInputChange = event => {
        const { value } = event.target;
        // this.props.onChange(value);

        this.setState({ userInputValue: value }, () => {
            if (!value) {
                this.clearSuggestions();
                return;
            }

            if (value.length > 3) {
                this.debouncedFetchPredictions();
            }
        });
    };

    handleInputOnBlur = () => {
        if (!this.mousedownOnSuggestion) {
            setTimeout(() => {
                this.clearSuggestions();
            }, 310);
        }
    };

    handleOnClear = () => {
        this.setState(
            {
                userInputValue: '',
            },
            () => {
                const payload = {
                    region_covered: '',
                };
                this.props.onChange(payload);
            },
        );
    };

    render() {
        const { isLoading, disabled, withValidation, isValid, unsaved, region, size } = this.props;
        const { userInputValue, suggestions } = this.state;

        return (
            <div data-test="SearchRegionInputContainer" className="search-region-input">
                <Search
                    data-test="SearchRegionInputSearch"
                    inputRef={this._autoCompleteRef}
                    name="search"
                    theme="ternary"
                    variant="outlined"
                    state={userInputValue || region ? 'active' : null}
                    size={size}
                    placeholder={getText('selfcare_network_section_search_region_placeholder')}
                    onSearch={this.handleInputChange}
                    onChange={this.handleInputChange}
                    onClear={this.handleOnClear}
                    value={userInputValue}
                    onBlur={this.handleInputOnBlur}
                    disabled={disabled}
                    loading={isLoading}
                />

                {suggestions && suggestions.length > 0 && (
                    <ul className={Styles.results}>
                        {suggestions.map(loc => (
                            <li
                                key={loc.id || loc.placeId}
                                data-place-id={loc.placeId}
                                data-place-description={loc.description}
                                onClick={this.handleOnSelect}
                            >
                                {loc.description}
                            </li>
                        ))}
                    </ul>
                )}

                {withValidation && !unsaved && !disabled && !isLoading && isValid && (
                    <ValidationStatus status="success" />
                )}

                {withValidation && !unsaved && !disabled && !isLoading && !isValid && (
                    <ValidationStatus status="error" />
                )}
            </div>
        );
    }
}

export default SearchRegionInput;
