import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Button from 'shared/Button';
import Styles from './Modal.module.scss';

const Modal = ({
    active = false,
    title = '',
    icon = '',
    onCancel = () => {},
    content = null,
    headerButton = {
        label: '',
        onClick: () => {},
        disabled: false,
        style: {
            theme: 'ternary',
            variant: 'filled',
            size: 'sm',
        },
        ref: null,
        hasAction: true,
        hasClose: true,
    },
    background = 'gray',
    width = 'wsm',
    height = 'hlg',
    disableDefaultScrollBehavior = false,
    hasHeader = true,
}) => {
    const excludeHTMLExternalScrolls = () => {
        document.body.style.overflowY = 'hidden';
    };

    const includeHTMLExternalScrolls = () => {
        document.body.style.overflowY = 'scroll';
    };

    useEffect(() => {
        excludeHTMLExternalScrolls();
        return () => {
            includeHTMLExternalScrolls();
        };
    }, []);

    const handleEscKey = evt => {
        evt = evt || window.event;
        if (evt.keyCode === 27) {
            onCancel();
        }
    };

    active
        ? document.addEventListener('keydown', handleEscKey)
        : document.removeEventListener('keydown', handleEscKey);
    return (
        <div className={classnames(Styles.overlay, active ? Styles?.active : null)}>
            <div className={classnames([Styles.modal, Styles[`${width}`], Styles[`${height}`]])}>
                {hasHeader && (
                    <div
                        className={classnames([
                            Styles.header,
                            Styles[background],
                            Styles[`${width}`],
                        ])}
                    >
                        <h3>{title}</h3>
                        {headerButton && (
                            <div id="headerButton" ref={headerButton.ref}>
                                {headerButton.hasAction && (
                                    <div
                                        role="button"
                                        aria-roledescription="button"
                                        className={classnames(
                                            Styles.createButton,
                                            Styles[headerButton.style.size],
                                        )}
                                    >
                                        <Button
                                            id={'modalButton'}
                                            disabled={headerButton.disabled}
                                            value={headerButton.label}
                                            theme={headerButton.style.theme}
                                            variant={headerButton.style.variant}
                                            size={headerButton.style.size}
                                            onClick={headerButton?.onClick}
                                        />
                                    </div>
                                )}
                                {headerButton.hasClose && (
                                    <i
                                        className={classnames(icon, Styles.icon)}
                                        onClick={onCancel}
                                    ></i>
                                )}
                            </div>
                        )}
                    </div>
                )}
                <div
                    className={classnames([
                        Styles.content,
                        hasHeader ? Styles.hasHeader : Styles.noHeader,
                        disableDefaultScrollBehavior && Styles.disableDefaultScrollBehavior,
                    ])}
                >
                    {content}
                </div>
            </div>
        </div>
    );
};

Modal.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    active: PropTypes.bool,
    onCancel: PropTypes.func,
    content: PropTypes.node,
    headerButton: PropTypes.oneOfType([
        PropTypes.shape({
            label: PropTypes.string,
            onClick: PropTypes.func,
            disabled: PropTypes.bool,
        }),
        PropTypes.bool,
    ]),
};

Modal.defaultProps = {
    id: 'modal',
    title: 'Title',
    active: false,
    onCancel: () => {},
    content: null,
    icon: 'icon-icon_delete_3',
};

export default Modal;
