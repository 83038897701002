import { adminRoutes } from './admin';
import { analyticsRoutes } from './analytics';
import { appRoutes } from './app';
import { campaignsRoutes } from './campaigns';
import { distributorsRoutes } from './distributors';
import { insuranceRoutes } from './insurance';
import { integrationsRoutes } from './integrations';
import { networksRoutes } from './networks';
import { projectsRoutes } from './projects';
import { sellersRoutes } from './sellers';

export const privateRoutes = [
    ...appRoutes,
    ...projectsRoutes,
    ...integrationsRoutes,
    ...analyticsRoutes,
    ...insuranceRoutes,
    ...networksRoutes,
    ...distributorsRoutes,
    ...adminRoutes,
    ...sellersRoutes,
    ...campaignsRoutes,
];

export const medipetPrivateRoutes = [
    ...appRoutes,
];
