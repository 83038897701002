import { lazy } from 'react';
import withAuth from 'hoc/withAuth';
import { roles } from 'services/roles';

const Integrations = lazy(() => import('redux/containers/Integrations'));
const IntegrationsDetails = lazy(() => import('redux/containers/Integrations/Details'));
const IntegrationsCredentials = lazy(() => import('redux/containers/Integrations/Credentials'));
const IntegrationsDevices = lazy(() => import('redux/containers/Integrations/Devices'));
const IntegrationsDevice = lazy(() => import('redux/containers/Integrations/Device'));

export const integrationsRoutes = [
    {
        id: 'route-integrations',
        path: '/integrations/:id',
        exact: true,
        header: {
            visible: true,
            section: 'integrations',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'integrations',
        },
        component: withAuth(Integrations),
        'data-test': 'RouteIntegrations',
        scope: [roles.INSURER],
    },
    {
        id: 'route-integrations-details',
        path: '/integrations/:id/details',
        exact: true,
        header: {
            visible: true,
            section: 'integrations',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'integrations',
        },
        component: withAuth(IntegrationsDetails),
        'data-test': 'RouteIntegrationsDetails',
        scope: [roles.INSURER],
    },
    {
        id: 'route-integrations-devices',
        path: '/integrations/:id/devices',
        exact: true,
        header: {
            visible: true,
            section: 'integrations',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'integrations',
        },
        component: withAuth(IntegrationsDevices),
        'data-test': 'RouteIntegrationsDevices',
        scope: [roles.INSURER],
    },
    {
        id: 'route-integrations-devices-structure',
        path: '/integrations/:id/devices/:deviceId/:section(structure)',
        exact: true,
        header: {
            visible: true,
            section: 'integrations',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'integrations',
        },
        component: withAuth(IntegrationsDevice),
        'data-test': 'RouteIntegrationsDevice',
        scope: [roles.INSURER],
    },
    {
        id: 'route-integrations-devices-graphics-step',
        path: [
            '/integrations/:id/devices/:deviceId/:section(graphics)',
            '/integrations/:id/devices/:deviceId/:section(graphics)/step/:step(1|2|3|4)',
        ],
        exact: true,
        header: {
            visible: true,
            section: 'integrations',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'integrations',
        },
        component: withAuth(IntegrationsDevice),
        'data-test': 'RouteIntegrationsDevice',
        scope: [roles.INSURER],
    },
    {
        id: 'route-integrations-devices-automations',
        path: '/integrations/:id/devices/:deviceId/:section(automations)',
        exact: true,
        header: {
            visible: true,
            section: 'integrations',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'integrations',
        },
        component: withAuth(IntegrationsDevice),
        'data-test': 'RouteIntegrationsDevice',
        scope: [roles.INSURER],
    },
    {
        id: 'route-integrations-credentials',
        path: '/integrations/:id/credentials',
        exact: true,
        header: {
            visible: true,
            section: 'integrations',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'integrations',
        },
        component: withAuth(IntegrationsCredentials),
        'data-test': 'RouteIntegrationsCredentials',
        scope: [roles.INSURER],
    },
];
