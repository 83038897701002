import Bugsnag from '@bugsnag/js';
import { HTTP_FORBIDDEN, HTTP_UNAUTHORIZED } from 'api/statusCodes';
import axios from 'axios';
import { getText } from 'localization';
import { checkAuthWithRedirect, homeRedirect, isProdEnv, stackFunction } from 'utils/functions';
import { SHOULD_NOT_UNLOG_WHEN_FORBIDDEN, TOKEN_EXPIRED } from './errorCodes';
import { getAccessToken, logout, redirectToLogout } from './localStorage';
import { sendErrorToast, sendErrorToastByStatusCode } from './toast';

export const apiGetAuthHeader = () => {
    const access_token = getAccessToken();

    if (!access_token || access_token === '') {
        checkAuthWithRedirect();
    }

    return `Bearer ${access_token}`;
};

export const apiHandleRequest = async request => {
    return await axios(request).then(response => {
        return response.data;
    });
};

export const apiHandleRequestRecursively = async (request, attr = 'elements', l) => {
    let { page_start_index } = request.params;
    let debugMode = false;
    const L = l || 100;

    const promise = async i => {
        return await axios({
            ...request,
            params: {
                ...request.params,
                page_size: L,
                page_start_index: i,
            },
        });
    };

    const rf = async (d, p, l) => {
        if (debugMode) console.log('Inside rf function');
        return await p.then(async pd => {
            if (!pd || pd.data === '') return d; // if no data, return
            if (debugMode) console.log('Promise resolved:', pd); // if data, log it
            const arr = [...d, ...pd.data[attr]]; // merge data
            if (debugMode)
                console.log(
                    'Condition check:',
                    pd.data[attr] && Array.isArray(pd.data[attr]) && pd.data[attr].length === l,
                );
            if (pd.data[attr] && Array.isArray(pd.data[attr]) && pd.data[attr].length === l) {
                page_start_index += l;
                if (debugMode) console.log('Making recursive call');
                return await rf(arr, promise(page_start_index), l);
            }
            return arr;
        });
    };

    return await rf([], promise(0), L).then(response => {
        if (debugMode) console.log('Final response:', response);
        return { elements: response, size: response.length };
    });
};

export const apiHandleErrorResponse = error => {
    const errorRequestURL = error.config.url;

    const FORBIDDEN_STATUS_EXCEPTION_URLS = [`/applications/self/distributors/`];

    const currentURLFitInForbiddenExceptions = FORBIDDEN_STATUS_EXCEPTION_URLS.some(url =>
        errorRequestURL?.includes(url),
    );

    const shouldCallException =
        error?.response?.status === HTTP_FORBIDDEN && currentURLFitInForbiddenExceptions;

    const exception = () => stackFunction(sendErrorToast, logout);

    if (
        (error && error === 'Error: Request failed with status code 401') ||
        (error?.response?.status === HTTP_UNAUTHORIZED && error?.data?.code === TOKEN_EXPIRED)
    ) {
        return stackFunction(sendErrorToast, redirectToLogout);
    }

    if (error?.response?.status === HTTP_FORBIDDEN) {
        if (error?.response?.data?.code === SHOULD_NOT_UNLOG_WHEN_FORBIDDEN) {
            return () => sendErrorToast(getText('forbidden_http_status_error'));
        } else {
            if (shouldCallException) {
                return exception();
            }
            return stackFunction(
                () => sendErrorToast(getText('forbidden_http_status_error')),
                homeRedirect,
            );
        }
    }
};

export const apiHandleThunkErrorResponse = (error, toastAlert, callback) => {
    if (toastAlert) {
        if (error) {
            if (!isProdEnv()) {
                console.warn('SC -> apiHandleThunkErrorResponse -> 1 ->', error);
            }

            try {
                Bugsnag.notify(error, event => {
                    event.severity = 'warning';
                    event.addMetadata('API Thunk', {
                        error: error,
                    });
                });
            } catch (error) {
                if (!isProdEnv()) {
                    console.log('SC -> apiHandleThunkErrorResponse -> 2 ->', JSON.stringify(error));
                }
                Bugsnag.notify(error);
            }
        }

        if (error && error.error && typeof error.error === 'string') {
            sendErrorToast(error.error);
        } else if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error !== ''
        ) {
            sendErrorToast(error.response.data.error);
        } else {
            sendErrorToastByStatusCode(error);
        }
    }

    if (callback && typeof callback === 'function') {
        callback();
    }
};
