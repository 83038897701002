import ServicesItemsDirectories from 'pages/Networks/ServicesItemsDirectories';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { networksOpenShareModalAction } from 'redux/domains/networks/actions';
import { networksGetServicesItemsDirectoriesObs } from 'redux/domains/networks/observer';
import {
    networksCreateDirectoryByTypeThunk,
    networksDeleteDirectoryThunk,
} from 'redux/domains/networks/thunk';
import { openPromptAction } from 'redux/domains/prompt/actions';

const mapStateToProps = (state, ownProps) => ({
    texts: state.texts,
    isLoading: state.app.isLoading,
    entityId: ownProps.match.params.id,
    servicesDirectories: state.networks.services.directories,
    itemsDirectories: state.networks.items.directories,
    shareModal: state.networks.share?.active,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    openShareModal: data => dispatch(networksOpenShareModalAction(data)),
    openPrompt: data => dispatch(openPromptAction(data)),
    getDirectories: () => networksGetServicesItemsDirectoriesObs(dispatch, ownProps),
    addServiceDirectory: () =>
        networksCreateDirectoryByTypeThunk(
            dispatch,
            'directory-offered-service',
            'services',
            ownProps,
        ),
    addItemDirectory: () =>
        networksCreateDirectoryByTypeThunk(dispatch, 'directory-offered-item', 'items', ownProps),
    // onDeleteServiceDirectory: data => networksDeleteDirectoryThunk(dispatch, data, ownProps),
    // onDeleteItemDirectory: data => networksDeleteDirectoryThunk(dispatch, data, ownProps),
    onDeleteDirectory: data => networksDeleteDirectoryThunk(dispatch, data, ownProps),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServicesItemsDirectories));
