import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './Tooltip.module.scss';
import { getElementPosition } from 'utils/functions';

export class Tooltip extends React.PureComponent {
    state = {
        visible: false,
        content: null,
    };

    static propTypes = {
        text: PropTypes.string,
        visible: PropTypes.bool,
        content: PropTypes.string,
        position: PropTypes.oneOf(['center', 'top', 'right', 'bottom', 'left']),
        element: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
    };

    handleClick = e => {
        this.setState({ visible: false });
    };

    render() {
        const { content, element } = this.props;

        if (!element) return null;

        const position = this.getRenderPosition() || null;

        return (
            <div
                data-test="TooltipContainer"
                style={position}
                data-tooltip="tooltip"
                className={classnames(Styles.tooltip)}
                onClick={this.handleClick}
            >
                <div className={classnames(Styles.arrow, Styles.left)}></div>
                <p>{content}</p>
            </div>
        );
    }

    getRenderPosition() {
        const { position, element } = this.props;
        const reference = getElementPosition(element);

        switch (position) {
            case 'center':
                return {
                    top: reference.top - reference.height - 140,
                    right: 'auto',
                    bottom: 'auto',
                    left: reference.left - 140,
                };

            case 'top':
                return {
                    top: reference.top - reference.height,
                    right: 'auto',
                    bottom: 'auto',
                    left: 'auto',
                };

            case 'right':
                return {
                    top: reference.top + window.scrollY,
                    right: 'auto',
                    bottom: 'auto',
                    left: reference.left + reference.width + 20,
                };

            case 'bottom':
                return {
                    top: reference.top + reference.height,
                    right: 'auto',
                    bottom: 'auto',
                    left: reference.left + reference.width / 2,
                };

            case 'left':
                return {
                    top: reference.top + reference.height,
                    right: 'auto',
                    bottom: 'auto',
                    left: reference.left,
                };

            default:
                return {
                    top: 'auto',
                    right: 'auto',
                    bottom: 'auto',
                    left: 'auto',
                };
        }
    }
}

export default Tooltip;
