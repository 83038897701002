import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { userHasPermission } from 'services/roles';
import PrivateRoute from '../../components/selfcare/PrivateRoute';
import { privateRoutes } from '../../routes';

const LogoutPage = lazy(() => import('pages/Logout'));
const AuthenticatePage = lazy(() => import('redux/containers/Authenticate'));
const MePage = lazy(() => import('redux/containers/Me'));
const ErrorPage = lazy(() => import('pages/ErrorPage'));
const LoginPage = lazy(() => import('redux/containers/LoginPage'));
const RegisterPage = lazy(() => import('redux/containers/RegisterPage'));
const RegisterFormPagePage = lazy(
    () => import('redux/containers/RegisterFormPage'),
);
const RecoverPage = lazy(() => import('redux/containers/RecoverPage'));
const ComponentsPage = lazy(() => import('pages/Components'));

const SelfcareRoutes = ({
    roles,
    user,
    registerTypeSearchParam,
    registrationType,
    isAuthenticated,
}) => {
    return (
        <>
            {!isAuthenticated && (
                <Switch>
                    <Route data-test="RouteLogin" exact path="/" component={LoginPage} />
                    <Route data-test="RouteLogin" exact path="/login" component={LoginPage} />
                    <Route
                        data-test="RouteRegisterFormPage"
                        exact
                        path="/register"
                        component={RegisterFormPagePage}
                    />
                    <Route
                        data-test="RouteRegister"
                        exact
                        path="/register/invite"
                        component={RegisterPage}
                    />
                    <Route data-test="RouteRecover" exact path="/recover" component={RecoverPage} />
                    <Route
                        data-test="RouteMe"
                        exact
                        path="/me/:access_token/:refresh_token"
                        component={MePage}
                    />
                    <Route data-test="RouteError" component={LogoutPage} />
                </Switch>
            )}

            {isAuthenticated && (
                <Switch>
                    <Redirect from="/projects/undefined" to="/board" />
                    <Redirect from="/integrations/undefined" to="/board" />
                    <Redirect from="/analytics/undefined" to="/board" />
                    <Redirect from="/insurance/undefined" to="/board" />
                    <Redirect exact from="/insurance" to="/insurance/dashboard" />
                    <Redirect from="/networks-management/undefined" to="/board" />
                    <Redirect
                        exact
                        from="/networks-management"
                        to="/networks-management/dashboard"
                    />
                    <Redirect exact from="/analytics" to="/analytics/user-visited-places" />
                    <Redirect exact from="/projects/:id" to="/projects/:id/dashboard" />
                    <Redirect
                        exact
                        from="/projects/:id/white-label"
                        to="/projects/:id/white-label/step/1"
                    />
                    <Redirect
                        exact
                        from="/projects/:id/marketplace"
                        to="/projects/:id/marketplace/public"
                    />
                    <Redirect exact from="/integrations/:id" to="/integrations/:id/devices" />
                    <Redirect
                        exact
                        from="/integrations/:id/devices/:deviceId"
                        to="/integrations/:id/devices/:deviceId/structure"
                    />
                    <Redirect
                        exact
                        from="/integrations/:id/devices/:deviceId/:section(graphics)"
                        to="/integrations/:id/devices/:deviceId/:section(graphics)/step/1"
                    />
                    <Redirect exact from="/sellers" to="/sellers/invites" />

                    {userHasPermission([roles.DISTRIBUTOR]) && (
                        <Redirect exact from="/distributors" to="/distributors/dashboard" />
                    )}

                    <Redirect exact from="/campaigns" to="/campaigns/campaigns" />

                    {/* TODO: Remove this redirect - it's a temporary fix for the issue with the campaign group list
        1. When I go to the details of a campaign
        2. And I get back to the campaign list via the section list
        3. the Link component leads me to /campaigns/:id/campaigns instead of /campaigns/campaigns
         */}
                    <Redirect exact from="/campaigns/:id/campaigns" to="/campaigns/campaigns" />

                    {/* Don't let the user access the dashboard if they are not a distributor */}
                    {!userHasPermission([roles.DISTRIBUTOR]) && (
                        <Redirect
                            exact
                            from="/distributors/dashboard"
                            to="/distributors/my-distributors"
                        />
                    )}

                    {!userHasPermission([roles.DISTRIBUTOR]) && (
                        <Redirect exact from="/distributors" to="/distributors/my-distributors" />
                    )}

                    {user.isAdmin && (
                        <Redirect exact from="/admin" to="/admin/permissions/approval-requests" />
                    )}
                    {user.isOnlyAdmin && (
                        <Redirect exact from="/" to="/admin/permissions/approval-requests" />
                    )}
                    {user.isOnlyAdmin && (
                        <Redirect exact from="/board" to="/admin/permissions/approval-requests" />
                    )}

                    {privateRoutes.map(route => (
                        /* TODO - Type it */
                        <PrivateRoute key={route.id} {...route} roles={roles} />
                    ))}

                    {user.isAdmin && (
                        <Route
                            data-test="RouteLogin"
                            exact
                            path="/components"
                            component={ComponentsPage}
                        />
                    )}
                    <Route data-test="RouteLogin" exact path="/login" component={LoginPage} />
                    <Route
                        data-test="RouteRegisterFormPage"
                        exact
                        path="/register"
                        // component={RegisterPage}
                        component={RegisterFormPagePage}
                    />
                    <Route
                        data-test="RouteRegister"
                        exact
                        path="/register/invite"
                        component={RegisterPage}
                    />
                    <Route data-test="RouteRecover" exact path="/recover" component={RecoverPage} />
                    <Route data-test="RouteLogout" exact path="/logout" component={LogoutPage} />

                    <Route
                        data-test="RouteAuthenticate"
                        exact
                        path="/authenticate/:id"
                        component={AuthenticatePage}
                    />
                    <Route
                        data-test="RouteMe"
                        exact
                        path="/me/:access_token/:refresh_token"
                        component={MePage}
                    />
                    <Route data-test="RouteError" component={ErrorPage} />
                </Switch>
            )}

            <Switch>
                <Redirect
                    from="/register"
                    to={{
                        pathname: '/register',
                        search:
                            registerTypeSearchParam === registrationType.INSURER
                                ? `?type=${registrationType.INSURER}`
                                : `?type=${registrationType.DISTRIBUTOR}`,
                    }}
                    push
                />
            </Switch>
        </>
    );
};

export default SelfcareRoutes;
