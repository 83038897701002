import { differenceInDays, format, formatDistance, isValid, parseISO, sub } from 'date-fns';
import { getText } from 'localization';
import queryString from 'query-string';
import { deleteSession, isUserAuthenticated } from 'services/localStorage';
import { sendSuccessToast } from 'services/toast';
import { Provider } from 'shared/Provider';
import { v4 as uuidv4 } from 'uuid';
import {
    API_URL_PREFIX,
    API_URL_SUFIX,
    DEFAULT_SHOW_SEARCH_ON_LIST,
    OBD_VALUES_BY_ENV,
    QA_INTEGRATION_DEVICE_RECIPE_ID,
} from './constants';

export const withProvider = children => {
    return Provider(children);
};

export const logoutWithCallback = location => {
    const values = queryString.parse(location?.search);
    const callbackUrl = encodeURI(values?.callbackUrl || window.location.pathname);

    window.location.href =
        callbackUrl !== '/logout' ? `/login?callbackUrl=${callbackUrl}` : '/login';
};

export const checkAuthWithRedirect = async location => {
    if (!isUserAuthenticated()) {
        await deleteSession();
        setTimeout(async () => {
            await logoutWithCallback(location);
        }, 100);
    }
};

export const getElementPosition = el => {
    if (!el) {
        return false;
    }

    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    return {
        top: Math.round(rect.top + scrollTop),
        left: Math.round(rect.left + scrollLeft),
        width: Math.round(rect.width),
        height: Math.round(rect.height),
    };
};

export const getNodePosition = el => {
    if (!el) {
        return false;
    }

    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
        top: Math.round(el.offsetTop + scrollTop),
        left: Math.round(el.offsetLeft + scrollLeft),
        width: Math.round(el.offsetWidth),
        height: Math.round(el.offsetHeight),
    };
};

export const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    sendSuccessToast('Text copied to clipboard.');
};

export const getLastMonthInterval = () => {
    const now = new Date();
    const result = sub(now, {
        // months: 1,
        days: 29,
    });

    return {
        offset_date: format(result, 'yyyy-MM-dd'),
        date: format(now, 'yyyy-MM-dd'),
        interval_start: format(result, 'd LLL Y', {
            awareOfUnicodeTokens: true,
        }),
        interval_end: format(now, 'd LLL Y', {
            awareOfUnicodeTokens: true,
        }),
    };
};

export const getDataViewsDefaultDate = date => {
    if (date) {
        /**
         * * NOTE: leading zero date fix to Safari
         */
        const d = new Date(date.replace(/(^|\D)(\d)(?!\d)/g, '$10$2'));
        const month = format(d, 'LLLL');
        const rest = format(d, 'Y');

        return `from ${month} ${rest}`;
    }

    const d = new Date();
    const month = format(d, 'LLLL');
    const rest = format(d, 'd, Y');

    return `of ${month} 1 to ${rest}`;
};

export const getDistributorsCommissionsDefaultDate = () => {
    const now = new Date();
    const utc_timestamp = Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
        now.getUTCMilliseconds(),
    );
    const date = format(utc_timestamp, 'yyyy-MM-dd');

    return date;
};

/**
 * @param {*} date_string - format: "2024-04-18T00:00:00 || 2024-04-18 || 2024-05-09T16:27:34.896+0000"
 * @param {*} filter - date_only, time&timezone, date&time&timezone
 */
export const convertUTCtoLocalDateAndHoursString = (date_string, filter) => {
    if (!date_string || typeof date_string !== 'string') return null;

    let finalDate;

    const hasTimePart = date_string.includes('T') && date_string.split('T')?.length > 1;

    if (hasTimePart) {
        const afterT = date_string.split('T')?.[1];
        const hasTimeZone = afterT?.includes('Z') || afterT?.includes('+') || afterT?.includes('-');
        if (hasTimeZone) {
            finalDate = new Date(date_string); // Para datas com fuso horário específico
        } else {
            finalDate = new Date(date_string + 'Z'); // Para datas sem fuso horário específico
        }
    } else {
        finalDate = new Date(date_string + 'Z'); // Para datas sem fuso horário específico
    }

    if (!finalDate || isNaN(finalDate.getTime())) return 'Invalid time value';
    let output;

    const locale = 'pt-BR'; // Date format will be: dd-mm-yyyy "17/04/2024"

    const defaultFormat = {
        date: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        },
        hour: {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false, // 24h
        },
    };

    // Options to format date and hour
    const defaultOptions = {
        timeZone: getTimezone(), // 'Europe/Lisbon',
        ...defaultFormat.date,
        ...defaultFormat.hour,
    };
    output = new Intl.DateTimeFormat(locale, {
        ...defaultOptions,
    }).format(finalDate);

    const formats = {
        date: new Intl.DateTimeFormat(locale, {
            timeZone: getTimezone(),
            ...defaultFormat.date,
        }).format(finalDate),
        time: new Intl.DateTimeFormat(locale, {
            timeZone: getTimezone(),
            ...defaultFormat.hour,
        }).format(finalDate),
        timezone: getTimezone(),
    };

    const { date, time, timezone } = formats;

    if (filter) {
        switch (filter) {
            case 'date_only':
                return date;
            case 'time&timezone':
                return time + ' ' + timezone;
            case 'date&time&timezone':
                return date + ' ' + time + ' ' + timezone;
            default:
                break;
        }
    }

    return output;
};

export const getTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone || ''; // 'Europe/Lisbon';
};

/**
 * @param {string} utcDateString
 * @returns 4/17/2024, 9:00:00 PM America/Fortaleza
 */
export const getLocaleDateAndHoursFormat = utcDateString => {
    if (!utcDateString) return null;
    return convertUTCtoLocalDateAndHoursString(utcDateString) + ' ' + getTimezone();
};

export const formatDate = (date, diff, type, _) => {
    if (!date || !isValid(new Date(date))) {
        return null;
    }

    const formatType = !type ? 'dd/MM/yyyy, HH:mm:ss' : type;

    const origin = parseISO(date, 'yyyy-MM-DDTHH:mm:ss.SSSZ', new Date(), {
        awareOfUnicodeTokens: true,
    });

    let output = format(origin, formatType, {
        awareOfUnicodeTokens: true,
    });

    if (diff && differenceInDays(new Date(), origin) < 2) {
        const diff = formatDistance(origin, new Date(), { addSuffix: true });
        output = diff;
    }

    return output;
};

export const formatDateToChat = date => {
    if (!date || !isValid(new Date(date))) {
        return null;
    }

    const origin = parseISO(date, 'yyyy-MM-DD', new Date(), {
        awareOfUnicodeTokens: true,
    });

    let output = format(origin, 'd LLL Y', {
        awareOfUnicodeTokens: true,
    });

    return output;
};

export const formatHourToChat = date => {
    if (!date || !isValid(new Date(date))) {
        return null;
    }

    const origin = parseISO(date, 'yyyy-MM-DDTHH:mm:ss.SSSZ', new Date(), {
        awareOfUnicodeTokens: true,
    });

    let output = format(origin, 'HH:mm', {
        awareOfUnicodeTokens: true,
    });

    return output;
};

export const formatTimestampToGallery = date => {
    if (!date || !isValid(new Date(date))) {
        return null;
    }

    const origin = parseISO(date, 'yyyy-MM-DDTHH:mm:ss.SSSZ', new Date(), {
        awareOfUnicodeTokens: true,
    });

    let output = format(origin, "d LLL Y 'at' HH:mm", {
        awareOfUnicodeTokens: true,
    });

    return output;
};

export const generateRandomId = () => {
    return Math.random().toString(36).substring(7);
};

export const generateUUID = () => {
    return (
        Math.random().toString(36).substring(5) +
        '-' +
        Math.random().toString(36).substring(9) +
        '-' +
        Math.random().toString(36).substring(9) +
        '-' +
        Math.random().toString(36).substring(9) +
        '-' +
        Math.random().toString(36).substring(3)
    );
};

export const generateUniqueUUID = () => {
    return uuidv4();
};

export const checkIsIE = () => {
    const ua = window.navigator.userAgent;
    return ua && (ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1) ? true : false;
};

export const getFilename = string => {
    if (!string || typeof string !== 'string') return null;

    const split = string.split('/');
    const last = split.pop();
    const sub = last.substring(0, last.indexOf('_') + 1);
    const temp = last.split(sub);
    return temp.pop();
};

export const getFileType = string => {
    if (string.indexOf('.pdf') !== -1) {
        return 'document';
    }

    if (
        string.indexOf('.jpeg') !== -1 ||
        string.indexOf('.jpg') !== -1 ||
        string.indexOf('.png') !== -1
    ) {
        return 'photo';
    }

    return 'video';
};

export const checkLinkProtocol = link => {
    return /(http(s?)):\/\//gi.test(link);
};

export const isValidEmail = string => {
    // "^[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$";
    return /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>().,;\s@"]+\.{0,1})+([^<>().,;:\s@"]{2,}|[\d.]+))$/.test(
        string,
    );
};

export const isValidUUID = uuid => {
    const uuidV4Regex = /^[A-F\d]{8}-[A-F\d]{4}-[A-F\d]{4}-[89AB][A-F\d]{3}-[A-F\d]{12}$/i;
    return uuidV4Regex.test(uuid);
};

export const isSelfcare = () => {
    const hostname = window.location.hostname;
    return hostname.includes('selfcare');
};

export const isMediPet = () => {
    const hostname = window.location.hostname;
    return hostname.includes('medipet');
};

export const isB2B = () => {
    const hostname = window.location.hostname;
    return hostname.includes('b2b');
};

export const isDevEnv = () => {
    const hostname = window.location.hostname;
    return hostname.includes('integrations') || hostname.includes('localhost');
};

export const isLocalEnv = () => {
    const hostname = window.location.hostname;
    return hostname.includes('localhost');
};

export const isIntEnv = () => {
    const hostname = window.location.hostname;
    return hostname.includes('integrations');
};

export const isStgEnv = () => {
    const hostname = window.location.hostname;
    return hostname.includes('staging');
};

export const isQaEnv = () => {
    const hostname = window.location.hostname;
    return hostname.includes('qa');
};

export const isProdEnv = () => {
    return !isIntEnv() && !isDevEnv() && !isLocalEnv() && !isStgEnv() && !isQaEnv();
};

export const logoutRedirect = () => {
    window.location.href = '/logout';
};

export const homeRedirect = () => {
    window.location.href = '/';
};

export const stackFunction = (func, callback) => {
    setTimeout(() => {
        func();
    }, 0);

    callback();
};

export const getDefaultShowSearchOnList = () => {
    return isDevEnv() || isStgEnv() ? 5 : DEFAULT_SHOW_SEARCH_ON_LIST;
};

export const getAPIBaseURL = () => {
    const prefix = API_URL_PREFIX;
    let middle = '.';
    const sufix = API_URL_SUFIX;

    if (isDevEnv()) {
        middle = '.integrations.';
    } else if (isStgEnv()) {
        middle = '.staging.';
    } else if (isQaEnv()) {
        middle = '.qa.';
    }

    return `${prefix}${middle}${sufix}`;
};

export const getEnv = () => {
    if (isDevEnv()) {
        return 'integrations';
    } else if (isStgEnv()) {
        return 'staging';
    } else if (isQaEnv()) {
        return 'qa';
    }

    return 'production';
};

export const getCustomerSelfcareBaseURL = () => {
    if (isDevEnv()) {
        return 'https://my.integrations.habit.io';
    } else if (isStgEnv()) {
        return 'https://my.qa.habit.io';
    } else if (isQaEnv()) {
        return 'https://my.qa.habit.io';
    }

    return 'https://my.habit.io';
};

export const generateNamespace = value => {
    return value.toLowerCase().replace(/\s/g, '_').replace('#', '');
};

export const tryParseJSON = jsonString => {
    try {
        const o = JSON.parse(jsonString);

        if (o && typeof o === 'object') {
            return o;
        }
    } catch (e) {
        if (isDevEnv()) {
            console.log('TCL: tryParseJSON -> exception', e);
        }
    }

    return false;
};

export const isJson = str => {
    try {
        JSON.parse(str);
    } catch (error) {
        // errorHandlerService(error);
        return false;
    }
    return true;
};

export const isJsonAndParseIt = str => {
    let value = null;
    try {
        value = JSON.parse(str);
    } catch (error) {
        // errorHandlerService(error);
        return false;
    }
    return value;
};

export const isObject = item => {
    return typeof item === 'object' && !Array.isArray(item) && item !== null;
};

export const objectToString = obj => {
    let string = '';
    Object.entries(obj).forEach(([key, value]) => {
        string = string?.concat(`${value} ${capitalizeFirstLetter(key)} / `);
    });

    return string !== '' ? string.slice(0, -2) : '';
};

export const getIntegrationDeviceRecipeIdByEnv = () => {
    if (isQaEnv()) {
        return QA_INTEGRATION_DEVICE_RECIPE_ID;
    }

    if (process.env.REACT_APP_INTEGRATION_DEVICE_RECIPE_ID) {
        return process.env.REACT_APP_INTEGRATION_DEVICE_RECIPE_ID;
    }
};

export const filterArrayByObjPropertyValue = (arr, prop, value, deep) => {
    let result = arr.filter(p => p[prop] === value);

    if (deep && result && result[0]) {
        result = result[0][deep].filter(p => p[prop] === value);
    }

    return result && result[0] ? result[0] : null;
};

export const isElementIntoView = (el, scroll, margin = 0) => {
    if (!el) return null;

    const rect = el.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    // Only completely visible elements return true:
    const isVisible = elemTop + margin >= 0 && elemBottom + margin <= window.innerHeight;
    if (scroll && !isVisible) {
        el.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
    // Partially visible elements return true:
    //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
};

export const isElementIntoViewOnContainer = (element, containerId, scroll, settings) => {
    const el = document.querySelectorAll(element);

    if (el && el[0]) {
        const container = document.getElementById(containerId);

        const rect = el[0].getBoundingClientRect();
        const elemTop = rect.top;
        const elemBottom = rect.bottom;

        const isVisible = elemTop >= 0 && elemBottom <= container.clientHeight;
        if (scroll && !isVisible) {
            el[0].scrollIntoView(
                settings ? settings : { behavior: 'smooth', block: 'end', inline: 'nearest' },
            );
        }

        return isVisible;
    }

    return;
};

export const isElementIntoViewCustom = (el, scroll, margin = 0, settings = false) => {
    if (!el) return null;

    const rect = el.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    // Only completely visible elements return true:
    const isVisible = elemTop + margin >= 0 && elemBottom + margin <= window.innerHeight;
    if (scroll && !isVisible) {
        el.scrollIntoView(
            settings ? settings : { behavior: 'smooth', block: 'end', inline: 'nearest' },
        );
    }

    return isVisible;
};

export const truncateString = (string, max) => {
    return `${string.substring(0, max)}...`;
};

export const capitalizeFirstLetter = string => {
    return string ? string.trim().replace(/^\w/, c => c.toUpperCase()) : string;
};

export const lowerCaseFirstLetter = string => {
    return string ? string.trim().replace(/^\w/, c => c.toLowerCase()) : string;
};

export const capitalizeFirstWordLetter = string => {
    return string
        ? string
              .trim()
              .toLowerCase()
              .replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()))
        : string;
};

export const cleanMaskedForSubmit = string => {
    return string
        ? string
              .replace(/_/g, '')
              .replace(/--/g, '')
              .replace(/[^A-Za-z0-9]$/, '')
        : string;
};

export const cleanMaskedBirthForSubmit = string => {
    return string
        ? string
              .replace(/_/g, '')
              .replace(/--/g, '')
              .replace(/[^0-9]$/, '')
        : string;
};

export const cleanMaskedPostalCodeForSubmit = string => {
    return string
        ? string
              .replace(/_/g, '')
              .replace(/--/g, '')
              .replace(/[^0-9]$/, '')
        : string;
};

export const getFirstAndLastInitials = string => {
    const words = string.split(' ');
    let output = '';

    for (let i = 0; i < words.length; i++) {
        if (words.length > 2 && i > 0 && i < words.length - 1) {
            continue;
        }
        output += words[i].substring(0, 1).toUpperCase();
    }

    return output;
};

export const getProtectedAssetServiceEndpoints = () => {
    const env = getEnv();

    return {
        cost_month: `/v3/applications/:id/channels/:channel_id/components/${OBD_VALUES_BY_ENV[env].component_namespace}/properties/${OBD_VALUES_BY_ENV[env].property_cost_month}/value`,
        distance_month: `/v3/applications/:id/channels/:channel_id/components/${OBD_VALUES_BY_ENV[env].component_namespace}/properties/${OBD_VALUES_BY_ENV[env].property_distance_month}/value`,
    };
};

export const formatURL = url => {
    const pattern = /^((http|https):\/\/)/;

    if (!pattern.test(url)) {
        return `http://${url}`;
    }

    return url;
};

export const roundRatio = (num, precision = 2) => {
    const scaled = Math.round(num + 'e' + precision);
    return Number(scaled + 'e' + -precision);
};

export const downloadFileFromUrl = (data, fileName = 'download', type = 'text/plain') => {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.href = data;
    a.target = '_blank';
    // Use download attribute to set set desired file name
    a.setAttribute('download', fileName);
    a.click();
    document.body.removeChild(a);
};

export const downloadDataToCSV = (csv, filename) => {
    let csvFile;
    let downloadLink;

    if (
        window.Blob === undefined ||
        window.URL === undefined ||
        window.URL.createObjectURL === undefined
    ) {
        alert("Your browser doesn't support this download.");
        return;
    }

    csvFile = new Blob([csv], { type: 'text/csv' });
    downloadLink = document.createElement('a');
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    return;
};

export const generateFakeItems = (count, offset = 0) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k + offset}`,
        content: `item ${k + offset}`,
    }));

/**
 * D&D reordering the result
 */
export const reorderList = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * D&D Moves an item from one list to another list.
 */
export const moveItemFromTo = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

export const sortArrayByObjDateProperty = (arr, property) => {
    return arr.sort(function (a, b) {
        return new Date(b[property]) - new Date(a[property]);
    });
};

export const getInsuranceIcon = namespace => {
    if (!namespace) return null;

    if (namespace.indexOf('_car_') !== -1 || namespace.indexOf('v1_car') !== -1) {
        return 'icon-icon_car';
    }
    if (namespace.indexOf('_home_') !== -1 || namespace.indexOf('v1_home') !== -1) {
        return 'icon-icon_home1';
    }
    if (namespace.indexOf('_pet_') !== -1 || namespace.indexOf('v1_pet') !== -1) {
        return 'icon-icon_pets';
    }
    if (namespace.indexOf('_sport_') !== -1 || namespace.indexOf('v1_sport') !== -1) {
        return 'icon-icon_insurance';
    }
    if (namespace.indexOf('_health_') !== -1 || namespace.indexOf('v1_health') !== -1) {
        return 'icon-icon_hospital';
    }
    return null;
};

export const getAppVersion = string => {
    const s = string.split('+');
    return s[0] ? s[0].replace('v', '') : '';
    // return s[0] ? s[0].replace('v', '').replaceAll('.', '') : '';
};

export const getInsuranceIconBySegment = segment => {
    if (!segment) return null;

    if (segment.toLowerCase().indexOf('car') !== -1) {
        return 'icon-icon_car';
    }
    if (segment.toLowerCase().indexOf('home') !== -1) {
        return 'icon-icon_home1';
    }
    if (segment.toLowerCase().indexOf('pet') !== -1) {
        return 'icon-icon_pets';
    }
    if (segment.toLowerCase().indexOf('sport') !== -1) {
        return 'icon-icon_insurance';
    }
    if (segment.toLowerCase().indexOf('health') !== -1) {
        return 'icon-icon_hospital';
    }
    if (segment.toLowerCase().indexOf('phone') !== -1) {
        return 'icon-icon_apps';
    }
    return 'icon-icon_insurance';
};

export const getSchemaType = schema => {
    switch (schema) {
        case 'v2_free_text-1':
        case 'v1_string':
            return 'text';
        case 'v2_single_option_select-1':
            return 'select';
        case 'v2_location':
        case 'v2_address_coordinates':
            return 'location';
        case 'v2_date-1':
            return 'date';
        case 'v2_single_file_upload':
        case 'v2_single_asset_upload':
            return 'upload';
        case 'v2_network_entity-1':
            return 'entity';
        case 'v2_currency-2':
            return 'currency';

        default:
            return 'text';
    }
};

export const groupArrayBy = (array, key) => {
    return array.reduce((result, obj) => {
        (result[obj[key]] = result[obj[key]] || []).push(obj);
        return result;
    }, {});
};

export const getInsuranceProductTypeTheme = segment => {
    if (!segment) return 'default';

    const normalized = segment.toLowerCase();

    if (
        normalized === 'car' ||
        normalized.indexOf('_car_') !== -1 ||
        normalized.indexOf('v1_car') !== -1
    )
        return 'car';

    if (
        normalized === 'home' ||
        normalized.indexOf('_home_') !== -1 ||
        normalized.indexOf('v1_home') !== -1
    )
        return 'home';

    if (
        normalized === 'pet' ||
        normalized.indexOf('_pet_') !== -1 ||
        normalized.indexOf('v1_pet') !== -1
    )
        return 'pet';

    if (
        normalized === 'sport' ||
        normalized.indexOf('_sport_') !== -1 ||
        normalized.indexOf('v1_sport') !== -1
    )
        return 'sport';

    if (
        normalized === 'health' ||
        normalized.indexOf('_health_') !== -1 ||
        normalized.indexOf('v1_health') !== -1
    )
        return 'health';

    if (
        normalized === 'phone' ||
        normalized.indexOf('_phone_') !== -1 ||
        normalized.indexOf('v1_phone') !== -1
    )
        return 'phone';

    if (normalized === 'personal mobility') return 'default';

    return 'default';
};

export const removeDuplicateObjectFromArray = (array, key) => {
    const check = new Set();
    return array.filter(obj => !check.has(obj[key]) && check.add(obj[key]));
};

export const isEqualToZero = value => {
    return value === '0' || value === 0;
};

/**
 *    Easily filter the log searching for 🚀 prefix
 */
export const consoleLog = message => {
    if (process.env.NODE_ENV === 'development') {
        console.log(`🚀 ${message}`);
    }
};

export const parseV3URL = url => {
    const s = url.split('/');
    if (s.includes('distributor-authorized-seller-applications')) {
        return s[4];
    } else {
        return s[3];
    }
};

export const getType = (type, invert, isField) => {
    if (isField) {
        switch (type) {
            case 'Físico':
                return 'physical';
            case 'físico':
                return 'physical';
            case 'Physical':
                return 'physical';
            case 'physical':
                return 'physical';
            case 'Online':
                return 'online';
            case 'online':
                return 'online';
            case 'Phone':
                return 'phone';
            case 'phone':
                return 'phone';
            case 'Telefônico':
                return 'phone';
            case 'telefônico':
                return 'phone';
            case 'Telefone':
                return 'phone';
            case 'telefone':
                return 'phone';
            case 'En línea':
                return 'online';
            case 'Teléfono':
                return 'phone';
            default:
                break;
        }
    }
    if (invert) {
        switch (type) {
            case 'Physical':
                return getText('distributors_leadsgeneratorpoints_physicaltype');
            case 'physical':
                return getText('distributors_leadsgeneratorpoints_physicaltype');
            case 'online':
                return getText('distributors_leadsgeneratorpoints_onlinetype');
            case 'Online':
                return getText('distributors_leadsgeneratorpoints_onlinetype');
            case 'Phone':
                return getText('distributors_leadsgeneratorpoints_telephonetype');
            case 'phone':
                return getText('distributors_leadsgeneratorpoints_telephonetype');
            case 'En línea':
                return getText('distributors_leadsgeneratorpoints_onlinetype');
            case 'Teléfono':
                return getText('distributors_leadsgeneratorpoints_telephonetype');
            default:
                break;
        }
    } else {
        switch (type) {
            case 'físico':
                return getText('distributors_leadsgeneratorpoints_physicaltype');
            case 'Físico':
                return getText('distributors_leadsgeneratorpoints_physicaltype');
            case 'Online':
                return getText('distributors_leadsgeneratorpoints_onlinetype');
            case 'online':
                return getText('distributors_leadsgeneratorpoints_onlinetype');
            case 'Telefônico':
                return getText('distributors_leadsgeneratorpoints_telephonetype');
            case 'telefônico':
                return getText('distributors_leadsgeneratorpoints_telephonetype');
            case 'En línea':
                return getText('distributors_leadsgeneratorpoints_onlinetype');
            case 'Teléfono':
                return getText('distributors_leadsgeneratorpoints_telephonetype');
            default:
                break;
        }
    }
};

export function getFlagName(id) {
    return id.replace(/_/g, ' ').replace(/^./, id[0].toUpperCase());
}

export const leaveOnlyPropertiesThatAreNotUndefined = obj => {
    Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === 'object') {
            leaveOnlyPropertiesThatAreNotUndefined(obj[key]);
        } else if (obj[key] === undefined) {
            delete obj[key];
        }
    });
};

export function parseJson(json) {
    if (json && json !== undefined && json !== null && typeof json === 'string') {
        try {
            const trimmedJson = json.trim();
            if (trimmedJson !== '') {
                return JSON.parse(trimmedJson);
            } else {
                return null;
            }
        } catch (e) {
            return null;
        }
    }
    return null;
}
