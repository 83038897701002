export const project = {
    isLoading: false,
    dashboard: {
        wl: {
            name: null,
            icon: null,
            total_prototypes: 0,
            total_releases: 0,
            total_devices: 0,
            total_users: 0,
        },
    },
    settings: {
        countries: {
            list: [],
            selected: null,
        },
        currencies: {
            list: [],
            selected: null,
        },
        legalDisclaimer: null,
        country: null,
        currency: null,
    },
    documents: {
        list: [],
    },
};
