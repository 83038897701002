export const teamMembers = {
    type: 'modal',
    id: 'TeamMembersModal',
    appId: null,
    owner: null,
    active: false,
    roles: [
        {
            id: 0,
            text: 'Owner',
            value: 'owner',
        },
        {
            id: 1,
            text: 'Member',
            value: 'member',
        },
    ],
    members: [],
};
