import { lazy } from 'react';
import withAuth from 'hoc/withAuth';
import { roles } from 'services/roles';

const FlowTesterPage = lazy(() => import('redux/containers/Projects/FlowTesterPage'));
const EventsSimulatorPage = lazy(() => import('redux/containers/Projects/EventsSimulatorPage'));
const EndUsersPage = lazy(() => import('redux/containers/Projects/EndUsersPage'));
const WhiteLabelPage = lazy(() => import('redux/containers/Projects/WhiteLabelPage'));
const BuildsPage = lazy(() => import('redux/containers/Projects/BuildsPage'));
const Projects = lazy(() => import('redux/containers/Projects'));
const CompatibleDevicesPage = lazy(() => import('redux/containers/Projects/CompatibleDevicesPage'));
const DashboardPage = lazy(() => import('redux/containers/Projects/Dashboard'));
const UsecasesPage = lazy(() => import('redux/containers/Projects/UsecasesPage'));
const ProjectTemplatesServices = lazy(() => import('redux/containers/Projects/Templates/Services'));
const ProjectTemplatesServicesService = lazy(() =>
    import('redux/containers/Projects/Templates/Service'),
);
const ProjectTemplatesCards = lazy(() => import('redux/containers/Projects/Templates/Cards'));
const BusinessRuleEngine = lazy(() => import('redux/containers/Projects/BusinessRuleEngine'));
const DistributorCommissions = lazy(() =>
    import('redux/containers/Projects/DistributorCommissions'),
);
const ProjectSettings = lazy(() => import('redux/containers/Projects/ProjectSettings'));
const Documents = lazy(() => import('redux/containers/Projects/Documents'));

export const projectsRoutes = [
    {
        id: 'route-projects',
        path: '/projects/:id',
        exact: true,
        header: {
            visible: true,
            section: 'projects',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'projects',
        },
        component: withAuth(Projects),
        'data-test': 'RouteProjects',
        scope: [roles.ADMIN],
        // uniqueRole: true,
    },
    {
        id: 'route-projects-flow-tester',
        path: '/projects/:id/flow-tester',
        exact: true,
        header: {
            visible: true,
            section: 'projects',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'projects',
        },
        component: withAuth(FlowTesterPage),
        'data-test': 'RouteFlowTesterPage',
        scope: [roles.ADMIN],
        // uniqueRole: true,
    },
    {
        id: 'route-projects-events-simulator',
        path: '/projects/:id/events-simulator',
        exact: true,
        header: {
            visible: true,
            section: 'projects',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'projects',
        },
        component: withAuth(EventsSimulatorPage),
        'data-test': 'RouteProjects',
        scope: [roles.ADMIN],
        // uniqueRole: true,
    },
    {
        id: 'route-projects-system-health',
        path: '/projects/:id/system-health',
        exact: true,
        header: {
            visible: true,
            section: 'projects',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'projects',
        },
        component: withAuth(Projects),
        'data-test': 'RouteProjects',
        scope: [roles.ADMIN],
        // uniqueRole: true,
    },
    {
        id: 'route-projects-end-users',
        path: '/projects/:id/end-users',
        exact: true,
        header: {
            visible: true,
            section: 'projects',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'projects',
        },
        component: withAuth(EndUsersPage),
        'data-test': 'RouteEndUsersPage',
        scope: [roles.ADMIN],
        // uniqueRole: true,
    },
    {
        id: 'route-projects-white-label',
        path: ['/projects/:id/white-label', '/projects/:id/white-label/step/:step(1|2|3|4|5|6|7)'],
        exact: true,
        header: {
            visible: true,
            section: 'projects',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'projects',
        },
        component: withAuth(WhiteLabelPage),
        'data-test': 'RouteWhiteLabelPage',
        scope: [roles.ADMIN],
        // uniqueRole: true,
    },
    {
        id: 'route-projects-builds',
        path: ['/projects/:id/builds'],
        exact: true,
        header: {
            visible: true,
            section: 'projects',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'projects',
        },
        component: withAuth(BuildsPage),
        'data-test': 'RouteBuildsPage',
        scope: [roles.ADMIN],
        // uniqueRole: true,
    },
    {
        id: 'route-projects-marketplace',
        path: '/projects/:id/marketplace/:section(public|private|mine)',
        exact: true,
        header: {
            visible: true,
            section: 'projects',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'projects',
        },
        component: withAuth(CompatibleDevicesPage),
        'data-test': 'RouteProjectsCompatibleDevices',
        scope: [roles.ADMIN],
        // uniqueRole: true,
    },
    {
        id: 'route-projects-dashboard',
        path: '/projects/:id/dashboard',
        exact: true,
        header: {
            visible: true,
            section: 'projects',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'projects',
        },
        component: withAuth(DashboardPage),
        'data-test': 'RouteDashboardPage',
        scope: [roles.ADMIN],
        // uniqueRole: true,
    },
    {
        id: 'route-projects-usecases',
        path: '/projects/:id/usecases',
        exact: true,
        header: {
            visible: true,
            section: 'projects',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'projects',
        },
        component: withAuth(UsecasesPage),
        'data-test': 'RouteUsecasesPage',
        scope: [roles.ADMIN],
        // uniqueRole: true,
    },
    {
        id: 'route-projects-templates-services',
        path: ['/projects/:id/templates/services'],
        exact: true,
        header: {
            visible: true,
            section: 'projects',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'projects',
        },
        component: withAuth(ProjectTemplatesServices),
        'data-test': 'RouteProjectTemplatesServices',
        scope: [roles.ADMIN],
        // uniqueRole: true,
    },
    {
        id: 'route-projects-templates-services-section',
        path: [
            '/projects/:id/templates/services/:serviceId/:section(protected-assets|coverages|settings)',
        ],
        exact: true,
        header: {
            visible: true,
            section: 'projects',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'projects',
        },
        component: withAuth(ProjectTemplatesServicesService),
        'data-test': 'RouteProjectTemplatesServicesService',
        scope: [roles.ADMIN],
        // uniqueRole: true,
    },
    {
        id: 'route-projects-templates-cards',
        path: ['/projects/:id/templates/cards'],
        exact: true,
        header: {
            visible: true,
            section: 'projects',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'projects',
        },
        component: withAuth(ProjectTemplatesCards),
        'data-test': 'RouteProjectTemplatesCards',
        scope: [roles.ADMIN],
        // uniqueRole: true,
    },
    {
        id: 'route-projects-business-rule-engine',
        path: ['/projects/:id/business-rule-engine'],
        exact: true,
        header: {
            visible: true,
            section: 'projects',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'projects',
        },
        component: withAuth(BusinessRuleEngine),
        'data-test': 'RouteBusinessRuleEngine',
        scope: [roles.ADMIN],
        // uniqueRole: true,
    },
    {
        id: 'route-projects-commissions-distributor',
        path: ['/projects/:id/commissions/distributor'],
        exact: true,
        header: {
            visible: true,
            section: 'projects',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'projects',
        },
        component: withAuth(DistributorCommissions),
        'data-test': 'RouteDistributorCommissions',
        scope: [roles.ADMIN],
        // uniqueRole: true,
    },
    {
        id: 'route-projects-project-settings',
        path: ['/projects/:id/settings/project'],
        exact: true,
        header: {
            visible: true,
            section: 'projects',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'projects',
        },
        component: withAuth(ProjectSettings),
        'data-test': 'RouteProjectSettings',
        scope: [roles.ADMIN],
        // uniqueRole: true,
    },
    {
        id: 'route-projects-documents',
        path: ['/projects/:id/documents'],
        exact: true,
        header: {
            visible: true,
            section: 'projects',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'projects',
        },
        component: withAuth(Documents),
        'data-test': 'RouteDocuments',
        scope: [roles.ADMIN],
        // uniqueRole: true,
    },
];
