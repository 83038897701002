import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './Avatar.module.scss';
import { getFirstAndLastInitials } from 'utils/functions';

export class Avatar extends React.PureComponent {
    static propTypes = {
        random: PropTypes.bool,
        color: PropTypes.string,
        customClass: PropTypes.string,
        text: PropTypes.string,
        theme: PropTypes.oneOf(['light', 'dark']),
        hasIcon: PropTypes.bool,
        icon: PropTypes.string,
    };

    static defaultProps = {
        theme: 'light',
    };

    getRandomColor() {
        const colors = ['#ffe1e4', '#d5dae2', '#dcfaf3', '#f5effa', '#c3ecf5'];

        return colors[Math.floor(Math.random() * colors.length)];
    }

    getAvatarIcon(icon) {
        return icon === 'user'
            ? 'icon-icon_user'
            : icon === 'home' || icon.toLowerCase().indexOf('home') !== -1
            ? 'icon-icon_home1'
            : icon === 'car' || icon.toLowerCase().indexOf('car') !== -1
            ? 'icon-icon_car'
            : icon === 'pet' || icon.toLowerCase().indexOf('pet') !== -1
            ? 'icon-icon_pets'
            : icon === 'phone' || icon.toLowerCase().indexOf('phone') !== -1
            ? 'icon-icon_apps'
            : 'icon-icon_insurance';
    }

    render() {
        const { random, color, text, theme, hasIcon, icon, customClass } = this.props;
        const customStyle = random ? '#c3ecf5' : color;
        if (!hasIcon) {
            return (
                <div
                    data-test="AvatarContainer"
                    style={{ backgroundColor: customStyle }}
                    className={classnames(Styles.avatar, Styles[theme], Styles[customClass])}
                >
                    {text && getFirstAndLastInitials(text)}
                </div>
            );
        } else {
            return (
                <div
                    data-test="AvatarContainer"
                    style={{ backgroundColor: customStyle }}
                    className={classnames(Styles.avatar, Styles[theme], Styles[customClass])}
                >
                    <i className={this.getAvatarIcon(icon)}></i>
                </div>
            );
        }
    }
}

export default Avatar;
