import { getText } from 'localization';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { lazy, Suspense } from 'react';
import Button from 'shared/Button';
import ErrorBoundary from 'shared/ErrorBoundary';
import Loading from 'shared/Loading';
import Popup from 'shared/Popup';
import { getElementPosition } from 'utils/functions';
import Styles from './EntityDirectory.module.scss';
import Header from './Header';

const Table = lazy(() => import('components/Networks/Table'));
const EntityModal = lazy(() => import('redux/containers/Networks/EntityModal'));

export class EntityDirectory extends React.PureComponent {
    state = {
        search: '',
        focusEdit: false,
        settings: {
            active: false,
            element: {},
            id: null,
        },
    };

    static propTypes = {
        isLoading: PropTypes.bool,
        isSaving: PropTypes.bool,
        texts: PropTypes.object,
        entities: PropTypes.array,
        directory: PropTypes.object,
        onEditEntityDirectory: PropTypes.func,
        history: PropTypes.object,
        onOpenEntityModal: PropTypes.func,
        getDirectories: PropTypes.func,
        getDirectory: PropTypes.func,
        activeModal: PropTypes.bool,
        tableHeader: PropTypes.array,
        tableFilters: PropTypes.object,
        tablePagination: PropTypes.object,
        openPrompt: PropTypes.func,
        onDeleteEntity: PropTypes.func,
        onClearNetwork: PropTypes.func,
        ownProps: PropTypes.object,
    };

    componentDidMount() {
        this.props.getDirectory();

        const values = queryString.parse(this.props.ownProps.location.search);
        if (values && values.from && values.from === 'create') {
            this.setState({ focusEdit: true });
        }
    }

    componentWillUnmount() {
        this.props.onClearNetwork();
    }

    handleOnSearch = e => {
        const value = e.target.value;

        this.setState({
            search: value,
        });
    };

    handleOnSearchClear = () => {
        this.setState({
            search: '',
        });
    };

    handleOnSubmit = e => {
        e.preventDefault();
    };

    handleOnOpenEntityModal = e => {
        if (e && e.id) {
            this.props.onOpenEntityModal({ type: 'edit', id: e.id });
        } else {
            this.props.onOpenEntityModal({ type: 'add' });
        }
    };

    handleToogleSettings = e => {
        e.preventDefault();
        const id = e.currentTarget.getAttribute('data-id');

        this.setState({
            settings: {
                active: !this.state.settings?.active,
                element: getElementPosition(e.currentTarget),
                id: id,
            },
        });
    };

    handleClickSetting = e => {
        e.preventDefault();

        const data = {
            id: 'deleteEntityPrompt',
            title: 'Alert',
            content: getText('prompt_delete_entity'),
            onConfirm: () => this.props.onDeleteEntity({ id: this.state.settings.id }),
        };

        this.props.openPrompt(data);
    };

    handleCloseSettings = () => {
        this.setState({
            settings: {
                active: false,
                id: null,
            },
        });
    };

    getPopupContent() {
        return (
            <>
                <li onClick={this.handleClickSetting}>{getText('delete_entity')}</li>
            </>
        );
    }

    render() {
        const {
            isLoading,
            isSaving,
            texts,
            entities,
            directory,
            onEditEntityDirectory,
            activeModal,
            tableHeader,
            tableFilters,
            tablePagination,
        } = this.props;
        const { search, focusEdit, settings } = this.state;

        const searchResults = entities.filter(
            e => e.name.toUpperCase().indexOf(search.toUpperCase()) !== -1,
        );

        return (
            <ErrorBoundary>
                <article data-test="EntityDirectoryContainer" className={Styles.entity}>
                    <Header
                        data-test="EntityDirectoryHeader"
                        autoFocus={focusEdit}
                        texts={texts}
                        isLoading={isLoading}
                        isSaving={isSaving}
                        directory={directory}
                        onEdit={onEditEntityDirectory}
                        hasEntities={entities && entities.length ? true : false}
                        tooltipText={getText('add_entity')}
                        onAdd={this.handleOnOpenEntityModal}
                        onChange={this.handleOnSearch}
                        onClear={this.handleOnSearchClear}
                        searchValue={search}
                    />

                    <section className={Styles.scrollContainer}>
                        <Suspense fallback={<Loading theme="none" variant="sm" />}>
                            {isLoading && (
                                <Loading
                                    theme="white"
                                    background="opacity"
                                    variant="sm"
                                    position="absolute"
                                />
                            )}

                            {!isLoading && (!entities || entities.length === 0) && (
                                <div data-test="EntityDirectoryEmpty" className={Styles.empty}>
                                    <div className={Styles.icons}>
                                        <i className="icon-icon_entities"></i>
                                        <i className="icon-icon_entities"></i>
                                        <i className="icon-icon_entities"></i>
                                    </div>
                                    <div className={Styles.text}>
                                        <h2>No entities yet!</h2>
                                        <p>Let’s start to add your first entity</p>
                                    </div>
                                    <div className={Styles.add}>
                                        <Button
                                            data-test="EntityDirectoryAdd"
                                            variant="filled"
                                            theme="ternary"
                                            value="+ Add entities"
                                            state="active"
                                            onClick={this.handleOnOpenEntityModal}
                                        />
                                    </div>
                                </div>
                            )}

                            {entities && entities.length > 0 && (
                                <Table
                                    data-test="EntityDirectoryTable"
                                    section="claims"
                                    header={tableHeader}
                                    data={searchResults}
                                    filters={tableFilters}
                                    pagination={tablePagination}
                                    texts={texts}
                                    onClickPrev={this.handleOnClickPrev}
                                    onClickNext={this.handleOnClickNext}
                                    onClickStart={this.handleOnClickStart}
                                    onClickEnd={this.handleOnClickEnd}
                                    onEdit={this.handleOnOpenEntityModal}
                                    onSort={this.handleOnSort}
                                    emptyText={getText('no_results')}
                                    hasMoreInfo
                                    onMoreInfo={this.handleToogleSettings}
                                />
                            )}

                            {activeModal && <EntityModal data-test="EntityDirectoryEntityModal" />}

                            <Popup
                                data-test="EntityDirectorySettingsPopup"
                                list
                                position="bottom"
                                id="entitySettingsPopup"
                                active={settings?.active}
                                element={settings.element}
                                element-id={settings.id}
                                onClickSetting={this.handleClickSetting}
                                closePopup={this.handleCloseSettings}
                                content={this.getPopupContent()}
                            />
                        </Suspense>
                    </section>
                </article>
            </ErrorBoundary>
        );
    }
}

export default EntityDirectory;
