import ActionTypes from 'redux/actionTypes';
import rootInitialState from 'redux/store/initialState';
import { generateUUID } from 'utils/functions';

export const initialState = {
    flowTester: rootInitialState.flowTester,
};

const reducer = (state = rootInitialState, action) => {
    switch (action.type) {
        case ActionTypes.FLOW_TESTER_SEARCH_USER:
            return searchUser(state, action);
        case ActionTypes.FLOW_TESTER_CREATE_NEW:
            return createFlow(state);
        case ActionTypes.FLOW_TESTER_ADD_STEP:
            return addStep(state, action);
        case ActionTypes.FLOW_TESTER_EDIT_STEP:
            return editStep(state, action);
        case ActionTypes.FLOW_TESTER_DELETE_STEP:
            return deleteStep(state, action);
        case ActionTypes.FLOW_TESTER_EDIT_NOTIFICATIONS:
            return editNotifications(state, action);
        case ActionTypes.FLOW_TESTER_SELECT_DEVICE:
            return selectDevice(state, action);
        case ActionTypes.FLOW_TESTER_DEVICE_PROPERTIES:
            return deviceProperties(state, action);
        case ActionTypes.FLOW_TESTER_ADD_FLOW:
            return addFlow(state, action);
        case ActionTypes.FLOW_TESTER_EDIT_FLOW:
            return editFlow(state, action);
        case ActionTypes.FLOW_TESTER_DELETE_FLOW:
            return deleteFlow(state, action);
        case ActionTypes.FLOW_TESTER_TOGGLE_SCHEDULE:
            return toggleSchedule(state, action);
        case ActionTypes.FLOW_TESTER_TOGGLE_EVENT_MODE:
            return toggleEventMode(state, action);
        case ActionTypes.FLOW_TESTER_CHANGE_RATE_INTERVAL:
            return changeRateInterval(state, action);
        default:
            return state;
    }
};

/**
 * Change Rate Interval
 * @param {*} state
 * @param {*} action
 */
const changeRateInterval = (state, action) => {
    const payload = action.payload.data;

    const output = state.map(flow => {
        if (flow.id === payload.flowId) {
            return {
                ...flow,
                successRateInterval: Number(payload.id),
            };
        }
        return flow;
    });

    return [...output];
};

/**
 * Toggle Event Mode
 * @param {*} state
 * @param {*} action
 */
const toggleEventMode = (state, action) => {
    const payload = action.payload.data;

    const output = state.map(flow => {
        if (flow.id === payload.flowId) {
            return {
                ...flow,
                mode: payload.mode,
            };
        }
        return flow;
    });

    return [...output];
};

/**
 * Add Flow
 * @param {*} state
 * @param {*} action
 */
const addFlow = (state, action) => {
    const payload = action.payload.data;

    const output = state.map(flow => {
        if (flow.id === payload.flowId) {
            return {
                ...flow,
                mode: 'view',
            };
        }
        return flow;
    });

    return [...output];
};

/**
 * Edit Flow
 * @param {*} state
 * @param {*} action
 */
const editFlow = (state, action) => {
    const payload = action.payload.data;

    const output = state.map(flow => {
        if (flow.id === payload.flowId) {
            return {
                ...flow,
                mode: 'edit',
            };
        }
        return flow;
    });

    return [...output];
};

/**
 * Delete Flow
 * @param {*} state
 * @param {*} action
 */
const deleteFlow = (state, action) => {
    const payload = action.payload.data;

    const output = state.filter(flow => flow.id !== payload.flowId);

    if (output.length === 0) {
        const flowTemplate = generateFakeFlow(state);

        return [flowTemplate];
    }

    return [...output];
};

/**
 * Toggle Schedule
 * @param {*} state
 * @param {*} action
 */
const toggleSchedule = (state, action) => {
    const toggle = action.payload.data;

    const output = state.map(flow => {
        if (flow.id === toggle.flowId) {
            return {
                ...flow,
                schedule: toggle.data,
            };
        }
        return flow;
    });

    return [...output];
};

/**
 * Search User
 * @param {*} state
 * @param {*} action
 */
const searchUser = (state, action) => {
    const user = action.payload.user;
    const id = action.payload.id;

    const output = state.map(flow => {
        if (flow.id === id) {
            return {
                ...flow,
                user: {
                    value: user,
                    match: user !== '' ? true : false,
                },
            };
        }
        return flow;
    });

    return [...output];
};

/**
 * Add Step
 * @param {*} state
 * @param {*} action
 */
const addStep = (state, action) => {
    const data = action.payload.data;

    const output = state.map(flow => {
        if (flow.id === data.flowId) {
            const length = flow.steps.length || 0;
            const id = flow.steps[length - 1] ? flow.steps[length - 1].id : 0;

            const newStep = {
                id: id + 1,
                text: data.form.text,
                interval: Number(data.form.interval),
                url: data.form.url,
            };

            return {
                ...flow,
                steps: [...flow.steps, newStep],
            };
        }
        return flow;
    });

    return [...output];
};

/**
 * Edit Step
 * @param {*} state
 * @param {*} action
 */
const editStep = (state, action) => {
    const payload = action.payload.data;

    const output = state.map(flow => {
        if (flow.id === payload.flowId) {
            return {
                ...flow,
                steps: flow.steps.map((step, index) => {
                    if (step.id === payload.stepId) {
                        return {
                            ...step,
                            text: payload.form.text,
                            interval: Number(payload.form.interval),
                            url: payload.form.url,
                        };
                    }

                    return step;
                }),
            };
        }
        return flow;
    });

    return [...output];
};

/**
 * Delete Step
 * @param {*} state
 * @param {*} action
 */
const deleteStep = (state, action) => {
    const payload = action.payload;

    const output = state.map(flow => {
        if (flow.id === payload.id) {
            return {
                ...flow,
                steps: flow.steps.filter(step => step.id !== payload.step),
            };
        }
        return flow;
    });

    return [...output];
};

/**
 * Edit Notifications
 * @param {*} state
 * @param {*} action
 */
const editNotifications = (state, action) => {
    const payload = action.payload.data;

    const output = state.map(flow => {
        if (flow.id === payload.flowId) {
            return {
                ...flow,
                notifications: payload.data,
            };
        }
        return flow;
    });

    return [...output];
};

/**
 * Select Device
 * @param {*} state
 * @param {*} action
 */
const selectDevice = (state, action) => {
    const payload = action.payload.data;

    const output = state.map(flow => {
        if (flow.id === payload.flowId) {
            const device = getDeviceById(flow, payload.id);

            return {
                ...flow,
                selectedDevice: payload.id,
                deviceProperties: {
                    deviceId: payload.id,
                    properties: device.properties,
                },
            };
        }
        return flow;
    });

    return [...output];
};

/**
 * Get Device By ID
 * @param {*} state
 * @param {*} id
 */
const getDeviceById = (state, id) => {
    const index = state.devices.findIndex(i => {
        return i.id === id;
    });

    if (index !== -1) {
        return state.devices[index];
    }

    return null;
};

/**
 * Device Properties
 * @param {*} state
 * @param {*} action
 */
const deviceProperties = (state, action) => {
    const payload = action.payload.data;

    const output = state.map(flow => {
        if (flow.id === payload.flowId) {
            return {
                ...flow,
                deviceProperties: {
                    ...flow.deviceProperties,
                    properties: flow.deviceProperties.properties.map(property => {
                        if (property.id === payload.data.propertyId) {
                            return {
                                ...property,
                                value: payload.data.value,
                            };
                        }

                        return property;
                    }),
                },
            };
        }
        return flow;
    });

    return [...output];
};

/**
 * Create Flow
 * @param {*} state
 * @param {*} action
 */
const createFlow = (state, action) => {
    const flowTemplate = generateFakeFlow(state);

    return [...state, flowTemplate];
};

const generateFakeFlow = (state, reset) => {
    const id = generateUUID();

    const flowTemplate = {
        id: id,
        mode: 'edit',
        successRateInterval: 0,
        user: {
            value: '',
            match: false,
        },
        selectedDevice: null,
        notifications: {
            type: '',
            email: '',
        },
        schedule: {
            status: 'off',
            interval: 0,
        },
        deviceProperties: {
            deviceId: null,
            properties: [],
        },
        devices: [
            {
                id: '5060d1bb-9f0d-4db2-8309-39d11946e647-0',
                name: 'Nest Camera',
                image: 'https://store.nest.com/assets/images/social/open-graph/nest-cam-iq.jpg',
                properties: [
                    {
                        id: 'f4a0fd4e-0e54-48d9-bbe0-1734cf2749db-0',
                        name: 'Video/motion',
                        value: 'on',
                    },
                    {
                        id: 'f4a0fd4e-0e54-48d9-bbe0-1734cf2749db-1',
                        name: 'Audio',
                        value: 'off',
                    },
                ],
            },
            {
                id: '5060d1bb-9f0d-4db2-8309-39d11946e647-1',
                name: 'Nest Smoke Detector',
                image: 'https://store.nest.com/assets/images/social/open-graph/smoke-co-alarm.jpg',
                properties: [
                    {
                        id: 'f5a87a38-1327-41ce-abd2-dd97c2a0bdf0-0',
                        name: 'Smoke detector',
                        value: 'off',
                    },
                    {
                        id: 'f5a87a38-1327-41ce-abd2-dd97c2a0bdf0-1',
                        name: 'Audio alarm',
                        value: 'on',
                    },
                ],
            },
        ],
        steps: [],
    };

    return flowTemplate;
};

export default reducer;
