import { getText } from 'localization';
import { toast } from 'react-toastify';
import {
    INVALID_ADDRESS,
    INVALID_EMAIL,
    INVALID_PHONE_NUMBER,
    INVALID_SMS,
    INVALID_USERS,
    MANDATORY_FIELDS,
    MAX_SIZE,
    SELLER_INVITE_ALREADY_EXISTS,
    TOKEN_EXPIRED,
    WRONG_FORMAT,
    getErrorMsgByCode,
} from 'services/errorCodes';
import { DEFAULT_ALERT_ERROR_MESSAGE, DEFAULT_TOAST_ALERT_TIME } from 'utils/constants';

export const sendSuccessToast = msg => {
    const successMsg = msg ? msg : getText('success_endpoint_label');
    toast.success(successMsg, {
        position: 'bottom-right',
        className: 'success',
        bodyClassName: 'success',
        autoClose: DEFAULT_TOAST_ALERT_TIME,
    });
};

export const sendErrorToast = msg => {
    const errorMsg = msg && typeof msg === 'string' ? msg : DEFAULT_ALERT_ERROR_MESSAGE;

    toast(errorMsg, {
        position: 'bottom-right',
        className: 'error',
        bodyClassName: 'error',
        autoClose: DEFAULT_TOAST_ALERT_TIME,
    });
};

export const sendErrorToastByStatusCode = response => {
    if (response && response.data && (response.data.code || response.data.code === 0)) {
        switch (response.data.code) {
            case MAX_SIZE:
                sendErrorToast(response.data.text ? response.data.text : 'Maximum size exceed.');
                break;
            case INVALID_USERS:
                sendErrorToast(
                    'Invalid users: check if the email and/or phone number entered match the application users.',
                );
                break;
            case TOKEN_EXPIRED:
                sendErrorToast(
                    'Token expired: please logout and login. Sorry for the inconvenience.',
                );
                break;
            case INVALID_EMAIL:
                sendErrorToast('Email: please enter a valid email.');
                break;
            case INVALID_PHONE_NUMBER:
                sendErrorToast(
                    'Phone number: please enter a valid phone number starting with country code.',
                );
                break;
            case INVALID_ADDRESS:
                sendErrorToast('Location: please enter a valid address.');
                break;
            case MANDATORY_FIELDS:
                sendErrorToast('Mandatory fields missing.');
                break;
            case SELLER_INVITE_ALREADY_EXISTS:
                sendErrorToast(getErrorMsgByCode(response.data.code));
                break;
            case INVALID_SMS:
                sendErrorToast(getText('login_last_page_company_sms_validation_error_invalid'));
                break;
            case WRONG_FORMAT:
                sendErrorToast('Invalid value type! Please check the input.');
                break;
            default:
                sendErrorToast(DEFAULT_ALERT_ERROR_MESSAGE);
                break;
        }
    } else {
        if (
            response &&
            response.data &&
            response.data.indexOf("error: Token didn't provide the necessary permissions") !== -1
        ) {
            sendErrorToast('Token expired: please logout and login. Sorry for the inconvenience.');
        } else {
            console.log(response);
            sendErrorToast(DEFAULT_ALERT_ERROR_MESSAGE);
        }
    }
};
