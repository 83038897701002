import React from 'react';
import PropTypes from 'prop-types';
import Styles from './ErrorBoundary.module.scss';
import { DEFAULT_ALERT_ERROR_MESSAGE } from 'utils/constants';

export class ErrorBoundary extends React.PureComponent {
    state = {
        hasError: null,
        error: null,
        errorInfo: null,
    };

    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
            .isRequired,
        FallbackComponent: PropTypes.any,
    };

    static getDerivedStateFromError(error) {
        return { hasError: true, errorInfo: error };
    }

    componentDidCatch(error, info) {
        this.setState({
            hasError: true,
            error: error,
            errorInfo: info,
        });

        if (process.env.NODE_ENV === 'production') {
            try {
                const Bugsnag = require('@bugsnag/js');
                Bugsnag.notify(error, event => {
                    event.addMetadata('ErrorBoundary', {
                        error: error,
                        errorInfo: info,
                    });
                });
            } catch (error) {
                console.log('ErrorBoundary', JSON.stringify(error));
            }
        }
    }

    render() {
        const { hasError, error, errorInfo } = this.state;

        if (hasError) {
            return (
                <div data-test="ErrorBoundary" className={Styles.errorBoundary}>
                    <h1>{DEFAULT_ALERT_ERROR_MESSAGE}</h1>
                    <details>
                        {error && error.toString()}
                        <br />
                        {errorInfo.componentStack}
                    </details>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
