import { getText } from 'localization';
import { NETWORK_MAX_NUM_OF_ITEMS } from 'utils/constants';

export const networks = {
    isLoading: false,
    isSaving: false,
    segments: [],
    categories: [],
    countries: [],
    providers: {
        selected: '',
        list: [],
    },
    dashboard: {
        totals: {
            total_number_of_entity_directories: 0,
            total_number_of_service_directories: 0,
            total_number_of_item_directories: 0,
            total_number_of_networks: 0,
        },
    },
    share: {
        type: 'modal',
        id: 'ShareModal',
        appId: null,
        owner: null,
        active: false,
        roles: [
            {
                id: 0,
                text: 'Owner',
                value: 'owner',
            },
            {
                id: 1,
                text: 'Member',
                value: 'member',
            },
        ],
        members: [],
    },
    entities: {
        directories: [],
        entity: {
            type: 'modal',
            id: 'EntityModal',
            active: false,
            entity: null,
            canSave: false,
        },
        list: [],
        header: [
            {
                id: 0,
                name: 'Name',
                field: 'name',
                isSortable: true,
                hasIcon: true,
            },
            {
                id: 1,
                name: 'Type',
                field: 'categories',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 2,
                name: 'Location',
                field: 'address',
                isSortable: true,
                hasIcon: null,
            },
        ],
        pagination: {
            current: '--',
            total: '--',
            next: true,
            page_start_index: 0,
        },
        filters: {
            field: 'car_plate',
            direction: 'asc',
        },
        chunk: 15,
    },
    services: {
        directories: [],
        service: {
            type: 'modal',
            id: 'ServiceModal',
            active: false,
            service: null,
            canSave: false,
        },
        list: [],
        header: [
            {
                id: 0,
                name: 'Name',
                field: 'name',
                isSortable: true,
                hasIcon: true,
            },
            {
                id: 1,
                name: 'Type',
                field: 'categories',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 2,
                name: 'Segment',
                field: 'segments',
                isSortable: true,
                hasIcon: null,
            },
        ],
        pagination: {
            current: '--',
            total: '--',
            next: true,
            page_start_index: 0,
        },
        filters: {
            field: 'car_plate',
            direction: 'asc',
        },
        chunk: 15,
    },
    items: {
        directories: [],
        item: {
            type: 'modal',
            id: 'ItemModal',
            active: false,
            item: null,
            canSave: false,
        },
        list: [],
        header: [
            {
                id: 0,
                name: 'Name',
                field: 'name',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 1,
                name: 'Type',
                field: 'categories',
                isSortable: true,
                hasIcon: null,
            },
            {
                id: 2,
                name: 'Segment',
                field: 'segments',
                isSortable: true,
                hasIcon: null,
            },
        ],
        pagination: {
            current: '--',
            total: '--',
            next: true,
            page_start_index: 0,
        },
        filters: {
            field: 'car_plate',
            direction: 'asc',
        },
        chunk: 15,
    },
    networks: {
        size: NETWORK_MAX_NUM_OF_ITEMS,
        networks: [],
        network: {
            type: 'modal',
            id: 'NetworkEntityModal',
            active: false,
            entity: null,
            services: {
                selectState: {
                    actives: 0,
                    selected: null,
                },
                list: [],
            },
            items: {
                selectState: {
                    actives: 0,
                    selected: null,
                },
                list: [],
            },
            network: null,
            temp: null,
            canSave: false,
        },
        entities: {
            selectState: {
                actives: 0,
                selected: null,
            },
            list: [],
            header: [
                {
                    id: 0,
                    name: getText('selfcare_network_section_name_label'),
                    field: 'name',
                    isSortable: true,
                    hasIcon: true,
                    hasCheckbox: true,
                },
                {
                    id: 1,
                    name: getText('selfcare_network_section_type_label'),
                    field: 'categories',
                    isSortable: true,
                    hasIcon: null,
                },
                {
                    id: 2,
                    name: getText('selfcare_network_section_location_label'),
                    field: 'address',
                    isSortable: true,
                    hasIcon: null,
                },
            ],
            pagination: {
                current: '--',
                total: '--',
                next: true,
                page_start_index: 0,
            },
            filters: {
                field: 'car_plate',
                direction: 'asc',
            },
            chunk: 15,
        },
    },
};
