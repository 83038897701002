import { connect } from 'react-redux';
import PaymentsDocuments from 'pages/Insurance/PaymentsDocuments';
import { hasProjectSelected, selectedProject } from 'redux/domains/insurance/selector';
import {
    insuranceOnSearchByTypeThunk,
    insuranceSearchPaginateThunk,
    insuranceSortDataThunk,
    insuranceClearSearchByTypeThunk,
    insuranceUpdatePaymentThunk,
    insuranceUploadPaymentDocumentThunk,
    insuranceDeletePaymentDocumentThunk,
} from 'redux/domains/insurance/thunk';
import { withRouter } from 'react-router';

const mapStateToProps = (state, ownProps) => ({
    texts: state.texts,
    isLoading: state.app.isLoading,
    isSaving: state.insurance.isSaving,
    data: state.insurance.payments,
    hasProjectSelected: hasProjectSelected(state),
    ownProps: ownProps,
    projectId: state.insurance.filters.projects.selected,
    project: selectedProject(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchData: () => insuranceOnSearchByTypeThunk(dispatch, null, 'payments', true),
    onSearch: data => insuranceOnSearchByTypeThunk(dispatch, data, 'payments'),
    onClearSearch: () => insuranceClearSearchByTypeThunk(dispatch, 'payments'),
    onClickPrev: data => insuranceSearchPaginateThunk(dispatch, data, 'payments', 'prev', true),
    onClickNext: data => insuranceSearchPaginateThunk(dispatch, data, 'payments', 'next', true),
    onClickStart: data => insuranceSearchPaginateThunk(dispatch, data, 'payments', 'start', true),
    onClickEnd: data => insuranceSearchPaginateThunk(dispatch, data, 'payments', 'end', true),
    onSort: data => insuranceSortDataThunk(dispatch, data, 'payments'),
    onSave: data => insuranceUpdatePaymentThunk(dispatch, data, ownProps),
    onUpload: data => insuranceUploadPaymentDocumentThunk(dispatch, data, ownProps),
    onDeleteDocument: data => insuranceDeletePaymentDocumentThunk(dispatch, data, ownProps),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentsDocuments));
