import ActionTypes from 'redux/actionTypes';
import rootInitialState from 'redux/store/initialState';

export const initialState = {
    project: rootInitialState.project,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.PROJECT_IS_LOADING:
            return isLoading(state, action);
        case ActionTypes.PROJECT_CLEAR:
            return clearProject(state, action);
        case ActionTypes.PROJECT_FETCH:
            return syncProject(state, action);
        case ActionTypes.PROJECT_EDIT:
            return editProject(state, action);
        case ActionTypes.PROJECT_DASHBOARD_SYNC_WL:
            return dashboardSyncWL(state, action);
        case ActionTypes.PROJECT_SYNC_ANALYTICS_DATA:
            return syncAnalyticsData(state, action);
        case ActionTypes.PROJECT_SYNC_INSURER_PRODUCT:
            return syncInsurerProduct(state, action);
        case ActionTypes.PROJECT_SYNC_SETTINGS_COUNTRIES:
            return syncSettingsCountries(state, action);
        case ActionTypes.PROJECT_SYNC_SETTINGS_CURRENCIES:
            return syncSettingsCurrencies(state, action);
        case ActionTypes.PROJECT_SYNC_SETTINGS_LEGAL_DISCLAIMER:
            return syncSettingsLegalDisclaimer(state, action);
        case ActionTypes.PROJECT_SYNC_DOCUMENTS:
            return syncDocuments(state, action);
        default:
            return state;
    }
};

/**
 * Sync Project
 * @param {*} state
 * @param {*} action
 */
const syncDocuments = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        documents: {
            ...state.documents,
            list: payload.elements,
        },
    };
};

/**
 * Sync Project
 * @param {*} state
 * @param {*} action
 */
const syncSettingsLegalDisclaimer = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        settings: {
            ...state.settings,
            legalDisclaimer: payload,
        },
    };
};

/**
 * Sync Project
 * @param {*} state
 * @param {*} action
 */
const syncSettingsCurrencies = (state, action) => {
    const payload = action.payload.data;

    const elements = payload.elements.map(i => {
        return {
            id: i.id,
            text: i.name,
        };
    });

    return {
        ...state,
        settings: {
            ...state.settings,
            currencies: {
                list: elements,
                selected: null,
            },
        },
    };
};

/**
 * Sync Project
 * @param {*} state
 * @param {*} action
 */
const syncSettingsCountries = (state, action) => {
    const payload = action.payload.data;

    const elements = payload.elements.map(i => {
        return {
            id: i.id,
            text: i.name,
        };
    });

    return {
        ...state,
        settings: {
            ...state.settings,
            countries: {
                list: elements,
                selected: null,
            },
        },
    };
};

/**
 * Sync Project
 * @param {*} state
 * @param {*} action
 */
const syncInsurerProduct = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        insurerProduct: payload,
    };
};

/**
 * Sync Project
 * @param {*} state
 * @param {*} action
 */
const syncAnalyticsData = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        isLoading: state.isLoading,
        analyticsDetails: payload,
    };
};

/**
 * Manage Loading state
 * @param {*} state
 * @param {*} action
 */
const isLoading = (state, action) => {
    const payload = action.payload.isLoading;

    return {
        ...state,
        isLoading: payload,
    };
};

/**
 * Clear Project
 * @param {*} state
 * @param {*} action
 */
const clearProject = (state, action) => {
    return { ...rootInitialState.project };
};

/**
 * Sync Project
 * @param {*} state
 * @param {*} action
 */
const syncProject = (state, action) => {
    const payload = action.payload.data;

    const country = payload?.cdata?.public?.country ? payload.cdata.public.country : null;
    const currency = payload?.cdata?.public?.currency ? payload.cdata.public.currency : null;

    return {
        ...state,
        isLoading: state.isLoading,
        ...payload,
        settings: {
            ...state.settings,
            country: country,
            currency: currency,
        },
    };
};

/**
 * Sync Project
 * @param {*} state
 * @param {*} action
 */
const editProject = (state, action) => {
    const payload = action.payload.data;

    return { ...state, name: payload.name };
};

/**
 * Sync Project Dashboard WL
 * @param {*} state
 * @param {*} action
 */
const dashboardSyncWL = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        dashboard: {
            ...state.dashboard,
            wl: payload,
        },
    };
};

export default reducer;
