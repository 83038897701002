import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Loading from 'shared/Loading';
import { DATE_FORMAT_NO_TIME_INTERNATIONAL } from 'utils/constants';
import { formatDate } from 'utils/functions';
import Styles from './NetworkTile.module.scss';

export class NetworkTile extends React.PureComponent {
    static defaultProps = {
        size: 'lg',
    };

    static propTypes = {
        isLoading: PropTypes.bool,
        text: PropTypes.string,
        network: PropTypes.object,
        tileType: PropTypes.string,
        type: PropTypes.string,
        baseUrl: PropTypes.string,
        onClick: PropTypes.func,
        size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
        onSettings: PropTypes.func,
    };

    handleSettings = e => {
        e.preventDefault();
    };

    render() {
        const { network, text, type, baseUrl, onClick, size, isLoading, onSettings, tileType } =
            this.props;

        if (type === 'add') {
            return (
                <div
                    data-test="NetworkTileAdd"
                    className={classnames(Styles.tile, Styles[type], Styles[size])}
                    onClick={!isLoading ? onClick : null}
                >
                    {isLoading && <Loading />}

                    {!isLoading && (
                        <>
                            <i className="icon-icon_add_2"></i>
                            <p>{text}</p>
                        </>
                    )}
                </div>
            );
        }

        return (
            <Link data-test="NetworkTileUrl" to={`${baseUrl}/${network.id}`}>
                <div
                    data-test="NetworkTileView"
                    className={classnames(Styles.tile, Styles[type], Styles[size])}
                >
                    <div className={Styles.wrapper}>
                        <div data-test="NetworkTileSettings" className={Styles.header}>
                            <p>Network</p>
                            {network && (!network.read_only || network.read_only === false) && (
                                <i
                                    className="icon-icon_more_info"
                                    data-network-id={network ? network.id : null}
                                    data-network-type={tileType ? tileType : null}
                                    onClick={onSettings}
                                ></i>
                            )}
                        </div>

                        <div className={Styles.content}>
                            {network && network.image && (
                                <img src={network.image} alt={network.name} />
                            )}
                            {network && !network.image && (
                                <div className={Styles.stamp}>
                                    <i className="icon-icon_Add_image"></i>
                                </div>
                            )}

                            <div className={Styles.info}>
                                <p data-test="NetworkTileName">{network.name}</p>
                                {/* <p data-test="NetworkTileName">{`Entities (3)`}</p> */}
                            </div>
                            <p data-test="NetworkTileDate" className={Styles.date}>
                                Created on{' '}
                                {formatDate(
                                    network.created,
                                    false,
                                    DATE_FORMAT_NO_TIME_INTERNATIONAL,
                                    false,
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

export default NetworkTile;
