import React from 'react';
import PropTypes from 'prop-types';
import Styles from './Styles.module.scss';
import ErrorBoundary from 'shared/ErrorBoundary';
import Button from 'shared/Button';

const Prompt = ({ title, text, onConfirm, isLoading, onCancel }) => {
    return (
        <ErrorBoundary>
            <article data-test="withOverlay" className={Styles.overlay}>
                <div data-testid="PromptContainer" className={Styles.prompt}>
                    <div className={Styles.header}>
                        <h3 data-testid="PromptTitle">{title}</h3>
                    </div>

                    <div className={Styles.content}>
                        <p data-testid="PromptContent" dangerouslySetInnerHTML={{ __html: text }} />
                    </div>

                    <div data-testid="PromptActions" className={Styles.actions}>
                        <Button
                            // autoFocus
                            data-testid="PromptButtonConfirm"
                            value={'Ok'}
                            type="button"
                            variant="filled"
                            theme="ternary"
                            onClick={onConfirm}
                            disabled={isLoading}
                            aria-disabled={isLoading}
                            loading={isLoading}
                            // tabIndex="1"
                        />
                        <Button
                            data-testid="PromptButtonCancel"
                            value={'Cancel'}
                            type="button"
                            variant="none"
                            theme="ternary"
                            onClick={onCancel}
                            disabled={isLoading}
                        />
                    </div>
                </div>
            </article>
        </ErrorBoundary>
    );
};

Prompt.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    isLoading: PropTypes.bool,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
};

Prompt.defaultProps = {
    title: 'Alert',
    text: 'Are you sure you want to delete this Document?',
};

export default Prompt;
