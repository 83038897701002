import withAuth from 'hoc/withAuth';
import { lazy } from 'react';
import { roles } from 'services/roles';

const InsuranceProductsPage = lazy(() => import('redux/containers/Distributors/InsuranceProducts'));
const ApiWebviewPage = lazy(() => import('redux/containers/Distributors/ApiWebview'));
const MyDistributorsPage = lazy(() => import('redux/containers/Distributors/MyDistributors'));
const StoreSellersPage = lazy(() => import('redux/containers/Distributors/StoreSellers'));
const LeadsGeneratorsPage = lazy(() => import('pages/Distributors/LeadsGenerator/index.tsx'));
const LeadsGeneratorsPointsPage = lazy(() =>
    import('pages/Distributors/LeadsGeneratorPoints/index'),
);
const DistributorsPage = lazy(() => import('pages/Distributors/Distributors/index'));
const VendorsPage = lazy(() => import('pages/Distributors/Vendors'));
const Outlets = lazy(() => import('pages/Distributors/Outlets'));
const Dashboard = lazy(() => import('pages/Distributors/Dashboard'));

export const distributorsRoutes = [
    {
        id: 'route-distributors-my-distributors',
        path: ['/distributors/my-distributors'],
        exact: true,
        header: {
            visible: true,
            section: 'distributors',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'distributors',
        },
        component: withAuth(MyDistributorsPage),
        'data-test': 'RouteMyDistributorsPage',
        scope: [roles.INSURER],
    },
    {
        id: 'route-distributors-insurance-products',
        path: ['/distributors/insurance-products'],
        exact: true,
        header: {
            visible: true,
            section: 'distributors',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'distributors',
        },
        component: withAuth(InsuranceProductsPage),
        'data-test': 'RouteInsuranceProductsPage',
        scope: [roles.DISTRIBUTOR],
    },
    {
        id: 'route-distributors-api-access',
        path: ['/distributors/api-access'],
        exact: true,
        header: {
            visible: true,
            section: 'distributors',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'distributors',
        },
        component: withAuth(ApiWebviewPage),
        'data-test': 'RouteApiWebviewPage',
        scope: [roles.DISTRIBUTOR],
    },
    {
        id: 'route-distributors-store-sellers',
        path: ['/distributors/store-sellers'],
        exact: true,
        header: {
            visible: true,
            section: 'distributors',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'distributors',
        },
        component: withAuth(StoreSellersPage),
        'data-test': 'RouteStoreSellersPage',
        scope: [roles.DISTRIBUTOR],
    },
    {
        id: 'route-distributor-vendors',
        path: ['/distributors/vendors'],
        exact: true,
        header: {
            visible: true,
            section: 'distributors',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'distributors',
        },
        component: withAuth(VendorsPage),
        'data-test': 'RouteDistributorVendorsPage',
        scope: [roles.DISTRIBUTOR],
    },
    {
        id: 'route-distributors-leads-generators',
        path: ['/distributors/leads-generators'],
        exact: true,
        header: {
            visible: true,
            section: 'distributors',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'distributors',
        },
        component: withAuth(LeadsGeneratorsPage),
        'data-test': 'RouteDistributorsLeadsGeneratorsPage',
        scope: [roles.DISTRIBUTOR],
    },
    {
        id: 'route-distributors-outlets',
        path: ['/distributors/outlets'],
        exact: true,
        header: {
            visible: true,
            section: 'distributors',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'distributors',
        },
        component: withAuth(Outlets),
        'data-test': 'RouteDistributorsOutlets',
        scope: [roles.DISTRIBUTOR],
    },
    {
        id: 'route-distributors-leads-generators-points',
        path: ['/distributors/leads-generators-points'],
        exact: true,
        header: {
            visible: true,
            section: 'distributors',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'distributors',
        },
        component: withAuth(LeadsGeneratorsPointsPage),
        'data-test': 'RouteDistributorsLeadsGeneratorsPointsPage',
        scope: [roles.DISTRIBUTOR],
    },
    {
        id: 'route-distributors-distributors',
        path: ['/distributors/distributors'],
        exact: true,
        header: {
            visible: true,
            section: 'distributors',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'distributors',
        },
        component: withAuth(DistributorsPage),
        'data-test': 'RouteDistributorsLeadsGeneratorsPointsPage',
        scope: [roles.DISTRIBUTOR],
    },
    {
        id: 'route-distributors-dashboard',
        path: ['/distributors/dashboard'],
        exact: true,
        header: {
            visible: true,
            section: 'distributors',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'distributors',
        },
        component: withAuth(Dashboard),
        'data-test': 'RouteDistributorsDashboard',
        scope: [roles.DISTRIBUTOR],
    },
];
