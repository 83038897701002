import withAuth from 'hoc/withAuth';
import { lazy } from 'react';
import { roles } from 'services/roles';

const ApprovalRequestsPage = lazy(() => import('redux/containers/Admin/ApprovalRequests'));

const FinancialEntitiesPage = lazy(() => import('redux/containers/Admin/FinancialEntities'));

const DistributorsPage = lazy(() => import('redux/containers/Admin/Distributors'));

const ProvidersPage = lazy(() => import('redux/containers/Admin/Providers'));

const DistributorPage = lazy(() => import('redux/containers/Admin/Distributors/Distributor'));

const ProviderPage = lazy(() => import('redux/containers/Admin/Providers/Provider'));

const InsurerProductsPage = lazy(() => import('redux/containers/Admin/InsurerProducts'));

const InsurerProductPage = lazy(() =>
    import('redux/containers/Admin/InsurerProducts/InsurerProduct'),
);
const GeneralSettingsPage = lazy(() => import('redux/containers/Admin/GeneralSettings'));

export const adminRoutes = [
    {
        id: 'route-admin-approval-requests',
        path: ['/admin/permissions/approval-requests'],
        exact: true,
        header: {
            visible: true,
            section: 'admin',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'admin',
        },
        component: withAuth(ApprovalRequestsPage),
        'data-test': 'RouteApprovalRequestsPage',
        scope: [roles.ADMIN],
    },
    {
        id: 'route-admin-financial-entities-management',
        path: ['/admin/entities/entities-management'],
        exact: true,
        header: {
            visible: true,
            section: 'admin',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'admin',
        },
        component: withAuth(FinancialEntitiesPage),
        'data-test': 'RouteFinancialEntitiesPage',
        scope: [roles.ADMIN],
    },
    {
        id: 'route-admin-insurer-products',
        path: ['/admin/products/insurer-products'],
        exact: true,
        header: {
            visible: true,
            section: 'admin',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'admin',
        },
        component: withAuth(InsurerProductsPage),
        'data-test': 'RouteInsurerProductsPage',
        scope: [roles.ADMIN],
    },
    {
        id: 'route-admin-providers',
        path: ['/admin/products/providers'],
        exact: true,
        header: {
            visible: true,
            section: 'admin',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'admin',
        },
        component: withAuth(ProvidersPage),
        'data-test': 'RouteProvidersPage',
        scope: [roles.ADMIN],
    },
    {
        id: 'route-admin-provider',
        path: ['/admin/products/providers/:id'],
        exact: true,
        header: {
            visible: true,
            section: 'admin',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'admin',
        },
        component: withAuth(ProviderPage),
        'data-test': 'RouteProviderPage',
        scope: [roles.ADMIN],
    },
    {
        id: 'route-admin-insurer-product',
        path: ['/admin/products/insurer-products/:id'],
        exact: true,
        header: {
            visible: true,
            section: 'admin',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'admin',
        },
        component: withAuth(InsurerProductPage),
        'data-test': 'RouteInsurerProductPage',
        scope: [roles.ADMIN],
    },
    {
        id: 'route-admin-distributors',
        path: ['/admin/products/distributors'],
        exact: true,
        header: {
            visible: true,
            section: 'admin',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'admin',
        },
        component: withAuth(DistributorsPage),
        'data-test': 'RouteDistributorsPage',
        scope: [roles.ADMIN],
    },
    {
        id: 'route-admin-distributor',
        path: ['/admin/products/distributors/:id'],
        exact: true,
        header: {
            visible: true,
            section: 'admin',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'admin',
        },
        component: withAuth(DistributorPage),
        'data-test': 'RouteDistributorPage',
        scope: [roles.ADMIN],
    },
    {
        id: 'route-admin-defaults-per-geography',
        path: ['/admin/settings/defaults-per-geography'],
        exact: true,
        header: {
            visible: true,
            section: 'admin',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'admin',
        },
        component: withAuth(GeneralSettingsPage),
        'data-test': 'RouteGeneralSettingsPage',
        scope: [roles.ADMIN],
    },
];
