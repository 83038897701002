import { Observable } from 'rxjs';
import routes from './routes';
// import { getUserInfo } from 'services/localStorage';
import { apiGetAuthHeader, apiHandleRequest, apiHandleRequestRecursively } from 'services/api';
import { sendErrorToast } from 'services/toast';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';

export const adminGetApprovalRequests = async params => {
    const app_id = process.env.APP_ID || process.env.REACT_APP_APP_ID;
    const url = routes.adminGetApprovalRequests.replace(':id', app_id);

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            page_size: params && params.page_size ? params.page_size : DEFAULT_PAGE_SIZE,
            order_by: params && params.order_by ? params.order_by : '-created',
            page_start_index: params && params.page_start_index ? params.page_start_index : 0,
            type: 'insurer',
        },
    };

    return await apiHandleRequest(request);
};

export const adminSetApprovalRequest = async params => {
    const app_id = process.env.APP_ID || process.env.REACT_APP_APP_ID;
    const url = routes.adminSetApprovalRequest
        .replace(':id', app_id)
        .replace(':type', params.type)
        .replace(':type-id', params.id)
        .replace(':state', params.state);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data,
    };

    return await apiHandleRequest(request);
};

export const adminGetFinancialFlows = async params => {
    const url = routes.adminGetFinancialEntities;
    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            page_size: params && params.page_size ? params.page_size : 15,
            order_by: params && params.order_by ? params.order_by : '-created',
            page_start_index: params && params.page_start_index ? params.page_start_index : 0,
            ...params,
        },
    };

    return await apiHandleRequest(request);
};

export const adminGetFinancialEntities = async params => {
    const url = routes.adminGetFinancialEntities;
    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            page_size: params && params.page_size ? params.page_size : 15,
            order_by: params && params.order_by ? params.order_by : '-created',
            page_start_index: params && params.page_start_index ? params.page_start_index : 0,
            ...params,
        },
    };

    return await apiHandleRequest(request);
};

export const adminGetSalesChannels = async id => {
    const url = routes.adminGetSalesChannels;
    const request = {
        method: 'get',
        url: url.replace(':id', id),
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };
    return await apiHandleRequest(request);
};

export const adminGetFinancialEntity = async id => {
    const url = routes.adminGetFinancialEntity;
    const request = {
        method: 'get',
        url: url.replace(':entity-id', id),
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const adminSetFinancialEntity = async params => {
    let request = {};
    if (params.id === null) {
        const url = routes.adminCreateFinancialEntity;

        request = {
            method: 'post',
            url: url,
            headers: {
                Authorization: apiGetAuthHeader(),
                'Content-Type': 'application/json',
            },
            data: params.data,
        };
    }

    if (params.id !== null) {
        const url = routes.adminGetFinancialEntity.replace(':entity-id', params.id);

        request = {
            method: 'patch',
            url: url,
            headers: {
                Authorization: apiGetAuthHeader(),
                'Content-Type': 'application/json',
            },
            data: params.data,
        };
    }

    return await apiHandleRequest(request);
};

export const adminGetDistributors = async params => {
    const url = routes.adminGetDistributors;

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            page_size: params && params.page_size ? params.page_size : DEFAULT_PAGE_SIZE,
            order_by: params && params.order_by ? params.order_by : '-created',
            page_start_index: params && params.page_start_index ? params.page_start_index : 0,
            state:
                params.state?.length > 0
                    ? `in/[${params.state.map(state => `"${state}"`)}]/j`
                    : undefined,
            name: params.name ? params.name : undefined,
            email: params.email ? params.email : undefined,
        },
    };

    return await apiHandleRequest(request);
};

export const adminGetProviders = async params => {
    const url = routes.adminGetProviders;

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            page_size: params && params.page_size ? params.page_size : DEFAULT_PAGE_SIZE,
            order_by: params && params.order_by ? params.order_by : '-created',
            page_start_index: params && params.page_start_index ? params.page_start_index : 0,
            state:
                params.state?.length > 0
                    ? `in/[${params.state.map(state => `"${state}"`)}]/j`
                    : undefined,
            name: params.name ? params.name : undefined,
            email: params.email ? params.email : undefined,
        },
    };

    return await apiHandleRequest(request);
};

export const adminGetDistributor = async distributorId => {
    const url = routes.adminGetDistributor;

    const request = {
        method: 'get',
        url: url.split(':distributor-id').join(distributorId),
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const adminGetProvider = async providerId => {
    const url = routes.adminGetProvider;

    const request = {
        method: 'get',
        url: url.split(':provider-id').join(providerId),
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const adminCreateFinancialBreakdown = async params => {
    const url = routes.adminCreateFinancialBreakdown;

    const request = {
        method: 'POST',
        url,
        data: params.body,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const adminGetFinancialBreakdowns = async params => {
    const url = routes.adminGetFinancialBreakdowns;

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            page_size: params && params.page_size ? params.page_size : DEFAULT_PAGE_SIZE,
            order_by: params && params.order_by ? params.order_by : '-created',
            // page_start_index: params && params.page_start_index ? params.page_start_index : 0,
        },
    };

    return await apiHandleRequest(request);
};

export const adminGetFinancialBreakdown = async params => {
    let request = {};
    if (params.id !== null) {
        const url = routes.adminGetFinancialBreakdown.replace(':id', params.id);

        request = {
            method: 'get',
            url: url,
            headers: {
                Authorization: apiGetAuthHeader(),
                'Content-Type': 'application/json',
            },
        };
    }

    return await apiHandleRequest(request);
};

export const adminGetFinancialFlowAttributionOperations = async params => {
    let request = {};
    if (params?.id === null) {
        const url = routes.adminGetFinancialFlowAttributionOperations;

        request = {
            method: 'get',
            url: url,
            headers: {
                Authorization: apiGetAuthHeader(),
                'Content-Type': 'application/json',
            },
        };
    }

    return await apiHandleRequest(request);
};

export const adminUpdateFinancialBreakdown = async params => {
    let request = {};
    if (params.id === null) {
        const url = routes.adminFinancialBreakdown.replace(':id', params.id);

        request = {
            method: 'patch',
            url: url,
            headers: {
                Authorization: apiGetAuthHeader(),
                'Content-Type': 'application/json',
            },
            data: params.data,
        };
    }

    return await apiHandleRequest(request);
};

export const adminDeleteFinancialBreakdown = async params => {
    let request = {};
    if (params.id === null) {
        const url = routes.adminFinancialBreakdown.replace(':id', params.id);

        request = {
            method: 'delete',
            url: url,
            headers: {
                Authorization: apiGetAuthHeader(),
                'Content-Type': 'application/json',
            },
        };
    }

    return await apiHandleRequest(request);
};

export const adminUpdateFinancialBreakdownFlows = async params => {
    let request = {};
    if (params.id !== null) {
        const url = routes.adminUpdateFinancialBreakdownFlows.replace(':id', params.id);
        request = {
            method: 'post',
            url: url,
            headers: {
                Authorization: apiGetAuthHeader(),
                'Content-Type': 'application/json',
            },
            data: params.data,
        };
    }

    return await apiHandleRequest(request);
};

export const adminDeleteFinancialBreakdownFlows = async params => {
    let request = {};
    const url = routes.adminDeleteFinancialBreakdownFlows.replace(':id', params.id);
    request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data,
    };

    return await apiHandleRequest(request);
};

export const adminUpdateFinancialBreakdownPrecalculations = async params => {
    let request = {};
    if (params.id === null) {
        const url = routes.adminUpdateFinancialBreakdownPrecalculations.replace(':id', params.id);

        request = {
            method: 'post',
            url: url,
            headers: {
                Authorization: apiGetAuthHeader(),
                'Content-Type': 'application/json',
            },
            data: params.data,
        };
    }

    return await apiHandleRequest(request);
};

export const adminDeleteFinancialBreakdownPrecalculations = async (flowId, clickedFormulaId) => {
    let request = {};
    const url = routes.adminDeleteFinancialBreakdownPrecalculations.replace(':id', flowId);
    request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            precalculations: [
                {
                    id: clickedFormulaId,
                },
            ],
        },
    };
    return await apiHandleRequest(request);
};

export const adminFetchComissionsView = async (id, data) => {
    const url = routes.comissionsView.replace(':id', id);
    let request = {
        method: 'POST',
        url,
        data,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };
    return await apiHandleRequest(request);
};

export const adminComissionsCreatePayment = async (id, data) => {
    const url = routes.comissionsCreatePayment.replace(':financialbreakdown_id', id);
    let request = {
        method: 'POST',
        url,
        data,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };
    return await apiHandleRequest(request);
};

export const adminComissionsUpdatePayment = async (
    financialBreakdownId,
    paymentHistoryId,
    data,
) => {
    const url = routes.comissionsUpdatePayment
        .replace(':financialbreakdown_id', financialBreakdownId)
        .replace(':paymenthistory_id', paymentHistoryId);
    let request = {
        method: 'POST',
        url,
        data,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };
    return await apiHandleRequest(request);
};

export const adminFetchComissionsCSV = async (id, data) => {
    const url = routes.comissionsCSV.replace(':id', id);
    let request = {
        method: 'POST',
        url,
        data,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };
    return await apiHandleRequest(request);
};

export const adminGetFinancialPrecalculationOperations = async params => {
    let request = {};
    if (params?.id === null) {
        const url = routes.adminGetFinancialPrecalculationOperations;

        request = {
            method: 'get',
            url: url,
            headers: {
                Authorization: apiGetAuthHeader(),
                'Content-Type': 'application/json',
            },
        };
    }

    return await apiHandleRequest(request);
};

export const adminSettingsBulkUpsertLegalDisclaimer = async params => {
    const url = routes.settingsBulkUpdateLegalDisclaimers.replace(':id', params.id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data,
    };

    return await apiHandleRequest(request);
};

export const adminGetApprovalRequestsObs = params => {
    const obs = new Observable(observer => {
        adminGetApprovalRequests(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
                sendErrorToast();
            });
    });

    return obs;
};

export const adminGetInsurerInfo = async params => {
    const url = routes.adminGetInsurerInfo.replace(':id', params.id);
    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };
    return await apiHandleRequest(request);
};

export const adminGetFinancialClientsTypes = async () => {
    const url = routes.adminGetFinancialClientsTypes;
    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };
    return await apiHandleRequest(request);
};

export const adminGetFinancialClients = async params => {
    let url = routes.adminGetFinancialClients.replace(':type', params.type);

    if (params.app_id) {
        url = url + `&app_id=${params.app_id}`;
    }

    if (params?.distributor_id) {
        url = url + `&distributor_id=${params?.distributor_id}`;
    }

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            page_start_index: 0,
        },
    };
    return await apiHandleRequestRecursively(request);
};
