export const mocks = [
    {
        id: 0,
        label: 'Goes to Downtown 1 time day',
        value: 72,
        color: '#f9bb00',
    },
    {
        id: 1,
        label: 'Ride a Bicycle every day',
        value: 10,
        color: '#2d426b',
    },
    {
        id: 2,
        label: 'Drives fast',
        value: 67,
        color: '#f9bb00',
    },
];
