import { connect } from 'react-redux';
import Dashboard from 'pages/Networks/Dashboard';
import { toggleModalAction } from 'redux/domains/teamMembers/actions';
import { networksMetaDataObs } from 'redux/domains/networks/observer';

const mapStateToProps = (state, ownProps) => ({
    texts: state.texts,
    isLoading: state.app.isLoading,
    dashboard: state.networks.dashboard,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchData: data => networksMetaDataObs(dispatch, data, ownProps),
    toggleModal: () => dispatch(toggleModalAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
