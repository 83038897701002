import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NetworkDetails from 'pages/Networks/NetworkDetails';
import { networksGetNetworkDataObs } from 'redux/domains/networks/observer';
import {
    networksSaveNetworkDetailsThunk,
    networksOnUploadNetworkImageThunk,
} from 'redux/domains/networks/thunk';

const mapStateToProps = (state, ownProps) => ({
    texts: state.texts,
    isLoading: state.app.isLoading,
    isSaving: state.networks.isSaving,
    segments: state.networks.segments,
    countries: state.networks.countries,
    directory_entities: state.networks.entities.directories,
    directory_services: state.networks.services.directories,
    directory_items: state.networks.items.directories,
    data: state.networks.networks.network.network,
    temp: state.networks.networks.network.temp,
    canSave: state.networks.networks.network.canSave,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchData: () => networksGetNetworkDataObs(dispatch, ownProps),
    onSubmit: data => networksSaveNetworkDetailsThunk(dispatch, data, ownProps),
    onUpload: data => networksOnUploadNetworkImageThunk(dispatch, data),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NetworkDetails));
