import React from 'react';
import Styles from './SubHeader.module.scss';
import Search from 'shared/Search';
import DropdownMultiselect from 'shared/DropdownMultiselect';

const SubHeader = ({
    texts,
    isLoading,
    search,
    selectors,
    onSearchChange,
    onSearchClear,
    onDropdownMultiselectChange,
    onDropdownMultiselectDone,
    onDropdownMultiselectClear,
    onDropdownMultiselectAll,
    onConfirm,
    disabled,
    onSearch,
    dropdownPlaceholder,
    searchPlaceholder,
}) => {
    return (
        <div data-test="SubHeaderContainer" className={Styles.container}>
            <div className={Styles.filters}>
                <div className={Styles.col}>
                    <div className={Styles.dropdown}>
                        <DropdownMultiselect
                            data-test="SubHeaderDropdownType"
                            id="typeList"
                            namespace="type"
                            name="type"
                            data={selectors?.type?.list ? selectors?.type?.list : []}
                            selected={selectors?.type?.selected ? selectors?.type?.selected : []}
                            position="bottom"
                            theme="select"
                            variant="outlined"
                            size="sm"
                            placeholder={dropdownPlaceholder}
                            state={
                                selectors?.type?.selected && selectors?.type?.selected?.length > 0
                                    ? 'active'
                                    : null
                            }
                            onChange={onDropdownMultiselectChange}
                            onSelectAll={onDropdownMultiselectAll}
                            onClearAll={onDropdownMultiselectClear}
                            onDone={onDropdownMultiselectDone}
                            isLoading={isLoading}
                            disabled={isLoading}
                            maxWidth="15rem"
                            block
                            withSearch={false}
                        />
                    </div>
                </div>
            </div>
            <div className={Styles.search}>
                <Search
                    data-test="SubHeaderSearch"
                    autoFocus
                    name="search"
                    theme="primary"
                    variant="outlined"
                    size="md"
                    placeholder={searchPlaceholder}
                    onSearch={onSearch}
                    onClear={onSearchClear}
                    onChange={onSearchChange}
                    value={search}
                    onConfirm={onConfirm}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

export default SubHeader;
