import { appFileUpload } from 'api/app';
import { useEffect, useState } from 'react';
import { sendErrorToast } from 'services/toast';

/**
 *
 * @param outerCallback a function to be called after the file has been uploaded outside of the hook - usually when the file is uploaded into the parent component (outside of the hook)
 * @param innerCallback a function to be called after the file has been uploaded inside of the hook - usually when the file is uploaded into the hook
 * @returns
 */
export const useUploadApi = (outerCallback?: Function, innerCallback?: Function) => {
    const [uploadedData, doUpload] = useState<{
        content: any;
    }>(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploadError, setIsUploadError] = useState(false);
    const [filePreview, setFilePreview] = useState(null);
    const [fileType, setFileType] = useState(null);

    const handleOuterCallback = (result, uploadedData) => {
        outerCallback(result);
    };

    const handleInnerCallback = (result, uploadedData) => {
        if (uploadedData && !isUploading) {
            const payload = {
                name: uploadedData.content.name,
                icon: result.icon,
                image: result.image,
                uri: result.url,
                classes: [
                    'io.habit.payment.asset',
                    `io.habit.payment.asset.${fileType}`,
                    'io.habit.trackableassets.type.document',
                ],
                tags: [
                    'io.habit.payment.asset',
                    `io.habit.payment.asset.${fileType}`,
                    'io.habit.trackableassets.type.document',
                ],
            };
            innerCallback(payload);
        }
    };

    const clearUpload = () => {
        doUpload(null);
        setFilePreview(null);
        setFileType(null);
        setIsUploading(false);
        setIsUploadError(false);
    };

    useEffect(() => {
        const uploadFile = async () => {
            setIsUploadError(false);
            setIsUploading(true);

            try {
                /**
                 * @example const result = await appFileUpload(uploadedData);
                 * @returns {Promise<{url: string, filename: string}>}
                 */
                const result = await appFileUpload(uploadedData);
                if (result && result.url) {
                    doUpload(result.url);
                    setFilePreview(result.filename);

                    if (outerCallback) {
                        handleOuterCallback(result, uploadedData);
                    }

                    if (innerCallback) {
                        handleInnerCallback(result, uploadedData);
                    }
                } else {
                    setIsUploadError(true);
                    sendErrorToast();
                }
            } catch (error) {
                setIsUploadError(true);
                sendErrorToast();
                doUpload(null);
                setFilePreview(null);
            }

            setIsUploading(false);
        };

        uploadedData?.content && uploadFile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedData]);

    // cleanup
    useEffect(() => {
        return () => {
            clearUpload();
        };
    }, []);

    return {
        uploadedData,
        filePreview,
        isUploading,
        isUploadError,
        doUpload,
        clearUpload,
        fileType,
        setFileType,
    };
};
