import rootInitialState from 'redux/store/initialState';

const getTexts = lang => {
    const texts = require(`redux/store/languages/${lang}.json`);

    let initalstate = rootInitialState.texts;
    initalstate = {
        ...texts,
    };

    return initalstate;
};

export const initialState = {
    texts: rootInitialState.texts,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            // return state;
            return getTexts('en');
    }
};

export default reducer;
