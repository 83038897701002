import classnames from 'classnames';
import { getText } from 'localization';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import Loading from 'shared/Loading';
import Styles from './ButtonGroup.module.scss';

export class ButtonGroup extends React.PureComponent {
    static propTypes = {
        texts: PropTypes.object,
        onPausePlay: PropTypes.func,
        onDelete: PropTypes.func,
        onReset: PropTypes.func,
        data: PropTypes.object,
        paused: PropTypes.bool,
        disablePausePlay: PropTypes.bool,
        disableReset: PropTypes.bool,
        disableDelete: PropTypes.bool,
        isLoading: PropTypes.bool,
    };

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    render() {
        const {
            onPausePlay,
            onDelete,
            onReset,
            data,
            paused,
            disablePausePlay,
            disableReset,
            disableDelete,
            isLoading,
        } = this.props;

        if (disablePausePlay && disableReset && disableDelete) {
            return null;
        }

        return (
            <div data-test="ButtonGroup" className={Styles.buttonGroup}>
                {onPausePlay && (
                    <button
                        className={classnames(disablePausePlay ? Styles.disabled : null)}
                        data-test="ButtonGroupPausePlay"
                        onClick={!disablePausePlay && !isLoading ? onPausePlay : null}
                        data-id={data.id}
                    >
                        {isLoading && <Loading variant="xxs" />}

                        {!isLoading && paused && (
                            <i
                                data-test="ButtonGroupPlay"
                                className="icon-icon_play"
                                data-tip={getText('activate')}
                            ></i>
                        )}

                        {!isLoading && !paused && (
                            <i
                                data-test="ButtonGroupPause"
                                className={classnames(
                                    'icon-icon_pause',
                                    disablePausePlay ? Styles.disabled : null,
                                )}
                                data-tip={getText('suspend')}
                            ></i>
                        )}
                    </button>
                )}

                {onReset && (
                    <button
                        className={classnames(disableReset ? Styles.disabled : null)}
                        data-test="ButtonGroupReset"
                        onClick={!disableReset && !isLoading ? onReset : null}
                        data-id={data.id}
                        data-email={data.email}
                    >
                        {isLoading && <Loading variant="xxs" />}

                        {!isLoading && (
                            <i
                                className={classnames(
                                    'icon-icon_reset_pass',
                                    disableReset ? Styles.disabled : null,
                                )}
                                data-tip={getText('send_recover_email')}
                            ></i>
                        )}
                    </button>
                )}

                {onDelete && (
                    <button
                        className={classnames(disableDelete ? Styles.disabled : null)}
                        data-test="ButtonGroupDelete"
                        onClick={!isLoading ? onDelete : null}
                        data-id={data.id}
                    >
                        {isLoading && <Loading variant="xxs" />}

                        {!isLoading && (
                            <i
                                className={classnames(
                                    'icon-icon_delete',
                                    disableDelete ? Styles.disabled : null,
                                )}
                                data-tip={getText('delete')}
                            ></i>
                        )}
                    </button>
                )}
            </div>
        );
    }
}

export default ButtonGroup;
