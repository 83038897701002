import React from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './MapRaw.scss';

class MapRaw extends React.PureComponent {
    // TODO: Refactor

    _map = null;
    _markers = {};
    _markerGroup = null;
    _line = null;
    _initialZoom = 3;
    _fitBoundsMaxZoom = 10;
    _fitBounds = [];
    _fixViewPosition = [350, 0];
    _key = 'id';
    _address = 'full_address';
    _primaryColor = '#f9bb00';
    _secondaryColor = '#2d426b';
    _startColor = '#b8e986';
    _endColor = '#f54b5e';

    state = {
        init: false,
    };

    static propTypes = {
        type: PropTypes.oneOf(['single', 'multiple']),
        center: PropTypes.array,
        data: PropTypes.array,
        selected: PropTypes.object,
        onSelect: PropTypes.func,
        withPadding: PropTypes.bool,
    };

    static defaultProps = {
        type: 'single',
        center: [38.7479075, -9.1622213],
    };

    componentDidMount() {
        this.initMap();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.init && JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.updateMap();
        }

        if (
            (!prevProps.selected && this.props.selected) ||
            (prevProps.selected &&
                this.props.selected &&
                prevProps.selected[this._key] !== this.props.selected[this._key])
        ) {
            const marker = this.props.data.filter(
                marker => marker[this._key] === this.props.selected[this._key],
            );
            if (marker[0]) {
                this.changeCircleMap(marker[0]);
            }
        }
    }

    componentWillUnmount() {
        this.clearMap();
    }

    async updateMap() {
        await this.clearMap();
        await this.initMap();
    }

    initMap() {
        const { data, selected, type, center, withPadding } = this.props;
        const light_1 = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png';

        if (!this._map) {
            this._map = L.map('mapRaw', {
                center: center,
                zoom: this._initialZoom,
                zoomControl: true,
                preferCanvas: true,
                layers: [L.tileLayer(light_1)],
            });

            this._map.zoomControl.setPosition('topright');
        }

        switch (type) {
            case 'multiple':
                if (data && data.length > 0) {
                    this._markerGroup = L.layerGroup().addTo(this._map);
                    for (let i = 0; i < data.length; i++) {
                        if (data[i] && Object.keys(data[i]).length > 0) {
                            this.addCircleMarker(data[i], i === 0, i === data.length - 1);
                            this._fitBounds.push([data[i].latitude, data[i].longitude]);
                        }
                    }

                    this.addMarkersPath();

                    setTimeout(() => {
                        this.bringToFrontInitAndEnd();
                        const bounds = new L.LatLngBounds(this._fitBounds);
                        const options = withPadding
                            ? {
                                  paddingTopLeft: this._fixViewPosition,
                              }
                            : {};
                        this._map.fitBounds(bounds, options);
                    }, 0);
                }
                break;

            default:
                if (selected && Object.keys(selected).length > 0) {
                    this.addMarker(selected);
                }
                break;
        }

        this.setState((state, props) => ({
            init: true,
        }));
    }

    bringToFrontInitAndEnd() {
        Object.keys(this._markers).forEach(item => {
            if (
                this._markers[item].options.className === 'start' ||
                this._markers[item].options.className === 'end'
            ) {
                this._markers[item].options.color =
                    this._markers[item].options.className === 'start'
                        ? this._startColor
                        : this._endColor;
                this._markers[item].options.fillColor = '#ffffff';
                this._markers[item].bringToFront();
            }
        });
    }

    clearMap() {
        if (this._markerGroup) {
            this._markerGroup.remove();
            this._markerGroup.removeFrom(this._map);
            this._map.removeLayer(this._markerGroup);
            this._markerGroup = null;
        }

        if (this._line) {
            this._line.remove();
            this._line.removeFrom(this._map);
            this._map.removeLayer(this._line);
            this._line = null;
        }

        if (this._map) {
            this._map.off();
            this._map.remove();
            this._map = null;
            this._fitBounds = [];
            this._markers = {};
        }
    }

    changeCircleMap(data) {
        if (data && data.latitude && data.longitude) {
            Object.keys(this._markers).forEach(item => {
                this._markers[item].options.color = this._secondaryColor;
                this._markers[item].options.fillColor = '#ffffff';
                this._markers[item].options.fillOpacity = 0.2;
                this._markers[item].closeTooltip();
            });

            this._markers[this.props.selected[this._key]].options.color = this._primaryColor;
            this._markers[this.props.selected[this._key]].options.fillOpacity = 1;
            this._markers[this.props.selected[this._key]].bringToFront();

            this._map.setView(new L.latLng(data.latitude, data.longitude));
            this.changeMarker(data);
        }
    }

    addCircleMarker(data, start, end) {
        const color = start ? this._startColor : end ? this._endColor : this._secondaryColor;

        this._markers[data[this._key]] = L.circleMarker(
            [data[0] ? data[0] : data.latitude, data[1] ? data[1] : data.longitude],
            {
                radius: 5,
                weight: 4,
                color: color,
                fillColor: '#ffffff',
                className: start ? 'start' : end ? 'end' : 'middle',
            },
        )
            .bindTooltip(
                data[this._address] && data[this._address].trim() !== ''
                    ? data[this._address]
                    : null,
                { direction: 'top', className: 'mapRawTooltip' },
            )
            .on('click', e => this.onClickMarker(e, data))
            .addTo(this._markerGroup);
    }

    addMarkersPath() {
        this._line = L.layerGroup().addTo(this._map);
        L.polyline(this._fitBounds, { color: this._primaryColor, opacity: 0.5 }).addTo(this._line);
    }

    changeMarker(data) {
        if (!this._markers) {
            this.addCircleMarker(data);
        }

        this.bringToFrontInitAndEnd();
    }

    onClickMarker(e, data) {
        e.target.openTooltip();
        this.props.onSelect([data]);
    }

    render() {
        return <div data-test="MapRawContainer" id="mapRaw"></div>;
    }
}

export default MapRaw;
