import classnames from 'classnames';
import { getText } from 'localization';
import PropTypes from 'prop-types';
import React from 'react';
import Avatar from 'shared/Avatar';
import Button from 'shared/Button';
import ButtonGroup from 'shared/ButtonGroup';
import Dropdown from 'shared/Dropdown';
import Popup from 'shared/Popup';
import { MEMBER_PERMISSIONS_LIST } from 'utils/constants';
import { formatDate, getElementPosition } from 'utils/functions';
import Styles from './Member.module.scss';

export class Member extends React.PureComponent {
    state = {
        selectRolePopup: {
            active: false,
            element: {},
            selectedId: null,
        },
        permissions: {
            selected: null,
            list: MEMBER_PERMISSIONS_LIST,
        },
    };

    static propTypes = {
        texts: PropTypes.object,
        index: PropTypes.number,
        data: PropTypes.object,
        roles: PropTypes.arrayOf(PropTypes.object),
        edit: PropTypes.bool,
        add: PropTypes.bool,
        onPausePlay: PropTypes.func,
        onDelete: PropTypes.func,
        onChangePermissions: PropTypes.func,
        isLoading: PropTypes.bool,
        withPermissions: PropTypes.bool,
    };

    componentDidMount() {
        if (this.props.data && this.props.data.hasOwnProperty('read_only')) {
            this.setState(state => ({
                ...state,
                permissions: {
                    ...state.permissions,
                    selected: this.props.data.read_only.toString(),
                },
            }));
        }
    }

    handleSelectRolePopup = e => {
        e.preventDefault();
        this.setState(
            {
                selectRolePopup: {
                    active: !this.state.selectRolePopup?.active,
                    element: getElementPosition(e.currentTarget),
                },
            },
            () => {
                // this.props.addStep(e);
            },
        );
    };

    handleOnPausePlay = () => {
        const { data, onPausePlay } = this.props;
        const payload = {
            memberId: data.id,
            action: data.state === 'active' ? 'pause' : 'resume',
        };

        onPausePlay(payload);
    };

    handleOnChangeDropdown = e => {
        const payload = {
            email: this.props.data.user.email,
            permissions: e.id,
        };
        this.props.onChangePermissions(payload);
    };

    render() {
        const { texts, index, data, edit, add, onDelete, isLoading } = this.props;
        const { selectRolePopup, permissions } = this.state;

        return (
            <li
                data-test="MemberItem"
                className={classnames(
                    Styles.member,
                    add ? Styles.add : null,
                    data.state === 'active' ? Styles?.active : null,
                    data.state === 'unverified' ? Styles.pending : null,
                    data.state === 'inactive' ? Styles.paused : null,
                )}
            >
                <div className={classnames(Styles.col)}>
                    {index === -1 && (
                        <Avatar
                            random={false}
                            color="#2d426b"
                            text={data.user.name}
                            theme="light"
                        />
                    )}

                    {index !== -1 && (
                        <Avatar
                            random={data.state !== 'active' ? false : true}
                            color={
                                data.state === 'unverified'
                                    ? '#F9BB00'
                                    : data.state === 'inactive'
                                    ? '#F54B5E'
                                    : null
                            }
                            text={data.user.name}
                            theme={data.state !== 'active' ? 'light' : 'dark'}
                        />
                    )}
                </div>

                {data.state === 'active' && (
                    <>
                        <div
                            data-test="MemberItemName"
                            className={classnames(Styles.col, Styles.name)}
                        >
                            {data.user.name}
                        </div>

                        <div
                            data-test="MemberItemRole"
                            className={classnames(Styles.col, Styles.role)}
                        >
                            <Button
                                block
                                variant="outlined"
                                theme="ternary"
                                value={this.getValueById()}
                                onClick={this.handleSelectRolePopup}
                                icon={selectRolePopup?.active ? 'icon_arrow_up' : 'icon_arrow_down'}
                                disabled
                            />

                            <Popup
                                list
                                position="bottom"
                                id={`memberRolePopup-${data.id}`}
                                active={selectRolePopup?.active}
                                element={selectRolePopup.element}
                                onClickSetting={this.handleClickUserSetting}
                                closePopup={this.handleClosePopups}
                                content={this.getSelectContent()}
                            />
                        </div>
                    </>
                )}

                <div className={classnames(Styles.col, Styles.email)}>
                    {data.state === 'unverified' && (
                        <span>
                            {getText('project_members_waiting_for')} {data.user.email}{' '}
                            {getText('project_members_waiting_acceptance_since')}{' '}
                            <time>{formatDate(data.updated, undefined, undefined, false)}</time>
                        </span>
                    )}

                    {data.state === 'inactive' && (
                        <span>
                            {getText('project_members_member')} {data.user.email}{' '}
                            {getText('project_members_is_suspended_since')}{' '}
                            <time>{formatDate(data.updated, undefined, undefined, false)}</time>
                        </span>
                    )}

                    {data.state === 'active' && (
                        <span data-test="MemberItemEmail">{data.user.email}</span>
                    )}
                </div>

                {data.state === 'active' && (
                    <>
                        <div className={classnames(Styles.col)}>
                            {/* {getText('end_users_table_title_last_login')}: <br /> <time>{formatDate(data.user.active_ts)}</time> */}
                        </div>

                        <div className={classnames(Styles.col)}>
                            {getText('end_users_table_title_created')}: <br />{' '}
                            <time>{formatDate(data.created, undefined, undefined, false)}</time>
                        </div>
                    </>
                )}

                <div className={classnames(Styles.col, Styles.actions)}>
                    {index > -1 && edit && (
                        <ButtonGroup
                            texts={texts}
                            data={data}
                            onPausePlay={this.handleOnPausePlay}
                            onDelete={onDelete}
                            paused={data.state === 'inactive'}
                            disablePausePlay={data.state === 'unverified'}
                            isLoading={isLoading}
                        />
                    )}

                    {index > -1 && !edit && data.hasOwnProperty('read_only') && (
                        <Dropdown
                            id="permissions"
                            data={permissions.list}
                            selected={permissions.selected}
                            position="bottom"
                            onChange={this.handleOnChangeDropdown}
                            name="permissions"
                            theme="select"
                            variant="outlined"
                            state={permissions.selected ? 'active' : null}
                            block
                            hasEmptyState
                            placeholder={getText('common_select_label')}
                            size="sm"
                        />
                    )}
                </div>
            </li>
        );
    }

    getSelectContent() {
        const { roles } = this.props;

        return (
            <>
                {roles.map(role => (
                    <li key={role.id}>{role.text}</li>
                ))}
            </>
        );
    }

    getValueById() {
        const { data, roles } = this.props;
        const role = roles.filter(role => role.value === data.role);

        return role[0].text;
    }
}

export default Member;
