import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import createRootReducer from 'redux/reducers';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

const history = createBrowserHistory();

export default function configureStore(initialState) {
    const middleware = [thunk, routerMiddleware(history)];
    const store = createStore(
        createRootReducer(history),
        initialState,
        applyMiddleware(...middleware),
    );

    return { store, history };
}
