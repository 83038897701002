import { getText } from 'localization';

export const USER_ALREADY_EXISTS = 1211;
export const USER_NOT_FOUND = 1101;
export const USER_UNVERIFIED = 4130;
export const INVALID_CREDENTIALS = 1601;
export const INVALID_USERS = 1504;
export const INVALID_SMS = 9404;
export const TOKEN_EXPIRED = 1042;
export const SHOULD_NOT_UNLOG_WHEN_FORBIDDEN = 1061;
export const MAX_SIZE = 6060;
export const UNAUTHORIZED_APPLICATION = 2420;
export const INVALID_EMAIL = 1016;
export const INVALID_ADDRESS = 1017;
export const INVALID_PHONE_NUMBER = 1018;
export const MANDATORY_FIELDS = 1200;
export const WRONG_FORMAT = 1202;
export const REGISTER_INSURER_PENDING = 5100;
export const REGISTER_INSURER_REJECTED = 5101;
export const REGISTER_DISTRIBUTOR_PENDING = 5102;
export const REGISTER_DISTRIBUTOR_REJECTED = 5103;
export const USER_NOT_FOUND_2 = 4404;
export const SELLER_INVITE_ALREADY_EXISTS = 9906;
export const SELLER_WEBVIEW_NOT_AVAILABLE = 9907;

export const getErrorMsgByCode = code => {
    switch (code) {
        case INVALID_CREDENTIALS:
            return getText('errors_invalid_credentials');
        case INVALID_USERS:
            return getText('errors_invalid_users');
        case USER_ALREADY_EXISTS:
            return getText('errors_user_already_exists');
        case USER_UNVERIFIED:
            return getText('errors_user_unverified');
        case MAX_SIZE:
            return 'Maximum size allowed: max-size:500x500px.';
        case REGISTER_INSURER_PENDING:
        case REGISTER_DISTRIBUTOR_PENDING:
            return getText('errors_register_application_pending');
        case REGISTER_INSURER_REJECTED:
        case REGISTER_DISTRIBUTOR_REJECTED:
            return (
                getText('errors_register_rejected_1') +
                '<a href="mailto:support@habit.io" rel="noopener noreferrer" target="_blank">' +
                getText('errors_register_rejected_2') +
                '</a>.'
            );
        case USER_NOT_FOUND_2:
            return getText('errors_user_not_found_2');
        case SELLER_INVITE_ALREADY_EXISTS:
            return getText('errors_seller_invite_already_exists');
        case SELLER_WEBVIEW_NOT_AVAILABLE:
            return getText('errors_seller_webview_not_available');

        default:
            return getText('errors_default');
    }
};
