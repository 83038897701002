import { connect } from 'react-redux';
import { checkSessionAction } from 'redux/domains/app/actions';
import App from 'routes/Router';

const mapStateToProps = state => ({
    name: state.app.name,
    isLoading: state.app.loading,
    isAuthenticated: state.app.isAuthenticated,
});

const mapDispatchToProps = dispatch => ({
    checkSession: () => dispatch(checkSessionAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
