import classnames from 'classnames';
import { getText } from 'localization';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { getUserInfo } from 'services/localStorage';
import { userIsDistributor, userIsInsurer } from 'services/roles';
import Button from 'shared/Button';
import Dropdown from 'shared/Dropdown';
import DropdownMultiselect from 'shared/DropdownMultiselect';
import ErrorBoundary from 'shared/ErrorBoundary';
import Search from 'shared/Search';
import { ADMIN_DISTRIBUTOR_STATUS_FILTERS, DISTRIBUTOR_STATUS_FILTERS } from 'utils/constants';
import InputDatePicker from './campaigns/InputDatePicker/index';
import Styles from './SectionHeader.module.scss';

export class SectionHeader extends React.PureComponent {
    state = {
        search: '',
    };

    static propTypes = {
        title: PropTypes.string,
        onClick: PropTypes.func,
        action: PropTypes.oneOf([
            'none',
            'add',
            'edit',
            'close',
            'viewAll',
            'search',
            'compatibleDevices',
            'usecases',
            'credentials',
            'insurance',
            'network',
            'distributorsInsuranceProducts',
            'distributorsGeneric',
            'commissionsDistributor',
            'addGeneric',
            'default',
            'sellers',
            'invites',
            'admin_distributors',
            'admin_providers',
            'distributorsdistributors',
            'distributorsdashboard',
            'distributor-documents',
            'provider-documents',
            'campaigns',
        ]),
        mode: PropTypes.string,
        active: PropTypes.bool,
        onSearch: PropTypes.func,
        tooltipText: PropTypes.string,
        data: PropTypes.object,
        onSelect: PropTypes.func,
        onClear: PropTypes.func,
        onToggleAdvancedSearch: PropTypes.func,
        isAdvancedSearch: PropTypes.bool,
        filterDisabled: PropTypes.bool,
        searchPlaceholder: PropTypes.string,
        classes: PropTypes.string,
        theme: PropTypes.string,
        isLoading: PropTypes.bool,
        handleOnConfirm: PropTypes.func,
        tempDeactivated: PropTypes.bool,
        selected: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        handleOnSelectAll: PropTypes.func,
        handleOnDone: PropTypes.func,
        handleOnChangeSearch: PropTypes.func,
        disabled: PropTypes.bool,
        variant: PropTypes.string,
        handleSelect: PropTypes.func,
        dropdownData: PropTypes.array,
        onCancel: PropTypes.func,
        options: PropTypes.object,
    };

    static defaultProps = {
        tempDeactivated: false,
        selected: [],
        variant: '',
        onCancel: () => {},
    };

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    handleOnChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            {
                [name]: value,
            },
            () => {
                if (this.props.action !== 'insurance') {
                    this.props.onSearch(this.state.search);
                } else {
                    if (value === '') {
                        this.props.onClear();
                    }
                }
            },
        );
    };

    handleOnSearch = () => {
        this.props.onSearch(this.state.search);
    };

    handleOnSubmit = e => {
        if (e) {
            e.preventDefault();
            this.handleOnSearch();
        }
    };

    handleOnClear = () => {
        this.setState(
            {
                search: '',
            },
            () => {
                this.props.onClear();
            },
        );
    };

    getTitle() {
        if (this.props.action === 'distributor-documents')
            return <div className={Styles.distributorDocumentsContainer}>{this.props.title}</div>;
        if (this.props.action === 'provider-documents')
            return <div className={Styles.providerDocumentsContainer}>{this.props.title}</div>;
        return <h3 data-test="SectionHeaderTitle">{this.props.title}</h3>;
    }

    render() {
        const {
            onClick,
            action,
            mode,
            active,
            onSearch,
            tooltipText,
            data,
            onSelect,
            filterDisabled,
            onToggleAdvancedSearch,
            isAdvancedSearch,
            searchPlaceholder,
            classes,
            theme,
            isLoading,
            handleOnConfirm,
            tempDeactivated,
            selected,
            variant,
            handleSelect,
            dropdownData,
            disabled,
            onCancel,
        } = this.props;

        return (
            <ErrorBoundary>
                <div
                    data-test="SectionHeaderContainer"
                    className={classnames(
                        Styles.sectionHeader,
                        Styles[action],
                        Styles[theme],
                        classes,
                    )}
                >
                    {this.getTitle()}
                    {onClick &&
                        (action === 'sellers' || action === 'distributorsdistributors') &&
                        !tempDeactivated && (
                            /* TODO: Cleanup action === 'distributorsdistributors' && Styles.noGrid  */
                            <div
                                className={classnames(
                                    Styles.sellersContainer,
                                    action === 'distributorsdistributors' && Styles.noGrid,
                                    Styles[variant],
                                )}
                            >
                                {variant === 'vendors' && (
                                    <Search
                                        placeholder={getText('distributor_vendors_search_by_email')}
                                        theme={'ternary'}
                                        variant="outlined"
                                        size="sm"
                                        onSearch={onSearch}
                                        onConfirm={() =>
                                            this.props.handleOnConfirm &&
                                            this.props.handleOnConfirm(this.state.search)
                                        }
                                        onChange={e => {
                                            this.props.handleOnChangeSearch &&
                                                this.props.handleOnChangeSearch(e, 'searchEmail');
                                        }}
                                        disabled={this.props.disabled || isLoading}
                                        value={this.props.searchEmail && this.props.searchEmail}
                                    ></Search>
                                )}

                                {action !== 'distributorsdistributors' && (
                                    <DropdownMultiselect
                                        id="filters"
                                        namespace="filters"
                                        name="filters"
                                        label="filters"
                                        theme="select"
                                        variant="outlined"
                                        placeholder={getText('dropdown_select')}
                                        data={DISTRIBUTOR_STATUS_FILTERS}
                                        selected={selected}
                                        position="bottom"
                                        block={true}
                                        isLoading={isLoading}
                                        title="Filters"
                                        double={false}
                                        onChange={e =>
                                            action === 'distributorsdistributors'
                                                ? () => {}
                                                : this.props.handleOnChange(e, 'filters')
                                        }
                                        onSelectAll={e =>
                                            action === 'distributorsdistributors'
                                                ? () => {}
                                                : this.props.handleOnSelectAll(e, 'allFilters')
                                        }
                                        onClearAll={e =>
                                            action === 'distributorsdistributors'
                                                ? () => {}
                                                : this.props.handleOnClear(e)
                                        }
                                        onDone={e =>
                                            action === 'distributorsdistributors'
                                                ? () => {}
                                                : this.props.handleOnDone(e)
                                        }
                                        onConfirm={e =>
                                            action === 'distributorsdistributors'
                                                ? () => {}
                                                : this.props.handleOnConfirm(e)
                                        }
                                        withSearch={true}
                                        onSearch={() => {}}
                                        state={'active'}
                                        hasDoneButton={false}
                                        data-test="SectionHeaderSearch"
                                        autoFocus
                                        size="sm"
                                        value={this.state.search}
                                        disabled={this.props.disabled || isLoading}
                                    />
                                )}

                                {action !== 'distributorsdistributors' && (
                                    <Search
                                        placeholder={getText('distributor_vendors_search_by_name')}
                                        theme={'ternary'}
                                        variant="outlined"
                                        size="sm"
                                        onSearch={() =>
                                            action === 'distributorsdistributors'
                                                ? () => {}
                                                : onSearch
                                        }
                                        onConfirm={() =>
                                            action === 'distributorsdistributors'
                                                ? () => {}
                                                : this.props.handleOnConfirm &&
                                                  this.props.handleOnConfirm(this.state.search)
                                        }
                                        onChange={e => {
                                            this.props.handleOnChangeSearch &&
                                                this.props.handleOnChangeSearch(e, 'search');
                                        }}
                                        disabled={this.props.disabled || isLoading}
                                        value={this.props.search && this.props.search}
                                    ></Search>
                                )}

                                {this.props.disabled ? (
                                    /* Temporarily, I just want to disable the input and filters and let the user create new invites */
                                    action === 'distributorsdistributors' ? (
                                        <i
                                            addactionicon="true"
                                            data-test="SectionHeaderActionIcon"
                                            className="icon-icon_add_2"
                                            onClick={onClick}
                                            data-tip={tooltipText ? tooltipText : null}
                                        ></i>
                                    ) : null
                                ) : (
                                    <i
                                        addactionicon="true"
                                        data-test="SectionHeaderActionIcon"
                                        className="icon-icon_add_2"
                                        onClick={onClick}
                                        data-tip={tooltipText ? tooltipText : null}
                                    ></i>
                                )}
                            </div>
                        )}

                    {action === 'campaigns' &&
                        !tempDeactivated &&
                        this.props.options.campaigns?.length > 0 && (
                            <div className={classnames([Styles.inputDatePickerContainer])}>
                                <InputDatePicker
                                    dateFilter={this.props.options.dateFilter}
                                    disabled={this.props.disabled || isLoading}
                                    onChange={e => {
                                        this.props.options.onChangeDate(e);
                                    }}
                                    onResetDates={() => this.props.options.onResetDates()}
                                    inputDateIconPosition="before"
                                />
                            </div>
                        )}
                    {action === 'campaigns' &&
                        !tempDeactivated &&
                        this.props.options.campaigns?.length > 0 && (
                            <div
                                className={classnames(
                                    Styles.campaignsContainer,
                                    Styles[variant],
                                    userIsInsurer()
                                        ? Styles.insurer
                                        : userIsDistributor()
                                        ? Styles.distributor
                                        : '',
                                )}
                            >
                                <Search
                                    placeholder={getText('campaign_header_placeholder_search')}
                                    theme={'ternary'}
                                    variant="outlined"
                                    size="md"
                                    onSearch={() => onSearch}
                                    onConfirm={() =>
                                        this.props.handleOnConfirm &&
                                        this.props.handleOnConfirm(this.state.search)
                                    }
                                    onChange={e => {
                                        this.props.handleOnChangeSearch &&
                                            this.props.handleOnChangeSearch(e, 'search');
                                    }}
                                    disabled={this.props.disabled || isLoading}
                                    value={this.props.search && this.props.search}
                                ></Search>
                                {this.props.options.shouldShowButtons &&
                                    (userIsInsurer() ? (
                                        <>
                                            {Boolean(getUserInfo().distributor_id) && (
                                                <Button
                                                    className={classnames([
                                                        Styles.addActionButton,
                                                        Styles.blue,
                                                    ])}
                                                    onClick={() => {
                                                        this.props.options.setOpenEmitVouchersModal(
                                                            true,
                                                        );
                                                    }}
                                                    value={getText('emit_vouchers_campaigns_label')}
                                                />
                                            )}
                                            <Button
                                                className={classnames(Styles.addActionButton)}
                                                onClick={() => {
                                                    this.props.options.setOpenCreateCampaignModal(
                                                        true,
                                                    );
                                                }}
                                                value={getText('create_campaigns_label')}
                                            />
                                        </>
                                    ) : (
                                        userIsDistributor() &&
                                        Boolean(getUserInfo().distributor_id) && (
                                            <Button
                                                className={classnames([
                                                    Styles.addActionButton,
                                                    Styles.blue,
                                                ])}
                                                onClick={() => {
                                                    this.props.options.setOpenEmitVouchersModal(
                                                        true,
                                                    );
                                                }}
                                                value={getText('emit_vouchers_campaigns_label')}
                                            />
                                        )
                                    ))}
                                {!this.props.options.shouldShowButtons && (
                                    <>
                                        <i
                                            className={classnames(
                                                'icon-icon_delete_3',
                                                Styles.closeIcon,
                                            )}
                                            onClick={onCancel}
                                        ></i>
                                    </>
                                )}
                            </div>
                        )}

                    {action === 'distributorsdashboard' && (
                        <div className={Styles.distributorsdashboardcontainer}>
                            {!disabled && (
                                <Dropdown
                                    theme="select"
                                    variant="outlined"
                                    placeholder={getText('distributors_outlets_selectlabel')}
                                    value={selected}
                                    valuePossibleCannotBePartOfDataList={true}
                                    disabled={!!isLoading}
                                    id="products"
                                    namespace="products"
                                    isAlphabeticSort={false}
                                    data={dropdownData}
                                    selected={selected}
                                    name="products"
                                    block={true}
                                    isLoading={isLoading}
                                    title=""
                                    double={false}
                                    onChange={handleSelect}
                                    withSearch={true}
                                    position="bottom-left"
                                />
                            )}
                        </div>
                    )}

                    {onClick && action === 'admin_distributors' && !tempDeactivated && (
                        <div className={classnames(Styles.sellersContainer, Styles[variant])}>
                            <Search
                                placeholder={getText('search_by_distributor_registered_entity')}
                                theme={'ternary'}
                                variant="outlined"
                                size="sm"
                                onSearch={onSearch}
                                onConfirm={() =>
                                    this.props.handleOnConfirm &&
                                    this.props.handleOnConfirm(this.state.search)
                                }
                                onChange={e => {
                                    this.props.handleOnChangeSearch &&
                                        this.props.handleOnChangeSearch(e, 'search');
                                }}
                                disabled={this.props.disabled || isLoading}
                                value={this.props.search && this.props.search}
                            ></Search>
                            <DropdownMultiselect
                                id="filters"
                                namespace="filters"
                                name="filters"
                                label="filters"
                                theme="select"
                                variant="outlined"
                                placeholder={getText('dropdown_select')}
                                data={ADMIN_DISTRIBUTOR_STATUS_FILTERS}
                                selected={selected}
                                position="bottom"
                                block={true}
                                isLoading={isLoading}
                                title="Filters"
                                double={false}
                                onChange={e => this.props.handleOnChange(e, 'filters')}
                                onSelectAll={e => this.props.handleOnSelectAll(e, 'allFilters')}
                                onClearAll={e => this.props.handleOnClear(e)}
                                onDone={e => this.props.handleOnDone(e)}
                                onConfirm={e => this.props.handleOnConfirm(e)}
                                withSearch={false}
                                onSearch={() => {}}
                                state={'active'}
                                hasDoneButton={false}
                                data-test="SectionHeaderSearch"
                                autoFocus
                                size="sm"
                                value={this.state.search}
                                disabled={this.props.disabled || isLoading}
                            />
                            {/* <Search
                                placeholder={getText('admin_distributor_search_by_email')}
                                theme={'ternary'}
                                variant="outlined"
                                size="sm"
                                onSearch={onSearch}
                                onConfirm={() =>
                                    this.props.handleOnConfirm &&
                                    this.props.handleOnConfirm(this.state.search)
                                }
                                onChange={e => {
                                    this.props.handleOnChangeSearch &&
                                        this.props.handleOnChangeSearch(e, 'searchEmail');
                                }}
                                disabled={this.props.disabled || isLoading}
                                value={this.props.searchEmail && this.props.searchEmail}
                            ></Search> */}
                        </div>
                    )}

                    {onClick && action === 'admin_providers' && !tempDeactivated && (
                        <div className={classnames(Styles.sellersContainer, Styles[variant])}>
                            <Search
                                placeholder={getText('search_by_distributor_registered_entity')}
                                theme={'ternary'}
                                variant="outlined"
                                size="sm"
                                onSearch={onSearch}
                                onConfirm={() =>
                                    this.props.handleOnConfirm &&
                                    this.props.handleOnConfirm(this.state.search)
                                }
                                onChange={e => {
                                    this.props.handleOnChangeSearch &&
                                        this.props.handleOnChangeSearch(e, 'search');
                                }}
                                disabled={this.props.disabled || isLoading}
                                value={this.props.search && this.props.search}
                            ></Search>
                            <DropdownMultiselect
                                id="filters"
                                namespace="filters"
                                name="filters"
                                label="filters"
                                theme="select"
                                variant="outlined"
                                placeholder={getText('dropdown_select')}
                                data={ADMIN_DISTRIBUTOR_STATUS_FILTERS}
                                selected={selected}
                                position="bottom"
                                block={true}
                                isLoading={isLoading}
                                title="Filters"
                                double={false}
                                onChange={e => this.props.handleOnChange(e, 'filters')}
                                onSelectAll={e => this.props.handleOnSelectAll(e, 'allFilters')}
                                onClearAll={e => this.props.handleOnClear(e)}
                                onDone={e => this.props.handleOnDone(e)}
                                onConfirm={e => this.props.handleOnConfirm(e)}
                                withSearch={false}
                                onSearch={() => {}}
                                state={'active'}
                                hasDoneButton={false}
                                data-test="SectionHeaderSearch"
                                autoFocus
                                size="sm"
                                value={this.state.search}
                                disabled={this.props.disabled || isLoading}
                            />
                            {/* <Search
                                placeholder={getText('admin_distributor_search_by_email')}
                                theme={'ternary'}
                                variant="outlined"
                                size="sm"
                                onSearch={onSearch}
                                onConfirm={() =>
                                    this.props.handleOnConfirm &&
                                    this.props.handleOnConfirm(this.state.search)
                                }
                                onChange={e => {
                                    this.props.handleOnChangeSearch &&
                                        this.props.handleOnChangeSearch(e, 'searchEmail');
                                }}
                                disabled={this.props.disabled || isLoading}
                                value={this.props.searchEmail && this.props.searchEmail}
                            ></Search> */}
                        </div>
                    )}

                    {onClick && action === 'invites' && (
                        <div className={classnames(Styles.invitesContainer)}>
                            <Search
                                placeholder={getText('search_with_dots')}
                                theme={'ternary'}
                                variant="outlined"
                                size="sm"
                                onSearch={onSearch}
                                onConfirm={() =>
                                    this.props.handleOnConfirm &&
                                    this.props.handleOnConfirm(this.state.search)
                                }
                                onChange={e => {
                                    this.props.handleOnChangeSearch &&
                                        this.props.handleOnChangeSearch(e, 'search');
                                }}
                                disabled={this.props.disabled || isLoading}
                                value={this.props.search && this.props.search}
                            ></Search>
                        </div>
                    )}

                    {onClick && action === 'add' && (
                        <i
                            data-test="SectionHeaderActionIcon"
                            className="icon-icon_add_2"
                            onClick={onClick}
                            data-tip={tooltipText ? tooltipText : null}
                        ></i>
                    )}

                    {onClick && action === 'edit' && (
                        <i
                            data-test="SectionHeaderActionIcon"
                            className={classnames(
                                'icon-icon_edit',
                                mode === 'edit' ? Styles?.active : null,
                            )}
                            onClick={onClick}
                            data-tip={tooltipText ? tooltipText : null}
                        ></i>
                    )}
                    {onClick && (action === 'close' || action === 'network') && (
                        <i
                            data-test="SectionHeaderActionIcon"
                            className="icon-icon_delete_2"
                            onClick={onClick}
                        ></i>
                    )}
                    {onClick && action === 'viewAll' && (
                        <Button
                            variant="filled"
                            theme="blue"
                            value={active ? getText('hide_all') : getText('view_all')}
                            onClick={onClick}
                            icon={active ? 'icon_password_off' : 'icon_password_on'}
                        />
                    )}
                    {action === 'search' && onSearch && (
                        <>
                            <div className={Styles.actionsArea}>
                                <Search
                                    data-test="SectionHeaderSearch"
                                    autoFocus
                                    name="search"
                                    theme="primary"
                                    variant="outlined"
                                    size="md"
                                    placeholder={
                                        getText('search_with_dots')
                                            ? getText('search_with_dots')
                                            : searchPlaceholder
                                            ? searchPlaceholder
                                            : ''
                                    }
                                    onSearch={this.handleOnSubmit}
                                    onClear={this.handleOnClear}
                                    onChange={this.handleOnChange}
                                    onConfirm={handleOnConfirm}
                                    value={this.state.search}
                                />
                            </div>

                            {onClick && (
                                <i
                                    className={classnames('icon-icon_add_2', Styles.addAction)}
                                    onClick={onClick}
                                    data-tip={tooltipText ? tooltipText : null}
                                ></i>
                            )}
                        </>
                    )}
                    {action === 'compatibleDevices' && onSearch && (
                        <div className={Styles.actionsArea}>
                            <Search
                                popupId="sectionHeaderSearchPopup"
                                data-test="SectionHeaderSearch"
                                autoFocus
                                withFilter
                                filterDisabled={filterDisabled}
                                filters={data}
                                name="search"
                                theme="primary"
                                variant="outlined"
                                size="sm"
                                placeholder={getText('search_device')}
                                onSearch={this.handleOnSubmit}
                                onClear={this.handleOnClear}
                                onChange={this.handleOnChange}
                                onSelect={onSelect}
                                value={this.state.search}
                            />
                        </div>
                    )}
                    {action === 'usecases' && onSearch && (
                        <div className={Styles.actionsArea}>
                            <Search
                                data-test="SectionHeaderSearch"
                                autoFocus
                                name="search"
                                theme="primary"
                                variant="outlined"
                                size="md"
                                placeholder={getText('search_with_dots')}
                                onSearch={this.handleOnSubmit}
                                onClear={this.handleOnClear}
                                onChange={this.handleOnChange}
                                value={this.state.search}
                            />
                        </div>
                    )}
                    {action === 'insurance' && (
                        <div className={Styles.actionsArea}>
                            <Button
                                theme="secondary"
                                variant="outlined"
                                state={isAdvancedSearch ? 'active' : 'dark'}
                                icon={isAdvancedSearch ? 'icon_arrow_up' : 'icon_arrow_down'}
                                size="lg"
                                value={getText('shared_section_header_advanced_search_label')}
                                onClick={onToggleAdvancedSearch}
                                // disabled={!onToggleAdvancedSearch}
                            />

                            <form onSubmit={this.handleOnSubmit}>
                                <Search
                                    data-test="SectionHeaderSearch"
                                    // autoFocus
                                    name="search"
                                    theme="primary"
                                    state={this.state.search !== '' ? 'active' : 'empty'}
                                    variant="outlined"
                                    size="md"
                                    placeholder={
                                        searchPlaceholder
                                            ? searchPlaceholder
                                            : getText('search_with_dots')
                                    }
                                    onSearch={this.handleOnSearch}
                                    onClear={this.handleOnClear}
                                    onChange={this.handleOnChange}
                                    value={this.state.search}
                                />
                            </form>
                        </div>
                    )}
                    {onClick &&
                        (action === 'distributorsInsuranceProducts' || action === 'addGeneric') && (
                            <div className={Styles.actionsArea}>
                                <i
                                    data-test="SectionHeaderActionIcon"
                                    className="icon-icon_add_2"
                                    onClick={onClick}
                                    data-tip={tooltipText ? tooltipText : null}
                                ></i>
                            </div>
                        )}
                    {action === 'commissionsDistributor' && (
                        <div className={Styles.actionsArea}>
                            <Button
                                theme="ternary"
                                variant="filled"
                                type="submit"
                                value={getText('save')}
                                disabled={onClick ? false : true}
                                loading={isLoading}
                                onClick={onClick}
                            />
                        </div>
                    )}
                    {(action === 'distributor-documents' || action === 'provider-documents') && (
                        <div className={Styles.actionsArea}>
                            <Button
                                theme="ternary"
                                variant="filled"
                                type="submit"
                                value={getText('save')}
                                disabled={onClick ? false : true}
                                loading={isLoading}
                                onClick={onClick}
                            />
                            <i
                                className={classnames('icon-icon_delete_3', Styles.icon)}
                                onClick={onCancel}
                            ></i>
                        </div>
                    )}
                    {action === 'default' && <></>}
                </div>
            </ErrorBoundary>
        );
    }
}

export default SectionHeader;
