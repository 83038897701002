import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Styles from './TabNav.module.scss';

export class TabNav extends React.PureComponent {
    static propTypes = {
        texts: PropTypes.object,
        navigation: PropTypes.arrayOf(PropTypes.object),
        section: PropTypes.string,
    };

    render() {
        const { navigation, section } = this.props;

        if (!navigation) return null;

        return (
            <nav role="navigation" data-test="TabNav" className={Styles.tabNav}>
                <ul>
                    {navigation.map(path => (
                        <Link
                            key={path.id}
                            to={!path.disabled ? path.fullUrl : '#'}
                            className={path.disabled ? Styles.disabled : null}
                        >
                            <li className={path.slug === section ? Styles?.active : null}>
                                <span>{path.name}</span>
                            </li>
                        </Link>
                    ))}
                </ul>
            </nav>
        );
    }
}

export default TabNav;
