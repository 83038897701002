import classnames from 'classnames';
import withOverlay from 'hoc/withOverlay';
import { getText } from 'localization';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'shared/Button';
import './Prompt.css';

import Styles from './Prompt.module.scss';

export class Prompt extends React.PureComponent {
    static propTypes = {
        type: PropTypes.string,
        active: PropTypes.bool,
        texts: PropTypes.object,
        id: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.string,
        onConfirm: PropTypes.func,
        onCancelPrompt: PropTypes.func,
        retry: PropTypes.bool,
        isLoading: PropTypes.bool,
        custom: PropTypes.bool,
        customText: PropTypes.string,
        customContent: PropTypes.string,
        customTitle: PropTypes.object,
        customWidth: PropTypes.string,
    };

    static defaultProps = {
        type: 'prompt',
    };

    render() {
        const {
            id,
            active,
            title,
            content,
            onConfirm,
            onCancelPrompt,
            retry,
            isLoading,
            custom,
            customText,
            customContent,
            customTitle,
            customWidth,
        } = this.props;

        if (!active) return null;

        return (
            <div
                data-testid="PromptContainer"
                id={id}
                className={classnames(
                    Styles.prompt,
                    active ? Styles?.active : null,
                    customWidth ? Styles[customWidth] : null,
                )}
            >
                <div className={Styles.header}>
                    {!customTitle && <h3 data-testid="PromptTitle">{title}</h3>}

                    {customTitle && (
                        <h3 className={Styles.custom}>
                            {customTitle.icon && <i className={customTitle.icon}></i>}
                            {customTitle.delete && (
                                <span>
                                    <i className="icon-icon_delete_2"></i>
                                </span>
                            )}
                        </h3>
                    )}
                </div>

                {content && (
                    <div className={Styles.content}>
                        {!customContent && (
                            <p
                                data-testid="PromptContent"
                                dangerouslySetInnerHTML={{ __html: content }}
                            />
                        )}
                        {customContent && (
                            <div
                                className={Styles.custom}
                                dangerouslySetInnerHTML={{ __html: customContent }}
                            />
                        )}
                    </div>
                )}

                {!custom && (
                    <div data-testid="PromptActions" className={Styles.actions}>
                        <Button
                            autoFocus
                            data-testid="PromptButtonConfirm"
                            value={
                                retry ? getText('retry') : customText ? customText : getText('ok')
                            }
                            type="button"
                            variant="filled"
                            theme="ternary"
                            onClick={onConfirm}
                            disabled={isLoading}
                            aria-disabled={isLoading}
                            loading={isLoading}
                            tabIndex="1"
                        />
                        <Button
                            data-testid="PromptButtonCancel"
                            value={getText('cancel')}
                            type="button"
                            variant="none"
                            theme="ternary"
                            onClick={onCancelPrompt}
                            disabled={isLoading}
                        />
                    </div>
                )}

                {custom && customText && (
                    <div data-testid="PromptActions" className={Styles.actions}>
                        <Button
                            autoFocus
                            data-testid="PromptButtonConfirm"
                            value={customText}
                            type="button"
                            variant="filled"
                            theme="ternary"
                            onClick={onConfirm}
                            disabled={isLoading}
                            aria-disabled={isLoading}
                            loading={isLoading}
                            tabIndex="1"
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default withOverlay(Prompt);
