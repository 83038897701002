import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Styles from './Dashboard.module.scss';
import ErrorBoundary from 'shared/ErrorBoundary';
import Loading from 'shared/Loading';

export class Dashboard extends React.PureComponent {
    static propTypes = {
        texts: PropTypes.object,
        isLoading: PropTypes.bool,
        dashboard: PropTypes.object,
        fetchData: PropTypes.func,
    };

    componentDidMount() {
        this.props.fetchData();
    }

    render() {
        const { isLoading, dashboard } = this.props;
        return (
            <ErrorBoundary>
                <section data-test="DashboardContainer" className={Styles.dashboard}>
                    <div className={Styles.scrollContainer}>
                        <div className={Styles.wrapper}>
                            <div className={Styles.row}>
                                <Link
                                    data-test="DashboardNetworks"
                                    className={Styles.container}
                                    to="/networks-management/networks"
                                >
                                    <div className={classnames(Styles.users)}>
                                        <h3 className={Styles.title}>NETWORKS</h3>
                                        {isLoading && <Loading />}

                                        {!isLoading && (
                                            <div className={Styles.content}>
                                                <div className={Styles.circle}>
                                                    <i className="icon-icon_networks"></i>
                                                    <p className={Styles.number}>
                                                        {dashboard.totals.total_number_of_networks}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Link>
                            </div>

                            <div className={Styles.row}>
                                <Link
                                    data-test="DashboardEntities"
                                    className={Styles.container}
                                    to="/networks-management/entities"
                                >
                                    <div className={classnames(Styles.users)}>
                                        <h3 className={Styles.title}>ENTITIES</h3>
                                        {isLoading && <Loading />}

                                        {!isLoading && (
                                            <div className={Styles.content}>
                                                <div className={Styles.circle}>
                                                    <i className="icon-icon_entities"></i>
                                                    <p className={Styles.number}>
                                                        {
                                                            dashboard.totals
                                                                .total_number_of_entity_directories
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Link>

                                <Link
                                    data-test="DashboardServiceServices"
                                    className={Styles.container}
                                    to="/networks-management/services-items"
                                >
                                    <div className={classnames(Styles.users)}>
                                        <h3 className={Styles.title}>SERVICES</h3>
                                        {isLoading && <Loading />}

                                        {!isLoading && (
                                            <div className={Styles.content}>
                                                <div className={Styles.circle}>
                                                    <i className="icon-icon_services"></i>
                                                    <p className={Styles.number}>
                                                        {
                                                            dashboard.totals
                                                                .total_number_of_service_directories
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Link>

                                <Link
                                    data-test="DashboardServiceItems"
                                    className={Styles.container}
                                    to="/networks-management/services-items"
                                >
                                    <div className={classnames(Styles.users)}>
                                        <h3 className={Styles.title}>ITEMS</h3>
                                        {isLoading && <Loading />}

                                        {!isLoading && (
                                            <div className={Styles.content}>
                                                <div className={Styles.circle}>
                                                    <i className="icon-icon_items"></i>
                                                    <p className={Styles.number}>
                                                        {
                                                            dashboard.totals
                                                                .total_number_of_item_directories
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </ErrorBoundary>
        );
    }
}

export default Dashboard;
