import rootInitialState from 'redux/store/initialState';

export const initialState = {
    texts: rootInitialState.texts,
    board: rootInitialState.board,
    integrations: rootInitialState.integrations,
    projects: rootInitialState.projects,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default reducer;
