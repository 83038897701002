import classnames from 'classnames';
import { getText } from 'localization';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'shared/Button';
import Dropdown from 'shared/Dropdown';
import DropdownMultiselect from 'shared/DropdownMultiselect';
import Input from 'shared/Input';
import SearchRegionInput from 'shared/SearchRegionInput';
import UploadImage from 'shared/UploadImage';
import Styles from '../../NetworkDetails.module.scss';

const NetworkDetailsForm = ({
    init,
    fields,
    data,
    temp,
    countries,
    segments,
    dirEntities,
    dirServices,
    dirItems,
    searchOptions,
    type,
    canSave,
    isLoading,
    isSaving,
    onChange,
    onChangeRegion,
    onDropdownChange,
    onDropdownMultiselectChange,
    onDropdownMultiselectAll,
    onDropdownMultiselectClear,
    onDropdownMultiselectDone,
    onUpload,
    onSubmit,
    onClear,
}) => {
    return (
        <form autoComplete="off" onSubmit={onSubmit}>
            <div className={Styles.wrapper}>
                <div className={Styles.upload}>
                    <UploadImage
                        id="networkImage"
                        name="image"
                        namespace="image"
                        field="image"
                        data={temp && temp.image ? temp : data}
                        size="md"
                        shape="square"
                        disabled={isLoading}
                        isLoading={isSaving}
                        isSaving={isSaving}
                        onUpload={onUpload}
                        tooltip={getText('selfcare_network_section_extensions_format_label')}
                        minWidth={300}
                        minHeight={300}
                        extensions={['image/jpg', 'image/jpeg', 'image/png']}
                        accept="image/png, image/jpeg"
                        ratio={1}
                        format="square"
                        strict
                    />
                </div>

                <div className={Styles.rows}>
                    <div className={Styles.row}>
                        <div className={Styles.col}>
                            <p className={Styles.fieldTitle}>
                                {getText('selfcare_network_section_name_label')}
                            </p>

                            <Input
                                autoFocus={init ? true : false}
                                type="text"
                                namespace=""
                                name="name"
                                theme="ternary"
                                variant="outlined"
                                size="sm"
                                state={fields.name !== '' ? 'active' : null}
                                placeholder={getText(
                                    'selfcare_network_section_insert_network_name_placeholder',
                                )}
                                value={fields.name !== '' ? fields.name : ''}
                                disabled={isLoading || isSaving}
                                onChange={onChange}
                            />
                        </div>

                        <div className={Styles.col}>
                            <p className={Styles.fieldTitle}>
                                {getText('selfcare_network_section_country_label')}
                            </p>

                            <Dropdown
                                id="countries"
                                data={countries.list}
                                selected={fields.country_id}
                                position="bottom"
                                onChange={onDropdownChange}
                                name="country_id"
                                theme="select"
                                variant="outlined"
                                size="sm"
                                state={fields.country_id ? 'active' : null}
                                block
                                hasEmptyState
                                placeholder={getText('common_select_label')}
                                disabled={isLoading || isSaving}
                            />
                        </div>

                        <div className={Styles.col}>
                            <p className={Styles.fieldTitle}>
                                {getText('selfcare_network_section_region_covered_label')}
                            </p>

                            <SearchRegionInput
                                init={init}
                                region={fields.region_covered}
                                value={fields.region_covered}
                                onChange={onChangeRegion}
                                searchOptions={searchOptions}
                                debounce={500}
                                disabled={isLoading || isSaving}
                                size="sm"
                            />
                        </div>

                        <div className={Styles.col}>
                            <p className={Styles.fieldTitle}>
                                {getText('selfcare_network_section_segment_label')}
                            </p>

                            <DropdownMultiselect
                                id="networkSegments"
                                namespace="segments"
                                data={segments && segments.length > 0 ? segments : []}
                                selected={fields.segments}
                                position="bottom"
                                onChange={onDropdownMultiselectChange}
                                name="segments"
                                theme="select"
                                variant="outlined"
                                size="sm"
                                state={
                                    segments.selected && segments.selected.length > 0
                                        ? 'active'
                                        : null
                                }
                                onSelectAll={onDropdownMultiselectAll}
                                onClearAll={onDropdownMultiselectClear}
                                onDone={onDropdownMultiselectDone}
                                block
                                disabled={isLoading || isSaving}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>

                    <div className={Styles.row}>
                        <div className={classnames(Styles.col, Styles.col3)}>
                            <p className={Styles.fieldTitle}>
                                {getText('selfcare_network_section_entities_label')}
                            </p>

                            <DropdownMultiselect
                                id="networkEntities"
                                namespace="directory_entities"
                                name="directory_entities"
                                data={dirEntities && dirEntities.length > 0 ? dirEntities : []}
                                selected={fields.directory_entities}
                                position="bottom"
                                onChange={onDropdownMultiselectChange}
                                theme="select"
                                variant="outlined"
                                size="sm"
                                state={
                                    dirEntities.selected && dirEntities.selected.length > 0
                                        ? 'active'
                                        : null
                                }
                                onSelectAll={onDropdownMultiselectAll}
                                onClearAll={onDropdownMultiselectClear}
                                onDone={onDropdownMultiselectDone}
                                block
                                disabled={isLoading || isSaving}
                                isLoading={isLoading}
                                itemKey="name"
                            />
                        </div>

                        <div className={classnames(Styles.col, Styles.col3)}>
                            <p className={Styles.fieldTitle}>
                                {getText('selfcare_network_section_services_label')}
                            </p>

                            <DropdownMultiselect
                                id="networkServices"
                                namespace="directory_services"
                                name="directory_services"
                                data={dirServices && dirServices.length > 0 ? dirServices : []}
                                selected={fields.directory_services}
                                position="bottom"
                                onChange={onDropdownMultiselectChange}
                                theme="select"
                                variant="outlined"
                                size="sm"
                                state={
                                    dirServices.selected && dirServices.selected.length > 0
                                        ? 'active'
                                        : null
                                }
                                onSelectAll={onDropdownMultiselectAll}
                                onClearAll={onDropdownMultiselectClear}
                                onDone={onDropdownMultiselectDone}
                                block
                                disabled={isLoading || isSaving}
                                isLoading={isLoading}
                                itemKey="name"
                            />
                        </div>

                        <div className={classnames(Styles.col, Styles.col3)}>
                            <p className={Styles.fieldTitle}>
                                {getText('selfcare_network_section_items_label')}
                            </p>

                            <DropdownMultiselect
                                id="networkItems"
                                name="directory_items"
                                namespace="directory_items"
                                data={dirItems && dirItems.length > 0 ? dirItems : []}
                                selected={fields.directory_items}
                                position="bottom"
                                onChange={onDropdownMultiselectChange}
                                theme="select"
                                variant="outlined"
                                size="sm"
                                state={
                                    dirItems.selected && dirItems.selected.length > 0
                                        ? 'active'
                                        : null
                                }
                                onSelectAll={onDropdownMultiselectAll}
                                onClearAll={onDropdownMultiselectClear}
                                onDone={onDropdownMultiselectDone}
                                block
                                disabled={isLoading || isSaving}
                                isLoading={isLoading}
                                itemKey="name"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={Styles.actions}>
                <div className={Styles.col}>
                    <Button
                        data-test="NetworksDetailsClear"
                        theme="ternary"
                        variant="outlined"
                        type="button"
                        value={getText('common_clear')}
                        disabled={isLoading || !canSave}
                        onClick={onClear}
                        icon="icon_refresh"
                    />
                    <Button
                        data-test="NetworksDetailsSubmit"
                        theme="ternary"
                        variant="filled"
                        type="submit"
                        value={
                            type === 'create'
                                ? getText('common_create')
                                : getText('common_save_label')
                        }
                        disabled={isLoading || isSaving || !canSave}
                        icon="icon_save"
                        loading={isSaving}
                    />
                </div>
            </div>
        </form>
    );
};

NetworkDetailsForm.propTypes = {
    init: PropTypes.bool,
    fields: PropTypes.object,
    data: PropTypes.object,
    temp: PropTypes.object,
    texts: PropTypes.object,
    countries: PropTypes.object,
    segments: PropTypes.array,
    dirEntities: PropTypes.array,
    dirServices: PropTypes.array,
    dirItems: PropTypes.array,
    searchOptions: PropTypes.object,
    type: PropTypes.string,
    canSave: PropTypes.bool,
    isLoading: PropTypes.bool,
    isSaving: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeRegion: PropTypes.func,
    onDropdownChange: PropTypes.func,
    onDropdownMultiselectChange: PropTypes.func,
    onDropdownMultiselectAll: PropTypes.func,
    onDropdownMultiselectClear: PropTypes.func,
    onDropdownMultiselectDone: PropTypes.func,
    onUpload: PropTypes.func,
    onSubmit: PropTypes.func,
    onClear: PropTypes.func,
};

const NetworkDetailsFormMemo = React.memo(NetworkDetailsForm);
export default NetworkDetailsFormMemo;
