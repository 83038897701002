import Warning from 'assets/svg/icon-warning.svg';
import Spinner from 'assets/svg/spinner.svg';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Styles from './Button.module.scss';

export class Button extends React.PureComponent {
    myRef = React.createRef();

    static propTypes = {
        size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl']),
        theme: PropTypes.oneOf([
            'primary',
            'secondary',
            'ternary',
            'ternary-danger',
            'quaternary',
            'danger',
            'success',
            'blue',
            'form',
            'select',
            'toggle',
            'inputValue',
        ]),
        variant: PropTypes.oneOf(['none', 'underlined', 'outlined', 'filled', 'locked']),
        state: PropTypes.oneOf([
            'empty',
            'active',
            'error',
            'selected',
            'focus',
            'light',
            'dark',
            'yellow',
            'green',
            'red',
            'gray',
            'inactive',
        ]),
        icon: PropTypes.string,
        itemIcon: PropTypes.string,
        type: PropTypes.oneOf(['button', 'reset', 'submit']),
        name: PropTypes.string,
        disabled: PropTypes.bool,
        value: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        id: PropTypes.string,
        custom: PropTypes.bool,
        element: PropTypes.element,
        autoFocus: PropTypes.bool,
        block: PropTypes.bool,
        loading: PropTypes.bool,
        tabIndex: PropTypes.string,
        form: PropTypes.string,
        readOnly: PropTypes.bool,
        onUnlock: PropTypes.func,
        disableClick: PropTypes.bool,
    };

    static defaultProps = {
        theme: 'primary',
        size: 'md',
        variant: 'outlined',
        type: 'button',
        disabled: false,
        value: 'Habit button',
        itemIcon: '',
        disableClick: false,
    };

    componentDidMount() {
        if (this.myRef && this.myRef.current && this.props.autoFocus) {
            this.myRef.current.focus();
        }
    }

    isHTML(str) {
        if (str.includes('<') && str.includes('>')) {
            return true;
        } else {
            return false;
        }
    }

    getItemIcon() {
        const { itemIcon, value } = this.props;
        let isHTML = str => {
            if (str.includes('<') && str.includes('>')) {
                return true;
            } else {
                return false;
            }
        };
        if (itemIcon) {
            return itemIcon === 'warning' ? (
                <>
                    <div id={'itemIconContainer'} className={Styles.itemIconContainer}>
                        <img
                            src={Warning}
                            alt="Warning icon that represents an error with the selected value"
                        />
                        <span className={Styles.postLabelIcon}>{value}</span>
                    </div>
                </>
            ) : null;
        } else {
            return isHTML(value) ? (
                <span dangerouslySetInnerHTML={{ __html: value }} />
            ) : (
                <>{value}</>
            );
        }
    }

    render() {
        const {
            id,
            theme,
            variant,
            icon,
            type,
            value,
            disabled,
            state,
            onClick,
            custom,
            element,
            autoFocus,
            block,
            loading,
            tabIndex,
            size,
            form,
            itemIcon,
            readOnly,
            onUnlock,
            disableClick,
            smallPlusIcon,
            ...otherProps
        } = this.props;

        let buttonStyle = icon
            ? classnames(
                  Styles.button,
                  Styles.withIcon,
                  Styles[theme],
                  Styles[variant],
                  Styles[state],
                  Styles[size],
                  readOnly ? Styles.readOnly : null,
                  disableClick ? Styles.noClick : null,
              )
            : classnames(
                  Styles.button,
                  Styles[theme],
                  Styles[variant],
                  Styles[state],
                  Styles[size],
                  readOnly ? Styles.readOnly : null,
                  disableClick ? Styles.noClick : null,
                  smallPlusIcon ? Styles.smallPlusIcon : null,
              );

        buttonStyle = block ? classnames(buttonStyle, Styles.block) : buttonStyle;

        if (custom && element) {
            return (
                <div
                    data-test="ButtonContainer"
                    className={classnames(
                        Styles.container,
                        block ? Styles.block : null,
                        disableClick ? Styles.noClick : null,
                    )}
                >
                    <button
                        data-test="Button"
                        id={id}
                        type={type}
                        className={buttonStyle}
                        disabled={disabled}
                        aria-disabled={disabled}
                        aria-label={value}
                        onClick={onClick}
                        autoFocus={autoFocus}
                        ref={this.myRef}
                        tabIndex={tabIndex}
                        form={form}
                        {...otherProps}
                    >
                        {element}

                        {icon && (
                            <i
                                data-test="ButtonIcon"
                                className={classnames(Styles.icon, `icon-${icon}`)}
                            ></i>
                        )}
                    </button>
                </div>
            );
        } else {
            return (
                <div
                    data-test="ButtonContainer"
                    className={classnames(
                        Styles.container,
                        block ? Styles.block : null,
                        disableClick ? Styles.noClick : null,
                    )}
                >
                    <button
                        data-test="Button"
                        id={id}
                        type={type}
                        className={buttonStyle}
                        disabled={disabled}
                        aria-disabled={disabled}
                        aria-label={value}
                        onClick={onClick}
                        autoFocus={autoFocus}
                        ref={this.myRef}
                        tabIndex={tabIndex}
                        form={form}
                        {...otherProps}
                    >
                        {!loading && (
                            <>
                                {this.getItemIcon()}

                                {icon && !loading && (
                                    <i
                                        data-test="ButtonIcon"
                                        className={classnames(Styles.icon, `icon-${icon}`)}
                                        onClick={onUnlock ? onUnlock : null}
                                    ></i>
                                )}
                            </>
                        )}

                        {loading && (
                            <div data-test="ButtonLoading" className={[Styles.loading]}>
                                {value}
                                <img src={Spinner} alt="" />
                            </div>
                        )}
                    </button>
                </div>
            );
        }
    }
}

export default Button;
