import axios from 'axios';
import { NProgressLoad } from './nprogress';
import { getAPIBaseURL } from 'utils/functions';
import { API_DEFAULT_TIMEOUT } from 'utils/constants';
import { apiHandleErrorResponse } from 'services/api';

const configureAPI = () => {
    const instance = axios.create();

    axios.defaults.baseURL = getAPIBaseURL();
    axios.defaults.timeout = process.env.REACT_APP_API_DEFAULT_TIMEOUT || API_DEFAULT_TIMEOUT;

    instance.interceptors.response.use(
        response => response,
        error => {
            apiHandleErrorResponse(error);

            return Promise.reject(error);
        },
    );

    NProgressLoad({ showSpinner: false });
};

export default configureAPI;
