import NetworkTile from 'components/Networks/NetworkTile';
import { getText } from 'localization';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import NetworkDetails from 'redux/containers/Networks/NetworkDetails';
import ShareModal from 'redux/containers/Networks/ShareModal';
import ErrorBoundary from 'shared/ErrorBoundary';
import Loading from 'shared/Loading';
import Popup from 'shared/Popup';

import SectionHeader from 'shared/SectionHeader';
import { getElementPosition } from 'utils/functions';
import Styles from './Networks.module.scss';

export class Networks extends React.PureComponent {
    state = {
        search: '',
        networkDetails: {},
        isNetworkDetails: false,
        settings: {
            active: false,
            element: {},
            id: null,
        },
    };

    static propTypes = {
        texts: PropTypes.object,
        isLoading: PropTypes.bool,
        networks: PropTypes.arrayOf(PropTypes.object),
        segments: PropTypes.array,
        countries: PropTypes.array,
        createNetwork: PropTypes.func,
        onDeleteNetwork: PropTypes.func,
        openPrompt: PropTypes.func,
        getNetworks: PropTypes.func,
        openShareModal: PropTypes.func,
        shareModal: PropTypes.bool,
    };

    componentDidMount() {
        this.props.getNetworks();
    }

    handleCreateNetwork = e => {
        // this.props.createNetwork();
        this.handleOnOpenNetworkDetails();
    };

    handleOnSearch = value => {
        this.setState({
            search: value.toUpperCase(),
        });
    };

    handleOnClear = () => {
        this.setState({
            search: '',
        });
    };

    handleOnSubmit = e => {
        e.preventDefault();
    };

    handleToogleSettings = e => {
        e.preventDefault();
        const id = e.currentTarget.getAttribute('data-network-id');

        this.setState({
            settings: {
                active: !this.state.settings?.active,
                element: getElementPosition(e.currentTarget),
                id: id,
            },
        });
    };

    handleClickSetting = e => {
        e.preventDefault();

        const action = e.currentTarget.getAttribute('data-action');

        switch (action) {
            case 'delete':
                const data = {
                    id: 'deleteNetworkPrompt',
                    title: 'Alert',
                    content: getText('prompt_delete_network'),
                    onConfirm: () =>
                        this.props.onDeleteNetwork({
                            id: this.state.settings.id,
                        }),
                };

                this.props.openPrompt(data);
                break;

            case 'share':
                this.props.openShareModal({ id: this.state.settings.id, type: 'networks' });
                break;

            default:
                break;
        }
    };

    handleCloseSettings = () => {
        this.setState({
            settings: {
                active: false,
                id: null,
            },
        });
    };

    handleOnOpenNetworkDetails = () => {
        this.setState(state => ({
            isNetworkDetails: !state.isNetworkDetails,
        }));
    };

    handleOnCloseNetworkDetails = () => {
        this.setState(state => ({
            isNetworkDetails: false,
        }));
    };

    render() {
        const { texts, networks, isLoading, shareModal } = this.props;
        const { search, settings, isNetworkDetails } = this.state;

        const searchResults =
            networks &&
            networks.length > 0 &&
            networks.filter(
                directory => directory.name && directory.name.toUpperCase().indexOf(search) !== -1,
            );

        return (
            <ErrorBoundary>
                <article data-test="NetworksContainer" className={Styles.networks}>
                    <SectionHeader
                        data-test="NetworksSectionHeader"
                        title={!isNetworkDetails ? getText('networks') : getText('create_network')}
                        texts={texts}
                        action={isNetworkDetails ? 'network' : 'search'}
                        onClick={
                            !isNetworkDetails && networks && networks.length > 0
                                ? this.handleCreateNetwork
                                : isNetworkDetails
                                ? this.handleOnCloseNetworkDetails
                                : null
                        }
                        onSearch={networks && networks.length > 0 ? this.handleOnSearch : null}
                        onClear={this.handleOnClear}
                        tooltipText={getText('create_network')}
                    />

                    <section className={Styles.scrollContainer}>
                        {isNetworkDetails && (
                            <NetworkDetails
                                data-test="NetworkDetails"
                                type="create"
                                onClose={this.handleOnCloseNetworkDetails}
                            />
                        )}

                        <Suspense fallback={<Loading theme="none" variant="sm" />}>
                            {isLoading && <Loading />}

                            {!isLoading && networks && networks.length === 0 && (
                                <div>
                                    <NetworkTile
                                        data-test="NetworkTileAdd"
                                        type="add"
                                        size="lg"
                                        text={getText('create_network')}
                                        onClick={this.handleCreateNetwork}
                                        isLoading={isLoading}
                                    />
                                </div>
                            )}

                            {!isLoading &&
                                networks &&
                                networks.length > 0 &&
                                searchResults.map(dir => (
                                    <NetworkTile
                                        data-test="NetworkTile"
                                        key={dir.id}
                                        type="view"
                                        size="lg"
                                        texts={texts}
                                        network={dir}
                                        tileType="networks"
                                        baseUrl={`/networks-management/networks`}
                                        onSettings={this.handleToogleSettings}
                                    />
                                ))}

                            {!isLoading &&
                                networks &&
                                networks.length > 0 &&
                                searchResults.length === 0 && (
                                    <p data-test="NetworksNotFound" className={Styles.notFound}>
                                        {getText('no_search_results')}
                                    </p>
                                )}
                        </Suspense>
                    </section>

                    <Popup
                        data-test="NetworksSettingsPopup"
                        list
                        position="right"
                        id="networkSettingsPopup"
                        active={settings?.active}
                        element={settings.element}
                        element-id={settings.id}
                        onClickSetting={this.handleClickSetting}
                        closePopup={this.handleCloseSettings}
                        content={this.getPopupContent()}
                    />

                    {shareModal && <ShareModal />}
                </article>
            </ErrorBoundary>
        );
    }

    getPopupContent() {
        return (
            <>
                <li onClick={this.handleClickSetting} data-action="share">
                    {getText('share_network')}
                </li>
                <li onClick={this.handleClickSetting} data-action="delete">
                    {getText('delete_network')}
                </li>
            </>
        );
    }
}

export default Networks;
