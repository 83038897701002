import {
    getDirectoriesByNetworkIdObs,
    getDirectoriesObs,
    getDirectoryElementsObs,
    getDirectoryObs,
    getNetworkAvailableEntitiesObs,
    getNetworkEntitiesObs,
    getNetworkEntityAvailableItemsObs,
    getNetworkEntityAvailableServicesObs,
    getNetworkEntityItemsObs,
    getNetworkEntityServicesObs,
    getNetworkObs,
    getSegmentsObs,
} from 'api/networks';
import { getCountriesObs, getMetaDataObs } from 'api/utils';
import { isLoadingAction } from 'redux/domains/app/actions';
import { closePromptAction, openPromptAction } from 'redux/domains/prompt/actions';
import { zip } from 'rxjs';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import {
    isSavingNetworksAction,
    networksSyncAvailableNetworkEntitiesAction,
    networksSyncCountriesAction,
    networksSyncDirectoriesByTypeAction,
    networksSyncDirectoryAction,
    networksSyncDirectoryElementsAction,
    networksSyncMetaDataTotalsAction,
    networksSyncNetworkAction,
    networksSyncNetworkEntitiesAction,
    networksSyncNetworkEntityServicesItemsAction,
    networksSyncSegmentsAction,
} from './actions';

export const networksMetaDataObs = dispatch => {
    dispatch(isLoadingAction(true));

    return dispatch((dispatch, getState) => {
        const payloadEntityDirectories = {
            namespace: 'total_number_of_directories',
            type: 'count',
            params: {
                directory_type: 'directory-entity',
            },
        };

        const payloadServiceDirectories = {
            namespace: 'total_number_of_directories',
            type: 'count',
            params: {
                directory_type: 'directory-offered-service',
            },
        };

        const payloadItemDirectories = {
            namespace: 'total_number_of_directories',
            type: 'count',
            params: {
                directory_type: 'directory-offered-item',
            },
        };

        const payloadNetworks = {
            namespace: 'total_number_of_networks',
            type: 'count',
        };

        const dataZip = () =>
            zip(
                getMetaDataObs(payloadEntityDirectories),
                getMetaDataObs(payloadServiceDirectories),
                getMetaDataObs(payloadItemDirectories),
                getMetaDataObs(payloadNetworks),
            );

        const subscription = dataZip().subscribe({
            next: response => {
                const payload = {
                    total_number_of_entity_directories:
                        response && response[0] ? response[0].total_number_of_directories.count : 0,
                    total_number_of_service_directories:
                        response && response[1] ? response[1].total_number_of_directories.count : 0,
                    total_number_of_item_directories:
                        response && response[2] ? response[2].total_number_of_directories.count : 0,
                    total_number_of_networks:
                        response && response[3] ? response[3].total_number_of_networks.count : 0,
                };
                dispatch(networksSyncMetaDataTotalsAction(payload));
            },
            error: err => {
                dispatch(isLoadingAction(false));
                console.error('Something wrong occurred: ', err);

                const prompt = {
                    id: 'genericError',
                    title: 'Oops',
                    content: 'Something went wrong...',
                    retry: true,
                    onConfirm: () => {
                        networksMetaDataObs(dispatch);
                    },
                };
                dispatch(openPromptAction(prompt));
            },
            complete: () => dispatch(isLoadingAction(false)),
        });

        return subscription;
    });
};

export const networksGetDirectoryObs = (dispatch, ownProps, element, types) => {
    dispatch(isLoadingAction(true));

    const payload = {
        id: ownProps.match.params.id,
        params: {
            order_by: '-created',
        },
    };

    return dispatch((dispatch, getState) => {
        const dataZip = () =>
            zip(
                getDirectoryObs(payload),
                getDirectoryElementsObs(payload, types),
                getSegmentsObs(),
            );

        const subscription = dataZip().subscribe({
            next: async response => {
                if (response && response[0]) {
                    await dispatch(networksSyncDirectoryAction(response[0], types));
                }

                await dispatch(
                    networksSyncDirectoryElementsAction(
                        response[1] && response[1] !== '' ? response[1] : { elements: [] },
                        element,
                        types,
                    ),
                );

                await dispatch(
                    networksSyncSegmentsAction(
                        response[2] && response[2] !== '' ? response[2] : { elements: [] },
                        types,
                    ),
                );

                dispatch(closePromptAction());
                dispatch(isLoadingAction(false));
            },
            error: err => {
                dispatch(isLoadingAction(false));
                // console.error('Something wrong occurred: ', err);

                const prompt = {
                    id: 'genericError',
                    title: 'Oops',
                    content: 'Something went wrong...',
                    retry: true,
                    onConfirm: () => {
                        networksGetDirectoryObs(dispatch, ownProps, element, types);
                    },
                };
                dispatch(openPromptAction(prompt));
            },
            complete: () => {
                // console.log('COMPLETE');
                // dispatch(isLoadingAction(false));
            },
        });

        return subscription;
    });
};

export const networksGetServicesItemsDirectoriesObs = (dispatch, ownProps) => {
    dispatch(isLoadingAction(true));

    const payloadServices = {
        directory_type: 'directory-offered-service',
        order_by: '-created',
    };

    const payloadItems = {
        directory_type: 'directory-offered-item',
        order_by: '-created',
    };

    return dispatch((dispatch, getState) => {
        const dataZip = () =>
            zip(getDirectoriesObs(payloadServices), getDirectoriesObs(payloadItems));

        const subscription = dataZip().subscribe({
            next: response => {
                dispatch(
                    networksSyncDirectoriesByTypeAction(
                        response[0] && response[0] !== '' ? response[0] : { elements: [] },
                        'services',
                    ),
                );

                dispatch(
                    networksSyncDirectoriesByTypeAction(
                        response[1] && response[1] !== '' ? response[1] : { elements: [] },
                        'items',
                    ),
                );

                dispatch(closePromptAction());
            },
            error: err => {
                dispatch(isLoadingAction(false));
                // console.error('Something wrong occurred: ', err);

                const prompt = {
                    id: 'genericError',
                    title: 'Oops',
                    content: 'Something went wrong...',
                    retry: true,
                    onConfirm: () => {
                        networksGetServicesItemsDirectoriesObs(dispatch, ownProps);
                    },
                };
                dispatch(openPromptAction(prompt));
            },
            complete: () => dispatch(isLoadingAction(false)),
        });

        return subscription;
    });
};

export const networksGetNetworkDataObs = (dispatch, ownProps) => {
    dispatch(isSavingNetworksAction(true));

    return dispatch((dispatch, getState) => {
        const id = ownProps.match.params.id;

        let dataZip;
        if (id) {
            const payloadEntities = {
                id: id,
                params: {
                    'directory.directory_type': 'directory-entity',
                    order_by: '-created',
                },
            };

            const payloadServices = {
                id: id,
                params: {
                    'directory.directory_type': 'directory-offered-service',
                    order_by: '-created',
                },
            };

            const payloadItems = {
                id: id,
                params: {
                    'directory.directory_type': 'directory-offered-item',
                    order_by: '-created',
                },
            };

            dataZip = () =>
                zip(
                    getSegmentsObs(),
                    getCountriesObs(),
                    getDirectoriesByNetworkIdObs(payloadEntities),
                    getDirectoriesByNetworkIdObs(payloadServices),
                    getDirectoriesByNetworkIdObs(payloadItems),
                    getDirectoriesObs({
                        directory_type: 'directory-entity',
                        order_by: '-created',
                    }),
                    getDirectoriesObs({
                        directory_type: 'directory-offered-service',
                        order_by: '-created',
                    }),
                    getDirectoriesObs({
                        directory_type: 'directory-offered-item',
                        order_by: '-created',
                    }),
                );
        } else {
            dataZip = () =>
                zip(
                    getSegmentsObs(),
                    getCountriesObs(),
                    getDirectoriesObs({
                        directory_type: 'directory-entity',
                        order_by: '-created',
                    }),
                    getDirectoriesObs({
                        directory_type: 'directory-offered-service',
                        order_by: '-created',
                    }),
                    getDirectoriesObs({
                        directory_type: 'directory-offered-item',
                        order_by: '-created',
                    }),
                );
        }

        const subscription = dataZip().subscribe({
            next: async response => {
                await dispatch(
                    networksSyncSegmentsAction(
                        response[0] && response[0] !== '' ? response[0] : { elements: [] },
                    ),
                );

                await dispatch(
                    networksSyncCountriesAction(
                        response[1] && response[1] !== '' ? response[1] : { elements: [] },
                    ),
                );

                await dispatch(
                    networksSyncDirectoriesByTypeAction(
                        mergeDirectories(response[2], response[5] ? response[5] : null),
                        'entities',
                    ),
                );
                await dispatch(
                    networksSyncDirectoriesByTypeAction(
                        mergeDirectories(response[3], response[6] ? response[6] : null),
                        'services',
                    ),
                );
                await dispatch(
                    networksSyncDirectoriesByTypeAction(
                        mergeDirectories(response[4], response[7] ? response[7] : null),
                        'items',
                    ),
                );

                dispatch(closePromptAction());
                dispatch(isSavingNetworksAction(false));
            },
            error: err => {
                dispatch(isSavingNetworksAction(false));
                // console.error('Something wrong occurred: ', err);

                const prompt = {
                    id: 'genericError',
                    title: 'Oops',
                    content: 'Something went wrong...',
                    retry: true,
                    onConfirm: () => {
                        networksGetNetworkDataObs(dispatch, ownProps);
                    },
                };
                dispatch(openPromptAction(prompt));
            },
            complete: () => {
                // console.log('COMPLETE');
                // dispatch(isLoadingAction(false));
            },
        });

        return subscription;
    });
};

const mergeDirectories = (dir1, dir2) => {
    let temp1 = dir1 && dir1 !== '' ? dir1 : { elements: [] };
    let temp2 = dir2 && dir2 !== '' ? dir2 : { elements: [] };

    if (!dir2) {
        return {
            elements: temp1.elements,
            size: temp1.elements.length,
        };
    }

    if (temp1.elements.length > 0) {
        temp1.elements = temp1.elements.map(d => d.directory);
    }

    let elements = [...temp1.elements, ...temp2.elements];
    elements = [...new Map(elements.map(item => [item['id'], item])).values()];

    return {
        elements: elements,
        size: temp1.elements.length + temp2.elements.length,
    };
};

export const networksGetNetworkObs = (dispatch, ownProps, search) => {
    dispatch(isLoadingAction(true));

    return dispatch((dispatch, getState) => {
        const id = ownProps.match.params.id;

        if (!id) {
            return;
        }

        let params = {
            order_by: '-created',
            page_size: DEFAULT_PAGE_SIZE,
        };

        const payload = {
            id: id,
            params: params,
        };

        const dataZip = () =>
            zip(
                getNetworkObs(payload, {
                    name: search,
                }),
                getNetworkAvailableEntitiesObs(payload, {
                    name: search,
                }),
                getNetworkEntitiesObs(payload, {
                    name: search,
                }),
            );

        const subscription = dataZip().subscribe({
            next: async response => {
                await dispatch(
                    networksSyncNetworkAction(response[0] && response[0] !== '' ? response[0] : {}),
                );

                await dispatch(
                    networksSyncAvailableNetworkEntitiesAction(
                        response[1] && response[1] !== '' ? response[1] : { elements: [] },
                    ),
                );

                await dispatch(
                    networksSyncNetworkEntitiesAction(
                        response[2] && response[2] !== '' ? response[2] : { elements: [] },
                    ),
                );

                dispatch(closePromptAction());
                dispatch(isLoadingAction(false));
            },
            error: err => {
                dispatch(isLoadingAction(false));
                // console.error('Something wrong occurred: ', err);

                const prompt = {
                    id: 'genericError',
                    title: 'Oops',
                    content: 'Something went wrong...',
                    retry: true,
                    onConfirm: () => {
                        networksGetNetworkObs(dispatch, ownProps);
                    },
                };
                dispatch(openPromptAction(prompt));
            },
            complete: () => {},
        });

        return subscription;
    });
};

export const networksGetNetworkEntityDataObs = (dispatch, data, ownProps) => {
    dispatch(isLoadingAction(true));

    return dispatch((dispatch, getState) => {
        const networkNumOfItems = getState().networks.networks.size;
        const id = ownProps.match.params.id;

        if (!id) {
            return;
        }

        let params = {
            order_by: '-created',
            page_start_index: 0,
            page_size: networkNumOfItems,
        };

        // Se eu apenas carregar os 15 da frente ([...list] concatenado) nunca vou ter a referência do item do array que pode ter sido alterado, ex:
        // Alterei o item 10 e já estou na terceira página de scroll, se eu rodar o [...list] não vou ter o item 10,
        // pois ele não está na lista que está sendo renderizada (90-120), então não vai ser recarregado
        // Eu precisaria de um fetch apenas no elemento mudado

        let queryParams = {};

        if (Boolean(data.name)) {
            queryParams.name = `m%2F(%3Fi)${data.name}.*%2Fi`;
        }

        const payload = {
            id: id,
            entityId: data.id,
            params,
        };

        const dataZip = () =>
            zip(
                getNetworkEntityAvailableServicesObs(payload, queryParams),
                getNetworkEntityServicesObs(payload, queryParams),
                getNetworkEntityAvailableItemsObs(payload, queryParams),
                getNetworkEntityItemsObs(payload, queryParams),
            );

        const subscription = dataZip().subscribe({
            next: async response => {
                const servicesPayload = {
                    type: 'services',
                    available: response[0] && response[0] !== '' ? response[0] : { elements: [] },
                    list: response[1] && response[1] !== '' ? response[1] : { elements: [] },
                };

                await dispatch(networksSyncNetworkEntityServicesItemsAction(servicesPayload));

                const itemsPayload = {
                    type: 'items',
                    available: response[2] && response[2] !== '' ? response[2] : { elements: [] },
                    list: response[3] && response[3] !== '' ? response[3] : { elements: [] },
                };

                await dispatch(networksSyncNetworkEntityServicesItemsAction(itemsPayload));

                dispatch(closePromptAction());
            },
            error: err => {
                dispatch(isLoadingAction(false));
                // console.error('Something wrong occurred: ', err);

                const prompt = {
                    id: 'genericError',
                    title: 'Oops',
                    content: 'Something went wrong...',
                    retry: true,
                    onConfirm: () => {
                        networksGetNetworkEntityDataObs(dispatch, data, ownProps);
                    },
                };
                dispatch(openPromptAction(prompt));
            },
            complete: () => {
                dispatch(isLoadingAction(false));
            },
        });

        return subscription;
    });
};
