import { getText } from 'localization';
import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';
import Button from 'shared/Button';
import Dropdown from 'shared/Dropdown';
import ErrorBoundary from 'shared/ErrorBoundary';
import Input from 'shared/Input';
import { INSURANCE_STATUS_LIST } from 'utils/constants';
import { cleanMaskedBirthForSubmit, cleanMaskedForSubmit } from 'utils/functions';
import Styles from './AdvancedSearch.module.scss';

export class AdvancedSearch extends React.PureComponent {
    state = {
        overlayId: 'advancedSearchOverlay',
        containerId: 'advancedSearchContainer',
        canSearch: false,
        status: {
            selected: null,
            list: INSURANCE_STATUS_LIST,
        },
        fields: {
            code: '',
            status: '',
            type: '',
            policy_start_date: '',
            date_expiration: '',
        },
    };

    static propTypes = {
        texts: PropTypes.object,
        isLoading: PropTypes.bool,
        data: PropTypes.object,
        advancedSearchStates: PropTypes.array,
        customSearchStates: PropTypes.array,
        onAdvancedSearchSubmit: PropTypes.func,
        onClear: PropTypes.func,
        onToggle: PropTypes.func,
    };

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, false);
        if (this.props.advancedSearchStates) {
            this.setState(
                state => ({
                    status: {
                        ...state.status,
                        list: this.props.advancedSearchStates,
                    },
                }),
                () => {},
            );
        }

        if (
            this.props.data &&
            this.props.data?.fields &&
            Object.entries(this.props.data)?.length > 0 &&
            Object.entries(this.props.data?.fields)?.length > 0
        ) {
            this.syncPropsToState();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, false);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps?.data &&
            this.props.data &&
            prevProps?.data?.fields &&
            this.props.data?.fields &&
            Object.entries(this.props?.data)?.length > 0 &&
            Object.entries(this.props?.data?.fields)?.length > 0 &&
            prevProps?.data?.fields !== this.props.data?.fields
        ) {
            this.syncPropsToState();
        }
    }

    syncPropsToState = () => {
        this.setState(
            state => ({
                fields: { ...this.props.data?.fields },
                status: {
                    ...state.status,
                    selected:
                        this.props.data?.fields?.custom_status &&
                        this.props.data?.fields?.custom_status !== this.props.data?.fields?.status
                            ? this.props.data?.fields?.status +
                              ':' +
                              this.props.data?.fields?.custom_status
                            : this.props.data?.fields?.status
                            ? this.props.data?.fields?.status
                            : null,
                    list: this.props.advancedSearchStates,
                },
            }),
            () => {
                this.handleValidation();
            },
        );
    };

    handleClickOutside = ({ target }) => {
        if (target) {
            const { overlayId, containerId } = this.state;
            const specifiedElement = document.getElementById(containerId);
            const targetId = target?.id;
            const isClickInside = specifiedElement.contains(target);

            if (targetId && targetId === overlayId && !isClickInside) {
                this.props.onToggle();
            }
        }
    };

    handleOnChange = e => {
        const target = e.target;
        const value = target.type && target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            state => ({
                fields: {
                    ...state.fields,
                    [name]: value,
                },
            }),
            () => {
                this.handleValidation();
            },
        );
    };

    handleOnChangeDropdown = data => {
        if (!data.id) {
            const newState = Object.keys(this.state.fields).reduce((object, key) => {
                if (key !== data?.name) {
                    object[key] = this.state.fields[key];
                }
                return object;
            }, {});

            this.setState(
                state => ({
                    [data.name]: {
                        ...state[data.name],
                        selected: data.id,
                    },
                    fields: {
                        ...newState,
                    },
                }),
                () => {
                    this.handleValidation();
                    this.handleOnSubmit();
                },
            );
        } else {
            this.setState(
                state => ({
                    [data.name]: {
                        ...state[data.name],
                        selected: data.id,
                    },
                    fields: {
                        ...state.fields,
                        [data.name]: data.id.includes(':') ? data.id.split(':')[1] : data.id,
                    },
                }),
                () => {
                    this.handleValidation();
                },
            );
        }
    };

    handleOnClear = () => {
        this.setState(
            state => ({
                canSearch: false,
                fields: {
                    code: '',
                    status: '',
                    type: '',
                    policy_start_date: '',
                    date_expiration: '',
                },
                status: {
                    ...state.status,
                    selected: null,
                },
            }),
            () => {
                this.props.onClear();
            },
        );
    };

    handleOnClearSingle = e => {
        const target = e.target;
        const name = target.getAttribute('data-name');

        this.setState(
            state => ({
                fields: {
                    ...state.fields,
                    [name]: '',
                },
            }),
            () => {
                this.handleValidation();
                this.handleOnSubmit();
            },
        );
    };

    handleOnSubmit = e => {
        if (e) {
            e.preventDefault();
        }

        const map = {
            identifier: ['io.habit.operations.fields.identifier'],
        };
        const fields = {
            ...this.state.fields,
            policy_start_date: cleanMaskedBirthForSubmit(this.state.fields?.policy_start_date),
            date_expiration: cleanMaskedBirthForSubmit(this.state.fields?.date_expiration),
            code: cleanMaskedForSubmit(this.state.fields?.code),
        };

        var tempStatus = this.state?.fields?.status?.toLowerCase().replace(/ /g, '-');

        var mainStatus = this.state?.status?.selected;

        if (mainStatus && typeof mainStatus === 'string' && mainStatus?.includes(':')) {
            if (mainStatus?.split(':')[0]) {
                mainStatus = mainStatus.split(':')[0];
            }
        }

        if (
            this.props.customSearchStates &&
            this.props.customSearchStates?.some(e => e.data === tempStatus)
        ) {
            fields.status = mainStatus;
            fields.custom_status = tempStatus;
        } else {
            fields.status = tempStatus;
            fields.custom_status = '';
        }

        this.props.onAdvancedSearchSubmit({
            map: map,
            fields: fields,
            closeSearch: e ? true : false,
        });
    };

    handleValidation = () => {
        let hasSearchField = false;
        Object.entries(this.state.fields).forEach(([key, value]) => {
            if (value !== '') {
                hasSearchField = true;
            }
        });

        if (!hasSearchField) {
            this.setState({ canSearch: false });
        } else {
            this.setState({ canSearch: true });
        }
    };

    render() {
        const { isLoading } = this.props;
        const { canSearch, fields, status, overlayId, containerId } = this.state;
        return (
            <ErrorBoundary>
                <div id={overlayId} className={Styles.overlay}>
                    <section
                        id={containerId}
                        data-test="AdvancedSearchContainer"
                        className={Styles.advancedSearch}
                    >
                        <form autoComplete="off" onSubmit={this.handleOnSubmit}>
                            <div className={Styles.row}>
                                <div className={Styles.col}>
                                    <p className={Styles.fieldTitle}>
                                        {getText('common_policy_number')}
                                    </p>
                                    <InputMask
                                        data-test="Input"
                                        mask="aaa-999"
                                        maskPlaceholder="_"
                                        value={
                                            fields.code && fields.code !== ''
                                                ? fields.code.toUpperCase()
                                                : ''
                                        }
                                        onChange={this.handleOnChange}
                                        disabled={isLoading}
                                    >
                                        <Input
                                            type="text"
                                            namespace=""
                                            name="code"
                                            theme="ternary"
                                            variant="outlined"
                                            state={
                                                fields.code && fields.code !== '' ? 'active' : null
                                            }
                                            placeholder="JAD-346"
                                            value={
                                                fields.code && fields.code !== ''
                                                    ? fields.code.toUpperCase()
                                                    : ''
                                            }
                                            disabled={isLoading}
                                            onChange={this.handleOnChange}
                                            onClear={this.handleOnClearSingle}
                                        />
                                    </InputMask>
                                </div>

                                <div className={Styles.col}>
                                    <p className={Styles.fieldTitle}>
                                        {getText(
                                            'index_insurance_policies_advanced_search_status_label',
                                        )}
                                    </p>
                                    <Dropdown
                                        title={''}
                                        id="policyStatus"
                                        data={status.list}
                                        selected={status.selected}
                                        position="bottom"
                                        onChange={this.handleOnChangeDropdown}
                                        name="status"
                                        theme="select"
                                        variant="outlined"
                                        state={status.selected ? 'active' : null}
                                        block
                                        hasEmptyState
                                        placeholder={getText('common_select_label')}
                                    />
                                </div>
                            </div>

                            <div className={Styles.row}>
                                <div className={Styles.col}>
                                    <p className={Styles.fieldTitle}>
                                        {getText(
                                            'index_insurance_policies_advanced_search_start_date_label',
                                        )}
                                    </p>
                                    <InputMask
                                        mask="9999-99-99"
                                        maskPlaceholder="_"
                                        value={
                                            fields.policy_start_date &&
                                            fields.policy_start_date !== ''
                                                ? fields.policy_start_date
                                                : ''
                                        }
                                        onChange={this.handleOnChange}
                                        disabled={isLoading}
                                    >
                                        <Input
                                            type="tel"
                                            namespace=""
                                            name="policy_start_date"
                                            theme="ternary"
                                            variant="outlined"
                                            state={
                                                fields.policy_start_date &&
                                                fields.policy_start_date !== ''
                                                    ? 'active'
                                                    : null
                                            }
                                            placeholder="YYYY-MM-DD"
                                            value={
                                                fields.policy_start_date &&
                                                fields.policy_start_date !== ''
                                                    ? fields.policy_start_date
                                                    : ''
                                            }
                                            disabled={isLoading}
                                            onChange={this.handleOnChange}
                                            onClear={this.handleOnClearSingle}
                                        />
                                    </InputMask>
                                </div>

                                <div className={Styles.col}>
                                    <p className={Styles.fieldTitle}>
                                        {getText(
                                            'index_insurance_policies_advanced_search_end_date_label',
                                        )}
                                    </p>
                                    <InputMask
                                        mask="9999-99-99"
                                        maskPlaceholder="_"
                                        value={
                                            fields.date_expiration && fields.date_expiration !== ''
                                                ? fields.date_expiration
                                                : ''
                                        }
                                        onChange={this.handleOnChange}
                                        disabled={isLoading}
                                    >
                                        <Input
                                            type="tel"
                                            namespace=""
                                            name="date_expiration"
                                            theme="ternary"
                                            variant="outlined"
                                            state={
                                                fields.date_expiration &&
                                                fields.date_expiration !== ''
                                                    ? 'active'
                                                    : null
                                            }
                                            placeholder="YYYY-MM-DD"
                                            value={
                                                fields.date_expiration &&
                                                fields.date_expiration !== ''
                                                    ? fields.date_expiration
                                                    : ''
                                            }
                                            disabled={isLoading}
                                            onChange={this.handleOnChange}
                                            onClear={this.handleOnClearSingle}
                                        />
                                    </InputMask>
                                </div>
                            </div>

                            <div className={Styles.actions}>
                                <div className={Styles.col}>
                                    <Button
                                        data-test="AdvancedSearchClear"
                                        theme="ternary"
                                        variant="outlined"
                                        type="button"
                                        value={getText('common_clear')}
                                        disabled={isLoading || !canSearch}
                                        onClick={this.handleOnClear}
                                        icon="icon_refresh"
                                    />
                                    <Button
                                        data-test="AdvancedSearchSubmit"
                                        theme="ternary"
                                        variant="filled"
                                        type="submit"
                                        value={getText('common_search')}
                                        disabled={isLoading || !canSearch}
                                        icon="icon_search"
                                    />
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
            </ErrorBoundary>
        );
    }
}

export default AdvancedSearch;
