import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Popup from 'shared/Popup';
import { getElementPosition } from 'utils/functions';
import Styles from './InputNumber.module.scss';

export class InputNumber extends React.PureComponent {
    myRef = React.createRef();

    state = {
        search: '',
        focus: false,
        popup: {
            active: false,
            element: null,
            selectedId: -1,
        },
    };

    static defaultProps = {
        step: 'any',
        // pattern: '[0–9]',
        // pattern: '^[0–9]$',
        pattern: '^[0-9]*.?[0-9]*$',
        direction: 'ltr',
    };

    static propTypes = {
        id: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        defaultValue: PropTypes.string,
        name: PropTypes.string,
        namespace: PropTypes.string,
        disabled: PropTypes.bool,
        placeholder: PropTypes.string,
        minLength: PropTypes.number,
        maxLength: PropTypes.number,
        minValue: PropTypes.string,
        maxValue: PropTypes.string,
        onChange: PropTypes.func,
        onClick: PropTypes.func,
        theme: PropTypes.oneOf(['primary', 'secondary', 'ternary', 'quaternary']),
        size: PropTypes.oneOf(['sm', 'md', 'lg']),
        variant: PropTypes.oneOf(['none', 'locked', 'filled', 'outlined']),
        sufix: PropTypes.string,
        classes: PropTypes.string,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        step: PropTypes.string,
        readOnly: PropTypes.bool,
        block: PropTypes.bool,
        pattern: PropTypes.string,
        withDropdown: PropTypes.bool,
        dropdownData: PropTypes.array,
        dropdownSelected: PropTypes.string,
        onDropdownSelect: PropTypes.func,
        onUnlock: PropTypes.func,
        focusOnDemand: PropTypes.bool,
        autoFocus: PropTypes.bool,
        direction: PropTypes.oneOf(['ltr', 'rtl']),
    };

    componentDidMount() {
        if (this.myRef && this.myRef.current && this.props.autoFocus) {
            this.myRef.current.focus();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.myRef &&
            this.myRef.current &&
            !prevProps.focusOnDemand &&
            this.props.focusOnDemand
        ) {
            this.myRef.current.focus();
        }
    }

    handleOnClick = e => {
        // e.preventDefault();
        // e.stopPropagation();
        const { onClick, withDropdown } = this.props;
        if (withDropdown) {
            const el = getElementPosition(e.target);
            this.setState(
                (state, props) => ({
                    focus: true,
                    popup: {
                        ...state.popup,
                        active: true,
                        element: el,
                        // selectedId: !state.popup?.active ? -1 : state.popup.selectedId,
                    },
                }),
                () => {
                    onClick && typeof onClick === 'function' && onClick(e);
                },
            );
        } else {
            onClick && typeof onClick === 'function' && onClick(e);
        }
    };

    handleOnFocus = e => {
        // e.preventDefault();
        // e.stopPropagation();
        const { onFocus } = this.props;
        onFocus && typeof onFocus === 'function' && onFocus(e);
        this.myRef.current.select();
    };

    handleOnBlur = e => {
        const { id, onBlur } = this.props;

        onBlur && typeof onBlur === 'function' && onBlur({ id: id });

        // this.setState(state => ({
        //     focus: false,
        // }));

        // if (this.state.popup?.active) {
        //     this.handleClosePopup();
        // }
    };

    handleClosePopup = () => {
        if (this.state.focus) {
            this.setState(state => ({
                focus: false,
                popup: {
                    ...state.popup,
                    active: false,
                    element: null,
                    selectedId: -1,
                },
            }));
        }
    };

    handleOnSelect = e => {
        e.preventDefault();
        const id = e.currentTarget.getAttribute('data-id');
        id && this.props.onDropdownSelect(id);
        this.handleClosePopup();
    };

    getContent() {
        const { dropdownData, dropdownSelected } = this.props;
        const { popup } = this.state;

        return (
            <div className={Styles.list}>
                <ul>
                    {dropdownData &&
                        dropdownData.length > 0 &&
                        dropdownData.map((item, index) => (
                            <li
                                data-selected={
                                    (popup &&
                                        popup.selectedId !== -1 &&
                                        index.toString() === popup.selectedId.toString()) ||
                                    (popup.selectedId === -1 &&
                                        dropdownSelected &&
                                        item.id.toString() === dropdownSelected.toString())
                                        ? 'selected'
                                        : null
                                }
                                className={classnames(
                                    dropdownSelected &&
                                        item.id.toString() === dropdownSelected.toString()
                                        ? Styles?.active
                                        : null,
                                    item.hasOwnProperty('disabled') && item.disabled
                                        ? Styles.disabled
                                        : null,
                                )}
                                key={item.id}
                                data-id={item.id}
                                onClick={this.handleOnSelect}
                            >
                                <p>{item.text}</p>
                            </li>
                        ))}
                </ul>
            </div>
        );
    }

    render() {
        const {
            id,
            value,
            defaultValue,
            namespace,
            name,
            disabled,
            placeholder,
            onChange,
            minLength,
            maxLength,
            minValue,
            maxValue,
            theme,
            size,
            sufix,
            classes,
            step,
            readOnly,
            block,
            pattern,
            withDropdown,
            variant,
            onUnlock,
            direction,
        } = this.props;

        const { popup } = this.state;

        const inputStyle = classnames(
            Styles[theme],
            Styles[size],
            Styles[variant],
            classes,
            sufix || onUnlock ? Styles.withSufix : null,
            readOnly ? Styles.readOnly : null,
            block ? Styles.block : null,
        );

        return (
            <div data-test="InputNumberContainer" className={Styles.container}>
                <input
                    dir={direction}
                    autoComplete="off"
                    data-id={id}
                    data-test="InputNumber"
                    data-namespace={namespace}
                    disabled={disabled}
                    aria-disabled={disabled}
                    ref={this.myRef}
                    onChange={onChange}
                    onClick={this.handleOnClick}
                    // onClick={onClick}
                    placeholder={placeholder}
                    value={value}
                    defaultValue={defaultValue}
                    type={pattern ? 'text' : 'number'}
                    name={name}
                    className={inputStyle}
                    minLength={minLength}
                    maxLength={maxLength}
                    onFocus={this.handleOnFocus}
                    onBlur={this.handleOnBlur}
                    min={minValue}
                    max={maxValue}
                    step={step}
                    pattern={pattern}
                    readOnly={readOnly}
                />

                {sufix && (
                    <span data-test="InputSufix" className={Styles.sufix}>
                        {sufix}
                    </span>
                )}

                {variant === 'locked' && readOnly && (
                    <span data-test="InputClear" className={Styles.clear}>
                        <i className="icon-icon_delete_2" onClick={onUnlock}></i>
                    </span>
                )}

                {withDropdown && !readOnly && (
                    <Popup
                        id={`selectPopup-${id}`}
                        position="bottom"
                        size="md"
                        active={popup?.active}
                        element={popup.element}
                        closePopup={this.handleClosePopup}
                        content={this.getContent()}
                    />
                )}
            </div>
        );
    }
}

export default InputNumber;
