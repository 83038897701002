// import { lazy } from 'react';
import withAuth from 'hoc/withAuth';
import { roles } from 'services/roles';

import InsuranceAssetsPage from 'redux/containers/Insurance/Assets';
import InsuranceAssetPage from 'redux/containers/Insurance/Assets/Asset';
import InsuranceClaimsPage from 'redux/containers/Insurance/Claims';
import InsuranceClaimPage from 'redux/containers/Insurance/Claims/Claim';
import InsuranceDashboardPage from 'redux/containers/Insurance/Dashboard';
import InsuranceInsureesPage from 'redux/containers/Insurance/Insurees';
import InsuranceInsureePage from 'redux/containers/Insurance/Insurees/Insuree';
import InsurancePaymentsDocumentsPage from 'redux/containers/Insurance/PaymentsDocuments';
import InsurancePaymentDocumentPage from 'redux/containers/Insurance/PaymentsDocuments/PaymentDocument';
import InsurancePoliciesPage from 'redux/containers/Insurance/Policies';
import InsurancePolicyPage from 'redux/containers/Insurance/Policies/Policy';
import InsuranceQuotesPage from 'redux/containers/Insurance/Quotes';
import InsuranceQuotePage from 'redux/containers/Insurance/Quotes/Quote';
import { userIsDistributorNotInsurer } from 'services/roles';

export const insuranceRoutes = [
    {
        id: 'route-integrations-dashboard',
        path: ['/insurance/dashboard', '/insurance/dashboard/app/:app_id'],
        exact: true,
        header: {
            visible: true,
            section: 'insurance',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'insurance',
        },
        component: withAuth(InsuranceDashboardPage),
        'data-test': 'RouteInsuranceDashboardPage',
        scope: [roles.INSURER, roles.DISTRIBUTOR, roles.DISTRIBUTORSELLER],
        bypassScope: userIsDistributorNotInsurer,
    },
    {
        id: 'route-insurance-insurees',
        path: ['/insurance/insurees', '/insurance/insurees/app/:app_id'],
        exact: true,
        header: {
            visible: true,
            section: 'insurance',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'insurance',
        },
        component: withAuth(InsuranceInsureesPage),
        'data-test': 'RouteInsuranceInsureesPage',
        scope: [roles.INSURER, roles.DISTRIBUTOR, roles.DISTRIBUTORSELLER],
        bypassScope: userIsDistributorNotInsurer,
    },
    {
        id: 'route-insurance-insurees-app',
        path: '/insurance/insurees/:id/app/:app_id',
        exact: true,
        header: {
            visible: true,
            section: 'insurance',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'insurance',
        },
        component: withAuth(InsuranceInsureePage),
        'data-test': 'RouteInsuranceInsureePage',
        scope: [roles.INSURER, roles.DISTRIBUTOR, roles.DISTRIBUTORSELLER],
        bypassScope: userIsDistributorNotInsurer,
    },
    {
        id: 'route-insurance-policies',
        path: ['/insurance/policies', '/insurance/policies/app/:app_id'],
        exact: true,
        header: {
            visible: true,
            section: 'insurance',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'insurance',
        },
        component: withAuth(InsurancePoliciesPage),
        'data-test': 'RouteInsurancePoliciesPage',
        scope: [roles.INSURER, roles.DISTRIBUTOR, roles.DISTRIBUTORSELLER],
        bypassScope: userIsDistributorNotInsurer,
    },
    {
        id: 'route-insurance-policies-app',
        path: '/insurance/policies/:id/app/:app_id',
        exact: true,
        header: {
            visible: true,
            section: 'insurance',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'insurance',
        },
        component: withAuth(InsurancePolicyPage),
        'data-test': 'RouteInsurancePolicyPage',
        scope: [roles.INSURER, roles.DISTRIBUTOR, roles.DISTRIBUTORSELLER],
        bypassScope: userIsDistributorNotInsurer,
    },
    {
        id: 'route-insurance-claims',
        path: ['/insurance/claims', '/insurance/claims/app/:app_id'],
        exact: true,
        header: {
            visible: true,
            section: 'insurance',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'insurance',
        },
        component: withAuth(InsuranceClaimsPage),
        'data-test': 'RouteInsuranceClaimsPage',
        scope: [roles.INSURER, roles.DISTRIBUTOR, roles.DISTRIBUTORSELLER],
        bypassScope: userIsDistributorNotInsurer,
    },
    {
        id: 'route-insurance-claims-app',
        path: '/insurance/claims/:id/app/:app_id',
        exact: true,
        header: {
            visible: true,
            section: 'insurance',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'insurance',
        },
        component: withAuth(InsuranceClaimPage),
        'data-test': 'RouteInsuranceClaimPage',
        scope: [roles.INSURER, roles.DISTRIBUTOR, roles.DISTRIBUTORSELLER],
        bypassScope: userIsDistributorNotInsurer,
    },
    {
        id: 'route-insurance-quotes',
        path: ['/insurance/quotes', '/insurance/quotes/app/:app_id'],
        exact: true,
        header: {
            visible: true,
            section: 'insurance',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'insurance',
        },
        component: withAuth(InsuranceQuotesPage),
        'data-test': 'RouteInsuranceQuotesPage',
        scope: [roles.INSURER, roles.DISTRIBUTOR, roles.DISTRIBUTORSELLER],
        bypassScope: userIsDistributorNotInsurer,
    },
    {
        id: 'route-insurance-quotes-app',
        path: '/insurance/quotes/:id/app/:app_id',
        exact: true,
        header: {
            visible: true,
            section: 'insurance',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'insurance',
        },
        component: withAuth(InsuranceQuotePage),
        'data-test': 'RouteInsuranceQuotePage',
        scope: [roles.INSURER, roles.DISTRIBUTOR, roles.DISTRIBUTORSELLER],
        bypassScope: userIsDistributorNotInsurer,
    },
    {
        id: 'route-insurance-assets',
        path: ['/insurance/assets', '/insurance/assets/app/:app_id'],
        exact: true,
        header: {
            visible: true,
            section: 'insurance',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'insurance',
        },
        component: withAuth(InsuranceAssetsPage),
        'data-test': 'RouteInsuranceAssetsPage',
        scope: [roles.INSURER, roles.DISTRIBUTOR, roles.DISTRIBUTORSELLER],
        bypassScope: userIsDistributorNotInsurer,
    },
    {
        id: 'route-insurance-assets-app',
        path: '/insurance/assets/:id/app/:app_id',
        exact: true,
        header: {
            visible: true,
            section: 'insurance',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'insurance',
        },
        component: withAuth(InsuranceAssetPage),
        'data-test': 'RouteInsuranceAssetPage',
        scope: [roles.INSURER, roles.DISTRIBUTOR, roles.DISTRIBUTORSELLER],
        bypassScope: userIsDistributorNotInsurer,
    },
    {
        id: 'route-insurance-payments-documents',
        path: ['/insurance/payments-documents', '/insurance/payments-documents/app/:app_id'],
        exact: true,
        header: {
            visible: true,
            section: 'insurance',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'insurance',
        },
        component: withAuth(InsurancePaymentsDocumentsPage),
        'data-test': 'RouteInsurancePaymentsDocumentsPage',
        scope: [roles.INSURER, roles.DISTRIBUTOR, roles.DISTRIBUTORSELLER],
        bypassScope: userIsDistributorNotInsurer,
    },
    {
        id: 'route-insurance-payments-documents-app',
        path: '/insurance/payments-documents/:id/app/:app_id',
        exact: true,
        header: {
            visible: true,
            section: 'insurance',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'insurance',
        },
        component: withAuth(InsurancePaymentDocumentPage),
        'data-test': 'RouteInsurancePaymentDocumentPage',
        scope: [roles.INSURER, roles.DISTRIBUTOR, roles.DISTRIBUTORSELLER],
        bypassScope: userIsDistributorNotInsurer,
    },
];
