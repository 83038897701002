import { stringToHash } from 'services/roles';

/**
 * NOTE: the "he" and "hr" keys are used to validate the session and are created on the login success thunk
 */
export const isUserAuthenticated = () => {
    const session = getSession();
    const isAuthenticated =
        session.access_token &&
            session.access_token !== '' &&
            session.access_token !== 'undefined' &&
            session.user &&
            session.user.hasOwnProperty('id') &&
            session.user.id !== '' &&
            session.user.hasOwnProperty('email') &&
            session.user.email !== '' &&
            session.user.hasOwnProperty('he') &&
            session.user.he === stringToHash(session.user.email) &&
            session.user.hasOwnProperty('hr') &&
            session.user.hr === stringToHash(session.user.roles) &&
            session.endpoints &&
            session.endpoints.hasOwnProperty('http') &&
            session.endpoints.hasOwnProperty('uploader')
            ? true
            : false;

    return isAuthenticated;
};

export const getAccessToken = () => {
    return localStorage.getItem('access_token');
};

export const getRefreshToken = () => {
    return localStorage.getItem('refresh_token');
};

export const setAccessToken = access_token => {
    localStorage.setItem('access_token', access_token);
};

export const setRefreshToken = refresh_token => {
    localStorage.setItem('refresh_token', refresh_token);
};

export const setAuthTokens = (access_token, refresh_token) => {
    setAccessToken(access_token);
    setRefreshToken(refresh_token);
};

export const setUserInfo = async user => {
    window.localStorage.removeItem('user');
    localStorage.setItem('user', JSON.stringify(user));
};

/**
 * @returns {User}
 */
export const getUserInfo = () => {
    return JSON.parse(localStorage.getItem('user'));
};

export const getUserRole = () => {
    const user = getUserInfo();
    return user && user.hasOwnProperty('roles') && user.roles ? user.roles : false;
};

export const setSession = (access_token, refresh_token) => {
    setAccessToken(access_token);
    setRefreshToken(refresh_token);
    setUserInfo();
};

export const getSession = () => {
    const session = {
        access_token: getAccessToken(),
        refresh_token: getRefreshToken(),
        user: getUserInfo(),
        endpoints: getEndpoints(),
    };

    return session;
};

export const deleteSession = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
    localStorage.removeItem('endpoint');
    localStorage.removeItem('endpoints');
    localStorage.removeItem('user_visited_places_alert');
    localStorage.removeItem('impersonate');
    return;
};

export const logout = () => {
    deleteSession();
    window.location.href = '/';
};

export const redirectToLogout = () => {
    window.location.href = '/logout';
};

export const getViewportAlert = () => {
    return localStorage.getItem('viewport_alert');
};

export const setViewportAlert = () => {
    localStorage.setItem('viewport_alert', true);
};

export const setEndpoints = endpoints => {
    localStorage.setItem('endpoints', JSON.stringify(endpoints));
};

export const getEndpoints = () => {
    return JSON.parse(localStorage.getItem('endpoints'));
};

export const setAppSettings = data => {
    const defaultSettings = {
        board: {
            integrations: false,
        },
    };

    const settings = data ? data : defaultSettings;
    localStorage.setItem('settings', JSON.stringify(settings));
};

export const getAppSettings = () => {
    if (!localStorage.getItem('settings')) {
        setAppSettings();
    }
    return JSON.parse(localStorage.getItem('settings'));
};

export const getUserVisitedPlacesAlert = () => {
    return localStorage.getItem('user_visited_places_alert');
};

export const setUserVisitedPlacesAlert = () => {
    localStorage.setItem('user_visited_places_alert', true);
};
