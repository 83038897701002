import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './TimelineList.module.scss';

const TimelineItem = ({ data, onSelect, selected }) => {
    let size = '';
    if (data['24h_percentage']) {
        size = data['24h_percentage'] * 100;
    } else {
        size = Math.round(Math.random() * 100);
    }

    const style = {
        width: `${size}%`,
    };

    return (
        <li
            className={classnames(Styles.item, selected ? Styles.selected : null)}
            onClick={onSelect}
            data-id={data.place_id}
        >
            <div className={Styles.icon}>
                {data.icon && <i className={data.icon}></i>}

                {!data.icon && <span className={Styles.noIcon}></span>}

                <span className={Styles.vLine}></span>
            </div>

            <div className={Styles.details}>
                <p className={Styles.title}>
                    {data.street_name && data.street_name.trim() !== '' ? data.street_name : `N/D`}
                </p>
                <p className={Styles.time}>
                    {data.total_duration && data.total_duration.trim() !== ''
                        ? data.total_duration
                        : `N/D`}
                </p>
                <p className={Styles.bar} style={style}></p>
            </div>
        </li>
    );
};

TimelineItem.propTypes = {
    data: PropTypes.object,
    onSelect: PropTypes.func,
    selected: PropTypes.bool,
};

export class TimelineList extends React.PureComponent {
    static propTypes = {
        data: PropTypes.array,
        onSelect: PropTypes.func,
        selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    };

    handleOnSelect = e => {
        const id = e.currentTarget.getAttribute('data-id');
        const place = this.props.data.filter(place => place.place_id.toString() === id);
        this.props.onSelect(place);
    };

    render() {
        const { data, selected } = this.props;

        return (
            <ul className={Styles.container}>
                {data &&
                    data.length > 0 &&
                    data.map((item, index) => (
                        <TimelineItem
                            key={item.place_id ? item.place_id : index}
                            data={item}
                            onSelect={item.place_id ? this.handleOnSelect : null}
                            selected={
                                selected && selected.toString() === item.place_id.toString()
                                    ? true
                                    : false
                            }
                        />
                    ))}
            </ul>
        );
    }
}

export default TimelineList;
