import languages from 'locales';
import enJSONreducer from 'redux/store/languages/en.json';

type EN_REDUCER_KEYS = keyof typeof enJSONreducer;

export type AVAILABLE_KEYS_FROM_REDUCER_LANGUAGES = EN_REDUCER_KEYS;

export type AVAILABLE_VALUES_FROM_REDUCER_KEYS_LANGUAGES =
    | (typeof enJSONreducer)[AVAILABLE_KEYS_FROM_REDUCER_LANGUAGES];

export const DEFAULT_LANGUAGE = 'pt';

export const SHOULD_SHOW_LANGUAGE_SELECTOR = true;

/**
 * @returns {Array} of available languages
 * @example
 * ["pt", "en", "es"]
 */
export const AVAILABLE_LANGUAGES = languages ? Object.keys(languages) : [];

/** 
    @returns {"pt" as default, "en" from AVAILABLE_LANGUAGES, "es" from AVAILABLE_LANGUAGES}
*/
export const getLocalStorageLanguage = (): string => {
    return localStorage.getItem('language');
};

const isLanguageValid = (lang: string) => {
    return AVAILABLE_LANGUAGES.includes(lang);
};

/** 
    @returns {"pt" as default, "en" from AVAILABLE_LANGUAGES, "es" from AVAILABLE_LANGUAGES}
*/
const getNavigatorLanguage = () => {
    if (
        navigator.language?.split('-')[0] &&
        typeof navigator.language?.split('-')[0] === 'string'
    ) {
        return navigator.language?.split('-')[0].toLowerCase();
    }
};

/**
 * @returns {"pt" as default, "en" from AVAILABLE_LANGUAGES, "es" from AVAILABLE_LANGUAGES}
 * @description
 * 1. Check if valid language is in localStorage
 * 2. Check if valid language is in navigator
 * 3. Return DEFAULT_LANGUAGE
 * */
const currentLanguage = isLanguageValid(getLocalStorageLanguage())
    ? getLocalStorageLanguage()
    : isLanguageValid(getNavigatorLanguage())
    ? getNavigatorLanguage()
    : DEFAULT_LANGUAGE;

if (!localStorage.getItem('language')) {
    localStorage.setItem('language', currentLanguage);
}

export const getCurrentLanguage = () => {
    return currentLanguage;
};

export const doesCurrentKeyExistsInCurrentLanguage = (key: any): boolean => {
    let wordThatExistsInCurrentLanguage = languages?.[currentLanguage]?.[key];
    if (!wordThatExistsInCurrentLanguage) {
        if (process.env.NODE_ENV === 'development') {
            console.log(
                `The key "${key}" does not exist in the current language "${currentLanguage}"`,
            );
        }
    }
    return !!wordThatExistsInCurrentLanguage;
};

/**
 *
 * @description This function is used to get the text from the current language. The keys can be found in the locales folder: src/locales
 * @param key {string} - The key that you want to get the text from
 */
export const getText = key => {
    if (isLanguageValid(currentLanguage) && languages[currentLanguage]) {
        let wordThatExistsInCurrentLanguage = languages[currentLanguage][key];
        let wordThatExistsInDefaultLanguage = languages[DEFAULT_LANGUAGE][key];
        let wordThatExistsInTheReducer = enJSONreducer[
            key
        ] as AVAILABLE_KEYS_FROM_REDUCER_LANGUAGES;

        if (wordThatExistsInCurrentLanguage) {
            return wordThatExistsInCurrentLanguage;
        } else if (wordThatExistsInDefaultLanguage) {
            return wordThatExistsInDefaultLanguage;
        } else if (wordThatExistsInTheReducer) {
            let shouldLog = false;
            if (process.env.NODE_ENV === 'development' && shouldLog) {
                console.log(`The key "${key}" should be updated to the new JSON format!`);
            }
            return wordThatExistsInTheReducer;
        } else {
            let shouldThrow = false;
            if (shouldThrow && process.env.NODE_ENV === 'development') {
                throw new Error(`The key "${key}" does not exist in the current language`);
            }
            return key;
        }
    }
};
