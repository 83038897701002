import classnames from 'classnames';
import { getText } from 'localization';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import ValidationStatus from 'shared/ValidationStatus';
import Styles from './UploadButton.module.scss';

export class UploadButton extends React.PureComponent {
    myRef = React.createRef();

    static propTypes = {
        texts: PropTypes.object,
        name: PropTypes.string,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        onClick: PropTypes.func,
        onDelete: PropTypes.func,
        types: PropTypes.string,
        file: PropTypes.string,
        namespace: PropTypes.string,
        withValidation: PropTypes.bool,
        isValid: PropTypes.bool,
        unsaved: PropTypes.bool,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        ReactTooltip.hide();
        ReactTooltip.rebuild();
    }

    handleOnClick = e => {
        this.myRef.current.click();
    };

    handleOnDelete = e => {
        this.myRef.current.value = '';
        this.props.onDelete(e);
    };

    render() {
        const {
            name,
            types,
            disabled,
            onChange,
            file,
            namespace,
            withValidation,
            isValid,
            unsaved,
        } = this.props;

        return (
            <div data-testid="UploadButton" className={Styles.upload}>
                <input
                    data-testid="UploadButtonInput"
                    data-namespace={namespace}
                    ref={this.myRef}
                    type="file"
                    name={name}
                    onChange={onChange}
                    accept={types}
                    disabled={disabled}
                    aria-disabled={disabled}
                />

                {!file && (
                    <button
                        data-testid="UploadButtonWithoutFile"
                        data-namespace={namespace}
                        className={Styles.withoutFile}
                        disabled={disabled}
                        aria-disabled={disabled}
                        onClick={this.handleOnClick}
                        type="button"
                    >
                        {getText('upload')}
                    </button>
                )}

                {file && (
                    <>
                        <button
                            data-testid="UploadButtonWithFile"
                            data-namespace={namespace}
                            data-tip={file}
                            className={Styles.withFile}
                            title={file}
                            disabled={disabled}
                            aria-disabled={disabled}
                            onClick={this.handleOnClick}
                            type="button"
                        >
                            {file}
                        </button>

                        <i
                            data-testid="UploadButtonDelete"
                            data-namespace={namespace}
                            data-tip="Clear"
                            className={classnames(
                                'icon-icon_delete_2',
                                disabled ? Styles.disabled : null,
                            )}
                            onClick={this.handleOnDelete}
                        ></i>
                    </>
                )}

                {withValidation && !unsaved && !disabled && isValid && (
                    <ValidationStatus status="success" />
                )}

                {withValidation && !unsaved && !disabled && !isValid && (
                    <ValidationStatus status="error" />
                )}
            </div>
        );
    }
}

export default UploadButton;
