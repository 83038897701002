import classnames from 'classnames';
import { getText } from 'localization';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { DateRangePicker, isInclusivelyBeforeDay, isSameDay } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Button from 'shared/Button';
import Styles from './DateRange.module.scss';
import './DateRange.scss';

const CustomInputIcon = () => {
    return <i className={classnames('icon-icon_calendar', Styles.calendarIcon)}></i>;
};

const CustomArrowIcon = () => {
    return <span>-</span>;
};

const CustomCloseIcon = ({ onClick }) => {
    return <i className={classnames('icon-icon_delete_2', Styles.closeIcon)} onClick={onClick}></i>;
};

CustomCloseIcon.propTypes = {
    onClick: PropTypes.func,
};

export class DateRange extends React.PureComponent {
    state = {
        visibleMonths: 3,
        startDate: null,
        endDate: null,
        focusedInput: null,
    };

    static propTypes = {
        autoFocus: PropTypes.bool,
        autoFocusEndDate: PropTypes.bool,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        required: PropTypes.bool,
        data: PropTypes.object,
        onClear: PropTypes.func,
        allDates: PropTypes.bool,
    };

    static defaultProps = {
        autoFocus: false,
        autoFocusEndDate: false,
        disabled: false,
        required: false,
        allDates: false,
    };

    componentDidMount() {
        if (this.props.data) {
            this.setState({
                startDate: moment(this.props.data.start),
                endDate: moment(this.props.data.end),
            });
        }
    }

    onDatesChange = ({ startDate, endDate }) => {
        this.setState({
            startDate,
            endDate,
        });
    };

    onFocusChange = focusedInput => {
        this.setState({
            focusedInput,
        });
    };

    onClose = ({ startDate, endDate }) => {
        this.props.onChange({
            start: startDate ? startDate.format('YYYY-MM-DD') : null,
            end: endDate ? endDate.format('YYYY-MM-DD') : null,
        });
    };

    onClearDates = () => {
        this.setState(
            {
                startDate: null,
                endDate: null,
            },
            () => {
                this.props.onClear &&
                    typeof this.props.onClear === 'function' &&
                    this.props.onClear({
                        startDate: null,
                        endDate: null,
                    });
            },
        );
    };

    onApplyDates = () => {
        this.setState(
            {
                focusedInput: null,
            },
            () => {
                this.props.onChange({
                    start: this.state.startDate ? this.state.startDate.format('YYYY-MM-DD') : null,
                    end: this.state.endDate ? this.state.endDate.format('YYYY-MM-DD') : null,
                });
            },
        );
    };

    initialVisibleMonth = () => {
        if (this.props.allDates) {
            return moment().subtract(this.state.visibleMonths - 2, 'month');
        }

        return moment().subtract(this.state.visibleMonths - 1, 'month');
    };

    renderDatePresets = () => {
        const today = moment();
        const yesterday = moment().subtract(1, 'day');
        let presets = [
            {
                id: 0,
                text: getText('shared_date_range_today_label'),
                start: today,
                end: today,
            },
            {
                id: 1,
                text: getText('shared_date_range_yesterday_label'),
                start: yesterday,
                end: yesterday,
            },
            {
                id: 2,
                text: getText('shared_date_range_last_week_label'),
                start: moment().subtract(1, 'week'),
                end: today,
            },
            {
                id: 3,
                text: getText('shared_date_range_last_month_label'),
                start: moment().subtract(1, 'month'),
                end: today,
            },
            {
                id: 4,
                text: getText('shared_date_range_last_3_months_label'),
                start: moment().subtract(3, 'month'),
                end: today,
            },
            {
                id: 5,
                text: getText('shared_date_range_last_year_label'),
                start: moment().subtract(12, 'month'),
                end: today,
            },
        ];

        if (this.props.allDates) {
            presets = [
                {
                    id: 0,
                    text: getText('shared_date_range_today_label'),
                    start: today,
                    end: today,
                },
                {
                    id: 1,
                    text: getText('shared_date_range_yesterday_label'),
                    start: yesterday,
                    end: yesterday,
                },
                {
                    id: 2,
                    text: getText('shared_date_range_last_month_label'),
                    start: moment().subtract(1, 'month'),
                    end: today,
                },
                {
                    id: 3,
                    text: getText('shared_date_range_next_month_label'),
                    start: today,
                    end: moment().add(1, 'month'),
                },
                {
                    id: 4,
                    text: getText('shared_date_range_last_year_label'),
                    start: moment().subtract(12, 'month'),
                    end: today,
                },
                {
                    id: 5,
                    text: getText('shared_date_range_next_year_label'),
                    start: today,
                    end: moment().add(12, 'month'),
                },
            ];
        }

        const { startDate, endDate } = this.state;

        return (
            <div className={Styles.presetsContainer}>
                <div className={Styles.clear}>
                    <Button
                        type="button"
                        theme="form"
                        variant="none"
                        value={getText('common_clear')}
                        onClick={this.onClearDates}
                    />
                </div>
                <div className={Styles.presets}>
                    {presets.map(({ id, text, start, end }) => {
                        const isSelected = isSameDay(start, startDate) && isSameDay(end, endDate);

                        return (
                            <Button
                                key={id}
                                type="button"
                                theme="form"
                                variant="none"
                                state={isSelected ? 'selected' : null}
                                value={text}
                                onClick={() =>
                                    this.onDatesChange({ startDate: start, endDate: end })
                                }
                            />
                        );
                    })}
                </div>
                <div className={Styles.apply}>
                    <Button
                        theme="form"
                        type="button"
                        value={getText('shared_datapicker_apply_label')}
                        disabled={!this.state.startDate || !this.state.endDate}
                        onClick={this.onApplyDates}
                    />
                </div>
            </div>
        );
    };

    isOutsideRange = day => {
        if (this.props.allDates) {
            return false;
        }

        return !isInclusivelyBeforeDay(day, moment());
    };

    render() {
        const { startDate, endDate, focusedInput, visibleMonths } = this.state;
        const { disabled, required } = this.props;

        const isActive = focusedInput || (startDate && endDate) ? true : false;
        const mustFill = required && !startDate && !endDate ? true : false;

        return (
            <div
                data-test="DateRangeContainer"
                className={classnames(
                    isActive ? Styles?.active : null,
                    mustFill ? Styles.required : null,
                    disabled ? Styles.disabled : null,
                )}
            >
                <DateRangePicker
                    renderCalendarInfo={this.renderDatePresets}
                    startDate={startDate}
                    startDateId="calendarStartDate"
                    endDate={endDate}
                    endDateId="calendarEndDate"
                    onDatesChange={this.onDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={this.onFocusChange}
                    disabled={disabled}
                    showDefaultInputIcon={true}
                    inputIconPosition={`before`}
                    customInputIcon={<CustomInputIcon />}
                    customArrowIcon={<CustomArrowIcon />}
                    customCloseIcon={<CustomCloseIcon onClick={this.onClearDates} />}
                    keepOpenOnDateSelect={true}
                    hideKeyboardShortcutsPanel={true}
                    showClearDates={true}
                    isOutsideRange={this.isOutsideRange}
                    numberOfMonths={visibleMonths}
                    initialVisibleMonth={this.initialVisibleMonth}
                    displayFormat={focusedInput ? 'DD/MM/YYYY' : 'MMMM Do YYYY'}
                    firstDayOfWeek={1}
                />
            </div>
        );
    }
}

export default DateRange;
