import ActionTypes from 'redux/actionTypes';
import rootInitialState from 'redux/store/initialState';
import { generateUUID } from 'utils/functions';

export const initialState = {
    eventsSimulator: rootInitialState.eventsSimulator,
};

const reducer = (state = rootInitialState, action) => {
    switch (action.type) {
        case ActionTypes.EVENTS_SIMULATOR_SEARCH_USER:
            return searchUser(state, action);
        case ActionTypes.EVENTS_SIMULATOR_CREATE_NEW:
            return createSimulator(state);
        case ActionTypes.EVENTS_SIMULATOR_DELETE_EVENT:
            return deleteEvent(state, action);
        case ActionTypes.EVENTS_SIMULATOR_SELECT_DEVICE:
            return selectDevice(state, action);
        case ActionTypes.EVENTS_SIMULATOR_DEVICE_PROPERTIES:
            return deviceProperties(state, action);
        default:
            return state;
    }
};

/**
 * Search User
 * @param {*} state
 * @param {*} action
 */
const searchUser = (state, action) => {
    const user = action.payload.user;
    const id = action.payload.id;

    const output = state.map(event => {
        if (event.id === id) {
            return {
                ...event,
                user: {
                    value: user,
                    match: user !== '' ? true : false,
                },
            };
        }
        return event;
    });

    return [...output];
};

/**
 * Delete Event
 * @param {*} state
 * @param {*} action
 */
const deleteEvent = (state, action) => {
    const payload = action.payload.data;

    const output = state.filter(event => event.id !== payload.id);

    if (output.length === 0) {
        const simulator = generateFakeSimulator(state, true);

        return [simulator];
    }

    return [...output];
};

/**
 * Select Device
 * @param {*} state
 * @param {*} action
 */
const selectDevice = (state, action) => {
    const payload = action.payload.data;

    const output = state.map(event => {
        if (event.id === payload.eventId) {
            const device = getDeviceById(event, payload.id);

            return {
                ...event,
                selectedDevice: payload.id,
                deviceProperties: {
                    deviceId: payload.id,
                    properties: device.properties,
                },
            };
        }
        return event;
    });

    return [...output];
};

/**
 * Get Device By ID
 * @param {*} state
 * @param {*} id
 */
const getDeviceById = (state, id) => {
    const index = state.devices.findIndex(i => {
        return i.id === id;
    });

    if (index !== -1) {
        return state.devices[index];
    }

    return null;
};

/**
 * Device Properties
 * @param {*} state
 * @param {*} action
 */
const deviceProperties = (state, action) => {
    const payload = action.payload.data;

    const output = state.map(event => {
        if (event.id === payload.eventId) {
            return {
                ...event,
                deviceProperties: {
                    ...event.deviceProperties,
                    properties: event.deviceProperties.properties.map(property => {
                        if (property.id === payload.data.propertyId) {
                            return {
                                ...property,
                                value: payload.data.value,
                            };
                        }

                        return property;
                    }),
                },
            };
        }
        return event;
    });

    return [...output];
};

const generateFakeSimulator = (state, reset) => {
    const id = generateUUID();

    const eventTemplate = {
        id: `temp-${id}`,
        mode: 'edit',
        user: {
            value: '',
            match: false,
        },
        selectedDevice: null,
        deviceProperties: {
            deviceId: null,
            properties: [],
        },
        devices: [
            {
                id: '5060d1bb-9f0d-4db2-8309-39d11946e647-0',
                name: 'Nest Camera',
                image: 'https://store.nest.com/assets/images/social/open-graph/nest-cam-iq.jpg',
                properties: [
                    {
                        id: 'f4a0fd4e-0e54-48d9-bbe0-1734cf2749db-0',
                        name: 'Video/motion',
                        value: 'on',
                    },
                    {
                        id: 'f4a0fd4e-0e54-48d9-bbe0-1734cf2749db-1',
                        name: 'Audio',
                        value: 'off',
                    },
                ],
            },
            {
                id: '5060d1bb-9f0d-4db2-8309-39d11946e647-1',
                name: 'Nest Smoke Detector',
                image: 'https://store.nest.com/assets/images/social/open-graph/smoke-co-alarm.jpg',
                properties: [
                    {
                        id: 'f5a87a38-1327-41ce-abd2-dd97c2a0bdf0-0',
                        name: 'Smoke detector',
                        value: 'off',
                    },
                    {
                        id: 'f5a87a38-1327-41ce-abd2-dd97c2a0bdf0-1',
                        name: 'Audio alarm',
                        value: 'on',
                    },
                ],
            },
        ],
    };

    return eventTemplate;
};

/**
 * Create Flow
 * @param {*} state
 * @param {*} action
 */
const createSimulator = (state, action) => {
    const eventTemplate = generateFakeSimulator(state);

    return [...state, eventTemplate];
};

export default reducer;
