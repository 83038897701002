import rootInitialState from 'redux/store/initialState';
import ActionTypes from 'redux/actionTypes';

export const initialState = {
    current: rootInitialState.navigation.current,
    routes: rootInitialState.navigation.routes,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.NAVIGATION_MANAGE_SIDEBAR_ROUTES:
            return manageRoutes(state, action);

        default:
            return state;
    }
};

const manageRoutes = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        routes: {
            ...state.routes,
            [payload.section]: [...payload.routes],
        },
    };
};

// const manageRoutes = (state, action) => {
//     const payload = action.payload.data;
//     let newRoutes = [];

//     Object.entries(state.routes[payload.section]).forEach(([key, value]) => {
//         const temp = value.list.map(el => {
//             if (!el.static && el.disabled && payload.action === 'unlock') {
//                 return {
//                     ...el,
//                     disabled: false,
//                 };
//             }

//             return el;
//         });

//         newRoutes = [
//             ...newRoutes,
//             {
//                 ...value,
//                 list: [...temp],
//             },
//         ];
//     });

//     return {
//         ...state,
//         routes: {
//             ...state.routes,
//             [payload.section]: [...newRoutes],
//         },
//     };
// };

export default reducer;
