import React from 'react';
import PropTypes from 'prop-types';
import Styles from './NivoLineChart.module.scss';
import { ResponsiveLine } from '@nivo/line';
import { fakeEmptyData } from './__MOCKS__';
import { DEFAULT_ALERT_ERROR_MESSAGE } from 'utils/constants';

const theme = {
    axis: {
        ticks: {
            line: {
                stroke: '#697da4',
            },
            text: {
                fill: '#2d426b',
            },
        },
    },
    grid: {
        line: {
            stroke: '#c7d4e0',
            strokeWidth: 1,
            strokeDasharray: '4 4',
        },
    },
    tooltip: {
        container: {
            background: 'white',
        },
    },
};

export class NivoLineChart extends React.PureComponent {
    state = {
        chartData: null,
        max: 100,
    };

    static propTypes = {
        data: PropTypes.array,
        color: PropTypes.string,
    };

    static defaultProps = {
        color: '#f9bb00',
    };

    componentDidMount() {
        const { data } = this.props;

        if (!data) {
            this.setState({ chartData: fakeEmptyData });
        }

        if (data && data[0].data.length > 0) {
            this.setState({ chartData: data, max: data[0].max });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { data } = this.props;

        if (
            data &&
            data[0].data.length > 0 &&
            JSON.stringify(prevProps.data) !== JSON.stringify(data)
        ) {
            this.setState({ chartData: data, max: data[0].max });
        }

        if (!data) {
            this.setState({ chartData: fakeEmptyData, max: 100 });
        }
    }

    render() {
        const { data } = this.props;
        const { chartData, max } = this.state;
        const hasData = data && data[0].data.length > 0 ? true : false;
        const color = this.props.color ? this.props.color : '#f9bb00';

        return (
            <div data-testid="NivoLineChart" className={Styles.chart}>
                {!chartData && <p className={Styles.error}>{DEFAULT_ALERT_ERROR_MESSAGE}</p>}

                {chartData && (
                    <ResponsiveLine
                        data={chartData}
                        isInteractive={hasData}
                        margin={{ top: 10, right: 30, bottom: 40, left: 40 }}
                        xScale={{ type: 'point' }}
                        yScale={{
                            type: 'linear',
                            stacked: true,
                            min: hasData ? 'auto' : 0,
                            max: hasData ? 'auto' : 100,
                        }}
                        curve="basis"
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            orient: 'bottom',
                            // tickSize: 5,
                            tickPadding: 0,
                            tickRotation: 0,
                            format: value => `${value}`,
                            tickValues: data && data[0].data.length > 0 ? data[0].data.length : 0,
                        }}
                        axisLeft={{
                            tickValues: [0, hasData ? max : 100],
                            orient: 'left',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                        }}
                        enableGridY={false}
                        colors={[color]}
                        enablePoints={false}
                        enableSlices="x"
                        enableArea={true}
                        useMesh={true}
                        theme={theme}
                        // tooltip={function ({ point }) {
                        //     console.log('NivoLineChart -> render -> e', point);
                        //     return `Date: ${point.data.x} - ${point.data.y}`;
                        // }}
                        // tooltip={({ id, value, color, point }) => (
                        //     <p style={{ backgroundColor: 'white' }}>
                        //         {`Date: ${point.data.x} - ${point.data.y}`}
                        //     </p>
                        // )}
                        sliceTooltip={({ slice }) => {
                            return (
                                <div className={Styles.tooltip}>
                                    {slice.points.map(point => (
                                        <div key={point.id} className={Styles.tooltipPoint}>
                                            <p>
                                                <strong>{point.data.dateLabel}:</strong>{' '}
                                                {point.data.date}
                                            </p>
                                            <p>
                                                <strong>{point.data.valueLabel}:</strong>{' '}
                                                {point.data.y}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            );
                        }}
                    />
                )}
            </div>
        );
    }
}

export default NivoLineChart;
