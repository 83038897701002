import React from 'react';
import PropTypes from 'prop-types';
import Styles from './Styles.module.scss';
import { DEFAULT_ALERT_ERROR_MESSAGE } from 'utils/constants';

const Error = ({ text, size }) => {
    return (
        <div data-testid="Container" className={`${Styles.container} ${Styles[size]}`}>
            <i className="icon-icon_alert"></i>
            <p>{text || DEFAULT_ALERT_ERROR_MESSAGE}</p>
        </div>
    );
};

Error.propTypes = {
    text: PropTypes.string,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
};

Error.defaultProps = {
    text: DEFAULT_ALERT_ERROR_MESSAGE,
    size: 'lg',
};

export default Error;
