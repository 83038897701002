// import { lazy } from 'react';
import withAuth from 'hoc/withAuth';
import { roles } from 'services/roles';

import NetworksDashboardPage from 'redux/containers/Networks/Dashboard';
import NetworksEntityDirectoriesPage from 'redux/containers/Networks/EntityDirectories';
import NetworksEntityDirectoryPage from 'redux/containers/Networks/EntityDirectory';
import NetworksServicesItemsDirectoriesPage from 'redux/containers/Networks/ServicesItemsDirectories';
import NetworksServiceDirectoryPage from 'redux/containers/Networks/ServiceDirectory';
import NetworksItemDirectoryPage from 'redux/containers/Networks/ItemDirectory';
import NetworksNetworksPage from 'redux/containers/Networks/Networks';
import NetworksNetworkPage from 'redux/containers/Networks/Network';

export const networksRoutes = [
    {
        id: 'route-networks-management-dashboard',
        path: '/networks-management/dashboard',
        exact: true,
        header: {
            visible: true,
            section: 'networks-management',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'networks-management',
        },
        component: withAuth(NetworksDashboardPage),
        'data-test': 'RouteNetworksDashboardPage',
        scope: [roles.ADMIN, roles.INSURER],
    },
    {
        id: 'route-networks-management-entities',
        path: '/networks-management/entities',
        exact: true,
        header: {
            visible: true,
            section: 'networks-management',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'networks-management',
        },
        component: withAuth(NetworksEntityDirectoriesPage),
        'data-test': 'RouteNetworksEntityDirectoriesPage',
        scope: [roles.ADMIN, roles.INSURER],
    },
    {
        id: 'route-networks-management-entities-id',
        path: '/networks-management/entities/:id',
        exact: true,
        header: {
            visible: true,
            section: 'networks-management',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'networks-management',
        },
        component: withAuth(NetworksEntityDirectoryPage),
        'data-test': 'RouteNetworksEntityDirectoryPage',
        scope: [roles.ADMIN, roles.INSURER],
    },
    {
        id: 'route-networks-management-networks',
        path: '/networks-management/networks',
        exact: true,
        header: {
            visible: true,
            section: 'networks-management',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'networks-management',
        },
        component: withAuth(NetworksNetworksPage),
        'data-test': 'RouteNetworksNetworksPage',
        scope: [roles.ADMIN, roles.INSURER],
    },
    {
        id: 'route-networks-management-services-items',
        path: '/networks-management/services-items',
        exact: true,
        header: {
            visible: true,
            section: 'networks-management',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'networks-management',
        },
        component: withAuth(NetworksServicesItemsDirectoriesPage),
        'data-test': 'RouteNetworksServicesItemsDirectoriesPage',
        scope: [roles.ADMIN, roles.INSURER],
    },
    {
        id: 'route-networks-management-services-id',
        path: '/networks-management/services/:id',
        exact: true,
        header: {
            visible: true,
            section: 'networks-management',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'networks-management',
        },
        component: withAuth(NetworksServiceDirectoryPage),
        'data-test': 'RouteNetworksServiceDirectoryPage',
        scope: [roles.ADMIN, roles.INSURER],
    },
    {
        id: 'route-networks-management-items-id',
        path: '/networks-management/items/:id',
        exact: true,
        header: {
            visible: true,
            section: 'networks-management',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'networks-management',
        },
        component: withAuth(NetworksItemDirectoryPage),
        'data-test': 'RouteNetworksItemDirectoryPage',
        scope: [roles.ADMIN, roles.INSURER],
    },
    {
        id: 'route-networks-management-networks-id',
        path: '/networks-management/networks/:id',
        exact: true,
        header: {
            visible: true,
            section: 'networks-management',
        },
        sidebar: {
            visible: true,
            disabled: false,
            section: 'networks-management',
        },
        component: withAuth(NetworksNetworkPage),
        'data-test': 'RouteNetworksNetworkPage',
        scope: [roles.ADMIN, roles.INSURER],
    },
];
