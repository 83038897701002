import classnames from 'classnames';
import React from 'react';
import Styles from './Paginator.module.scss';

const Paginator = ({
    isLoading,
    data: pagination,
    onClickPrev,
    onClickNext,
    onClickStart,
    onClickEnd,
    shouldHaveArrowToGoToLastPagination = true,
}) => {
    return (
        <div data-test="Paginator" className={Styles.paginator}>
            <div className={Styles.controls}>
                <i
                    className={classnames(
                        'icon-icon_arrow_newer_end',
                        pagination?.current <= 1 ? Styles.disabled : null,
                    )}
                    onClick={isLoading ? () => {} : pagination?.current > 1 ? onClickStart : null}
                ></i>
                <i
                    data-test="PaginatorPrev"
                    className={classnames(
                        'icon-icon_arrow_newer',
                        pagination?.current <= 1 ? Styles.disabled : null,
                    )}
                    onClick={isLoading ? () => {} : pagination?.current > 1 ? onClickPrev : null}
                ></i>
            </div>
            {/* <button onClick={() => {
                console.log(pagination);
            }}
            >
                Click me
            </button> */}
            <div data-test="PaginatorLabel" className={Styles.pages}>
                {pagination?.current} /{' '}
                {typeof pagination.total === 'number'
                    ? pagination.total
                    : pagination?.current && !pagination?.next
                    ? pagination?.current
                    : '...'}
            </div>
            <div className={Styles.controls}>
                <i
                    data-test="PaginatorNext"
                    className={classnames(
                        'icon-icon_arrow_older',
                        !pagination?.next ? Styles.disabled : null,
                    )}
                    onClick={isLoading ? () => {} : pagination?.next ? onClickNext : null}
                ></i>
            </div>
        </div>
    );
};
export default Paginator;
