import classnames from 'classnames';
import DirectoryTile from 'components/Networks/DirectoryTile';
import { getText } from 'localization';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import ShareModal from 'redux/containers/Networks/ShareModal';
import ErrorBoundary from 'shared/ErrorBoundary';
import Loading from 'shared/Loading';
import Popup from 'shared/Popup';
import SectionHeader from 'shared/SectionHeader';
import { getElementPosition } from 'utils/functions';
import Styles from './ServicesItemsDirectories.module.scss';

export class ServicesItemsDirectories extends React.PureComponent {
    state = {
        searchServices: '',
        searchItems: '',
        settings: {
            active: false,
            element: {},
            id: null,
        },
    };

    static propTypes = {
        texts: PropTypes.object,
        isLoading: PropTypes.bool,
        servicesDirectories: PropTypes.arrayOf(PropTypes.object),
        itemsDirectories: PropTypes.arrayOf(PropTypes.object),
        addServiceDirectory: PropTypes.func,
        addItemDirectory: PropTypes.func,
        onDeleteServiceDirectory: PropTypes.func,
        onDeleteItemDirectory: PropTypes.func,
        onDeleteDirectory: PropTypes.func,
        openPrompt: PropTypes.func,
        getDirectories: PropTypes.func,
        openShareModal: PropTypes.func,
        shareModal: PropTypes.bool,
    };

    componentDidMount() {
        this.props.getDirectories();
    }

    handleOnSearchServices = value => {
        this.setState({
            searchServices: value,
        });
    };

    handleOnSearchItems = value => {
        this.setState({
            searchItems: value,
        });
    };

    handleOnClearServices = () => {
        this.setState({
            searchServices: '',
        });
    };

    handleOnClearItems = () => {
        this.setState({
            searchItems: '',
        });
    };

    handleOnSubmit = e => {
        e.preventDefault();
    };

    handleToogleSettings = e => {
        e.preventDefault();
        const id = e.currentTarget.getAttribute('data-directory-id');
        const type = e.currentTarget.getAttribute('data-directory-type');

        this.setState({
            settings: {
                active: !this.state.settings?.active,
                element: getElementPosition(e.currentTarget),
                id: id,
                type: type,
            },
        });
    };

    handleClickSetting = e => {
        e.preventDefault();

        const action = e.currentTarget.getAttribute('data-action');
        const { settings } = this.state;

        switch (action) {
            case 'delete':
                const data = {
                    id: 'deleteDirectoryPrompt',
                    title: 'Alert',
                    content: getText('prompt_delete_directory'),
                    onConfirm: () =>
                        this.props.onDeleteDirectory({ id: settings.id, type: settings.type }),
                };

                this.props.openPrompt(data);
                break;

            case 'share':
                this.props.openShareModal({ id: settings.id, type: settings.type });
                break;

            default:
                break;
        }
    };

    handleCloseSettings = () => {
        this.setState({
            settings: {
                active: false,
                id: null,
            },
        });
    };

    render() {
        const {
            texts,
            servicesDirectories,
            itemsDirectories,
            isLoading,
            shareModal,
            addServiceDirectory,
            addItemDirectory,
        } = this.props;
        const { searchServices, searchItems, settings } = this.state;

        const servicesSearchResults =
            servicesDirectories &&
            servicesDirectories.length > 0 &&
            servicesDirectories.filter(
                directory =>
                    directory.name.toUpperCase().indexOf(searchServices.toUpperCase()) !== -1,
            );

        const itemsSearchResults =
            itemsDirectories &&
            itemsDirectories.length > 0 &&
            itemsDirectories.filter(
                directory => directory.name.toUpperCase().indexOf(searchItems.toUpperCase()) !== -1,
            );

        return (
            <ErrorBoundary>
                <article data-test="ServicesItemsDirectoriesContainer" className={Styles.container}>
                    <div className={Styles.section}>
                        <SectionHeader
                            data-test="ServicesItemsDirectoriesServicesSectionHeader"
                            title={getText('services')}
                            texts={texts}
                            action="search"
                            onClick={
                                servicesDirectories && servicesDirectories.length > 0
                                    ? addServiceDirectory
                                    : null
                            }
                            onSearch={
                                servicesDirectories && servicesDirectories.length > 0
                                    ? this.handleOnSearchServices
                                    : null
                            }
                            onClear={this.handleOnClearServices}
                            tooltipText={getText('create_directory')}
                            classes={
                                !servicesDirectories && servicesDirectories.length === 0
                                    ? Styles.textCenter
                                    : null
                            }
                        />

                        <section
                            className={classnames(
                                Styles.scrollContainer,
                                servicesDirectories && servicesDirectories.length > 1
                                    ? Styles.center
                                    : null,
                            )}
                        >
                            <Suspense fallback={<Loading theme="none" variant="sm" />}>
                                {isLoading && <Loading />}

                                {!isLoading &&
                                    servicesDirectories &&
                                    servicesDirectories.length === 0 && (
                                        <>
                                            <h3>
                                                <i className="icon-icon_about"></i>Add your services
                                                and list them in different directories.
                                            </h3>
                                            <div className={Styles.tiles}>
                                                <DirectoryTile
                                                    data-test="ServicesItemsDirectoriesServicesDirectoryTileAdd"
                                                    type="add"
                                                    size="lg"
                                                    text={getText('create_directory')}
                                                    onClick={addServiceDirectory}
                                                    isLoading={isLoading}
                                                />
                                            </div>
                                        </>
                                    )}

                                {!isLoading &&
                                    servicesDirectories &&
                                    servicesDirectories.length > 0 &&
                                    servicesSearchResults.length > 0 && (
                                        <div className={Styles.tiles}>
                                            {servicesSearchResults.map(dir => (
                                                <DirectoryTile
                                                    data-test="ServicesItemsDirectoriesServicesDirectoryTile"
                                                    key={dir.id}
                                                    type="view"
                                                    size="lg"
                                                    texts={texts}
                                                    directory={dir}
                                                    directoryType="services"
                                                    baseUrl={`/networks-management/services`}
                                                    onSettings={this.handleToogleSettings}
                                                />
                                            ))}
                                        </div>
                                    )}

                                {!isLoading &&
                                    servicesDirectories &&
                                    servicesDirectories.length > 0 &&
                                    servicesSearchResults.length === 0 && (
                                        <p
                                            data-test="ServicesItemsDirectoriesServicesNotFound"
                                            className={Styles.notFound}
                                        >
                                            {getText('no_search_results')}
                                        </p>
                                    )}
                            </Suspense>
                        </section>
                    </div>

                    <div className={Styles.section}>
                        <SectionHeader
                            data-test="ServicesItemsDirectoriesItemsSectionHeader"
                            title={getText('items')}
                            texts={texts}
                            action="search"
                            onClick={
                                itemsDirectories && itemsDirectories.length > 0
                                    ? addItemDirectory
                                    : null
                            }
                            onSearch={
                                itemsDirectories && itemsDirectories.length > 0
                                    ? this.handleOnSearchItems
                                    : null
                            }
                            onClear={this.handleOnClearItems}
                            tooltipText={getText('create_directory')}
                            classes={
                                !itemsDirectories && itemsDirectories.length === 0
                                    ? Styles.textCenter
                                    : null
                            }
                        />

                        <section
                            className={classnames(
                                Styles.scrollContainer,
                                itemsDirectories && itemsDirectories.length > 1
                                    ? Styles.center
                                    : null,
                            )}
                        >
                            <Suspense fallback={<Loading theme="none" variant="sm" />}>
                                {isLoading && <Loading />}

                                {!isLoading &&
                                    itemsDirectories &&
                                    itemsDirectories.length === 0 && (
                                        <>
                                            <h3>
                                                <i className="icon-icon_about"></i>Add your items
                                                and list them in different directories.
                                            </h3>

                                            <DirectoryTile
                                                data-test="ServicesItemsDirectoriesItemsDirectoryTileAdd"
                                                type="add"
                                                size="lg"
                                                text={getText('create_directory')}
                                                onClick={addItemDirectory}
                                                isLoading={isLoading}
                                            />
                                        </>
                                    )}

                                {!isLoading &&
                                    itemsDirectories &&
                                    itemsDirectories.length > 0 &&
                                    itemsSearchResults.length > 0 && (
                                        <div className={Styles.tiles}>
                                            {itemsSearchResults.map(dir => (
                                                <DirectoryTile
                                                    data-test="ServicesItemsDirectoriesItemsDirectoryTile"
                                                    key={dir.id}
                                                    type="view"
                                                    size="lg"
                                                    texts={texts}
                                                    directory={dir}
                                                    directoryType="items"
                                                    baseUrl={`/networks-management/items`}
                                                    onSettings={this.handleToogleSettings}
                                                />
                                            ))}
                                        </div>
                                    )}

                                {!isLoading &&
                                    itemsDirectories &&
                                    itemsDirectories.length > 0 &&
                                    itemsSearchResults.length === 0 && (
                                        <p
                                            data-test="ServicesItemsDirectoriesItemsNotFound"
                                            className={Styles.notFound}
                                        >
                                            {getText('no_search_results')}
                                        </p>
                                    )}
                            </Suspense>
                        </section>
                    </div>

                    <Popup
                        data-test="ServicesItemsDirectoriesSettingsPopup"
                        list
                        position="right"
                        id="servicesItemsSettingsPopup"
                        active={settings?.active}
                        element={settings.element}
                        element-id={settings.id}
                        onClickSetting={this.handleClickSetting}
                        closePopup={this.handleCloseSettings}
                        content={this.getPopupContent()}
                    />

                    {shareModal && <ShareModal />}
                </article>
            </ErrorBoundary>
        );
    }

    getPopupContent() {
        return (
            <>
                <li onClick={this.handleClickSetting} data-action="share">
                    {getText('share_directory')}
                </li>
                <li onClick={this.handleClickSetting} data-action="delete">
                    {getText('delete_directory')}
                </li>
            </>
        );
    }
}

export default ServicesItemsDirectories;
