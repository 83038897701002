import { TEMPLATES_SERVICES_SEGMENTS_NAMESPACES } from 'utils/constants';

export const templates = {
    isLoading: false,
    isSaving: false,
    services: {
        segments: {
            namespaces: TEMPLATES_SERVICES_SEGMENTS_NAMESPACES,
            lists: {
                car: [],
                pets: [],
                home: [],
            },
        },
        service: null,
        'protected-assets': {
            selected: null,
            items: {
                required: [],
                optional: [],
            },
            item: {
                type: 'modal',
                id: 'ItemModal',
                active: false,
                data: null,
                action: null,
                itemType: null,
                canSave: false,
            },
        },
        coverages: {
            selected: null,
            items: {
                required: [],
                optional: [],
                addon: [],
                available: [],
                networks: [],
            },
            coverage: {
                type: 'modal',
                id: 'CoverageModal',
                active: false,
                data: null,
                action: null,
                canSave: false,
            },
        },
        settings: null,
    },
    cards: {
        lists: {
            content: [],
            alert: [],
            info: [],
        },
        card: {
            type: 'modal',
            id: 'CardModal',
            active: false,
            data: null,
            element: null,
            action: null,
            cardType: null,
            canSave: false,
            title: null,
        },
    },
};
