/* eslint-disable no-undef */
import Blockly from 'blockly';
import { sendErrorToast } from 'services/toast';

const colorsPalette = {
    'Root Blocks': 30,
    Variables: 60,
    Constants: 90,
    Alarms: 120,
    Base: 150,
    Devices: 180,
    'IFs and Flow': 210,
    Time: 240,
    Types: 270,
    'Use-cases': 300,
    Users: 330,
    Utilities: 360,
};

// Main data structure
const blockly = {
    trigger(conf) {
        // Automatization of the API call
        if (conf.on !== 'now') return;

        fetch(blockly.apiEndpoints[conf.action], {
            method: conf.method,
            headers: {
                Authorization: `Bearer ${blockly.auth.access_token}`,
            },
        })
            .then(res => res.json())
            .then(
                result => conf.onSuccess(result),
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                error => conf.onError(error),
            );
    },
    apiEndpoints: {},
    auth: {
        access_token: '',
    },
    vars: {
        componentsClasses: [],
        propertiesClasses: [],
        currentColor: 0,
    },
    methods: [],
    output: {},
    ui: {},
    isLoading: true,
    generateRandomNumbers: function (length) {
        let result = '';

        for (let i = 0; i < length; i++) {
            result += Math.floor(Math.random() * 9).toString();
        }

        return result;
    },
};

/* ########################################*
 * GRAPHICAL TOOLS                        *
 *######################################## */
// const blocklyArea = document.getElementById('blockly');
// const blocklyDiv = document.getElementById('blockly');

blockly.ui = {
    blockly_area: undefined,
    blockly_div: undefined,
    workspace: undefined,

    setfullscreen(e) {
        // const element = blockly.ui.blockly_area;

        blockly.ui.blockly_area.style.left = '0px';
        blockly.ui.blockly_area.style.top = '0px';
        blockly.ui.blockly_area.style.width = '100%';
        blockly.ui.blockly_area.style.height = '100%';

        blockly.ui.blockly_div.style.left = '0px';
        blockly.ui.blockly_div.style.top = '0px';
        blockly.ui.blockly_div.style.width = '100%';
        blockly.ui.blockly_div.style.height = '100%';
        blockly.ui.blockly_div.style.zIndex = '1000';
    },

    onresize(e) {
        let element = blockly.ui.blockly_area;
        let x = 0;
        let y = 0;

        do {
            x += element.offsetLeft;
            y += element.offsetTop;
            element = element.offsetParent;
        } while (element);

        const innerHeight =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clienHeight;

        blockly.ui.blockly_area.style.height = `${innerHeight - 400}px`;

        blockly.ui.blockly_div.style.left = `${x}px`;
        blockly.ui.blockly_div.style.top = `${y}px`;
        blockly.ui.blockly_div.style.width = `${blockly.ui.blockly_area.offsetWidth}px`;
        blockly.ui.blockly_div.style.height = `${blockly.ui.blockly_area.offsetHeight}px`;
    },

    ready(callback) {
        Blockly.utils.genUid = () =>
            // eslint-disable-next-line no-useless-escape
            `\$blockly:${blockly.methods.generateUUID().replace(/[^a-z0.9]/g, '')}:uid`;

        const categoriesObject = {};
        const toolboxCategories = [];

        // Init Muzzley blocks
        // eslint-disable-next-line no-unused-vars
        for (const category in muzzleyBlocks) {
            // Create or use the category object, add the block node
            categoriesObject[category] = categoriesObject[category] || {
                name: category,
                colour: colorsPalette[category],
                // colour: blockly.methods.getNewColor().toString(),
            };

            // eslint-disable-next-line no-unused-vars
            for (const block in muzzleyBlocks[category]) {
                // Create block and assign code generation handler
                delete Blockly.JavaScript[block];

                muzzleyBlocks[category][block].createBlock({
                    color: categoriesObject[category]
                        ? categoriesObject[category].colour
                        : colorsPalette[category],
                });

                Blockly.JavaScript[block] = muzzleyBlocks[category][block].action;

                if (!categoriesObject[category].blocks) {
                    categoriesObject[category].blocks = [];
                }

                categoriesObject[category].blocks.push({ type: block });
            }
        }

        // When the get operators response is received
        blockly.api.getOperators.onSuccess = function (response) {
            const operators = [];

            // eslint-disable-next-line no-unused-vars
            for (const operatorKey in response) {
                response[operatorKey].key = operatorKey;
                operators.push(response[operatorKey]);
            }

            operators.sort((el1, el2) => (el1.category < el2.category ? -1 : 1));

            function createBlockMetadata(operatorKey, operatorData, type) {
                const metadata = {
                    type: type || operatorData.type,
                    id: `${operatorKey}_${type || operatorData.type}`,
                    name: operatorKey,
                    label: operatorData.label,
                    category: operatorData.category,
                    color: categoriesObject[operatorData.category]
                        ? categoriesObject[operatorData.category].colour
                        : colorsPalette[operatorData.category],
                };

                categoriesObject[metadata.category] = categoriesObject[metadata.category] || {
                    name: metadata.category,
                    colour: metadata.color,
                };

                if (!categoriesObject[metadata.category].blocks) {
                    categoriesObject[metadata.category].blocks = [];
                }

                categoriesObject[metadata.category].blocks.push({ type: metadata.id });

                return metadata;
            }

            // Init operator blocks from JSON
            // eslint-disable-next-line no-unused-vars
            for (const idx in operators) {
                if (operators[idx].category === 'hidden') continue;

                const blockMetadata = createBlockMetadata(operators[idx].key, operators[idx]);
                blockly.methods.createBlock(blockMetadata, operators[idx]);

                // If the operator is of type functional, we must create the blocks with the return type as well
                if (operators[idx].type === 'functional') {
                    const blockMetadata = createBlockMetadata(
                        operators[idx].key,
                        operators[idx],
                        'condition',
                    );
                    blockly.methods.createBlock(blockMetadata, operators[idx]);
                }
            }

            let categories = [];

            // eslint-disable-next-line no-unused-vars
            for (const category in categoriesObject) {
                if (
                    category !== 'Root Blocks' &&
                    category !== 'Variables' &&
                    category !== 'Constants'
                ) {
                    categories.push(category);
                }
            }

            categories = ['Root Blocks', 'Variables', 'Constants']?.concat(categories);

            // Add categories with nodes to too3lboxCategories
            // eslint-disable-next-line no-unused-vars
            for (const i in categories) {
                const catName = categories[i];
                toolboxCategories.push(categoriesObject[catName]);
            }

            callback(toolboxCategories);

            blockly.isLoading = false;
            window.blockly = blockly;
        };
    },
};

blockly.methods.getNewColor = function () {
    if (blockly.vars.currentColor + 41 > 360) blockly.vars.currentColor = 15;
    return (blockly.vars.currentColor += 41);
};

// Returns a new UUID
blockly.methods.generateUUID = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        let r = crypto.getRandomValues(new Uint8Array(1))[0] % 16 | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

blockly.methods.export = function () {
    const xml = Blockly.Xml.workspaceToDom(blockly.ui.workspace);
    return Blockly.Xml.domToText(xml);
};

blockly.methods.loadBlocks = function (xmlBlocks) {
    const xml = Blockly.Xml.textToDom(xmlBlocks);

    Blockly.Xml.domToWorkspace(blockly.ui.workspace, xml);
    // blockly.ui.blockly_area.parentNode.removeChild(blockly.ui.blockly_area);
};

blockly.methods.parseJson = function () {
    Blockly.JavaScript.workspaceToCode(blockly.ui.workspace);
    return blockly.output;
};

// clean str for json parsing
const clean = function (str) {
    const result = str.trim();
    return result[result.length - 1] === ',' ? result.slice(0, -1) : result;
};

// Blocks Actions
const genericAction = function (block, obj) {
    const operator = {
        name: obj.name,
        args: [],
    };

    for (let i = 0; i !== obj.args.length; ++i) {
        switch (obj.args[i].type) {
            case 'hidden':
                operator.args.push('');
                break;
            // INTENTIONAL FALL THROUGH
            case 'device-class':
            case 'property-class':
            case 'string':
            case 'time-unit':
            case 'value-picker':
                operator.args.push(block.getFieldValue(`arg_${i}`));
                break;

            case 'boolean':
                operator.args.push(block.getFieldValue(`arg_${i}`) === 'TRUE');
                break;

            case 'number':
            case 'value':
                var value = block.getFieldValue(`arg_${i}`);
                operator.args.push(value === '' ? '' : JSON.parse(value));
                break;

            case 'statements':
            case 'let':
                var code = clean(Blockly.JavaScript.statementToCode(block, `arg_${i}`));
                var objects = JSON.parse(`[${code}]`);

                var result = {
                    name: 'seq',
                    args: objects,
                };
                operator.args.push(result);
                break;

            default:
                // eslint-disable-next-line no-redeclare
                var value = clean(Blockly.JavaScript.statementToCode(block, `arg_${i}`));
                operator.args.push(value === '' ? '' : JSON.parse(value));
                break;
        }
    }

    // if (obj.triggerable === 'true') blockly.output.triggers.push(operator);
    return `${JSON.stringify(operator).trim()},`;
};

// Creates the properties for each loaded block
function createBlockProperties(args) {
    if (!args || !args.length) return;

    for (let i = 0; i !== args.length; ++i) {
        switch (args[i].type) {
            case 'hidden':
                break;

            case 'boolean':
                this.appendDummyInput()
                    .appendField(args.length > 1 ? args[i].label : '')
                    .setAlign(Blockly.ALIGN_RIGHT)
                    .appendField(new Blockly.FieldCheckbox('true'), `arg_${i}`);
                break;

            case 'statements':
                this.appendStatementInput(`arg_${i}`).appendField(
                    args.length > 1 ? args[i].label : '',
                );
                break;

            case 'let':
                this.appendStatementInput(`arg_${i}`).appendField(
                    args.length > 1 ? args[i].label : '',
                );
                break;

            case 'string':
                this.appendDummyInput()
                    .setAlign(Blockly.ALIGN_RIGHT)
                    .appendField(args.length > 1 ? args[i].label : '')
                    .appendField(new Blockly.FieldTextInput('Write here'), `arg_${i}`);
                break;

            case 'number':
                this.appendDummyInput()
                    .setAlign(Blockly.ALIGN_RIGHT)
                    .appendField(args.length > 1 ? args[i].label : '')
                    .appendField(new Blockly.FieldTextInput('0'), `arg_${i}`);
                break;

            case 'value-picker':
                this.appendDummyInput()
                    .appendField(args[i].label)
                    .setAlign(Blockly.ALIGN_RIGHT)
                    .appendField(
                        new Blockly.FieldDropdown([
                            ['location', 'location'],
                            ['time', 'time'],
                            ['device-choice', 'device-choice'],
                            ['text', 'text'],
                            ['single-choice', 'single-choice'],
                            ['multi-choice', 'multi-choice'],
                            ['ads-list', 'ads-list'],
                        ]),
                        `arg_${i}`,
                    );
                break;

            case 'time-unit':
                var options = [
                    ['seconds', 'seconds'],
                    ['minutes', 'minutes'],
                    ['hours', 'hours'],
                    ['days', 'days'],
                    ['weeks', 'weeks'],
                ];
                this.appendDummyInput()
                    .appendField(args[i].label)
                    .setAlign(Blockly.ALIGN_RIGHT)
                    .appendField(new Blockly.FieldDropdown(options), `arg_${i}`);
                break;

            case 'property-class':
                this.appendDummyInput()
                    .appendField(args[i].label)
                    .setAlign(Blockly.ALIGN_RIGHT)
                    .appendField(
                        new Blockly.FieldDropdown(blockly.vars.propertiesClasses),
                        `arg_${i}`,
                    );
                break;

            case 'device-class':
                this.appendDummyInput()
                    .appendField(args[i].label)
                    .setAlign(Blockly.ALIGN_RIGHT)
                    .appendField(
                        new Blockly.FieldDropdown(blockly.vars.componentsClasses),
                        `arg_${i}`,
                    );
                break;

            default:
                this.appendValueInput(`arg_${i}`)
                    .setAlign(Blockly.ALIGN_RIGHT)
                    .appendField(args.length > 1 ? args[i].label : '');
                break;
        }
    }
}

// Creates a block
blockly.methods.createBlock = function (metadata, obj) {
    // Blocks UI
    const blockTypes = {
        functional: {
            init() {
                this.appendDummyInput().appendField(metadata.label);
                createBlockProperties.call(this, obj.args);
                this.setInputsInline(false);
                if (!obj.args || obj.args.length === 1) this.setInputsInline(true);
                this.setPreviousStatement(true);
                this.setNextStatement(true);
                this.setTooltip(obj.label);
                this.setColour(metadata.color);
            },
        },

        condition: {
            init() {
                this.appendDummyInput().appendField(metadata.label);
                createBlockProperties.call(this, obj.args);
                this.setOutput(true);
                this.setColour(metadata.color);
                this.setTooltip(obj.label);
                this.setInputsInline(!!(!obj.args || obj.args.length === 1));
            },
        },
    };

    const blocksActions = function (block) {
        return genericAction(block, obj);
    };

    // Create Block and add action by input type
    Blockly.Blocks[metadata.id] = blockTypes[metadata.type];
    Blockly.JavaScript[metadata.id] = blocksActions;
};

/* ########################################*
 * API & ENDPOINT ROUTES                  *
 *######################################## */
blockly.api = {
    getOperators: {
        action: 'get-operators',
        method: 'POST',
        on: 'now',
        beforeXHR() {
            blockly.isLoading = true;
        },
        onSuccess(response) {
            // Implemented inside ready function overriding this function
        },
        onFailure(response) {
            console.log('Failed');
            blockly.isLoading = false;
        },
        onError(error) {
            console.log('Error', error);
            blockly.isLoading = false;
        },
        onAbort(error) {
            console.log('aborted');
            blockly.isLoading = false;
        },
    },

    getComponentsClasses: {
        action: 'get-components-classes',
        method: 'GET',
        on: 'now',
        beforeXHR() {
            blockly.isLoading = true;
        },
        onSuccess(response) {
            if (!response.elements) response.elements = [];

            blockly.vars.componentsClasses = response.elements.map(component => [
                component.id,
                `\${component:${component.id}}`,
            ]);

            blockly.vars.componentsClasses.splice(0, 0, ['---', '']);

            blockly.trigger(blockly.api.getPropertiesClasses);
        },
        onFailure(response) {
            blockly.isLoading = false;
            sendErrorToast('Failed to retrieve channel components');
            console.log('Failed to retrieve channel components', response);
        },
        onError(error) {
            blockly.isLoading = false;
            sendErrorToast('Failed to retrieve channel components');
            console.log('Failed to retrieve channel components', error);
        },
        onAbort(abort) {
            blockly.isLoading = false;
            sendErrorToast('Failed to retrieve channel components');
            console.log('Failed to retrieve channel components. Aborted..', abort);
        },
    },

    getPropertiesClasses: {
        action: 'get-properties-classes',
        method: 'GET',
        on: 'now',
        beforeXHR() {
            blockly.isLoading = true;
        },
        onSuccess(response) {
            if (!response.elements) response.elements = [];

            blockly.vars.propertiesClasses = response.elements.map(property => [
                property.id,
                `\${property:${property.id}}`,
            ]);

            blockly.vars.propertiesClasses.splice(0, 0, ['---', '']);
            blockly.isLoading = true;
            blockly.trigger(blockly.api.getOperators);
        },
        onFailure(response) {
            blockly.isLoading = false;
            sendErrorToast('Failed to retrieve channel components');
            console.log('Failed to retrieve channel components', response);
        },
        onError(error) {
            blockly.isLoading = false;
            sendErrorToast('Failed to retrieve channel components');
            console.log('Failed to retrieve channel components', error);
        },
        onAbort(abort) {
            blockly.isLoading = false;
            sendErrorToast('Failed to retrieve channel components');
            console.log('Failed to retrieve channel components. Aborted..', abort);
        },
    },
};

/* ########################################*
 * VIEWERS                                *
 *######################################## */
blockly.views = {};

/**
  Muzzley custom blocks
*/

let muzzleyBlocks = {
    'Root Blocks': {
        // If Then Else Condition
        if_then_else: {
            createBlock(options) {
                Blockly.Blocks.if_then_else = {
                    init() {
                        this.appendValueInput('IfCondition').setCheck(null).appendField('If');
                        this.appendStatementInput('doStatement').setCheck(null).appendField('do');
                        this.appendStatementInput('elseStatement')
                            .setCheck(null)
                            .setAlign(Blockly.ALIGN_RIGHT)
                            .appendField('else');
                        this.setInputsInline(false);
                        this.setPreviousStatement(false, null);
                        this.setColour(options.color);
                        this.setTooltip('If Then Else condition');
                    },
                };
            },

            action(block) {
                const conditionStatement = Blockly.JavaScript.statementToCode(block, 'IfCondition');

                const operator = {
                    name: 'if',
                    args: [
                        conditionStatement === ''
                            ? ''
                            : JSON.parse(conditionStatement.trim().slice(0, -1)),
                    ],
                };

                const doStatements = Blockly.JavaScript.statementToCode(block, 'doStatement')
                    .trim()
                    .slice(0, -1);
                const objects1 = JSON.parse(`[${doStatements}]`);
                let multiple = {
                    name: 'seq',
                    args: [],
                };

                objects1.forEach(obj => {
                    multiple.args.push(obj);
                });

                operator.args.push(multiple);

                const elseStatement = Blockly.JavaScript.statementToCode(block, 'elseStatement')
                    .trim()
                    .slice(0, -1);

                const objects2 = JSON.parse(`[${elseStatement}]`);

                multiple = {
                    name: 'seq',
                    args: [],
                };

                objects2.forEach(obj => {
                    multiple.args.push(obj);
                });

                operator.args.push(multiple);

                blockly.output = operator;

                return JSON.stringify(operator);
            },
        },

        // If Then Condition
        if_do: {
            createBlock(options) {
                Blockly.Blocks.if_do = {
                    init() {
                        this.appendValueInput('IfCondition').setCheck(null).appendField('If');
                        this.appendStatementInput('doStatement').setCheck(null).appendField('do');
                        this.setInputsInline(false);
                        this.setPreviousStatement(false, null);
                        this.setColour(options.color);
                        this.setTooltip('If Then Else condition');
                    },
                };
            },

            action(block) {
                const conditionStatement = Blockly.JavaScript.statementToCode(block, 'IfCondition');

                const operator = {
                    name: 'if',
                    args: [
                        conditionStatement === ''
                            ? ''
                            : JSON.parse(conditionStatement.trim().slice(0, -1)),
                    ],
                };

                const doStatements = Blockly.JavaScript.statementToCode(block, 'doStatement')
                    .trim()
                    .slice(0, -1);

                const objects = JSON.parse(`[${doStatements}]`);

                const multiple = {
                    name: 'seq',
                    args: [],
                };

                objects.forEach(obj => {
                    multiple.args.push(obj);
                });

                operator.args.push(multiple);

                blockly.output = operator;

                return JSON.stringify(operator);
            },
        },
        // Do
        do: {
            createBlock(options) {
                Blockly.Blocks.do = {
                    init() {
                        this.appendStatementInput('doStatement').setCheck(null).appendField('Do');
                        this.setInputsInline(false);
                        this.setPreviousStatement(false, null);
                        this.setColour(options.color);
                        this.setTooltip('Do');
                    },
                };
            },

            action(block) {
                const operator = {
                    name: 'seq',
                    args: [],
                };

                const doStatements = Blockly.JavaScript.statementToCode(block, 'doStatement')
                    .trim()
                    .slice(0, -1);

                const objects = JSON.parse(`[${doStatements}]`);

                objects.forEach(obj => {
                    operator.args.push(obj);
                });

                blockly.output = operator;

                return JSON.stringify(operator);
            },
        },
        // With-vars root
        with: {
            createBlock(options) {
                Blockly.Blocks.with = {
                    init() {
                        this.appendDummyInput().appendField('With');
                        this.appendStatementInput('Vars').setCheck(null).appendField('Vars');
                        this.appendStatementInput('Do').setCheck(null).appendField('Do');
                        this.setInputsInline(false);
                        this.setPreviousStatement(false, null);
                        this.setColour(options.color);
                        this.setTooltip('With-vars');
                    },
                };
            },

            action(block) {
                const operator = {
                    name: 'with-vars',
                    args: [],
                };

                const bindings = Blockly.JavaScript.statementToCode(block, 'Vars')
                    .trim()
                    .slice(0, -1);
                const actions = Blockly.JavaScript.statementToCode(block, 'Do').trim().slice(0, -1);

                let object = JSON.parse(`[${bindings}]`);

                let multiple = {
                    name: 'seq',
                    args: [],
                };

                object.forEach(obj => {
                    multiple.args.push(obj);
                });

                operator.args.push(multiple);

                object = JSON.parse(`[${actions}]`);

                multiple = {
                    name: 'seq',
                    args: [],
                };

                object.forEach(obj => {
                    multiple.args.push(obj);
                });

                operator.args.push(multiple);

                blockly.output = operator;

                return JSON.stringify(operator);
            },
        },
    },

    Constants: {
        event: {
            createBlock(options) {
                Blockly.Blocks.event = {
                    init() {
                        this.appendDummyInput().appendField('event');
                        this.setInputsInline(true);
                        this.setOutput(true, null);
                        this.setColour(options.color);
                        this.setTooltip('The event constant');
                    },
                };
            },

            action(block) {
                // eslint-disable-next-line no-template-curly-in-string
                return '"${event}"';
            },
        },
        event_sender: {
            createBlock(options) {
                Blockly.Blocks.event_sender = {
                    init() {
                        this.appendDummyInput().appendField('event_sender');
                        this.setInputsInline(true);
                        this.setOutput(true, null);
                        this.setColour(options.color);
                        this.setTooltip('The event.sender constant');
                    },
                };
            },

            action(block) {
                // eslint-disable-next-line no-template-curly-in-string
                return '"${event sender}"';
            },
        },
        user_id: {
            createBlock(options) {
                Blockly.Blocks.user_id = {
                    init() {
                        this.appendDummyInput().appendField('user id');
                        this.setInputsInline(true);
                        this.setOutput(true, null);
                        this.setColour(options.color);
                        this.setTooltip('The user id constant');
                    },
                };
            },

            action(block) {
                // eslint-disable-next-line no-template-curly-in-string
                return '"${user-id}"';
            },
        },

        rule_id: {
            createBlock(options) {
                Blockly.Blocks.rule_id = {
                    init() {
                        this.appendDummyInput().appendField('rule id');
                        this.setInputsInline(true);
                        this.setOutput(true, null);
                        this.setColour(options.color);
                        this.setTooltip('The rule id constant');
                    },
                };
            },

            action(block) {
                // eslint-disable-next-line no-template-curly-in-string
                return '"${rule-id}"';
            },
        },

        true_block: {
            createBlock(options) {
                Blockly.Blocks.true_block = {
                    init() {
                        this.appendDummyInput().appendField('true');
                        this.setInputsInline(true);
                        this.setOutput(true, null);
                        this.setColour(options.color);
                        this.setTooltip('The truth value');
                    },
                };
            },

            action(block) {
                return 'true';
            },
        },
        false_block: {
            createBlock(options) {
                Blockly.Blocks.false_block = {
                    init() {
                        this.appendDummyInput().appendField('false');
                        this.setInputsInline(true);
                        this.setOutput(true, null);
                        this.setColour(options.color);
                        this.setTooltip('The false value');
                    },
                };
            },

            action(block) {
                return 'false';
            },
        },
        io_write_block: {
            createBlock(options) {
                Blockly.Blocks.io_write_block = {
                    init() {
                        this.appendDummyInput().appendField('io_write');
                        this.setInputsInline(true);
                        this.setOutput(true, null);
                        this.setColour(options.color);
                        this.setTooltip('The IO write value');
                    },
                };
            },

            action(block) {
                return '"w"';
            },
        },
        io_iwrite_block: {
            createBlock(options) {
                Blockly.Blocks.io_iwrite_block = {
                    init() {
                        this.appendDummyInput().appendField('io_iwrite');
                        this.setInputsInline(true);
                        this.setOutput(true, null);
                        this.setColour(options.color);
                        this.setTooltip('The IO write confirmation value');
                    },
                };
            },

            action(block) {
                return '"iw"';
            },
        },
        io_read_block: {
            createBlock(options) {
                Blockly.Blocks.io_read_block = {
                    init() {
                        this.appendDummyInput().appendField('io_read');
                        this.setInputsInline(true);
                        this.setOutput(true, null);
                        this.setColour(options.color);
                        this.setTooltip('The IO read value');
                    },
                };
            },

            action(block) {
                return '"r"';
            },
        },
        io_iread_block: {
            createBlock(options) {
                Blockly.Blocks.io_iread_block = {
                    init() {
                        this.appendDummyInput().appendField('io_iread');
                        this.setInputsInline(true);
                        this.setOutput(true, null);
                        this.setColour(options.color);
                        this.setTooltip('The IO read confirmation value');
                    },
                };
            },

            action(block) {
                return '"ir"';
            },
        },
    },

    Variables: {
        var: {
            createBlock(options) {
                Blockly.Blocks.var = {
                    init() {
                        this.setHelpUrl(Blockly.Msg.VARIABLES_GET_HELPURL);
                        this.setColour(options.color);
                        this.appendDummyInput().appendField(
                            new Blockly.FieldVariable(
                                Blockly.Msg.VARIABLES_DEFAULT_NAME +
                                    blockly.generateRandomNumbers(3),
                            ),
                            'VAR',
                        );
                        this.setOutput(true);
                        this.setTooltip(Blockly.Msg.VARIABLES_GET_TOOLTIP);
                        this.contextMenuMsg_ = Blockly.Msg.VARIABLES_GET_CREATE_SET;
                    },
                    getVars() {
                        return [this.getField('VAR').text_];
                    },
                    renameVar(oldName, newName) {
                        if (Blockly.Names.equals(oldName, this.getField('VAR').text_)) {
                            this.setFieldValue(newName, 'VAR');
                        }
                    },
                    contextMenuType_: 'variables_set',
                    customContextMenu(options) {
                        const option = { enabled: true };
                        const name = this.getField('VAR').text_;
                        option.text = this.contextMenuMsg_.replace('%1', name);
                        const xmlField = goog.dom.createDom('field', null, name);
                        xmlField.setAttribute('name', 'VAR');
                        const xmlBlock = goog.dom.createDom('block', null, xmlField);
                        xmlBlock.setAttribute('type', this.contextMenuType_);
                        option.callback = Blockly.ContextMenu.callbackFactory(this, xmlBlock);
                        options.push(option);
                    },
                };
            },

            action(block) {
                const varName = block.getField('VAR').text_;
                return `"\${var ${varName}}"`;
            },
        },

        // Assign result to var
        set_var: {
            createBlock(options) {
                Blockly.Blocks.set_var = {
                    init() {
                        this.jsonInit({
                            message0: Blockly.Msg.VARIABLES_SET,
                            args0: [
                                {
                                    type: 'field_variable',
                                    name: 'VAR',
                                    variable:
                                        Blockly.Msg.VARIABLES_DEFAULT_NAME +
                                        blockly.generateRandomNumbers(3),
                                    variableTypes: [''],
                                },
                                {
                                    type: 'input_value',
                                    name: 'VALUE',
                                },
                            ],
                            previousStatement: null,
                            nextStatement: null,
                            colour: options.color,
                            tooltip: Blockly.Msg.VARIABLES_SET_TOOLTIP,
                            helpUrl: Blockly.Msg.VARIABLES_SET_HELPURL,
                        });

                        this.contextMenuMsg_ = Blockly.Msg.VARIABLES_SET_CREATE_GET;
                    },

                    getVars() {
                        return [this.getField('VAR').text_];
                    },

                    renameVar(oldName, newName) {
                        if (Blockly.Names.equals(oldName, this.getField('VAR').text_)) {
                            this.setFieldValue(newName, 'VAR');
                        }
                    },

                    contextMenuType_: 'variables_get',

                    customContextMenu: Blockly.Blocks.variables_get.customContextMenu,
                };
            },

            action(block) {
                const value = clean(Blockly.JavaScript.statementToCode(block, 'VALUE'));

                const operator = {
                    name: 'bind',
                    args: [block.getField('VAR').text_, value === '' ? '' : JSON.parse(value)],
                };

                return `${JSON.stringify(operator).trim()},`;
            },
        },
    },
};

export { blockly };
