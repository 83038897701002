import ActionTypes from 'redux/actionTypes';
import rootInitialState from 'redux/store/initialState';

export const initialState = {
    builds: rootInitialState.builds,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.BUILDS_FETCH:
            return buildsFetch(state, action);
        case ActionTypes.BUILDS_SYNC:
            return syncBuilds(state, action);
        case ActionTypes.BUILDS_CLEAR:
            return clear(state, action);
        case ActionTypes.BUILDS_IS_DOWNLOADING:
            return isDownloading(state, action);
        default:
            return state;
    }
};

/**
 * Clear Builds
 * @param {*} state
 * @param {*} action
 */
const clear = (state, action) => {
    return {
        isDownloading: false,
        prototypes: [],
        releases: [],
    };
};

/**
 * Sync builds
 * @param {*} state
 * @param {*} action
 */
const syncBuilds = (state, action) => {
    const payload = action.payload.data;
    const builds = payload.elements;

    const prototypes = builds.filter(build => build.whitelabel.target === 'android_prototype');
    const releases = builds.filter(build => build.whitelabel.target !== 'android_prototype');

    const groupReleases = releases.reduce((r, a) => {
        r[a.build_identifier] = r[a.build_identifier] || {};

        r[a.build_identifier].id = a.build_identifier;

        if (a.whitelabel.target === 'ios_release' && !r[a.build_identifier].ios) {
            r[a.build_identifier].ios = a;
        }

        if (a.whitelabel.target === 'android_release' && !r[a.build_identifier].android) {
            r[a.build_identifier].android = a;
        }

        return r;
    }, []);

    if (payload.app && payload.app.elements) {
        const name = payload.app.elements.filter(
            field => field.assetschema.namespace === 'sc-wl-app-name',
        );
        const icon = payload.app.elements.filter(
            field => field.assetschema.namespace === 'sc-wl-android-app-icon',
        );

        // const releases1 = [...Object.values(groupReleases)];
        const releases1 = Object.keys(groupReleases).map(function (key) {
            return groupReleases[key];
        });

        return {
            ...state,
            app: {
                name: name[0] && name[0].content ? name[0].content : 'App Name',
                icon: icon[0] && icon[0].content ? icon[0].content : '',
            },
            prototypes: [...prototypes],
            releases: [...releases1],
        };
    }

    // const releases2 = [...Object.values(groupReleases)];
    const releases2 = Object.keys(groupReleases).map(function (key) {
        return groupReleases[key];
    });

    return {
        ...state,
        prototypes: [...prototypes],
        releases: [...releases2],
    };
};

/**
 * Fetch builds
 * @param {*} state
 * @param {*} action
 */
const buildsFetch = (state, action) => {
    const payload = action.payload.data;

    return { ...payload };
};

/**
 * Is Downloading
 * @param {*} state
 * @param {*} action
 */
const isDownloading = (state, action) => {
    const payload = action.payload.data;

    return { ...state, isDownloading: payload };
};

export default reducer;
