import { downloadAsset } from 'api/assets';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { roles, userHasPermission } from 'services/roles';
import { sendErrorToast } from 'services/toast';
import Loading from 'shared/Loading';
import ValidationStatus from 'shared/ValidationStatus';
import { INSURANCE_PRIVATE_FILE_URI_SEARCH } from 'utils/constants';
import Prompt from './Prompt';
import Styles from './UploadPaymentDocuments.module.scss';

export class UploadPaymentDocuments extends React.PureComponent {
    myRef = React.createRef();

    static propTypes = {
        placeholder: PropTypes.string,
        text: PropTypes.string,
        name: PropTypes.string,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        onClick: PropTypes.func,
        onDelete: PropTypes.func,
        types: PropTypes.string,
        file: PropTypes.string,
        namespace: PropTypes.string,
        withValidation: PropTypes.bool,
        withDeletion: PropTypes.bool,
        isValid: PropTypes.bool,
        unsaved: PropTypes.bool,
        subscriptionId: PropTypes.string,
        assetId: PropTypes.string,
        isLoading: PropTypes.bool,
        isSaving: PropTypes.bool,
        isUploading: PropTypes.bool,
        project: PropTypes.object,
    };

    state = {
        isDownloading: false,
        isPromptActive: false,
        deleteElement: null,
    };

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    handleOnClick = e => {
        this.myRef.current.click();
    };

    handleOnDeleteCancel = e => {
        e.preventDefault();
        e.stopPropagation();

        this.setState(
            (state, props) => ({
                isPromptActive: false,
                deleteElement: null,
            }),
            () => {},
        );
    };

    handleOnDeletePrompt = e => {
        e.preventDefault();
        e.stopPropagation();

        const { isPromptActive } = this.state;

        if (!isPromptActive) {
            const subscriptionId = e.currentTarget.getAttribute('data-subscription-id');
            const assetId = e.currentTarget.getAttribute('data-asset-id');

            const element = {
                subscription_id: subscriptionId,
                asset_id: assetId,
            };

            this.setState(
                (state, props) => ({
                    isPromptActive: true,
                    deleteElement: element,
                }),
                () => {},
            );
        }
    };

    handleOnDelete = e => {
        const { deleteElement } = this.state;

        if (deleteElement) {
            this.myRef.current.value = '';
            this.props.onDelete(deleteElement);

            this.setState(
                (state, props) => ({
                    isPromptActive: false,
                    deleteElement: null,
                }),
                () => {},
            );
        }
    };

    handleProtectedAsset = async e => {
        const { project, file } = this.props;
        const url = file;

        let path = url.split('?');
        if (path[0].indexOf('/distributors/') !== -1) {
            path = path[0].split('/distributors/');
            path = '/distributors/' + path[1];
        } else if (path[0].indexOf('/applications/') !== -1) {
            path = path[0].split('/applications/');
            path = '/applications/' + path[1];
        } else {
            sendErrorToast();
            return;
        }

        const payload = {
            id: project.id,
            confirmation_hash: project.confirmation_hash,
            url: url,
            path: path,
        };

        try {
            this.setState({ isDownloading: true });
            await downloadAsset(payload)
                .then(response => {
                    if (response?.url) {
                        window.open(response.url, '_blank');
                    } else {
                        sendErrorToast();
                    }
                })
                .then(response => this.setState({ isDownloading: false }))
                .catch(error => {
                    console.log(error);
                    this.setState({ isDownloading: false });
                    sendErrorToast();
                });
        } catch (error) {
            console.log(error);
            this.setState({ isDownloading: false });
            sendErrorToast();
        }
    };

    render() {
        const {
            placeholder,
            text,
            name,
            types,
            disabled,
            onChange,
            file,
            namespace,
            withValidation,
            isValid,
            unsaved,
            subscriptionId,
            assetId,
            isSaving,
            isUploading,
            withDeletion,
        } = this.props;
        const { isPromptActive, isDownloading } = this.state;
        const enableEdit = userHasPermission([
            roles.ADMIN,
            roles.DISTRIBUTOR,
            roles.INSURER,
            roles.INSURER_DISTRIBUTOR,
        ]);
        const disableEdit = !enableEdit;

        return (
            <div data-testid="Container" className={Styles.upload}>
                <input
                    data-testid="UploadPaymentDocumentInputs"
                    data-namespace={namespace}
                    ref={this.myRef}
                    type="file"
                    name={name}
                    onChange={onChange}
                    accept={types}
                    disabled={disabled || disableEdit}
                    aria-disabled={disabled || disableEdit}
                />

                {!file && (
                    <button
                        data-testid="UploadPaymentDocumentWithoutFiles"
                        data-namespace={namespace}
                        className={Styles.withoutFile}
                        disabled={disabled || disableEdit}
                        aria-disabled={disabled || disableEdit}
                        onClick={this.handleOnClick}
                        type="button"
                    >
                        <i className="icon-icon_attachment"></i> {placeholder}
                        {isUploading && (
                            <span>
                                <Loading variant="xxs" />
                            </span>
                        )}
                    </button>
                )}

                {file && (
                    <>
                        <span
                            data-testid="UploadPaymentDocumentWithFiles"
                            data-namespace={namespace}
                            className={classnames(
                                Styles.withFile,
                                disabled || disableEdit ? Styles.disabled : null,
                            )}
                            title={file}
                        >
                            {file.indexOf(INSURANCE_PRIVATE_FILE_URI_SEARCH) === -1 && (
                                <a href={file} target="_blank" rel="noreferrer">
                                    <i className="icon-icon_flow_test"></i> {text}
                                </a>
                            )}

                            {file.indexOf(INSURANCE_PRIVATE_FILE_URI_SEARCH) !== -1 && (
                                <div onClick={this.handleProtectedAsset}>
                                    <i className="icon-icon_flow_test"></i> {text}
                                </div>
                            )}

                            <span>
                                {(isUploading || isDownloading) && <Loading variant="xxs" />}

                                {withDeletion && !isSaving && !isDownloading && !isUploading && (
                                    <i
                                        data-tip="Delete document" // TODO: Translate
                                        data-testid="UploadPaymentDocumentDeletes"
                                        data-namespace={namespace}
                                        data-subscription-id={subscriptionId}
                                        data-asset-id={assetId}
                                        className={classnames(
                                            'icon-icon_delete_3',
                                            disabled || disableEdit ? Styles.disabled : null,
                                        )}
                                        onClick={!disableEdit ? this.handleOnDeletePrompt : null}
                                    ></i>
                                )}
                            </span>
                        </span>
                    </>
                )}

                {withValidation && !unsaved && !disabled && isValid && (
                    <ValidationStatus status="success" />
                )}

                {withValidation && !unsaved && !disabled && !isValid && (
                    <ValidationStatus status="error" />
                )}

                {isPromptActive && !disableEdit && (
                    <Prompt onConfirm={this.handleOnDelete} onCancel={this.handleOnDeleteCancel} />
                )}
            </div>
        );
    }
}

UploadPaymentDocuments.defaultProps = {
    withDeletion: true,
};

export default UploadPaymentDocuments;
