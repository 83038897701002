import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Styles from './Loading.module.scss';
import Spinner from './svg/spinner.svg';

export class Loading extends React.PureComponent {
    static propTypes = {
        theme: PropTypes.oneOf(['none', 'white', 'dark', 'overlay', 'full', 'full_white']),
        variant: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg']),
        position: PropTypes.oneOf(['absolute', 'relative']),
        background: PropTypes.oneOf(['solid', 'opacity', 'transparent']),
    };

    static defaultProps = {
        theme: 'none',
        variant: 'sm',
    };

    render() {
        const { theme, variant, position, background } = this.props;

        return (
            <span
                data-test="LoadingContainer"
                className={classnames(
                    Styles.loading,
                    Styles[theme],
                    Styles[variant],
                    Styles[position],
                    Styles[background],
                )}
            >
                <img data-test="LoadingSpinner" src={Spinner} alt="Loading..." />
            </span>
        );
    }
}

export default Loading;
