import React from 'react';
import PropTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import './MapCluster.scss';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import Marker from 'leaflet/dist/images/marker-icon.png';

// leaflet.markercluster stable version 1.4.1

class MapCluster extends React.PureComponent {
    // TODO: Refactor

    _map = null;
    _sizes = [10, 20, 30, 40, 50];
    _clusterGroup = {};
    _total = 0;
    _fitBoundsMaxZoom = 10;
    _fitBounds = [];
    _fixViewPosition = [350, 0];

    state = {
        init: false,
        data: [],
    };

    static propTypes = {
        data: PropTypes.array,
        color: PropTypes.string,
    };

    static defaultProps = {
        data: [],
        color: 'yellow',
    };

    componentDidMount() {
        this.initMap();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.init &&
            this.props.data &&
            (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) ||
                prevProps.color !== this.props.color)
        ) {
            this.updateMap();
        }

        if (this.state.init && !this.props.data) {
            this.clearMap();
        }
    }

    componentWillUnmount() {
        if (this._map) {
            this._map.off();
            this._map.remove();
            this._map = null;
        }
    }

    clearMap() {
        if (this._map) {
            this._map.off();
            this._map.remove();
            this._map = null;

            this.initMap();
        }
    }

    async updateMap() {
        await this.clearMap();
        await this.initMap();
    }

    async initMap() {
        const light_1 = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png';

        if (!this._map) {
            this._map = await L.map('mapCluster', {
                center: [38.7479075, -9.1622213],
                zoom: 4,
                // minZoom: 1,
                // maxZoom: 7,
                zoomControl: true,
                preferCanvas: true,
                layers: [L.tileLayer(light_1)],
            });

            this._map.zoomControl.setPosition('topright');
        }

        if (this.props.data) {
            try {
                await this.initCluster();
            } catch (e) {
                if (window.L) {
                    delete window.L;

                    import('leaflet').then(response => {
                        window.L = response;
                        this.initCluster();
                    });
                }
            }
        }
    }

    initCluster() {
        try {
            const { data } = this.props;
            const total = data ? data.length : this._total;

            this._clusterGroup = new L.markerClusterGroup({
                spiderfyOnMaxZoom: false,
                showCoverageOnHover: false,
                zoomToBoundsOnClick: true,
                chunkedLoading: true,
                removeOutsideVisibleBounds: true,
                maxClusterRadius: 30,
                singleMarkerMode: true,
                animateAddingMarkers: false,
                iconCreateFunction: cluster => {
                    const clusterSize = cluster.getChildCount();
                    let size = 50;

                    for (let i = 0; i < this._sizes.length; i++) {
                        if (clusterSize <= total * (this._sizes[i] / 100)) {
                            size = this._sizes[i];
                            break;
                        }
                    }

                    return new L.divIcon({
                        html: `<b>${clusterSize}</b>`,
                        className: `mycluster ${this.props.color}`,
                        iconSize: new L.point(size, size),
                    });
                },
            });

            const icon = new L.Icon({
                iconUrl: Marker,
                iconAnchor: new L.Point(16, 16),
            });

            for (let i = 0; i < data.length; i++) {
                let a = data[i];
                let marker = new L.marker(new L.LatLng(a.latitude, a.longitude), {
                    icon: icon,
                    title: 'title',
                });
                this._clusterGroup.addLayer(marker);
                this._fitBounds.push([a.latitude, a.longitude]);
            }

            this._clusterGroup.on('clusterclick', function (a) {
                a.layer.zoomToBounds({ padding: [20, 20] });
            });

            if (data.length) {
                setTimeout(() => {
                    const bounds = new L.LatLngBounds(this._fitBounds);
                    this._map
                        .fitBounds(bounds, {
                            maxZoom: this._fitBoundsMaxZoom,
                        })
                        .addLayer(this._clusterGroup);
                }, 0);
            }

            this.setState((state, props) => ({
                init: true,
            }));
        } catch (error) {
            // console.log('MapCluster -> initCluster -> error', error);
        }
    }

    getRandomLatLng(map) {
        const bounds = map.getBounds(),
            southWest = bounds.getSouthWest(),
            northEast = bounds.getNorthEast(),
            lngSpan = northEast.lng - southWest.lng,
            latSpan = northEast.lat - southWest.lat;

        return new L.LatLng(
            southWest.lat + latSpan * Math.random(),
            southWest.lng + lngSpan * Math.random(),
        );
    }

    render() {
        return <div data-testid="MapCluster" id="mapCluster"></div>;
    }
}

export default MapCluster;
