import { connect } from 'react-redux';
import Dashboard from 'pages/Insurance/Dashboard';
import { insuranceMetaDataObs } from 'redux/domains/insurance/observer';
import { withRouter } from 'react-router';

const mapStateToProps = (state, ownProps) => ({
    texts: state.texts,
    isLoading: state.app.isLoading,
    teamMembers: state.teamMembers,
    insurance: state.insurance,
    ownProps: ownProps,
    projectId: state.insurance.filters.projects.selected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchData: data => insuranceMetaDataObs(dispatch, data, ownProps),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
