import ActionTypes from 'redux/actionTypes';
import rootInitialState from 'redux/store/initialState';
import { insuranceProductsTableListModel } from '../distributors/model';
import {
    approvalRequestsTableListModel,
    distributorsTableListModel,
    providersTableListModel,
    financialEntitiesTableListModel,
} from './model';

export const initialState = {
    admin: rootInitialState.admin,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.ADMIN_IS_LOADING:
            return isLoading(state, action);
        case ActionTypes.ADMIN_IS_SAVING:
            return isSaving(state, action);
        case ActionTypes.ADMIN_CLEAR:
            return clear(state, action);
        case ActionTypes.ADMIN_SYNC_APPROVAL_REQUESTS:
            return syncApprovalRequests(state, action);
        case ActionTypes.ADMIN_APPROVAL_REQUESTS_OPEN_MODAL:
            return openApprovalRequestsModal(state, action);
        case ActionTypes.ADMIN_APPROVAL_REQUESTS_CLOSE_MODAL:
            return closeApprovalRequestsModal(state, action);
        case ActionTypes.ADMIN_SYNC_FINANCIAL_ENTITIES_MANAGEMENT:
            return syncFinancialEntities(state, action);
        case ActionTypes.ADMIN_FINANCIAL_ENTITY_OPEN_MODAL:
            return openFinancialEntityModal(state, action);
        case ActionTypes.ADMIN_FINANCIAL_ENTITY_CLOSE_MODAL:
            return closeFinancialEntityModal(state, action);
        case ActionTypes.ADMIN_FINANCIAL_FLOW_OPEN_MODAL:
            return openFinancialFlowModal(state, action);
        case ActionTypes.ADMIN_FINANCIAL_FLOW_CLOSE_MODAL:
            return closeFinancialFlowModal(state, action);
        case ActionTypes.ADMIN_SYNC_DISTRIBUTORS:
            return syncDistributors(state, action);
        case ActionTypes.ADMIN_SYNC_DISTRIBUTOR:
            return syncDistributor(state, action);
        case ActionTypes.ADMIN_SYNC_PROVIDERS:
            return syncProviders(state, action);
        case ActionTypes.ADMIN_SYNC_PROVIDER:
            return syncProvider(state, action);
        case ActionTypes.ADMIN_SYNC_COUNTRIES_LIST:
            return syncCountriesList(state, action);
        case ActionTypes.ADMIN_INSURANCE_PRODUCTS_SYNC_FILTER:
            return syncInsuranceProductsFiltersType(state, action);
        case ActionTypes.ADMIN_INSURANCE_PRODUCTS_SYNC:
            return syncInsuranceProductsList(state, action);
        case ActionTypes.ADMIN_SETTINGS_DISCLAIMERS_SYNC:
            return syncSettingsDisclaimers(state, action);
        case ActionTypes.ADMIN_PAGINATE_INSURANCE_PRODUCTS_FORWARD:
            return paginateInsuranceProductsForward(state, action);
        case ActionTypes.ADMIN_PAGINATE_INSURANCE_PRODUCTS_BACKWARD:
            return paginateInsuranceProductsBackward(state, action);
        case ActionTypes.ADMIN_PRODUCTS_SET_PRODUCT_DETAILS:
            return setProductDetails(state, action);
        default:
            return state;
    }
};

const syncSettingsDisclaimers = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        settings: {
            ...state.settings,
            disclaimers: payload,
        },
    };
};

const paginateInsuranceProductsForward = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        insuranceProducts: {
            ...state.insuranceProducts,
            pagination: {
                ...state.insuranceProducts.pagination,
                ...payload,
            },
        },
    };
};

const paginateInsuranceProductsBackward = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        insuranceProducts: {
            ...state.insuranceProducts,
            pagination: {
                ...state.insuranceProducts.pagination,
                ...payload,
            },
        },
    };
};

const syncInsuranceProductsList = (state, action) => {
    const payload = action.payload.data;
    return {
        ...state,
        insuranceProducts: {
            ...state.insuranceProducts,
            list: insuranceProductsTableListModel(payload.elements, state.insuranceProducts.list),
            pagination: {
                ...state.insuranceProducts.pagination,
                current:
                    state?.insuranceProducts?.pagination?.current === '--'
                        ? 1
                        : state?.insuranceProducts?.pagination?.current,
                next: true,

                total: '...',
            },
        },
    };
};

const syncInsuranceProductsFiltersType = (state, action) => {
    const payload = action.payload.data;
    const type = action.payload.type;

    const elements = payload.elements.map(i => {
        return {
            id: i.id,
            text: i.name,
        };
    });

    return {
        ...state,
        insuranceProducts: {
            ...state.insuranceProducts,
            selectors: {
                ...state.insuranceProducts.selectors,
                [type]: {
                    list: elements,
                    selected: [],
                },
            },
        },
    };
};

const syncCountriesList = (state, action) => {
    const payload = action.payload.data;

    const elements = payload.map(i => {
        return {
            id: i.id,
            text: i.name,
        };
    });

    return {
        ...state,
        countries: {
            ...state.countries,
            list: elements,
        },
    };
};

const openApprovalRequestsModal = (state, action) => {
    const payload = action.payload.data;

    const request = state.approvalRequests.list.filter(item => item.id === payload.id);

    return {
        ...state,
        approvalRequests: {
            ...state.approvalRequests,
            modal: {
                ...state.approvalRequests.modal,
                active: true,
                data: request[0] || null,
            },
        },
    };
};

const closeApprovalRequestsModal = (state, action) => {
    return {
        ...state,
        approvalRequests: {
            ...state.approvalRequests,
            modal: {
                ...state.approvalRequests.modal,
                active: false,
                data: null,
            },
        },
    };
};

const syncApprovalRequests = (state, action) => {
    const payload = action.payload.data;

    const list = approvalRequestsTableListModel(payload);

    return {
        ...state,
        approvalRequests: {
            ...state.approvalRequests,
            list: list,
        },
    };
};

const openFinancialEntityModal = (state, action) => {
    const payload = action.payload.data;

    const request = state.financialEntities.list.filter(item => item.id === payload.id);

    return {
        ...state,
        financialEntities: {
            ...state.financialEntities,
            modal: {
                ...state.financialEntities.modal,
                active: true,
                data: request[0] || null,
            },
        },
    };
};

const closeFinancialEntityModal = (state, action) => {
    return {
        ...state,
        financialEntities: {
            ...state.financialEntities,
            modal: {
                ...state.financialEntities.modal,
                active: false,
                data: null,
            },
        },
    };
};

const syncFinancialEntities = (state, action) => {
    const payload = action.payload.data;
    const list = financialEntitiesTableListModel(payload);

    return {
        ...state,
        financialEntities: {
            ...state.financialEntities,
            list: list,
        },
    };
};

const syncDistributor = (state, action) => {
    // console.log(action);
    // const payload = action.payload.data;
    // const list = distributorTableListModel(payload);
    // console.log(list);
    // console.log(state);
    // return {
    //     ...state,
    //     distributors: {
    //         ...state.distributors,
    //         list: list,
    //     },
    // };
};

const syncDistributors = (state, action) => {
    const payload = action.payload.data;

    const list = distributorsTableListModel(payload);
    return {
        ...state,
        distributors: {
            ...state.distributors,
            list: list,
        },
    };
};

const syncProviders = (state, action) => {
    const payload = action.payload.data;

    const list = providersTableListModel(payload);
    return {
        ...state,
        providers: {
            ...state.providers,
            list: list,
        },
    };
};

const syncProvider = (state, action) => {
    // console.log(action);
    // const payload = action.payload.data;
    // const list = distributorTableListModel(payload);
    // console.log(list);
    // console.log(state);
    // return {
    //     ...state,
    //     distributors: {
    //         ...state.distributors,
    //         list: list,
    //     },
    // };
};

const openFinancialFlowModal = (state, action) => {
    const payload = action.payload.data;

    const request = state.distributors.list.filter(item => item.id === payload.id);

    return {
        ...state,
        distributors: {
            ...state.distributors,
            modal: {
                ...state.distributors?.active.modal,
                active: true,
                data: request[0] || null,
            },
        },
    };
};

const closeFinancialFlowModal = (state, action) => {
    return {
        ...state,
        distributors: {
            ...state.distributors,
            modal: {
                ...state.distributors?.active.modal,
                active: false,
                data: null,
            },
        },
    };
};

/**
 * Is Loading
 * @param {*} state
 * @param {*} action
 */
const isLoading = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        isLoading: payload,
    };
};

/**
 * Is Saving
 * @param {*} state
 * @param {*} action
 */
const isSaving = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        isSaving: payload,
    };
};

/**
 * Is Saving
 * @param {*} state
 * @param {*} action
 */
const clear = (state, action) => {
    return {
        ...rootInitialState.admin,
    };
};

const setProductDetails = (state, action) => {
    const payload = action.payload.data;

    return {
        ...state,
        insuranceProducts: {
            ...state.insuranceProducts,
            insuranceProduct: payload,
        },
    };
};

export default reducer;
