import ActionTypes from 'redux/actionTypes';

export const isAnalyticsLoadingAction = isLoading => ({
    type: ActionTypes.IS_LOADING,
    payload: {
        isLoading,
    },
});

export const analyticsPopulationSegmentationToggleFilterAction = data => ({
    type: ActionTypes.ANALYTICS_POPULATION_SEGMENTATION_TOGGLE_FILTER,
    payload: {
        data,
    },
});

export const analyticsPopulationSegmentationClearFiltersAction = () => ({
    type: ActionTypes.ANALYTICS_POPULATION_SEGMENTATION_CLEAR_FILTERS,
});

export const openAnalyticsPopulationSegmentationModalAction = data => ({
    type: ActionTypes.ANALYTICS_POPULATION_SEGMENTATION_OPEN_COMPARE_MODAL,
    payload: {
        data,
    },
});

export const closeAnalyticsPopulationSegmentationModalAction = () => ({
    type: ActionTypes.ANALYTICS_POPULATION_SEGMENTATION_CLOSE_COMPARE_MODAL,
});

export const analyticsUsersVisitedPlacesToggleFilterAction = data => ({
    type: ActionTypes.ANALYTICS_USERS_VISITED_PLACES_TOGGLE_FILTER,
    payload: {
        data,
    },
});
export const analyticsSyncProjectsAction = data => ({
    type: ActionTypes.ANALYTICS_SYNC_PROJECTS,
    payload: {
        data,
    },
});

export const analyticsUsersVisitedPlacesClearFiltersAction = () => ({
    type: ActionTypes.ANALYTICS_USERS_VISITED_PLACES_CLEAR_FILTERS,
});

export const analyticsUsersVisitedPlacesChangeProjectAction = data => ({
    type: ActionTypes.ANALYTICS_USERS_VISITED_PLACES_CHANGE_PROJECT,
    payload: {
        data,
    },
});

export const analyticsUsersVisitedPlacesErrorAction = data => ({
    type: ActionTypes.ANALYTICS_USERS_VISITED_PLACES_ERROR,
    payload: {
        data,
    },
});

export const analyticsUsersVisitedPlacesStoreUserAction = data => ({
    type: ActionTypes.ANALYTICS_USERS_VISITED_PLACES_STORE_USER,
    payload: {
        data,
    },
});

export const analyticsUsersVisitedPlacesClearUserAction = () => ({
    type: ActionTypes.ANALYTICS_USERS_VISITED_PLACES_CLEAR_USER,
});

export const analyticsUsersVisitedPlacesStoreViewsAction = (data, date) => ({
    type: ActionTypes.ANALYTICS_USERS_VISITED_PLACES_STORE_VIEWS,
    payload: {
        data,
        date,
    },
});

export const analyticsUserVisitedPlacesDismissAction = () => ({
    type: ActionTypes.ANALYTICS_USERS_VISITED_PLACES_ALERT_DISMISS,
});

export const analyticsUserDataStoreViewsAction = (data, date, type) => ({
    type: ActionTypes.ANALYTICS_USER_DATA_STORE_VIEWS,
    payload: {
        data,
        date,
        type,
    },
});

export const analyticsUserRawDataClearAction = () => ({
    type: ActionTypes.ANALYTICS_USER_DATA_CLEAR,
});

export const analyticsUserRawLocationDataDismissAction = () => ({
    type: ActionTypes.ANALYTICS_USER_RAW_LOCATION_DATA_ALERT_DISMISS,
});

export const analyticsUserRawLocationDataErrorAction = data => ({
    type: ActionTypes.ANALYTICS_USER_RAW_LOCATION_DATA_ERROR,
    payload: {
        data,
    },
});

export const analyticsUserRawLocationDataStoreUserAction = data => ({
    type: ActionTypes.ANALYTICS_USER_RAW_LOCATION_DATA_STORE_USER,
    payload: {
        data,
    },
});

export const analyticsUserRawLocationDataClearUserAction = () => ({
    type: ActionTypes.ANALYTICS_USER_RAW_LOCATION_DATA_CLEAR_USER,
});

export const analyticsUserFactsStoreViewsAction = (data, interval) => ({
    type: ActionTypes.ANALYTICS_USER_FACTS_STORE_VIEWS,
    payload: {
        data,
        interval,
    },
});

export const analyticsUserFactsClearDataAction = () => ({
    type: ActionTypes.ANALYTICS_USER_FACTS_CLEAR_DATA,
});

export const analyticsChangeDatesAction = data => ({
    type: ActionTypes.ANALYTICS_CHANGE_DATES,
    payload: {
        data,
    },
});

export const analyticsUserFactsIsLoadingScoreAction = data => ({
    type: ActionTypes.ANALYTICS_USER_FACTS_IS_LOADING_SCORE,
    payload: {
        data,
    },
});

export const analyticsUserFactsStoreScoreAction = data => ({
    type: ActionTypes.ANALYTICS_USER_FACTS_STORE_SCORE,
    payload: {
        data,
    },
});

export const analyticsUserFactsClearMainViewsAction = () => ({
    type: ActionTypes.ANALYTICS_USER_FACTS_CLEAR_MAIN_VIEWS,
});

export const analyticsUserFactsClearScoreAction = () => ({
    type: ActionTypes.ANALYTICS_USER_FACTS_CLEAR_SCORE,
});
